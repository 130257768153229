import { IM, IMLayout, SpacingProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

interface Props {
  width?: number | string
  height?: number | string
  spacing?: SpacingProps
  color?: string
  style?: StyleProp<ViewStyle>
}

export default function CardSkeleton({ width = '100%', height = 60, spacing, color, style }: Props) {
  return (
    <IM.View spacing={spacing} style={[style]}>
      <IM.SkeletonContainer height={height} width={width} backgroundColor={color}>
        <IM.Rect x="0" y="0" rx={IMLayout.borderRadius} ry={IMLayout.borderRadius} width={width} height={height} />
      </IM.SkeletonContainer>
    </IM.View>
  )
}
