import { RouteProp, useRoute } from '@react-navigation/native'
import React, { useEffect } from 'react'

import { Screen } from '../../components/screen/Screen'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import { HistoryDetailStackParamList } from '../../navigation/types'
import HistoryDetailView from '../../views/history/HistoryDetailView'

export default function HistoryDetailScreen() {
  const route = useRoute<RouteProp<HistoryDetailStackParamList, 'Detail'>>()

  const { setDetail } = useActivityDetail()

  useEffect(() => {
    return () => {
      setDetail(undefined)
    }
  }, [])

  return (
    <Screen>
      <Screen.Header goBack title={route.params.title} />

      <Screen.Content>
        <HistoryDetailView {...route.params} />
      </Screen.Content>
    </Screen>
  )
}
