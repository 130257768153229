import { useContext } from 'react'

import { UserSettingsContext } from '../contexts/UserSettingsContext'

export default function useUserSettings() {
  const context = useContext(UserSettingsContext)

  if (!context) {
    throw new Error('useUserSettings() must be called inside UseUserProvider')
  }

  return context
}
