import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api } from '../../../apis/apiCalls'
import { ActivityType } from '../../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../../components/ApiDeleteButton'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../../components/Infominds/hooks/useEditDataHandler'
import BooleanInput from '../../../components/input/BooleanInput'
import ColorInput from '../../../components/input/ColorInput'
import MultiLangTextInput from '../../../components/input/MultiLangTextInput'
import { TextInputProps } from '../../../components/input/TextInput'
import ScrollViewForm from '../../../components/ScrollViewForm'
import TimeAttendanceSelector from '../../../components/selectors/TimeAttendanceSelector'
import { USER_COLORS } from '../../../constants/Colors'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { EditOrCreateViewRef, UploadStatus } from '../../../types'

type Props = {
  activityType?: ActivityType
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

function ActivityTypeCreateOrEditView({ activityType, onDone, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) {
  const { i18n } = useLanguage()

  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))
  const rndColor = useMemo(() => USER_COLORS[Math.round(Math.random() * (USER_COLORS.length - 1))].primary, [])
  const initialValue = useMemo(() => activityType ?? { planColor: rndColor }, [activityType])
  const requiredFields = useMemo<EditDataHandlerRequiredFields<ActivityType>>(
    () => [['descriptionDe', 'descriptionEn', 'descriptionIt'], 'timeAttendId', 'planColor'],
    []
  )
  const { state, editMode, createOrUpdate, handleDataChange } = useEditDataHandler(api.activities.types.post, api.activities.types.put, undefined, {
    eventKeyCreation: EVENT_KEYS.UPDATED_ACTIVITY_TYPES,
    eventKeyModification: EVENT_KEYS.UPDATED_ACTIVITY_TYPES,
    onDone: onDone,
    onUploadStatus: onUploadStatus,
    editMode: !!activityType,
    initialValue: initialValue,
    showErrorAlert: true,
    requiredFields,
    modifyDataBeforeRequest: data => ({ ...data, notToInvoice: !!data.notToInvoice }),
  })

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <MultiLangTextInput
        required
        title={i18n.t('DESCRIPTION')}
        values={{ de: state?.descriptionDe, en: state?.descriptionEn, it: state?.descriptionIt }}
        onChangeText={values => handleDataChange({ descriptionEn: values.en, descriptionDe: values.de, descriptionIt: values.it })}
        multiline
        placeholder={i18n.t('DESCRIPTION')}
        {...commonProps}
      />
      <TimeAttendanceSelector
        required
        onChange={value => handleDataChange({ timeAttendId: value?.id })}
        editable
        initialValue={activityType?.timeAttendId}
        {...commonProps}
      />
      <ColorInput
        required
        title={i18n.t('PLAN_COLOR')}
        placeholder={i18n.t('COLOR')}
        value={state?.planColor}
        onChangeColor={value => handleDataChange({ planColor: value })}
        {...commonProps}
      />
      <BooleanInput
        title={i18n.t('NO_INVOICE')}
        value={!!state?.notToInvoice}
        onValueChange={value => handleDataChange({ notToInvoice: value })}
        {...commonProps}
      />
      {editMode && !!activityType && (
        <ApiDeleteButton
          onDeleted={onDone}
          data={activityType}
          deleteAlertMessage={i18n.t('DELETE_ACTIVITY_TYPE_ALERT')}
          deleteAlertTitle={i18n.t('DELETE')}
          deleteRequest={api.activities.types.delete}
          eventKey={EVENT_KEYS.UPDATED_ACTIVITY_TYPES}
        />
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityTypeCreateOrEditView)
