import { Address, Contact, Customer } from '../apis/types/apiResponseTypes'

const customerUtils = {
  getTitle(customer: Customer | null | undefined) {
    if (!customer) return ''
    if (!customer.company1) return customer.companyId.toString()
    return `(${customer.companyId}) ${customer.company1?.trim()}`
  },
  getContactInfo(contact: Contact | null | undefined) {
    if (!contact) return { contactText: undefined, contactDescription: undefined }
    if (contact?.firstName || contact?.lastName) {
      const nameTerms = [contact?.lastName, contact?.firstName].filter(Boolean) as string[]

      // check if description contains any info other than name
      let description = contact.description?.trim().toLowerCase() ?? ''
      nameTerms.forEach(term => {
        description = description.replaceAll(term.toLowerCase(), '').trim()
      })
      const hasUsefulInfo = description.length > 0

      return { contactText: nameTerms.join(' '), contactDescription: hasUsefulInfo ? contact?.description : undefined }
    }
    return { contactText: contact?.description }
  },
  formatAddress: <T extends Address>(customer: T | undefined | null, type?: 'street' | 'location') => {
    if (!customer) return ''
    let toRet = ''

    if (customer.place !== undefined && (!type || type === 'location')) {
      toRet += customer.place
      if (customer.province !== undefined) toRet += ` (${customer.province})`
    }

    if (customer.zip !== undefined && (!type || type === 'location')) {
      if (toRet !== '') toRet += ' - '
      toRet += `${customer.zip}`
    }

    if (customer.state !== undefined && (!type || type === 'location')) {
      if (toRet !== '') toRet += ' - '
      toRet += `${customer.state}`
    }

    if (customer.street !== undefined && (!type || type === 'street')) {
      if (toRet !== '') toRet += ' - '
      toRet += `${customer.street}`
    }

    return toRet
  },
}

export default customerUtils
