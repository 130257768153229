import { IMLayout } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { Platform } from 'react-native'
import { AvoidSoftInputView, AvoidSoftInputViewProps } from 'react-native-avoid-softinput'

export default function KeyboardAwareIOS({ children, style, ...props }: PropsWithChildren<AvoidSoftInputViewProps>) {
  if (Platform.OS !== 'ios') {
    return <>{children}</>
  }

  return (
    <AvoidSoftInputView {...props} showAnimationDuration={250} style={[IMLayout.flex.f1, style]}>
      {children}
    </AvoidSoftInputView>
  )
}
