import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { SearchProvider } from '../../../components/screen/contexts/SearchContext'
import { Screen } from '../../../components/screen/Screen'
import ContractListView from '../../../views/activities/contracts/ContractListView'

export default function ContractListScreen() {
  const { i18n } = useLanguage()

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header goBack title={i18n.t('CONTRACTS')} />

        <Screen.Content>
          <ContractListView />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
