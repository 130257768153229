import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IMStyle } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { memo } from 'react'

import { useDataProvider } from '../../../dataProvider/hooks/useDataProvider'
import PressableIcon from '../../Infominds/PressableIcon'

type GetIconReturn = {
  icon: IconProp
  color: string
  size?: number
}

const SyncIcon = memo(function SyncIcon() {
  const dataProviderState = useDataProvider()
  const navigation = useNavigation()

  const { icon, color, size } = getIcon()

  function getIcon(): GetIconReturn {
    if (!dataProviderState.isOnline) return { icon: ['fal', 'cloud-slash'], color: IMStyle.palette.red }
    if (dataProviderState.failedRequests?.length) return { icon: ['fal', 'cloud-exclamation'], color: IMStyle.palette.yellow }
    if (dataProviderState.pendingDataToSync) return { icon: ['fal', 'cloud-arrow-up'], color: IMStyle.palette.yellow }
    return { icon: ['fal', 'cloud-arrow-down'], color: IMStyle.palette.white, size: 24 }
  }

  return (
    <PressableIcon
      icon={icon}
      color={color}
      size={size ?? 22}
      onPress={() => navigation.navigate('BottomTab', { screen: 'SynchronizationStack', params: { screen: 'Synchronization' } })}
    />
  )
})

export default SyncIcon
