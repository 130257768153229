import { IM, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { i18nKey, ListSection, RelativeDayType } from '../../types'
import CardLeftTitle from '../card/left/CardLeftTitle'
import SelectInput, { SelectInputProps } from '../selectors/selectInput/SelectInput'

type Props = {
  value: RelativeDayType | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: RelativeDayType | undefined) => void
} & Pick<SelectInputProps<RelativeDayType>, 'title'>

type SelectorElement = {
  value: RelativeDayType
  languageKey: i18nKey
}

const SelectableTypes: SelectorElement[] = [
  { value: 'first', languageKey: 'REPETITION_FIRST' },
  { value: 2, languageKey: 'REPETITION_SECOND' },
  { value: 3, languageKey: 'REPETITION_THIRD' },
  { value: 4, languageKey: 'REPETITION_FOURTH' },
  { value: 'last', languageKey: 'REPETITION_LAST' },
]

export default function FirstLastSelector({ value, onChange, ...props }: Props) {
  const { i18n } = useLanguage()

  const { theme } = useTheme()

  const render = ({ item }: SectionListRenderItemInfo<SelectorElement, ListSection<SelectorElement>>, onPress?: () => void) => {
    return (
      <IM.Card
        head={<CardLeftTitle color={item.value === value && theme.primary} />}
        defaultContent={{ texts: [i18n.t(item.languageKey)] }}
        onPress={onPress}
        spacing={['bottom', 'horizontal']}
      />
    )
  }

  return (
    <SelectInput
      id={['value']}
      data={SelectableTypes}
      value={SelectableTypes.find(q => q.value === value)}
      screenTitle={i18n.t('REPETITION')}
      renderItem={render}
      onChange={item => onChange(item?.value)}
      renderSelectedString={item => i18n.t(item.languageKey)}
      disableFastInput
      allDataLoaded={undefined}
      onLoadMore={undefined}
      noDataMessage=""
      loading={false}
      required
      {...props}
    />
  )
}
