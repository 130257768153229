import { IM, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

type Props = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    required?: boolean
    onReset: () => void
    onRefresh?: () => void
    disabledInfo?: string | false
    noData?: boolean
  }

const SelectTextInput = memo(function SelectTextInput({
  title,
  details,
  editable,
  error,
  loading,
  disableFocus,
  required,
  titleFontWeight,
  onReset,
  onRefresh,
  disabledInfo,
  noData,
  ...textInputProps
}: Props) {
  const { theme } = useTheme()

  return (
    <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
      <BaseTextInput {...textInputProps}>
        {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={required} />}
        {editable && (
          <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
            {required || !textInputProps.value ? (
              <PressableIcon
                icon={['fal', 'chevron-right']}
                size={18}
                color={theme.textPlaceholder}
                disabled={loading === true}
                onPress={textInputProps.onPress}
              />
            ) : (
              <PressableIcon icon={['fal', 'times']} size={18} color={theme.textPlaceholder} disabled={loading === true} onPress={onReset} />
            )}
          </BaseTextInput.RightIcon>
        )}
        {!editable && disabledInfo && (
          <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
            <IM.Text style={[{ color: theme.textPlaceholder }, styles.disabledText]}>{disabledInfo}</IM.Text>
          </BaseTextInput.RightIcon>
        )}
        {(!!noData || error) && !loading && !textInputProps.value && !!onRefresh && (
          <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
            <PressableIcon icon={['fal', 'refresh']} size={18} color={error ? theme.error : theme.textPlaceholder} onPress={onRefresh} />
          </BaseTextInput.RightIcon>
        )}
      </BaseTextInput>
    </BaseTextInputProvider>
  )
})

export default SelectTextInput

const styles = StyleSheet.create({
  disabledText: {
    paddingRight: 9,
  },
})
