import { IM, useLanguage, Utils, ViewProps } from '@infominds/react-native-components'
import React from 'react'

import { ConstructionSiteLotto, ContractObjectLot } from '../../apis/types/apiResponseTypes'
import { useContractCreation } from '../../contexts/activity/ContractCreationContext'
import { ContractLotSelection } from '../../types'
import ContractActivitySelector from '../../views/activities/contracts/ContractActivitySelector'
import MultiConstructionSiteLottoSelector from './MultiConstructionSiteLottoSelector'

export type ContractLottoSelectorProps = {
  selection?: ContractLotSelection
  setSelection: (value: ContractLotSelection | undefined) => void
  index: number
  selectedLots: number[]
} & Pick<ViewProps, 'spacing' | 'style'>
export function ContractLottoSelector({ selection, setSelection, index, selectedLots, ...viewProps }: ContractLottoSelectorProps) {
  const { i18n } = useLanguage()
  const { state, contract, editMode } = useContractCreation()

  function filterConstructionSiteLots(value: ConstructionSiteLotto | undefined) {
    return !!selection?.lots.find(lot => value?.lotId === lot.objectLotId) || !selectedLots.find(sl => sl === value?.lotId)
  }

  const shoActivitySelector = !!selection?.lots.length && !editMode

  return (
    <IM.View {...viewProps}>
      <MultiConstructionSiteLottoSelector
        title={`${i18n.t('CONTRACT_ACTIVITY_OBJECT')} ${index + 1} ${index === 0 ? ' *' : ''}`}
        editable={!editMode}
        value={selection?.lots.map(lot => lot.objectLotId ?? 0)}
        objectId={state?.objectId}
        filter={filterConstructionSiteLots}
        onChange={value => {
          const lots = value?.map<Partial<ContractObjectLot>>(v => ({ srvContractId: contract?.srvContractId, objectLotId: v.lotId })) ?? []
          if (selection) {
            setSelection({ ...selection, lots })
          } else {
            setSelection({
              id: Utils.getUid(),
              constructionSiteId: state?.objectId ?? 0,
              lots,
            })
          }
        }}
        disableBorderRadius={shoActivitySelector ? 'bottom' : undefined}
      />

      {shoActivitySelector && (
        <ContractActivitySelector
          contractActivity={selection.activity}
          onChange={rep => {
            selection.activity = rep
            setSelection({ ...selection })
          }}
          noTitle
          disableBorderRadius={['top']}
          contract={state}
        />
      )}
    </IM.View>
  )
}
