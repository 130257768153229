import { IM, ViewProps } from '@infominds/react-native-components'
import React from 'react'

import { Activity } from '../../../apis/types/apiResponseTypes'
import Separator from '../../../components/Infominds/Separator'
import TextExpandable from '../../../components/TextExpandable'

type Props = {
  activity: Activity
  numberOfLines?: number
  enableSeparator?: boolean
  maxLength?: number
}

export default function ActivityCardDescription({ activity, numberOfLines = 3, enableSeparator, ...props }: Props & ViewProps) {
  return (
    <IM.View {...props}>
      {enableSeparator && <Separator />}
      <TextExpandable numberOfLines={numberOfLines}>{activity.description}</TextExpandable>
    </IM.View>
  )
}
