import { IM, IMLayout, IMStyle, useAlert, useLanguage, useModalController, useOrientation, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Animated, Platform, StyleSheet, useWindowDimensions } from 'react-native'

import { Contact, Customer, Destination, TimeSlot } from '../../apis/types/apiResponseTypes'
import ContactInformation from '../../components/ContactInformation'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import Map from '../../components/map/Map'
import { SCREEN_CONSTANTS } from '../../components/screen/constants/constants'
import useSearch from '../../components/screen/hooks/useSearch'
import { ADD_CONTACT_DESTINATION_BUTTON_ID } from '../../constants/ButtonIds'
import TimeSlotCreationModal from '../../modals/anagrafics/TimeSlotCreationModal'
import CustomerContactCreationModal from '../../modals/customer/CustomerContactCreationModal'
import DestinationEditOrCreateModal from '../../modals/customer/DestinationEditOrCreateModal'
import { utils } from '../../utils/utils'
import ConstructionSitesView from './ConstructionSitesView'
import ContactsView from './ContactsView'
import DestinationsView from './DestinationsView'

interface Props {
  customer: Customer
}

export type TabType = 'contacts' | 'destinations' | 'infobox'
const buttonAnimationValue = new Animated.Value(0)

const CustomerDetailsLargeDeviceView = ({ customer }: Props) => {
  const alert = useAlert()
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { tabletOpen } = useSearch()
  const { landscape } = useOrientation()
  const { height } = useWindowDimensions()
  const contactEditOrCreateModalController = useModalController<Contact | undefined>()
  const destinationEditOrCreateModalController = useModalController<Destination | undefined>()
  const timeSlotEditOrCreateModalController = useModalController<TimeSlot | undefined>()

  const mapShown = !tabletOpen && !!customer?.address?.latitude && !!customer?.address?.longitude
  const top = mapShown ? undefined : 0
  const fixedHeight = Math.floor(height * 0.6)
  const mapHeight = mapShown ? fixedHeight : '100%'

  return (
    <>
      <IM.View style={IMLayout.flex.f1}>
        {mapShown && (
          <Map
            items={[customer]}
            mapMarkerRenderItem={MapMarker}
            mapPadding={{ bottom: fixedHeight + 100, top: 0, left: 0, right: 0 }}
            hideMyLocationButton
            hideCenterButton
            disableMapTouch
            delta={0.002}
            onMapPress={() => utils.openMaps(customer.address?.latitude, customer.address?.longitude, alert, i18n.t('OPEN_MAP_LINK_ERROR'))}
            style={Platform.OS === 'web' && { marginBottom: fixedHeight }}
          />
        )}
        <IM.View style={[styles.screen, { height: mapHeight, top: top }]}>
          <IM.View style={[styles.screenBorder, IMLayout.shadow, IMLayout.flex.f1, { backgroundColor: theme.tabNavigator.background }]}>
            <ContactInformation flexLimitLargeDevice={landscape ? 18 : 6} customer={customer} />
            <IM.View
              style={[
                styles.screenBorder,
                IMLayout.shadow,
                IMLayout.flex.f1,
                {
                  backgroundColor: theme.background,
                },
              ]}>
              <IM.View style={[styles.row, IMLayout.flex.f1]}>
                <IM.View style={styles.list}>
                  <ContactsView customerId={customer.companyId} showTitle skeletonNumber={10} show />
                </IM.View>
                <IM.View style={styles.list}>
                  <DestinationsView customerId={customer.companyId} showTitle skeletonNumber={10} show />
                </IM.View>
                <IM.View style={styles.list}>
                  <ConstructionSitesView customerId={customer.companyId} showTitle skeletonNumber={10} show />
                </IM.View>
              </IM.View>
            </IM.View>
          </IM.View>
        </IM.View>
      </IM.View>
      <AnimatedButton
        id={ADD_CONTACT_DESTINATION_BUTTON_ID}
        value={buttonAnimationValue}
        icon={['fal', 'plus']}
        onPress={() => contactEditOrCreateModalController.show(undefined)}
      />

      <CustomerContactCreationModal customerId={customer.companyId} controller={contactEditOrCreateModalController} />
      <TimeSlotCreationModal controller={timeSlotEditOrCreateModalController} />
      <DestinationEditOrCreateModal customerId={customer.companyId} controller={destinationEditOrCreateModalController} />
    </>
  )
}

function MapMarker() {
  return <IM.Icon icon={['fas', 'location-dot']} color={IMStyle.palette.red} size={30} />
}

const styles = StyleSheet.create({
  list: { flex: 1 },
  row: {
    flexDirection: 'row',
  },
  screen: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  screenBorder: {
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
})

export default CustomerDetailsLargeDeviceView
