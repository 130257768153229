import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { ListSection } from '../../types'
import CardLeftTitle from '../card/left/CardLeftTitle'
import BaseTextInputTitle from '../input/baseTextInput/BaseTextInputTitle'
import SelectInput, { SelectInputProps } from '../selectors/selectInput/SelectInput'
import { RepetitionUtils } from './RepetitionUtils'

type Month = { index: number; text: string }

export type MonthSelectorProps = { value: number | undefined; onChange: (value: number | undefined) => void } & Pick<
  SelectInputProps<Month>,
  'title' | 'required' | 'editable' | 'placeholder' | 'spacing'
>

export default function MonthSelector({ value, onChange, title, spacing, ...selectorProps }: MonthSelectorProps) {
  const { theme } = useTheme()
  const { i18n, language } = useLanguage()

  const Months = useMemo(() => RepetitionUtils.getMonths(language).map((text, index) => ({ index, text })), [])

  const selectedMonth = useMemo(() => (value !== undefined ? Months.find(w => w.index === value) : undefined), [value])

  const render = ({ item }: SectionListRenderItemInfo<Month, ListSection<Month>>, onPress?: () => void) => {
    return (
      <IM.Card
        head={<CardLeftTitle color={item.index === selectedMonth?.index && theme.primary} />}
        defaultContent={{ texts: [item.text] }}
        onPress={onPress}
        spacing={['bottom', 'horizontal']}
      />
    )
  }

  return (
    <IM.View spacing={spacing}>
      {!!title && <BaseTextInputTitle title={title} required={selectorProps.required} />}
      <SelectInput
        id={['index']}
        data={Months}
        value={selectedMonth}
        screenTitle={i18n.t('DAY')}
        renderItem={render}
        onChange={item => onChange(item?.index)}
        renderSelectedString={item => item.text}
        disableFastInput
        allDataLoaded={undefined}
        onLoadMore={undefined}
        noDataMessage=""
        loading={false}
        showNoSelectionItem={false}
        {...selectorProps}
      />
    </IM.View>
  )
}
