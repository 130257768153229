import { useContext, useEffect } from 'react'

import { ActivityDetailContext } from '../../contexts/activity/ActivityDetailContext'
import { ActivityId } from '../../types'

export default function useActivityDetail(id?: ActivityId) {
  const context = useContext(ActivityDetailContext)

  useEffect(() => {
    if (!id) return
    context?.loadDetail({ ...id })
  }, [id])

  if (!context) throw new Error('useActivityDetail() can only be used inside of ActivityDetailContextProvider')
  return context
}
