import { ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useRef } from 'react'

import LoadingStateView from '../../components/LoadingStateView'
import { Screen } from '../../components/screen/Screen'
import FormSkeleton from '../../components/skeleton/FormSkeleton'
import useEmailSettings from '../../hooks/activity/useEmailSettings'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import EmailSettingsView, { EmailSettingsViewRef } from '../../views/settings/EmailSettingsView'
import BaseServiceModal from '../BaseServiceModal'

export type EmailSettingsModalProps = { controller: ModalController }

export default function EmailSettingsModal({ controller }: EmailSettingsModalProps) {
  return (
    <BaseServiceModal controller={controller}>
      <ModalContent controller={controller} />
    </BaseServiceModal>
  )
}

const ModalContent = ({ controller }: EmailSettingsModalProps) => {
  const { i18n } = useLanguage()
  const viewRef = useRef<EmailSettingsViewRef | null>(null)

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const { emailSettings, loadingEmailSettings } = useEmailSettings()

  return (
    <Screen transparent>
      <Screen.Header
        goBack={() => handleGoBack()}
        backHandlerCallback={handleGoBack}
        isModal
        title={i18n.t('EMAIL_SETTINGS')}
        rightIcon={['fal', 'check']}
        onRightIconPress={() => viewRef.current?.onSave()}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}
      />
      <Screen.Content>
        <LoadingStateView loading={loadingEmailSettings} listSpacing>
          <FormSkeleton count={4} />
        </LoadingStateView>

        {loadingEmailSettings === false && (
          <EmailSettingsView emailSettings={emailSettings} ref={viewRef} updateUploadStatus={setStatus} onDone={() => handleGoBack(true)} />
        )}
      </Screen.Content>
    </Screen>
  )
}
