import { useDidUpdate } from '@infominds/react-native-components'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect } from 'react'

import ActivityDetailEditHeader from '../../components/screen/headers/ActivityDetailEditHeader'
import { Screen } from '../../components/screen/Screen'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useHideBottomTab from '../../hooks/useHideBottomTab'
import { ActivityDetailStackParamList, RootStackParamList } from '../../navigation/types'
import ActivityDetailView from '../../views/activities/ActivityDetailView'

export default function ActivityDetailScreen() {
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityDetail'>>()
  const navigation = useNavigation<NavigationProp<ActivityDetailStackParamList, 'ActivityDetail'>>()
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>()
  const { setDetail } = useActivityDetail()

  useHideBottomTab()

  useActivityDetail(route.params)

  function handleGoBack() {
    if (route.params.source) {
      //@ts-ignore we just want to switch back to the source-stack, therefore a target screen is not required
      rootNavigation.navigate('BottomTab', {
        screen: route.params.source,
      })
      // call goBack as well to unload screen
      navigation.goBack()
    } else {
      navigation.goBack()
    }
  }

  // add cleanup of navigation on blur to reset the source
  useDidUpdate(() => {
    if (route.params.source) {
      navigation.setParams({ ...route.params, source: undefined })
    }
  }, [route.params.srvActivityId, route.params.srvActivityTypeId, route.params.srvActivityYear])

  useEffect(() => {
    return () => {
      setDetail(undefined)
    }
  }, [])

  return (
    <Screen>
      <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack}>
        <ActivityDetailEditHeader />
      </Screen.Header>

      <Screen.Content>
        <ActivityDetailView />
      </Screen.Content>
    </Screen>
  )
}
