type ResourceManagerDataPrototype = {
  id: string
}

export class ResourceManager<T extends ResourceManagerDataPrototype> {
  private resources: T[] = []

  public Get(id: string) {
    return this.resources.find(r => r.id === id)
  }

  public GetAll() {
    return this.resources
  }

  public Register(resource: T) {
    const existingResource = this.Get(resource.id)
    if (existingResource) return existingResource

    this.resources.push(resource)
    return resource
  }
}
