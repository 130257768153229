import { IM, TextProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'

import Indicator, { IndicatorProps } from './indicator/Indicator'
import Text from './Text'

interface Props extends IndicatorProps {
  text: string
  style?: StyleProp<ViewStyle>
  textViewStyle?: StyleProp<TextStyle>
  alignIndicator?: 'left' | 'right'
  indicatorColor?: string
}

export default function TextTable({
  text,
  position,
  style,
  textViewStyle,
  alignIndicator = 'right',
  indicatorColor,
  ...others
}: Props & Pick<TextProps, 'numberOfLines' | 'secondary' | 'primary'>) {
  return (
    <IM.View style={[styles.container, style]}>
      {alignIndicator === 'left' && <Indicator position={position} style={styles.leftIndicatorStyle} color={indicatorColor} />}
      <IM.View style={textViewStyle}>
        <Text {...others}>{text}</Text>
      </IM.View>
      {alignIndicator === 'right' && <Indicator position={position} color={indicatorColor} />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  leftIndicatorStyle: {
    marginRight: 3,
  },
  container: { justifyContent: 'center', flexDirection: 'row' },
})
