import { IM, IMLayout, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { Customer } from '../apis/types/apiResponseTypes'
import useClipboard from '../hooks/useClipboard'
import useLayout from '../hooks/useLayout'
import appUtils from '../utils/appUtils'
import customerUtils from '../utils/customerUtils'
import { utils } from '../utils/utils'
import DynamicView, { DynamicViewProps } from './Infominds/DynamicView'
import PressableTextIcon from './Infominds/PressableTextIcon'
import { SCREEN_CONSTANTS } from './screen/constants/constants'

interface Props extends Pick<DynamicViewProps, 'flexLimitLargeDevice'> {
  customer: Customer
}

const ContactInformation = ({ customer, ...others }: Props) => {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { theme } = useTheme()
  const { copyToClipboard } = useClipboard()

  const email = customer.email
  const phone = customer.phone
  const phone2 = customer.phone2
  const address = `${customerUtils.formatAddress(customer.address, 'street')} - ${customerUtils.formatAddress(customer.address, 'location')}`
  const hasCoordinates = !!customer.address?.latitude && !!customer.address?.longitude

  const commonStyle = {
    pressableStyle: { borderRadius: SCREEN_CONSTANTS.headerRadius },
  }

  return (
    <>
      <DynamicView style={[{ padding: IMLayout.horizontalMargin }]} {...others}>
        <IM.View style={[isSmallDevice ? IMLayout.flex.f6 : IMLayout.flex.f3, styles.info]}>
          <PressableTextIcon
            icon={['fal', 'map-location-dot']}
            iconSize={20}
            onPress={() => {
              if (hasCoordinates) {
                utils.openMaps(customer.address?.latitude, customer.address?.longitude, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
              } else {
                utils.openMapsWithQuery(address, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
              }
            }}
            onLongPress={() => copyToClipboard(address)}
            style={[styles.common]}
            {...commonStyle}>
            {address}
          </PressableTextIcon>
        </IM.View>
        {phone && (
          <IM.View style={[IMLayout.flex.f3, styles.info]}>
            <PressableTextIcon
              icon={['fal', 'phone']}
              iconSize={20}
              onPress={() => appUtils.openPhone(phone, alert, i18n.t('OPEN_PHONE_ERROR'))}
              onLongPress={() => copyToClipboard(phone)}
              numberOfLines={1}
              style={[styles.common, { color: theme.text }]}
              {...commonStyle}>
              {phone}
            </PressableTextIcon>
          </IM.View>
        )}
        {phone2 && (
          <IM.View style={[IMLayout.flex.f3, styles.info]}>
            <PressableTextIcon
              icon={['fal', 'phone']}
              iconSize={20}
              onPress={() => appUtils.openPhone(phone2, alert, i18n.t('OPEN_PHONE_ERROR'))}
              onLongPress={() => copyToClipboard(phone2)}
              numberOfLines={1}
              style={[styles.common, { color: theme.text }]}
              {...commonStyle}>
              {phone2}
            </PressableTextIcon>
          </IM.View>
        )}
        {email && (
          <IM.View style={[IMLayout.flex.f3, styles.info]}>
            <PressableTextIcon
              icon={['fal', 'envelope']}
              iconSize={20}
              onPress={() => appUtils.openEmail(alert, i18n.t('OPEN_EMAIL_ERROR'), email)}
              onLongPress={() => copyToClipboard(email)}
              numberOfLines={1}
              style={[styles.common, { color: theme.text }]}
              {...commonStyle}>
              {email}
            </PressableTextIcon>
          </IM.View>
        )}
      </DynamicView>
    </>
  )
}

const styles = StyleSheet.create({
  common: { width: 0, flexGrow: 1 },
  info: { justifyContent: 'center' },
})

export default ContactInformation
