import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { Button, Popover } from 'antd'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../types'
import Text from '../Text'

export type Legend = {
  title?: string
  planColor?: string
}

export type LegendSection = {
  title?: string
  values: Legend[]
}

interface Props {
  sections: LegendSection[]
  disabled?: boolean
}

const styles = StyleSheet.create({
  container: { alignItems: 'center' },
  indicator: { borderRadius: IMLayout.iconRadius, height: 10, aspectRatio: 1 },
  title: {
    marginBottom: 2,
  },
})

function ChartLegend({ sections, disabled }: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const content = (
    <IM.View style={IMLayout.flex.row}>
      {sections?.map((column, columIndex) => (
        // eslint-disable-next-line react-native/no-inline-styles
        <IM.View key={columIndex.toString()} style={{ maxWidth: 240, marginRight: columIndex === sections.length - 1 ? 0 : 14 }}>
          <IM.View style={styles.title}>
            <Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{column.title}</Text>
          </IM.View>
          {column.values.map((row, rowIndex) => (
            <IM.View key={`${columIndex}-${rowIndex}`} style={[IMLayout.flex.row, styles.container]}>
              <IM.View style={[styles.indicator, { backgroundColor: row.planColor }]} />
              <IM.View spacing="left" style={IMLayout.flex.f1}>
                <Text numberOfLines={1}>{row.title}</Text>
              </IM.View>
            </IM.View>
          ))}
        </IM.View>
      ))}
    </IM.View>
  )

  return (
    <Popover
      content={content}
      title={i18n.t('LEGEND')}
      trigger="hover"
      open={disabled ? false : undefined}
      color={colorScheme === 'dark' ? theme.header.detail.background : 'white'}>
      <Button type="text" disabled={disabled}>
        <IM.Icon icon={['fal', 'info-circle']} size={18} color={theme.header.main.text.primary} />
      </Button>
    </Popover>
  )
}

export default memo(ChartLegend)
