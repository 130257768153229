import { useLanguage } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'

import { Activity } from '../../../apis/types/apiResponseTypes'
import { activityUtils } from '../../../utils/ActivityUtils'
import MapMarkerIcon from './MapMarkerIcon'

interface Props {
  activity: Activity | undefined
  selected?: boolean
}

function MapMarker({ activity, selected }: Props) {
  const { i18n } = useLanguage()
  const unassigned = useMemo(() => !activity?.assignedUser?.length, [activity])

  return (
    <MapMarkerIcon
      icon={activityUtils.getActivityIconByActivity(activity)}
      unassigned={unassigned}
      selected={selected}
      highlightText={i18n.t('GOTO_DETAIL')}
    />
  )
}

export default memo(MapMarker)
