import { Language, Utils } from '@infominds/react-native-components'
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  getDaysInMonth,
  isSameMonth,
  lastDayOfMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns'
import { i18n as i18nType } from 'i18next'

import { RelativeDayType, Repetition } from '../../types'
import TimeUtils from '../../utils/TimeUtils'

export const RepetitionUtils = {
  initRepetition(startDate?: Date, endDate?: Date): Repetition {
    return {
      type: 'none',
      onDay: 1,
      onDayRelativeType: 'first',
      onDayType: 'numeric',
      repeatEveryMonth: 0,
      onWeekDay: 1,
      totalRepetitions: endDate ? undefined : 1,
      weekDays: [1],
      repeatEvery: 1,
      startDate,
      endDate,
    } as Repetition
  },
  getDays(language: Language, weekStartsOn: Exclude<Parameters<typeof startOfWeek>[1], undefined>['weekStartsOn'] = 0, shortFormat?: boolean) {
    return Array(7)
      .fill(0)
      .map((_, q) => {
        const date = addDays(startOfWeek(new Date(), { weekStartsOn, locale: TimeUtils.languageToLocale(language) }), q)
        return TimeUtils.format(date, language, shortFormat ? 'EEEEEE' : 'EEEE')
      })
  },
  getMonths(language: Language) {
    return Array(12)
      .fill(0)
      .map((_, q) => {
        const date = new Date()
        date.setMonth(q)
        return TimeUtils.format(date, language, 'MMMM')
      })
  },
  getRepetitionText(i18n: i18nType, repetition: Repetition, language: Language) {
    const repetitionDates = this.calculateRepetitionDates(repetition)
    if (repetition.type === 'none' || !repetition.startDate || !repetitionDates.length) return null
    const dateFormat = language === 'it' ? 'd MMMM yyyy' : 'd. MMMM yyyy'
    const startDateFormatted = TimeUtils.format(repetitionDates[0], language, dateFormat)
    const endDateText =
      repetitionDates.length > 1 &&
      Utils.stringValueReplacer(
        i18n.t('REPETITION_TEXT_UNTIL_DATE'),
        TimeUtils.format(repetitionDates[repetitionDates.length - 1], language, dateFormat)
      )
    const totalAmountText = Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_TOTAL_AMOUNT'), repetitionDates.length)

    let text = ''
    if (repetition.type === 'day') {
      // Repeats every (n) day(s) starting WEEKDAY, DD MM YYYY
      if (repetition.repeatEvery && repetition.repeatEvery > 1) {
        text = Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_EVERY_DAYS'), repetition.repeatEvery)
      } else {
        text = i18n.t('REPETITION_TEXT_EVERY_DAY')
      }
    } else if (repetition.type === 'week') {
      // Repeats every  day(s) starting WEEKDAY, DD MM YYYY
      const weekDays = this.getDays(language)
        .map((q, index) => ({ day: q, index }))
        .filter(q => repetition.weekDays?.includes(q.index))

      if (weekDays[0].index === 0) {
        const wd0 = weekDays.shift()
        if (wd0) weekDays.push(wd0)
      }

      let weekDayText = ''

      if (weekDays.length === 1) {
        weekDayText = weekDays[0].day
      } else {
        weekDayText = `${weekDays
          .slice(0, -1)
          .map(q => q.day)
          .join(', ')} ${i18n.t('REPETITION_TEXT_AND')} ${weekDays[weekDays.length - 1].day}`
      }

      if (repetition.repeatEvery && repetition.repeatEvery > 1) {
        text = Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_EVERY_WEEKS'), weekDayText, repetition.repeatEvery)
      } else {
        text = Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_EVERY_WEEK'), weekDayText)
      }
    } else if (repetition.type === 'month') {
      if (repetition.onDayType === 'numeric') {
        text = Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_DAY_OF_MONTH'), repetition.onDay ?? 1)
      } else {
        const dayOfWeek = this.getDays(language).find((_, index) => index === repetition.onWeekDay)
        if (!dayOfWeek) return null

        const whenText = getRelativeDayText(repetition.onDayRelativeType, dayOfWeek, i18n)
        text = Utils.stringValueReplacer(
          i18n.t(repetition.repeatEvery && repetition.repeatEvery > 1 ? 'REPETITION_TEXT_ON_MONTH' : 'REPETITION_TEXT_EVERY_MONTH'),
          whenText
        )
      }

      if (repetition.repeatEvery && repetition.repeatEvery > 1) {
        text = `${Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_EVERY_MONTHS'), repetition.repeatEvery)} ${text}`
      }
    } else if (repetition.type === 'year') {
      // repeats every year
      const monthName = this.getMonths(language).find((_, index) => index === repetition.repeatEveryMonth)
      if (!monthName) return null
      let whenText = ''
      if (repetition.onDayType === 'numeric') {
        whenText = Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_DAY_OF_MONTH_SHORT'), repetition.onDay ?? 1)
      } else {
        const dayOfWeek = this.getDays(language).find((_, index) => index === repetition.onWeekDay)
        if (!dayOfWeek) return null
        whenText = `${getRelativeDayText(repetition.onDayRelativeType, dayOfWeek, i18n)} ${i18n.t('REPETITION_TEXT_IN_MONTH')} `
      }
      text = Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_EVERY_YEAR'), whenText, monthName)
    }

    text = Utils.stringValueReplacer(i18n.t('REPETITION_TEXT'), text, startDateFormatted)
    if (endDateText) text += ` ${endDateText}`
    if (totalAmountText) text += `. ${totalAmountText}`
    return text
  },
  calculateRepetitionDates(repetition: Repetition) {
    if (
      repetition.type === 'none' ||
      !repetition.startDate ||
      !repetition.repeatEvery ||
      (!repetition.endDate && !repetition.totalRepetitions) ||
      (!!repetition.endDate && repetition.endDate < repetition.startDate)
    ) {
      return []
    }

    const result: Date[] = []
    const startDate = startOfDay(repetition.startDate)
    const endDate = repetition.endDate ? endOfDay(repetition.endDate) : undefined
    let date = new Date(startDate)

    // day mode
    if (repetition.type === 'day') {
      // add all dates every n days until limit is reached
      while ((!endDate || date <= endDate) && (!repetition.totalRepetitions || result.length < repetition.totalRepetitions)) {
        result.push(date)
        date = addDays(date, Math.max(repetition.repeatEvery ?? 1, 1))
      }

      return result
    }

    // week mode
    if (repetition.type === 'week') {
      if (!repetition.weekDays?.length) return []
      date = startOfWeek(date, { weekStartsOn: 0 })
      // take all dates of every week that match the filter
      while ((!endDate || date <= endDate) && (!repetition.totalRepetitions || result.length < repetition.totalRepetitions)) {
        const datesToAdd = Array(7)
          .fill(0)
          .map((_, q) => q)
          .filter(q => repetition.weekDays?.includes(q))
          .map(q => addDays(date, q))
          .filter(q => q >= startDate && (!endDate || q <= endDate))

        datesToAdd.forEach(d => {
          if (!!repetition.totalRepetitions && result.length >= repetition.totalRepetitions) return
          result.push(d)
        })
        date = addWeeks(date, Math.max(repetition.repeatEvery ?? 1, 1))
      }
      return result
    }

    // month mode
    if (repetition.type === 'month') {
      if (!repetition.onDayType) {
        return []
      }
      date = startOfMonth(date)
      while ((!endDate || date <= endDate) && (!repetition.totalRepetitions || result.length < repetition.totalRepetitions)) {
        const foundDate = findDateInMonth(date, repetition)
        if (!!foundDate && foundDate >= startDate && (!endDate || foundDate <= endDate)) result.push(foundDate)
        date = addMonths(date, Math.max(repetition.repeatEvery ?? 1, 1))
      }

      return result
    }

    // year mode
    if (repetition.type === 'year') {
      if (!repetition.onDayType || repetition.repeatEveryMonth === undefined) {
        return []
      }
      date = startOfYear(date)
      while ((!endDate || date <= endDate) && (!repetition.totalRepetitions || result.length < repetition.totalRepetitions)) {
        date.setMonth(repetition.repeatEveryMonth)
        const foundDate = findDateInMonth(date, repetition)
        if (!!foundDate && foundDate >= startDate && (!endDate || foundDate <= endDate)) result.push(foundDate)
        date = startOfYear(addYears(date, 1))
      }

      return result
    }

    return []
  },
}

function findDateInMonth(date: Date, repetition: Repetition) {
  if (repetition.type !== 'month' && repetition.type !== 'year') return null
  if (repetition.onDayType === 'numeric') {
    if (!repetition.onDay) return null
    let targetDate = new Date(date)
    if (getDaysInMonth(date) < repetition.onDay) {
      targetDate = lastDayOfMonth(targetDate)
    } else {
      targetDate.setDate(repetition.onDay)
    }
    return targetDate
  } else if (repetition.onDayType === 'relative') {
    if (repetition.onWeekDay === undefined) return null
    const foundDate = findWeekDayInMonth(date, repetition.onWeekDay, repetition.onDayRelativeType)
    return foundDate
  }
  return null
}

function findWeekDayInMonth(date: Date, day: number, mode: RelativeDayType = 'first') {
  let dayFinderDate = mode === 'last' ? endOfMonth(date) : startOfMonth(date)
  let foundDate = null
  for (let i = 0; i < 7; i++) {
    if (dayFinderDate.getDay() === day) {
      foundDate = dayFinderDate
      break
    }
    dayFinderDate = mode === 'last' ? subDays(dayFinderDate, 1) : addDays(dayFinderDate, 1)
  }

  if (!foundDate || mode === 'first' || mode === 'last') return foundDate

  // second, third, fourth
  let add = mode - 1
  while (add >= 1) {
    const newDate = addWeeks(foundDate, add)
    if (isSameMonth(newDate, foundDate)) return newDate
    add--
  }

  return foundDate
}

function getRelativeDayText(mode: RelativeDayType = 'first', day: string, i18n: i18nType) {
  switch (mode) {
    case 'last':
      return Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_LAST_OF'), day)
    case 4:
      return Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_FOURTH_OF'), day)
    case 3:
      return Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_THIRD_OF'), day)
    case 2:
      return Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_SECOND_OF'), day)
    default:
      return Utils.stringValueReplacer(i18n.t('REPETITION_TEXT_FIRST_OF'), day)
  }
}
