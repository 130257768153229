import { useEffect } from 'react'

import { api } from '../../apis/apiCalls'
import { ConstructionSiteLotto } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { LoadingType } from '../../types'

export type UseConstructionSiteLotsResult = {
  constructionSiteLots: ConstructionSiteLotto[] | undefined
  loadingConstructionSiteLots: LoadingType
  refreshConstructionSiteLots: () => void
}

export default function useConstructionSiteLots(constructionSiteId?: number): UseConstructionSiteLotsResult {
  const {
    item: constructionSiteLots,
    loadItem: loadLots,
    loading: loadingConstructionSiteLots,
    setItem: setLots,
  } = useControlledLoader(api.constructionsSites.lotto.getList)

  useEffect(refreshConstructionSiteLots, [constructionSiteId])

  function refreshConstructionSiteLots() {
    if (!constructionSiteId) {
      setLots([])
      return
    }
    loadLots({ objectId: constructionSiteId })
  }

  return { constructionSiteLots, loadingConstructionSiteLots, refreshConstructionSiteLots }
}
