import { IM, IMLayout, IMStyle } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { Activity } from '../../apis/types/apiResponseTypes'
import useOnLayout from '../../hooks/useOnLayout'
import { activityUtils } from '../../utils/ActivityUtils'

type Props = {
  activity: Activity
  width: number
  onPress?: () => void
}

export default function ActivityCalenderCard({ activity, width, onPress }: Props) {
  const activityTypeIcon = useMemo(() => activityUtils.getActivityIconByActivity(activity), [activity])
  const { onLayout, layout } = useOnLayout()

  const title = activity.title ?? ''

  const show = useMemo(() => {
    const height = layout?.height ?? 1e5
    const iconAndTitleInRow = 24 + title.length * 8 <= width
    return {
      icon: (iconAndTitleInRow && height > 58) || height > 70,
      title: height > 10,
      destination: height > 44,
      contact: height > 80,
      // icon size + textLength * textSize  (estimations)
      iconAndTitleInRow,
    }
  }, [layout?.height, width, title])

  const destinationText = useMemo(() => {
    if (activity.destinationDescription) return activity.destinationDescription
    else if (activity.constructionSiteDescription) return activity.constructionSiteDescription
    else if (activity.customerDescription) return activity.customerDescription
    else return undefined
  }, [activity])

  return (
    <TouchableOpacity onPress={onPress} style={styles.root} onLayout={onLayout}>
      <IM.View style={styles.container}>
        <IM.View spacing={'horizontal'} style={styles.content}>
          <IM.View>
            <IM.View style={show.iconAndTitleInRow && [IMLayout.flex.row, styles.rowGap]}>
              {show.icon && (
                <IM.View spacing={'top'}>
                  <IM.Icon icon={activityTypeIcon} color={IMStyle.palette.white} size={18} />
                </IM.View>
              )}
              {title && show.title && (
                <IM.Text primary style={[styles.text]} numberOfLines={1}>
                  {title}
                </IM.Text>
              )}
            </IM.View>

            {destinationText && show.destination && (
              <IM.Text secondary style={[styles.text]}>
                {destinationText}
              </IM.Text>
            )}

            {activity.contactDescription && show.contact && (
              <IM.View spacing={'top'}>
                <IM.Text secondary style={[styles.text]}>
                  {activity.contactDescription}
                </IM.Text>
              </IM.View>
            )}
          </IM.View>
        </IM.View>
      </IM.View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  root: { height: '100%' },
  container: { height: '100%' },
  content: { flex: 1, justifyContent: 'space-between' },
  text: { color: IMStyle.palette.white },
  rowGap: { alignItems: 'center', gap: 8 },
})
