import { IM, IMLayout, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import GroupedActivityTravelView from '../../../components/activity/travel/GroupedActivityTravelView'
import Button from '../../../components/Button'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import LoadingStateView from '../../../components/LoadingStateView'
import MoreButton from '../../../components/MoreButton'
import TravelSkeleton from '../../../components/skeleton/TravelSkeleton'
import { AppStyle } from '../../../constants/Styles'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import useActivityTravels from '../../../hooks/activity/useActivityTravels'
import useActivityTypes from '../../../hooks/activity/useActivityTypes'
import useLayout from '../../../hooks/useLayout'
import usePartialList from '../../../hooks/usePartialList'
import ActivityTravelCreateOrEditModal from '../../../modals/activity/ActivityTravelCreateOrEditModal'
import { ActivityDetailStackParamList } from '../../../navigation/types'
import { ActivityTravelCreateOrEditViewProps } from '../../../views/activities/ActivityTravelCreateOrEditView'
import { LoadedCardProps } from '../../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData' | 'loading'> & { dontLoadOnMount?: boolean }

const ActivityDetailTravelCard = memo(function ActivityDetailTravelCard({ enableChange, dontLoadOnMount, ...props }: Props) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation<NavigationProp<ActivityDetailStackParamList>>()
  const modifyTravelModal = useModalController<ActivityTravelCreateOrEditViewProps>()
  const { detail, isHistorical } = useActivityDetail()
  const { groupedTravels, loadingTravels } = useActivityTravels(detail, dontLoadOnMount)
  const { activityTypes } = useActivityTypes()

  const { displayedItems: displayedTravels, displayMode, setDisplayMode } = usePartialList(groupedTravels, { reverse: true })

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('TRAVEL')} />} noContentSpacing {...props}>
      <IM.View spacing={'all'} style={styles.contentView}>
        <LoadingStateView loading={loadingTravels}>
          <TravelSkeleton />
        </LoadingStateView>
        {!groupedTravels?.length && !loadingTravels && (
          <IM.View spacing={'all'}>
            <IM.Text secondary>{i18n.t('NO_TRAVELS')}</IM.Text>
          </IM.View>
        )}
        {!!groupedTravels?.length && !loadingTravels && (
          <MoreButton
            allItemsCount={groupedTravels.length}
            shownItemsCount={displayedTravels?.length ?? 0}
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
          />
        )}

        {!!detail &&
          displayedTravels?.map((travel, index) => (
            <GroupedActivityTravelView
              key={`ActivityTravelRecord#${travel.date}`}
              activity={detail}
              travel={travel}
              separator={index > 0 || groupedTravels.length > 3}
              activityTypes={activityTypes ?? []}
              enableChange={!isHistorical && enableChange}
              onEdit={travelToEdit => {
                if (!detail) return
                if (isSmallDevice) navigation.navigate('ActivityTravelEditScreen', { activity: detail, activityTravel: travelToEdit })
                else modifyTravelModal.show({ activity: detail, activityTravel: travelToEdit })
              }}
            />
          ))}

        {enableChange && detail && (
          <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd]} spacing={'top'}>
            <Button
              title={i18n.t('ADD')}
              onPress={() => {
                if (isSmallDevice) navigation.navigate('ActivityTravelEditScreen', { activity: detail })
                else modifyTravelModal.show({ activity: detail })
              }}
              color={theme.primary}
            />
          </IM.View>
        )}
      </IM.View>
      <ActivityTravelCreateOrEditModal controller={modifyTravelModal} />
    </IM.Card>
  )
})

export default ActivityDetailTravelCard

const styles = StyleSheet.create({
  contentView: {
    minHeight: 50,
    flexShrink: 1,
  },
})
