import { IM, ModalController, useLanguage, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { Activity } from '../../apis/types/apiResponseTypes'
import EmployeeCard from '../../cards/common/EmployeeCard'
import { Screen } from '../../components/screen/Screen'
import ScrollViewData from '../../components/ScrollViewData'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController
  activity: Activity
}

export default function AssignedUserModal({ controller, activity }: Props) {
  const { i18n } = useLanguage()

  const filteredUsers = useMemo(() => Utils.keepUniques(activity.assignedUser ?? [], e => e.id), [activity.assignedUser])

  return (
    <BaseServiceModal controller={controller}>
      <Screen transparent>
        <Screen.Header goBack={() => controller.close()} isModal title={i18n.t('EMPLOYEES')} />
        <Screen.Content>
          <ScrollViewData>
            <IM.View>{filteredUsers?.map(user => <EmployeeCard key={user.id} employee={user} spacing={'bottom'} />)}</IM.View>
          </ScrollViewData>
        </Screen.Content>
      </Screen>
    </BaseServiceModal>
  )
}
