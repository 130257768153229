import { useEffect, useState } from 'react'

export default function useBackgroundWorker(callback: (() => void | Promise<void>) | null | undefined, interval_ms = 0) {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    if (!callback || interval_ms <= 0) return
    const interval = setInterval(() => {
      Promise.resolve(callback()).catch(null)
    }, interval_ms)

    return () => clearInterval(interval)
  }, [callback, interval_ms, counter])

  function triggerCallback() {
    // call the callback and increase the counter to one to reset the interval
    callback?.()?.catch(console.error)
    setCounter(counter + 1)
  }

  return {
    triggerCallback,
  }
}
