import { useEvent } from '@infominds/react-native-components'
import { useEffect, useMemo } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useObjectUtils from '../useObjectUtils'

export default function useActivityEmployees(activity?: Activity, filterBy?: { employeeId?: number; supplierId?: number }) {
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const {
    item: activityEmployees,
    loadItem: load,
    loading: loadingActivityEmployees,
    setItem,
  } = useControlledLoader(api.activities.employees.getList)
  const filteredActivityEmployees = useMemo(
    () => activityEmployees?.filter(e => !filterBy || (!!filterBy.employeeId && filterBy.employeeId === e.technician?.id)),
    [filterBy, activityEmployees]
  )

  useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED }, refreshActivityEmployees)

  useEffect(refreshActivityEmployees, [activity])

  function refreshActivityEmployees() {
    if (!activity) {
      setItem([])
      return
    }
    load(activityObjectUtils.createRequestObject(activity))
  }

  return { activityEmployees: filteredActivityEmployees, loadingActivityEmployees, refreshActivityEmployees, filteredActivityEmployees }
}
