import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, useEvent, useTheme } from '@infominds/react-native-components'
import React, { memo, useEffect, useState } from 'react'
import { Animated, Easing, Platform, StyleSheet } from 'react-native'
import REAAnimated, { Extrapolation, interpolate, useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'

import { EVENT_KEYS } from '../../constants/EventKeys'
import { InfiniteLoadingType, ThemeColorExpanded } from '../../types'
import LoadingIcon from '../LoadingIcon'
import PressableIcon from './PressableIcon'
import PressableTextIcon, { PressableTextIconProps } from './PressableTextIcon'

export type AnimatedButtonHideEvent = {
  id: string
  hide: boolean
}

export interface AnimatedButtonProps extends PressableTextIconProps {
  id?: string
  value: Animated.Value
  bottom?: number
  text?: string
  loading?: InfiniteLoadingType
  loadingIcon?: InfiniteLoadingType
  forceShow?: boolean
  badgeIcon?: IconProp
}

const AnimatedButton = memo(function AnimatedButton({
  id,
  style,
  bottom = 14,
  text,
  value,
  loading = false,
  loadingIcon = false,
  forceShow = false,
  badgeIcon,
  ...pressableIconProps
}: AnimatedButtonProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const pulsingAnimation = useSharedValue(-1)

  const [hide, setHide] = useState(false)
  const [shouldHide, setShouldHide] = useState(false)

  useEvent<AnimatedButtonHideEvent>({ key: EVENT_KEYS.SECTION_LIST_CLOSE_TO_END_EVENT_KEY }, ({ id: idToHide, hide: hideValue }) => {
    if (idToHide === id) {
      setShouldHide(hideValue)
    }
  })

  useEffect(() => {
    if (badgeIcon === undefined) return

    pulsingAnimation.value = withRepeat(
      withTiming(-pulsingAnimation.value, {
        duration: 1000,
      }),
      -1,
      true
    )

    return () => {
      pulsingAnimation.value = -1
    }
  }, [badgeIcon])

  const animatedStyles = useAnimatedStyle(() => {
    const scale = interpolate(pulsingAnimation.value, [-1, 1], [1, 1.2], {
      extrapolateRight: Extrapolation.CLAMP,
    })

    return {
      transform: [{ scale: scale }],
    }
  }, [])

  useEffect(() => {
    setHide(forceShow === false ? shouldHide : false)
  }, [shouldHide, forceShow])

  useEffect(() => {
    value &&
      Animated.timing(value, {
        toValue: hide ? 0 : 1,
        duration: hide ? 300 : 700,
        easing: Easing.exp,
        useNativeDriver: Platform.OS === 'android' || Platform.OS === 'ios',
      }).start()
  }, [value, hide])

  const translation = value?.interpolate({
    inputRange: [0, 1],
    outputRange: [150, 0],
  })

  const common = {
    style: [
      styles.createButton,
      {
        bottom: bottom,
        backgroundColor: pressableIconProps.disabled ? theme.button.disabledBackground : theme.button.background,
      },
      style,
    ],
  }

  return (
    <Animated.View style={[translation && { transform: [{ translateX: translation }] }, styles.container]}>
      {text ? (
        <IM.View style={[styles.createButton, { bottom: bottom, backgroundColor: theme.button.background }, IMLayout.shadow, style]}>
          <PressableTextIcon
            color={IMStyle.palette.white}
            style={[styles.pressable]}
            disabled={loading !== false}
            pressableStyle={{ borderRadius: IMLayout.iconRadius }}
            {...pressableIconProps}>
            {loading === false ? (
              text
            ) : (
              <IM.View style={styles.loadingIndicator}>
                <LoadingIcon size={16} color={pressableIconProps.color} />
              </IM.View>
            )}
          </PressableTextIcon>
        </IM.View>
      ) : (
        <>
          {pressableIconProps.icon !== undefined && (
            <>
              {loadingIcon === false ? (
                <IM.View style={IMLayout.flex.f1}>
                  <PressableIcon
                    icon={pressableIconProps.icon}
                    size={pressableIconProps.iconSize}
                    color={IMStyle.palette.white}
                    {...common}
                    {...pressableIconProps}
                  />
                  {badgeIcon && (
                    // eslint-disable-next-line react-native/no-inline-styles
                    <IM.View style={{ zIndex: 10 }} pointerEvents="none">
                      <REAAnimated.View
                        style={[styles.badge, IMLayout.shadow, { backgroundColor: theme.general.info, top: -bottom - 46 }, animatedStyles]}
                      />
                      <IM.Icon
                        icon={badgeIcon}
                        color={IMStyle.palette.white}
                        size={15}
                        // eslint-disable-next-line react-native/no-inline-styles
                        style={{ position: 'absolute', top: -bottom - 43, right: 4 }}
                      />
                    </IM.View>
                  )}
                </IM.View>
              ) : (
                <IM.View {...common}>
                  <LoadingIcon size={20} style={styles.loadingIcon} color={pressableIconProps.color} />
                </IM.View>
              )}
            </>
          )}
        </>
      )}
    </Animated.View>
  )
})

export default AnimatedButton

const styles = StyleSheet.create({
  badge: {
    right: 1,
    borderRadius: IMLayout.iconRadius,
    minWidth: 22,
    height: 22,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    ...Platform.select({
      web: {
        bottom: 0,
        right: 0,
      },
    }),
  },
  createButton: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
    padding: 4,
    paddingRight: 8,
    borderTopLeftRadius: IMLayout.iconRadius,
    borderBottomLeftRadius: IMLayout.iconRadius,
  },
  // @ts-ignore works on web
  pressable: { textAlign: 'center', ...Platform.select({ web: { display: 'flex', width: 'max-content', userSelect: 'none' } }) },
  loadingIndicator: {
    paddingLeft: 6,
    paddingTop: Platform.OS === 'ios' ? 4 : 0,
  },
  loadingIcon: {
    padding: 8,
  },
})
