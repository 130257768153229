import { DefaultTheme, IMStyle, InfomindsColors, ThemeType } from '@infominds/react-native-components'
import Color from 'color'

import { ThemeColorExpanded } from '../types'

export const themeExpansion: ThemeType<ThemeColorExpanded> = {
  light: {
    ...DefaultTheme.light,
    access: {
      denied: IMStyle.palette.red,
      granted: '#25D07C',
      inactive: '#a9a9a9',
    },
    article: {
      status: {
        green: '#25D07C',
        red: '#FF6161',
        yellow: '#FDCF57',
        grey: '#92918E',
      },
      image: {
        placeholder: '#8E8E8E',
      },
    },
    background: '#F0F2F5',
    backgroundSecondary: '#E2E6ED',
    button: {
      ...DefaultTheme.light.button,
      background: '#333B47',
      disabledBackground: '#BCBCBC',
      disabledIcon: IMStyle.palette.white,
      icon: IMStyle.palette.white,
      qualityCheck: '#8E98F6',
    },
    card: {
      ...DefaultTheme.light.card,
      background: '#FFFFFF',
      secondaryBackground: '#535F71',
      cardBackground: {
        active: '#333B47',
        selected: '#e3e2e2',
      },
      accent: {
        active: '#333B47',
      },
      button: {
        active: '#535F71',
        inactive: '#D3D3D3',
        icon: {
          active: 'white',
          inactive: '#8B9F95',
        },
      },
    },
    chip: {
      ...DefaultTheme.light.chip,
      border: IMStyle.palette.neutral,
      background: '#EBEBEB',
      active: {
        background: '#535F71',
        border: '#535F71',
        text: DefaultTheme.dark.text,
      },
      text: DefaultTheme.light.text,
      icon: {
        enabled: DefaultTheme.light.text,
        disabled: '#848484',
      },
      installation: IMStyle.palette.tint,
      removal: '#FF5858',
    },
    closing: {
      first: '#F5F5F5',
      second: '#EEEEEE',
      third: '#E6E6E6',
      fourth: '#DFDFDF',
    },
    drag: {
      active: '#e1e1e1',
    },
    dropdown: {
      arrow: '#4C4D4D',
    },
    gantt: {
      background: '#ffffff',
      border: { primary: '#e9e9e9', secondary: '#d2d2d2' },
      nonWorking: { background: '#F8F8F9', text: 'black' },
      selection: IMStyle.palette.tint,
    },
    general: {
      info: IMStyle.palette.tint,
      error: '#FF5858',
      warn: '#FFA758',
      image: '#7682F6',
      excel: '#389663',
      word: '#5186D7',
      pdf: '#B30C01',
      text: '#8E8E8E',
      blue: '#1A73E8',
    },
    header: {
      main: {
        background: '#333B47',
        error: '#FF5858',
        text: { primary: IMStyle.palette.white, secondary: '#C3C7C7', placeholder: Color(IMStyle.palette.white).darken(0.26).toString() },
      },
      detail: { background: '#535F71', skeletonBackground: '#627085' },
    },
    inputBox: {
      background: { active: '#fcfcfc', disabled: '#fafafa' },
      border: { active: '#D5D4DC', disabled: '#fafafa', error: '#FF5858' },
    },
    item: { selected: '#f2f2f2' },
    loader: {
      background: '#DEDEDE',
      border: '#D0CECE',
    },
    map: {
      marker: {
        background: IMStyle.palette.tint,
        unassigned: '#333B47',
      },
    },
    moreSheet: {
      background: '#FFFFFF',
      navigationElement: { background: Color('#FFFFFF').darken(0.03).toString(), selected: Color(IMStyle.palette.tint).darken(0.07).toString() },
      indicator: 'gray',
    },
    pressable: {
      ...DefaultTheme.light.pressable,
      disabled: '#BFBFBF',
      highlight: '#F7F7F7',
      animatedHighlight: '#DFF2E8',
    },
    pressableIcon: {
      disabled: '#e7e7e7',
    },
    radioButton: IMStyle.palette.tint,
    settings: { badge: { background: '#E3E7EC' } },
    skeleton: {
      background: '#E5ECF0',
      highlight: '#DDE4E8',
    },
    tabNavigator: {
      ...DefaultTheme.light.tabNavigator,
      background: '#FFFFFF',
    },
    segmentedControl: {
      background: '#F2F2F3',
      tint: '#FFFFFF',
    },
    drawerNavigator: {
      background: '#535F71', // '#333B47',
      bar: '#333B47',
      hover: '#535F71',
      icon: {
        focused: '#25D07C',
        unFocused: '#DDE4E8',
      },
    },
    textPlaceholder: '#B3B9C2',
    timeline: {
      background: '#B3B9C2',
    },
    filterTag: {
      default: {
        background: '#FFFFFF',
        border: IMStyle.palette.neutral,
        text: DefaultTheme.light.text,
      },
      active: {
        background: '#535F71',
        border: '#535F71',
        text: DefaultTheme.dark.text,
      },
      header: {
        background: '#FFFFFF',
        border: IMStyle.palette.neutral,
        text: DefaultTheme.light.text,
      },
    },
    closingSummary: {
      divider: '#D5D4DC',
      input: {
        background: DefaultTheme.light.background,
      },
    },
  },
  dark: {
    ...DefaultTheme.dark,
    access: {
      denied: IMStyle.palette.red,
      granted: '#25D07C',
      inactive: '#a9a9a9',
    },
    article: {
      status: {
        green: '#25D07C',
        red: '#FF6161',
        yellow: '#FDCF57',
        grey: '#92918E',
      },
      image: {
        placeholder: '#919192',
      },
    },
    background: '#18191A',
    backgroundSecondary: '#222425',
    button: {
      ...DefaultTheme.light.button,
      background: '#424242',
      disabledBackground: '#898989',
      disabledIcon: IMStyle.palette.white,
      icon: IMStyle.palette.white,
      qualityCheck: '#7682F6',
    },
    card: {
      ...DefaultTheme.dark.card,
      secondaryBackground: '#424242',
      background: '#272829',
      cardBackground: {
        active: '#5C5C5C',
        selected: '#46484a',
      },
      accent: {
        active: '#424242',
      },
      button: {
        active: '#424242',
        inactive: '#0B3F25',
        icon: {
          active: 'white',
          inactive: '#5C5C5C',
        },
      },
    },
    chip: {
      ...DefaultTheme.dark.chip,
      border: IMStyle.palette.neutral,
      background: '#353535',
      active: {
        background: '#686868',
        border: '#686868',
        text: DefaultTheme.light.text,
      },
      text: DefaultTheme.dark.text,
      icon: {
        enabled: DefaultTheme.light.text,
        disabled: '#9F9F9F',
      },
      installation: IMStyle.palette.tint,
      removal: '#FF5858',
    },
    closing: {
      first: '#1E1E1E',
      second: '#242424',
      third: '#2A2A2A',
      fourth: '#303030',
    },
    drag: {
      active: '#424242',
    },
    dropdown: {
      arrow: '#9CA2AD',
    },
    gantt: {
      background: '#161616',
      border: { primary: '#2f2f2f', secondary: '#515151' },
      nonWorking: { background: '#1E1E1E', text: 'white' },
      selection: IMStyle.palette.tint,
    },
    general: {
      info: IMStyle.palette.tint,
      error: '#FF5858',
      warn: '#FFA758',
      image: '#97A1F8',
      excel: '#389663',
      word: '#5186D7',
      pdf: '#F8372B',
      text: '#919192',
      blue: '#1A73E8',
    },
    header: {
      main: { background: '#424242', error: '#FF5858', text: { primary: IMStyle.palette.white, secondary: '#C3C7C7', placeholder: '#676767' } },
      detail: { background: '#202020', skeletonBackground: '#424242' },
    },
    inputBox: {
      background: { active: '#2f2f2f', disabled: '#222222' },
      border: { active: '#2f2f2f', disabled: '#222222', error: '#FF5858' },
    },
    item: { selected: '#343435' },
    loader: {
      background: '#454545',
      border: '#595959',
    },
    map: {
      marker: {
        background: IMStyle.palette.tint,
        unassigned: InfomindsColors.textDetail,
      },
    },
    moreSheet: {
      background: '#252526',
      navigationElement: { background: '#333333', selected: Color(IMStyle.palette.tint).darken(0.25).toString() },
      indicator: 'gray',
    },
    pressable: {
      ...DefaultTheme.dark.pressable,
      disabled: '#3C3C3C',
      highlight: '#2B2B2C',
      animatedHighlight: '#23362C',
    },
    pressableIcon: {
      disabled: '#626262',
    },
    radioButton: IMStyle.palette.tint,
    settings: { badge: { background: '#1E2021' } },
    skeleton: {
      background: '#303233',
      highlight: '#424242',
    },
    segmentedControl: {
      background: '#272829',
      tint: '#686868',
    },
    tabNavigator: {
      ...DefaultTheme.dark.tabNavigator,
      background: '#242526',
    },
    drawerNavigator: {
      background: '#2B2C2E', // '#424242',
      bar: '#18191A',
      hover: '#242526',
      icon: {
        focused: '#25D07C',
        unFocused: '#D0D0D0',
      },
    },
    textPlaceholder: '#676767',
    timeline: {
      background: '#676767',
    },
    filterTag: {
      default: {
        background: '#272829',
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
      active: {
        background: DefaultTheme.dark.textDetail,
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
      header: {
        background: DefaultTheme.dark.textDetail,
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
    },
    closingSummary: {
      divider: '#2f2f2f',
      input: {
        background: '#343434',
      },
    },
  },
}

export const themeWebExpansion: ThemeType<ThemeColorExpanded> = {
  ...themeExpansion,
  light: {
    ...themeExpansion.light,
    header: {
      ...themeExpansion.light.header,
      main: {
        ...themeExpansion.light.header.main,
        background: '#535F71',
      },
      detail: {
        ...themeExpansion.light.header.detail,
        background: themeExpansion.light.header.main.background,
      },
    },
  },
  dark: {
    ...themeExpansion.dark,
    header: {
      ...themeExpansion.dark.header,
      main: {
        ...themeExpansion.dark.header.main,
        background: '#2B2C2E',
      },
    },
  },
}
