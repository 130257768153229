import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { SectionList, SectionListRenderItemInfo, StyleProp, ViewStyle } from 'react-native'

import { Customer } from '../../apis/types/apiResponseTypes'
import CustomerCard from '../../cards/customer/CustomerCard'
import { ListSection } from '../../types'

interface Props {
  lastUsedCustomers: Customer[]
  containerStyle?: StyleProp<ViewStyle>
  onPress: (id: number) => void
}

export default function CustomersLastUsedTabletView({ lastUsedCustomers, containerStyle, onPress }: Props) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()

  const renderSection = (title: string) => {
    return (
      <IM.View spacing="all">
        <IM.Text>{title}</IM.Text>
      </IM.View>
    )
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<Customer, ListSection<Customer>>) => {
    return (
      <IM.View spacing={['bottom', 'horizontal']}>
        <CustomerCard customer={item} onPress={() => onPress(item.companyId)} />
      </IM.View>
    )
  }

  const renderNoLastUsed = () => {
    return <></>
  }

  const data: ListSection<Customer>[] = useMemo(() => {
    const displayData: ListSection<Customer>[] = []

    if (lastUsedCustomers.length) {
      displayData.push({
        title: i18n.t('LAST_USED'),
        data: lastUsedCustomers,
      })
    }

    return displayData
  }, [lastUsedCustomers, i18n])

  return (
    <IM.View style={containerStyle}>
      <SectionList
        sections={data}
        keyExtractor={item => item.companyId?.toString()}
        renderItem={renderItem}
        renderSectionHeader={({ section }) => renderSection(section.title ?? '')}
        ListEmptyComponent={renderNoLastUsed}
        stickySectionHeadersEnabled={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          marginHorizontal: IMLayout.horizontalMargin,
        }}
        indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
      />
    </IM.View>
  )
}
