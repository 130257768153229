import { useLanguage } from '@infominds/react-native-components'
import lodash from 'lodash'
import { useContext, useMemo } from 'react'

import FilterContext, { FilterContextProps } from '../contexts/FilterContext'
import { SearchKey } from '../types'
import { filterUtils } from '../utils/FilterUtils'

export type FilterOptions<T> = {
  search?: string
  searchConfig?: SearchKey<T>[]
}

export default function useFilter<T extends object, TRequest extends object = object>(items?: T[], options?: FilterOptions<T>) {
  const context = useContext(FilterContext) as FilterContextProps<T>
  const { language, i18n } = useLanguage()

  const filteredItems = useMemo(() => {
    if (!items || !context) return undefined
    let filtered = filterUtils.applyFilter(lodash.cloneDeep(items ?? []), context.filters)
    if (options?.searchConfig) {
      filtered = filterUtils.filterItemsBySearch(filtered, options.search ?? '', options.searchConfig)
    }
    const activitySorted = filterUtils.applySort(filtered, context.orders)
    const activityGrouped = filterUtils.groupBy(
      activitySorted,
      context.groups.find(g => !!g.active),
      language,
      i18n
    )
    return filterUtils.groupedDataToSectionList(activityGrouped, i18n.t('WITHOUT_GROUP'))
  }, [items, context?.filters, context?.orders, context?.groups, options?.search, options?.searchConfig])

  const requestFilter = useMemo<TRequest | undefined>(() => {
    if (!context) return {} as TRequest

    const result = {}

    context.apiFilter.forEach(filter => {
      if (!filter.active || (!filter.value && !filter.values?.length)) return
      Object.assign(result, { [filter.dataKey]: filter.value ?? filter.values })
    }, {})

    context.groups
      .filter(g => g.active && !!g.options?.apiRequestValue)
      .forEach(g => {
        if (!g.apiRequestKey || !g.options?.apiRequestValue) return
        Object.assign(result, { [g.apiRequestKey]: g.options?.apiRequestValue })
      })

    context.orders
      .filter(o => o.active && !!o.options?.apiRequestValue)
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
      .forEach(o => {
        if (!o.apiRequestKey || !o.options?.apiRequestValue) return
        const key = o.apiRequestKey as keyof typeof result
        const value = result[key] ? [...result[key], o.options.apiRequestValue] : [o.options?.apiRequestValue]
        Object.assign(result, { [o.apiRequestKey]: value })
      })

    return result as TRequest
  }, [options?.search, context.apiFilter, context.groups, context.orders])

  if (context === undefined) throw new Error('useFilter() must be called inside FilterProvider')
  return { ...context, filteredItems, requestFilter }
}
