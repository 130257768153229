import { IM, useTheme } from '@infominds/react-native-components'
import React, { ChangeEvent } from 'react'
import { StyleProp, StyleSheet, View, ViewProps } from 'react-native'
import { CheckBox } from 'react-native-web'

type Props = {
  text?: string
  disabled?: boolean
  color?: string
  onChange?: (e: ChangeEvent) => void
  onValueChange?: (value: boolean | 'mixed') => void
  value?: boolean
  containerStyle?: StyleProp<ViewProps>
}

export type InternalCheckboxProps = Props & Pick<ViewProps, 'style'>

export default function Checkbox({ color, value = false, text, style, containerStyle, ...otherProps }: InternalCheckboxProps) {
  const { theme } = useTheme()

  return (
    <View style={[styles.checkboxContainer, containerStyle]}>
      <CheckBox style={[styles.checkbox, style]} color={color ?? theme.checkbox.tint} value={value} {...otherProps} />
      {!!text && <IM.Text style={styles.label}>{text}</IM.Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  checkboxContainer: {},
  checkbox: {
    alignSelf: 'center',
  },
  label: {
    margin: 8,
  },
})
