import { IM, IMLayout, useLanguage, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { ActivityTime, ActivityType } from '../../../apis/types/apiResponseTypes'
import { AppStyle } from '../../../constants/Styles'
import useLanguageSelect from '../../../hooks/useLanguageSelect'
import useUserSettings from '../../../hooks/useUserSettings'
import { EmployeeUtils } from '../../../utils/EmployeeUtils'
import TimeUtils from '../../../utils/TimeUtils'
import EmployeeDateRecord from '../../EmployeeDateRecord'
import Pressable from '../../Infominds/Pressable'
import TextTable from '../../TextTable'

export type ActivityTimeRecordProps = {
  time: ActivityTime
  activityTypes?: ActivityType[]
  onEdit?: () => void
  showType?: boolean
  showEmployee?: boolean
  enableChange?: boolean
} & Pick<ViewProps, 'spacing'>

function ActivityTimeRecord({
  time,
  activityTypes,
  onEdit,
  showType = true,
  showEmployee = true,
  enableChange,
  ...viewProps
}: ActivityTimeRecordProps) {
  const { i18n } = useLanguage()
  const { user } = useUserSettings()
  const { theme } = useTheme()
  const isUserTime = useMemo(() => EmployeeUtils.compare(user.technician, time.technician), [user.technician, time.technician])
  const editable = (isUserTime || user.can.modify.timesOfOthers) && !!enableChange
  const type = useMemo(() => activityTypes?.find(q => q.srvActivityTypeId === time.activityTimeSrvActivityTypeId), [activityTypes, time])
  const { value: typeText } = useLanguageSelect({ de: type?.descriptionDe, en: type?.descriptionEn, it: type?.descriptionIt })

  const startTime = useMemo(() => TimeUtils.formatTimeStamp(time.startTime) ?? '', [time.startTime])
  const stopTime = useMemo(() => TimeUtils.formatTimeStamp(time.stopTime) ?? '', [time.stopTime])
  const duration = useMemo(() => TimeUtils.formatTimeStamp(time.total) ?? '', [time.total])
  const showTime = !!startTime && !!stopTime

  return (
    <Pressable onPress={onEdit} disabled={!editable} {...viewProps}>
      <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]} spacing={'none'}>
        <IM.View style={[IMLayout.flex.f1, AppStyle.justifyContentCenter]}>
          {!!showEmployee && <EmployeeDateRecord employee={time.technician} info={showType ? typeText : undefined} />}
        </IM.View>
        {editable && (
          <IM.View style={AppStyle.center}>
            <IM.Icon size={18} color={theme.textPlaceholder} icon={['fal', 'pen-to-square']} />
          </IM.View>
        )}
        {showTime && (
          <IM.View>
            <TextTable
              style={[AppStyle.justifyContentStart]}
              textViewStyle={styles.textWidth}
              text={startTime}
              position={'top'}
              alignIndicator="right"
            />
            <TextTable
              style={[AppStyle.justifyContentStart]}
              textViewStyle={styles.textWidth}
              text={stopTime}
              position={'bottom'}
              alignIndicator="right"
            />
          </IM.View>
        )}
        {!!duration && !showTime && (
          <IM.View style={[styles.infoView, showTime && styles.alignEnd]}>
            <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
              <IM.Text secondary>{i18n.t('DURATION')}:</IM.Text>
              <IM.Text numberOfLines={1} primary>
                {duration}
              </IM.Text>
            </IM.View>
          </IM.View>
        )}
      </IM.View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  infoView: {
    justifyContent: 'center',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  textWidth: {
    width: 40,
  },
})

export default memo(ActivityTimeRecord)
