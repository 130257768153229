import { useTheme } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react'

import { TextInputRef, ThemeColorExpanded } from '../../types'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type PasswordInputRef = {
  hide: () => void
}

type Props = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    details?: string
    focusOnMount?: boolean
  }

const PasswordInput = memo(
  forwardRef(function PasswordInput(
    { title, details, editable, error, disableFocus, loading, focusOnMount = false, required, ...textInputProps }: Props,
    ref: ForwardedRef<PasswordInputRef>
  ) {
    useImperativeHandle(ref, () => ({
      hide: () => setVisible(false),
    }))

    const { theme } = useTheme<ThemeColorExpanded>()
    const textRef = createRef<TextInputRef>()

    const [visible, setVisible] = useState(false)

    useEffect(() => {
      focusOnMount && textRef.current?.focus()
    }, [focusOnMount])

    return (
      <>
        <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
          <BaseTextInput {...textInputProps} ref={textRef} secureTextEntry={!visible}>
            <BaseTextInput.Title title={title} details={details} required={required} />
            <BaseTextInput.RightIcon>
              {loading && <BaseTextInputSpinningIcon loading={loading} />}

              {!loading && (
                <PressableIcon
                  icon={['fal', visible ? 'eye-slash' : 'eye']}
                  size={18}
                  color={theme.textDetail}
                  disabled={!editable}
                  onPress={() => setVisible(prev => !prev)}
                />
              )}
            </BaseTextInput.RightIcon>
          </BaseTextInput>
        </BaseTextInputProvider>
      </>
    )
  })
)

export default PasswordInput
