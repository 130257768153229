import React from 'react'

import { SearchContextProps } from '../../components/screen/contexts/SearchContext'
import useSearch from '../../components/screen/hooks/useSearch'
import { ArticleSearchContext, ArticleSearchContextResult } from '../../contexts/activity/ArticleSearchContext'
import { ArticleSearchMode } from '../../types'

type ArticleSearchProps = {
  mode: ArticleSearchMode
  setMode: React.Dispatch<React.SetStateAction<ArticleSearchMode>>
} & Omit<SearchContextProps, 'mode' | 'setMode'> &
  ArticleSearchContextResult

export default function useArticleSearch(): ArticleSearchProps {
  const defaultSearch = useSearch()
  const articleContext = React.useContext(ArticleSearchContext)

  if (!articleContext) throw new Error('useArticleSearch() can only be used inside of <ArticleSearchContextProvider>')

  return {
    ...defaultSearch,
    mode: defaultSearch.mode as ArticleSearchMode,
    setMode: defaultSearch.setMode as React.Dispatch<React.SetStateAction<ArticleSearchMode>>,
    ...articleContext,
  }
}
