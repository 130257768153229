import { useLanguage } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react'
import { Keyboard } from 'react-native'

import { EmailModel } from '../../apis/types/apiResponseTypes'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { RootStackParamList } from '../../navigation/types'
import { EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import EmailModelCreateOrEditView from '../../views/anagrafics/email/EmailModelCreateOrEditView'

const EmailModelCreationScreen = (
  { controller }: ModalScreenProps<EmailModel | undefined> & { preset?: EmailModel },
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()
  const route = useRoute<RouteProp<RootStackParamList, 'EmailModelCreateOrEdit'>>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined
  const [isDuplicating, setIsDuplicating] = useState(false)
  const EmailModelToEdit = isDuplicating ? undefined : isModal ? controller.data : route?.params.emailModel

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal={isModal}
          title={EmailModelToEdit ? i18n.t('EDIT_EMAIL_MODEL') : i18n.t('ADD_EMAIL_MODEL')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            <EmailModelCreateOrEditView
              emailModel={EmailModelToEdit}
              ref={createViewRef}
              onDone={() => {
                handleGoBack(true)
              }}
              onUploadStatus={setStatus}
              setIsDuplicating={setIsDuplicating}
            />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(EmailModelCreationScreen)
