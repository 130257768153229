import { IM, IMLayout, useLanguage, useModalController } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import React, { createRef, useEffect, useState } from 'react'
import { Animated, Platform } from 'react-native'

import { TimeSlot } from '../../../apis/types/apiResponseTypes'
import TimeSlotCard from '../../../cards/activities/TimeSlotCard'
import FlashListData from '../../../components/FlashListData'
import AnimatedButton from '../../../components/Infominds/AnimatedButton'
import useSearch from '../../../components/screen/hooks/useSearch'
import { ADD_ACTIVITY_BUTTON_ID } from '../../../constants/ButtonIds'
import { AppStyle } from '../../../constants/Styles'
import { useDataProvider } from '../../../dataProvider/hooks/useDataProvider'
import useTimeSlots from '../../../hooks/activity/useTimeSlots'
import useLayout from '../../../hooks/useLayout'
import TimeSlotCreationModal from '../../../modals/anagrafics/TimeSlotCreationModal'
import { utils } from '../../../utils/utils'

const buttonAnimationValue = new Animated.Value(0)

export default function TimeSlotListView() {
  const { search } = useSearch()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation()
  const { isOnline } = useDataProvider()
  const [uuid, setUuid] = useState(utils.generateUuid())
  const createActivityModal = useModalController<TimeSlot | undefined>()

  const { timeSlots, loadingTimeSlots, reloadTimeSlots } = useTimeSlots()

  const listRef = createRef<FlashList<string | TimeSlot>>()

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [])

  useEffect(() => {
    setUuid(utils.generateUuid())
  }, [loadingTimeSlots])

  const renderItem = (elem: ListRenderItemInfo<TimeSlot | string>) => {
    const item = elem.item

    if (typeof item === 'string') {
      return <IM.Text>{item}</IM.Text>
    } else {
      return (
        <TimeSlotCard
          style={[AppStyle.card]}
          spacing={'bottom'}
          timeSlot={item}
          onPress={() => {
            isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
              ? navigation.navigate('TimeSlotCreateOrEdit', { timeSlot: item })
              : createActivityModal.show(item)
          }}
        />
      )
    }
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlashListData
        ref={listRef}
        data={timeSlots}
        loading={loadingTimeSlots}
        noDataMessage={i18n.t('NO_TIME_SLOT_FOUND')}
        renderItem={renderItem}
        isSearching={search !== ''}
        refresh={reloadTimeSlots}
        extraData={uuid}
      />
      {!loadingTimeSlots && isOnline && (
        <>
          <AnimatedButton
            id={ADD_ACTIVITY_BUTTON_ID}
            value={buttonAnimationValue}
            icon={['fal', 'plus']}
            onPress={() => {
              isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                ? navigation.navigate('TimeSlotCreateOrEdit', {})
                : createActivityModal.show(undefined)
            }}
          />
        </>
      )}
      <TimeSlotCreationModal controller={createActivityModal} />
    </IM.View>
  )
}
