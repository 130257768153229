import { IM, IMLayout, useLanguage, Utils } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import useExtendedTheme from '../hooks/useExtendedTheme'
import appUtils from '../utils/appUtils'

export type CollapsablePriceListData = {
  name: string
  value: number
  notToInvoice?: boolean
}

export type CollapsablePriceListProps = {
  title: string
  data: CollapsablePriceListData[]
  defaultOpen?: boolean
  total?: number
  disabled?: boolean
  hidePrices?: boolean
}

export default function CollapsablePriceList({ title, data, defaultOpen, total, disabled, hidePrices }: CollapsablePriceListProps) {
  const [open, setOpen] = useState(!!defaultOpen)
  const sum = useMemo(() => total ?? Utils.sum(data, d => (d.notToInvoice ? 0 : d.value)), [data, total])
  const { i18n } = useLanguage()
  const sortedData = useMemo(() => data.sort(a => (a.notToInvoice ? 1 : -1)), [data])
  const canBeOpened = useMemo(() => !disabled && !!data.length, [disabled, data])

  const { theme } = useExtendedTheme()

  return (
    <Pressable onPress={() => setOpen(prev => !prev)} disabled={!canBeOpened}>
      <IM.View>
        <IM.View style={[IMLayout.flex.row, styles.header]}>
          {canBeOpened ? (
            <IM.TextWithIcon
              icon={open ? ['fal', 'chevron-down'] : ['fal', 'chevron-right']}
              iconColor={canBeOpened ? theme.textDetail : theme.card.background}>
              {title}
            </IM.TextWithIcon>
          ) : (
            <IM.Text>{title}</IM.Text>
          )}
          {!hidePrices && <IM.Text>{appUtils.formatPrice(sum)}</IM.Text>}
        </IM.View>

        {open && (
          <IM.View style={styles.entriesView} spacing={'bottom'}>
            {sortedData.map((entry, index) => (
              <IM.View
                key={entry.name}
                style={[IMLayout.flex.row, styles.entryView, index % 2 === 0 && { backgroundColor: theme.closingSummary.input.background }]}>
                <IM.Text secondary={entry.notToInvoice}>{` ${entry.name} ${entry.notToInvoice ? ` (${i18n.t('NO_INVOICE')})` : ''}`}</IM.Text>
                {!hidePrices && <IM.Text secondary={entry.notToInvoice}>{appUtils.formatPrice(entry.notToInvoice ? 0 : entry.value)}</IM.Text>}
              </IM.View>
            ))}
          </IM.View>
        )}
      </IM.View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  header: {
    justifyContent: 'space-between',
    marginHorizontal: IMLayout.horizontalMargin * 2,
    marginVertical: IMLayout.verticalMargin,
  },
  entryView: {
    paddingLeft: 19 + IMLayout.horizontalMargin * 2,
    paddingRight: IMLayout.horizontalMargin * 2,
    justifyContent: 'space-between',
  },
  entriesView: {},
})
