import { ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { Destination } from '../../apis/types/apiResponseTypes'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ModalScreenRef } from '../../types'
import CustomerDestinationCreateOrEditView from '../../views/customer/CustomerDestinationCreateOrEditView'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<Destination | undefined>
  customerId: number
}

export default function DestinationEditOrCreateModal({ controller, customerId }: Props) {
  const ref = createRef<ModalScreenRef>()
  const { i18n } = useLanguage()

  const destination = controller?.data

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <Screen transparent>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal
          title={destination ? destination.description : i18n.t('CREATE_DESTINATION')}
          // onRightIconPress={() => {
          //   Keyboard.dismiss()
          //   createViewRef.current?.handleUpload()
          // }}
          // rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            <CustomerDestinationCreateOrEditView customerId={customerId} destination={destination} onDone={handleGoBack} onUploadStatus={setStatus} />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </BaseServiceModal>
  )
}
