import { AssetUtils } from '@infominds/react-native-media-lite'
import { Platform } from 'react-native'

import { api, apiDtoIds } from '../apis/apiCalls'
import { ActivityMedia, ActivityNote, ActivityNotePostResult, UserSettings } from '../apis/types/apiResponseTypes'
import { ActivityId, MediaPostInputResult } from '../types'
import { AppMediaUtils } from './AppMediaUtils'
import { objectUtils } from './objectUtils'

type SendNoteRequest = { noteToSend: MediaPostInputResult; activityId: ActivityId; employeeId?: number; noteDate: string }

export const noteUtils = {
  async sendNote(request: SendNoteRequest, abortController?: AbortController) {
    const { noteToSend, activityId, employeeId, noteDate } = request
    if ((!noteToSend?.text && !noteToSend.assets?.length) || !activityId) throw new Error('No Data')
    const requestParams: Partial<ActivityNote> = {
      ...activityId,
      employeeId: employeeId,
      noteDate: noteDate,
      notes: noteToSend.text,
    }
    const notePostResult = await api.activities.notes.post(requestParams, abortController)
    if (!notePostResult) throw new Error('Failed to create activityNote')
    if (!noteToSend.assets?.length) return notePostResult

    for (const asset of noteToSend.assets) {
      const mediaRequest: Partial<ActivityMedia> = {
        ...activityId,
        srvActivityNoteId: notePostResult.srvActivityNoteId,
        mediaTitle: asset.fileName,
        type: asset.type,
      }

      if (Platform.OS === 'web') {
        mediaRequest.media = asset.base64 ?? ''
        mediaRequest.mediaTitle = asset.fileName
      } else {
        const compressedAsset = await AssetUtils.compressAsset(asset)
        mediaRequest.media = await AssetUtils.assetToBase64(compressedAsset)
        mediaRequest.mediaSize = Number(await AppMediaUtils.getSize(compressedAsset))
        mediaRequest.mediaDuration = compressedAsset.type === 'video' || compressedAsset.type === 'audio' ? compressedAsset.duration : undefined
      }

      await api.activities.media.post(mediaRequest, abortController)
    }

    return notePostResult
  },
  async convertMediaToLiteAssets(media: ActivityMedia[] | undefined) {
    return Promise.all(
      media
        ?.filter(q => !!q.media && !!q.mediaTitle)
        .map(element =>
          AssetUtils.base64ToAsset(
            element.media ?? '',
            objectUtils.createUniqueIdFromKeys(element, apiDtoIds.media),
            element.mediaTitle ?? '',
            element.type ?? AppMediaUtils.getAssetTypeByFileName(element.mediaTitle),
            element.mediaDuration
          )
        ) ?? []
    )
  },
  createNoteFromPostResult(
    result: ActivityNotePostResult | undefined,
    request: SendNoteRequest,
    userSettings: UserSettings | undefined
  ): ActivityNote {
    return {
      srvActivityId: result?.srvActivityId ?? 0,
      srvActivityTypeId: result?.srvActivityTypeId ?? 0,
      srvActivityYear: result?.srvActivityYear ?? 0,
      srvActivityNoteId: result?.srvActivityNoteId ?? 0,
      employee: userSettings?.employee,
      mediaCount: request.noteToSend.assets?.length,
      noteDate: request.noteDate,
      notes: request.noteToSend.text,
    }
  },
}
