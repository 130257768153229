import { IMLayout, useTheme } from '@infominds/react-native-components'
import { LoginScreen as LibLoginScreen, OverrideThemeColors } from '@infominds/react-native-license'
import React from 'react'
import { ImageSourcePropType, Platform } from 'react-native'
import { OrientationType } from 'react-native-orientation-locker'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useRecoilValue } from 'recoil'

import icon from '../../assets/img/icon.png'
import KeyboardAwareIOS from '../../components/KeyboardAwareIOS'
import DemoMode from '../../constants/DemoMode'
import useLayout from '../../hooks/useLayout'
import { landingPageUrlAtom } from '../../utils/stateManager'

export default function LoginScreen() {
  const { colorScheme } = useTheme()
  const { isSmallDevice } = useLayout()
  const landingPage = useRecoilValue(landingPageUrlAtom)

  const colors: OverrideThemeColors = {
    dark: { background: '#1E1E1E', button: { default: { background: { disable: '#2F2F2F' }, text: { disable: '#4E4E4E' } } } },
    light: { background: '#F5F5F5', button: { default: { background: { disable: '#ECECEC' }, text: { disable: '#BBBBBB' } } } },
  }

  return (
    <SafeAreaView style={[IMLayout.flex.f1, { backgroundColor: colorScheme === 'light' ? colors.light.background : colors.dark.background }]}>
      <KeyboardAwareIOS>
        <LibLoginScreen
          demoData={{
            license: DemoMode.DEMO_LICENSE_KEY,
            username: DemoMode.DEMO_USERNAME,
            password: DemoMode.DEMO_PASSWORD,
          }}
          iconSource={icon as ImageSourcePropType}
          colorScheme={colorScheme}
          disableStatusBar
          keyboardAvoiding={Platform.OS === 'android'}
          overrideThemeColors={colors}
          onSuccess={() => {
            Platform.OS === 'web' && window.history.pushState(null, document.title, landingPage)
          }}
          codeScannerOrientationOnExit={isSmallDevice ? OrientationType.PORTRAIT : undefined}
        />
      </KeyboardAwareIOS>
    </SafeAreaView>
  )
}
