import { IM, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { Destination } from '../../apis/types/apiResponseTypes'
import DeleteButton from '../../components/DeleteButton'
import GroupSpacer from '../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import AddressInput from '../../components/input/AddressInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { EditOrCreateViewRef, UploadStatus } from '../../types'

export type DestinationCreateOrEditViewProps = {
  customerId?: number
  destination?: Destination
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

function CustomerDestinationCreateOrEditView(
  { destination, customerId, onDone, onUploadStatus }: DestinationCreateOrEditViewProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))
  const { i18n } = useLanguage()

  const editable = false

  const requiredFields = useMemo<EditDataHandlerRequiredFields<Destination>>(() => ['description', 'companyId'], [])
  const { state, editMode, createOrUpdate, deleteData, handleDataChange } = useEditDataHandler<Destination, Partial<Destination>>(
    undefined, // TODO implement when end-points exist
    undefined, // TODO implement when end-points exist
    undefined, // TODO implement when end-points exist
    {
      eventKeyCreation: EVENT_KEYS.DESTINATION_CREATED_OR_MODIFIED,
      eventKeyModification: EVENT_KEYS.DESTINATION_CREATED_OR_MODIFIED,
      eventKeyDeletion: EVENT_KEYS.DESTINATION_CREATED_OR_MODIFIED,
      onDone: onDone,
      onUploadStatus: onUploadStatus,
      editMode: !!destination,
      initialValue: { ...destination, companyId: customerId },
      showErrorAlert: true,
      requiredFields,
    }
  )

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
    editable: editable === false ? false : undefined,
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <CustomerSelector
        onChange={value => handleDataChange({ companyId: value?.companyId })}
        editable={!editMode}
        value={state?.companyId}
        required
        {...commonProps}
      />

      <GroupSpacer />

      <TextInput
        required
        title={i18n.t('DESCRIPTION')}
        placeholder={i18n.t('DESCRIPTION')}
        value={state?.description}
        onChangeText={value => handleDataChange({ description: value })}
        maxHeight={200} // maxHeight is necessary, because if the input gets to big, the user is no longer able to scroll. (especially on an iOS tablet)
        multiline
        {...commonProps}
      />
      <GroupSpacer />
      <TextInput
        title={i18n.t('PHONE')}
        placeholder={i18n.t('PHONE')}
        value={state?.phone}
        onChangeText={value => handleDataChange({ phone: value })}
        {...commonProps}
      />
      <TextInput
        title={i18n.t('EMAIL')}
        placeholder={i18n.t('EMAIL')}
        value={state?.email}
        onChangeText={value => handleDataChange({ email: value })}
        {...commonProps}
      />
      <TextInput
        title={i18n.t('FAX')}
        placeholder={i18n.t('FAX')}
        value={state?.fax}
        onChangeText={value => handleDataChange({ fax: value })}
        {...commonProps}
      />
      <GroupSpacer />
      <AddressInput address={state?.address} onValueChange={value => handleDataChange({ address: value })} {...commonProps} />

      <GroupSpacer />

      {editMode && editable && (
        <IM.View {...commonProps}>
          <DeleteButton title={i18n.t('DELETE')} onPress={deleteData} />
        </IM.View>
      )}
      <GroupSpacer />
      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(CustomerDestinationCreateOrEditView)
