import { SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { ActivityType } from '../../apis/types/apiResponseTypes'
import ActivityTypeCard from '../../cards/activities/ActivityTypeCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ListSection, ThemeColorExpanded } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: number
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: ActivityType | undefined) => void
  required?: boolean
  initialValue?: number
}

export default function ActivityTypeSelector({ id, onChange, initialValue, ...props }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [search, setSearch] = useState('')
  const { languageSelect } = useLanguageSelect()
  const [selectedId, setSelectedId] = useState(id)

  const { item: data, loadItem, loading } = useControlledLoader(api.activities.types.getList)
  const filteredData = useDataSearchFilter(data, search, ['descriptionDe', 'descriptionIt', 'descriptionEn'])

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    setSelectedId(id)
  }, [id])

  const refresh = () => loadItem()

  useEffect(() => {
    if (!initialValue) return
    const foundItem = data?.find(d => d.srvActivityTypeId === initialValue)
    if (foundItem) {
      setSelectedId(foundItem.srvActivityTypeId)
    }
  }, [data])

  function getText(item: ActivityType) {
    return languageSelect({ de: item.descriptionDe, en: item.descriptionEn, it: item.descriptionIt }) ?? ''
  }

  const render = ({ item }: SectionListRenderItemInfo<ActivityType, ListSection<ActivityType>>, onPress?: () => void) => {
    return (
      <ActivityTypeCard
        activityType={item}
        onPress={() => onPress?.()}
        style={[item.srvActivityTypeId === selectedId && { backgroundColor: theme.card.cardBackground.selected }]}
        spacing={['bottom', 'horizontal']}
        showPlanColor
      />
    )
  }

  const handleOnChange = (newValue: ActivityType | undefined) => {
    setSelectedId(newValue?.srvActivityTypeId)
    onChange(newValue)
  }

  return (
    <SelectInput
      id={apiDtoIds.activityTypes}
      data={filteredData}
      value={data?.find(elem => elem.srvActivityTypeId === selectedId)}
      loading={loading}
      refresh={refresh}
      title={i18n.t('ACTIVITY_TYPE')}
      screenTitle={i18n.t('ACTIVITY_TYPES')}
      noDataMessage={i18n.t('NO_ACTIVITY_TYPE')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => getText(item)}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      {...props}
    />
  )
}
