import { IM, IMLayout, IMStyle, useDimensions, useLanguage } from '@infominds/react-native-components'
import Color from 'color'
import React, { useMemo } from 'react'
import { ScrollView, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import { FilterContextProps } from '../../../contexts/FilterContext'
import { ApiFilterDataSorter, DataFilterConfigType, FilterDataSorter, GroupDataSorter, OrderDataSorter } from '../../../types'
import { filterUtils } from '../../../utils/FilterUtils'
import ApiFilterTag from '../../ApiFilterTag'
import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'
import SorterTag from '../../SorterTag'
import Tag from '../../Tag'

type Props = {
  enabled: boolean
  activeFilters?: FilterDataSorter<Activity>[]
  activeApiFilters?: ApiFilterDataSorter<void>[]
  activeGroups?: GroupDataSorter<Activity>[]
  activeOrders: OrderDataSorter<Activity>[]
  style?: StyleProp<ViewStyle>
  onNavigate: () => void
} & Pick<FilterContextProps<Activity>, 'changeFilterStatus' | 'changeApiFilter'>

export default function CommonFilterDetailHeader({
  enabled,
  activeFilters,
  activeApiFilters,
  activeGroups,
  activeOrders,
  style,
  onNavigate,
  changeFilterStatus,
  changeApiFilter,
}: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()

  const groupedApiFilters = useMemo(() => filterUtils.groupApiFilters(activeApiFilters), [activeApiFilters])

  return (
    <>
      <IM.View style={[styles.container, isSmallDevice && IMLayout.flex.f1, style]}>
        <ScrollView
          horizontal
          // eslint-disable-next-line react-native/no-inline-styles
          contentContainerStyle={[styles.scrollView, { marginLeft: isSmallDevice ? 4 : 0 }]}
          showsHorizontalScrollIndicator={false}>
          {(activeGroups ? activeGroups.length === 0 : true) &&
            (activeFilters ? activeFilters.length === 0 : true) &&
            (activeApiFilters ? activeApiFilters.length === 0 : true) &&
            activeOrders.length === 0 &&
            isSmallDevice && <IM.Text style={{ color: Color(IMStyle.palette.white).darken(0.3).toString() }}>{i18n.t('NO_FILTER_ACTIVE')}</IM.Text>}
          {activeGroups?.map(el => {
            return (
              <SorterTag
                key={el.id}
                sorter={el}
                isHeader
                onPress={() => changeFilterStatus(DataFilterConfigType.Group, el.id, false)}
                icon={['fal', 'layer-group']}
              />
            )
          })}
          {activeOrders.map(el => {
            return <SorterTag key={el.id} sorter={el} isHeader onPress={() => changeFilterStatus(DataFilterConfigType.Order, el.id, false)} />
          })}
          {activeFilters?.map(filter =>
            filter.values.map(el => {
              return (
                <Tag
                  key={`${filter.id}-${el.value}`}
                  active={!!el.active}
                  id={el.value}
                  name={el.value}
                  isHeader
                  onPress={() => changeFilterStatus(DataFilterConfigType.Filter, el.id, false)}
                  icon={['fal', 'filter']}
                />
              )
            })
          )}
          {groupedApiFilters?.map((filter, index) => (
            <ApiFilterTag
              key={filter.data.length ? filter.data[0].id : index}
              filter={filter}
              isHeader
              onPress={() => {
                filter.data.forEach(f => changeApiFilter(f.id, undefined))
              }}
            />
          ))}
        </ScrollView>
        <Pressable disabled={!enabled} onPress={onNavigate} style={{ marginLeft: IMLayout.horizontalMargin }}>
          <TextWithIcon alignIcon="right" icon={['fal', 'chevron-right']} color={IMStyle.palette.white} secondary={!enabled}>
            {i18n.t('FILTERS')}
          </TextWithIcon>
        </Pressable>
      </IM.View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  scrollView: {
    marginVertical: 4,
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
