import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import ActivityTravelCreateOrEditScreen from '../../screens/activity/ActivityTravelCreateOrEditScreen'
import { ModalScreenRef } from '../../types'
import { ActivityTravelCreateOrEditViewProps } from '../../views/activities/ActivityTravelCreateOrEditView'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ActivityTravelCreateOrEditViewProps>
}

export default function ActivityTravelCreateOrEditModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ActivityTravelCreateOrEditScreen ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}
