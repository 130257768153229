import { IM, IMStyle, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import TextExpandable from '../../../components/TextExpandable'

type Props = {
  description: string | undefined
  tecDescription: string | undefined
  customerDescription?: string
  spacing?: SpacingProps
  numberOfLines?: number
}

const ActivityDetailDescriptionCard = memo(function ActivityDetailDescriptionCard({
  description,
  tecDescription,
  customerDescription,
  spacing,
  numberOfLines,
}: Props) {
  const { i18n } = useLanguage()

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('DESCRIPTION')} />} spacing={spacing} style={styles.card}>
      <IM.View spacing={tecDescription ? 'bottom' : 'none'}>
        <TextExpandable numberOfLines={numberOfLines}>{description}</TextExpandable>
      </IM.View>
      {!!tecDescription && (
        <>
          <IM.Text style={styles.title}>{i18n.t('TECHNICAL_DESCRIPTION')}</IM.Text>
          <TextExpandable numberOfLines={numberOfLines}>{tecDescription}</TextExpandable>
        </>
      )}
      {!!customerDescription && (
        <IM.View spacing="top">
          <IM.Text style={styles.title}>{i18n.t('CUSTOMER_DESCRIPTION')}</IM.Text>
          <TextExpandable numberOfLines={numberOfLines}>{customerDescription}</TextExpandable>
        </IM.View>
      )}
    </IM.Card>
  )
})

export default ActivityDetailDescriptionCard

const styles = StyleSheet.create({
  card: {
    minHeight: 75,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
    fontWeight: IMStyle.typography.fontWeightBold,
  },
})
