import { IM, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleSheet } from 'react-native'

export default function AppContent({ children }: PropsWithChildren) {
  const { theme } = useTheme()

  return <IM.View style={[styles.screen, { backgroundColor: theme.background }]}>{children}</IM.View>
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: 'center',
  },
})
