import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { SearchProvider } from '../../../components/screen/contexts/SearchContext'
import DragAndDropHeader from '../../../components/screen/headers/DragAndDropHeader'
import { Screen } from '../../../components/screen/Screen'
import { DragAndDropProvider } from '../../../contexts/DragAndDropContext'
import ActivityTypeListView from '../../../views/activities/activityTypes/ActivityTypeListView'

export default function ActivityTypeListScreen() {
  const { i18n } = useLanguage()

  return (
    <DragAndDropProvider>
      <SearchProvider>
        <Screen>
          <Screen.Header goBack>
            <DragAndDropHeader title={i18n.t('ACTIVITY_TYPES')} enabled />
          </Screen.Header>
          <Screen.Content>
            <ActivityTypeListView />
          </Screen.Content>
        </Screen>
      </SearchProvider>
    </DragAndDropProvider>
  )
}
