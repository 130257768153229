import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'

import { InfiniteLoadingType, LoadingType } from '../types'
import Error, { ErrorProps } from './Error'
import ListSpacer from './ListSpacer'
import SkeletonText from './skeleton/SkeletonText'

export type LoadingStateViewProps = {
  loading: LoadingType | InfiniteLoadingType | true | undefined
  errorAddComponent?: ReactNode
  listSpacing?: boolean
} & ViewProps &
  Omit<ErrorProps, 'style'>

export default function LoadingStateView({
  loading,
  message,
  iconSize,
  children,
  errorAddComponent,
  listSpacing,
  style,
  ...viewProps
}: LoadingStateViewProps) {
  if (loading === undefined || loading === false || (loading !== 'catched' && loading !== 'reloading' && loading !== true)) return <></>

  return (
    <IM.View style={[style, !!listSpacing && { marginHorizontal: IMLayout.horizontalMargin * 2 }]} {...viewProps}>
      {!!listSpacing && <ListSpacer />}
      {loading === 'catched' && (
        <>
          <Error message={message} iconSize={iconSize} />
          {errorAddComponent}
        </>
      )}

      {(loading === 'reloading' || loading === true) && (
        <>
          {children}
          {!children && (
            <>
              <SkeletonText width="70%" />
              <SkeletonText spacing="top" width="100%" />
            </>
          )}
        </>
      )}
    </IM.View>
  )
}
