import { IM } from '@infominds/react-native-components'
import React, { createRef, useCallback, useEffect, useRef } from 'react'
import { StyleSheet, useWindowDimensions } from 'react-native'
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel'

import { apiDtoIds } from '../../apis/apiCalls'
import { Activity, ActivityState } from '../../apis/types/apiResponseTypes'
import ActivityMapCarouselCard from '../../cards/activityList/ActivityMapCarouselCard'
import CONSTANTS from '../../constants/Constants'
import useObjectUtils from '../../hooks/useObjectUtils'
import { InfiniteLoadingType, LoadingType } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import GeoLocationUtils from '../../utils/GeoLocationUtils'
import useMap from '../map/hooks/useMap'

interface Props {
  activities: Activity[]
  height: number
  loading: LoadingType | InfiniteLoadingType
  onActivityPressed: (activity: Activity) => void
  loadMoreActivities: () => void
}

const dummyActivity: Activity = {
  assignedUser: [],
  connectedActivity: [],
  title: '',
  srvActivityId: -1,
  priority: -1,
  srvActivityYear: -1,
  srvActivityTypeId: -1,
  state: ActivityState.Open,
}

export default function ActivityCarousel({ activities, height, loading, onActivityPressed, loadMoreActivities }: Props) {
  const windowDimensions = useWindowDimensions()
  const width = Math.min(windowDimensions.width, CONSTANTS.mapCarouselMaxItemWidth)
  const { selectedItem, moveMapToItem, setSelectedItem } = useMap<Activity>()
  const currentItem = useRef(activities.length ? activities[0] : null)
  const carouselRef = createRef<ICarouselInstance>()
  const objectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const snapToMarkerActive = useRef<Activity | undefined>(undefined)

  useEffect(() => {
    if (!activities.length) return
    if (!selectedItem) {
      moveMapToItemWithIndex(0)
      setSelectedItem(activities[0])
      return
    }
    const foundSelectedItem = activities.findIndex(activity => objectUtils.compare(activity, selectedItem))
    if (foundSelectedItem >= 0) {
      moveMapToItemWithIndex(foundSelectedItem)
      return
    }
    // if selected item is no longer in the list, then select the first item
    setSelectedItem(activities[0])
  }, [activities])

  useEffect(() => {
    if (!selectedItem) return
    const index = activities.findIndex(activity => activityUtils.compareActivities(activity, selectedItem))
    const foundActivity = activities.at(index)
    if (!foundActivity || activityUtils.compareActivities(foundActivity, currentItem.current)) return

    carouselRef.current?.scrollTo({ index, animated: true })
    snapToMarkerActive.current = foundActivity
  }, [selectedItem])

  const moveMapToItemWithIndex = (index: number) => {
    currentItem.current = index < activities.length && index >= 0 ? activities[index] : null
    if (currentItem.current) {
      if (snapToMarkerActive.current) {
        if (activityUtils.compareActivities(snapToMarkerActive.current, currentItem.current)) {
          snapToMarkerActive.current = undefined
        }
      } else {
        moveMapToItem(currentItem.current)
        setSelectedItem(currentItem.current)
      }
    }

    if (index === activities.length - 1) loadMoreActivities()
  }

  const renderItem = useCallback(
    ({ item, index }: { item: Activity; index: number }) => {
      return (
        <ActivityMapCarouselCard
          key={activityUtils.createId(item)}
          activity={item}
          borderless
          height={height}
          onPress={() => onActivityPressed(item)}
          index={index + 1}
          total={activities.length}
          hasCoordinates={GeoLocationUtils.isValidLocation(item.address)}
          loading={item.srvActivityId === -1 ? 'reloading' : false}
        />
      )
    },
    [height, loading, activities]
  )

  return (
    <IM.View style={styles.container}>
      <Carousel
        ref={carouselRef}
        data={loading === 'reloading' ? [dummyActivity] : activities}
        renderItem={renderItem}
        onSnapToItem={moveMapToItemWithIndex}
        height={height}
        loop
        pagingEnabled
        snapEnabled
        width={width}
        style={styles.carousel}
        enabled={activities.length > 1}
        windowSize={11}
        mode="parallax"
        modeConfig={{
          parallaxScrollingScale: 0.9,
          parallaxScrollingOffset: activities.length > 1 ? 50 : 0,
        }}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  carousel: {
    width: '100%',
    justifyContent: 'center',
  },
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
})
