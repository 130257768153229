import { Utils } from '@infominds/react-native-components'

import { Employee, Technician } from '../apis/types/apiResponseTypes'

export const EmployeeUtils = {
  getName(employee: Employee | undefined, type: 'name-surname' | 'surname-name' = 'name-surname') {
    let result: string[] = []
    if (employee?.firstName) result.push(employee.firstName)
    if (employee?.lastName) result.push(employee.lastName)

    if (type === 'surname-name') result = result.reverse()
    return result.join(' ')
  },
  compare(a: Employee | Technician | undefined | null, b: Employee | Technician | undefined | null) {
    if (!a || !b) return false
    if (a.id !== b.id) return false
    const typeA = 'technicianType' in a ? a.technicianType : 'employee'
    const typeB = 'technicianType' in b ? b.technicianType : 'employee'
    return typeA === typeB
  },
  calculateInitials(inputString: string) {
    if (!inputString) return ''

    const simplifiedString = inputString
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .split(' ')
      .filter(el => el !== '')
    let initial = ''

    if (simplifiedString.length === 0) {
      initial = ''
    } else if (simplifiedString.length === 1) {
      initial = simplifiedString[0][0].toUpperCase()
    } else {
      initial = `${simplifiedString[0][0].toUpperCase()}${simplifiedString[1][0].toUpperCase()}`
    }

    return initial
  },
  sort<T extends Employee | Technician>(employees: T[] | undefined | null, priorityUsers?: (T | number)[]) {
    if (!employees) return []
    return [...employees].sort((a, b) => sorterFun(a, b, priorityUsers))
  },
  sortTechnicians(a: Technician | undefined | null, b: Technician | undefined | null, priorityUsers?: (Technician | number)[]) {
    if (!b) return -1
    if (!a) return 1
    if (a.technicianType !== b.technicianType) return a.technicianType === 'employee' ? -1 : 1
    return sorterFun(a, b, priorityUsers)
  },
  sorterFun,
  isTechnicianType(technician: unknown): technician is Technician {
    return !!technician && typeof technician === 'object' && !!(technician as Technician).technicianType
  },
}

function sorterFun(a: Employee | Technician | undefined, b: Employee | Technician | undefined, priorityUsers?: (Employee | Employee | number)[]) {
  if (!b) return 1
  if (!a) return -1
  const aHasPriority = !!priorityUsers?.find(p => (typeof p === 'object' ? p.id === a.id : p === a.id))
  const bHasPriority = !!priorityUsers?.find(p => (typeof p === 'object' ? p.id === b.id : p === b.id))
  if (aHasPriority !== bHasPriority) {
    return aHasPriority ? -1 : 1
  }
  return Utils.compareStringsForSort(EmployeeUtils.getName(a), EmployeeUtils.getName(b))
}
