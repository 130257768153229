import { IM, IMLayout, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, RouteProp, StackActions, useNavigation, useRoute } from '@react-navigation/native'
import React, { createRef, useCallback, useEffect } from 'react'
import { Keyboard, StyleSheet } from 'react-native'

import { api } from '../../../apis/apiCalls'
import { Contract } from '../../../apis/types/apiResponseTypes'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import Separator from '../../../components/Infominds/Separator'
import LoadingStateView from '../../../components/LoadingStateView'
import { SearchProvider } from '../../../components/screen/contexts/SearchContext'
import { Screen } from '../../../components/screen/Screen'
import ScrollViewData from '../../../components/ScrollViewData'
import SkeletonScrollView from '../../../components/skeleton/SkeletonScrollView'
import { ContractCreationContextProvider } from '../../../contexts/activity/ContractCreationContext'
import { FormProvider } from '../../../contexts/FormContext'
import useContractActivities from '../../../hooks/activity/useContractActivities'
import useEditOrCreateScreenBackManager from '../../../hooks/useEditOrCreateScreenBackManager'
import useLayout from '../../../hooks/useLayout'
import ActivityCreationModal from '../../../modals/activity/ActivityCreationModal'
import { ContractDetailStackParamList } from '../../../navigation/types'
import { EditOrCreateViewRef } from '../../../types'
import { ActivityCreateOrEditViewProps } from '../../../views/activities/ActivityCreateOrEditView'
import ContractActivityListView from '../../../views/activities/contracts/ContractActivityListView'
import ContractCreateOrEditView from '../../../views/activities/contracts/ContractCreateOrEditView'

export default function ContractDetailScreen() {
  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()
  const route = useRoute<RouteProp<ContractDetailStackParamList, 'ContractDetail'>>()
  const navigation = useNavigation<NavigationProp<ContractDetailStackParamList, 'ContractDetail'>>()
  const activityCreationModal = useModalController<ActivityCreateOrEditViewProps>()

  const { isSmallDevice } = useLayout()
  const hasContractToEdit = !!route.params?.contractId
  const {
    item: contractToEdit,
    loadItem: loadContract,
    loading: loadingContract,
    setItem: setContractToEdit,
  } = useControlledLoader(api.activities.contracts.getDetail)

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
  })

  useEffect(() => {
    refreshContract()
  }, [route.params?.contractId])

  function refreshContract() {
    if (!hasContractToEdit) {
      setContractToEdit(null)
      return
    }
    loadContract({ contractId: route.params?.contractId })
  }

  const { contractActivities, loadingContractActivities, refreshContractActivities } = useContractActivities(contractToEdit)

  function onDone(contract?: Contract, deleted?: boolean) {
    if (!contract?.activityNumber || deleted) {
      handleGoBack(deleted)
      return
    }
    navigation.dispatch(
      StackActions.replace('ContractActivityCreation', {
        screen: 'ActivityCreation',
        params: { contract, numberOfACtivitiesToCreate: contract.activityNumber },
      })
    )
  }

  const ActivityList = useCallback(
    () =>
      contractToEdit ? (
        <ContractActivityListView
          contract={contractToEdit}
          uploadState={status}
          activities={contractActivities}
          loadingActivities={loadingContractActivities}
        />
      ) : (
        <></>
      ),
    [contractToEdit, status, contractActivities, loadingContractActivities]
  )

  return (
    <>
      <SearchProvider>
        <Screen>
          <Screen.Header
            goBack={() => handleGoBack()}
            backHandlerCallback={handleGoBack}
            title={contractToEdit ? i18n.t('EDIT_CONTRACT') : i18n.t('CREATE_CONTRACT')}
            onRightIconPress={() => {
              Keyboard.dismiss()
              createViewRef.current?.handleUpload()
            }}
            rightIcon={['fal', 'check']}
            disable={status === 'done' || status === 'mandatoryMissing'}
            loading={status === 'uploading'}
          />
          <Screen.Content>
            <IM.View style={[IMLayout.flex.f1, !isSmallDevice && IMLayout.flex.row]}>
              {!isSmallDevice && (
                <>
                  <IM.View style={[IMLayout.flex.f1]}>
                    {!!contractToEdit && (
                      <ScrollViewData refresh={() => refreshContractActivities()}>
                        <ActivityList />
                      </ScrollViewData>
                    )}
                  </IM.View>
                  <Separator type="vertical" />
                </>
              )}
              <IM.View style={[IMLayout.flex.f1]}>
                <FormProvider>
                  <LoadingStateView loading={loadingContract} spacing={'top'}>
                    <SkeletonScrollView elementHeight={35} spacing={'vertical'} />
                  </LoadingStateView>
                  {(!!contractToEdit || !hasContractToEdit) && (
                    <ContractCreationContextProvider
                      onDone={onDone}
                      onUploadStatus={setStatus}
                      contract={contractToEdit}
                      contractActivities={loadingContractActivities ? null : contractActivities}>
                      <ContractCreateOrEditView ref={createViewRef} style={styles.ContractCreatOrEditView}>
                        {isSmallDevice && !!contractToEdit && <ActivityList />}
                      </ContractCreateOrEditView>
                    </ContractCreationContextProvider>
                  )}
                </FormProvider>
              </IM.View>
            </IM.View>
          </Screen.Content>
        </Screen>
      </SearchProvider>
      <ActivityCreationModal controller={activityCreationModal} />
    </>
  )
}

const styles = StyleSheet.create({
  ContractCreatOrEditView: {
    flex: 1,
  },
})
