import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import InfomindsAnalytics from '@infominds/react-native-analytics'
import { IM, IMLayout } from '@infominds/react-native-components'
import { useStorageMigration } from '@infominds/react-native-license'
import { ConfigProvider } from 'antd'
import deDE from 'antd/locale/de_DE'
import itIT from 'antd/locale/it_IT'
import React, { useEffect, useState } from 'react'
import { LogBox, Platform, useWindowDimensions } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'
import { ToastProvider } from 'react-native-toast-notifications'
import { RecoilEnv, RecoilRoot } from 'recoil'

import WebConfig from './app.config'
import { languageResources } from './assets/languages/LanguageResources'
import AuthenticationWrapper from './components/AuthenticationWrapper'
import InitMeta from './components/InitMeta'
import ThemeContextWrapper from './components/ThemeContextWrapper'
import CONSTANTS from './constants/Constants'
import { firebaseConfig } from './constants/FirebaseConfigWeb'
import { AnimationProvider } from './contexts/AnimationContext'
import { EmbedProvider } from './contexts/EmbedContext'
import { GeoLocationProvider } from './contexts/GeoLocationContext'
import { UserSettingsProvider } from './contexts/UserSettingsContext'
import InitStructures from './InitStructures'
import Navigator from './navigation/Navigator'
import { utils } from './utils/utils'

import './dataProvider/InitDataProvider'

import AppNews from './components/AppNews'
import ToastContainer from './components/ToastContainer'

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = !__DEV__

export default function App() {
  const [uuid, setUuid] = useState(utils.generateUuid())
  const { fontScale } = useWindowDimensions()
  const migrated = useStorageMigration({ isExpensesApp: false })

  useEffect(() => {
    setUuid(utils.generateUuid())
  }, [fontScale])

  useEffect(() => {
    InfomindsAnalytics.init(firebaseConfig)

    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs()
    }
  }, [])

  if (migrated === undefined) return <></>
  if (migrated === false) console.error('🛑 Migration failed!')

  return (
    <EmbedProvider>
      <AnimationProvider>
        <SafeAreaProvider key={uuid} initialMetrics={initialWindowMetrics}>
          <RecoilRoot>
            <GestureHandlerRootView style={IMLayout.flex.f1}>
              <IM.LanguageProvider resources={languageResources}>
                <IM.LanguageContext.Consumer>
                  {languageContext => {
                    if (!languageContext?.initOk) return <IM.LoadingSpinner isVisible />
                    return (
                      <ThemeContextWrapper>
                        <IM.ThemeContext.Consumer>
                          {themeContext => (
                            <IM.AlertProvider useNewModalComponent useMessageQueue>
                              <AuthenticationWrapper>
                                {({ authReady, isLoggedIn }) => (
                                  <UserSettingsProvider>
                                    {({ userSettingsReady }) => (
                                      <>
                                        <InitMeta />
                                        <InitStructures />
                                        <ConfigProvider
                                          locale={languageContext.language === 'de' ? deDE : languageContext.language === 'it' ? itIT : undefined}
                                          theme={{
                                            token: {
                                              colorPrimary: themeContext?.theme.primary,
                                              colorText: themeContext?.theme.text,
                                              colorTextPlaceholder: themeContext?.theme.textPlaceholder,
                                            },
                                            components: {
                                              DatePicker: {
                                                colorBgElevated: themeContext?.colorScheme === 'dark' ? themeContext.theme.background : '#ffffff',
                                                colorIcon: themeContext?.colorScheme === 'dark' ? themeContext?.theme.text : 'rgba(0, 0, 0, 0.45)',
                                                colorTextDisabled: themeContext?.theme.textPlaceholder,
                                                controlItemBgActive: themeContext?.colorScheme === 'dark' ? '#43A973' : '#EEFAF4',
                                              },
                                            },
                                          }}>
                                          <BottomSheetModalProvider>
                                            <ToastProvider
                                              placement="bottom"
                                              duration={CONSTANTS.toastVisibilityTime}
                                              offsetBottom={IMLayout.verticalMargin * (Platform.OS === 'ios' ? 4 : 2)}
                                              offset={20}
                                              swipeEnabled={true}>
                                              <GeoLocationProvider>
                                                <AppNews>
                                                  {authReady && (isLoggedIn === false || userSettingsReady) ? (
                                                    <Navigator
                                                      isLoggedIn={isLoggedIn ?? false}
                                                      basePath={WebConfig.BASE_PATH.includes('/') ? WebConfig.BASE_PATH : '/'}
                                                    />
                                                  ) : (
                                                    <IM.View style={[IMLayout.flex.f1, { backgroundColor: themeContext?.theme.background }]}>
                                                      {Platform.OS !== 'web' && <IM.LoadingSpinner isVisible />}
                                                    </IM.View>
                                                  )}
                                                </AppNews>
                                              </GeoLocationProvider>
                                            </ToastProvider>
                                          </BottomSheetModalProvider>
                                        </ConfigProvider>
                                      </>
                                    )}
                                  </UserSettingsProvider>
                                )}
                              </AuthenticationWrapper>
                            </IM.AlertProvider>
                          )}
                        </IM.ThemeContext.Consumer>
                        <ToastContainer />
                      </ThemeContextWrapper>
                    )
                  }}
                </IM.LanguageContext.Consumer>
              </IM.LanguageProvider>
            </GestureHandlerRootView>
          </RecoilRoot>
        </SafeAreaProvider>
      </AnimationProvider>
    </EmbedProvider>
  )
}
