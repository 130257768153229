import { useModalController } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import useFilter from '../../../hooks/useFilter'
import useLayout from '../../../hooks/useLayout'
import FilterModal from '../../../modals/common/FilterModal'
import { CommonStackParamList } from '../../../navigation/types'
import { filterUtils } from '../../../utils/FilterUtils'
import useScreen from '../hooks/useScreen'
import { DetailHeaderType } from '../types'
import CommonFilterDetailHeader from './CommonFilterDetailHeader'

interface Props {
  style?: StyleProp<ViewStyle>
  enabled: boolean
}

export default function FilterDetailHeader({ style, enabled }: Props) {
  const { isSmallDevice } = useLayout()
  const { detail } = useScreen()
  const { filters, apiFilter, groups, changeFilterStatus, isAnyFilterActive, changeApiFilter } = useFilter()
  const controller = useModalController<CommonStackParamList['Filter']>()

  const activeFilters = useMemo(() => filterUtils.getActiveFilters(filters), [filters])
  const activeApiFilters = useMemo(() => filterUtils.getActiveApiFilters(apiFilter), [apiFilter])
  const activeGroups = useMemo(() => groups.filter(el => el.active && !el.options?.isDefault), [groups])

  useEffect(() => {
    // open filterModal directly if no filters are active and filter icon is pressed
    if (!isSmallDevice || !detail.open || detail.type !== DetailHeaderType.FILTER) return
    if (!isAnyFilterActive) controller.show(undefined)
  }, [detail.open])

  return (
    <>
      <CommonFilterDetailHeader
        activeFilters={activeFilters}
        activeApiFilters={activeApiFilters}
        activeGroups={activeGroups}
        activeOrders={[]}
        enabled={enabled}
        changeFilterStatus={changeFilterStatus}
        changeApiFilter={changeApiFilter}
        onNavigate={() => controller.show(undefined)}
        style={style}
      />
      <FilterModal controller={controller} />
    </>
  )
}
