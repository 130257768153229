import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { GanttChartWrapperProps } from './GanttChartWrapper'

export default (Component: React.ElementType) => {
  return (props: GanttChartWrapperProps) => (
    <DndProvider backend={HTML5Backend}>
      <Component {...props} />
    </DndProvider>
  )
}
