import { IM, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { api } from '../apis/apiCalls'
import { Mandant } from '../apis/types/apiResponseTypes'
import DropdownArrowDown from './DropdownArrowDown'
import DropdownArrowUp from './DropdownArrowUp'
import DropdownCheck from './DropdownCheck'

export type MandantSelectorRef = { close: () => void }

interface Props {
  onOpen: () => void
  onReLogIn: () => void
}

const MandantSelector = ({ onOpen, onReLogIn }: Props, ref: ForwardedRef<MandantSelectorRef>) => {
  useImperativeHandle(ref, () => ({
    close: () => setOpen(false),
  }))

  const { alert } = useAlert()
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { companies: licenseCompanies, company: selectedMandant, updateToken } = useAuthentication()

  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [mandants, setMandants] = useState<Mandant[]>([])
  const [selectedMandantId, setSelectedMandantId] = useState<number | undefined>(undefined)

  const originalMandantId = useRef<number | undefined>(undefined)

  const mandantItems = useMemo(
    () =>
      mandants.map(mandant => {
        return { label: mandant.name, value: mandant.id }
      }),
    [mandants]
  )

  useEffect(() => {
    api.mandants
      .get()
      .then(gotMandants => {
        const filteredArray = gotMandants.filter(value => licenseCompanies?.includes(value.id.toString()))
        setMandants(filteredArray)

        if (selectedMandant && filteredArray.length > 0 && filteredArray.find(el => el.id.toString() === selectedMandant)) {
          const selected = parseInt(selectedMandant, 10)
          originalMandantId.current = selected
          setSelectedMandantId(selected)
        } else {
          console.error('Empty company array loaded from db')
        }
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    if (selectedMandantId !== undefined) {
      const newMandant = mandants.find(item => item.id === selectedMandantId)

      if (newMandant) {
        if (originalMandantId.current !== newMandant.id) {
          alert(i18n.t('WARNING'), i18n.t('CHANGE_COMPANY_WARNING'), [
            {
              text: i18n.t('CANCEL'),
              style: 'default',
              onPress: () => {
                return setSelectedMandantId(originalMandantId.current)
              },
            },
            {
              text: i18n.t('RESTART_APP'),
              style: 'destructive',
              onPress: () => {
                updateToken(newMandant.id.toString())
                  .then(() => onReLogIn())
                  .catch(err => console.error('Failed mandant re-auth:', err))
              },
            },
          ])
        }
      } else {
        console.error(`Mandant ${selectedMandantId ?? ''} not found`)
        setError(true)
      }
    }
  }, [selectedMandantId])

  const onMandantOpen = useCallback(() => {
    onOpen()
  }, [])

  const common = {
    items: mandantItems,
    loading: mandantItems.length === 0,
    containerStyle: { width: 220 },
    setOpen: setOpen,
    setValue: setSelectedMandantId,
    TickIconComponent: DropdownCheck,
    ArrowDownIconComponent: DropdownArrowDown,
    ArrowUpIconComponent: DropdownArrowUp,
  }

  return (
    <IM.View>
      {selectedMandantId && error === false ? (
        <IM.Dropdown
          closeAfterSelecting
          placeholder={i18n.t('LOADING_PLACEHOLDER')}
          open={open}
          value={selectedMandantId}
          onOpen={onMandantOpen}
          listMode="SCROLLVIEW"
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ minHeight: 42 }}
          {...common}
        />
      ) : (
        <IM.Dropdown
          placeholder={error ? i18n.t('ERROR_PLACEHOLDER') : i18n.t('LOADING_PLACEHOLDER')}
          open={false}
          value={null}
          disabled
          listMode="SCROLLVIEW"
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ minHeight: 42 }}
          textStyle={{ color: theme.textDetail }}
          {...common}
        />
      )}
    </IM.View>
  )
}

export default forwardRef(MandantSelector)
