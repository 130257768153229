import { IM, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'

import { api } from '../../../apis/apiCalls'
import MainSupplierCard from '../../../cards/articles/MainSupplierCard'
import SupplierArticleCard, { SupplierArticleCardProps } from '../../../cards/articles/SupplierArticleCard'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import BaseTextInputTitle from '../../../components/input/baseTextInput/BaseTextInputTitle'
import LoadingStateView from '../../../components/LoadingStateView'

export type SupplierArticlesViewProps = { articleId: string }

export default function SupplierArticlesView({ articleId }: SupplierArticlesViewProps) {
  const { i18n } = useLanguage()
  const { item: supplierArticles, loading, loadItem: loadingSupplierArticles } = useControlledLoader(api.articles.supplierArticles.getList)

  const mainSupplierArticle = useMemo(() => supplierArticles?.find(article => !!article.isMainSupplierId), [supplierArticles])
  const secondarySupplierArticles = useMemo(() => supplierArticles?.filter(article => !article.isMainSupplierId), [supplierArticles])

  useEffect(load, [articleId])

  function load() {
    if (!articleId) return
    loadingSupplierArticles({ articleId })
  }

  const commonProps = { spacing: 'bottom' } satisfies Partial<SupplierArticleCardProps>

  return (
    <IM.View>
      <LoadingStateView loading={loading} />
      {!!mainSupplierArticle && (
        <>
          <BaseTextInputTitle title={i18n.t('MAIN_SUPPLIER')} />
          <MainSupplierCard supplierArticle={mainSupplierArticle} {...commonProps} />
        </>
      )}
      {!!secondarySupplierArticles?.length && <BaseTextInputTitle title={i18n.t('SUPPLIER_ARTICLES')} />}
      {secondarySupplierArticles?.map(article => <SupplierArticleCard key={article.supplyArticleId} supplierArticle={article} {...commonProps} />)}
    </IM.View>
  )
}
