import lodash from 'lodash'
import { useEffect } from 'react'
import { RecoilState, useRecoilState } from 'recoil'

import DataProviderUtils from '../dataProvider/utils/DataProviderUtils'
import useLayout from './useLayout'

type DataKeyType<T> = keyof T | (keyof T)[]

type Options<T> = {
  maxLastUsedShown?: number
} & (T extends { id: string } ? { id?: DataKeyType<T> } : { id: DataKeyType<T> })

interface HookReturn<T> {
  items: T[]
  setItem: (val: T) => void
}

export default function useLastUsed<T, TRequest extends object>(
  getData: (request: TRequest, abortController: AbortController) => Promise<T[]>,
  lastUsedAtom: RecoilState<T[]>,
  options: Options<T>
): HookReturn<T> {
  const { isSmallDevice } = useLayout()
  const [lastUsed, setLastUsed] = useRecoilState(lastUsedAtom)
  const maxLastUsedShown = options.maxLastUsedShown ?? (isSmallDevice ? 3 : 10)
  const idKey = (options.id ?? 'id') as keyof T

  useEffect(() => {
    validate()
  }, [])

  function update(item: T) {
    setLastUsed(prev => [item, ...prev.filter(elem => !compareElements(elem, item))].slice(0, maxLastUsedShown))
  }

  function compareElements(a: T, b: T) {
    if (options.id) {
      if (Array.isArray(options.id)) {
        for (const id of options.id) {
          if (a[id] !== b[id]) return false
        }
        return true
      }
      return a[options.id] === b[options.id]
    }

    if (DataProviderUtils.dataContainsId(a) && DataProviderUtils.dataContainsId(b)) return a.id === b.id
    return a === b
  }

  function validate() {
    const promises: Promise<void>[] = []
    const elementsToRemove: T[] = []

    lastUsed.forEach(elem => {
      const request = { [idKey]: elem[idKey] } as TRequest
      promises.push(
        getData(request, new AbortController())
          .then(found => {
            if (found.length === 0) {
              elementsToRemove.push(elem)
            }
          })
          .catch(err => console.error(`Failed validation of last used`, err))
      )
    })

    Promise.all(promises)
      .then(() => {
        if (elementsToRemove.length !== 0) {
          setLastUsed(prev => {
            let copy = lodash.cloneDeep(prev)

            elementsToRemove.forEach(toRemove => {
              copy = copy.filter(elem => !compareElements(elem, toRemove))
            })

            return copy
          })
        }
      })
      .catch(err => console.error('Last used validation failed', err))
  }

  return { items: lastUsed, setItem: update }
}
