import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import { AppStyle } from '../../../constants/Styles'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import { activityUtils } from '../../../utils/ActivityUtils'
import { ActivityListType } from '../../../views/activities/ActivityListView'

const styles = StyleSheet.create({
  padding: { paddingTop: 5 },
})

type Props = {
  type: ActivityListType
  activity?: Activity
  forceLayout?: ForceLayoutType
  size?: number
}

export default function ActivityCardIcon({ activity, forceLayout, type, size, ...props }: Props & ViewProps) {
  const { isSmallDevice } = useLayout(true, forceLayout)
  const icon = useMemo(() => activityUtils.getActivityIconByActivity(activity), [activity])

  return (
    <IM.View style={[!isSmallDevice && AppStyle.center, IMLayout.flex.row, type === 'gantt' && styles.padding]} {...props}>
      <IM.Icon icon={icon} size={size ?? type === 'gantt' ? 28 : 35} />
    </IM.View>
  )
}
