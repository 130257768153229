import { AssetUtils, LiteAsset } from '@infominds/react-native-media-lite'
import { Platform } from 'react-native'
import ReactNativeBlobUtil from 'react-native-blob-util'

import { shareUtils } from './ShareUtils'

export const AppMediaUtils = {
  getAssetTypeByFileName(fileName: string | undefined) {
    if (!fileName) return 'file'
    if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png')) return 'photo'
    if (fileName.endsWith('.mp4')) return 'video'
    if (fileName.endsWith('.mp3')) return 'audio'
    return 'file'
  },
  getSize(asset: LiteAsset) {
    return ReactNativeBlobUtil.fs
      .stat(getProcessedAssetPath(asset))
      .then(stats => {
        return stats.size
      })
      .catch(err => {
        console.error('Failed getSize()', err)
        return 0
      })
  },
  getFileName(asset: LiteAsset) {
    return asset.path?.replace('\\', '/').split('/').pop()
  },
  shareFile(asset: LiteAsset) {
    const data = Platform.OS === 'web' ? asset.base64 : asset.path
    if (!data) {
      throw new Error('No data to share')
    }
    const type = AssetUtils.getAssetMime(asset)
    return shareUtils.file(data, asset.fileName, type)
  },
}

function getProcessedAssetPath(asset: LiteAsset, path?: string) {
  const pathToProcess = path ?? asset.path ?? ''

  if (Platform.OS === 'ios') {
    return pathToProcess.replace('file://', '')
  } else {
    //Android
    let toRet = pathToProcess.replace('content://', 'file:///')

    if (asset.type === 'photo') {
      // Images coming from react-native-camera do not have the complete path
      toRet = 'file://' + toRet
    }

    return toRet
  }
}
