import { IM, IMLayout, useAlert, useEvent, useLanguage, Utils } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { ActivityClosingRequest } from '../../apis/types/apiRequestTypes'
import { Activity } from '../../apis/types/apiResponseTypes'
import ActivityClosingCard from '../../cards/activities/closing/ActivityClosingCard'
import ActivityButton from '../../components/ActivityButton'
import ActivityDeleteButton, { ActivityDeleteButtonProps } from '../../components/ActivityDeleteButton'
import ActivityPDFButton from '../../components/ActivityPDFButton'
import { DynamicLayoutView } from '../../components/DynamicLayoutView'
import GroupSpacer from '../../components/GroupSpacer'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import ActivityInput from '../../components/input/ActivityInput'
import NumberInput from '../../components/input/NumberInput'
import SignatureInput from '../../components/input/SignatureInput'
import TextInput from '../../components/input/TextInput'
import ScrollViewData from '../../components/ScrollViewData'
import { DynamicScrollView } from '../../components/SplitScrollView'
import SwitchWithText from '../../components/SwitchWithText'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { AppStyle } from '../../constants/Styles'
import { useReportActivity } from '../../contexts/report/ReportActivityContext'
import useActivityClosingSummary from '../../hooks/activity/useActivityClosingSummary'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useActivityEmailSuggestion from '../../hooks/activity/useActivityEmailSuggestion'
import useActivityTimeSummary from '../../hooks/activity/useActivityTimeSummary'
import useEmailSettings from '../../hooks/activity/useEmailSettings'
import useLayout from '../../hooks/useLayout'
import useObjectUtils from '../../hooks/useObjectUtils'
import useUserSettings from '../../hooks/useUserSettings'
import { ReportStackParamList } from '../../navigation/types'
import { ActivityClosingState, SignatureInputEvent, UploadStatus } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import { ValidationUtils } from '../../utils/ValidationUtils'
import ClosingEmailView from './closing/ClosingEmailView'

type ActivityClosingViewProps = {
  setStatus: (newStatus: UploadStatus) => void
  onActivityClosed: () => void
  onActivityAlreadyClosed: () => void
}

const signatureIds = {
  employee: 'EmployeeSignature',
  customer: 'CustomerSignature',
}

export default function ActivityClosingView({ setStatus, onActivityClosed, onActivityAlreadyClosed }: ActivityClosingViewProps) {
  const { i18n } = useLanguage()
  const { isLargeDevice } = useLayout(true)

  const { userSettings, user, isOnlyReport } = useUserSettings()
  const { detail, loadingDetail, ergoTask, isHistorical } = useActivityDetail()
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const [isDeleting, setIsDeleting] = useState(false)

  const { emailSettingsValid } = useEmailSettings()
  const { loadingEmailSuggestion, emailRequired } = useActivityEmailSuggestion(detail, suggestion => handleChange({ srvEmailAdresses: suggestion }))

  const { closingSummary, loadingClosingSummary, refreshSummary, closingSummaryError } = useActivityClosingSummary(detail)
  const { alert } = useAlert()
  const presetActivity = useMemo<Activity | undefined>(
    () => (detail ? activityUtils.createPresetForClosing(detail) : undefined),
    [detail, userSettings]
  )

  const summary = useActivityTimeSummary(detail)
  useEffect(() => {
    if (summary) handleChange({ performedWork: summary })
  }, [summary])

  const [state, setState] = useState<Partial<ActivityClosingState>>({ totalAmount: 0, discount: 0 })

  const showSignature = Platform.OS === 'android' || Platform.OS === 'ios'
  const signatureRequired = useMemo(() => showSignature && ergoTask?.paramList?.SIGNATURE === '1', [ergoTask])
  const hidePrices = useMemo(() => ergoTask?.paramList?.HIDE_APP_PRICE === '1', [ergoTask])
  const pdfPreviewButtonEnabled = useMemo(
    () => !!detail && !loadingClosingSummary && !!state && !!state && (!state.sendReport || !!state.customerEmail),
    [detail, state, loadingClosingSummary]
  )

  const pdfRequest = useMemo<Partial<ActivityClosingRequest> | false>(
    () =>
      !!detail &&
      !!state && {
        ...state,
        signatureEmployee: state.signatureEmployee ? appUtils.convertImageForRequest(state.signatureEmployee) : undefined,
        signatureCustomer: state.signatureCustomer ? appUtils.convertImageForRequest(state.signatureCustomer) : undefined,
      },
    [detail, state]
  )

  useEvent<SignatureInputEvent>({ key: EVENT_KEYS.SIGNATURE_ADDED }, data =>
    data.id === signatureIds.customer ? handleChange({ signatureCustomer: data.signature }) : handleChange({ signatureEmployee: data.signature })
  )

  const activityIsBeingClosed = useRef(false)
  const { request: closeActivity, loading: closingBusy } = useRequest(api.activities.closing, {
    onSuccess: () => {
      onActivityClosed?.()
    },
    showErrorAlert: true,
  })
  const { item: contact, loadItem: loadContract, setItem: setContact } = useControlledLoader(api.customers.contacts.getDetail)
  const contactDescription = useMemo(() => customerUtils.getContactInfo(contact)?.contactText, [contact])

  useEffect(() => {
    setContact(undefined)
    if (!detail?.contactId || !detail?.companyId) return
    loadContract({ contactId: detail.contactId, customerId: detail.companyId })
  }, [detail])

  function handleChange(data: Partial<ActivityClosingState>) {
    setStatus('waiting')
    setState(prev => ({ ...prev, ...data }))
  }

  useEffect(() => {
    if (!isHistorical || activityIsBeingClosed.current) return
    // show alert "activity already closed" and navigate out
    alert(i18n.t('WARNING'), i18n.t('ACTIVITY_ALREADY_CLOSED'), [
      {
        isPreferred: true,
        text: i18n.t('CLOSE'),
        onPress: onActivityAlreadyClosed,
        style: 'cancel',
      },
    ])
  }, [isHistorical])

  function handleCloseActivity() {
    if (!detail) return
    const doCloseActivity = () =>
      closeActivity({
        ...activityObjectUtils.createRequestObject(detail),
        ...state,
        signatureCustomer: state.signatureCustomer ? appUtils.convertImageForRequest(state.signatureCustomer) : undefined,
        signatureEmployee: state.signatureEmployee ? appUtils.convertImageForRequest(state.signatureEmployee) : undefined,
        paid: !hidePrices && state.paid,
        paidAmount: hidePrices ? undefined : state.paidAmount,
        discount: hidePrices ? undefined : state.discount,
        sendReport: emailSettingsValid === true && !!state.srvEmailAdresses,
      })
    if (state.paid && state.paidAmount === 0) {
      alert(i18n.t('WARNING'), i18n.t('PAID_ZERO_ALERT'), [
        { text: i18n.t('CONFIRM'), onPress: doCloseActivity },
        { text: i18n.t('CANCEL'), isPreferred: true, style: 'destructive' },
      ])
    } else {
      activityIsBeingClosed.current = true
      doCloseActivity()
    }
  }

  const emailOk = useMemo(
    () =>
      emailSettingsValid === false ||
      (emailSettingsValid === true &&
        ((!emailRequired && !state.srvEmailAdresses) || (!!state.srvEmailAdresses && ValidationUtils.email(state.srvEmailAdresses)))),
    [state.srvEmailAdresses, emailSettingsValid]
  )
  const closingButtonEnabled = useMemo(
    () =>
      !!detail &&
      !loadingClosingSummary &&
      !!state &&
      emailOk &&
      (!state.sendReport || !!state.customerEmail) &&
      (!signatureRequired || (!!state.signatureCustomer && !!state.signatureEmployee)) &&
      !isDeleting,
    [detail, state, loadingClosingSummary, emailOk, isDeleting]
  )

  const deletingButtonEnabled = useMemo(
    () => !!detail && !loadingClosingSummary && !!state && closingBusy !== 'reloading',
    [detail, state, loadingClosingSummary, closingBusy]
  )

  if (!detail) {
    return <ScrollViewData loading={loadingDetail} />
  }

  return (
    <>
      <DynamicScrollView mode={!isLargeDevice ? 'stacked' : 'split'} separator={'split-only'} refreshLeft={refreshSummary}>
        <DynamicScrollView.Left>
          <ActivityClosingCard
            state={state}
            setState={setState}
            summary={closingSummary}
            loading={loadingClosingSummary}
            mode={hidePrices ? 'hide-prices' : 'full'}
            error={closingSummaryError}
          />
        </DynamicScrollView.Left>
        <DynamicScrollView.Right>
          {!hidePrices && (
            <SwitchWithText
              text={i18n.t('HAS_BEEN_PAID')}
              textProps={{ primary: true }}
              value={!!state.paid}
              onValueChange={newSwitchValue =>
                handleChange({
                  paid: newSwitchValue,
                  paidAmount: newSwitchValue ? Utils.roundToPrecision(state.paidAmount ?? state.totalAmount ?? 0, 2) : undefined,
                })
              }
              style={[styles.switch, styles.paidSwitch]}
              alignChildren="right"
              spacing={'bottom'}>
              <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, AppStyle.justifyContentEnd]}>
                <IM.View style={styles.paidInputView}>
                  <NumberInput
                    selectTextOnFocus
                    value={state.paidAmount}
                    disableFastInputs
                    onValueChanged={value => handleChange({ paidAmount: value })}
                    unit={'€'}
                    decimals={2}
                  />
                </IM.View>
              </IM.View>
            </SwitchWithText>
          )}
          <SwitchWithText
            text={i18n.t('IS_WORKING')}
            textProps={{ primary: true }}
            value={!!state.isWorking}
            onValueChange={value => handleChange({ isWorking: value })}
            style={[styles.switch]}
            spacing={hidePrices ? 'bottom' : 'vertical'}
          />
          <TextInput
            value={state.performedWork}
            onChangeText={value => handleChange({ performedWork: value })}
            multiline
            title={i18n.t('WORK_NOTES')}
            maxHeight={200} // maxHeight is necessary, because if the input gets to big, the user is no longer able to scroll. (especially on an iOS tablet)
            maxLength={4000}
            showClearTextIcon
          />

          {showSignature && (
            <IM.View spacing={'top'}>
              <SignatureInput
                required={signatureRequired}
                signature={state.signatureEmployee}
                setSignature={value => handleChange({ signatureEmployee: value })}
                id={signatureIds.employee}
                title={i18n.t('SIGNATURE_EMPLOYEE')}
                subTitle={EmployeeUtils.getName(userSettings?.employee)}
              />
              <SignatureInput
                required={signatureRequired}
                signature={state.signatureCustomer}
                setSignature={value => handleChange({ signatureCustomer: value })}
                id={signatureIds.customer}
                title={i18n.t('SIGNATURE_CUSTOMER')}
                subTitle={contactDescription}
              />
            </IM.View>
          )}

          <TextInput
            value={state.customerNote}
            onChangeText={value => handleChange({ customerNote: value })}
            multiline
            title={i18n.t('CUSTOMER_NOTE')}
            maxHeight={200} // maxHeight is necessary, because if the input gets to big, the user is no longer able to scroll. (especially on an iOS tablet)
            spacing="top"
            maxLength={4000}
          />

          {user.can.add.activities && !isOnlyReport && (
            <ActivityInput
              activity={state.followUpActivity}
              preSetActivity={presetActivity}
              onChange={value => handleChange({ followUpActivity: value })}
              spacing={'top'}
              title={i18n.t('CREATE_FOLLOWUP_ACTIVITY')}
              placeholder={i18n.t('NO_ACTIVITY')}
            />
          )}

          <ClosingEmailView state={state} handleChange={handleChange} emailRequired={emailRequired} loadingEmailSuggestion={loadingEmailSuggestion} />

          <GroupSpacer />
          <DynamicLayoutView>
            <DynamicLayoutView.Small>
              <IM.View style={[!isOnlyReport && IMLayout.flex.row, styles.buttons]} spacing="bottom">
                <IM.View style={[IMLayout.flex.row, styles.buttons]}>
                  {pdfRequest && (
                    <ActivityPDFButton
                      activity={detail}
                      closingRequest={pdfRequest}
                      disabled={!pdfPreviewButtonEnabled}
                      disabledShare={!closingButtonEnabled}
                    />
                  )}
                  {isOnlyReport && (
                    <ReportDeleteActivityButton activity={detail} onDeletingStatusChanged={setIsDeleting} disabled={!deletingButtonEnabled} />
                  )}
                </IM.View>
                <IM.View
                  style={[IMLayout.flex.row, AppStyle.justifyContentEnd, { gap: IMLayout.horizontalMargin }]}
                  spacing={isOnlyReport ? 'top' : 'none'}>
                  <ActivityButton
                    title={i18n.t('CONCLUDE_ACTIVITY')}
                    icon={['fal', 'paper-plane']}
                    onPress={handleCloseActivity}
                    disabled={!closingButtonEnabled || isHistorical}
                    loading={closingBusy === 'reloading'}
                  />
                </IM.View>
              </IM.View>
            </DynamicLayoutView.Small>
            <DynamicLayoutView.Large>
              <IM.View style={[IMLayout.flex.row, styles.buttons]} spacing="bottom">
                <IM.View style={[IMLayout.flex.row]}>
                  {pdfRequest && (
                    <ActivityPDFButton
                      activity={detail}
                      closingRequest={pdfRequest}
                      disabled={!pdfPreviewButtonEnabled}
                      disabledShare={!closingButtonEnabled}
                    />
                  )}
                </IM.View>
                <IM.View
                  style={[IMLayout.flex.row, AppStyle.justifyContentEnd, { gap: IMLayout.horizontalMargin }]}
                  spacing={isOnlyReport ? 'top' : 'none'}>
                  {isOnlyReport && (
                    <ReportDeleteActivityButton activity={detail} onDeletingStatusChanged={setIsDeleting} disabled={!deletingButtonEnabled} />
                  )}
                  <ActivityButton
                    title={i18n.t('CONCLUDE_ACTIVITY')}
                    icon={['fal', 'paper-plane']}
                    onPress={handleCloseActivity}
                    disabled={!closingButtonEnabled || isHistorical}
                    loading={closingBusy === 'reloading'}
                  />
                </IM.View>
              </IM.View>
            </DynamicLayoutView.Large>
          </DynamicLayoutView>
          <GroupSpacer />
          <GroupSpacer />
        </DynamicScrollView.Right>
      </DynamicScrollView>
    </>
  )
}

type ReportDeleteActivityButtonProps = Pick<ActivityDeleteButtonProps, 'activity' | 'onDeletingStatusChanged' | 'disabled'>
function ReportDeleteActivityButton(props: ReportDeleteActivityButtonProps) {
  const { setActivity } = useReportActivity()
  const navigation = useNavigation<NavigationProp<ReportStackParamList>>()

  function onDeleted() {
    setActivity(undefined)
    navigation.navigate('ReportScreen')
  }

  return <ActivityDeleteButton {...props} forceDeletion onDeleted={onDeleted} />
}

const styles = StyleSheet.create({
  switch: {},
  paidSwitch: {},
  buttons: {
    justifyContent: 'space-between',
  },
  paidInputView: {
    width: 150,
  },
})
