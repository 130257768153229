import { useInfomindsAnalyticsNavigation } from '@infominds/react-native-analytics'
import { useLanguage, useTheme } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DarkTheme, DefaultTheme, InitialState, NavigationState, NavigationContainer as ReactNavigationContainer } from '@react-navigation/native'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import { useSetRecoilState } from 'recoil'

import { STORAGE_KEYS } from '../constants/Keys'
import useEmbed from '../hooks/useEmbed'
import useMenu from '../hooks/useMenu'
import appUtils from '../utils/appUtils'
import navigationUtils from '../utils/navigationUtils'
import { landingPageUrlAtom } from '../utils/stateManager'
import { utils } from '../utils/utils'
import { GetNavigationConfig } from './NavigationRouteConfig'
import { RootStackParamList } from './types'

interface Props extends PropsWithChildren {
  basePath: string
}

export default function NavigationContainer({ children, basePath }: Props) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const { setEmbed } = useEmbed()
  const { onRouteChange } = useMenu()
  const setLandingPage = useSetRecoilState(landingPageUrlAtom)

  const previousRouteStack = useRef<string>()

  const [isNavigationReady, setNavigationReady] = useState(!__DEV__)
  const [initialState, setInitialState] = useState<InitialState | undefined>()
  const [key, setKey] = useState(utils.generateUuid())

  const { navigationRef, onReady, onStateChange: onStateChangeAnalytics } = useInfomindsAnalyticsNavigation<keyof RootStackParamList>()

  const enableNavigationState = Platform.OS !== 'web' && __DEV__ === true

  useEffect(() => {
    Platform.OS === 'web' && setLandingPage(window.location.href)
  }, [])

  useEffect(() => {
    Platform.OS === 'web' && console.info(`base path: ${basePath}`)
  }, [basePath])

  useEffect(() => {
    if (!isNavigationReady || enableNavigationState) {
      AsyncStorage.getItem(STORAGE_KEYS.NAVIGATION_STATE)
        .then(savedStateString => {
          const state = savedStateString ? (JSON.parse(savedStateString) as InitialState) : undefined
          setInitialState(state)
        })
        .catch(console.error)
        .finally(() => setNavigationReady(true))
    }
  }, [isNavigationReady])

  useEffect(() => {
    if (navigationRef.current) {
      const currentRoute = navigationRef.getCurrentRoute()

      if (currentRoute) {
        previousRouteStack.current = navigationUtils.findStack(navigationRef.getRootState(), currentRoute.key)
      }
    }
  }, [key, navigationRef])

  const onStateChange = useCallback((state: NavigationState | undefined) => {
    enableNavigationState &&
      AsyncStorage.setItem(STORAGE_KEYS.NAVIGATION_STATE, JSON.stringify(state)).catch(err => console.error('Can not save state', err))

    onStateChangeAnalytics()

    const currentRoute = navigationRef.getCurrentRoute()

    setEmbed(currentRoute?.name?.toLowerCase().includes('embed') ?? false)

    if (currentRoute) {
      onRouteChange(previousRouteStack.current)
      previousRouteStack.current = navigationUtils.findStack(navigationRef.getRootState(), currentRoute.key)
    }
  }, [])

  return (
    <>
      {isNavigationReady && (
        <ReactNavigationContainer
          ref={navigationRef}
          onReady={() => {
            setKey(utils.generateUuid())
            onReady()
          }}
          initialState={enableNavigationState ? initialState : undefined}
          onStateChange={onStateChange}
          documentTitle={{
            formatter: (_options, route) => appUtils.getDocumentTitle(route, i18n),
          }}
          linking={{
            enabled: true,
            prefixes: [],
            config: GetNavigationConfig(basePath),
          }}
          theme={colorScheme === 'light' ? DefaultTheme : DarkTheme}>
          {children}
        </ReactNavigationContainer>
      )}
    </>
  )
}
