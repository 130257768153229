import { useDimensions, useModalController, useTheme } from '@infominds/react-native-components'
import React, { useCallback, useState } from 'react'
import { Alert, Dimensions, ScrollView, StyleSheet, View } from 'react-native'
import YoutubePlayer, { PLAYER_STATES } from 'react-native-youtube-iframe'

import { Screen } from '../components/screen/Screen'
import BaseServiceModal from '../modals/BaseServiceModal'
import PressableIcon from './Infominds/PressableIcon'

const Spacer = () => {
  return <View style={styles.spacer} />
}

const styles = StyleSheet.create({
  spacer: { backgroundColor: '#000000', height: 5 },
})

export default function InfoVideo() {
  const { theme } = useTheme()
  const { isSmallDevice } = useDimensions()

  const playerHeight = isSmallDevice ? (Dimensions.get('window').width / 16) * 9 : 367

  const [playing, setPlaying] = useState(false)

  const infoVideoModal = useModalController()

  const onStateChange = useCallback((state: PLAYER_STATES) => {
    if (state === 'ended') {
      setPlaying(false)
      Alert.alert('video has finished playing!')
    }
  }, [])

  return (
    <View>
      <PressableIcon
        icon={['fas', 'clapperboard-play']}
        color={'white'}
        size={16}
        onPress={() => {
          infoVideoModal.show()
        }}
      />

      <BaseServiceModal controller={infoVideoModal}>
        <Screen transparent>
          <Screen.Header goBack={() => infoVideoModal.close()} isModal title={'Info'} />
          <Screen.Content>
            <ScrollView style={{ backgroundColor: theme.background }}>
              <YoutubePlayer height={playerHeight} play={playing} videoId={'nAZTFoB-3Jo'} onChangeState={onStateChange} />
              <Spacer />
              <YoutubePlayer height={playerHeight} play={playing} videoId={'TQFkEYY-rIE'} onChangeState={onStateChange} />
              <Spacer />
              <YoutubePlayer height={playerHeight} play={playing} videoId={'p8C0Y5sk8ak'} onChangeState={onStateChange} />
              {/* <Button title={playing ? 'pause' : 'play'} onPress={togglePlaying} /> */}
            </ScrollView>
          </Screen.Content>
        </Screen>
      </BaseServiceModal>
    </View>
  )
}
