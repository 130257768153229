import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { api } from '../../../apis/apiCalls'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import CustomerContactCard from '../../customer/CustomerContactCard'

interface Props {
  error?: ConstrainBoolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  changeable?: boolean
}

export default function ActivityDetailConstructionSiteContactIdCard({ spacing, style, changeable }: Props) {
  const { i18n } = useLanguage()
  const { detail } = useActivityDetail()

  const {
    item: contacts,
    loadItem: loadContacts,
    loading: loadingContacts,
    setItem: setContacts,
  } = useControlledLoader(api.constructionsSites.contacts.getList)
  const contact = useMemo(
    () => contacts?.find(c => c.objectId === detail?.objectId && c.contactId === detail?.objectContactId),
    [contacts, detail?.objectContactId]
  )

  useEffect(() => {
    load()
  }, [detail?.objectContactId, detail?.objectId])

  function load() {
    if (!detail?.objectId || !detail.objectContactId) {
      setContacts([])
      return
    }
    loadContacts({ objectId: detail.objectId })
  }

  return (
    <CustomerContactCard
      contact={contact}
      leftText={i18n.t('CONSTRUCTION_SITE_CONTACT')}
      disabled={!contacts || !changeable}
      enableChange={changeable}
      loading={loadingContacts}
      style={style}
      spacing={spacing}
    />
  )
}
