import { useEffect, useState } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import useObjectUtils from '../useObjectUtils'

export default function useActivityEmailSuggestion(activity: Activity | null | undefined, onSuggestionFound?: (suggestion: string) => void) {
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const [loading, setLoading] = useState(false)
  const [suggestion, setSuggestion] = useState<string | null | undefined>(null)
  const [emailRequired, setEmailRequired] = useState(false)

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    findSuggestion(abortController)
      .then(foundSuggestion => {
        setSuggestion(foundSuggestion)
        if (!foundSuggestion) return
        onSuggestionFound?.(foundSuggestion)
      })
      .catch(console.error)
      .finally(() => setLoading(false))

    return () => abortController.abort()
  }, [activity])

  async function findSuggestion(abortController: AbortController) {
    if (!activity) return null
    // Priority list:
    // 1. Email returned from sendPdf endpoint
    // 2. Customer-Contact email
    // 3. Object-Contact email
    // 4. Customer-Email
    // 5. Object-Email
    const emailFromCustomerSettings = await api.activities.email.getSendPdfRequiredEmail(
      activityObjectUtils.createRequestObject(activity),
      abortController
    )
    if (emailFromCustomerSettings) {
      setEmailRequired(true)
      return emailFromCustomerSettings
    } else {
      setEmailRequired(false)
    }
    if (!activity.companyId) return
    if (activity.contactId) {
      const contact = await api.customers.contacts.getDetail({ contactId: activity.contactId, customerId: activity.companyId }, abortController)
      if (contact?.email) {
        return contact.email
      }
    }
    if (activity.objectId && activity.objectContactId) {
      const contacts = await api.constructionsSites.contacts.getList({ objectId: activity.objectId }, abortController)
      const contact = contacts.find(c => c.contactId === activity.objectContactId)
      if (contact?.email) {
        return contact.email
      }
    }
    if (activity.companyId) {
      const customer = await api.customers.getDetail({ customerId: activity.companyId }, abortController)
      if (customer?.email) {
        return customer.email
      }
    }
    if (activity.objectId) {
      const object = await api.constructionsSites.getDetail({ objectId: activity.objectId }, abortController)
      if (object?.email) {
        return object.email
      }
    }
    return null
  }

  return {
    loadingEmailSuggestion: loading,
    emailSuggestion: suggestion,
    emailRequired,
  }
}
