import { IM, IMLayout, useLanguage, ViewProps } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'

import { api } from '../../../apis/apiCalls'
import ApiDeleteButton from '../../../components/ApiDeleteButton'
import GroupSpacer from '../../../components/GroupSpacer'
import DateInput from '../../../components/input/DateInput'
import MultiLangTextInput from '../../../components/input/MultiLangTextInput'
import TextInput from '../../../components/input/TextInput'
import ScrollViewForm from '../../../components/ScrollViewForm'
import ConstructionSiteSelector from '../../../components/selectors/ConstructionSiteSelector'
import { ContractDeviceSelector } from '../../../components/selectors/ContractDeviceSelector'
import CustomerSelector from '../../../components/selectors/CustomerSelector'
import DestinationSelector from '../../../components/selectors/DestinationSelector'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { useContractCreation } from '../../../contexts/activity/ContractCreationContext'
import { EditOrCreateViewRef } from '../../../types'
import ContractLotsSelectionView from './ContractLotsSelectionView'

type Props = Pick<ViewProps, 'style' | 'spacing' | 'children'>

function ContractCreateOrEditView({ children, ...viewProps }: Props, ref: ForwardedRef<EditOrCreateViewRef>) {
  const { i18n } = useLanguage()

  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))

  const { state, handleDataChange, createOrUpdate, clearObjectLots, objectLots, contract, editMode, onDone, errorOnFields, contractActivities } =
    useContractCreation()

  const commonProps = {
    spacing: 'vertical',
  } satisfies Partial<ViewProps>

  return (
    <IM.View {...viewProps}>
      <ScrollViewForm disableHideKeyboardOnScroll>
        <MultiLangTextInput
          required
          title={i18n.t('TITLE')}
          placeholder={i18n.t('TITLE')}
          values={{ de: state?.titleDe, en: state?.titleEn, it: state?.titleIt }}
          onChangeText={values => handleDataChange({ titleEn: values.en, titleDe: values.de, titleIt: values.it })}
          {...commonProps}
        />
        <MultiLangTextInput
          title={i18n.t('NOTE')}
          placeholder={i18n.t('NOTE')}
          values={{ de: state?.noteDe, en: state?.noteEn, it: state?.noteIt }}
          onChangeText={values => handleDataChange({ noteEn: values.en, noteDe: values.de, noteIt: values.it })}
          multiline
          {...commonProps}
        />
        <IM.View style={[IMLayout.flex.row, { gap: 3 * IMLayout.horizontalMargin }]}>
          <DateInput
            required
            containerStyle={IMLayout.flex.f1}
            title={i18n.t('DATE_FROM')}
            value={state?.startDate}
            onChangeDate={value => handleDataChange({ startDate: value?.toISOString() })}
            editable={!editMode || contractActivities?.length === 0}
            {...commonProps}
          />

          <DateInput
            required
            containerStyle={IMLayout.flex.f1}
            title={i18n.t('DATE_TO')}
            value={state?.expiredDate}
            onChangeDate={value => handleDataChange({ expiredDate: value?.toISOString() })}
            minimumDate={state?.startDate}
            error={errorOnFields.includes('expiredDate')}
            editable={!editMode || contractActivities?.length === 0}
            {...commonProps}
          />
        </IM.View>

        <TextInput
          title={i18n.t('DOCUMENT_NR_ERGO')}
          value={state?.ergoDocNr}
          onChangeText={value => handleDataChange({ ergoDocNr: value })}
          {...commonProps}
        />
        <DateInput
          title={i18n.t('DOCUMENT_DATE_ERGO')}
          value={state?.ergoDocDate}
          onChangeDate={value => handleDataChange({ ergoDocDate: value?.toISOString() })}
          {...commonProps}
        />

        <CustomerSelector
          required
          value={state?.companyId}
          onChange={value => {
            if (state?.companyId === value?.companyId) return
            handleDataChange({ companyId: value?.companyId, destinationId: undefined, objectId: undefined, device: undefined })
            clearObjectLots()
          }}
          editable={!editMode}
          {...commonProps}
        />

        <ConstructionSiteSelector
          customerId={state?.companyId}
          editable={!editMode}
          value={state?.objectId}
          onChange={value => {
            handleDataChange({
              objectId: value?.objectId,
              device: undefined,
              companyId: value?.companyId ?? state?.companyId,
            })
            clearObjectLots(value?.objectId)
          }}
          {...commonProps}
        />
        {!!state?.objectId && !state.device && <ContractLotsSelectionView />}
        {(!state?.objectId || !objectLots.find(ol => !!ol.lots.length)) && <ContractDeviceSelector />}
        {state?.companyId && (
          <DestinationSelector
            customerId={state?.companyId}
            editable
            onChange={value => handleDataChange({ destinationId: value?.destinationId })}
            disableFastInput
            value={state?.destinationId}
            {...commonProps}
          />
        )}
        {editMode && !!contract && contractActivities?.length === 0 && (
          <ApiDeleteButton
            onDeleted={onDone}
            data={contract}
            deleteAlertMessage={i18n.t('DELETE_CONTRACT_ALERT')}
            deleteAlertTitle={i18n.t('DELETE_CONTRACT')}
            deleteRequest={api.activities.contracts.delete}
            eventKey={EVENT_KEYS.UPDATED_CONTRACTS}
            {...commonProps}
          />
        )}
        <GroupSpacer />
        {children}
      </ScrollViewForm>
    </IM.View>
  )
}

export default forwardRef(ContractCreateOrEditView)
