import { IM, IMLayout, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import type { Activity } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import DynamicView from '../../components/Infominds/DynamicView'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import useLayout, { ForceLayoutType } from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import GeoLocationUtils from '../../utils/GeoLocationUtils'
import { ActivityListType } from '../../views/activities/ActivityListView'
import ActivityCardDescription from './components/ActivityCardDescription'
import ActivityCardDetailInfo from './components/ActivityCardDetailInfo'
import ActivityCardHeader from './components/ActivityCardHeader'
import ActivityCardIcon from './components/ActivityCardIcon'
import ActivityCardInfo from './components/ActivityCardInfo'
import ActivityCardTimeInfo from './components/ActivityCardTimeInfo'

export type ActivityListCardProps = {
  activity: Activity
  spacing?: SpacingProps
  borderless?: boolean
  limitDescriptionToLines?: number
  forceLayout?: ForceLayoutType
  isSelected?: boolean
  style?: StyleProp<ViewStyle>
  type: ActivityListType
  showLocationMissingIndicator?: boolean
  onPress?: () => void
  onLongPress?: () => void
  onTagPress?: (start: string) => void
  assignable?: boolean
  deleted?: boolean
  showPlanTag?: boolean
  disabled?: boolean
  showEmployee?: boolean
}

const ActivityListCard = memo(function ActivityListCard({
  activity,
  spacing,
  borderless,
  style,
  limitDescriptionToLines,
  forceLayout,
  isSelected,
  type,
  showLocationMissingIndicator,
  assignable = true,
  deleted,
  showPlanTag,
  disabled,
  onPress,
  onLongPress,
  onTagPress,
  showEmployee = true,
}: ActivityListCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()
  const { user } = useUserSettings()
  const { isSmallDevice, isMediumDevice, isLargeDevice, layoutSelect } = useLayout(true, forceLayout)
  const headerColor = useMemo(() => activityUtils.getCardHeadColorByActivity(activity, theme), [activity.priority])
  const showMissingCoordinates = useMemo(
    () => !!showLocationMissingIndicator && !GeoLocationUtils.isValidLocation(activity.address),
    [activity, showLocationMissingIndicator]
  )

  const showTimeInfo = useMemo(
    () =>
      !!activity.expireDate ||
      !!activity.closedDate ||
      !!activity.estimatedTime ||
      !!activityUtils.formatNextActivityPlanDate(activity, language, user.isTechnician && user.technician ? user.technician : undefined)?.length,
    [activity, user]
  )
  const descriptionMaxLines = limitDescriptionToLines ?? isLargeDevice ? 4 : 3

  return (
    <IM.Card
      head={borderless ? undefined : <CardLeftTitle color={headerColor} />}
      disabled={disabled}
      onPress={onPress}
      onLongPress={onLongPress}
      borderless={borderless}
      spacing={spacing}
      style={[isSelected && { backgroundColor: theme.item.selected }, { borderRadius: IMLayout.borderRadius }, style]}>
      <DynamicView
        flexLimitSmallDevice={2}
        flexLimitLargeDevice={12}
        separatorVertical="largeDeviceOnly"
        separatorHorizontal="smallDeviceOnly"
        forceLayout={forceLayout}>
        {!isSmallDevice && <ActivityCardIcon activity={activity} spacing="right" type={type} forceLayout={forceLayout} />}
        {isSmallDevice && (
          <ActivityCardHeader
            style={IMLayout.flex.f1}
            activity={activity}
            type={type}
            showIcon
            forceLayout={forceLayout}
            showEmployee={showEmployee}
            assignable={!!assignable}
            showPlanTag={showPlanTag}
            onTagPress={onTagPress}
          />
        )}
        {isSmallDevice && showTimeInfo && <ActivityCardTimeInfo style={IMLayout.flex.f6} activity={activity} forceLayout={forceLayout} />}
        <DynamicView style={IMLayout.flex.f6} separatorVertical="largeDeviceOnly" separatorHorizontal="smallDeviceOnly" forceLayout={forceLayout}>
          {!isSmallDevice && (
            <ActivityCardHeader
              style={layoutSelect({ small: IMLayout.flex.f6, large: { width: 200 } })}
              activity={activity}
              type={type}
              assignable={!!assignable}
              forceLayout={forceLayout}
            />
          )}

          {(!!activity.customerDescription || !!activity.constructionSiteDescription || !!activity.destinationDescription) && (
            <ActivityCardDetailInfo activity={activity} style={IMLayout.flex.f3} forceLayout={forceLayout} />
          )}

          {!!activity.description && (
            <ActivityCardDescription
              style={layoutSelect({ medium: IMLayout.flex.f12, large: IMLayout.flex.f3, small: IMLayout.flex.f6 })}
              activity={activity}
              spacing={isMediumDevice ? 'top' : 'none'}
              numberOfLines={descriptionMaxLines}
            />
          )}
        </DynamicView>

        {!isSmallDevice && (
          <ActivityCardInfo
            activity={activity}
            type={type}
            showIcon
            showEmployee={showEmployee}
            assignable={!!assignable}
            forceLayout={forceLayout}
          />
        )}
        {showMissingCoordinates && (
          <IM.View style={styles.message}>
            <TextWithIcon icon={['fal', 'location-dot-slash']} iconColor={theme.general.error} secondary>
              {i18n.t('MISSING_COORDINATES')}
            </TextWithIcon>
          </IM.View>
        )}
      </DynamicView>
      {!!deleted && (
        <IM.View style={[{ backgroundColor: theme.error }, styles.deleteView, IMLayout.shadow]}>
          <IM.Icon icon={['fal', 'trash']} size={18} color={'#fff'} />
        </IM.View>
      )}
    </IM.Card>
  )
})

export default ActivityListCard

const styles = StyleSheet.create({
  deleteView: {
    position: 'absolute',
    top: -5,
    right: -5,
    borderRadius: 100,
    padding: 10,
  },
  message: {
    flex: 6,
    justifyContent: 'flex-start',
  },
})
