import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useEffect, useRef, useState } from 'react'

import { LoadingType } from '../../../types'
import appUtils from '../../../utils/appUtils'

type Options<TResponse, TRequest> = {
  showErrorAlert?: boolean
  onSuccess?: (response: TResponse, request: TRequest) => void
  onError?: (error: unknown) => void
  onAborted?: () => void
}

interface Result<TRequest, TResponse> {
  response: TResponse | undefined
  request: (request: TRequest) => void
  loading: LoadingType
  error: string
}

export default function useRequest<TRequest extends object, TResponse>(
  requestFunc: (request: TRequest, abortController: AbortController) => Promise<TResponse>,
  options?: Options<TResponse, TRequest>
): Result<TRequest, TResponse> {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const [response, setResponse] = useState<TResponse>()
  const [loading, setLoading] = useState<LoadingType>(false)
  const request = useRef<TRequest>({} as TRequest)
  const errorMessage = useRef('')

  useEffect(() => {
    const abortController = new AbortController()
    loading === 'reloading' && loader(abortController)
    return () => abortController.abort()
  }, [loading])

  function loader(abortController: AbortController) {
    errorMessage.current = ''
    const requestCopy = { ...request.current }
    requestFunc(requestCopy, abortController)
      .then(result => {
        setResponse(result)
        setLoading(false)
        options?.onSuccess?.(result, requestCopy)
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          console.debug(`Aborted useRequest`)
          options?.onAborted?.()
          setLoading('aborted')
          return
        }
        options?.onError?.(err)
        setLoading('catched')
        errorMessage.current = appUtils.getBackendErrorMessage(err)

        if (options?.showErrorAlert) {
          alert.alert(i18n.t('API_CATCH_TITLE'), errorMessage.current)
        }
      })

    return
  }

  function load(requestData: TRequest) {
    request.current = requestData
    setLoading('reloading')
  }

  return { response, request: load, loading, error: errorMessage.current }
}
