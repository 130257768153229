import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api } from '../../apis/apiCalls'
import { TimeAttendance } from '../../apis/types/apiResponseTypes'
import TimeAttendanceCard from '../../cards/activities/TimeAttendanceCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: number
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: TimeAttendance | undefined) => void
  required?: boolean
  initialValue?: number
}

export default function TimeAttendanceSelector({ id, onChange, initialValue, ...props }: Props) {
  const { i18n } = useLanguage()

  const [search, setSearch] = useState('')
  const [selectedId, setSelectedId] = useState(id)

  const { item: data, loadItem, loading } = useControlledLoader(api.timeAttendance.getList)
  const filteredData = useDataSearchFilter(data, search, ['description'])

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadItem()

  useEffect(() => {
    if (!initialValue) return
    const foundItem = data?.find(d => d.id === initialValue)
    if (foundItem) {
      setSelectedId(foundItem.id)
    }
  }, [data])

  const render = ({ item }: SectionListRenderItemInfo<TimeAttendance, ListSection<TimeAttendance>>, onPress?: () => void) => {
    return (
      <TimeAttendanceCard timeAttendance={item} onPress={() => onPress?.()} selected={item.id === selectedId} spacing={['bottom', 'horizontal']} />
    )
  }

  const handleOnChange = (newValue: TimeAttendance | undefined) => {
    setSelectedId(newValue?.id)
    onChange(newValue)
  }

  return (
    <SelectInput
      id={'id'}
      data={filteredData}
      value={data?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={refresh}
      title={i18n.t('TIME_ATTENDANCE')}
      screenTitle={i18n.t('TIME_ATTENDANCES')}
      noDataMessage={i18n.t('NO_TIME_ATTENDANCE_TYPE')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.description ?? item.id?.toString() ?? ''}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      {...props}
    />
  )
}
