import { useLanguage, useToastMessage } from '@infominds/react-native-components'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ClipboardUtils } from '../utils/ClipboardUtils'

export default function useClipboard() {
  const { i18n } = useLanguage()
  const { bottom } = useSafeAreaInsets()
  const toast = useToastMessage({ placement: 'bottom', style: { marginBottom: bottom } })

  function copyToClipboard(text: string) {
    ClipboardUtils.copyToClipboard(text)
    toast.show(i18n.t('COPIED_TO_CLIPBOARD'))
  }

  return {
    copyToClipboard,
  }
}
