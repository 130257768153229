import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import ActivityCreateOrEditScreen from '../../screens/activity/ActivityCreateOrEditScreen'
import { ModalScreenRef } from '../../types'
import { ActivityCreateOrEditViewProps } from '../../views/activities/ActivityCreateOrEditView'
import BaseServiceModal from '../BaseServiceModal'

type Props = {
  controller: ModalController<ActivityCreateOrEditViewProps>
} & Pick<ActivityCreateOrEditViewProps, 'handleCreateActivity'>

export default function ActivityCreationModal({ controller, handleCreateActivity }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ActivityCreateOrEditScreen ref={ref} controller={controller} handleCreateActivity={handleCreateActivity} />
    </BaseServiceModal>
  )
}
