import { IM, IMLayout, useLanguage, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { ArticlePriceListCategories, ArticlePriceListNode } from '../../../apis/types/apiResponseTypes'
import ArticleCategoryCard from '../../../cards/articles/ArticleCategoryCard'
import ArticleContextSelectionCard from '../../../cards/articles/ArticleContextSelectionCard'
import ListSpacer from '../../../components/ListSpacer'
import LoadingStateView from '../../../components/LoadingStateView'
import CardSkeleton from '../../../components/skeleton/CardSkeleton'
import CONSTANTS from '../../../constants/Constants'
import useArticleSearch from '../../../hooks/activity/useArticleSearch'
import useLayout from '../../../hooks/useLayout'
import { articleUtils } from '../../../utils/ArticleUtils'

type ArticlePriceListNodeViewProps = {
  categories: ArticlePriceListCategories
  depth?: number
} & Pick<ViewProps, 'style'>

export default function ArticlePriceListNodeView({ categories, depth = 0, ...viewProps }: ArticlePriceListNodeViewProps) {
  const keys = useMemo(() => Object.keys(categories), [categories])

  return (
    <IM.View {...viewProps}>
      {keys.map((key, index) => (
        <Node key={`ArticleCategory${categories[key]?.levelRowId ?? -index}`} node={categories[key]} depth={depth} />
      ))}
    </IM.View>
  )
}

type NodeProps = {
  node: ArticlePriceListNode
  depth: number
}
function Node({ node, depth }: NodeProps) {
  const { i18n } = useLanguage()
  const {
    search,
    openCategories,
    setOpenCategories,
    loadingPriceListArticles,
    selectedCategory,
    loadPriceListArticles,
    priceListArticles,
    articles,
    setSelectedCategory,
    forceLayout,
  } = useArticleSearch()
  const { isLargeDevice } = useLayout(false, forceLayout)

  const isOpen = useMemo(() => !!node.levelRowId && openCategories.includes(node.levelRowId), [openCategories])
  const isSelected = useMemo(() => node.levelRowId === selectedCategory?.levelRowId, [selectedCategory, node])

  const articlesToDisplay = useMemo(() => {
    if (node.categoryNodeList) return null
    if (search) {
      return articles.filter(a => articleUtils.compareGoodCategories(node, a.goodCategory))
    }
    return priceListArticles?.filter(a => articleUtils.compareGoodCategories(node, a.goodCategory))
  }, [priceListArticles, articles, node])

  function handleNodePressed() {
    setOpenCategories(prev => {
      if (!node.levelRowId) return prev

      // largeDevice: if no sub-categories exist, load articles then exit
      if (!node.categoryNodeList && isLargeDevice) {
        if (search && !!selectedCategory && articleUtils.compareGoodCategories(node, selectedCategory)) {
          setSelectedCategory(undefined)
          return prev
        }
        loadArticlesIfNotAlready()
        setSelectedCategory(node)
        return prev
      }

      // if node is already open, then close it
      if (prev.includes(node.levelRowId)) return [...prev.filter(p => p !== node.levelRowId)]

      // otherwise open it and load articles if no sub-categories exist
      prev.push(node.levelRowId)
      if (!isLargeDevice && !node.categoryNodeList) {
        loadArticlesIfNotAlready()
        setSelectedCategory(node)
      }
      return [...prev]
    })
  }

  function loadArticlesIfNotAlready() {
    if (!!node.categoryNodeList || (articlesToDisplay && articlesToDisplay.length)) return
    loadPriceListArticles(node)
  }

  if (!node) return <></>
  return (
    <IM.View>
      <ArticleCategoryCard
        node={node}
        spacing={['bottom']}
        style={{ marginLeft: depth * CONSTANTS.articleNodeListIndentWidth }}
        isOpen={isOpen}
        isSelected={isSelected && isLargeDevice}
        showIcon={!isLargeDevice || !!node.categoryNodeList}
        onCardPressed={handleNodePressed}
      />
      {!!isSelected && !isLargeDevice && (!articlesToDisplay || !articlesToDisplay.length) && (
        <LoadingStateView loading={loadingPriceListArticles}>
          <CardSkeleton height={30} spacing={['bottom']} style={{ marginLeft: depth * CONSTANTS.articleNodeListIndentWidth }} />
        </LoadingStateView>
      )}
      {isOpen && (
        <>
          {!!node.categoryNodeList && <ArticlePriceListNodeView categories={node.categoryNodeList} depth={depth + 1} />}
          {!isLargeDevice && !node.categoryNodeList && (
            <>
              {!!articlesToDisplay && (
                <>
                  {articlesToDisplay.map(article => (
                    <IM.View key={article.articleId} spacing={['bottom']}>
                      <ArticleContextSelectionCard article={article} />
                    </IM.View>
                  ))}
                </>
              )}
              {(!loadingPriceListArticles || !isSelected) && (!articlesToDisplay || !articlesToDisplay.length) && (
                <IM.View style={{ marginLeft: depth * CONSTANTS.articleNodeListIndentWidth + IMLayout.borderRadius }}>
                  <IM.Text secondary>{i18n.t('NO_ARTICLES_FOUND')}</IM.Text>
                </IM.View>
              )}
              <ListSpacer height={12} />
            </>
          )}
        </>
      )}
    </IM.View>
  )
}
