import { useFilePicker as useLibFilePicker } from 'use-file-picker'

import { UseFilePickerReturn } from '../../types'

export default function useFilePicker(): UseFilePickerReturn {
  const { openFilePicker, loading, filesContent, clear } = useLibFilePicker({
    multiple: true,
    readAs: 'DataURL',
  })

  return { openFilePicker, loading, filesContent, clear }
}
