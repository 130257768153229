import { useLanguage } from '@infominds/react-native-components'
import lodash from 'lodash'
import React, { createContext, PropsWithChildren, useCallback, useMemo } from 'react'

import useDataApiFilterState from '../hooks/useDataApiFilterState'
import useDataFilterState from '../hooks/useDataFilterState'
import useDataGroupState from '../hooks/useDataGroupState'
import useDataOrderState from '../hooks/useDataOrderState'
import {
  ApiFilterConfig,
  ApiFilterDataSorter,
  DataFilterConfigType,
  DataFilterValue,
  FilterConfig,
  FilterDataSorter,
  GroupConfig,
  GroupDataSorter,
  OrderConfig,
  OrderDataSorter,
} from '../types'
import { filterUtils } from '../utils/FilterUtils'

export interface FilterContextProps<T, TSub = void, TApi = void> {
  filters: FilterDataSorter<T, TSub>[]
  apiFilter: ApiFilterDataSorter<TApi>[]
  groups: GroupDataSorter<T, TSub>[]
  orders: OrderDataSorter<T, TSub>[]
  isAnyFilterActive: boolean
  initFilters: (elements: T[]) => void
  changeFilterStatus: (type: DataFilterConfigType, id: string, setValue?: boolean, filterValue?: string) => void
  changeFilterDateRange: (id: string, newStart: string | undefined, newEnd: string | undefined) => void
  changeApiFilter: (id: string, filterValue?: string | number | boolean | string[] | number[], text?: string, forceValue?: boolean) => void
  clear: (skip?: ClearSkip) => void
  initOk: boolean
}

type ClearSkip = {
  filters?: (string | number | symbol)[]
  groups?: (string | number | symbol)[]
  orders?: (string | number | symbol)[]
  apiFilters?: (string | number | symbol)[]
}

const FilterContext = createContext<FilterContextProps<object> | undefined>(undefined)

type ProviderProps<T, TSub = void, TApi = void> = {
  storageKeyUniqueId: string
  groupConfig?: GroupConfig<T, TSub, TApi>
  orderConfig?: OrderConfig<T, TSub, TApi>
  filterConfig?: FilterConfig<T, TSub>
  apiFilterConfig?: ApiFilterConfig<TApi>
}

export function FilterProvider<T extends object, TSub extends object | void = void, TApi = void>({
  storageKeyUniqueId,
  children,
  groupConfig,
  orderConfig,
  filterConfig,
  apiFilterConfig,
}: PropsWithChildren<ProviderProps<T, TSub, TApi>>) {
  const { i18n } = useLanguage()
  const [groups, setGroups, initGroupsOk] = useDataGroupState<T, TSub, TApi>(storageKeyUniqueId, groupConfig)
  const [orders, setOrders, initOrdersOk] = useDataOrderState<T, TSub, TApi>(storageKeyUniqueId, orderConfig)
  const [filters, setFilters, initFiltersOK] = useDataFilterState<T, TSub>(storageKeyUniqueId, filterConfig)
  const [apiFilter, setApiFilter, initApiFiltersOK] = useDataApiFilterState<TApi>(storageKeyUniqueId, apiFilterConfig)
  const initOk = initGroupsOk && initOrdersOk && initFiltersOK && initApiFiltersOK

  const isAnyFilterActive = useMemo(() => {
    if (groups.find(g => g.active && !g.options?.isDefault)) return true
    if (orders.find(o => o.active && !o.options?.isDefault)) return true
    if (filters.find(f => f.values.find(v => v.active))) return true
    if (apiFilter.find(f => f.active)) return true
    return false
  }, [groups, orders, filters, apiFilter])

  function initFilters(elements: T[] | undefined) {
    if (!elements) return

    setFilters(prev => {
      const clone = lodash.cloneDeep(prev)

      return clone.map(filterEntry => {
        const mergedValues: DataFilterValue[] = []
        const valuesFromData = filterUtils.prepareFilterValues(elements, filterEntry.dataKey, filterEntry.id, filterEntry.options, i18n)
        const valuesFromStorage = filterEntry.values

        for (const valueFromData of valuesFromData) {
          const storageValueFound = valuesFromStorage.find(el => el.id === valueFromData.id)

          if (storageValueFound !== undefined) {
            if (filterEntry.options?.filterType === 'dateRange') {
              mergedValues.push({ ...valueFromData, active: storageValueFound.active, value: storageValueFound.value })
            } else {
              mergedValues.push({ ...valueFromData, active: storageValueFound.active })
            }
          } else {
            mergedValues.push(valueFromData)
          }
        }

        return { ...filterEntry, values: mergedValues }
      })
    })
  }

  const changeFilterStatus = useCallback((type: DataFilterConfigType, id: string, setValue?: boolean) => {
    switch (type) {
      case 'filter': {
        setFilters(prev => {
          prev.forEach(entry => {
            entry.values.forEach(value => {
              if (value.id === id) value.active = setValue ?? !value.active
            })
          })
          return [...prev]
        })
        break
      }
      case 'group': {
        setGroups(prev => updateGroups(prev, id, setValue))
        break
      }
      case 'order': {
        setOrders(prev => {
          prev.forEach(entry => {
            if (entry.id === id) {
              entry.active = setValue ?? !entry.active
              entry.order = entry.active ? Date.now() : undefined
            }
          })

          return [...prev]
        })
        break
      }
    }
  }, [])

  const changeFilterDateRange = (id: string, newStart: string | undefined, newEnd: string | undefined) => {
    setFilters(prev => {
      prev.forEach(entry => {
        if (entry.id === id) {
          entry.values.forEach(value => {
            if (value.isDateRangeMax) {
              value.value = newEnd ?? ''
              value.active = newEnd !== undefined && newEnd !== ''
            } else {
              value.value = newStart ?? ''
              value.active = newStart !== undefined && newStart !== ''
            }
          })
        }
      })
      return [...prev]
    })
  }

  function changeApiFilter(id: string, filterValue?: string | number | boolean | string[] | number[], text?: string, forceValue?: boolean) {
    setApiFilter(prev => {
      prev.forEach(entry => {
        const valueValid = !!filterValue && (!Array.isArray(filterValue) || !!filterValue.length)
        if (entry.id === id) {
          if (forceValue !== undefined) {
            entry.active = valueValid && forceValue
          } else {
            entry.active = (valueValid ?? !entry.active) && !!entry.visible
          }
          entry.value = Array.isArray(filterValue) ? undefined : filterValue
          entry.values = Array.isArray(filterValue) ? filterValue : undefined
          entry.text = text ?? ''
        }
      })
      return [...prev]
    })
  }

  function updateGroups(prevState: GroupDataSorter<T, TSub>[], id: string, setValue?: boolean) {
    let anyTrue = false
    prevState.forEach(entry => {
      if (entry.id !== id) {
        entry.active = false
        return
      }

      entry.active = setValue ?? !entry.active
      if (entry.active) anyTrue = true
    })
    if (!anyTrue) {
      const hasDefault = prevState.find(q => q.options?.isDefault)
      if (hasDefault) {
        hasDefault.active = true
      }
    }

    return [...prevState]
  }

  const clear = useCallback((skip?: ClearSkip) => {
    setFilters(prev => {
      prev.forEach(entry => {
        entry.values.forEach(value => {
          if (skip?.filters?.includes(entry.dataKey)) return
          value.active = false
        })
      })
      return [...prev]
    })

    setGroups(prev => {
      prev.forEach(entry => {
        if (skip?.groups?.includes(entry.dataKey)) return
        entry.active = false
      })
      return [...prev]
    })

    setOrders(prev => {
      prev.forEach(entry => {
        if (skip?.orders?.includes(entry.dataKey)) return
        entry.active = false
      })
      return [...prev]
    })

    setApiFilter(prev => {
      prev.forEach(entry => {
        if (skip?.apiFilters?.includes(entry.dataKey)) return
        entry.active = false
        entry.value = ''
        entry.text = ''
      })
      return [...prev]
    })
  }, [])

  const props = useMemo<FilterContextProps<T, TSub, TApi>>(
    () => ({
      filters,
      apiFilter,
      groups,
      orders,
      isAnyFilterActive,
      changeFilterStatus,
      changeFilterDateRange,
      changeApiFilter,
      initFilters,
      clear,
      initOk,
    }),
    [filters, groups, orders, apiFilter, initOk]
  )

  return <FilterContext.Provider value={props as FilterContextProps<object>}>{children}</FilterContext.Provider>
}

export default FilterContext
