import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { ActivityInElaborationFilter } from '../../apis/types/apiRequestTypes'
import BaseTextInputTitle from '../input/baseTextInput/BaseTextInputTitle'
import { TextInputProps } from '../input/TextInput'
import Tag from '../Tag'

type Props = {
  value: ActivityInElaborationFilter | undefined
  onChange: (values: ActivityInElaborationFilter | undefined) => void
} & Pick<TextInputProps, 'title' | 'editable' | 'spacing' | 'disableBorderRadius' | 'placeholder'>

export default function InElaborationSelector({ onChange, value, editable, spacing, title }: Props) {
  const { i18n } = useLanguage()

  return (
    <IM.View spacing={spacing}>
      <BaseTextInputTitle title={title ?? i18n.t('STATE')} />
      <IM.View style={[IMLayout.flex.row]}>
        <Tag
          id={1}
          active={value === ActivityInElaborationFilter.InElaboration}
          name={i18n.t('IN_ELABORATION')}
          onPress={() => onChange(value === ActivityInElaborationFilter.InElaboration ? undefined : ActivityInElaborationFilter.InElaboration)}
          disabled={!editable}
        />
        <Tag
          id={2}
          active={value === ActivityInElaborationFilter.NotInElaboration}
          name={i18n.t('NOT_IN_ELABORATION')}
          onPress={() => onChange(value === ActivityInElaborationFilter.NotInElaboration ? undefined : ActivityInElaborationFilter.NotInElaboration)}
          disabled={!editable}
        />
      </IM.View>
    </IM.View>
  )
}
