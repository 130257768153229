import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CardProps, IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import { ActivityType } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ThemeColorExpanded } from '../../types'

export type ActivityTypeCardProps = {
  activityType: ActivityType
  showPlanColor?: boolean
  endIcon?: IconProp
} & CardProps

export default memo(function ActivityTypeCard({ activityType, selected, showPlanColor, endIcon, style, ...otherProps }: ActivityTypeCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()
  const { value: description } = useLanguageSelect({ en: activityType.descriptionEn, it: activityType.descriptionIt, de: activityType.descriptionDe })

  return (
    <IM.Card
      {...otherProps}
      style={[styles.card, style]}
      head={<CardLeftTitle color={selected ? theme.primary : showPlanColor ? activityType.planColor : undefined} />}>
      <IM.View style={[IMLayout.flex.row]}>
        <IM.View style={IMLayout.flex.f1}>
          <IM.Text primary>{description}</IM.Text>
        </IM.View>
        {!!activityType.notToInvoice && <IM.Text secondary>{i18n.t('NO_INVOICE')}</IM.Text>}
        {!!endIcon && (
          <IM.View spacing={'left'}>
            <IM.Icon icon={endIcon} color={theme.textDetail} size={20} />
          </IM.View>
        )}
      </IM.View>
    </IM.Card>
  )
})

const styles = StyleSheet.create({
  card: {
    minHeight: 44, // set to avoid size change if icon is made visible. empirically determined
  },
})
