import { useContext } from 'react'

import EmbedContext from '../contexts/EmbedContext'

export default function useEmbed() {
  const context = useContext(EmbedContext)

  if (context === undefined) {
    throw new Error('useEmbed() must be called inside EmbedProvider')
  }

  return context
}
