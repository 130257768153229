import { IM, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api } from '../../apis/apiCalls'
import { ConstructionSiteLotto } from '../../apis/types/apiResponseTypes'
import DeleteButton from '../../components/DeleteButton'
import GroupSpacer from '../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerOptions, EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import BooleanInput from '../../components/input/BooleanInput'
import MultiLangTextInput from '../../components/input/MultiLangTextInput'
import { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { EditOrCreateViewRef, UploadStatus } from '../../types'

export type ConstructionSiteLottoCreationViewProps = {
  objectId: number
  constructionSiteLotto?: ConstructionSiteLotto
  preset?: Partial<ConstructionSiteLotto>
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: EditDataHandlerOptions<ConstructionSiteLotto, Partial<ConstructionSiteLotto>>['onDone']
}

function ConstructionSiteLottoCreationView(
  { constructionSiteLotto, objectId, onDone, onUploadStatus, preset }: ConstructionSiteLottoCreationViewProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))
  const { i18n } = useLanguage()
  const requiredFields = useMemo<EditDataHandlerRequiredFields<ConstructionSiteLotto>>(() => [['descriptionDe', 'descriptionIt']], [])
  const { state, editMode, createOrUpdate, deleteData, handleDataChange } = useEditDataHandler<ConstructionSiteLotto, Partial<ConstructionSiteLotto>>(
    api.constructionsSites.lotto.post,
    undefined,
    undefined,
    {
      eventKeyCreation: EVENT_KEYS.LOTTO_CREATED_OR_MODIFIED,
      eventKeyModification: EVENT_KEYS.LOTTO_CREATED_OR_MODIFIED,
      eventKeyDeletion: EVENT_KEYS.LOTTO_CREATED_OR_MODIFIED,
      onDone: onDone,
      onUploadStatus: onUploadStatus,
      editMode: !!constructionSiteLotto,
      initialValue: constructionSiteLotto ?? preset,
      showErrorAlert: true,
      requiredFields,
      modifyDataBeforeRequest: data => ({
        ...data,
        objectId,
        descriptionDe: data.descriptionDe || data.descriptionIt,
        descriptionIt: data.descriptionIt || data.descriptionDe,
      }),
    }
  )

  const editable = true
  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
    editable,
  }
  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <MultiLangTextInput
        title={i18n.t('DESCRIPTION')}
        placeholder={i18n.t('DESCRIPTION')}
        values={{ de: state?.descriptionDe, it: state?.descriptionIt }}
        onChangeText={value => handleDataChange({ descriptionDe: value.de, descriptionIt: value.it })}
        filterLanguages={['de', 'it']}
        maxLength={30}
        {...commonProps}
      />
      <BooleanInput
        value={!!state?.invoiceByService}
        onValueChange={value => handleDataChange({ invoiceByService: Number(value) })}
        title={i18n.t('INVOICE_BY_SERVICE')}
        {...commonProps}
      />
      <BooleanInput
        value={!!state?.isGeneralCosts}
        onValueChange={value => handleDataChange({ isGeneralCosts: Number(value) })}
        title={i18n.t('IS_GENERAL_COST')}
        {...commonProps}
      />
      <BooleanInput
        value={!!state?.noRegisterCosts}
        onValueChange={value => handleDataChange({ noRegisterCosts: Number(value) })}
        title={i18n.t('NO_REGISTER_COSTS')}
        {...commonProps}
      />

      <GroupSpacer />

      {editMode && editable && (
        <IM.View {...commonProps}>
          <DeleteButton title={i18n.t('DELETE')} onPress={deleteData} />
        </IM.View>
      )}
      <GroupSpacer />
      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(ConstructionSiteLottoCreationView)
