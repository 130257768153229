import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, useDimensions } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StyleSheet } from 'react-native'

import useUserSettings from '../../../hooks/useUserSettings'
import InfoVideo from '../../InfoVideo'
import DataManagementIconGroup, { DataManagementIconGroupProps } from '../DataManagementIconGroup'
import HeaderChangeViewIcon from '../HeaderChangeViewIcon'
import { ScreenViewType } from '../types'

type Icon = { icon: IconProp; size: number }

export const MAP_ICON: Icon = { icon: ['fal', 'map-location-dot'], size: 21 }
export const CALENDAR_ICON: Icon = { icon: ['fal', 'calendar'], size: 18 }
export const LIST_ICON: Icon = { icon: ['fal', 'list'], size: 20 }

interface Props {
  disableChangeViewIcon?: boolean
}

const ServiceHeader = ({ disableChangeViewIcon = false, ...props }: Props & DataManagementIconGroupProps) => {
  const { isSmallDevice } = useDimensions()
  const { isOnlyReport } = useUserSettings()

  return (
    <IM.View style={styles.container}>
      <IM.View style={[styles.leftContainer]}>
        <DataManagementIconGroup {...props} alignment="left" />
        {isSmallDevice && !isOnlyReport && <InfoVideo />}
      </IM.View>
      <IM.View style={[styles.rightContainer]}>
        {!disableChangeViewIcon && (
          <HeaderChangeViewIcon
            types={
              Platform.OS === 'web' && isSmallDevice
                ? [Object.assign({ type: ScreenViewType.LIST }, CALENDAR_ICON), Object.assign({ type: ScreenViewType.CALENDAR }, LIST_ICON)]
                : [
                    Object.assign({ type: ScreenViewType.LIST }, MAP_ICON),
                    Object.assign({ type: ScreenViewType.LOCATION }, CALENDAR_ICON),
                    Object.assign({ type: ScreenViewType.CALENDAR }, LIST_ICON),
                  ]
            }
          />
        )}
        {/* <WebInstallButton /> */}
        {!isSmallDevice && !isOnlyReport && <InfoVideo />}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row' },
  rightContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})

export default ServiceHeader
