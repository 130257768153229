import { useAlert, useDidUpdate, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { api } from '../apis/apiCalls'
import { ActivityId } from '../types'
import ActivityButton, { ActivityButtonProps } from './ActivityButton'
import useRequest from './Infominds/hooks/useRequest'

export type ActivityDeleteButtonProps = {
  activity?: ActivityId
  forceDeletion?: boolean
  onDeleted?: () => void
  onDeletingStatusChanged?: (deleting: boolean) => void
} & Pick<ActivityButtonProps, 'disabled'>

export default function ActivityDeleteButton({
  activity,
  forceDeletion,
  onDeleted,
  onDeletingStatusChanged: setDeleting,
  ...props
}: ActivityDeleteButtonProps) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { alert } = useAlert()

  const { request: deleteActivity, loading } = useRequest(api.activities.delete, { showErrorAlert: true, onSuccess: onDeleted })

  useDidUpdate(() => setDeleting?.(loading === 'reloading'), [loading])

  function handleCancelActivity() {
    alert(i18n.t('CANCEL_ACTIVITY'), i18n.t('CANCEL_REPORT_ACTIVITY_ALERT'), [
      {
        text: i18n.t('BACK'),
        style: 'cancel',
        isPreferred: true,
      },
      {
        text: i18n.t('CANCEL_ACTIVITY'),
        onPress: () => {
          deleteActivity({ ...activity, forceDeletion })
        },
        style: 'destructive',
      },
    ])
  }

  return (
    <ActivityButton
      title={i18n.t('CANCEL')}
      onPress={handleCancelActivity}
      icon={['fas', 'trash']}
      style={{ backgroundColor: theme.error }}
      loading={loading === 'reloading'}
      {...props}
    />
  )
}
