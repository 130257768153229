import { IM, ViewProps } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'

import Text from './Text'

interface Props extends ViewProps {
  header: string
  text?: string
  children?: ReactNode
  textPriority?: 'primary' | 'secondary'
}

export default function TextWithHeader({ header, text, style, children, textPriority, ...props }: Props) {
  return (
    <IM.View style={style} {...props}>
      <Text primary>{header}</Text>
      {text && (
        <Text primary={textPriority === 'primary'} secondary={textPriority === 'secondary'}>
          {text}
        </Text>
      )}
      {children && children}
    </IM.View>
  )
}
