import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { api } from '../../../apis/apiCalls'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import CustomerDestinationCard from '../../customer/CustomerDestinationCard'

interface Props {
  error?: ConstrainBoolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  changeable?: boolean
}

export default function ActivityDetailDestinationIdCard({ spacing, style, changeable }: Props) {
  const { i18n } = useLanguage()

  const { detail } = useActivityDetail()

  const {
    item: destination,
    loadItem: loadDestination,
    loading: loadingDestination,
    setItem: setDestination,
  } = useControlledLoader(api.customers.destinations.getDetail)

  useEffect(() => {
    load()
  }, [detail?.destinationId, detail?.companyId])

  function load() {
    if (!detail?.companyId || !detail.destinationId) {
      setDestination(undefined)
      return
    }
    loadDestination({ customerId: detail.companyId, destinationId: detail.destinationId })
  }
  return (
    <CustomerDestinationCard
      destination={destination}
      leftText={i18n.t('DESTINATION_CARD_TITLE')}
      disabled={!destination}
      enableChange={changeable}
      loading={loadingDestination}
      style={style}
      spacing={spacing}
    />
  )
}
