import { i18n as i18nType } from 'i18next'

import { Activity, Contract, CreateActivityContractLots } from '../apis/types/apiResponseTypes'
import { RepetitionUtils } from '../components/repetition/RepetitionUtils'
import { ContractActivity, ContractLotSelection, Repetition } from '../types'
import { activityUtils } from './ActivityUtils'

export const ContractUtils = {
  getContractRepText(i18n: i18nType, contractActivity: ContractActivity | undefined) {
    if (!contractActivity?.activity?.title) return ''
    const totalNumberOfActivities =
      contractActivity?.repetition && contractActivity?.repetition.type !== 'none'
        ? RepetitionUtils.calculateRepetitionDates(contractActivity?.repetition).length
        : 1
    if (!totalNumberOfActivities) return ''
    return `${contractActivity.activity.title} (${totalNumberOfActivities} ${
      totalNumberOfActivities === 1 ? i18n.t('ACTIVITY') : i18n.t('ACTIVITIES')
    })`
  },
  createContractActivities(
    deviceActivity: ContractActivity | undefined,
    objectLots: ContractLotSelection[] | undefined
  ): Partial<Pick<Contract, 'createActivities' | 'createObjectLots'>> {
    const result: Partial<Pick<Contract, 'createActivities' | 'createObjectLots'>> = { createActivities: [], createObjectLots: [] }
    const filteredLots = objectLots?.filter(objectLot => !!objectLot.lots.length)
    // if objectLots exist use the createObjectLots object to let the api create the lots and activities
    if (filteredLots?.length) {
      result.createObjectLots = filteredLots.reduce<CreateActivityContractLots[]>((resultLots, objectLot) => {
        if (!objectLot.lots.length) return resultLots
        // if objectLot has activity configured
        if (objectLot.activity?.activity) {
          for (const lot of objectLot.lots) {
            if (!lot.objectLotId) continue
            resultLots.push(createContractLotsAndActivities(lot.objectLotId, objectLot.activity.activity, objectLot.activity.repetition))
          }

          return resultLots
        }

        // otherwise add just the objectLot and create NO activities
        for (const lot of objectLot.lots) {
          if (!lot.objectLotId) continue
          resultLots.push({
            objectLotId: lot.objectLotId,
            createActivities: [],
          })
        }

        return resultLots
      }, [])
    }
    // otherwise use the createActivities object to create activities
    else if (deviceActivity?.activity) {
      result.createActivities = createContractLotsAndActivities(0, deviceActivity.activity, deviceActivity.repetition).createActivities
    }

    return result
  },
}

function createContractLotsAndActivities(objectLotId: number, activity: Partial<Activity>, repetition: Repetition): CreateActivityContractLots {
  if (repetition.type === 'none') return { objectLotId: objectLotId, createActivities: [activityUtils.createBasicRequest({ ...activity })] }

  const planDates = RepetitionUtils.calculateRepetitionDates(repetition)
  return {
    objectLotId: objectLotId,
    createActivities: planDates?.map<Partial<Activity>>(date => activityUtils.createBasicRequest({ ...activity, expireDate: date?.toISOString() })),
  }
}
