import { useNavigation, useNavigationState } from '@react-navigation/native'

export default function useActivityBackNavigation() {
  const navigation = useNavigation()
  const navigationState = useNavigationState(s => s)

  function goBackToTop() {
    const lastRouteParams = navigationState.routes.at(-1)?.params
    if (!lastRouteParams || !('source' in lastRouteParams) || !lastRouteParams.source) {
      navigation.reset({
        routes: [
          {
            name: 'BottomTab',
            state: {
              routes: [{ name: 'ActivitiesStack', state: { routes: [{ name: 'Activities' }] } }],
            },
          },
        ],
      })
      return
    }
    const source = lastRouteParams.source
    if (source === 'HistoryStack') {
      navigation.reset({
        routes: [
          {
            name: 'BottomTab',
            state: {
              routes: [{ name: source, state: { routes: [{ name: 'History' }] } }],
            },
          },
        ],
      })
    } else if (source === 'PlannerStack') {
      navigation.reset({
        routes: [
          {
            name: 'BottomTab',
            state: {
              routes: [{ name: source, state: { routes: [{ name: 'Planner' }] } }],
            },
          },
        ],
      })
    } else if (source === 'AnagraficsStack') {
      navigation.reset({
        routes: [
          {
            name: 'BottomTab',
            state: {
              routes: [{ name: source, state: { routes: [{ name: 'Anagrafics' }] } }],
            },
          },
        ],
      })
    } else {
      navigation.reset({
        routes: [
          {
            name: 'BottomTab',
            state: {
              routes: [{ name: 'ActivitiesStack', state: { routes: [{ name: 'Activities' }] } }],
            },
          },
        ],
      })
    }
  }

  return { goBackToTop }
}
