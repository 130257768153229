import { IM, IMLayout, IMStyle, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { createRef, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import Orientation from 'react-native-orientation-locker'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import PressableIcon from '../components/Infominds/PressableIcon'
import DefaultHeader from '../components/screen/headers/default/DefaultHeader'
import { Screen } from '../components/screen/Screen'
import SignaturePad from '../components/SignaturePad'
import { EVENT_KEYS } from '../constants/EventKeys'
import useLayout from '../hooks/useLayout'
import useOnLayout from '../hooks/useOnLayout'
import { RootStackParamList } from '../navigation/types'
import { SignatureInputEvent, SignaturePadRef } from '../types'

export default function SignatureScreen() {
  const { i18n, language } = useLanguage()
  const insets = useSafeAreaInsets()
  const { onLayout, layout } = useOnLayout()
  const { onLayout: onTitleLayout, layout: titleLayout } = useOnLayout()
  const { theme } = useTheme()
  const navigation = useNavigation()
  const { isSmallDevice } = useLayout()
  const ref = createRef<SignaturePadRef>()
  const route = useRoute<RouteProp<RootStackParamList, 'Signature'>>()
  const title = React.useMemo(() => route.params.title ?? i18n.t('SIGNATURE'), [language, route.params.title])
  const subTitle = route.params.subTitle
  const { emit } = useEvent<SignatureInputEvent>({ key: EVENT_KEYS.SIGNATURE_ADDED })

  const handleOk = (sign: string) => {
    emit({ id: route.params.id, signature: sign })
    navigation.goBack()
  }

  useEffect(() => {
    if (isSmallDevice) Orientation.lockToPortrait()
  }, [isSmallDevice])

  if (isSmallDevice) {
    return (
      <IM.View onLayout={onLayout} style={IMLayout.flex.f1}>
        <SignaturePad
          ref={ref}
          signature={null}
          height={layout?.height}
          width={layout?.width}
          onOk={handleOk}
          rotateOnSave={isSmallDevice ? 90 : 0}
        />
        <IM.View
          style={[
            styles.title,
            {
              transform: [
                { rotate: '-90deg' }, // rotate -90 deg. After rotation
                { translateX: +(titleLayout?.width ?? 0) / 2 }, // move text x by width / 2
                { translateY: -(titleLayout?.width ?? 0) / 2 + (titleLayout?.height ?? 0) / 2 }, // move text y by width/2 and then by height/2
              ],
            },
          ]}
          onLayout={onTitleLayout}>
          <IM.Text style={{ color: theme.textDetail, fontSize: IMStyle.typography.fontSizeBig }}>{title}</IM.Text>
          {!!subTitle && <IM.Text style={{ color: theme.textDetail, fontSize: IMStyle.typography.fontSizeMedium }}>{subTitle}</IM.Text>}
        </IM.View>

        <IM.View style={[styles.line, { marginTop: insets.top, borderColor: theme.textDetail }]} />
        <IM.View style={[styles.buttonsTop, { marginTop: insets.top }]}>
          <IM.PressableIcon
            icon={['fal', 'check']}
            size={35}
            color={theme.textDetail}
            style={{ transform: [{ rotate: '270deg' }] }}
            onPress={() => ref.current?.readSignatures()}
          />
          <IM.PressableIcon
            icon={['fal', 'trash']}
            size={35}
            color={theme.textDetail}
            style={{ transform: [{ rotate: '270deg' }] }}
            onPress={() => ref.current?.clearSignature()}
          />
        </IM.View>
        <IM.View style={[styles.buttonBack, { marginBottom: insets.bottom }]}>
          <IM.PressableIcon
            icon={['fal', 'arrow-left']}
            size={35}
            color={theme.textDetail}
            style={{ transform: [{ rotate: '270deg' }] }}
            onPress={() => navigation.goBack()}
          />
        </IM.View>
      </IM.View>
    )
  }

  return (
    <Screen>
      <Screen.Header
        goBack={() => navigation.goBack()}
        onRightIconPress={() => {
          ref.current?.readSignatures()
        }}
        rightIcon={['fal', 'check']}>
        <DefaultHeader title={title}>
          <PressableIcon icon={['fal', 'trash']} color={IMStyle.palette.white} style={[]} onPress={() => ref.current?.clearSignature()} size={22} />
        </DefaultHeader>
      </Screen.Header>
      <Screen.Content>
        <IM.View onLayout={onLayout} style={IMLayout.flex.f1}>
          <SignaturePad
            ref={ref}
            signature={null}
            height={layout?.height}
            width={layout?.width}
            onOk={handleOk}
            rotateOnSave={isSmallDevice ? 90 : 0}
          />
        </IM.View>
        <IM.View style={[styles.lineLargeDevice, { borderColor: theme.textDetail }]} />
      </Screen.Content>
    </Screen>
  )
}

const styles = StyleSheet.create({
  title: {
    position: 'absolute',
    bottom: IMLayout.verticalMargin + 60,
    left: IMLayout.horizontalMargin + 8,
  },
  buttonsTop: {
    position: 'absolute',
    top: IMLayout.verticalMargin,
    left: IMLayout.horizontalMargin,
  },
  buttonBack: {
    position: 'absolute',
    bottom: IMLayout.verticalMargin,
    left: IMLayout.horizontalMargin,
  },
  line: {
    position: 'absolute',
    top: '5%',
    bottom: '5%',
    right: '25%',
    borderWidth: 1,
    borderStyle: 'dashed',
  },
  lineLargeDevice: {
    position: 'absolute',
    left: '5%',
    right: '5%',
    bottom: '30%',
    borderTopWidth: 1,
    borderStyle: 'solid',
  },
})
