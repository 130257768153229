import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import { DefaultSearchDetailHeaderProps } from '../../components/screen/headers/default/DefaultSearchDetailHeader'
import HeaderWithIcons from '../../components/screen/headers/HeaderWithIcons'
import { Screen } from '../../components/screen/Screen'
import CustomersListView from '../../views/passwords/CustomersListView'

export default function CustomersListScreen() {
  const { i18n } = useLanguage()
  const navigation = useNavigation()

  const commonSearchProps: DefaultSearchDetailHeaderProps = {
    enableQR: false, // TODO: QR not implemented yet
    deferredTimeout: 10,
  }

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header>
          <HeaderWithIcons
            title={i18n.t('CUSTOMERS_LIST')}
            disableFilter
            tabletSearchComponent={<SearchDetailHeaderContainer iconPosition="right" {...commonSearchProps} />}
          />
        </Screen.Header>
        <Screen.DetailHeader>
          <SearchDetailHeaderContainer {...commonSearchProps} />
        </Screen.DetailHeader>
        <Screen.Content>
          <CustomersListView
            onCustomerSelect={customer =>
              navigation.navigate('CustomerStack', {
                screen: 'CustomerDetail',
                params: { customerId: customer.companyId, customerName: customer.company1?.toString() ?? '' },
              })
            }
          />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
