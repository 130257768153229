import { Buffer } from 'buffer'

import { ShareUtilsType } from './ShareUtils'

export const shareUtils: ShareUtilsType = {
  file(data: string, fileName: string) {
    const byteCharacters = Buffer.from(data, 'base64')
    const blobURL = window.URL.createObjectURL(new Blob([byteCharacters]))
    const a = document.createElement('a')
    a.href = blobURL
    a.setAttribute('download', fileName)
    a.click()
    return Promise.resolve()
  },
}
