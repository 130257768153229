import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { api } from '../../../apis/apiCalls'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import ConstructionSiteCard from '../../constructionSites/ConstructionSiteCard'

interface Props {
  error?: ConstrainBoolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  changeable?: boolean
}

export default function ActivityDetailConstructionSiteIdCard({ spacing, style, changeable }: Props) {
  const { i18n } = useLanguage()

  const { detail } = useActivityDetail()

  const {
    item: constructionSite,
    loadItem: loadConstructionSite,
    loading: loadingConstructionSite,
    setItem: setConstructionSite,
  } = useControlledLoader(api.customers.constructionSites.getDetail)

  useEffect(() => {
    load()
  }, [detail?.objectId, detail?.companyId])

  function load() {
    if (!detail?.companyId || !detail.objectId) {
      setConstructionSite(undefined)
      return
    }
    loadConstructionSite({ customerId: detail.companyId, objectId: detail.objectId })
  }

  return (
    <>
      <ConstructionSiteCard
        constructionSite={constructionSite}
        showConstructionSiteLotto={!detail?.device && (!!detail?.objectLotId || !!detail?.srvContractLotId)}
        leftText={i18n.t('CONSTRUCTION_SITE')}
        disabled={!constructionSite}
        loading={loadingConstructionSite}
        enableChange={changeable}
        style={style}
        spacing={spacing}
      />
    </>
  )
}
