import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, TextProps, ViewStyle } from 'react-native'

import Pressable from './Infominds/Pressable'
import RadioButton from './RadioButton'
import Text from './Text'

interface Props {
  text: string
  description?: string
  info?: string
  selected: boolean
  disabled?: boolean
  numberOfLines?: TextProps['numberOfLines']
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}

export default function RadioPressable({ selected, disabled, text, style, description, info, numberOfLines, onPress }: Props) {
  return (
    <Pressable spacing="horizontal" style={[styles.pressable, style]} onPress={onPress} disabled={disabled}>
      <IM.View style={[IMLayout.flex.row, styles.view]}>
        <RadioButton selected={selected} disabled={disabled} />
        <Text secondary={disabled} style={IMLayout.flex.f1} numberOfLines={numberOfLines}>
          {text}
        </Text>
        {!!info && (
          <IM.View style={[IMLayout.flex.f5]} spacing={'left'}>
            <Text secondary numberOfLines={1}>
              {info}
            </Text>
          </IM.View>
        )}
      </IM.View>
      {description && (
        <IM.View spacing="top">
          <Text secondary style={IMLayout.flex.f1} numberOfLines={numberOfLines}>
            {description}
          </Text>
        </IM.View>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  pressable: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: 14,
    flexWrap: 'wrap',
  },
  view: { alignItems: 'center' },
})
