import { IM, IMLayout, SpacingProps, useDimensions, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import ActivityButton from '../../../components/ActivityButton'
import ActivityPDFButton from '../../../components/ActivityPDFButton'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import DynamicView from '../../../components/Infominds/DynamicView'
import TextWithHeader from '../../../components/TextWithHeader'
import CONSTANTS from '../../../constants/Constants'
import { AppStyle } from '../../../constants/Styles'
import useActivityEmployees from '../../../hooks/activity/useActivityEmployees'
import useEmailNotifications from '../../../hooks/email/useEmailNotifications'
import useUserSettings from '../../../hooks/useUserSettings'
import AssignedUserModal from '../../../modals/activity/AssignedUserModal'
import SendActivityEmailModal from '../../../modals/activity/SendActivityEmailModal'
import { activityUtils } from '../../../utils/ActivityUtils'
import TimeUtils from '../../../utils/TimeUtils'
import { ActivityListType } from '../../../views/activities/ActivityListView'
import ActivityCardIcon from '../../activityList/components/ActivityCardIcon'
import ActivityCardInfo from '../../activityList/components/ActivityCardInfo'

type Props = {
  type: ActivityListType
  activity: Activity
  assignable?: boolean
  spacing?: SpacingProps
}

const ActivityDetailInfoCard = memo(function ActivityDetailInfoCard({ activity, assignable, spacing, type }: Props) {
  const { i18n, language } = useLanguage()
  const { theme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const assignedUserModal = useModalController()
  const sendEmailModal = useModalController()
  const { userSettings, isOnlyReport } = useUserSettings()
  const emailNotifications = useEmailNotifications()

  const { activityEmployees } = useActivityEmployees(activity)
  const planDateText = useMemo(
    () => activityUtils.formatNextPlanDate(activityEmployees ?? [], language, userSettings?.employee?.id),
    [activityEmployees, language]
  )
  const creationDate = useMemo(() => activity.activityDate && TimeUtils.format(activity.activityDate, language), [activity, language])
  const expireDate = useMemo(() => activity.expireDate && TimeUtils.format(activity.expireDate, language), [activity, language])
  const closedDate = useMemo(() => activity.closedDate && TimeUtils.format(activity.closedDate, language, 'P HH:mm'), [activity, language])

  const isHistorical = useMemo(() => activityUtils.isActivityClosed(activity), [activity.state])

  const documentNumber = useMemo(() => activityUtils.getDocumentNumber(activity), [activity])

  return (
    <>
      <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('INFO')} />}>
        <DynamicView>
          <IM.View style={[IMLayout.flex.f3, IMLayout.flex.row, !isSmallDevice && AppStyle.center]}>
            <ActivityCardIcon activity={activity} spacing="right" type="normal" forceLayout="small" />
            <IM.View style={[IMLayout.flex.f1]}>
              <IM.Text primary>{activity.title}</IM.Text>
              {activity.srvActivityId && (
                <IM.Text secondary>{`${i18n.t('NUMBER_SHORT')}: ${activity.srvActivityYear}-${activity.srvActivityTypeId}-${
                  activity.srvActivityId
                }`}</IM.Text>
              )}
            </IM.View>
            {isSmallDevice && !!activity.assignedUser?.length && (
              <ActivityCardInfo
                type={type}
                activity={activity}
                showEmployee
                showIcon={false}
                assignable={!!assignable}
                onAssignedUsersPressed={assignedUserModal.show}
              />
            )}
          </IM.View>

          {!isOnlyReport && (
            <IM.View style={[IMLayout.flex.f4, IMLayout.flex.row, !isSmallDevice && AppStyle.center]} spacing={isSmallDevice ? 'top' : undefined}>
              {!isOnlyReport && (
                <>
                  <TextWithHeader
                    style={[IMLayout.flex.f1, !isSmallDevice && styles.marginRight]}
                    header={i18n.t('CREATED_ON')}
                    text={creationDate ? creationDate : '-'}
                  />
                  {!!activity.createdBy && (
                    <TextWithHeader
                      style={[IMLayout.flex.f1, !isSmallDevice && styles.marginRight]}
                      header={i18n.t('CREATED_BY')}
                      text={activity.createdBy}
                    />
                  )}
                </>
              )}
              {!isOnlyReport && (
                <TextWithHeader
                  style={[IMLayout.flex.f1, !isSmallDevice && styles.marginRight]}
                  header={i18n.t('EXPIRE_DATE')}
                  text={expireDate ? expireDate : '-'}
                />
              )}
            </IM.View>
          )}
          {!!isOnlyReport && (
            <IM.View style={[IMLayout.flex.f4]}>
              <TextWithHeader style={[IMLayout.flex.f1, styles.marginRight]} header={i18n.t('CLOSED_ON')} text={closedDate || '-'} />
            </IM.View>
          )}

          {((!!planDateText && !isHistorical) || (!!closedDate && isHistorical)) && !isOnlyReport && (
            <IM.View style={[IMLayout.flex.f3, IMLayout.flex.row, !isSmallDevice && AppStyle.center]} spacing={isSmallDevice ? 'top' : undefined}>
              {!isHistorical && <TextWithHeader style={[IMLayout.flex.f1, styles.marginRight]} header={i18n.t('PLAN_DATE')} text={planDateText} />}
              {isHistorical && (
                <TextWithHeader style={[IMLayout.flex.f1, styles.marginRight]} header={i18n.t('CLOSED_ON')} text={closedDate || '-'} />
              )}
            </IM.View>
          )}

          {!isSmallDevice && !!activity.assignedUser?.length && (
            <ActivityCardInfo
              type={type}
              style={[styles.largeDeviceActivityInfo]}
              activity={activity}
              showEmployee
              assignable={!!assignable}
              onAssignedUsersPressed={assignedUserModal.show}
            />
          )}
        </DynamicView>
        {!!documentNumber && (
          <>
            <IM.View style={[IMLayout.flex.row]} spacing={isSmallDevice || isOnlyReport ? 'top' : undefined}>
              <IM.View style={[IMLayout.flex.f1]}>
                <TextWithHeader style={[]} header={i18n.t('DOCUMENT_NR_ERGO')} text={documentNumber} />
              </IM.View>
            </IM.View>
            <IM.View style={[IMLayout.flex.row, styles.reportButtonView]} spacing={'top'}>
              <ActivityPDFButton activity={activity} />
              <ActivityButton
                icon={['fal', 'envelope']}
                onPress={() => sendEmailModal.show()}
                style={{ backgroundColor: theme.card.secondaryBackground }}
                title={i18n.t('SEND_MAIL')}
              />
            </IM.View>
          </>
        )}
        <AssignedUserModal controller={assignedUserModal} activity={activity} />
        <SendActivityEmailModal controller={sendEmailModal} activity={activity} onEmailSent={() => emailNotifications.onSuccess()} />
      </IM.Card>
    </>
  )
})

export default ActivityDetailInfoCard

const styles = StyleSheet.create({
  reportButtonView: {
    gap: IMLayout.horizontalMargin,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  largeDeviceActivityInfo: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: 150,
  },
  marginRight: { marginRight: CONSTANTS.margin },
})
