import { Storage, useDidUpdate } from '@infominds/react-native-components'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { STORAGE_KEYS } from '../constants/Keys'
import { GroupConfig, GroupDataSorter, StorageType } from '../types'
import { filterUtils } from '../utils/FilterUtils'

export default function useDataGroupState<T extends object, TSub = void, TApi = void>(
  storageKeyUniqueId: string,
  config?: GroupConfig<T, TSub, TApi>
): [GroupDataSorter<T, TSub>[], Dispatch<SetStateAction<GroupDataSorter<T, TSub>[]>>, boolean] {
  const [state, setState] = useState<GroupDataSorter<T, TSub>[]>(filterUtils.initDataGroup(config))
  const [initOk, setInitOk] = useState(false)
  const storage = Storage<StorageType[]>(STORAGE_KEYS.FILTER_CONTEXT_GROUP + (config ? config.type : '') + storageKeyUniqueId)

  useEffect(() => {
    storage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          setState(prev => {
            prev.forEach(elem => {
              const found = loaded.find(ld => ld.id === elem.id)
              elem.active = found?.value === 'true'
            })
            return [...prev]
          })
        }
      })
      .catch(err => console.error('Failed loading ticket group by', err))
      .finally(() => setInitOk(true))
  }, [])

  useDidUpdate(() => {
    storage
      .save(state.map(element => ({ id: element.id, value: element.active.toString(), isMax: false })))
      .catch(err => console.error('Failed saving dataFilterState ' + (config ? config.type : ''), err))
  }, [state])

  return [state, setState, initOk]
}
