import { Utils } from '@infominds/react-native-components'
import { AssetUtils, LiteAsset } from '@infominds/react-native-media-lite'
import { Platform } from 'react-native'

import { api, apiDtoIds } from '../apis/apiCalls'
import {
  ActivityArticle,
  ActivityArticleMedia,
  Article,
  ArticleGoodCategory,
  ArticlePriceList,
  ArticlePriceListNode,
  SupplierArticle,
} from '../apis/types/apiResponseTypes'
import { ActivityId } from '../types'
import { AppMediaUtils } from './AppMediaUtils'
import { objectUtils } from './objectUtils'
import { priceListUtils } from './PriceListUtils'

function compareGoodCategories(a: ArticleGoodCategory | ArticlePriceListNode | undefined, b: ArticleGoodCategory | ArticlePriceListNode | undefined) {
  return (
    !!a &&
    !!b &&
    a.goodCategoryId1 === b.goodCategoryId1 &&
    a.goodCategoryId2 === b.goodCategoryId2 &&
    a.goodCategoryId3 === b.goodCategoryId3 &&
    a.goodCategoryId4 === b.goodCategoryId4
  )
}

export const articleUtils = {
  compareGoodCategories,
  filterArticlesByPriceList: (articles: Article[] | undefined, priceList: ArticlePriceList | null | undefined) => {
    if (!priceList?.categoryList || !articles?.length) return []

    const categories = priceListUtils.getAllGoodCategoriesFromPriceList(priceList)
    return articles.filter(article => !!categories.find(category => !!compareGoodCategories(article.goodCategory, category)))
  },
  formatQuantity(article: ActivityArticle) {
    if (!article) return ''
    const result = [article.quantity ?? '']
    if (article.measureUnit?.description) result.push(article.measureUnit?.description)
    return result.join(' ')
  },
  formatAvailability(article: Article, hideUnit?: boolean) {
    if (!article?.actStock) return ''
    const result = [article.actStock.toString()]
    if (article.measureUnit?.description && !hideUnit) result.push(article.measureUnit.description)
    return result.join(' ')
  },
  calcPrice(article: ActivityArticle | undefined | null, includeDiscounts?: boolean) {
    const result = { total: 0, netTotal: 0, vat: 0, vatRate: 0 }
    if (!article || !article.quantity || article.quantity < 0 || !article.netPrice) return result
    result.netTotal = article.quantity * article.netPrice
    result.vatRate = article.vatExEmpt?.vatRate ?? 0
    result.vat = Utils.roundToPrecision(result.netTotal * (result.vatRate / 100.0))
    result.total = Utils.roundToPrecision(result.netTotal + result.vat, 2)
    if (includeDiscounts) {
      result.total -= (article.rowDiscount1 ?? 0) + (article.rowDiscount2 ?? 0) + (article.rowDiscount3 ?? 0) + (article.rowDiscount4 ?? 0)
      result.total = Math.max(0, result.total)
    }
    result.netTotal = Utils.roundToPrecision(result.netTotal, 2)
    return result
  },
  calcNetPriceMediaArticle(article: ActivityArticle | undefined | null) {
    if (!article || !article.quantity || article.quantity < 0 || !article.unitPrice) return 0
    return article.unitPrice - ((article.rowDiscount1 ?? 0) + (article.rowDiscount2 ?? 0) + (article.rowDiscount3 ?? 0) + (article.rowDiscount4 ?? 0))
  },
  async createOrUpdateArticles(selectedArticles: Partial<ActivityArticle>[], activity: ActivityId, initSelectedArticles: ActivityArticle[]) {
    const articlesToCreate = selectedArticles.filter(a => !a.srvActivityArticleId && !!a.quantity)
    const articlesToUpdate = selectedArticles.filter(a => !!a.srvActivityArticleId)

    for (const articleToCreate of articlesToCreate) {
      await api.activities.articles.post({
        ...articleToCreate,
        ...activity,
        srvActivityId: activity.srvActivityId,
        srvActivityTypeId: activity.srvActivityTypeId,
        srvActivityYear: activity.srvActivityYear,
        activityArticlervActivityTypeId: activity.srvActivityTypeId,
        determinePrice: true,
      })
    }
    await Promise.all(
      articlesToUpdate.map(articleToUpdate => {
        if (!articleToUpdate.quantity) {
          return api.activities.articles.delete(articleToUpdate)
        } else {
          const foundOriginal = initSelectedArticles.find(a => objectUtils.compareItemsWithKeys(a, articleToUpdate, apiDtoIds.activityArticles))
          // if quantity was unchanged then dont update the article
          if (!foundOriginal || foundOriginal.quantity === articleToUpdate.quantity) return Promise.resolve()
          return api.activities.articles.put({ ...articleToUpdate, determinePrice: true })
        }
      })
    )
  },
  async postMediaArticle({ request, assets }: { request: Partial<ActivityArticle>; assets: LiteAsset[] }, abortController?: AbortController) {
    if (!request.articleId) throw new Error('No Data')

    const postResult = await api.activities.articles.post({ ...request, isJolly: true }, abortController)
    if (!postResult.srvActivityArticleId) throw new Error('Failed to create activityNote: Missing srvActivityArticleId')
    const article = objectUtils.createRequestObject<ActivityArticle>(request as ActivityArticle, apiDtoIds.activityArticles)
    article.srvActivityArticleId = postResult.srvActivityArticleId
    await createMediaAssets(assets, article, abortController)
    return postResult
  },
  async updateMediaArticle({ request, assets }: { request: Partial<ActivityArticle>; assets: LiteAsset[] }, abortController?: AbortController) {
    await api.activities.articles.put(request, abortController)
    const article = objectUtils.createRequestObject<ActivityArticle>(request as ActivityArticle, apiDtoIds.activityArticles)
    await createMediaAssets(assets, article, abortController)
  },
  getSupplierArticleTitle(article: SupplierArticle) {
    if (!article.supplierDescription) return ''
    return `(${article.supplierId}) ${article.supplierDescription}`
  },
  async convertMediaToLiteAssets(media: ActivityArticleMedia[] | undefined) {
    return Promise.all(
      media
        ?.filter(q => !!q.media && !!q.mediaTitle)
        .map(element =>
          AssetUtils.base64ToAsset(
            element.media ?? '',
            objectUtils.createUniqueIdFromKeys(element, apiDtoIds.activityArticleMedia),
            element.mediaTitle ?? '',
            element.type ?? AppMediaUtils.getAssetTypeByFileName(element.mediaTitle),
            element.mediaDuration
          )
        ) ?? []
    )
  },
  getRdaDocNumber(article: ActivityArticle) {
    if (!article?.refer2DocId) return undefined

    return [
      // according to IlariaF 3 is always "OC" and 6 is "BO"
      article.refer2DocType === 3 ? 'OC' : article.refer2DocType === 6 ? 'BO' : article.refer2DocType?.toString() ?? '',
      article.refer2SerialYear ?? '',
      article.refer2SerialNr ?? '',
      article.refer2DocId ?? '',
    ].join('-')
  },
}

async function createMediaAssets(assetsToSend: LiteAsset[], article: Partial<ActivityArticle>, abortController?: AbortController) {
  if (!assetsToSend?.length || !article) return

  for (const asset of assetsToSend) {
    const mediaRequest: Partial<ActivityArticleMedia> = {
      ...article,
      mediaTitle: asset.fileName,
      type: asset.type,
    }

    if (Platform.OS === 'web') {
      mediaRequest.media = asset.base64 ?? ''
      mediaRequest.mediaTitle = asset.fileName
    } else {
      const compressedAsset = await AssetUtils.compressAsset(asset)
      mediaRequest.media = await AssetUtils.assetToBase64(compressedAsset)
      mediaRequest.mediaSize = Number(await AppMediaUtils.getSize(compressedAsset))
      mediaRequest.mediaDuration = compressedAsset.type === 'video' || compressedAsset.type === 'audio' ? compressedAsset.duration : undefined
    }
    await api.activities.articles.media.post(mediaRequest, abortController)
  }
}
