import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { Contact } from '../../apis/types/apiResponseTypes'
import CustomerContactCreationScreen from '../../screens/customer/CustomerContactCreationScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<Contact | undefined>
  customerId: number
  onContactCreated?: (customer: Contact) => void
}

export default function CustomerContactCreationModal({ controller, customerId, onContactCreated }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <CustomerContactCreationScreen ref={ref} controller={controller} customerId={customerId} onContactCreated={onContactCreated} />
    </BaseServiceModal>
  )
}
