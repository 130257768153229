import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Language, Utils } from '@infominds/react-native-components'
import { differenceInCalendarDays, isAfter, startOfDay } from 'date-fns'
import { i18n } from 'i18next'
import { DimensionValue } from 'react-native'

import { apiDtoIds } from '../apis/apiCalls'
import { Activity, ActivityEmployee, ActivityPlanTime, ActivityState, Contract, Technician } from '../apis/types/apiResponseTypes'
import { ActivityIcons } from '../constants/IconTypes'
import { InfiniteLoadingType, LoadingType, PlannedActivityEmployee, SupportedActivityPriorities, ThemeColorExpanded } from '../types'
import { EmployeeUtils } from './EmployeeUtils'
import { imDateUtils } from './infominds/imDateUtils'
import { objectUtils } from './objectUtils'
import TimeUtils from './TimeUtils'

function getNextPlanTime<T extends ActivityPlanTime | ActivityEmployee>(planTime: T[] | undefined) {
  if (!planTime?.length) return undefined
  const sortedPLanDates = [...planTime].sort((a, b) => Utils.compareStringsForSort(a.planDateFrom, b.planDateFrom))
  // find the next (not jet finished) plan-date or alternatively the last
  return sortedPLanDates.find(s => !!s.planDateFrom && s.planDateFrom > startOfDay(new Date()).toISOString()) ?? sortedPLanDates.pop()
}

function getNextPlanTimeFromTechnicians(assignedUsers: Technician[] | undefined, user?: Technician) {
  const planTimes = assignedUsers?.reduce<ActivityPlanTime[]>((times, assignedUser) => {
    if ((!!user && !EmployeeUtils.compare(assignedUser, user)) || !assignedUser.planTimes?.length) return times
    times.push(...assignedUser.planTimes)
    return times
  }, [])
  return getNextPlanTime(planTimes)
}

export const activityUtils = {
  createBasicRequest(data: Partial<Activity>): Activity {
    return {
      ...data,
      priority: data.priority ?? 0,
      device: data.objectLotId ? undefined : data.device,
      srvActivityYear: data.srvActivityYear ?? new Date().getFullYear(),
      activityDate: data.activityDate ?? new Date().toISOString(),
    } as Activity
  },
  getActivityIconByActivity<T extends Pick<Activity, 'srvContractId' | 'objectId'>>(activity: T | undefined): IconProp {
    if (activity?.srvContractId) return ActivityIcons.contract
    if (activity?.objectId) return ActivityIcons.constructionSite
    return ActivityIcons.default
  },
  getActivityNumber<T extends Pick<Activity, 'srvActivityYear' | 'srvActivityTypeId' | 'srvActivityId'>>(activity: T, i18nValue: i18n) {
    return `${i18nValue.t('NUMBER_SHORT')} ${activity.srvActivityYear}-${activity.srvActivityTypeId}-${activity.srvActivityId}`
  },
  getCardHeadColorByActivity(activity: Activity | undefined, theme: ThemeColorExpanded) {
    return SupportedActivityPriorities.find(q => q.id === activity?.priority)?.color?.(theme) ?? theme.card.secondaryBackground
  },
  createId(activity: Activity, ignoreGrouping?: boolean) {
    const id = objectUtils.createUniqueIdFromKeys(activity, apiDtoIds.activity)
    if (!activity.planDate || ignoreGrouping) return id
    return `${id}+${activity.planDate}`
  },
  compareActivities(activityA: Activity | undefined | null, activityB: Activity | undefined | null) {
    return objectUtils.compareItemsWithKeys(activityA, activityB, apiDtoIds.activity)
  },
  getTabTitle(title: string, activities: (Activity | string)[], loading: LoadingType | InfiniteLoadingType, showCount?: boolean) {
    const realActivities = activities.filter(q => typeof q !== 'string')
    if (loading === 'reloading') return title
    const result = [title]
    if (showCount && realActivities) result.push(realActivities.length.toString())
    return result.join(' ')
  },
  getPdfName(activity: Activity) {
    return `EM-SERVICE_${activity.srvActivityYear}-${activity.srvActivityTypeId}-${activity.srvActivityId}.pdf`
  },
  itemIsActivity(item: Activity | string | null): item is Activity {
    if (!item || typeof item !== 'object') return false
    return !!item.srvActivityYear && !!item.srvActivityId && !!item.srvActivityTypeId
  },
  calculateRatio(fromDate?: string, untilDate?: string, theme?: ThemeColorExpanded): { width: DimensionValue; color?: string; expired?: boolean } {
    const from = fromDate ? new Date(fromDate) : new Date()
    const until = untilDate
      ? new Date(TimeUtils.resetTimeOfISOString(new Date(untilDate).toISOString(), 24 * 60 * 60 - 1))
      : new Date(TimeUtils.resetTimeOfISOString(new Date().toISOString(), 24 * 60 * 60 - 1))

    if (isAfter(new Date(), until)) {
      return { width: '100%', color: theme?.article.status.red, expired: true }
    } else if (isAfter(from, new Date())) {
      return { width: '0%', color: theme?.article.status.green, expired: false }
    } else {
      const totalContractDuration = differenceInCalendarDays(until, from)
      const remainingDays = differenceInCalendarDays(until, new Date())

      const ratio = 1 - remainingDays / totalContractDuration
      const width: DimensionValue = `${ratio * 100}%`

      if (ratio < 0.8) {
        return {
          width,
          color: theme?.article.status.green,
          expired: false,
        }
      } else if (ratio >= 0.8 && ratio < 1) {
        return {
          width,
          color: theme?.article.status.yellow,
          expired: false,
        }
      } else {
        return {
          width,
          color: theme?.article.status.red,
          expired: true,
        }
      }
    }
  },
  getWarrantyStatus(warrantyStart: string, warrantyEnd: string, theme?: ThemeColorExpanded) {
    return this.calculateRatio(warrantyStart, warrantyEnd, theme)
  },
  getContractStatus(contract: Contract, theme?: ThemeColorExpanded) {
    return this.calculateRatio(contract.startDate, contract.expiredDate, theme)
  },
  getActiveContract(contracts: Contract[] | undefined) {
    let toRet: Contract | undefined

    if (contracts) {
      try {
        contracts.forEach(contract => {
          const status = this.getContractStatus(contract)

          const newValue = status.width?.toString().replace('%', '') ?? ''
          const completion = parseInt(newValue, 10)

          if (completion > 0 && completion < 100) {
            toRet = contract
          }
        })
      } catch (err) {
        console.error(err)
      }
    }

    return toRet
  },
  getNextPlanTime,
  getNextPlanTimeFromTechnicians,
  /**
   * Sort Activities by planDate. Newest first
   */
  sortByPlanDate(a: Activity, b: Activity, user?: Technician) {
    return Utils.compareStringsForSort(
      getNextPlanTimeFromTechnicians(a.assignedUser, user)?.planDateTo,
      getNextPlanTimeFromTechnicians(b.assignedUser, user)?.planDateTo
    )
  },
  /**
   * Sort Activities by expireDate
   */
  sortByExpireDate(a: Activity, b: Activity, type: 'asc' | 'desc' = 'asc') {
    let value = Utils.compareStringsForSort(a.expireDate, b.expireDate)
    if (type === 'desc') value *= -1

    return value
  },
  /**
   * Returns { from, to } for the next planned time of the activity
   */
  formatNextActivityPlanDate(activity: Activity, language: Language, user?: Technician) {
    const nextPlanDate = this.getNextPlanTimeFromTechnicians(activity.assignedUser, user)
    if (!nextPlanDate) return

    const fromDate = new Date(nextPlanDate.planDateFrom)
    const formattedDateFrom = TimeUtils.format(fromDate, language)
    const formattedTimeFrom = TimeUtils.formatTime(fromDate)
    const toDate = new Date(nextPlanDate.planDateTo)
    const formattedDateTo = TimeUtils.format(toDate, language)
    const formattedTimeTo = TimeUtils.formatTime(toDate)

    const result = [`${formattedDateFrom} ${formattedTimeFrom}`]
    if (formattedDateFrom === formattedDateTo) result.push(formattedTimeTo)
    else result.push(`${formattedDateTo} ${formattedTimeTo}`)

    return result
      .map(r => r.trim())
      .filter(r => !!r)
      .join(' - ')
  },
  formatNextPlanDate(data: ActivityEmployee[], language: Language, preferredEmployeeId?: number) {
    if (!data?.length) return ''

    let filteredData = [...data]
    if (preferredEmployeeId) {
      filteredData = data.filter(e => e.technician?.id === preferredEmployeeId)
      if (!filteredData.length && data.length) filteredData = [...data]
    }
    const nextPlanDate = getNextPlanTime(filteredData)
    if (!nextPlanDate) return

    const fromDate = nextPlanDate.planDateFrom && new Date(nextPlanDate.planDateFrom)
    const formattedDateFrom = fromDate && TimeUtils.format(fromDate, language)
    const formattedTimeFrom = fromDate && TimeUtils.formatTime(fromDate)
    const toDate = nextPlanDate.planDateTo && new Date(nextPlanDate.planDateTo)
    const formattedDateTo = toDate && TimeUtils.format(toDate, language)
    const formattedTimeTo = toDate && TimeUtils.formatTime(toDate)

    const result = [`${formattedDateFrom || ''} ${formattedTimeFrom || ''}`]
    if (formattedDateFrom === formattedDateTo) result.push(formattedTimeTo || '')
    else result.push(`${formattedDateTo || ''} ${formattedTimeTo || ''}`)

    return result
      .map(r => r.trim())
      .filter(r => !!r)
      .join(' - ')
  },
  isActivityClosed<T extends { state: ActivityState }>(activity: T | undefined | null) {
    return activity?.state === ActivityState.Closed || activity?.state === ActivityState.ClosedWithInterventionConnected
  },
  isActivityPlanned<T extends { assignedUser?: Technician[] }>(activity: T): { planned: boolean; start: string | undefined } {
    const hasAssignedUsers = activity.assignedUser ? activity.assignedUser.length !== 0 : false
    let hasPlanDateStart = false
    let planStart

    activity.assignedUser?.forEach(el => {
      if (el.planTimes) {
        el.planTimes.forEach(time => {
          if (time.planDateFrom) {
            hasPlanDateStart = true
            planStart = time.planDateFrom
          }
        })
      }
    })

    return { planned: hasAssignedUsers && hasPlanDateStart, start: planStart }
  },
  convertPlannedEmployeesForRequest(
    plannedEmployees: PlannedActivityEmployee[] | undefined,
    disabledTechnicians: Technician[]
  ): Partial<ActivityEmployee>[] {
    return (
      plannedEmployees
        ?.filter(p => !p.isDeleted)
        .filter(el => !disabledTechnicians.find(tech => tech.id === el.technician?.id && tech.technicianType === el.technician.technicianType))
        .map(p => ({
          ...p,
          technicianId: p.technician?.id,
          technicianType: p.technician?.technicianType,
          planDateFrom: imDateUtils.toUTC(p.planDateFrom),
          planDateTo: imDateUtils.toUTC(p.planDateTo),
        })) ?? []
    )
  },
  getDocumentNumber(activity: Activity) {
    if (!activity?.referToDocId) return undefined
    return [
      // according to IlariaF 3 is always "OC" and 6 is "BO"
      activity.referToDocType === 3 ? 'OC' : activity.referToDocType === 6 ? 'BO' : activity.referToDocType?.toString() ?? '',
      activity.referToSerialYear ?? '',
      activity.referToSerialNumber ?? '',
      activity.referToDocId ?? '',
    ].join('-')
  },
  createPresetForClosing(activity: Activity): Activity {
    const createActivityEmployees = activity.assignedUser?.map<Partial<ActivityEmployee>>(user => ({ technician: { ...user, planTimes: [] } }))

    return {
      ...activity,
      expireDate: undefined,
      createActivityEmployees,
    }
  },
} as const
