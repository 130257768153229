import { IM, IMLayout, useEvent, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import React, { createRef, useEffect, useState } from 'react'
import { Animated } from 'react-native'

import { api } from '../../../apis/apiCalls'
import { Contract } from '../../../apis/types/apiResponseTypes'
import ContractCard from '../../../cards/activities/ContractCard'
import FlashListData from '../../../components/FlashListData'
import AnimatedButton from '../../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import useSearch from '../../../components/screen/hooks/useSearch'
import { ADD_ACTIVITY_BUTTON_ID } from '../../../constants/ButtonIds'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { AppStyle } from '../../../constants/Styles'
import { useDataProvider } from '../../../dataProvider/hooks/useDataProvider'
import useUserSettings from '../../../hooks/useUserSettings'
import { utils } from '../../../utils/utils'

const buttonAnimationValue = new Animated.Value(0)

export default function ContractListView() {
  const { search } = useSearch()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { userSettings } = useUserSettings()
  const { isOnline } = useDataProvider()
  const [uuid, setUuid] = useState(utils.generateUuid())

  const { item: Contracts, loadItem: loadContracts, loading: loadingContracts } = useControlledLoader(api.activities.contracts.getList)
  useEvent({ key: EVENT_KEYS.UPDATED_CONTRACTS }, () => loadContracts())

  const listRef = createRef<FlashList<string | Contract>>()

  useEffect(() => {
    loadContracts()
  }, [userSettings])

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [])

  useEffect(() => {
    setUuid(utils.generateUuid())
  }, [loadingContracts])

  const renderItem = (elem: ListRenderItemInfo<Contract | string>) => {
    const item = elem.item

    if (typeof item === 'string') {
      return <IM.Text>{item}</IM.Text>
    } else {
      return (
        <ContractCard
          style={[AppStyle.card]}
          spacing={'bottom'}
          contract={item}
          onPress={() => {
            navigation.navigate('ContractDetailStack', {
              screen: 'ContractDetail',
              params: { contractId: item.srvContractId },
            })
          }}
        />
      )
    }
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlashListData
        ref={listRef}
        data={Contracts}
        loading={loadingContracts}
        noDataMessage={i18n.t('NO_CONTRACT_FOUND')}
        renderItem={renderItem}
        isSearching={search !== ''}
        refresh={loadContracts}
        extraData={uuid}
      />
      {!loadingContracts && isOnline && (
        <AnimatedButton
          id={ADD_ACTIVITY_BUTTON_ID}
          value={buttonAnimationValue}
          icon={['fal', 'plus']}
          onPress={() => {
            navigation.navigate('ContractDetailStack', { screen: 'ContractDetail', params: {} })
          }}
        />
      )}
    </IM.View>
  )
}
