import dayjs, { OpUnitType } from 'dayjs'

type ToUtcOptions = { start?: OpUnitType; end?: OpUnitType }

export const imDateUtils = {
  /**
   * Convert the input date into UTC format
   * @param date Date to be converted into UTC format
   * @returns {string} UCT string date
   */
  toUTC: (date: Date | dayjs.Dayjs | string | undefined, option?: ToUtcOptions) => {
    if (date === undefined || date === '' || dayjs(date).isValid() === false) return undefined
    if (option && option.start !== undefined && option.end !== undefined) {
      console.error('Can not used both start and end option at the same time')
      return undefined
    }

    let toRet = dayjs(date)

    if (option?.start) {
      option.end
      toRet = toRet.startOf(option?.start)
    }

    if (option?.end) {
      option?.start
      toRet = toRet.endOf(option?.end)
    }

    return toRet.toISOString()
  },
}
