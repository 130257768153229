import React, { memo } from 'react'

import useArticleSearch from '../../../hooks/activity/useArticleSearch'
import ArticleListView from './ArticleListView'

function ArticleFullSearchView() {
  const { historyArticles, loadingHistoryArticles, refreshHistoryArticles, loadMoreHistoryArticles } = useArticleSearch()

  return (
    <ArticleListView
      articles={historyArticles}
      loadingArticles={loadingHistoryArticles}
      refreshArticles={refreshHistoryArticles}
      loadMoreArticles={loadMoreHistoryArticles}
    />
  )
}

export default memo(ArticleFullSearchView)
