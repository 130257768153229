import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { i18nKey, RepetitionType } from '../../types'
import SelectorBar, { SelectorBarValue } from './SelectorBar'

export type RepetitionTypeSelectorProps = {
  title: string
  selected: RepetitionType | undefined
  onChange: (value: RepetitionType) => void
  required?: boolean
}

const RepetitionTypes: { type: RepetitionType; textKey: i18nKey }[] = [
  { type: 'none', textKey: 'REPETITION_TYPE_NONE' },
  { type: 'day', textKey: 'REPETITION_TYPE_DAILY' },
  { type: 'week', textKey: 'REPETITION_TYPE_WEEKLY' },
  { type: 'month', textKey: 'REPETITION_TYPE_MONTHLY' },
  { type: 'year', textKey: 'REPETITION_TYPE_YEARLY' },
]

export default function RepetitionTypeSelector({ title, selected, onChange, required }: RepetitionTypeSelectorProps) {
  const { i18n } = useLanguage()

  const selectorValues = useMemo(() => RepetitionTypes.map<SelectorBarValue<RepetitionType>>(q => ({ value: q.type, text: i18n.t(q.textKey) })), [])

  return (
    <SelectorBar
      values={selectorValues}
      title={title ?? i18n.t('REPETITION')}
      onValuePressed={onChange}
      selectedValues={selected ? [selected] : undefined}
      required={required}
    />
  )
}
