import { ModalController, useAlert, useLanguage } from '@infominds/react-native-components'
import { useEffect, useRef } from 'react'

export default function usePopStateModal<T>(unsavedChanges: boolean, title: string, description: string, controller?: ModalController<T>) {
  const { alert } = useAlert()
  const { i18n } = useLanguage()

  const hasPushedState = useRef(false)

  useEffect(() => {
    if (controller === undefined) return

    if (controller.isShown && !hasPushedState.current) {
      window.history.pushState(null, document.title, window.location.href)
      hasPushedState.current = true
    } else if (!controller.isShown && hasPushedState.current) {
      window.history.go(-1)
      hasPushedState.current = false
    }
  }, [unsavedChanges])

  useEffect(() => {
    if (controller === undefined || hasPushedState.current === false) return

    const callback = () => {
      if (!unsavedChanges) {
        controller.close()
        return
      }

      alert(title, description, [
        {
          text: i18n.t('DISCARD'),
          style: 'destructive',
          onPress: () => {
            controller.close()
          },
        },
        {
          text: i18n.t('CANCEL'),
          style: 'cancel',
          onPress: () => window.history.pushState(null, document.title, window.location.href),
        },
      ])
    }

    window.addEventListener('popstate', callback)
    return () => window.removeEventListener('popstate', callback)
  }, [unsavedChanges])
}
