import { IM, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { ViewProps } from 'react-native'

import { api } from '../../../apis/apiCalls'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import { BaseTextInputProps } from '../../../components/input/baseTextInput/BaseTextInput'
import { ContractLottoSelector } from '../../../components/selectors/ContractLottoSelector'
import { useContractCreation } from '../../../contexts/activity/ContractCreationContext'
import { ContractLotSelection } from '../../../types'

type ContractLotsSelectionViewProps = Pick<BaseTextInputProps, 'required' | 'spacing' | 'editable' | 'tabIndex'> & Pick<ViewProps, 'style'>

export default function ContractLotsSelectionView(props: ContractLotsSelectionViewProps) {
  const { state, objectLots, setObjectLots, editMode } = useContractCreation()
  const {
    item: constructionSiteLots,
    loadItem: loadConstructionSiteLots,
    setItem: setObjectLotsData,
  } = useControlledLoader(api.constructionsSites.lotto.getList)

  useEffect(() => {
    if (!state?.objectId) {
      setObjectLotsData([])
      return
    }
    setObjectLotsData(undefined)
    loadConstructionSiteLots({ objectId: state.objectId })
  }, [state?.objectId])

  const lotsToShow = useMemo<ContractLotSelection[]>(() => {
    // decide if empty selector should be shown
    if (
      !editMode &&
      (!objectLots.length ||
        (objectLots[objectLots.length - 1].lots.length &&
          !!constructionSiteLots?.filter(
            constructionSiteLot => !objectLots.find(objectLot => objectLot.lots.find(lots => lots.objectLotId === constructionSiteLot.lotId))
          ).length))
    ) {
      // add empty to cause an additional selector to be shown
      return [...objectLots, { id: Utils.getUid(), constructionSiteId: state?.objectId ?? 0, lots: [] }]
    }
    return objectLots
  }, [objectLots, state?.objectId, editMode])

  return (
    <IM.View>
      {lotsToShow.map((lot, index) => (
        <ContractLottoSelector
          {...props}
          index={index}
          key={lot.id}
          selection={lot}
          spacing={'vertical'}
          setSelection={item => {
            setObjectLots(prev => {
              if (!item?.lots.length) {
                if (index > 0 || lotsToShow.length > 1) return prev.filter(p => p.id !== lot.id)
                return prev.map(p => (p.id === lot.id ? { ...p, lots: [] } : p))
              }
              const foundElement = prev.find(p => p.id === item.id)
              if (foundElement) {
                return prev.map(p => (p.id === item.id ? item : p))
              }
              return [...prev, item]
            })
          }}
          selectedLots={lotsToShow.map(lts => lts.lots.map(l => l.objectLotId ?? 0)).flat()}
        />
      ))}
    </IM.View>
  )
}
