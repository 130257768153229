import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { UserRole } from '../../apis/types/apiResponseTypes'
import EmployeeBadge from '../../components/EmployeeBadge'
import { SETTINGS_BORDER_RADIUS } from '../../components/screen/constants/constants'
import SkeletonCard from '../../components/skeleton/SkeletonCard'
import Text from '../../components/Text'
import useUserSettings from '../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../types'

interface Props {
  style?: StyleProp<ViewStyle>
}

export default function UserCard({ style }: Props) {
  const { userSettings, isOnlyReport } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  const employee = userSettings?.employee
  const role = useMemo(() => {
    if (isOnlyReport) return undefined
    switch (userSettings?.roles) {
      case UserRole.admin:
        return i18n.t('ROLE_ADMIN')
      case UserRole.planner:
        return i18n.t('ROLE_PLANNER')
      case UserRole.teamLeader:
        return i18n.t('ROLE_TEAM_LEADER')
      case UserRole.technician:
        return i18n.t('ROLE_TECHNICIAN')
    }
    return userSettings?.roles
  }, [userSettings?.roles])

  return (
    <>
      <IM.View
        spacing="all"
        style={[
          style,
          styles.row,
          styles.employee,
          {
            backgroundColor: theme.settings.badge.background,
          },
        ]}>
        <IM.View style={[IMLayout.flex.row, styles.employeeInfo]}>
          {employee ? (
            <>
              <EmployeeBadge
                size={80}
                showName={false}
                id={employee.id}
                name={`${employee.firstName ?? ''} ${employee.lastName ?? ''}`}
                spacing="right"
              />
              <IM.View style={styles.employeeTitle}>
                {employee.firstName && <Text style={styles.name}>{employee.firstName}</Text>}
                {employee.lastName && <Text style={styles.surname}>{employee.lastName}</Text>}
                {!!role && <Text style={styles.role}>{role}</Text>}
              </IM.View>
            </>
          ) : (
            <SkeletonCard />
          )}
        </IM.View>
      </IM.View>
      <IM.View spacing="top" />
    </>
  )
}

const styles = StyleSheet.create({
  employee: { borderRadius: SETTINGS_BORDER_RADIUS, minHeight: 100, alignItems: 'center' },
  employeeInfo: { alignItems: 'center', marginLeft: 4 },
  employeeTitle: { marginLeft: 12 },
  row: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  name: { fontSize: IMStyle.typography.fontSizeRegular + 2 },
  surname: { fontSize: IMStyle.typography.fontSizeRegular + 2, fontWeight: IMStyle.typography.fontWeightBold },
  role: {
    fontSize: IMStyle.typography.fontSizeRegular - 2,
    fontStyle: 'italic',
    marginTop: 2,
  },
})
