import React, { PropsWithChildren, useState } from 'react'
import { DragEndParams } from 'react-native-draggable-flatlist'

type DragAndDropContextType = {
  dragActive: boolean
  setDragActive: React.Dispatch<React.SetStateAction<boolean>>
  reorderedData: unknown[] | null
  setReorderedData: React.Dispatch<React.SetStateAction<unknown[] | null>>
  changed: boolean
  setChanged: React.Dispatch<React.SetStateAction<boolean>>
}

const DragAndDropContext = React.createContext<DragAndDropContextType | null>(null)

export function DragAndDropProvider({ children }: PropsWithChildren) {
  const [dragActive, setDragActive] = useState(false)
  const [reorderedData, setReorderedData] = useState<unknown[] | null>(null)
  const [changed, setChanged] = useState(false)

  return (
    <DragAndDropContext.Provider
      value={{
        dragActive,
        setDragActive,
        reorderedData,
        setReorderedData,
        changed,
        setChanged,
      }}>
      {children}
    </DragAndDropContext.Provider>
  )
}

export function useDragAndDrop<T>() {
  const context = React.useContext(DragAndDropContext)
  if (context === null) {
    throw new Error('useDragAndDropContext must be used within a DragAndDropProvider')
  }

  function onDragEnd(params: DragEndParams<T>) {
    context?.setReorderedData(params.data)
    context?.setChanged(true)
  }

  function reset() {
    context?.setReorderedData(null)
    context?.setChanged(false)
  }

  return {
    ...context,
    reorderedData: context.reorderedData as T[],
    setReorderedData: context.setReorderedData as React.Dispatch<React.SetStateAction<T[] | null>>,
    onDragEnd,
    reset,
  }
}
