import { useEvent } from '@infominds/react-native-components'
import { useEffect } from 'react'

import { api } from '../../apis/apiCalls'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { EVENT_KEYS } from '../../constants/EventKeys'

export default function useTimeSlots(srvTimeSlotId?: number) {
  const { item: timeSlots, loadItem: loadTimeSlots, loading: loadingTimeSlots } = useControlledLoader(api.activities.timeSlots.getList)

  useEffect(reloadTimeSlots, [srvTimeSlotId])
  useEvent({ key: EVENT_KEYS.UPDATED_TIME_SLOTS }, () => reloadTimeSlots())

  function reloadTimeSlots() {
    loadTimeSlots({ srvTimeSlotId })
  }

  return {
    timeSlots,
    loadingTimeSlots,
    reloadTimeSlots,
  }
}
