import { IM, IMLayout, IMStyle, useLanguage, useModalController } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import React, { useMemo, useState } from 'react'
import { Platform, StyleSheet, TextInput } from 'react-native'

import { AppStyle } from '../constants/Styles'
import { useActivityNote } from '../contexts/activity/ActivityNoteContext'
import useExtendedTheme from '../hooks/useExtendedTheme'
import useOnLayout from '../hooks/useOnLayout'
import DisplayAssetModal from '../modals/media/DisplayAssetModal'
import PressableIcon, { PressableIconProps } from './Infominds/PressableIcon'
import LoadingIcon from './LoadingIcon'
import AssetBar from './media/AssetBar'
import MediaButtons from './MediaButtons'

export default function MediaPostInput() {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()
  const newAssetModal = useModalController<LiteAsset>()

  const { sendNote, loadingNotes, sendingNote, newAssets, setNewAssets } = useActivityNote()

  const [text, setText] = useState('')

  const disablePostButton = useMemo(
    () => loadingNotes === 'reloading' || (!text && !newAssets?.length) || sendingNote === 'reloading',
    [loadingNotes, sendingNote, newAssets, text]
  )

  function send() {
    sendNote({ text, assets: newAssets ? [...newAssets] : [] })
    setText('')
  }

  function handleAssetBarAssetPressed(asset: LiteAsset) {
    if (Platform.OS === 'web') {
      setNewAssets(prev => prev.filter(a => a.id !== asset.id))
      return
    }
    newAssetModal.show(asset)
  }

  function handleDeleteAssets(assetsToDelete: LiteAsset[]) {
    setNewAssets(prev => prev.filter(a => !assetsToDelete.some(adt => adt.id === a.id)))
  }

  return (
    <IM.View>
      <AssetBar
        assets={newAssets}
        showLoadingSpinner={sendingNote === 'reloading'}
        onAssetPressed={handleAssetBarAssetPressed}
        style={styles.assetBar}
      />
      <IM.View style={[IMLayout.flex.row]}>
        <TextInput
          style={[
            IMLayout.flex.f1,
            {
              color: theme.text,
            },
            { borderColor: theme.textPlaceholder },
            styles.textInput,
          ]}
          placeholderTextColor={theme.textPlaceholder}
          value={text}
          onChangeText={setText}
          placeholder={i18n.t('NOTE_INPUT_PLACEHOLDER')}
        />
        <MediaButtons disabled={loadingNotes === 'reloading' || sendingNote === 'reloading'} assets={newAssets} setAssets={setNewAssets} />
        <MediaPostInputButton
          icon={['fal', 'chevron-right']}
          onPress={send}
          style={[
            { borderBottomRightRadius: IMLayout.borderRadius },
            !disablePostButton && { backgroundColor: theme.primary, borderColor: theme.primary },
          ]}
          disabled={disablePostButton}
          busy={sendingNote === 'reloading'}
        />
      </IM.View>
      <DisplayAssetModal assets={newAssets} controller={newAssetModal} onDeleteAsset={handleDeleteAssets} allowAssetDeletion />
    </IM.View>
  )
}

type MediaPostInputButtonProps = PressableIconProps & { secondary?: boolean; busy?: boolean }
function MediaPostInputButton({ style, color, secondary, busy, onLayout: externalOnLayout, ...props }: MediaPostInputButtonProps) {
  const { theme } = useExtendedTheme()
  const { layout, onLayout } = useOnLayout()

  return (
    <IM.View
      style={[
        !secondary && { backgroundColor: theme.card.headBackground, borderColor: theme.card.headBackground },
        secondary && { backgroundColor: theme.card.secondaryBackground, borderColor: theme.card.secondaryBackground },
        style,
      ]}
      onLayout={e => {
        onLayout(e)
        externalOnLayout?.(e)
      }}>
      {busy && (
        <IM.View style={[{ width: layout?.width, height: layout?.height }, AppStyle.center]}>
          <LoadingIcon />
        </IM.View>
      )}
      {!busy && (
        <PressableIcon
          pressableStyle={{ backgroundColor: undefined }}
          style={[styles.pressableIconMain]}
          color={color ?? IMStyle.palette.white}
          {...props}
        />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  assetBar: {
    marginBottom: 4,
  },
  textInput: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingHorizontal: IMLayout.horizontalMargin,
  },
  pressableIconMain: {
    padding: 10,
  },
})
