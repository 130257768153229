import React from 'react'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import FilterDetailHeader from '../../components/screen/headers/FilterDetailHeader'
import ServiceHeader from '../../components/screen/headers/ServiceHeader'
import { Screen } from '../../components/screen/Screen'
import FilterContext from '../../contexts/FilterContext'
import { filterUtils } from '../../utils/FilterUtils'
import ActivitiesView from '../../views/activities/ActivitiesView'

export default function ActivitiesScreen() {
  return (
    <SearchProvider>
      <FilterContext.Consumer>
        {context => {
          return (
            <Screen>
              <Screen.Header>
                <ServiceHeader
                  tabletSearchComponent={<SearchDetailHeaderContainer dividers={['right']} enableQR />}
                  tabletFilterComponent={
                    <FilterDetailHeaderContainer dividers={['right']}>
                      <FilterDetailHeader enabled />
                    </FilterDetailHeaderContainer>
                  }
                  indicatorNumber={filterUtils.getActiveFilterCount(context?.filters ?? [], context?.apiFilter ?? [])}
                />
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <FilterDetailHeader enabled />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <ActivitiesView />
              </Screen.Content>
            </Screen>
          )
        }}
      </FilterContext.Consumer>
    </SearchProvider>
  )
}
