import { useMemo } from 'react'

import CONSTANTS from '../../constants/Constants'
import useLayout, { ForceLayoutType } from '../useLayout'
import useOnLayout from '../useOnLayout'

type MediaDisplayMode = 'compact' | 'full'
export type MediaViewImageSizeInfo = { size: number; displayCount: number; itemsPerRow: number; lastSmall?: boolean }
type Options = { mediaCount: number; displayMode?: MediaDisplayMode; forceLayout?: ForceLayoutType; overwriteImageSize?: number }
export default function useMediaView({ mediaCount, displayMode = 'compact', forceLayout, overwriteImageSize }: Options) {
  const { layout, onLayout } = useOnLayout()
  const { isSmallDevice } = useLayout(false, forceLayout)

  const imageSize = useMemo<MediaViewImageSizeInfo | null>(() => {
    if (overwriteImageSize) return { size: overwriteImageSize, displayCount: mediaCount, itemsPerRow: mediaCount }
    const width = layout?.width ?? 0
    if (width <= 0) return null
    const preferredImageSize = isSmallDevice ? CONSTANTS.imageSizeSmallDevice : CONSTANTS.imageSizeLargeDevice
    const imageRequiredSpace = preferredImageSize + CONSTANTS.imageSpacing
    const maxImagesPerRow = Math.floor(width / imageRequiredSpace)

    if (displayMode === 'full') {
      if (isSmallDevice) {
        return { size: Math.min((width - CONSTANTS.imageSpacing * 2) / 2, preferredImageSize), displayCount: mediaCount, itemsPerRow: 2 }
      }
      return { size: Math.min(width, preferredImageSize), displayCount: mediaCount, itemsPerRow: maxImagesPerRow }
    }
    // small device
    if (isSmallDevice) {
      if (mediaCount <= 1) return { size: preferredImageSize, displayCount: 1, itemsPerRow: 1 }
      if (mediaCount === 2) return { size: Math.min(preferredImageSize, (width - CONSTANTS.imageSpacing) / 2), displayCount: 2, itemsPerRow: 2 }
      if (width >= imageRequiredSpace + preferredImageSize / 2 + CONSTANTS.imageSpacing) {
        return { size: preferredImageSize, displayCount: 2, lastSmall: true, itemsPerRow: mediaCount }
      }
      const downSizedImageSize = Math.floor(width * 0.66)
      return { size: downSizedImageSize, displayCount: 2, lastSmall: true, itemsPerRow: mediaCount }
    }

    // large device

    // if all images can fit inside of view display them all
    if (mediaCount <= maxImagesPerRow) return { size: preferredImageSize, displayCount: mediaCount, itemsPerRow: mediaCount }

    const maxFittingImagesWithMoreCount = Math.min(
      Math.floor((width - (preferredImageSize / 2 + CONSTANTS.imageSpacing)) / imageRequiredSpace) + 1,
      mediaCount
    )

    if (maxFittingImagesWithMoreCount <= 0) return null

    return {
      size: (width - preferredImageSize / 2) / maxFittingImagesWithMoreCount,
      displayCount: maxFittingImagesWithMoreCount,
      lastSmall: true,
      itemsPerRow: maxFittingImagesWithMoreCount,
    }
  }, [layout, mediaCount, displayMode])

  const rows = useMemo(
    () => !!mediaCount && imageSize && !!imageSize.itemsPerRow && (displayMode === 'compact' ? 1 : Math.ceil(mediaCount / imageSize.itemsPerRow)),
    [imageSize, mediaCount, displayMode]
  )

  return {
    layout,
    onLayout,
    imageSize,
    rows,
  }
}
