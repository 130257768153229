import { useMemo } from 'react'

import { SearchKey } from '../types'
import { filterUtils } from '../utils/FilterUtils'

export default function useDataSearchFilter<T extends object | string>(data: T[] | undefined, search: string, keys?: SearchKey<T>[]) {
  const filteredData = useMemo(() => filterUtils.filterItemsBySearch(data, search, keys), [data, search, keys])

  return filteredData
}
