import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ConstructionSiteLotto } from '../../apis/types/apiResponseTypes'
import ChangeButton from '../../components/ChangeButton'
import Error from '../../components/Error'
import SkeletonText from '../../components/skeleton/SkeletonText'
import TextWithHeader from '../../components/TextWithHeader'
import { InfiniteLoadingType, LoadingType } from '../../types'

export type ConstructionSiteLottoViewProps = {
  constructionSiteLotto?: ConstructionSiteLotto
  device?: string
  style?: StyleProp<ViewStyle>
  loading?: LoadingType | InfiniteLoadingType
  inactive?: boolean
  disableIcon?: boolean
  enableChange?: boolean
  onChange?: () => void
}

export default function ConstructionSiteLottoView({
  constructionSiteLotto,
  enableChange,
  onChange,
  loading,
  device,
  style,
}: ConstructionSiteLottoViewProps) {
  const { i18n } = useLanguage()

  const description = useMemo(() => constructionSiteLotto?.description || device || '', [constructionSiteLotto, device])

  return (
    <IM.View style={[styles.content, style]}>
      {loading === false || loading === 'init' ? (
        <IM.View style={IMLayout.flex.row}>
          <IM.View style={[IMLayout.flex.f1, styles.textView]}>
            {description ? (
              <>
                <TextWithHeader header={i18n.t('CONSTRUCTION_SITE_LOTTO')} text={description} />
              </>
            ) : (
              <IM.Text secondary>{i18n.t('NO_CONSTRUCTION_SITE_LOTTO_SELECTED')}</IM.Text>
            )}
          </IM.View>
          {enableChange && <ChangeButton onPress={() => onChange?.()} />}
        </IM.View>
      ) : (
        <>
          {loading === 'catched' ? (
            <>
              <Error />
              {enableChange && <ChangeButton onPress={() => onChange?.()} />}
            </>
          ) : (
            <>
              <SkeletonText width="70%" />
              <SkeletonText spacing="top" width="100%" />
            </>
          )}
        </>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  textView: {
    justifyContent: 'center',
  },
  content: { flex: 1, justifyContent: 'space-between' },
})
