import { useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { Image, ImageStyle, Platform, StyleProp } from 'react-native'

import SkeletonText from './skeleton/SkeletonText'

interface Props {
  uri: string | undefined
  width: number
  height: number
  showLoadingSkeleton?: boolean
  style?: StyleProp<ImageStyle>
  color?: string
  onError?: (value: boolean) => void
  onDone?: (value: boolean) => void
  mode?: false | 'default' | 'hidden'
}

const BlobImage = memo(function BlobImage({ uri, width, height, style, showLoadingSkeleton, color, onError, onDone, mode }: Props) {
  const { theme } = useTheme()
  const { bearerToken } = useAuthentication()

  const [loading, setLoading] = useState<boolean>(true)
  const [loadedImage, setLoadedImage] = useState(false)
  const [blobUrl, setBlobUrl] = useState('')
  const hiddenMode = useMemo(() => mode === 'hidden', [mode])

  useEffect(() => {
    // This needs changes on backend first.
    if (Platform.OS === 'web' && uri) {
      setLoading(true)
      onError?.(false)
      fetch(uri, { headers: { Authorization: bearerToken ?? '' } })
        .then(res => {
          if (res.status === 200) {
            res
              .blob()
              .then(blob => {
                setBlobUrl(URL.createObjectURL(blob))
                setLoadedImage(true)
              })
              .catch(err => {
                console.error('Failed converting blob', err)
                onError?.(true)
              })
              .finally(() => setLoading(false))
          }
        })
        .catch(() => {
          onError?.(true)
          setLoading(false)
        })
    }
  }, [uri])

  useEffect(() => {
    onDone?.(!loading)
  }, [loading])

  return (
    <>
      {Platform.OS === 'web' && (!hiddenMode || !!blobUrl) && (
        <Image source={{ uri: blobUrl }} style={[{ width, height }, style, { backgroundColor: color ?? theme.background }]} />
      )}

      {Platform.OS !== 'web' && (
        <>
          {showLoadingSkeleton && loading === true && !hiddenMode && <SkeletonText width={width} height={height} />}
          <Image
            source={{
              uri: uri,
              method: 'GET',
              headers: {
                Authorization: bearerToken ?? '',
              },
            }}
            style={[
              (loadedImage || !hiddenMode) && [
                {
                  width,
                  height,
                },
                style,
              ],
              { backgroundColor: color ?? theme.background },
            ]}
            onLoadStart={() => {
              onError?.(false)
            }}
            onLoadEnd={() => setLoading(false)}
            onLoad={() => setLoadedImage(true)}
            onError={err => {
              // on Android it returns {"error": "unknown image format"}
              if (Platform.OS === 'ios') {
                // code 204 means image is missing
                const code = (err.nativeEvent as { responseCode?: number }).responseCode
                if (code !== 204) {
                  console.error('BlobImage Error:', (err.nativeEvent as { responseCode?: number }).responseCode)
                }
              }

              onError?.(true)
            }}
          />
        </>
      )}
    </>
  )
})

export default BlobImage
