import { useLanguage } from '@infominds/react-native-components'
import { Flex } from 'antd'
import React, { memo } from 'react'
import { Resource } from 'react-big-scheduler-stch'

import { Qualification } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import ResourceFilter from './ResourceFilter'

interface Props {
  resourceName: string
  resources: Resource[]
  qualifications: Qualification[]
}

function GanttChartResourceHeader({ resourceName, ...others }: Props) {
  const { i18n } = useLanguage()

  return (
    <Flex gap="small" justify="space-between" align="center" vertical={false}>
      {resourceName}
      <ResourceFilter
        {...others}
        qualifications={[...others.qualifications, { id: CONSTANTS.noQualificationId, description: i18n.t('WITHOUT_QUALIFICATION') }]}
      />
    </Flex>
  )
}

export default memo(GanttChartResourceHeader)
