import { useDimensions } from '@infominds/react-native-components'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo } from 'react'
import { atomFamily, useRecoilState } from 'recoil'

import { STORAGE_KEYS } from '../../../constants/Keys'
import { AllBottomTabRoutesParamList } from '../../../navigation/types'
import appUtils from '../../../utils/appUtils'

interface Props {
  forceSmallDevice?: boolean
  storageKey?: string
}

type Keyof<T extends object> = Extract<keyof T, string>

export type MasterDetailProp<ParamList extends ParamListBase, RouteName extends keyof ParamList = Keyof<ParamList>> = ParamList[RouteName]

export interface IMasterDetailContext<T extends MasterDetailProp<ParamListBase> = MasterDetailProp<ParamListBase>> {
  detailParams: T | undefined
  setDetailParams: Dispatch<SetStateAction<T | undefined>>
  enableMDLayout: boolean
  navigate<Name extends keyof AllBottomTabRoutesParamList>(
    ...args: Name extends unknown
      ? undefined extends AllBottomTabRoutesParamList[Name]
        ? [screen: Name] | [screen: Name, params: AllBottomTabRoutesParamList[Name]]
        : [screen: Name, params: AllBottomTabRoutesParamList[Name]]
      : never
  ): void
}

export const MasterDetailContext = createContext<IMasterDetailContext | undefined>(undefined)

const atomState = atomFamily<object | undefined, string>({
  key: 'masterDetailState',
  default: undefined,
  effects: param => (param ? [appUtils.localStorageEffect<object | undefined>(param + STORAGE_KEYS.MASTER_DETAIL)] : []),
})

export function MasterDetailProvider<T extends MasterDetailProp<ParamListBase>>({
  forceSmallDevice,
  children,
  storageKey,
}: PropsWithChildren<Props>) {
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation()
  const enableMDLayout = !isSmallDevice && !forceSmallDevice

  const [detailParameters, setDetailParameters] = useRecoilState<object | undefined>(atomState(storageKey ?? ''))

  const handleNavigation = <Name extends keyof AllBottomTabRoutesParamList>(
    ...args: Name extends unknown
      ? undefined extends AllBottomTabRoutesParamList[Name]
        ? [screen: Name] | [screen: Name, params: AllBottomTabRoutesParamList[Name]]
        : [screen: Name, params: AllBottomTabRoutesParamList[Name]]
      : never
  ) => {
    if (!enableMDLayout) {
      //@ts-ignore discover why there is a ts error
      navigation.navigate(...args)
    } else {
      //@ts-ignore discover why there is a ts error
      setDetailParameters(args[1])
    }
  }

  const props = useMemo<IMasterDetailContext<T>>(
    () => ({ detailParams: detailParameters as T, setDetailParams: setDetailParameters, navigate: handleNavigation, enableMDLayout: enableMDLayout }),
    [detailParameters, isSmallDevice, forceSmallDevice]
  )

  // @ts-ignore: to understand
  return <MasterDetailContext.Provider value={props}>{children}</MasterDetailContext.Provider>
}
