import { useTheme } from '@infominds/react-native-components'

import { ThemeColorExpanded } from '../types'

export default function useExtendedTheme() {
  const theme = useTheme<ThemeColorExpanded>()

  function getColorByIndex(index: number) {
    const colorIndex = index % theme.theme.randomColors.length
    return theme.theme.randomColors[colorIndex]
  }

  return { ...theme, getColorByIndex }
}
