import { SpacingProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ActivityNoteContextProvider } from '../../../contexts/activity/ActivityNoteContext'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import ActivityNotesCard from '../notes/ActivityNotesCard'

interface Props {
  error?: ConstrainBoolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  enableChange?: boolean
  dontLoadOnMount?: boolean
}

export default function ActivityDetailNotesCard({ spacing, style, enableChange, dontLoadOnMount }: Props) {
  const { detail, isHistorical } = useActivityDetail()

  return (
    <ActivityNoteContextProvider activityDetail={detail} dontLoadOnMount={dontLoadOnMount}>
      <ActivityNotesCard spacing={spacing} style={style} enableChange={enableChange && !isHistorical} />
    </ActivityNoteContextProvider>
  )
}
