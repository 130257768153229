import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { translation } from '../../assets/languages/i18next'
import { ActivityPriority, ListSection, SupportedActivityPriorities } from '../../types'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  value: number | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: number | undefined) => void
  required?: boolean
  disableFastInput?: boolean
}

export default function ActivityPrioritySelector({ value, onChange, disableFastInput, ...props }: Props) {
  const { i18n } = useLanguage()
  const [selected, setSelected] = useState<ActivityPriority | undefined>(findUnavailableType(value))

  function findUnavailableType(id: number | undefined) {
    return SupportedActivityPriorities.find(q => q.id === (id ?? 0))
  }

  const onPressed = (newUnavailableType: ActivityPriority | undefined) => {
    setSelected(newUnavailableType)
    onChange(newUnavailableType?.id)
  }

  function translate(item: ActivityPriority) {
    return i18n.t(item.labelKey.toUpperCase() as keyof typeof translation.en)
  }

  const render = ({ item }: SectionListRenderItemInfo<ActivityPriority, ListSection<ActivityPriority>>, onPress?: () => void) => {
    return <RadioPressable key={item.id} onPress={onPress} selected={item.id === selected?.id} text={translate(item)} />
  }

  return (
    <SelectInput
      id={['id']}
      data={SupportedActivityPriorities ?? []}
      value={selected}
      loading={false}
      title={i18n.t('PRIORITY')}
      screenTitle={i18n.t('PRIORITY')}
      noDataMessage={''}
      renderItem={render}
      onChange={onPressed}
      renderSelectedString={item => translate(item)}
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      hideNoSelectionItem
      {...props}
    />
  )
}
