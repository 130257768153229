import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { OptionsProvider } from '@infominds/react-native-camera'
import { useDimensions, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useMemo } from 'react'
import { Platform, StatusBar } from 'react-native'
import Orientation from 'react-native-orientation-locker'

import WebConfig from '../app.config'
import AppContent from '../components/Infominds/AppContent'
import { TabBarProvider } from '../components/Infominds/DrawerNavigator'
import CONSTANTS from '../constants/Constants'
import { MenuProvider } from '../contexts/MenuContext'
import useUserSettings from '../hooks/useUserSettings'
import ErrorModal from '../modals/ErrorModal'
import navigationUtils from '../utils/navigationUtils'
import { BottomTabs, LoginStackNavigator, RootStackNavigator } from './NavigationConfig'
import NavigationContainer from './NavigationContainer'
import { BottomTabParamList, TabNavigationScreen } from './types'

interface Props {
  isLoggedIn: boolean
  basePath: string
}
const basePath = WebConfig.BASE_PATH.includes('/') ? WebConfig.BASE_PATH : '/'
export default function Navigator({ isLoggedIn }: Props) {
  const { i18n } = useLanguage()
  const errorModal = useModalController()
  const { theme, colorScheme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const { logout } = useAuthentication()
  const { userSettings, menuItems, loading, loadSettings, user, isOnlyReport } = useUserSettings()

  useEffect(() => {
    isSmallDevice && Orientation.lockToPortrait()
  }, [isSmallDevice])

  useEffect(() => {
    if (!userSettings) return

    userSettings.employee?.id === undefined && errorModal.show()
    userSettings.employee?.id !== undefined && errorModal.isShown && errorModal.close()
  }, [userSettings])

  const elements = isSmallDevice ? CONSTANTS.maxNavBarElements : CONSTANTS.maxTabletNavBarElements
  const bottomTabs = useMemo(() => {
    const tabs: TabNavigationScreen<keyof BottomTabParamList>[] = []

    if (isOnlyReport) {
      tabs.push(BottomTabs.report)
      tabs.push(BottomTabs.anagrafics)
    } else {
      tabs.push(BottomTabs.activities)
      tabs.push(BottomTabs.customers)
      tabs.push(BottomTabs.history)
      if (Platform.OS !== 'web') tabs.push(BottomTabs.more)
      if (user.can.see.anagrafics) tabs.push(BottomTabs.anagrafics)
      if (Platform.OS === 'web' && user.can.see.planer) tabs.push(BottomTabs.planing)
    }
    tabs.push(BottomTabs.settings)

    return navigationUtils.sortTabs(tabs, elements, menuItems)
  }, [user, isSmallDevice, elements, menuItems])

  return (
    <OptionsProvider>
      <MenuProvider bottomTabs={bottomTabs} maxDynamicElements={elements} ignoreStorage={isOnlyReport}>
        <StatusBar backgroundColor="transparent" barStyle={colorScheme === 'dark' ? 'light-content' : 'dark-content'} translucent />
        <AppContent>
          <BottomSheetModalProvider>
            <NavigationContainer basePath={basePath}>
              <>
                {!isLoggedIn && <LoginStackNavigator />}
                {isLoggedIn && (
                  <TabBarProvider>
                    <RootStackNavigator />
                  </TabBarProvider>
                )}
              </>
            </NavigationContainer>
          </BottomSheetModalProvider>
        </AppContent>
      </MenuProvider>
      <ErrorModal
        controller={errorModal}
        description={i18n.t('COLLABORATOR_NOT_ASSIGNED')}
        onHardwareBackPress={() => {
          return
        }}
        buttons={[
          {
            title: i18n.t('CHECK'),
            loading,
            onPress: loadSettings,
          },
          {
            title: i18n.t('LOGOUT'),
            color: theme.error,
            onPress: () => {
              logout().catch(err => console.error('Logout error', err))
              errorModal.close()
            },
          },
        ]}
      />
    </OptionsProvider>
  )
}
