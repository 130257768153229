import { ApiError } from '../../apis/types/apiResponseTypes'
import { AssignedIdPair, DataProviderOptions, GetResultModifierFun as GetDBModifierFun } from '../types'

interface GetModifier<TData, TRequest> {
  query: object | undefined
  modifyDBResult: GetDBModifierFun<TData, TRequest> | undefined
}

const DataProviderUtils = {
  detectIsOfflineFromFetchException(exception: unknown) {
    if (ExceptionIsResponse(exception)) {
      return false
      // TODO  404 is not an indicator for being offline. Check if other possibilities exists
      // TODO check if tunnel causes issues
      //return exception.status === 404
    }
    if (ExceptionIsError(exception)) {
      return !!exception.message?.toLowerCase().includes('network request failed')
    }
    return false
  },
  extractErrorMessageFromException(exception: unknown): string {
    if (ExceptionIsResponse(exception)) {
      return `${exception.status} ${exception.statusText}`
    }
    if (ExceptionIsError(exception)) {
      return exception.message
    }
    if (ExceptionIsApiError(exception)) {
      return exception.Message
    }
    return ''
  },
  createGetModifier<TData, TRequest>(request: TRequest | undefined, options: DataProviderOptions<TData, TRequest> | undefined) {
    const result: GetModifier<TData, TRequest> = { query: undefined, modifyDBResult: undefined }
    if (options?.get_queryProvider) result.query = options.get_queryProvider(request)
    const get_resultModifier = options?.get_resultModifier
    if (get_resultModifier) result.modifyDBResult = getResult => get_resultModifier(getResult, request)
    return result
  },
  idIsTemporaryId(id: string | number, identifierString: string, identifierNumber: number) {
    return (id && typeof id === 'string' && id.startsWith(identifierString)) || (typeof id === 'number' && id < identifierNumber)
  },
  /**
   * Searches the object for temporary ids, identified by "identifier", and replaces them with the actual values
   * @param dataIn object to check
   * @param idList list of temporary-id & erp-id pairs
   * @param identifierString used to identify values with a temporary id (identification by value.startsWith)
   * @returns object with modified ids
   */
  replaceTemporaryIds<T extends object | string | number>(
    dataIn: T,
    idList: AssignedIdPair[] | undefined,
    identifierString: string,
    identifierNumber: number
  ): T {
    if (!dataIn || !idList?.length || !identifierString) return dataIn
    const replaceId = (value: string) => {
      if (!this.idIsTemporaryId(value, identifierString, identifierNumber)) return value
      const foundId = idList.find(id => id.id === value)
      if (!foundId) return value
      return foundId.erpId
    }

    if (typeof dataIn === 'string') return replaceId(dataIn) as T
    if (typeof dataIn === 'object') {
      if (Array.isArray(dataIn)) {
        return dataIn.map((entry: object) => this.replaceTemporaryIds(entry, idList, identifierString, identifierNumber)) as T
      }
      const data = {}
      for (const key of Object.keys(dataIn)) {
        Object.assign(data, { [key]: this.replaceTemporaryIds(dataIn[key as keyof typeof dataIn] as T, idList, identifierString, identifierNumber) })
      }
      return data as T
    }
    return dataIn
  },
  resultToArray: <T>(data?: unknown): T[] => {
    if (!data) return []
    if (Array.isArray(data)) return data as T[]
    return [data] as T[]
  },
  dataContainsId: <T>(data: T): data is T & { id: string } => {
    if (data && Object.keys(data).includes('id') && (data as T & { id: string }).id) {
      return true
    }
    return false
  },
  reduceData: <T>(data: T[], reducer?: (entry: T) => T): object[] => {
    if (!data || !reducer) return data as object[]
    return data.map(d => reducer(d)) as object[]
  },
  cancelablePromise<T>(promise: Promise<T>, abortController?: AbortController) {
    return new Promise<T>((resolve, reject) => {
      abortController?.signal.addEventListener('abort', () => {
        const abortError = new Error('Aborted')
        abortError.name = 'AbortError'
        reject(abortError)
      })
      promise.then(resolve).catch(reject)
    })
  },
  objectHasKey<T>(item: T, key: string) {
    return !!item && typeof item === 'object' && Object.keys(item).includes(key) && !!item[key as keyof T]
  },
  getObjectSize(obj: unknown): number {
    if (!obj) return 0
    return JSON.stringify(obj).length
  },
  convertObjectSizeToString(sizeInBytes: number): string {
    if (!sizeInBytes) return '0 B'
    if (sizeInBytes > 1024 * 1024 * 1024) return `${(sizeInBytes / Math.pow(1024, 3)).toFixed(2)} GB`
    if (sizeInBytes >= 1024 * 1024) return `${(sizeInBytes / Math.pow(1024, 2)).toFixed(2)} MB`
    if (sizeInBytes >= 1024) return `${(sizeInBytes / 1024).toFixed(2)} KB`
    return `${sizeInBytes} B`
  },
  modifyRequest<TRequest>(apiResource: string, request?: TRequest) {
    if (!request || !apiResource.includes('{')) return { modifiedApiResource: apiResource, modifiedRequest: request }
    // split url on /
    // replace {requestToken}'s with value from request
    const modifiedRequest = { ...request }
    const modifiedApiResource = apiResource
      .split('/')
      .map(term => {
        if (!term || !term.startsWith('{') || !term.endsWith('}')) return term
        const requestKey = term.substring(1, term.length - 1) as keyof TRequest
        const value = request[requestKey]
        if (value && (typeof value === 'string' || typeof value === 'number')) {
          delete modifiedRequest[requestKey]
          return value.toString()
        }
        return ''
      })
      .filter(q => !!q)
      .join('/')

    return {
      modifiedApiResource,
      modifiedRequest,
    }
  },
  createIdFrom<T extends object | string | number>(item: T, ...keys: (keyof T)[]): Record<string, string | number> | null {
    if (typeof item === 'string' || typeof item === 'number') {
      return keys.reduce((result, key) => Object.assign(result, { [key]: item }), {})
    }
    if (typeof item === 'object') {
      return keys
        .map(key => {
          const value = item[key]
          return { key, value }
        })
        .reduce((result, current) => Object.assign(result, { [current.key]: current.value }), {})
    }
    console.error('DataProviderUtils.createIdFrom() Error: Could not create id from', item, keys)
    return null
  },
  provideSendApiResultFromData<TData>(data: TData, id: (keyof TData)[]) {
    return id.reduce((result, key) => Object.assign(result, { [key]: data[key] }), {})
  },
  getDefaultOptions<TData, TGetRequest, TPostResult>() {
    return { id: [], enableOffline: false, id_provider: (entry: TData) => entry } as DataProviderOptions<TData, TGetRequest, TPostResult>
  },
}

export default DataProviderUtils

function ExceptionIsResponse(exception: unknown): exception is Response {
  return !!(exception as Response).status
}

function ExceptionIsError(exception: unknown): exception is Error {
  return !!(exception as Error).message
}

function ExceptionIsApiError(exception: unknown): exception is ApiError {
  return !!(exception as ApiError).Message
}
