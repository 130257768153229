import { IM, IMLayout, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { createRef, useEffect, useMemo } from 'react'
import { Animated, FlatList, ListRenderItem, Platform, StyleSheet } from 'react-native'

import { apiDtoIds } from '../../apis/apiCalls'
import { GetActivityRequest, GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Activity } from '../../apis/types/apiResponseTypes'
import ActivityListCard from '../../cards/activityList/ActivityListCard'
import FlatListData from '../../components/FlatListData'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import useSearch from '../../components/screen/hooks/useSearch'
import { ADD_ACTIVITY_BUTTON_ID } from '../../constants/ButtonIds'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { ActivityFilterConfig } from '../../constants/Filters'
import useActivities from '../../hooks/activity/useActivities'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import useObjectUtils from '../../hooks/useObjectUtils'
import ReportActivityCreationModal from '../../modals/activity/ReportActivityCreationModal'
import { ReportActivityCreateOrEditViewProps } from '../activities/ReportActivityCreateOrEditView'

const buttonAnimationValue = new Animated.Value(0)
export default function ReportView() {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { isMediumDevice } = useLayout(true)
  const { compare: compareActivities } = useObjectUtils<Activity>(apiDtoIds.activity)
  const masterDetail = useMasterDetail<Activity>()
  const createActivityModal = useModalController<ReportActivityCreateOrEditViewProps>()
  const listRef = createRef<FlatList<string | Activity>>()
  const { requestFilter } = useFilter<Activity, GetActivityRequest>()
  const { groupedActivities, loadActivities, loadMoreActivities, loadingActivities } = useActivities({
    enabled: true,
    assignedMode: requestFilter?.assignedUserId?.length ? GetActivityRequestAssignedUser.AssignedTo : GetActivityRequestAssignedUser.Assigned,
    filter: requestFilter,
    searchText: search,
    filterConfig: ActivityFilterConfig,
  })

  useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED }, loadActivities)

  useEffect(() => {
    if (loadingActivities !== false) return
    const activities = groupedActivities.filter(a => typeof a !== 'string') as Activity[]
    const firstActivity = activities.at(0)
    if (!firstActivity && masterDetail.enableMDLayout) {
      masterDetail.setDetailParams(undefined)
      return
    }
    if (masterDetail.enableMDLayout && !!masterDetail.detailParams && !activities.find(a => compareActivities(a, masterDetail.detailParams))) {
      masterDetail.setDetailParams(firstActivity)
    } else if (masterDetail.enableMDLayout && !masterDetail.detailParams && activities.length) {
      masterDetail.setDetailParams(firstActivity)
    }
  }, [groupedActivities, loadingActivities])

  function onActivityPressed(activity: Activity) {
    masterDetail.navigate('Detail', {
      srvActivityId: activity.srvActivityId,
      srvActivityTypeId: activity.srvActivityTypeId,
      srvActivityYear: activity.srvActivityYear,
      title: activity.title,
    })
  }

  function handleActivityCreation() {
    createActivityModal.show({})
  }

  const showAddButton = useMemo(() => {
    return loadingActivities === false || loadingActivities === 'loadMore' || loadingActivities === 'hiddenLoading'
  }, [loadingActivities])

  const renderItem: ListRenderItem<string | Activity> | null | undefined = elem => {
    const isFirst = elem.index === 0
    const item = elem.item

    // group header
    if (typeof item === 'string') {
      return (
        <IM.View
          style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}
          spacing={'bottom'}>
          <IM.Text>{item}</IM.Text>
        </IM.View>
      )
    } else {
      return (
        <ActivityListCard
          activity={item}
          onPress={() => onActivityPressed(item)}
          forceLayout={isMediumDevice ? undefined : 'small'}
          isSelected={compareActivities(item, masterDetail.detailParams)}
          type={'normal'}
          spacing={'bottom'}
          style={[
            Platform.select({
              web: { marginLeft: 2 * IMLayout.horizontalMargin, marginRight: 2 * IMLayout.horizontalMargin },
              native: { marginHorizontal: 2 * IMLayout.horizontalMargin },
            }),
          ]}
        />
      )
    }
  }

  return (
    <IM.View style={[IMLayout.flex.f1, styles.main]}>
      <FlatListData
        ref={listRef}
        data={groupedActivities}
        loading={loadingActivities}
        noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
        renderItem={renderItem}
        isSearching={search !== ''}
        refresh={loadActivities}
        onLoadMore={loadMoreActivities}
        listSpacer
      />
      {showAddButton && (
        <>
          <AnimatedButton id={ADD_ACTIVITY_BUTTON_ID} value={buttonAnimationValue} icon={['fal', 'plus']} onPress={handleActivityCreation} />
        </>
      )}
      <ReportActivityCreationModal controller={createActivityModal} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {
    overflow: 'hidden',
  },
})
