import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Destination } from '../../apis/types/apiResponseTypes'
import CustomerDestinationCard from '../../cards/customer/CustomerDestinationCard'
import CONSTANTS from '../../constants/Constants'
import { ListSection } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import { BaseTextInputProps } from '../input/baseTextInput/BaseTextInput'
import SelectInput from './selectInput/SelectInput'

type Props = {
  customerId: number | undefined
  onChange: (value: Destination | undefined) => void
  disableFastInput?: boolean
  value?: number
  disabledInfo?: string | false
} & Pick<BaseTextInputProps, 'required' | 'spacing' | 'editable' | 'style' | 'tabIndex'>

export default function DestinationSelector({ customerId, onChange, disableFastInput, value, ...props }: Props) {
  const { i18n } = useLanguage()

  const [search, setSearch] = useState('')
  const {
    item: data,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
    setItem,
  } = useInfiniteLoader(api.customers.destinations.getList, { chuckSize: CONSTANTS.destinationsChunkSize })
  const { item: selected, loadItem: loadSelected, setItem: setSelected } = useControlledLoader(api.customers.destinations.getDetail)

  useEffect(() => {
    refresh(search)
  }, [search, customerId])

  const refresh = (searchtext?: string) => {
    if (!customerId) {
      setItem([])
      return
    }
    loadItem({ customerId, searchtext })
  }

  useEffect(() => {
    if (value && customerId && (selected?.destinationId !== value || selected.companyId !== customerId)) {
      loadSelected({ destinationId: value, customerId })
    }
    if (!value) setSelected(undefined)
  }, [value, customerId])

  const render = ({ item }: SectionListRenderItemInfo<Destination, ListSection<Destination>>, onPress?: () => void) => {
    return (
      <CustomerDestinationCard
        destination={item}
        selected={item.destinationId === selected?.destinationId}
        onPress={onPress}
        spacing={['horizontal', 'bottom']}
        hideButtons
      />
    )
  }

  const handleOnChange = (newValue: Destination | undefined) => {
    onChange(newValue)
  }

  return (
    <SelectInput
      id={apiDtoIds.destinations}
      data={data}
      value={selected ?? undefined}
      loading={customerId === undefined ? false : loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={i18n.t('DESTINATION')}
      screenTitle={i18n.t('DESTINATIONS')}
      noDataMessage={i18n.t('NO_DESTINATION_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.description ?? ''}
      allDataLoaded={allDataLoaded}
      onLoadMore={loadMore}
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      {...props}
    />
  )
}
