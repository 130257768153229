import { useContext, useEffect, useRef, useState } from 'react'
import { NativeSyntheticEvent, Platform, TextInput, TextInputKeyPressEventData } from 'react-native'

import FormContext from '../contexts/FormContext'

/**
 * Only works in combination with FormContext and the only supported ScrollView is <ScrollViewForm>
 */
export default function useFormInput(enabled = true, fixedIndex?: number) {
  const formContext = useContext(FormContext) // using useContext here to allow usage of the inputs outside of the form-provider
  const inputRef = useRef<TextInput>(null)
  const [id, setId] = useState<string | null>(null)

  useEffect(() => {
    if (Platform.OS !== 'web') return
    if (!formContext || !enabled) return
    const registeredId = formContext.registerInput(inputRef, fixedIndex)

    setId(registeredId)

    return () => {
      if (registeredId !== null) formContext.removeInput(registeredId)
    }
  }, [fixedIndex, enabled])

  function setInputFocused() {
    if (id) formContext?.setFocusedInput(id)
  }

  function onKeyPress(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    if (Platform.OS !== 'web') return
    if ((e.nativeEvent.key === 'Tab' || e.nativeEvent.key === 'Enter') && id && formContext) {
      formContext.focusNextInput(id)
      e.target.blur()
    }
  }

  return {
    inputRef,
    onKeyPress,
    setInputFocused,
  }
}
