import { IM, useTheme } from '@infominds/react-native-components'
import { Badge, Button } from 'antd'
import React, { memo } from 'react'

import { ThemeColorExpanded } from '../../types'

interface Props {
  count: number
  badgeStyle?: React.CSSProperties
  disabled?: boolean
  onClick?: () => void
}

function ChartFilter({ count, badgeStyle, disabled, onClick }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <Badge
      count={disabled ? 0 : count}
      showZero={!disabled}
      color={theme.general.info}
      offset={[-14, 6]}
      size="small"
      overflowCount={99}
      style={badgeStyle}>
      <Button type="text" onClick={onClick} disabled={disabled}>
        <IM.Icon icon={['fal', 'filter']} />
      </Button>
    </Badge>
  )
}

export default memo(ChartFilter)
