import { IM, useAlert, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import React, { useMemo, useState } from 'react'

import { api, apiDtoIds } from '../../../apis/apiCalls'
import { ActivityArticle, ActivityArticleMedia } from '../../../apis/types/apiResponseTypes'
import MediaView from '../../../components/media/MediaView'
import MediaButtons from '../../../components/MediaButtons'
import MediaSkeleton from '../../../components/skeleton/MediaSkeleton'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { useArticleMedia } from '../../../contexts/activity/ArticleMediaContext'
import useObjectUtils from '../../../hooks/useObjectUtils'
import DisplayAssetModal from '../../../modals/media/DisplayAssetModal'
import { AppMediaUtils } from '../../../utils/AppMediaUtils'
import appUtils from '../../../utils/appUtils'

export type ArticleMediaViewProps = { article?: ActivityArticle; editable?: boolean }

export default function ArticleMediaView({ article, editable }: ArticleMediaViewProps) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const alert = useAlert()
  const showAssetModal = useModalController<LiteAsset>()
  const { assets, setAssets, newAssets, setNewAssets, loadingMedia, media, reloadMedia } = useArticleMedia()
  const [deletingMedia, setDeletingMedia] = useState<string[]>([])
  const articleModifiedEvent = useEvent({ key: EVENT_KEYS.ACTIVITY_ARTICLES_UPDATED })
  const assetsToShow = useMemo(() => [...newAssets, ...assets], [assets, newAssets])

  const mediaObjectUtils = useObjectUtils<ActivityArticleMedia>(apiDtoIds.activityArticleMedia)

  function handleAssetPressed(asset: LiteAsset) {
    if (deletingMedia.includes(asset.id)) return
    if (asset.type === 'file') {
      if (newAssets.find(a => a.id === asset.id)) return
      AppMediaUtils.shareFile(asset).catch(console.error)
    } else {
      showAssetModal.show(asset)
    }
  }

  function handleDeleteAsset(assetsToDelete: LiteAsset[], skipConfirmation?: boolean) {
    const newAssetsToDelete = assetsToDelete.filter(a => newAssets.some(na => na.id === a.id))
    const backEndAssetsToDelete = assetsToDelete.filter(a => assets.some(na => na.id === a.id))

    if (newAssetsToDelete.length) {
      setNewAssets(prev => prev.filter(q => !newAssetsToDelete.some(a => a.id === q.id)))
    }
    if (backEndAssetsToDelete.length) {
      deleteMedia(backEndAssetsToDelete, skipConfirmation)
    }
  }

  function deleteMedia(assetsToDelete: LiteAsset[], skipConfirmation?: boolean) {
    if (skipConfirmation) {
      deleteMediaRequest(assetsToDelete)
      return
    }
    alert.alert(i18n.t('DELETE_MEDIA'), i18n.t('DELETE_MEDIA_ALERT'), [
      {
        text: i18n.t('REMOVE'),
        onPress: () => deleteMediaRequest(assetsToDelete),
        style: 'destructive',
      },
      {
        text: i18n.t('CANCEL'),
        style: 'cancel',
      },
    ])
  }

  function deleteMediaRequest(assetsToDelete: LiteAsset[]) {
    setDeletingMedia(prev => [...prev, ...assetsToDelete.map(q => q.id)])

    const mediaToDelete = assetsToDelete.map(a => media?.find(m => mediaObjectUtils.createId(m) === a.id)).filter(Boolean) ?? []
    if (mediaToDelete.length !== assetsToDelete.length) {
      console.warn('Some assets were not found in list of media. This indicates a bug')
    }

    Promise.allSettled(
      mediaToDelete.map(m => (m ? api.activities.articles.media.delete(mediaObjectUtils.createRequestObject(m)) : Promise.resolve()))
    )
      .then(() => {
        setAssets(prev => prev.filter(prevAssets => !assetsToDelete.some(assetToDelete => assetToDelete.id === prevAssets.id)))
      })
      .catch(error => {
        const errorMessage = appUtils.getBackendErrorMessage(error)
        alert.alert(i18n.t('API_CATCH_TITLE'), errorMessage)
        reloadMedia()
        setAssets([])
      })
      .finally(() => {
        setDeletingMedia(prev => prev.filter(prevDeletingAssets => !assetsToDelete.some(assetToDelete => assetToDelete.id === prevDeletingAssets)))
        articleModifiedEvent.emit()
      })
  }

  return (
    <IM.View>
      {!!editable && <MediaButtons round assets={newAssets} setAssets={setNewAssets} />}
      <MediaView
        spacing={'top'}
        assets={assetsToShow}
        onAssetPressed={handleAssetPressed}
        align={'left'}
        loading={loadingMedia}
        loadingSkeleton={<MediaSkeleton mediaCount={article?.mediaCount ?? 0} align={'left'} />}
        editMode={editable}
        onDeleteAsset={handleDeleteAsset}
        assetsBeingDeleted={deletingMedia}
        mediaBackgroundColor={theme.card.background}
        alwaysShowFull
      />
      <DisplayAssetModal
        assets={assetsToShow}
        controller={showAssetModal}
        onDeleteAsset={assetsToDelete => handleDeleteAsset(assetsToDelete, true)}
        allowAssetDeletion={editable}
      />
    </IM.View>
  )
}
