import { PathConfig, PathConfigMap } from '@react-navigation/native'

import {
  ActivityCreationStackParamList,
  ActivityDetailStackParamList,
  ActivityStackParamList,
  ActivityTypeStackParamList,
  AnagraficsStackParamList,
  CommonStackParamList,
  ContractDetailStackParamList,
  ContractStackParamList,
  CustomerListStackParamList,
  CustomerStackParamList,
  HistoryDetailStackParamList,
  HistoryStackParamList,
  PlannerStackParamList,
  ReportStackParamList,
  SettingsStackParamList,
  SynchronizationStackParamList,
  TimeSlotStackParamList,
  UnavailableTypeStackParamList,
} from './types'

type ConfigType = {
  initialRouteName?: keyof ReactNavigation.RootParamList | undefined
  screens: PathConfigMap<ReactNavigation.RootParamList>
}

const CommonStackConfig: PathConfig<CommonStackParamList> = {
  path: 'common',
  screens: {
    Filter: 'filter',
  },
}

// Customer
const CustomerStackConfig: PathConfig<CustomerStackParamList> = {
  path: 'customerStack',
  screens: {
    CustomerDetail: 'customerDetail',
  },
}

// Activity
const ActivityCreationStackConfig: PathConfig<ActivityCreationStackParamList> = {
  path: 'activityMod',
  screens: {
    ActivityCreation: 'mod',
  },
}

const ActivityDetailStackConfig = (basePath?: string): PathConfig<ActivityDetailStackParamList> => ({
  path: basePath ?? 'detail',
  screens: {
    ActivityDetail: '',
    ActivityDetailWork: 'work',
    ActivityCustomerStack: CustomerStackConfig,
    ActivityEditStack: ActivityCreationStackConfig,
    ActivityClosing: 'closing',
    ActivityTimeEditScreen: 'times',
    ActivityTravelEditScreen: 'travels',
    ActivityArticleEditScreen: 'articles',
    ActivityArticleSelectionScreen: 'search',
  },
})

const ActivityStackConfig: PathConfig<ActivityStackParamList> = {
  path: 'activities',
  screens: {
    Activities: '',
    ActivityCommonStack: CommonStackConfig,
  },
}

// Anagrafics
const AnagraficsActivityTypeStack: PathConfig<ActivityTypeStackParamList> = {
  path: 'types',
  screens: {
    ActivityTypeList: 'list',
  },
}

const AnagraficsContractStack: PathConfig<ContractStackParamList> = {
  path: 'contracts',
  screens: {
    ContractList: 'list',
  },
}

const ContractDetailStackConfig: PathConfig<ContractDetailStackParamList> = {
  path: 'contractDetail',
  screens: {
    ContractActivityCreation: 'create',
    ContractDetail: 'contract',
    ContractActivityDetailStack: ActivityDetailStackConfig('contract-activity-detail'),
  },
}

const AnagraficsTimeSlotStack: PathConfig<TimeSlotStackParamList> = {
  path: 'slots',
  screens: {
    TimeSlotList: 'list',
  },
}

const AnagraficsUnavailablesStack: PathConfig<UnavailableTypeStackParamList> = {
  path: 'unavailables',
  screens: {
    UnavailableTypeList: 'list',
  },
}

const AnagraficsStackConfig: PathConfig<AnagraficsStackParamList> = {
  path: 'anagrafics',
  screens: {
    Anagrafics: '',
    AnagraficsActivityTypeStack: AnagraficsActivityTypeStack,
    AnagraficsContractStack: AnagraficsContractStack,
    AnagraficsTimeSlotStack: AnagraficsTimeSlotStack,
    AnagraficsUnavailableTypeStack: AnagraficsUnavailablesStack,
  },
}

// Customers
const CustomerListStackConfig: PathConfig<CustomerListStackParamList> = {
  path: 'customers',
  screens: {
    CustomersList: '',
  },
}

// History
const HistoryStackConfig: PathConfig<HistoryStackParamList> = {
  path: 'history',
  screens: {
    History: '',
  },
}

const HistoryDetailStackConfig: PathConfig<HistoryDetailStackParamList> = {
  path: 'historyDetail',
  screens: {
    Detail: 'detail',
    ActivityArticleEditScreen: 'articlesEdit',
    ActivityMediaArticleScreen: 'mediaArticlesEdit',
    Report: 'report',
  },
}

// Report
const ReportStackConfig: PathConfig<ReportStackParamList> = {
  path: 'report',
  screens: {
    ReportScreen: '',
    Detail: 'detail',
    ReportClosing: 'ReportClosing',
    ActivityTimeEditScreen: 'times',
    ActivityTravelEditScreen: 'travels',
    ActivityArticleEditScreen: 'articles',
    ActivityArticleSelectionScreen: 'search',
  },
}

// Synch
const SynchronisationStackConfig: PathConfig<SynchronizationStackParamList> = {
  path: 'sync',
  screens: {
    Synchronization: 'sync',
  },
}

// Settings
const SettingsStackConfig: PathConfig<SettingsStackParamList> = {
  path: 'settings',
  screens: {
    Settings: 'settings',
  },
}

const PlannerStackConfig: PathConfig<PlannerStackParamList> = {
  path: 'planner',
  screens: {
    Planner: '',
    PlannerEmbed: 'embed',
  },
}

export const GetNavigationConfig = (basePath: string): ConfigType => ({
  screens: {
    BottomTab: {
      path: basePath,
      screens: {
        ActivitiesStack: ActivityStackConfig,
        AnagraficsStack: AnagraficsStackConfig,
        SynchronizationStack: SynchronisationStackConfig,
        CustomersStack: CustomerListStackConfig,
        SettingsStack: SettingsStackConfig,
        PlannerStack: PlannerStackConfig,
        HistoryStack: HistoryStackConfig,
        ReportStack: ReportStackConfig,
        MoreEmpty: {},
      },
    },
    EditNavigation: `${basePath}edit`,
    Login: `${basePath}login`,
    NotFound: `${basePath}*`,
    More: `${basePath}more`,
    Signature: `${basePath}signature`,
    ActivityDetailStack: ActivityDetailStackConfig(),
    ActivityCreation: ActivityCreationStackConfig,
    CustomerStack: CustomerStackConfig,
    ActivityTypeCreateOrEdit: 'activityTypeCreateOrEdit',
    TimeSlotCreateOrEdit: 'timeSlotCreateOrEdit',
    UnavailableTypeCreateOrEdit: 'unavailableTypeCreateOrEdit',
    ContractDetailStack: ContractDetailStackConfig,
    HistoryDetailStack: HistoryDetailStackConfig,
  },
})
