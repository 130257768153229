import { useContext } from 'react'

import { PWAInstallContext } from './PWAInstallProvider'

export default function usePWAInstall() {
  const context = useContext(PWAInstallContext)

  if (context === undefined) {
    throw new Error('useAnimation() must be called inside AnimationProvider')
  }

  return context
}
