import { IM, IMLayout, SpacingProps } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { LayoutChangeEvent, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import useEmployeeColor from '../hooks/useEmployeeColor'
import { EmployeeUtils } from '../utils/EmployeeUtils'
import BlobImage from './BlobImage'
import EmployeeBadgePlaceholderText from './EmployeeBadgePlaceholderText'

interface Props {
  id: string | number | undefined
  name: string
  color?: string
  blobPlaceholderColor?: string
  spacing?: SpacingProps
  showName?: boolean
  style?: StyleProp<ViewStyle>
  size?: number
  onLayout?: (event: LayoutChangeEvent) => void
  fetchImage?: boolean
}

const IMAGE_DIMENSION = 26

const EmployeeBadge = memo(function EmployeeBadge({
  id,
  name,
  color,
  spacing,
  showName,
  blobPlaceholderColor,
  style,
  size,
  onLayout,
  fetchImage = true,
}: Props) {
  const { url } = useAuthentication()
  const { getEmployeeColor } = useEmployeeColor()
  const [done, setDone] = useState(false)
  const [error, setError] = useState(false)

  const progressImage = useSharedValue(0)
  const progressBackground = useSharedValue(1)

  const initials = useMemo(() => EmployeeUtils.calculateInitials(name), [name])
  const badgeColor = useMemo(() => getEmployeeColor(id), [id])
  const uri = id ? `${url ?? ''}/api/employees/image/${id}` : undefined

  useEffect(() => {
    if (!done) return

    progressImage.value = withTiming(1, { duration: 500, easing: Easing.in(Easing.quad) })
    progressBackground.value = withTiming(0, { duration: 500, easing: Easing.out(Easing.circle) })
  }, [done])

  const animatedImageStyles = useAnimatedStyle(() => {
    return {
      opacity: progressImage.value,
    }
  }, [])

  const animatedBackgroundStyles = useAnimatedStyle(() => {
    return {
      opacity: progressBackground.value,
    }
  }, [])

  return (
    <IM.View style={[IMLayout.flex.row, styles.container, style]} spacing={spacing} onLayout={onLayout}>
      <Animated.View
        style={[
          styles.badge,
          { width: size ?? IMAGE_DIMENSION, height: size ?? IMAGE_DIMENSION, backgroundColor: color ?? badgeColor },
          animatedBackgroundStyles,
        ]}>
        <EmployeeBadgePlaceholderText initials={initials} size={size} />
      </Animated.View>
      <Animated.View
        style={[
          styles.badge,
          styles.imageBadge,
          {
            width: size ?? IMAGE_DIMENSION,
            height: size ?? IMAGE_DIMENSION,
            backgroundColor: color ?? badgeColor,
          },
          animatedImageStyles,
        ]}>
        {error || !fetchImage ? (
          <EmployeeBadgePlaceholderText initials={initials} size={size} />
        ) : (
          <BlobImage
            uri={uri}
            height={size ?? IMAGE_DIMENSION}
            width={size ?? IMAGE_DIMENSION}
            style={{ borderRadius: IMLayout.iconRadius }}
            onError={setError}
            onDone={setDone}
            color={blobPlaceholderColor ?? color}
          />
        )}
      </Animated.View>
      {showName && (
        <IM.View spacing="left" style={IMLayout.flex.f1}>
          <IM.Text>{name}</IM.Text>
        </IM.View>
      )}
    </IM.View>
  )
})

export default EmployeeBadge

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  badge: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMLayout.iconRadius,
  },
  imageBadge: {
    position: 'absolute',
  },
})
