import { IM, IMLayout, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'

import { api } from '../../apis/apiCalls'
import { ActivitySendEmailRequest } from '../../apis/types/apiRequestTypes'
import { Activity } from '../../apis/types/apiResponseTypes'
import ActivityButton from '../../components/ActivityButton'
import useRequest from '../../components/Infominds/hooks/useRequest'
import EmailInput from '../../components/input/EmailInput'
import { Screen } from '../../components/screen/Screen'
import ScrollViewData from '../../components/ScrollViewData'
import EmailModuleSelector from '../../components/selectors/EmailModuleSelector'
import { AppStyle } from '../../constants/Styles'
import useEmailNotifications from '../../hooks/email/useEmailNotifications'
import { ValidationUtils } from '../../utils/ValidationUtils'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController
  activity: Activity
  onEmailSent?: () => void
}

export default function SendActivityEmailModal({ controller, activity, ...props }: Props) {
  const { i18n } = useLanguage()

  return (
    <BaseServiceModal controller={controller}>
      <Screen transparent>
        <Screen.Header goBack={() => controller.close()} isModal title={i18n.t('SEND_MAIL')} />
        <Screen.Content>
          <Content controller={controller} activity={activity} {...props} />
        </Screen.Content>
      </Screen>
    </BaseServiceModal>
  )
}

function Content({ controller, activity, onEmailSent }: Props) {
  const { i18n } = useLanguage()
  const emailNotifications = useEmailNotifications()
  const [state, setState] = useState<ActivitySendEmailRequest>({
    srvActivityId: activity.srvActivityId,
    srvActivityTypeId: activity.srvActivityTypeId,
    srvActivityYear: activity.srvActivityYear,
    srvEmailId: activity.srvEmailId,
    srvEmailAdresses: activity.srvEmailAdresses,
    addReport: !!activity.referToDocId,
  })

  function handleChange(data: Partial<ActivitySendEmailRequest>) {
    setState(prev => ({ ...prev, ...data }))
  }

  const { request: sendEmail, loading: busySendingEmail } = useRequest(api.activities.email.send, {
    showErrorAlert: false,
    onSuccess: result => {
      if (result.hasSended) {
        onEmailSent?.()
        controller.close()
        return
      }

      emailNotifications.onError(result.message)
    },
    onError: error => emailNotifications.onError(error),
  })

  const canSendEmail = useMemo(
    () => !!state.srvEmailId && !!state.srvEmailAdresses && !!activity && ValidationUtils.email(state.srvEmailAdresses, ';'),
    [state]
  )

  return (
    <ScrollViewData>
      <EmailInput
        title={i18n.t('RECEIVER')}
        placeholder={i18n.t('RECEIVER')}
        value={state.srvEmailAdresses}
        onChangeText={value => handleChange({ srvEmailAdresses: value })}
        spacing={'bottom'}
        required
      />
      <EmailModuleSelector
        value={state.srvEmailId}
        srvActivityTypeId={activity.srvActivityTypeId}
        onChange={value => handleChange({ srvEmailId: value?.srvEmailId })}
        editable
        spacing={'bottom'}
        required
        autoSelect
      />
      <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd]} spacing={'top'}>
        <ActivityButton
          title={i18n.t('SEND_MAIL')}
          icon={['fal', 'paper-plane']}
          loading={busySendingEmail === 'reloading'}
          onPress={() => sendEmail(state)}
          disabled={!canSendEmail}
        />
      </IM.View>
    </ScrollViewData>
  )
}
