import { IMLayout } from '@infominds/react-native-components'
import { Platform, StyleSheet } from 'react-native'

export const AppStyle = StyleSheet.create({
  card: {
    ...Platform.select({
      web: { marginLeft: 2 * IMLayout.horizontalMargin, marginRight: 2 * IMLayout.horizontalMargin },
      native: { marginHorizontal: 2 * IMLayout.horizontalMargin },
    }),
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  justifyContentStart: {
    justifyContent: 'flex-start',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  border: {
    borderWidth: 1,
  },
})
