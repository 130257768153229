import React, { Children, cloneElement, PropsWithChildren, ReactElement, useMemo } from 'react'

import useLayout, { ForceLayoutType } from '../hooks/useLayout'

function DynamicLayoutView({
  children,
  detectMediumDevice,
  forceLayout,
}: PropsWithChildren<{ detectMediumDevice?: boolean; forceLayout?: ForceLayoutType | undefined }>) {
  const { isSmallDevice, isMediumDevice, isLargeDevice } = useLayout(detectMediumDevice, forceLayout)

  const { SmallLayoutComponent, MediumLayoutComponent, LargeLayoutComponent } = useMemo(() => {
    const childArray = Children.toArray(children)
    return {
      SmallLayoutComponent: childArray.find(q => (q as ReactElement).type === SmallLayout) as ReactElement,
      MediumLayoutComponent: childArray.find(q => (q as ReactElement).type === MediumLayout) as ReactElement,
      LargeLayoutComponent: childArray.find(q => (q as ReactElement).type === LargeLayout) as ReactElement,
    }
  }, [children])

  if (isSmallDevice && !SmallLayoutComponent) throw new Error('DynamicLayoutView missing <DynamicLayoutView.Small> component')
  if (isMediumDevice && !MediumLayoutComponent) throw new Error('DynamicLayoutView missing <DynamicLayoutView.Medium> component')
  if (isLargeDevice && !LargeLayoutComponent) throw new Error('DynamicLayoutView missing <DynamicLayoutView.Large> component')

  if (isSmallDevice) return cloneElement(SmallLayoutComponent)
  if (isMediumDevice) return cloneElement(MediumLayoutComponent)
  if (isLargeDevice) return cloneElement(LargeLayoutComponent)
  return <></>
}

function SmallLayout({ children }: PropsWithChildren) {
  return <>{children}</>
}

function MediumLayout({ children }: PropsWithChildren) {
  return <>{children}</>
}

function LargeLayout({ children }: PropsWithChildren) {
  return <>{children}</>
}

const DynamicLayoutViewNameSpace = Object.assign(DynamicLayoutView, {
  Small: SmallLayout,
  Medium: MediumLayout,
  Large: LargeLayout,
})

export { DynamicLayoutViewNameSpace as DynamicLayoutView }
