import React, { createContext, PropsWithChildren, useState } from 'react'

import { STORAGE_KEYS } from '../../constants/Keys'
import useAsyncStorageState from '../../hooks/useAsyncStorageState'
import { ActivityId } from '../../types'

interface ReportActivityContextType {
  activity: ActivityId | undefined
  setActivity: React.Dispatch<React.SetStateAction<ActivityId | undefined>>
  isNewActivity: boolean
  setIsNewActivity: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReportActivityContext = createContext<ReportActivityContextType | null>(null)

export function ReportActivityProvider({ children }: PropsWithChildren) {
  const [activity, setActivity] = useAsyncStorageState<ActivityId | undefined>(STORAGE_KEYS.REPORT_ACTIVITY, undefined)
  const [isNewActivity, setIsNewActivity] = useState(false)

  return (
    <ReportActivityContext.Provider value={{ activity, setActivity, isNewActivity, setIsNewActivity }}>{children}</ReportActivityContext.Provider>
  )
}

export function useReportActivity() {
  const context = React.useContext(ReportActivityContext)
  if (!context) {
    throw new Error('useReport must be used within ReportProvider')
  }
  return context
}
