import { IM, IMLayout, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Technician } from '../apis/types/apiResponseTypes'
import useEmployeeColor from '../hooks/useEmployeeColor'
import useUserSettings from '../hooks/useUserSettings'
import { EmployeeUtils } from '../utils/EmployeeUtils'
import EmployeeBadge from './EmployeeBadge'
import Pressable from './Infominds/Pressable'

export type AssignedUserBadgeListProps = {
  employees: Technician[]
  style?: StyleProp<ViewStyle>
  maxEmployees?: number
  /**
   * Include one more employee if it is the last. In this case the number of employees displayed is maxEmployees + 1.
   * Since +n is not displayed in this case because there are no more employees some space is saved and can be filled with another employee badge
   */
  allowExtraIfLast?: boolean
  size?: number
  onPress?: () => void
}

const OVERLAP = 0.2

export default function AssignedUserBadgeList({
  employees,
  style,
  maxEmployees = 2,
  allowExtraIfLast: includeOneMoreIfLast,
  size = 40,
  onPress,
}: AssignedUserBadgeListProps) {
  const { userSettings } = useUserSettings()
  const { getEmployeeColor } = useEmployeeColor()

  const employeesSortedAndFiltered = useMemo(
    () =>
      EmployeeUtils.sort(
        Utils.keepUniques(employees, e => e.id),
        userSettings?.employee?.id ? [userSettings?.employee?.id] : undefined
      ),
    [employees]
  )

  const employeeCountToDisplay = useMemo(() => {
    if (employeesSortedAndFiltered.length <= maxEmployees || (includeOneMoreIfLast && employeesSortedAndFiltered.length === maxEmployees + 1)) {
      return employeesSortedAndFiltered.length
    }
    return maxEmployees
  }, [employeesSortedAndFiltered, maxEmployees, includeOneMoreIfLast])

  return (
    <Pressable style={[IMLayout.flex.row, style]} disabled={!onPress} onPress={onPress}>
      {employeesSortedAndFiltered.slice(0, employeeCountToDisplay + 1).map((employee, index) => {
        if (index === employeeCountToDisplay) {
          return (
            <IM.View key="remainingIndicator" style={styles.addInfo}>
              <IM.Text secondary>+{employeesSortedAndFiltered.length - maxEmployees}</IM.Text>
            </IM.View>
          )
        }
        return (
          <EmployeeBadge
            key={employee.id}
            size={size}
            name={EmployeeUtils.getName(employee)}
            id={employee.id}
            style={[styles.badge, { zIndex: employees.length - index }, index > 0 && { marginLeft: -size * OVERLAP }]}
            color={getEmployeeColor(employee)}
            fetchImage={employee.technicianType === 'employee'}
          />
        )
      })}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  badge: { alignItems: 'center' },
  overlap: {
    marginLeft: -10,
  },
  addInfo: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 3,
  },
})
