import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { ListSection } from '../../types'
import CardLeftTitle from '../card/left/CardLeftTitle'
import SelectInput, { SelectInputProps } from '../selectors/selectInput/SelectInput'
import { RepetitionUtils } from './RepetitionUtils'

type WeekDay = { index: number; text: string }

export type DaySelectorProps = { value: number | undefined; onChange: (value: number | undefined) => void } & Pick<
  SelectInputProps<WeekDay>,
  'title' | 'required' | 'editable' | 'placeholder'
>

export default function DaySelector({ value, onChange, ...selectorProps }: DaySelectorProps) {
  const { theme } = useTheme()
  const { i18n, language } = useLanguage()

  const weekDays = useMemo(() => {
    const wd = RepetitionUtils.getDays(language).map<WeekDay>((text, index) => ({ index, text }))
    const wd0 = wd.shift() as WeekDay
    return [...wd, wd0]
  }, [])
  const selectedDay = useMemo(() => (value !== undefined ? weekDays.find(w => w.index === value) : undefined), [value])

  const render = ({ item }: SectionListRenderItemInfo<WeekDay, ListSection<WeekDay>>, onPress?: () => void) => {
    return (
      <IM.Card
        head={<CardLeftTitle color={item.index === selectedDay?.index && theme.primary} />}
        defaultContent={{ texts: [item.text] }}
        onPress={onPress}
        spacing={['bottom', 'horizontal']}
      />
    )
  }

  return (
    <SelectInput
      id={['index']}
      data={weekDays}
      value={selectedDay}
      screenTitle={i18n.t('DAY')}
      renderItem={render}
      onChange={item => onChange(item?.index)}
      renderSelectedString={item => item.text}
      disableFastInput
      allDataLoaded={undefined}
      onLoadMore={undefined}
      noDataMessage=""
      loading={false}
      {...selectorProps}
    />
  )
}
