import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { SearchProvider } from '../../../components/screen/contexts/SearchContext'
import { Screen } from '../../../components/screen/Screen'
import UnavailableTypeListView from '../../../views/activities/unavailables/UnavailableTypeListView'

export default function UnavailableTypeListScreen() {
  const { i18n } = useLanguage()

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header goBack title={i18n.t('UNAVAILABLE_TYPES')} />

        <Screen.Content>
          <UnavailableTypeListView />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
