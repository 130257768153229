import { DateUtils, IM, IMLayout, Language } from '@infominds/react-native-components'
import React, { memo, useMemo, useRef } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import { TextInputRef } from '../../types'
import TimeUtils from '../../utils/TimeUtils'
import { BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'
import DateInput from './DateInput'
import TimeInput from './TimeInput'

type TextInputProps = Pick<BaseTextInputProps, 'editable' | 'value' | 'spacing'> &
  Pick<BaseTextInputProviderProps, 'error'> & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    minimumDate?: Date
    maximumDate?: Date
    onChangeDate: (date: Date | undefined) => void
    datePickerDefaultValue?: string
  }

export const convertDateToInputFormat = (date: Date, language: Language) => {
  return TimeUtils.formatDateTime(date, language)
}

export const convertInputToDate = (input?: string) => {
  if (!input) return undefined
  try {
    return new Date(input)
  } catch {
    return undefined
  }
}

export const convertInputToTime = (input: string | undefined) => {
  if (!input) return undefined
  const date = new Date(input)
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

const DateTimeInput = memo(function DateTimeInput({
  title,
  details,
  titleFontWeight,
  editable = true,
  minimumDate,
  maximumDate,
  onChangeDate,
  value,
  error,
  datePickerDefaultValue = new Date().toISOString(),
  spacing,
}: TextInputProps) {
  const timeInputRef = useRef<TextInputRef>(null)
  const time = useMemo(() => convertInputToTime(value), [value])

  function handleTimeChanged(newTime: string | undefined) {
    onChangeDate(TimeUtils.addTimeSpanToDate(value || new Date(), newTime))
  }

  function handleChangeDate(newDate: Date | undefined) {
    if (!value || !newDate) {
      onChangeDate(newDate)
      return
    }
    const date = new Date(value)
    date.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
    onChangeDate(date)
  }

  const timePickerDefaultValue = useMemo(
    () => (datePickerDefaultValue ? TimeUtils.minutesToTimeSpan(TimeUtils.getMinutesOfDay(DateUtils.dateify(datePickerDefaultValue))) : undefined),
    [datePickerDefaultValue]
  )

  return (
    <IM.View spacing={spacing}>
      {!!title && (
        <IM.View spacing={'none'}>
          <IM.Text primary style={[!!titleFontWeight && { fontWeight: titleFontWeight }]}>
            {title}
          </IM.Text>
          {!!details && <IM.Text secondary>{details}</IM.Text>}
        </IM.View>
      )}
      <IM.View style={[IMLayout.flex.row, styles.container]}>
        <IM.View style={IMLayout.flex.f1}>
          <DateInput
            value={value}
            onChangeDate={handleChangeDate}
            editable={editable}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            error={error}
            defaultValue={datePickerDefaultValue}
            onSubmitEditing={() => setTimeout(() => timeInputRef.current?.focus(), 50)} // delay focus slightly because text is not jet set
            selectTextOnFocus
          />
        </IM.View>
        <IM.View style={IMLayout.flex.f1}>
          <TimeInput
            textInputRef={timeInputRef}
            value={time}
            style={IMLayout.flex.f1}
            onChangeTime={handleTimeChanged}
            editable={editable}
            error={error}
            selectTextOnFocus
            defaultPickerValue={timePickerDefaultValue}
          />
        </IM.View>
      </IM.View>
    </IM.View>
  )
})

export default DateTimeInput

const styles = StyleSheet.create({
  container: {
    gap: IMLayout.horizontalMargin * 2,
  },
})
