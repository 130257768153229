import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api } from '../../../apis/apiCalls'
import { TimeSlot } from '../../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../../components/ApiDeleteButton'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../../components/Infominds/hooks/useEditDataHandler'
import MultiLangTextInput from '../../../components/input/MultiLangTextInput'
import NumberInput from '../../../components/input/NumberInput'
import { TextInputProps } from '../../../components/input/TextInput'
import TimeInput from '../../../components/input/TimeInput'
import ScrollViewForm from '../../../components/ScrollViewForm'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { EditOrCreateViewRef, UploadStatus } from '../../../types'

type Props = {
  timeSlot?: TimeSlot
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

function TimeSlotCreateOrEditView({ timeSlot, onDone, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) {
  const { i18n } = useLanguage()

  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))
  const requiredFields = useMemo<EditDataHandlerRequiredFields<TimeSlot>>(() => ['hourFrom', 'hourTo', ['titleDe', 'titleEn', 'titleIt']], [])
  const { state, editMode, createOrUpdate, handleDataChange } = useEditDataHandler(
    api.activities.timeSlots.post,
    api.activities.timeSlots.put,
    undefined,
    {
      eventKeyCreation: EVENT_KEYS.UPDATED_TIME_SLOTS,
      eventKeyModification: EVENT_KEYS.UPDATED_TIME_SLOTS,
      onDone: onDone,
      onUploadStatus: onUploadStatus,
      editMode: !!timeSlot,
      initialValue: timeSlot,
      showErrorAlert: true,
      requiredFields,
    }
  )

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <MultiLangTextInput
        required
        title={i18n.t('TITLE')}
        placeholder={i18n.t('TITLE')}
        values={{ de: state?.titleDe, en: state?.titleEn, it: state?.titleIt }}
        onChangeText={values => handleDataChange({ titleEn: values.en, titleDe: values.de, titleIt: values.it })}
        multiline
        {...commonProps}
      />
      <MultiLangTextInput
        title={i18n.t('DESCRIPTION')}
        placeholder={i18n.t('DESCRIPTION')}
        values={{ de: state?.descriptionDe, en: state?.descriptionEn, it: state?.descriptionIt }}
        onChangeText={values => handleDataChange({ descriptionEn: values.en, descriptionDe: values.de, descriptionIt: values.it })}
        multiline
        {...commonProps}
      />
      <IM.View style={[IMLayout.flex.row, { gap: 3 * IMLayout.horizontalMargin }]}>
        <TimeInput
          required
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('TIME_FROM')}
          value={state?.hourFrom}
          onChangeTime={value => handleDataChange({ hourFrom: value })}
          limitTo24h
          {...commonProps}
        />

        <TimeInput
          required
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('TIME_UNTIL')}
          value={state?.hourTo}
          onChangeTime={value => handleDataChange({ hourTo: value })}
          limitTo24h
          {...commonProps}
        />
      </IM.View>
      <NumberInput
        disableFastInputs
        title={i18n.t('INCREASE_PERCENTAGE')}
        value={state?.increasePercentage}
        onValueChanged={value => handleDataChange({ increasePercentage: value })}
        unit={'%'}
        {...commonProps}
      />
      {editMode && !!timeSlot && (
        <ApiDeleteButton
          onDeleted={onDone}
          data={timeSlot}
          deleteAlertMessage={i18n.t('DELETE_TIME_SLOT_ALERT')}
          deleteAlertTitle={i18n.t('DELETE')}
          deleteRequest={api.activities.timeSlots.delete}
          eventKey={EVENT_KEYS.UPDATED_TIME_SLOTS}
          {...commonProps}
        />
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(TimeSlotCreateOrEditView)
