import { IM, useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { GetActivityRequest, GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Activity } from '../../apis/types/apiResponseTypes'
import ActivityCarousel from '../../components/activity/ActivityCarousel'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import MapMarker from '../../components/map/components/MapMarker'
import Map from '../../components/map/Map'
import useSearch from '../../components/screen/hooks/useSearch'
import { OpenActivityStates } from '../../constants/Constants'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useActivities from '../../hooks/activity/useActivities'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import { activityUtils } from '../../utils/ActivityUtils'
import { ActivityListProps } from './ActivityListView'
import ActivityMapList from './ActivityMapList'

type Props = {
  hideAddButton?: (hide: boolean) => void
} & ActivityListProps

const HEIGHT = 270

export default function ActivityListMapView(props: Props) {
  const { isLargeDevice } = useLayout(true)

  if (isLargeDevice) return <MapView {...props} />
  return <SmallDeviceMapView {...props} />
}

// carousel activities are handled separately
function SmallDeviceMapView(props: Props) {
  const { user, taskType } = useUserSettings()

  const { search } = useSearch()
  const { requestFilter: ogFilter } = useFilter<Activity, GetActivityRequest>()

  const requestFilter = useMemo<GetActivityRequest | undefined>(() => ({ ...ogFilter, groupBy: undefined, taskType }), [ogFilter])

  const {
    activities,
    loadMoreActivities,
    loadingActivities: loading,
  } = useActivities({
    enabled: true,
    assignedMode: user.can.see.allActivities ? GetActivityRequestAssignedUser.Assigned : GetActivityRequestAssignedUser.AssignedTo,
    states: OpenActivityStates,
    filter: requestFilter,
    searchText: search,
  })

  return <MapView {...props} activities={activities} loadMoreActivities={loadMoreActivities} loading={loading} carousel />
}

function MapView({
  activities,
  loading,
  buttonAnimationValue,
  reloadActivities,
  onCreate,
  onActivityPressed,
  loadMoreActivities,
  carousel,
  ...others
}: Props & { carousel?: boolean }) {
  // const { isLargeDevice } = useLayout(true)
  const { i18n } = useLanguage()
  const { isOnline } = useDataProvider()
  const { user } = useUserSettings()

  const unassignedActivities = others.unassignedActivities

  const allActivities = useMemo(() => {
    if (!unassignedActivities?.length) return activities ?? []
    if (!activities.length) return unassignedActivities ?? []
    return [...activities, i18n.t('UNASSIGNED'), ...unassignedActivities]
  }, [activities, unassignedActivities])
  const allActivitiesWithoutHeaders = useMemo(() => allActivities.filter(activityUtils.itemIsActivity), [allActivities])
  const activitiesWithoutHeaders = useMemo(() => activities.filter(activityUtils.itemIsActivity), [activities])

  const mapMarker = useCallback((item: Activity, selected: boolean) => <MapMarker activity={item} selected={selected} />, [])

  return (
    <>
      <Map
        items={allActivitiesWithoutHeaders}
        mapMarkerRenderItem={mapMarker}
        mapMarkerKeyExtractor={activityUtils.createId}
        centerButtonBottom={!carousel ? 10 : HEIGHT}
        mapPadding={
          carousel
            ? {
                bottom: HEIGHT,
                top: 0,
                left: 0,
                right: 0,
              }
            : undefined
        }
        onSelectedMarkerPressed={onActivityPressed}
        alignChildren={carousel ? 'bottom' : 'left'}
        mapRenderPopUp={item => (item.title ?? '') + '\n' + (item.customerDescription ?? '')}>
        {carousel && (
          <ActivityCarousel
            height={HEIGHT}
            onActivityPressed={onActivityPressed}
            activities={activitiesWithoutHeaders}
            loading={loading}
            loadMoreActivities={loadMoreActivities}
          />
        )}
        {!carousel && (
          <IM.View style={Platform.OS === 'web' ? styles.listViewWeb : styles.listViewTablet}>
            <ActivityMapList
              activities={activities ?? []}
              loading={loading}
              reloadActivities={reloadActivities}
              showSmallDeviceCards
              buttonAnimationValue={buttonAnimationValue}
              onCreate={onCreate}
              onActivityPressed={onActivityPressed}
              loadMoreActivities={loadMoreActivities}
              {...others}
            />
          </IM.View>
        )}
      </Map>
      {carousel && loading === false && isOnline && user.can.add.activities && (
        <>
          <AnimatedButton id="MapButton" value={buttonAnimationValue} icon={['fal', 'plus']} onPress={onCreate} />
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  listViewWeb: {
    width: '30%',
    minWidth: 450,
  },
  listViewTablet: {
    width: '40%',
    minWidth: 450,
  },
})
