import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'

export type ErrorProps = {
  message?: string
  iconSize?: number
  style?: StyleProp<ViewStyle>
}

export default function Error({ message, style, iconSize = 26 }: ErrorProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={[style, styles.main]}>
      <IM.View style={styles.container}>
        <IM.Icon icon={['fas', 'triangle-exclamation']} color={theme.general.error} size={iconSize} />
        <IM.Text
          style={[
            {
              color: theme.general.error,
            },
            styles.text,
          ]}>
          {message ?? i18n.t('ERROR')}
        </IM.Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {
    alignItems: 'center',
  },
  container: {
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 400,
  },
  text: {
    fontSize: IMStyle.typography.fontSizeSmall - 2,
    marginTop: IMLayout.horizontalMargin / 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
    textAlign: 'center',
  },
})
