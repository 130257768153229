import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { api } from '../../../apis/apiCalls'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import { ActivityDetailStackParamList } from '../../../navigation/types'
import CustomerDetailCard from '../../customer/CustomerDetailCard'

interface Props {
  error?: ConstrainBoolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  disabled?: boolean
}

export default function ActivityDetailCustomerIdCard({ error, spacing, style, disabled }: Props) {
  const { i18n } = useLanguage()
  const navigation = useNavigation<NavigationProp<ActivityDetailStackParamList>>()
  const { detail } = useActivityDetail()

  const { item: customer, loadItem: loadCustomer, loading: loadingCustomer, setItem: setCustomer } = useControlledLoader(api.customers.getDetail)

  useEffect(() => {
    load()
  }, [detail?.companyId])

  function load() {
    if (!detail?.companyId) {
      setCustomer(undefined)
      return
    }
    loadCustomer({ customerId: detail.companyId })
  }

  return (
    <>
      <CustomerDetailCard
        customer={customer}
        leftText={i18n.t('CUSTOMER')}
        disabled={!customer || disabled}
        enableChange={false}
        loading={loadingCustomer}
        onPress={
          !error && !!customer
            ? () => {
                navigation.navigate('ActivityCustomerStack', {
                  screen: 'CustomerDetail',
                  params: { customerId: customer.companyId, customerName: customer.company1 },
                })
              }
            : undefined
        }
        style={style}
        spacing={spacing}
      />
    </>
  )
}
