import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from 'react'

export type SearchContextProps = {
  search: string
  setSearch: (text: string, isBarcodeSearch?: boolean) => void
  tabletOpen: boolean
  setTabletOpen: Dispatch<SetStateAction<boolean>>
  qrCodeScanned: boolean
  mode: string
  setMode: React.Dispatch<React.SetStateAction<string>>
  onSubmit?: () => void
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined)

type SearchProviderProps<TMode extends string = string> = PropsWithChildren<{
  defaultMode?: TMode
  defaultTabletOpen?: boolean
  onSubmit?: () => void
}>
export function SearchProvider<TMode extends string = string>({ children, defaultMode, defaultTabletOpen, onSubmit }: SearchProviderProps<TMode>) {
  const [search, setSearch] = useState('')
  const [qrCodeScanned, setQrCodeScanned] = useState(false)
  const [tabletOpen, setTabletOpen] = useState(!!defaultTabletOpen)
  const [mode, setMode] = useState(defaultMode ?? '')

  const handleSearch = (text: string, isBarcodeSearch = false) => {
    setSearch(text)
    setQrCodeScanned(isBarcodeSearch)
  }

  const props = useMemo<SearchContextProps>(
    () => ({
      search,
      setSearch: handleSearch,
      tabletOpen,
      setTabletOpen,
      qrCodeScanned,
      mode,
      setMode,
      onSubmit,
    }),
    [search, mode, tabletOpen, onSubmit]
  )

  return <SearchContext.Provider value={props}>{children}</SearchContext.Provider>
}

export default SearchContext
