import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../types'
import PressableIcon, { PressableIconProps } from '../Infominds/PressableIcon'
import useScreen from './hooks/useScreen'
import { DetailHeaderType } from './types'

interface Props {
  type: DetailHeaderType
  changesView?: boolean
  highlight?: boolean
  indicatorNumber?: number
}

const HeaderIcon = ({ type, highlight, indicatorNumber, style, ...props }: PressableIconProps & Props) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { detail, setDetailOpen } = useScreen()

  function handlePressed() {
    setDetailOpen(type)
  }

  return (
    <>
      <IM.View
        style={
          detail.open &&
          detail.type === type && {
            backgroundColor: theme.header.detail.background,
            borderRadius: IMLayout.iconRadius,
          }
        }>
        <PressableIcon color={highlight ? theme.general.info : IMStyle.palette.white} onPress={handlePressed} style={style} {...props} />
      </IM.View>
      {!!indicatorNumber && (
        <Pressable onPress={handlePressed} {...props} style={[{ backgroundColor: theme.primary }, styles.indicatorPressable]}>
          <IM.Text style={styles.indicatorText}>{indicatorNumber}</IM.Text>
        </Pressable>
      )}
    </>
  )
}

export default HeaderIcon

const styles = StyleSheet.create({
  indicatorText: {
    fontSize: 12,
    color: '#fff',
    padding: 0,
    margin: 0,
    marginTop: -1,
    marginLeft: 0,
  },
  indicatorPressable: {
    position: 'absolute',
    top: 4,
    right: -2,

    padding: 0,
    borderRadius: 18,
    width: 18,
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
