import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import { SkipTake } from '../../../apis/types/apiRequestTypes'
import useBackgroundWorker from '../../../hooks/useBackgroundWorker'
import { InfiniteLoadingType } from '../../../types'
import appUtils from '../../../utils/appUtils'

type Options<T> = {
  chuckSize: number
  onLoadedCallback?: (items: T[], reload: boolean) => void
  onErrorCallback?: (reload: boolean) => void
  initialData?: T[]
  backgroundUpdaterInterval?: number
  dontResetOnLoad?: boolean
}

interface Result<TRequest, TResponse> {
  item: TResponse[]
  loadItem: (request: TRequest, hidden?: boolean) => void
  loading: InfiniteLoadingType
  loadMore: () => void
  setItem: Dispatch<SetStateAction<TResponse[]>>
  allDataLoaded: boolean
  lastSuccessfulRequest: TRequest | null
  reloadInBackground: () => void
}

/**
 * Hook manages data for dynamically growing list.
 * Load can be used to load first time or to reload.
 * LoadMore can be used f.ex. onListEndReached
 * @param getData function to get Data
 * @param options loader options
 * @returns {items, loadItems, loading, loadMore, setItems, allDataLoaded}
 */
export default function useInfiniteLoader<TRequest extends SkipTake, TResponse>(
  getData: (request?: TRequest, abortController?: AbortController) => Promise<TResponse[]>,
  options: Options<TResponse>
): Result<TRequest, TResponse> {
  const { chuckSize, onLoadedCallback, onErrorCallback } = options

  const [item, setItem] = useState<TResponse[]>(options.initialData ?? [])
  const [loading, setLoading] = useState<InfiniteLoadingType>('init')
  const [loadCounter, setLoadCounter] = useState(0)
  const [endOfListCounter, setEndOfListCounter] = useState(0)
  const allDataLoaded = useRef(false)
  const request = useRef<TRequest | null>(null)
  const lastSuccessfulRequest = useRef<TRequest | null>(null)

  useEffect(() => {
    const abortController = new AbortController()
    loader(abortController, loading === 'reloading')
    return () => abortController.abort()
  }, [loadCounter, endOfListCounter])

  const backgroundReloader = useCallback(() => {
    const memRequest = lastSuccessfulRequest.current
    if (!memRequest) return
    const abortController = new AbortController()
    getData({ ...memRequest, skip: 0, take: item.length }, abortController)
      .then(result => {
        if (appUtils.createSimpleHash(result) !== appUtils.createSimpleHash(item)) {
          handleResult(result, memRequest, true)
        }
      })
      .catch(() => {
        lastSuccessfulRequest.current = null
      })
  }, [item])
  const { triggerCallback: reloadInBackground } = useBackgroundWorker(backgroundReloader, options?.backgroundUpdaterInterval)

  function loader(controller: AbortController, reload?: boolean) {
    if ((!reload && allDataLoaded.current) || !request.current) return null

    const skip = reload ? 0 : item.length
    const memRequest = { ...request.current }
    const skipTake = chuckSize > 0 ? { skip, take: chuckSize } : undefined
    getData({ ...memRequest, ...skipTake }, controller)
      .then(result => handleResult(result, memRequest, reload || chuckSize === 0))
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          // setLoading('aborted')
          console.debug(`Aborted useInfiniteLoader`, request.current)
          return
        }

        onErrorCallback?.(!!reload)
        console.error('Failed loader() on useInfiniteLoader', err)

        setLoading('catched')
      })

    return
  }

  function handleResult(result: TResponse[], memRequest: TRequest, reload?: boolean) {
    if (!result) {
      throw new Error('Empty result')
    }

    onLoadedCallback?.(result ?? [], !!reload)

    allDataLoaded.current = result.length < chuckSize

    if (reload) {
      setItem(result ?? [])
    } else {
      setItem(prev => {
        prev.push(...result)
        if (onLoadedCallback) onLoadedCallback(prev, !!reload)
        return [...prev]
      })
    }
    lastSuccessfulRequest.current = { ...memRequest }
    setLoading(false)
  }

  function load(requestData: TRequest, hidden?: boolean) {
    if (!options.dontResetOnLoad) setItem([])

    setLoading(hidden ? 'hiddenLoading' : 'reloading')

    request.current = requestData
    setLoadCounter(prev => prev + 1)
  }

  function loadMore() {
    if (allDataLoaded.current || loading) return
    setLoading('loadMore')

    setEndOfListCounter(prev => prev + 1)
  }

  return {
    item,
    loadItem: load,
    loading,
    loadMore,
    setItem,
    allDataLoaded: allDataLoaded.current,
    lastSuccessfulRequest: lastSuccessfulRequest.current,
    reloadInBackground,
  }
}
