import { DateUtils, useEvent, Utils } from '@infominds/react-native-components'
import { isSameDay } from 'date-fns'
import { useContext, useEffect, useMemo } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { ActivityDetailContext } from '../../contexts/activity/ActivityDetailContext'
import { GroupedActivityTime } from '../../types'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import TimeUtils from '../../utils/TimeUtils'
import useObjectUtils from '../useObjectUtils'

export default function useActivityTimes(activity: Activity | null | undefined, dontLoadOnMount?: boolean) {
  const activityUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const {
    item: times,
    loadItem: loadTimes,
    loading: loadingTimes,
    setItem: setTimes,
    setLoading,
  } = useControlledLoader(api.activities.times.getList, { backgroundUpdaterInterval: CONSTANTS.backgroundUpdateInterval })

  const groupedTimes = useMemo(() => {
    const grouped = Utils.keepUniques(times ?? [], q => `${q.timeDate ? TimeUtils.format(q.timeDate, 'en') : 'noDate'}`)
      .filter(q => !!q.technician?.id && !!q.timeDate)
      .map<GroupedActivityTime>(element => ({
        date: element.timeDate ?? '',
        times: [],
      }))
    grouped.forEach(item => {
      item.times =
        times
          ?.filter(t => !!item.date && !!t.timeDate && isSameDay(DateUtils.dateify(item.date), DateUtils.dateify(t.timeDate)))
          .sort((a, b) => {
            const sortEmployee = EmployeeUtils.sortTechnicians(a.technician, b.technician)
            if (sortEmployee) return sortEmployee
            return TimeUtils.sortTimeSpans(a.startTime ?? '', b.startTime ?? '')
          }) ?? []
    })

    return TimeUtils.sortDate(grouped, 'date', 'desc')
  }, [times])

  useEvent({ key: EVENT_KEYS.ACTIVITY_TIME_MODIFIED }, refreshTimes)
  useEffect(() => {
    if (dontLoadOnMount) {
      setLoading(false)
      return
    }
    refreshTimes()
  }, [activity])

  function refreshTimes() {
    if (!activity) {
      setTimes([])
      return
    }
    loadTimes(activityUtils.createRequestObject(activity))
  }

  const detailContext = useContext(ActivityDetailContext) // using useContext instead of custom hook here to allow usage outside of context provider
  useEffect(() => detailContext?.setDataCount(prev => ({ ...prev, times: times?.length ?? 0 })), [times])

  return { times, groupedTimes, loadingTimes, refreshTimes }
}
