import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import ActivityPlanStatusTag from '../../components/ActivityPlanStatusTag'
import AssignedUserBadgeList from '../../components/AssignedUserBadgeList'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import Text from '../../components/Text'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ThemeColorExpanded } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import { ActivityListCardProps } from './ActivityListCard'
import ActivityCardDetailInfo from './components/ActivityCardDetailInfo'
import ActivityCardTitle from './components/ActivityCardTitle'

const ActivityListBriefCard = ({
  activity,
  spacing,
  style,
  showPlanTag,
  disabled,
  onPress,
  onTagPress,
}: Pick<ActivityListCardProps, 'activity' | 'spacing' | 'onPress' | 'style' | 'showPlanTag' | 'onTagPress' | 'disabled'>) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { value: timeSlot } = useLanguageSelect({
    de: activity.timeSlot?.descriptionDe,
    en: activity.timeSlot?.descriptionEn,
    it: activity.timeSlot?.descriptionIt,
  })

  const icon = useMemo(() => activityUtils.getActivityIconByActivity(activity), [activity])
  const headerColor = useMemo(() => activityUtils.getCardHeadColorByActivity(activity, theme), [activity.priority])

  return (
    <IM.Card
      head={<CardLeftTitle color={headerColor} />}
      disabled={disabled}
      onPress={onPress}
      spacing={spacing}
      style={[{ borderRadius: IMLayout.borderRadius }, style]}>
      <IM.View style={[IMLayout.flex.row, styles.container]}>
        <IM.View style={[IMLayout.flex.row, styles.iconAndTitle]} spacing="right">
          <IM.Icon icon={icon} size={24} />
          <ActivityCardTitle activity={activity} spacing="left" hideActivityNumber style={IMLayout.flex.f1} showRda />
        </IM.View>
        <IM.View style={[IMLayout.flex.f1, styles.timeSlotText]}>
          {activity.assignedUser && (
            <AssignedUserBadgeList employees={activity.assignedUser} maxEmployees={3} allowExtraIfLast size={30} style={styles.employeeBadges} />
          )}
          <Text secondary style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }} numberOfLines={1}>
            {timeSlot}
            {activity.estimatedTime ? ' (' + activity.estimatedTime + ')' : ''}
          </Text>
        </IM.View>
      </IM.View>
      {(!!activity.customerDescription || !!activity.constructionSiteDescription || !!activity.destinationDescription) && (
        <ActivityCardDetailInfo style={IMLayout.flex.f3} activity={activity} disableHighlight spacing="top" lite />
      )}
      {showPlanTag && <ActivityPlanStatusTag activity={activity} spacing="top" onPress={onTagPress} />}
    </IM.Card>
  )
}

export default memo(ActivityListBriefCard)

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  iconAndTitle: { flex: 1, alignItems: 'center' },
  employeeBadges: { padding: 4 },
  timeSlotText: { alignItems: 'flex-end' },
})
