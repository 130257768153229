import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { SearchProvider } from '../../../components/screen/contexts/SearchContext'
import { Screen } from '../../../components/screen/Screen'
import TimeSlotListView from '../../../views/activities/timeSlots/TimeSlotListView'

export default function TimeSlotListScreen() {
  const { i18n } = useLanguage()

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header goBack title={i18n.t('TIME_SLOTS')} />

        <Screen.Content>
          <TimeSlotListView />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
