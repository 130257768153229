import { useLanguage, Utils } from '@infominds/react-native-components'
import { useMemo } from 'react'

import { Activity } from '../../apis/types/apiResponseTypes'
import TimeUtils from '../../utils/TimeUtils'
import useActivityTimes from './useActivityTimes'

export default function useActivityTimeSummary(activity: Activity | null | undefined) {
  const { language } = useLanguage()
  const { times } = useActivityTimes(activity)

  const summary = useMemo(
    () =>
      Utils.keepUniques(
        times
          ?.filter(q => q.notes)
          .map(q => q.timeDate)
          .filter(q => q) as string[],
        q => TimeUtils.format(q, language)
      )
        .map(date => {
          const title = TimeUtils.format(date, language)
          const dateTimes = times?.filter(q => q.notes && q.timeDate && TimeUtils.format(q.timeDate, language) === title)
          if (!dateTimes) return null

          const prefix = `• `
          const notes = Utils.keepUniques(dateTimes, q => q.notes)
            .map(q => `${prefix}${q.notes?.replace(/\n/g, '') ?? ''}`)
            .join('\n')

          return `${title}\n${notes}`
        })
        .filter(q => q)
        .join('\n\n'),
    [times]
  )

  return summary
}
