import { IM, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import { TextInputRef } from '../../types'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type TextInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    disabledInfo?: string | false
    showClearTextIcon?: boolean
  }

const TextInput = memo(
  forwardRef(function TextInput(
    {
      title,
      titleFontWeight,
      details,
      editable = true,
      error,
      loading,
      disableFocus,
      disabledInfo,
      showClearTextIcon,
      ...textInputProps
    }: TextInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { theme } = useTheme()

    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput ref={ref} {...textInputProps}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />}
          {editable && !loading && !!textInputProps.value && showClearTextIcon && (
            <BaseTextInput.RightIcon
              disableBorder={textInputProps.disableBorderRadius}
              key={'ClearIcon'}
              style={textInputProps.multiline ? styles.multiLineRightIcon : undefined}>
              <PressableIcon icon={['fal', 'times']} size={18} color={theme.textPlaceholder} onPress={() => textInputProps.onChangeText?.('')} />
            </BaseTextInput.RightIcon>
          )}
          {loading && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              {loading && <BaseTextInputSpinningIcon key={'LoadingSpinnerIcon'} loading={loading} />}
            </BaseTextInput.RightIcon>
          )}
          {!editable && !loading && disabledInfo && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              <IM.Text key={'DisabledInfoText'} style={[{ color: theme.textPlaceholder }, styles.disabledText]}>
                {disabledInfo}
              </IM.Text>
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default TextInput

const styles = StyleSheet.create({
  multiLineRightIcon: {
    alignItems: 'flex-start',
    paddingTop: 4,
  },
  disabledText: {
    paddingRight: 9,
  },
})
