import { IM, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'

interface Props {
  onPress?: () => void
  selected?: boolean
  borderless?: boolean
  spacing?: SpacingProps
}

export default function NoSelectionCard({ onPress, selected, borderless, spacing }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} onPress={onPress} borderless={borderless} spacing={spacing}>
      <IM.Text numberOfLines={3}>{i18n.t('NO_SELECTION')}</IM.Text>
    </IM.Card>
  )
}
