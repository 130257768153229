import { IM, useLanguage } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import { apiDtoIds } from '../../../apis/apiCalls'
import { ActivityNote } from '../../../apis/types/apiResponseTypes'
import ActivityNotePost from '../../../components/activity/notes/ActivityNotePost'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import Separator from '../../../components/Infominds/Separator'
import LoadingStateView from '../../../components/LoadingStateView'
import MediaPostInput from '../../../components/MediaPostInput'
import MoreButton from '../../../components/MoreButton'
import NoteSkeleton from '../../../components/skeleton/NoteSkeleton'
import { useActivityNote } from '../../../contexts/activity/ActivityNoteContext'
import useObjectUtils from '../../../hooks/useObjectUtils'
import { LoadedCardProps } from '../../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData' | 'loading'>

const ActivityNotesCard = memo(function ActivityNotesCard({ enableChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const objectUtils = useObjectUtils<ActivityNote>(apiDtoIds.activityNotes)
  const { notes, displayedNotes, loadingNotes, displayMode, setDisplayMode } = useActivityNote()

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('NOTES')} />} noContentSpacing {...props}>
      <IM.View spacing={'all'} style={styles.contentView}>
        <LoadingStateView loading={loadingNotes}>
          <NoteSkeleton />
        </LoadingStateView>
        {!displayedNotes?.length && !loadingNotes && (
          <IM.View spacing={'all'}>
            <IM.Text secondary>{i18n.t('NO_NOTES')}</IM.Text>
          </IM.View>
        )}
        {!!notes?.length && !loadingNotes && (
          <MoreButton
            allItemsCount={notes.length}
            shownItemsCount={displayedNotes.length}
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
          />
        )}
        {displayedNotes?.map((note, index) => (
          <ActivityNotePost
            key={'ActivityNotePost' + objectUtils.createId(note)}
            note={note}
            separator={index > 0 || (notes && notes?.length > 3)}
            enableChange={enableChange}
          />
        ))}
        {loadingNotes === 'hiddenLoading' && (
          <>
            <Separator />
            <NoteSkeleton spacing={'bottom'} align="right" />
          </>
        )}
      </IM.View>

      {enableChange && <MediaPostInput />}
    </IM.Card>
  )
})

export default ActivityNotesCard

const styles = StyleSheet.create({
  contentView: {
    minHeight: 100,
    flexGrow: 1,
  },
})
