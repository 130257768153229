import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { useFocusEffect } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'

import { api } from '../apis/apiCalls'
import useExtendedTheme from '../hooks/useExtendedTheme'
import { PDFPreviewButtonProps } from '../types'
import { activityUtils } from '../utils/ActivityUtils'
import appUtils from '../utils/appUtils'
import ActivityButton from './ActivityButton'

let controller: AbortController | undefined

export default function ActivityPDFButton({ disabled, activity, closingRequest }: PDFPreviewButtonProps) {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { url, bearerToken } = useAuthentication()
  const { theme } = useExtendedTheme()

  const [loading, setLoading] = useState(false)

  const isHistorical = useMemo(() => activityUtils.isActivityClosed(activity), [activity])

  useFocusEffect(
    useCallback(() => {
      return () => {
        controller?.abort()
        controller = undefined
      }
    }, [])
  )

  const handlePress = () => {
    setLoading(true)

    if (controller === undefined) controller = new AbortController()

    const get = isHistorical ? api.reports.get : api.reports.getPreview
    url &&
      bearerToken &&
      get({ ...activity, ...closingRequest }, url, bearerToken, controller.signal)
        .then(blob => {
          const file = new File([blob], activityUtils.getPdfName(activity), { type: 'application/pdf', lastModified: new Date().getTime() })
          const fileURL = URL.createObjectURL(file)
          const pdfWindow = window.open()
          if (pdfWindow) {
            pdfWindow.location.assign(fileURL)
            URL.revokeObjectURL(fileURL)
          }
        })
        .catch(err => {
          if (appUtils.isAbortError(err)) return
          return showAlert(appUtils.getBackendErrorMessage(err))
        })
        .finally(() => setLoading(false))
  }

  const showAlert = (message: string) => {
    alert(i18n.t('ERROR'), message, [
      {
        onPress: () => {
          return
        },
        text: i18n.t('OK'),
      },
    ])
  }

  return (
    <ActivityButton
      title={isHistorical ? i18n.t('REPORT') : i18n.t('PREVIEW')}
      icon={['fal', 'file-pdf']}
      onPress={handlePress}
      disabled={disabled}
      loading={loading}
      style={{ backgroundColor: theme.card.secondaryBackground }}
    />
  )
}
