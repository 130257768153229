import { IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { PropsWithChildren, useMemo } from 'react'

import { Activity, Contract, TimeSlot } from '../../apis/types/apiResponseTypes'
import GroupSpacer from '../../components/GroupSpacer'
import DateInput from '../../components/input/DateInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import TimeInput from '../../components/input/TimeInput'
import ActivityPrioritySelector from '../../components/selectors/ActivityPrioritySelector'
import ActivityTypeSelector from '../../components/selectors/ActivityTypeSelector'
import ContractSelector from '../../components/selectors/ContractSelector'
import TaskSelector from '../../components/selectors/TaskSelector'
import TimeSlotSelector from '../../components/selectors/TimeSlotSelector'
import CONSTANTS from '../../constants/Constants'

type Props = PropsWithChildren<
  {
    state?: Partial<Activity>
    initialValue?: Partial<Activity>
    editMode?: boolean
    handleDataChange: (activity: Partial<Activity>) => void
    onTimeSlotChanged?: (timeSlot?: TimeSlot) => void
    hide?: (keyof Activity)[]
    contract?: Contract
    hasTimesRegistered?: boolean | 'loading'
  } & (
    | { hasPredefinedContract?: boolean; onContractSelected: (value: Contract | undefined) => void }
    | { hasPredefinedContract?: boolean; onContractSelected?: never }
  )
>

function ActivityCreationDefaultInputs({
  state,
  initialValue,
  handleDataChange,
  hasPredefinedContract,
  onContractSelected,
  editMode,
  children,
  contract,
  hide,
  hasTimesRegistered = false,
  onTimeSlotChanged,
}: Props) {
  const { i18n } = useLanguage()

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
  }

  return (
    <>
      <HidableView valueKey="srvActivityTypeId" hide={hide}>
        <ActivityTypeSelector
          editable={!editMode}
          required
          onChange={value => handleDataChange({ srvActivityTypeId: value?.srvActivityTypeId })}
          initialValue={initialValue?.srvActivityTypeId}
          {...commonProps}
        />
      </HidableView>
      <HidableView valueKey="taskId" hide={hide}>
        <TaskSelector
          editable={!editMode}
          required
          onChange={value => handleDataChange({ taskId: value?.id })}
          initialValue={initialValue}
          {...commonProps}
        />
      </HidableView>

      <HidableView valueKey="srvContractId" hide={hide}>
        {!!onContractSelected && (
          <ContractSelector
            editable={!hasPredefinedContract && !initialValue?.srvContractId && !editMode}
            onChange={value => {
              onContractSelected(value)
            }}
            initialValue={initialValue?.srvContractId}
            {...commonProps}
          />
        )}
      </HidableView>
      <GroupSpacer />

      {children}
      <HidableView valueKey="title" hide={hide}>
        <TextInput
          required
          title={i18n.t('TITLE')}
          placeholder={i18n.t('TITLE')}
          value={state?.title}
          onChangeText={value => handleDataChange({ title: value })}
          {...commonProps}
        />
      </HidableView>
      <HidableView valueKey="description" hide={hide}>
        <TextInput
          title={i18n.t('DESCRIPTION')}
          placeholder={i18n.t('DESCRIPTION')}
          value={state?.description}
          onChangeText={value => handleDataChange({ description: value })}
          maxHeight={200} // maxHeight is necessary, because if the input gets to big, the user is no longer able to scroll. (especially on an iOS tablet)
          multiline
          {...commonProps}
        />
      </HidableView>
      <HidableView valueKey="technicalDescription" hide={hide}>
        <TextInput
          title={i18n.t('TECHNICAL_DESCRIPTION')}
          placeholder={i18n.t('TECHNICAL_DESCRIPTION')}
          value={state?.technicalDescription}
          onChangeText={value => handleDataChange({ technicalDescription: value })}
          maxHeight={200} // maxHeight is necessary, because if the input gets to big, the user is no longer able to scroll. (especially on an iOS tablet)
          multiline
          {...commonProps}
        />
      </HidableView>
      <HidableView valueKey="priority" hide={hide}>
        <ActivityPrioritySelector editable value={state?.priority} onChange={value => handleDataChange({ priority: value })} {...commonProps} />
      </HidableView>
      <GroupSpacer />
      <HidableView valueKey="srvTimeSlotId" hide={hide}>
        <TimeSlotSelector
          editable={!editMode || !hasTimesRegistered}
          onChange={value => {
            handleDataChange({ srvTimeSlotId: value?.srvTimeSlotId })
            onTimeSlotChanged?.(value)
          }}
          initialValue={initialValue?.srvTimeSlotId}
          disableFastInput
          subTitle={hasTimesRegistered === true ? i18n.t('TIME_SLOT_LOCKED_BY_TIMES') : undefined}
          disabledInfo={hasTimesRegistered === true ? i18n.t('LOCKED') : undefined}
          {...commonProps}
        />
      </HidableView>
      <HidableView valueKey="estimatedTime" hide={hide}>
        <TimeInput
          editable
          title={i18n.t('ESTIMATED_TIME')}
          value={state?.estimatedTime}
          onChangeTime={value => handleDataChange({ estimatedTime: value })}
          defaultPickerValue="00:00"
          {...commonProps}
        />
      </HidableView>
      <HidableView valueKey="expireDate" hide={hide}>
        <DateInput
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('EXPIRE_DATE')}
          value={state?.expireDate}
          minimumDate={contract?.startDate ?? CONSTANTS.today}
          maximumDate={contract?.expiredDate ?? CONSTANTS.maxDate}
          onChangeDate={value => handleDataChange({ expireDate: value?.toISOString() })}
          {...commonProps}
        />
      </HidableView>
    </>
  )
}

export default ActivityCreationDefaultInputs

function HidableView({ valueKey, hide, children }: PropsWithChildren<{ valueKey: keyof Activity } & Pick<Props, 'hide'>>) {
  const hidden = useMemo(() => {
    return !!hide?.includes(valueKey)
  }, [valueKey, hide])

  if (hidden) return <></>

  return <>{children}</>
}
