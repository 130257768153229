import { IM, IMLayout, IMStyle } from '@infominds/react-native-components'
import { AssetUtils, MediaUtils } from '@infominds/react-native-media-lite'
import React, { useRef } from 'react'
import { StyleSheet } from 'react-native'

import { VideoPreviewProps } from './VideoPreview'

const MAX_ICON_SIZE = 36
export default function VideoPreview({ asset, size, overlayIcon, showControls }: VideoPreviewProps) {
  const mime = AssetUtils.getAssetMime(asset)
  const uri = asset.base64?.startsWith('data:') ? asset.base64 : MediaUtils.base64ToUri(asset.base64 ?? '', mime)

  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <>
      <video
        ref={videoRef}
        style={{ ...styles.video, width: size, height: size }}
        onClick={e => e.preventDefault()}
        loop={!showControls}
        autoPlay={!showControls}
        controls={!!showControls}
        muted>
        <source src={uri} type={mime} />
      </video>
      {!!overlayIcon && (
        <IM.View style={[styles.overlayLoadingSpinnerView]}>
          <IM.Icon icon={overlayIcon} size={Math.min(size / 3, MAX_ICON_SIZE)} color={IMStyle.palette.white} />
        </IM.View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  video: {
    overflow: 'hidden',

    borderRadius: IMLayout.borderRadius,
  },

  overlayLoadingSpinnerView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: IMLayout.borderRadius,
    opacity: 0.3,
    zIndex: 10,
  },
})
