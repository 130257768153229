import { CardProps, IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { UnavailableType } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import { AppStyle } from '../../constants/Styles'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { SupportedUnavailableTypes } from '../../types'

export type UnavailableCardProps = {
  unavailableType: UnavailableType
} & Pick<CardProps, 'style' | 'spacing' | 'onPress'>

export default function UnavailableTypeCard({ unavailableType, ...otherProps }: UnavailableCardProps) {
  const { i18n } = useLanguage()
  const { value: title } = useLanguageSelect({ en: unavailableType.titleEn, it: unavailableType.titleIt, de: unavailableType.titleDe })
  const { value: note } = useLanguageSelect({ en: unavailableType.noteEn, it: unavailableType.noteIt, de: unavailableType.noteDe })
  const type = useMemo(() => SupportedUnavailableTypes.find(st => st.type === unavailableType.type), [unavailableType])

  return (
    <IM.Card {...otherProps} head={<CardLeftTitle color={unavailableType.planColor} />}>
      <IM.View style={IMLayout.flex.row}>
        <IM.View style={IMLayout.flex.f1}>
          <IM.View style={IMLayout.flex.row}>
            <IM.Text primary>{title}</IM.Text>
          </IM.View>
          {note && <IM.Text secondary>{note}</IM.Text>}
        </IM.View>
        {!!type && (
          <IM.View style={AppStyle.center}>
            <IM.Text secondary>{i18n.t(type.labelKey)}</IM.Text>
          </IM.View>
        )}
      </IM.View>
    </IM.Card>
  )
}
