import { CardButton, CardProps, IM } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren, ReactNode } from 'react'
import { StyleSheet } from 'react-native'

import CardLeftTitle from '../components/card/left/CardLeftTitle'
import ChangeButton from '../components/ChangeButton'
import LoadingStateView from '../components/LoadingStateView'
import useExtendedTheme from '../hooks/useExtendedTheme'
import { InfiniteLoadingType } from '../types'
import appUtils from '../utils/appUtils'

export type LoadedCardProps = PropsWithChildren<
  CardProps & {
    hasData: boolean
    leftText?: string
    noDataText?: string
    enableChange?: boolean
    loading?: InfiniteLoadingType
    hideButtons?: boolean
    onChange?: () => void
    showChildrenIfNoData?: boolean
    subComponent?: ReactNode
  }
>

function LoaderCard({
  children,
  hasData,
  leftText,
  noDataText,
  selected,
  enableChange,
  hideButtons,
  loading = false,
  onChange,
  subComponent,
  showChildrenIfNoData,
  ...other
}: LoadedCardProps) {
  const { theme, colorScheme } = useExtendedTheme()

  const inactive = false
  const buttons: CardButton[] = []

  const opacity = inactive ? appUtils.getOpacity(colorScheme) : 1

  return (
    <IM.Card
      head={<CardLeftTitle color={selected && theme.general.info} text={leftText} />}
      buttons={{
        buttons: hideButtons || !hasData || loading !== false ? [] : buttons,
      }}
      {...other}
      style={[other.style, { opacity }]}>
      {loading === false || loading === 'init' ? (
        <IM.View style={styles.content}>
          {hasData || showChildrenIfNoData ? children : <IM.Text secondary>{noDataText}</IM.Text>}
          {enableChange && <ChangeButton style={styles.changeButton} onPress={() => onChange?.()} />}
          {subComponent}
        </IM.View>
      ) : (
        <LoadingStateView
          loading={loading}
          errorAddComponent={enableChange && <ChangeButton style={styles.changeButton} onPress={() => onChange?.()} />}
        />
      )}
    </IM.Card>
  )
}

export default memo(LoaderCard)

const styles = StyleSheet.create({
  changeButton: {
    alignItems: 'flex-end',
  },
  content: { flex: 1, justifyContent: 'space-between' },
})
