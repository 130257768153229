import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { ConstructionSiteLotto, ErgoTask } from '../../../apis/types/apiResponseTypes'
import ConstructionSiteLottCreationScreen from '../../../screens/constructionSite/ConstructionSiteLottCreationScreen'
import { ModalScreenRef } from '../../../types'
import BaseServiceModal from '../../BaseServiceModal'

interface Props {
  controller: ModalController<ConstructionSiteLotto | undefined>
  objectId: number
  onConstructionSiteLottoCreated?: (customer: ConstructionSiteLotto) => void
  preset?: Partial<ConstructionSiteLotto>
  ergoTask?: ErgoTask | null
}

export default function ConstructionSiteLottoCreationModal({ controller, objectId, onConstructionSiteLottoCreated, preset, ergoTask }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ConstructionSiteLottCreationScreen
        ref={ref}
        controller={controller}
        objectId={objectId}
        onLotCreated={onConstructionSiteLottoCreated}
        preset={preset}
        ergoTask={ergoTask}
      />
    </BaseServiceModal>
  )
}
