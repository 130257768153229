import { useCallback, useMemo } from 'react'

import { api } from '../../apis/apiCalls'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'

export default function useGanttChartWrapperGetApi() {
  const { item: activities, loadItem: loadActivities, loading: loadingActivities } = useControlledLoader(api.activities.employees.getPlanned)
  const { item: unavailables, loadItem: loadUnavailables, loading: loadingUnavailables } = useControlledLoader(api.activities.unavailables.getList)

  const loadData = useCallback(
    (from: string, to: string) => {
      loadActivities({ planDateFrom: from, planDateTo: to })
      loadUnavailables({ planDateFrom: from, planDateTo: to })
    },
    [loadActivities, loadUnavailables]
  )

  const data = useMemo(() => {
    if (!(loadingActivities === false && loadingUnavailables === false)) return undefined
    return [...(activities ?? []), ...(unavailables ?? [])]
  }, [activities, unavailables, loadingActivities, loadingUnavailables])

  return { data, loadData }
}
