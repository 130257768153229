import { CardProps, IM } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { SupplierArticle } from '../../apis/types/apiResponseTypes'
import { articleUtils } from '../../utils/ArticleUtils'

export type MainSupplierCardProps = { supplierArticle: SupplierArticle } & CardProps

export default function MainSupplierCard({ supplierArticle, ...cardProps }: MainSupplierCardProps) {
  const title = useMemo(() => articleUtils.getSupplierArticleTitle(supplierArticle), [supplierArticle])

  return (
    <IM.Card {...cardProps}>
      <IM.Text primary>{title}</IM.Text>
    </IM.Card>
  )
}
