import { IM, IMStyle, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { Animated, StyleSheet } from 'react-native'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { api } from '../../../apis/apiCalls'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import useEmbed from '../../../hooks/useEmbed'
import useLanguageSelect from '../../../hooks/useLanguageSelect'
import { useRotateAnimation } from '../../../hooks/useRotateAnimation'
import SettingsModal, { SettingsModalProps } from '../../../modals/settings/SettingsModal'
import { PlannerStackParamList } from '../../../navigation/types'
import { ThemeColorExpanded } from '../../../types'
import { chartUtils } from '../../../utils/chartUtils'
import { ganttChartClosedPlannerSideViewAtom, ganttChartDateLabelAtom, ganttChartRefreshAtom } from '../../../utils/stateManager'
import ChartLegend, { Legend, LegendSection } from '../../gantt/ChartLegend'
import GanttChartViewTabSelector from '../../GanttChartViewTabSelector'
import DateTimePicker from '../../Infominds/DateTimePicker'
import useControlledLoader from '../../Infominds/hooks/useControlledLoader'
import Pressable from '../../Infominds/Pressable'
import PressableIcon from '../../Infominds/PressableIcon'
import PressableTextIcon from '../../Infominds/PressableTextIcon'
import Text from '../../Text'

const ChartHeader = () => {
  const { embed } = useEmbed()
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const navigation = useNavigation<NavigationProp<PlannerStackParamList>>()
  const controller = useModalController<SettingsModalProps>()
  const { languageSelect } = useLanguageSelect()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const chartDate = useRecoilValue(ganttChartDateLabelAtom(sessionKey))
  const [refresh, setRefresh] = useRecoilState(ganttChartRefreshAtom)
  const setPlannerSideViewClosed = useSetRecoilState(ganttChartClosedPlannerSideViewAtom(sessionKey))

  const [showPicker, setShowPicker] = useState(false)

  const { emit: onNext } = useEvent({ key: EVENT_KEYS.GANTT_CHART_NEXT })
  const { emit: onPrevious } = useEvent({ key: EVENT_KEYS.GANTT_CHART_PREVIOUS })
  const { emit: onPick } = useEvent<Date>({ key: EVENT_KEYS.GANTT_CHART_DATE_PICK })

  const { item: absences, loadItem: loadAbsences, loading: loadingAbsences } = useControlledLoader(api.activities.unavailables.types.getList)
  const { item: activityTypes, loadItem: loadActivityTypes, loading: loadingActivityTypes } = useControlledLoader(api.activities.types.getList)

  const [spin] = useRotateAnimation(refresh !== false, 1000)

  useEffect(() => {
    loadAbsences()
    loadActivityTypes()
  }, [])

  const legendElements = useMemo(() => {
    const toRet: LegendSection[] = []
    let legend: Legend[] = []

    if (loadingActivityTypes === false) {
      activityTypes?.forEach(el =>
        legend.push({ planColor: el.planColor, title: languageSelect({ de: el.descriptionDe, en: el.descriptionEn, it: el.descriptionIt }) })
      )
      legend.push({ planColor: chartUtils.getActivityBlockColor(colorScheme), title: i18n.t('ACTIVITY_COMPLETED') })
    }

    toRet.push({ title: i18n.t('ACTIVITIES'), values: legend })

    legend = []
    absences?.forEach(el => legend.push({ planColor: el.planColor, title: languageSelect({ de: el.titleDe, en: el.titleEn, it: el.titleIt }) }))

    toRet.push({ title: i18n.t('UNAVAILABLE'), values: legend })

    return toRet
  }, [absences, activityTypes, loadingActivityTypes, loadingAbsences])

  return (
    <>
      <IM.View style={styles.container}>
        <IM.View style={styles.leftContainer}>
          <PressableTextIcon
            icon={['fal', 'calendar-day']}
            onPress={() => onPick(new Date())}
            color={theme.header.main.text.primary}
            spacing="right"
            iconSize={18}
            style={{ fontSize: IMStyle.typography.fontSizeRegular }}>
            {i18n.t('CALENDAR_TODAY')}
          </PressableTextIcon>
          <PressableIcon icon={['fal', 'chevron-left']} size={20} color={theme.header.main.text.primary} onPress={() => onPrevious()} />
          <PressableIcon icon={['fal', 'chevron-right']} size={20} color={theme.header.main.text.primary} onPress={() => onNext()} />
          <Pressable spacing="left" onPress={() => setShowPicker(true)}>
            <Text style={[styles.date, { color: theme.header.main.text.primary }]}>{chartDate?.label}</Text>
          </Pressable>
        </IM.View>
        <IM.View style={styles.rightContainer}>
          {__DEV__ && (
            <Pressable
              onPress={() =>
                !embed
                  ? navigation.navigate('PlannerEmbed', { language: 'de', code: 1, companyId: undefined, objectId: undefined })
                  : navigation.navigate('Planner')
              }>
              <IM.Text style={{ color: theme.header.main.text.primary }}>{embed ? 'NOR' : 'EMB'}</IM.Text>
            </Pressable>
          )}
          <ChartLegend sections={legendElements} disabled={loadingActivityTypes !== false || loadingActivityTypes !== false} />
          <IM.View style={styles.refreshIcon}>
            <Animated.View style={{ transform: [{ rotate: spin }] }}>
              <PressableIcon icon={['fal', 'arrows-rotate']} size={20} color={theme.header.main.text.primary} onPress={() => setRefresh(true)} />
            </Animated.View>
          </IM.View>
          <PressableIcon
            icon={['fal', 'sidebar-flip']}
            size={20}
            color={theme.header.main.text.primary}
            onPress={() => setPlannerSideViewClosed(prev => !prev)}
          />
          {embed && (
            <PressableIcon
              icon={['fal', 'gear']}
              size={20}
              color={theme.header.main.text.primary}
              onPress={() => controller.show({ showPlannerSettings: true })}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{ marginHorizontal: 4 }}
            />
          )}
          <GanttChartViewTabSelector />
        </IM.View>
      </IM.View>
      <DateTimePicker
        key={chartDate?.date.toString() ?? ''}
        date={chartDate ? new Date(chartDate.date) : new Date()}
        setDate={date => {
          onPick(date)
          setShowPicker(false)
        }}
        show={showPicker}
        setShow={setShowPicker}
        mode="date"
      />
      <SettingsModal controller={controller} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row', alignItems: 'center' },
  rightContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  date: { fontSize: 16 },
  refreshIcon: { marginRight: 5 },
})

export default ChartHeader
