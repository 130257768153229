import { useDidUpdate, useTheme } from '@infominds/react-native-components'
import React, { memo, Ref, useMemo, useRef, useState } from 'react'
import { Platform, TextStyle } from 'react-native'

import useForm from '../../hooks/useForm'
import { TextInputRef } from '../../types'
import DateTimePicker from '../Infominds/DateTimePicker'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

type TextInputProps = Omit<BaseTextInputProps, 'onChangeText'> &
  Omit<BaseTextInputProviderProps, 'error'> & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    onChangeTime: (value: string | undefined) => void
    defaultPickerValue?: Date | string
    error?: boolean
    limitTo24h?: boolean
    textInputRef?: Ref<TextInputRef>
  }

const convertTimeFromInputFormat = (time: string) => {
  const [h, m] = time.split(':')
  const date = new Date()
  date.setHours(parseInt(h, 10))
  date.setMinutes(parseInt(m, 10))

  return date
}

const convertTimeToInputFormat = (date: Date) => {
  const toRet = `${date.getHours()}:${date.getMinutes()}`
  const split = toRet.split(':')
  let toRetHours = split[0]
  let toRetMinutes = split[1]

  if (toRetMinutes.length === 1) {
    toRetMinutes = `0${date.getMinutes()}`
  }

  if (toRetHours.length === 1) {
    toRetHours = `0${date.getHours()}`
  }

  return toRetHours + ':' + toRetMinutes
}

const TEXT_LENGTH_REFERENCE = 5
function isOfTimeFormat(text: string | undefined) {
  return !!text?.match(/^\d{2}:\d{2}/)
}

const TimeInput = memo(function TimeInput({
  title,
  details,
  titleFontWeight,
  editable = true,
  loading,
  disableFocus,
  onChangeTime,
  defaultPickerValue,
  error,
  limitTo24h,
  textInputRef,
  ...textInputProps
}: TextInputProps) {
  const { theme } = useTheme()
  const { setError } = useForm()

  const [show, setShow] = useState(false)
  const [formError, setFormError] = useState(false)
  const [text, setText] = useState(textInputProps.value)
  const [isDelete, setIsDelete] = useState(textInputProps.value === undefined || textInputProps.value.length === 0 ? false : true)
  const length = Platform.OS === 'ios' ? text?.length ?? 0 : TEXT_LENGTH_REFERENCE
  const prevText = useRef(text)
  const timePickerDefaultDate = useMemo(() => {
    if (text && isOfTimeFormat(text)) return convertTimeFromInputFormat(text)
    if (defaultPickerValue) {
      if (typeof defaultPickerValue === 'string') {
        if (isOfTimeFormat(defaultPickerValue)) return convertTimeFromInputFormat(defaultPickerValue)
      } else if (defaultPickerValue instanceof Date) {
        return defaultPickerValue
      }
    }
    return undefined
  }, [defaultPickerValue, text])

  function onTimeChanged(time: string) {
    time && prevText.current && setIsDelete(time.length < prevText.current.length)
    prevText.current = time
    setText(time)

    if (isOfTimeFormat(time)) {
      handleError(false)

      onChangeTime(time)
    } else if (time?.length === 0) {
      handleError(false)
      onChangeTime(undefined)
    }
  }

  useDidUpdate(() => {
    if (textInputProps.value === undefined) return
    setText(textInputProps.value)
  }, [textInputProps.value])

  const handlePickerTime = (newDate: Date) => {
    onTimeChanged(convertTimeToInputFormat(newDate))
  }

  const handleError = (value: boolean) => {
    setError(value)
    setFormError(value)
  }

  return (
    <>
      <BaseTextInputProvider editable={editable} error={formError || error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput
          {...textInputProps}
          ref={textInputRef}
          value={text}
          onChangeText={val => {
            const split = val.split(':')

            if (split.length === 2) {
              let minutesToRet = split[1]
              let hoursToRet = split[0]
              const minutes = parseInt(split[1], 10)
              const hours = parseInt(split[0], 10)

              if (hoursToRet.length > 2) {
                return onTimeChanged(val)
              }

              if (minutes >= 60) {
                minutesToRet = '59'
              }

              if (limitTo24h && hours >= 24) {
                hoursToRet = '00'
              }

              onTimeChanged(`${hoursToRet}:${minutesToRet}`)
              return
            }

            onTimeChanged(val)
          }}
          type="time"
          mask={[/\d/, /\d/, ':', /\d/, /\d/]}
          maskAutoComplete={!isDelete}
          placeholder="00:00"
          pointerEvents="box-only"
          selection={length < 5 ? { start: length, end: length } : undefined}
          onBlur={() => {
            if (text && !isOfTimeFormat(text)) {
              if (text.match(/^\d{1,2}:?\d?$/)) {
                const [hh, mm] = text.split(':')
                onTimeChanged(`${hh.padStart(2, '0')}:${(mm || '').padEnd(2, '0')}`)
              } else {
                handleError(true)
              }
            } else {
              handleError(false)
            }
          }}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />}
          <BaseTextInput.RightIcon>
            <PressableIcon icon={['fal', 'clock']} color={theme.textDetail} size={20} onPress={() => setShow(true)} disabled={!editable} />
          </BaseTextInput.RightIcon>
        </BaseTextInput>
      </BaseTextInputProvider>
      <DateTimePicker date={timePickerDefaultDate ?? new Date()} setDate={handlePickerTime} show={show} setShow={setShow} mode="time" />
    </>
  )
})

export default TimeInput
