import { IM, IMLayout, SpacingProps, useDimensions } from '@infominds/react-native-components'
import { FlashList, FlashListProps, ListRenderItemInfo } from '@shopify/flash-list'
import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, StyleSheet } from 'react-native'

import { InfiniteLoadingType, LoadingType } from '../types'
import Error from './Error'
import RefreshControl from './Infominds/RefreshControl'
import ListSpacer from './ListSpacer'
import NoEntry from './NoEntry'
import SkeletonCard from './skeleton/SkeletonCard'

export type FlashListDataProps<T> = Omit<FlashListProps<T>, 'ref' | 'onRefresh'> &
  Pick<FlashListProps<T>, 'ListHeaderComponent' | 'ListFooterComponent'> & {
    noDataMessage: string
    isSearching?: boolean
    loading?: LoadingType | InfiniteLoadingType
    spacingSkeleton?: SpacingProps
    closeEndThreshold?: number
    hideButtonId?: string
    refresh?: () => void
    renderItem: (item: ListRenderItemInfo<T | string>) => ReactNode
    listSpacer?: boolean | number
    onLoadMore?: () => void
    skeletonComponent?: ReactNode
    skeletonNumberLoading?: number
    skeletonNumberLoadingMore?: number
  }

function List<T>(
  {
    noDataMessage,
    loading = false,
    isSearching = false,
    refresh,
    spacingSkeleton,
    listSpacer = true,
    ListHeaderComponent,
    ListFooterComponent,
    onLoadMore,
    skeletonComponent,
    skeletonNumberLoading,
    skeletonNumberLoadingMore = 3,
    ...others
  }: FlashListDataProps<T>,
  ref: ForwardedRef<FlashList<T>>
) {
  const { isSmallDevice } = useDimensions()

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()
    others.onScroll?.(event)
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlashList
        {...others}
        ref={ref}
        estimatedItemSize={others.estimatedItemSize ?? isSmallDevice ? 200 : 100}
        scrollEnabled={loading !== 'reloading'}
        ListHeaderComponent={
          <FlashListDataHeader
            listSpacer={listSpacer}
            ListHeaderComponent={ListHeaderComponent}
            loading={loading}
            skeletonComponent={skeletonComponent}
            skeletonNumberLoading={skeletonNumberLoading}
            spacingSkeleton={spacingSkeleton}
            data={others.data}
            noDataMessage={noDataMessage}
          />
        }
        ListFooterComponent={
          <>
            {loading === 'loadMore' &&
              Array(skeletonNumberLoadingMore)
                .fill(0)
                .map((_, index) => {
                  return (
                    <IM.View spacing={spacingSkeleton ?? ['bottom']} style={{ marginHorizontal: IMLayout.horizontalMargin * 2 }} key={index}>
                      {skeletonComponent ?? <SkeletonCard />}
                    </IM.View>
                  )
                })}
            {ListFooterComponent ?? <IM.View spacing="vertical" />}
          </>
        }
        refreshControl={!isSearching ? refresh ? <RefreshControl refreshing={false} onRefresh={refresh} /> : undefined : undefined}
        onScroll={handleScroll}
        onEndReachedThreshold={0.5}
        onEndReached={onLoadMore}
      />
    </IM.View>
  )
}

export type FlashListDataHeader<T> = Pick<
  FlashListDataProps<T>,
  'listSpacer' | 'ListHeaderComponent' | 'loading' | 'skeletonComponent' | 'skeletonNumberLoading' | 'spacingSkeleton' | 'data' | 'noDataMessage'
>
export function FlashListDataHeader<T>({
  listSpacer,
  ListHeaderComponent,
  loading,
  skeletonComponent,
  skeletonNumberLoading = 12,
  spacingSkeleton,
  data,
  noDataMessage,
}: FlashListDataHeader<T>) {
  return (
    <>
      {!!listSpacer && <ListSpacer height={typeof listSpacer === 'number' ? listSpacer : undefined} />}
      {ListHeaderComponent}
      {loading === 'reloading' && (
        <IM.View>
          {Array(skeletonNumberLoading)
            .fill(0)
            .map((_, index) => {
              return (
                <IM.View spacing={spacingSkeleton ?? ['bottom']} style={{ marginHorizontal: IMLayout.horizontalMargin * 2 }} key={index}>
                  {skeletonComponent ?? <SkeletonCard />}
                </IM.View>
              )
            })}
        </IM.View>
      )}
      <IM.View style={styles.container}>
        <>
          {loading === 'catched' && <Error />}
          {loading === false && data?.length === 0 && <>{data?.length === 0 && <NoEntry description={noDataMessage} />}</>}
        </>
      </IM.View>
    </>
  )
}

const FlashListData = forwardRef(List) as <T>(props: FlashListDataProps<T> & { ref?: ForwardedRef<FlashList<T>> }) => ReturnType<typeof List>

export default FlashListData

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
})
