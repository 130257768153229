import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { createRef, useEffect, useMemo } from 'react'
import { FlatList, ListRenderItem, Platform } from 'react-native'

import { apiDtoIds } from '../../apis/apiCalls'
import { ActivityOrderBy, GetActivityRequest, GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Activity, ActivityState } from '../../apis/types/apiResponseTypes'
import ActivityListCard from '../../cards/activityList/ActivityListCard'
import FlatListData from '../../components/FlatListData'
import Separator from '../../components/Infominds/Separator'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import useSearch from '../../components/screen/hooks/useSearch'
import CONSTANTS from '../../constants/Constants'
import { HistoryFilterConfig } from '../../constants/Filters'
import useActivities from '../../hooks/activity/useActivities'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import useObjectUtils from '../../hooks/useObjectUtils'

export default function HistoryView() {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { isMediumDevice } = useLayout(true)
  const navigation = useNavigation()
  const { compare: compareActivities } = useObjectUtils<Activity>(apiDtoIds.activity)
  const masterDetail = useMasterDetail<Activity>()
  const listRef = createRef<FlatList<string | Activity>>()

  const { requestFilter: filter } = useFilter<Activity, GetActivityRequest>()
  const requestFilter = useMemo(() => ({ ...filter, orderBy: [ActivityOrderBy.ClosedDateDesc] }) as GetActivityRequest, [filter])
  // const {
  //   item: activities,
  //   loadItem: loadActivities,
  //   loading,
  //   loadMore,
  // } = useInfiniteLoader(api.activities.getList, { chuckSize: CONSTANTS.activityChunkSize })

  const {
    groupedActivities: groupedActivities,
    activities,
    loadActivities,
    loadMoreActivities: loadMore,
    loadingActivities: loading,
  } = useActivities({
    states: [ActivityState.Closed, ActivityState.ClosedWithInterventionConnected],
    assignedMode: requestFilter?.assignedUserId ? GetActivityRequestAssignedUser.AssignedTo : GetActivityRequestAssignedUser.All,
    filter: requestFilter,
    searchText: search,
    enabled: true,
    filterConfig: HistoryFilterConfig,
    chunkSize: CONSTANTS.activityChunkSize,
  })

  useEffect(() => {
    if (loading === false) {
      if (masterDetail.enableMDLayout && !!masterDetail.detailParams && !activities.find(a => compareActivities(a, masterDetail.detailParams))) {
        masterDetail.setDetailParams(activities.length ? activities[0] : undefined)
      } else if (masterDetail.enableMDLayout && !masterDetail.detailParams && activities.length) {
        masterDetail.setDetailParams(activities[0])
      }
    }
  }, [activities, loading])

  function onActivityPressed(activity: Activity) {
    if (masterDetail.enableMDLayout) {
      masterDetail.setDetailParams(activity)
    } else {
      navigation.navigate('HistoryDetailStack', {
        screen: 'Detail',
        params: {
          srvActivityId: activity.srvActivityId,
          srvActivityTypeId: activity.srvActivityTypeId,
          srvActivityYear: activity.srvActivityYear,
          title: activity.title,
        },
      })
    }
  }

  const renderItem: ListRenderItem<string | Activity> | null | undefined = elem => {
    const isFirst = elem.index === 0
    const item = elem.item
    const isLast = elem.index === activities.length - 1

    // group header
    if (typeof item === 'string') {
      return (
        <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}>
          {item === i18n.t('UNASSIGNED') && !isFirst && <Separator />}
          <IM.Text numberOfLines={1}>{item}</IM.Text>
        </IM.View>
      )
    } else {
      return (
        <ActivityListCard
          activity={item}
          onPress={() => onActivityPressed(item)}
          forceLayout={isMediumDevice ? undefined : 'small'}
          isSelected={compareActivities(item, masterDetail.detailParams)}
          type={'normal'}
          spacing={'bottom'}
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              marginTop: IMLayout.horizontalMargin,
              marginBottom: isLast ? IMLayout.horizontalMargin : 0,
            },
            Platform.select({
              web: { marginLeft: 2 * IMLayout.horizontalMargin, marginRight: 2 * IMLayout.horizontalMargin },
              native: { marginHorizontal: 2 * IMLayout.horizontalMargin },
            }),
          ]}
        />
      )
    }
  }

  return (
    <FlatListData
      ref={listRef}
      data={groupedActivities}
      loading={loading}
      noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
      renderItem={renderItem}
      isSearching={search !== ''}
      refresh={loadActivities}
      onLoadMore={loadMore}
      listSpacer
    />
  )
}
