import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import useUserSettings from '../../../hooks/useUserSettings'
import { activityUtils } from '../../../utils/ActivityUtils'
import GeoLocationUtils from '../../../utils/GeoLocationUtils'

type ActivityCardInfoIconsProps = {
  activity?: Activity
  showIcons: boolean
  style?: StyleProp<ViewStyle>
  forceLayout?: ForceLayoutType
  showLocationMissingIndicator?: boolean
}

const ICON_SIZE = 22

export default function ActivityCardInfoIcons({ activity, showIcons, forceLayout, style, showLocationMissingIndicator }: ActivityCardInfoIconsProps) {
  const { theme } = useTheme()
  const { isSmallDevice } = useLayout(true, forceLayout)
  const { isOnlyReport } = useUserSettings()

  const isActivityClosed = useMemo(() => activityUtils.isActivityClosed(activity), [activity])
  const alignItems = isSmallDevice ? 'flex-end' : 'center'
  const hasLocation = useMemo(() => GeoLocationUtils.isValidLocation(activity?.address), [])
  const hasIcons = !!activity?.inElaboration || (!!showLocationMissingIndicator && !hasLocation)

  if (!hasIcons || !showIcons) return <></>

  return (
    <IM.View style={[styles.icons, style]}>
      {!!activity?.inElaboration && !isOnlyReport && !isActivityClosed && (
        <IM.Icon style={[{ alignItems }]} color={theme.textDetail} size={ICON_SIZE} icon={['fal', 'person-running-fast']} />
      )}
      {!!showLocationMissingIndicator && !hasLocation && (
        <IM.Icon style={[{ alignItems }]} color={theme.textDetail} size={ICON_SIZE} icon={['fal', 'location-dot-slash']} />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  icons: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: IMLayout.horizontalMargin,
  },
})
