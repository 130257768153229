import { useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React from 'react'
import { useRecoilState } from 'recoil'

import useLayout from '../hooks/useLayout'
import { DropdownItems, GanttChartView, ThemeColorExpanded } from '../types'
import { ganttChartViewAtom } from '../utils/stateManager'
import TabSelector from './TabSelector'

export default function GanttChartViewTabSelector() {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isLargeDevice } = useLayout(true)
  const { sessionKey } = useAuthentication()

  const [selected, setSelected] = useRecoilState(ganttChartViewAtom(sessionKey))

  const views: DropdownItems<GanttChartView>[] = [
    { label: i18n.t('CALENDAR_DAY'), value: 'day' },
    { label: i18n.t('CALENDAR_WEEK'), value: 'week' },
    { label: i18n.t('CALENDAR_MONTH'), value: 'month' },
  ]

  const backgroundColor = theme.header.detail.background

  return (
    <TabSelector height={30} width={isLargeDevice ? 280 : 240}>
      {views.map((el, index) => {
        return (
          <TabSelector.Item
            key={index}
            text={el.label}
            selected={el.value === selected}
            textColor="white"
            selectedTextColor={theme.general.info}
            onPress={() => setSelected(el.value)}
            backgroundColor={backgroundColor}
            disableDivider={index === views.length - 1}
            dividerColor={index === views.length - 1 ? undefined : theme.header.detail.skeletonBackground}
            selectionColor={backgroundColor}
          />
        )
      })}
    </TabSelector>
  )
}
