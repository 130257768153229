import { CardProps, IM, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import SplitText from '../../../components/SplitText'
import useActivityClosingSummary from '../../../hooks/activity/useActivityClosingSummary'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import useExtendedTheme from '../../../hooks/useExtendedTheme'
import appUtils from '../../../utils/appUtils'
import ClosingSummaryView from '../../../views/activities/closing/ClosingSummaryView'

type ActivitySummaryCardProps = Pick<CardProps, 'spacing' | 'style'>

export default function ActivitySummaryCard(props: ActivitySummaryCardProps) {
  const { detail, ergoTask } = useActivityDetail()
  const { theme } = useExtendedTheme()
  const { i18n } = useLanguage()
  const { closingSummary, loadingClosingSummary } = useActivityClosingSummary(detail)
  const hidePrices = useMemo(() => ergoTask?.paramList?.HIDE_APP_PRICE === '1', [ergoTask])

  const total = useMemo(() => closingSummary?.totalAmountWithVat ?? 0, [closingSummary?.totalAmountWithVat])
  const totalText = useMemo(() => (loadingClosingSummary ? '' : appUtils.formatPrice(total ?? 0)), [total, loadingClosingSummary])
  const vatText = useMemo(
    () => (loadingClosingSummary ? '' : appUtils.formatPrice(closingSummary?.totalVatAmount ?? 0)),
    [closingSummary, loadingClosingSummary]
  )

  const dividerStyle = useMemo<StyleProp<ViewStyle>>(() => ({ borderTopWidth: 1, borderTopColor: theme.closingSummary.divider }), [theme])

  const discountText = useMemo(() => (detail?.discount ? appUtils.formatPrice(detail?.discount ?? 0) : undefined), [detail?.discount])
  const paidAmountText = useMemo(() => appUtils.formatPrice(detail?.paidAmount ?? 0), [detail?.paidAmount])

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('SUMMARY')} />} noContentSpacing {...props}>
      <ClosingSummaryView loading={loadingClosingSummary} summary={closingSummary} mode={hidePrices ? 'hide-prices' : 'full'} />
      <IM.View spacing={'all'} style={[dividerStyle]}>
        <SplitText leftText={`${i18n.t('TOTAL')} (${i18n.t('VAT_INCL')})`} rightText={totalText} spacing={['horizontal']} primary />
        <SplitText leftText={i18n.t('VAT_SUMMARY')} rightText={vatText} spacing={['horizontal']} secondary />
      </IM.View>
      <IM.View spacing={'all'} style={[dividerStyle]}>
        {!!discountText && <SplitText leftText={`${i18n.t('DISCOUNT')}`} rightText={discountText} spacing={['horizontal']} secondary />}
        <SplitText leftText={i18n.t('PAID_AMOUNT')} rightText={paidAmountText} spacing={['horizontal']} primary />
      </IM.View>
    </IM.Card>
  )
}
