import { useLanguage } from '@infominds/react-native-components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { Activity } from '../../apis/types/apiResponseTypes'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityCreationStackParamList } from '../../navigation/types'
import { EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import ActivityCreationView, { ActivityCreateOrEditViewProps } from '../../views/activities/ActivityCreateOrEditView'

const ActivityCreateOrEditScreen = (
  { controller, handleCreateActivity }: ModalScreenProps<ActivityCreateOrEditViewProps> & { handleCreateActivity?: (activity: Activity) => void },
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const createViewRef = createRef<EditOrCreateViewRef>()
  const route = useRoute<RouteProp<ActivityCreationStackParamList, 'ActivityCreation'>>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined
  const contract = isModal ? controller.data?.contract : route.params?.contract
  const technician = isModal ? controller.data?.technician : route.params?.technician
  const planDateFrom = isModal ? controller.data?.planDateFrom : route.params?.planDateFrom
  const planDateTo = isModal ? controller.data?.planDateTo : route.params?.planDateTo
  const activity = isModal ? controller.data?.activity : route.params?.activity
  const numberOfACtivitiesToCreate = isModal ? controller.data?.numberOfACtivitiesToCreate : route.params?.numberOfACtivitiesToCreate
  const presetActivity = isModal ? controller.data?.presetActivity : route.params?.presetActivity

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal={isModal}
          title={activity ? i18n.t('EDIT_ACTIVITY') : i18n.t('CREATE_ACTIVITY')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            <ActivityCreationView
              ref={createViewRef}
              activity={activity}
              contract={contract}
              technician={technician}
              planDateFrom={planDateFrom}
              planDateTo={planDateTo}
              numberOfACtivitiesToCreate={numberOfACtivitiesToCreate}
              onDone={() => handleGoBack(true)}
              onDeleted={() => {
                controller?.close()
                // navigate back to activities screen
                navigation.navigate('BottomTab', { screen: 'ActivitiesStack', params: { screen: 'Activities' } })

                // if source is given navigate back to that stack (this is done together with the above navigation)
                if (route.params.source) {
                  //@ts-ignore we just want to go back to the original stack. No need
                  navigation.navigate('BottomTab', { screen: route.params.source })
                }
              }}
              onUploadStatus={setStatus}
              handleCreateActivity={handleCreateActivity}
              presetActivity={presetActivity}
            />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(ActivityCreateOrEditScreen)
