import { useEffect } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useObjectUtils from '../useObjectUtils'

export default function useActivityClosingSummary(activity?: Activity | null) {
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const {
    item: closingSummary,
    loading: loadingClosingSummary,
    loadItem: loadClosingSummary,
    setItem: setClosingSummary,
    error: closingSummaryError,
  } = useControlledLoader(api.activities.closingSummary)

  useEffect(refreshSummary, [activity])
  function refreshSummary() {
    if (!activity) {
      setClosingSummary(null)
      return
    }
    loadClosingSummary(activityObjectUtils.createRequestObject(activity))
  }

  return {
    closingSummary,
    loadingClosingSummary,
    refreshSummary,
    closingSummaryError,
  }
}
