import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { ActivityType } from '../../apis/types/apiResponseTypes'
import ActivityTypeCreationScreen from '../../screens/activity/activityTypes/ActivityTypeCreationScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ActivityType | undefined>
}

export default function ActivityTypeCreationModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ActivityTypeCreationScreen ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}
