import React, { memo } from 'react'

import useArticleSearch from '../../../hooks/activity/useArticleSearch'
import ArticleListView from './ArticleListView'

function ArticleFullSearchView() {
  const { articles, loadingArticles, refreshArticles, loadMoreArticles } = useArticleSearch()

  return (
    <ArticleListView articles={articles} loadingArticles={loadingArticles} refreshArticles={refreshArticles} loadMoreArticles={loadMoreArticles} />
  )
}

export default memo(ArticleFullSearchView)
