import lodash from 'lodash'
import { Cursor } from 'react-native-local-mongodb'

import {
  DataProviderObjectLink,
  DataProviderOptions,
  DataProviderPostOptions,
  GetQueryProviderFun,
  GetQueryProviderFunWrapper,
  GetResultModifierFunWrapper,
} from '../types'
import DataProviderHelper from './DataProviderHelper'
import DataProviderUtils from './DataProviderUtils'

export type OfflineProviderOptions<TData, TGetRequest, TPostResult> =
  // depending if TData has an id field the id property is mandatory
  (TData extends { id: string }
    ? {
        id?: (keyof TData)[]
        /**
         * if true, only ever on object of this type will be saved to the local db
         */
        singleObject?: boolean
      }
    : { id: (keyof TData)[]; singleObject?: boolean } | { id?: (keyof TData)[]; singleObject: true }) & {
    /**
     * Provide get-queries to get data from local db. Use DataProviderHelper.query to simplify things
     */
    get_queries?: (GetQueryProviderFunWrapper<TData, TGetRequest> | false | undefined | null)[]
    /**
     * Provide get-modifiers (like sort, skip-take,... ) to modify get result from local db. Use DataProviderHelper.modify to simplify things
     */
    get_modifiers?: (GetResultModifierFunWrapper<TData, TGetRequest> | false | undefined | null)[]
    /**
     * Full filter-function on all get-requests.
     * @return true | false to filter the element
     * TODO implement
     */
    get_filter?: (data: TData) => boolean
    /**
     * Reduces data to save memory in local db
     */
    dataReducer?: (entry: TData) => TData
    /**
     * Determines the local-resource (table). Default is equal to api-resource
     */
    localResource?: string
    /**
     * Synchronisation-type (is only required if the given sync type is not equal to the api resource url ('/' is the same as '_'.)
     * For example: the syncType 'customer_shippingAddress' and the endPoint 'customer/shippingAddress' are automatically linked
     */
    syncType?: string
    /**
     * If auto-querying is enabled all request parameters not used in other queries/modifiers will automatically be applied as a match get-query.
     */
    autoQuery?: boolean
    /**
     * Links a property in the object to another resource. This way subObjects can be handled as their own resources.
     */
    subObjectLinks?: DataProviderObjectLink<TData>[]
  } & (
      | {
          /**
           * Post Options
           */
          post: DataProviderPostOptions<TData, TPostResult>
        }
      | {
          /**
           * Post Options
           */
          post?: DataProviderPostOptions<TData, string>
        }
    )

export default function OfflineProvider<TData extends object, TGetRequest = void, TPostResult = Partial<TData>>(
  options: OfflineProviderOptions<TData, TGetRequest, TPostResult>
): DataProviderOptions<TData, TGetRequest, TPostResult> {
  const { get_queries, get_modifiers, singleObject, dataReducer, autoQuery = true, subObjectLinks } = options ?? {}
  const id = singleObject ? (['id'] as (keyof TData)[]) : ((options?.id ?? ['id']) as (keyof TData)[])

  function get_resultModifier(result: Cursor<TData[]>, request?: TGetRequest) {
    if (!get_modifiers?.length) return result
    for (const mod of get_modifiers) {
      if (!mod || !mod.fun) continue
      result = mod.fun(result, request)
    }
    return result
  }

  function get_queryProvider(request?: TGetRequest) {
    if (!request) return {}

    const keys = Object.keys(request)
      .map(key => key as keyof TGetRequest)
      .filter(key => request[key] !== undefined && request[key] !== null)
    if (!keys.length) return {}

    const queries: object[] = []
    for (const key of keys) {
      const queriesContainingKey = get_getQueriesContainingKey(key)
      let foundQuery = false
      if (queriesContainingKey) {
        for (const get_query of queriesContainingKey) {
          const result = get_query(request)
          if (result !== undefined && !lodash.isEmpty(result)) {
            foundQuery = true
            queries.push(result)
          }
        }
      }

      if (!foundQuery && autoQuery && !getModifierContainsKey(key)) {
        const query = DataProviderHelper.query.match(key)
        if (!query) continue
        const result = query.fun({ [key]: request[key] } as TGetRequest)
        if (!!result && !lodash.isEmpty(result)) {
          queries.push(result)
        }
      }
    }
    if (!queries.length) return {}
    if (queries.length === 1) return queries[0]
    return { $and: queries.filter(arg => arg !== undefined && arg !== null) }
  }

  function get_id_provider() {
    if (singleObject) {
      return () => ({ id: '0' }) as Record<string, string | number>
    }
    return (entry: TData) => DataProviderUtils.createIdFrom<TData>(entry, ...id)
  }

  function getModifierContainsKey(key: keyof TGetRequest) {
    return !!get_modifiers?.length && !!get_modifiers.find(mod => !!mod && mod.modifierKeys.includes(key))
  }

  function get_getQueriesContainingKey(key: keyof TGetRequest) {
    if (!get_queries?.length) return undefined
    const result = get_queries
      .filter(mod => !!mod && mod.modifierKeys.includes(key))
      .reduce<GetQueryProviderFun<TData, TGetRequest>[]>((functions, item) => (item ? [...functions, item.fun] : functions), [])
    if (!result?.length) return undefined
    return result
  }

  return {
    id,
    enableOffline: true,
    get_resultModifier,
    get_queryProvider,
    id_provider: get_id_provider(),
    dataReducer,
    localResource: options?.localResource,
    syncType: options?.syncType,
    subObjectLinks,
  }
}
