import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import { DrawerNavigationOptions } from '@react-navigation/drawer'
import React, { PropsWithChildren } from 'react'
import { Platform } from 'react-native'

import useLayout from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import { BottomTabParamList } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import { DrawerProvider } from './DrawerContext'
import { DrawerNavigator as DrawerNavigatorLargeDevice, TabBarWeb } from './DrawerNavigatorLargeDevice'
import { DrawerNavigator as DrawerNavigatorSmallDevice } from './DrawerNavigatorSmallDevice'

type TabNavigatorProps = {
  initialRouteName?: keyof BottomTabParamList
  screenOptions?: DrawerNavigationOptions
}

export default function DrawerNavigator<RouteName extends keyof BottomTabParamList>(props: TabNavigatorProps) {
  const { isLargeDevice } = useLayout(true)

  if (isLargeDevice) return <DrawerNavigatorLargeDevice<RouteName> {...props} />
  return <DrawerNavigatorSmallDevice<RouteName> {...props} />
}

export function TabBarProvider({ children }: PropsWithChildren) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isLargeDevice } = useLayout(true)
  const { isOnlyReport } = useUserSettings()

  if (!isLargeDevice || Platform.OS !== 'web') return <IM.View style={[IMLayout.flex.f1]}>{children}</IM.View>
  return (
    <DrawerProvider>
      <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
        <IM.View style={[{ backgroundColor: theme.drawerNavigator.background }]}>
          <TabBarWeb defaultRoute={isOnlyReport ? 'ReportStack' : 'ActivitiesStack'} />
        </IM.View>
        <IM.View style={[IMLayout.flex.f1]}>{children}</IM.View>
      </IM.View>
    </DrawerProvider>
  )
}
