import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api } from '../../apis/apiCalls'
import { Country } from '../../apis/types/apiResponseTypes'
import SelectorCard from '../../cards/SelectorCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  value: string | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Country | undefined) => void
} & Pick<SelectInputProps<Country>, 'required' | 'disableFastInput' | 'title' | 'placeholder'>

export default function CountrySelector({ value, onChange, title, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const { item: data, loadItem, loading, error } = useControlledLoader(api.countries.getList)
  const filteredData = useDataSearchFilter(data, search, ['description', 'countryId'])
  const selectedItem = useMemo(() => data?.find(d => d.countryId === value), [data, value])

  useEffect(loadItem, [])

  const render = ({ item }: SectionListRenderItemInfo<Country, ListSection<Country>>, onPress?: () => void) => {
    return (
      <SelectorCard
        key={item.countryId}
        onPress={onPress}
        selected={item.countryId === value}
        value={getTitle(item)}
        spacing={['bottom', 'horizontal']}
      />
    )
  }

  return (
    <>
      <SelectInput
        id={['countryId']}
        data={filteredData}
        value={selectedItem}
        loading={loading}
        title={title ?? i18n.t('ADDRESS_STATE')}
        screenTitle={title ?? i18n.t('ADDRESS_STATE')}
        noDataMessage={i18n.t('NO_ADDRESS_STATE_FOUND')}
        renderItem={render}
        onChange={onChange}
        onSearchChange={setSearch}
        refresh={loadItem}
        renderSelectedString={getTitle}
        disableLoadAfterMount
        error={error}
        {...props}
      />
    </>
  )
}

function getTitle(country: Country) {
  return [country.countryId, country.description].filter(Boolean).join(' - ')
}
