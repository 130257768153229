import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CalendarViewMode } from '@howljs/calendar-kit'
import { Language } from '@infominds/react-native-components'
import dayjs from 'dayjs'

import TimeUtils from './TimeUtils'

const CalendarUtils = {
  convertActivityPlanTimeStringToDate(dateString: string, timeString: string) {
    try {
      const [dateTerm] = dateString.split('T')
      const date = new Date(dateTerm)
      const time = TimeUtils.parseTimeSpan(timeString)
      date.setHours(time.hours)
      date.setMinutes(time.minutes)
      date.setSeconds(time.seconds)
      return date
    } catch (e) {
      console.error(e)
    }
    return undefined
  },
  getIcon(nextView: CalendarViewMode): IconProp {
    switch (nextView) {
      case 'day':
        return ['fal', 'calendar-day']
      case 'threeDays':
        return ['fal', 'calendar-week']
      case 'workWeek':
        return ['fal', 'calendar-clock']
      case 'week':
        return ['fal', 'calendar']
    }
  },
  getWeekDaysName(type: 'long' | 'short', locale: Language, shift?: number) {
    const localeDayjs = dayjs().locale(locale).localeData()

    let days: dayjs.WeekdayNames

    if (type === 'long') {
      days = [...localeDayjs.weekdays()]
    } else {
      days = [...localeDayjs.weekdaysShort()]
    }

    if (shift) {
      for (let i = 0; i < shift; i++) {
        const shifted = days.shift()
        shifted && days.push(shifted)
      }
    }

    return days
  },
}

export default CalendarUtils
