import { IM, IMLayout } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import MediaViewAsset, { MediaViewAssetProps } from './MediaViewAsset'

export type AssetBarProps = { assets?: LiteAsset[] } & Pick<MediaViewAssetProps, 'showLoadingSpinner' | 'onAssetPressed' | 'style'> &
  Partial<Pick<MediaViewAssetProps, 'size'>>

export default function AssetBar({ assets, size = 50, style, ...assetProps }: AssetBarProps) {
  return (
    <IM.View style={[IMLayout.flex.row, styles.container, style]}>
      <IM.View style={[styles.scrollViewContainer]}>
        <ScrollView horizontal>
          {assets?.map(asset => <MediaViewAsset key={asset.id} asset={asset} size={size} style={styles.assetView} {...assetProps} />)}
        </ScrollView>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  assetView: {
    marginHorizontal: 2,
  },
  scrollViewContainer: {
    flexShrink: 1,
  },
  container: {
    justifyContent: 'center',
  },
})
