import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../../types'

export type MapMarkerIconProps = {
  icon: IconProp
  style?: StyleProp<ViewStyle>
  selected?: boolean
  unassigned?: boolean
  highlightText?: string
  color?: string
}

const MapMarkerIcon = memo(function MapMarkerIcon({ icon, style, selected, highlightText, unassigned }: MapMarkerIconProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <>
      <IM.View
        style={[
          styles.iconView,
          { backgroundColor: theme.map.marker.background },
          unassigned && { backgroundColor: theme.map.marker.unassigned },
          IMLayout.flex.row,
          style,
        ]}>
        <IM.Icon size={25} color={IMStyle.palette.white} icon={icon} />
        {!!selected && !!highlightText && (
          <IM.View style={styles.highlightTextView}>
            <IM.Text style={styles.highlightText}>{highlightText}</IM.Text>
          </IM.View>
        )}
      </IM.View>
      <IM.View
        style={[styles.triangle, { borderTopColor: theme.map.marker.background }, unassigned && { borderTopColor: theme.map.marker.unassigned }]}
      />
    </>
  )
})

export default MapMarkerIcon

const styles = StyleSheet.create({
  iconView: {
    flex: 1,
    padding: 8,
    borderRadius: IMLayout.borderRadius,
  },
  triangle: {
    zIndex: -1,
    marginTop: -3,
    alignSelf: 'center',
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 9,
    borderRightWidth: 9,
    borderTopWidth: 15,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
  },
  highlightText: { marginLeft: 10, color: IMStyle.palette.white },
  highlightTextView: {
    justifyContent: 'center',
  },
})
