import { CardProps, IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { EmailModel } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ThemeColorExpanded } from '../../types'

export type EmailModelCardProps = {
  model: EmailModel
} & Pick<CardProps, 'style' | 'spacing' | 'onPress' | 'selected'>

export default function EmailModelCard({ model, selected, ...otherProps }: EmailModelCardProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { value: title } = useLanguageSelect({ en: model.subject_En, it: model.subject_It, de: model.subject_De })

  return (
    <IM.Card {...otherProps} head={<CardLeftTitle color={selected && theme.primary} />}>
      <IM.View style={IMLayout.flex.row}>
        <IM.View style={IMLayout.flex.f1}>
          <IM.View style={[IMLayout.flex.row, styles.titleView]}>
            <IM.Text primary>{title}</IM.Text>
            {!!model.obsolete && <IM.Text secondary>{i18n.t('OBSOLETE')}</IM.Text>}
          </IM.View>
          <IM.Text>{model.activityType}</IM.Text>
          {!!model.isDefault && <IM.Text secondary>{i18n.t('DEFAULT_MODEL')}</IM.Text>}
        </IM.View>
      </IM.View>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  titleView: {
    justifyContent: 'space-between',
  },
})
