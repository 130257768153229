import { LiteAsset } from '@infominds/react-native-media-lite'
import type { Dispatch, SetStateAction } from 'react'
import type { ColorSchemeName } from 'react-native'

import { GalleryUtilsType } from '../types'

export const galleryUtils: GalleryUtilsType = {
  async launchGallery(
    _prevSelectedMedia: LiteAsset[],
    _onAccept: Dispatch<SetStateAction<LiteAsset[]>>,
    _cancelTextIOS: string,
    _openGalleryIOS: string,
    _openFilesIOS: string,
    _colorScheme: NonNullable<ColorSchemeName>
  ) {
    return new Promise(() => {
      return
    })
  },
}
