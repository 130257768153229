import { DateUtils, IM, useLanguage } from '@infominds/react-native-components'
import { addMinutes, isSameDay } from 'date-fns'
import React, { useEffect, useMemo } from 'react'

import { api } from '../../../apis/apiCalls'
import EmployeeCard from '../../../cards/common/EmployeeCard'
import DeleteButton from '../../../components/DeleteButton'
import GroupSpacer from '../../../components/GroupSpacer'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import Separator from '../../../components/Infominds/Separator'
import DateTimeInput from '../../../components/input/DateTimeInput'
import ScrollViewForm from '../../../components/ScrollViewForm'
import Text from '../../../components/Text'
import CONSTANTS from '../../../constants/Constants'
import useTimeSlots from '../../../hooks/activity/useTimeSlots'
import useExtendedTheme from '../../../hooks/useExtendedTheme'
import { PlannedActivityEmployee } from '../../../types'
import { imDateUtils } from '../../../utils/infominds/imDateUtils'
import TimeUtils from '../../../utils/TimeUtils'
import TechnicianPlannedActivitiesView from '../../../views/activities/planning/TechnicianPlannedActivitiesView'

interface Props {
  state: PlannedActivityEmployee | undefined
  initialData?: PlannedActivityEmployee
  error?: boolean
  setState: React.Dispatch<React.SetStateAction<PlannedActivityEmployee | undefined>>
  onDelete: () => void
  estimatedTime?: string
  timeSlotId?: number
}

export default function ActivityPlanEmployeeModalView({ state, setState, onDelete, error, estimatedTime, timeSlotId, initialData }: Props) {
  const { theme } = useExtendedTheme()
  const { i18n } = useLanguage()
  const { item: unavailables, loadItem: loadUnavailables } = useControlledLoader(api.activities.unavailables.getList)
  const { item: activities, loadItem: loadActivities } = useControlledLoader(api.activities.employees.getPlanned)

  const { timeSlots } = useTimeSlots(timeSlotId)
  const selectedTimeSlot = useMemo(() => (timeSlotId ? timeSlots?.at(0) : undefined), [timeSlots])
  const selectedTimeSlotStartDate = useMemo(
    () => (selectedTimeSlot?.hourFrom ? TimeUtils.addTimeSpanToDate(new Date(), selectedTimeSlot.hourFrom)?.toISOString() : undefined),
    [selectedTimeSlot]
  )

  useEffect(() => {
    if (!state) return

    const toUTC = imDateUtils.toUTC(state.planDateTo)
    const fromUTC = imDateUtils.toUTC(state.planDateFrom)

    if (state && toUTC && fromUTC) {
      loadUnavailables({
        planDateTo: toUTC,
        planDateFrom: fromUTC,
        technicianId: state.technician?.id,
        technicianType: state.technician?.technicianType,
      })
      loadActivities({
        planDateTo: toUTC,
        planDateFrom: fromUTC,
        technicianId: state.technician?.id,
        technicianType: state.technician?.technicianType,
      })
    }
  }, [state])

  const minToDate = useMemo(() => (state?.planDateFrom ? DateUtils.dateify(state.planDateFrom) : CONSTANTS.today), [state?.planDateFrom])
  const activitiesFiltered = state
    ? activities?.filter(
        el =>
          !(
            el.srvActivityId === state.srvActivityId &&
            el.srvActivityTypeId === state.srvActivityTypeId &&
            el.srvActivityYear === state.srvActivityYear
          )
      )
    : activities
  const employeeHasUnavailable = unavailables === undefined ? false : unavailables.length !== 0
  const employeeHasActivity = activitiesFiltered === undefined ? false : activitiesFiltered.length !== 0
  const employeeUnavailable = employeeHasUnavailable || employeeHasActivity

  function getNewToTime(value: Date) {
    let minutesToAdd = 60
    if (estimatedTime) {
      minutesToAdd = TimeUtils.timeSpanToMinutes(estimatedTime)
    } else if (selectedTimeSlot?.hourFrom && selectedTimeSlot.hourTo) {
      const endTime = TimeUtils.addTimeSpanToDate(value, selectedTimeSlot.hourTo)
      if (endTime && endTime > value) return endTime.toISOString()
    }

    return addMinutes(value, minutesToAdd).toISOString()
  }

  function handlePlanDateFromChanged(value: Date | undefined, forceSet?: boolean) {
    setState(prev => {
      if (!prev) return prev
      if (value && (forceSet || (prev.planDateFrom === undefined && value.toISOString().includes('T00:00')))) {
        if (selectedTimeSlot?.hourFrom) {
          const { hours } = TimeUtils.parseTimeSpan(selectedTimeSlot.hourFrom)
          value.setHours(hours, 0)
        } else {
          value?.setHours(8, 0)
        }
      }
      value?.setSeconds(0, 0)
      let planDateTo = prev.planDateTo
      const toDate = planDateTo ? new Date(planDateTo) : undefined
      // if no to-time is set or to-time is < new from-time, set new to-time
      if (value && (forceSet || !toDate || isSameDay(toDate, value) || toDate < value)) {
        planDateTo = getNewToTime(value)
      }

      return { ...prev, planDateFrom: value?.toISOString(), planDateTo: planDateTo }
    })
  }

  useEffect(() => {
    if ((!selectedTimeSlot && timeSlotId) || state?.planDateFrom || state?.planDateTo) return
    handlePlanDateFromChanged(new Date(), true)
  }, [selectedTimeSlot])

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      {state?.technician && (
        <IM.View spacing="bottom">
          <IM.View spacing="bottom">
            <IM.Text primary>{i18n.t('COLLABORATOR')}</IM.Text>
          </IM.View>
          <EmployeeCard employee={state?.technician} borderless />
        </IM.View>
      )}
      {employeeUnavailable && (
        <IM.View>
          <Text style={{ color: theme.general.warn }}>{i18n.t('UNAVAILABLE_WARN')}</Text>
        </IM.View>
      )}
      <DateTimeInput
        error={error}
        value={state?.planDateFrom}
        onChangeDate={handlePlanDateFromChanged}
        // The minimum date should be undefined when the user is editing and already set time otherwise it will be change automatically
        minimumDate={state?.planDateFrom === undefined ? CONSTANTS.today : undefined}
        maximumDate={CONSTANTS.maxDate}
        datePickerDefaultValue={selectedTimeSlotStartDate}
        title={i18n.t('DATE_FROM')}
        spacing="vertical"
      />
      <DateTimeInput
        error={error}
        value={state?.planDateTo}
        onChangeDate={value => setState({ ...state, planDateTo: value?.toISOString() } as PlannedActivityEmployee)}
        minimumDate={minToDate}
        maximumDate={CONSTANTS.maxDate}
        datePickerDefaultValue={state?.planDateFrom}
        title={i18n.t('DATE_TO')}
      />

      <GroupSpacer />

      <DeleteButton onPress={onDelete} title={i18n.t('DELETE')} />
      {!!state?.technician?.id && (
        <>
          <GroupSpacer />
          <Separator />
          <TechnicianPlannedActivitiesView employeeId={state?.technician?.id} filterTime={initialData} />
        </>
      )}
    </ScrollViewForm>
  )
}
