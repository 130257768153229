import lodash from 'lodash'

import { dataProviderCore } from './InitDataProvider'
import { DataProviderOptions, GetApiResultModifierFun } from './types'

/**
 *
 * @param apiResource api resource ("f.ex customer"). May include {tokens} which will be replaced with their corresponding value from the request object
 * @param options
 * @param getResponseModifier
 * @returns
 */
export function DataProvider<
  TData extends object,
  TGetRequest = void,
  TPostResult extends Partial<TData> = Partial<TData>,
  TPutResult = string,
  TPatchResult = string,
  TDeleteResult = boolean,
>(apiResource: string, options?: DataProviderOptions<TData, TGetRequest, TPostResult>, getResponseModifier?: GetApiResultModifierFun<TData>) {
  //: DataProviderResult<TData, TGetRequest, TPostResult>
  const resource = options?.localResource ?? apiResource
  const syncType = options?.syncType || apiResource.replace('/', '_') //sync type is normally the same as the apiResource ('/' is not allowed in C# enum, therefore '_' is used as a replacement value)
  dataProviderCore.RegisterResource(resource, apiResource, !!options?.enableOffline && syncType, lodash.cloneDeep(options))

  function GET(request?: TGetRequest, abortController?: AbortController) {
    return dataProviderCore.Get({
      request,
      abortController,
      resource,
      apiResource,
      options: options as DataProviderOptions<TData, TGetRequest>,
      resultModifier: getResponseModifier,
    })
  }

  async function GET_FIRST(request?: TGetRequest, abortController?: AbortController) {
    const data = await dataProviderCore.Get({
      request,
      abortController,
      resource,
      apiResource,
      options: options as DataProviderOptions<TData, TGetRequest>,
      resultModifier: getResponseModifier,
    })
    return data?.length ? data[0] : null
  }

  function POST(data: Partial<TData>, abortController?: AbortController) {
    return dataProviderCore.Post<TData>({
      data: data as TData,
      type: 'POST',
      resource,
      apiResource,
      options: options as DataProviderOptions<TData>,
      abortController,
    }) as Promise<TPostResult>
  }

  function PUT(data: Partial<TData>, abortController?: AbortController) {
    return dataProviderCore.Update<TData>({
      data: data as TData,
      type: 'PUT',
      resource,
      apiResource,
      options: options as DataProviderOptions<TData>,
      abortController,
    }) as Promise<TPutResult>
  }

  function PATCH(data: Partial<TData>, abortController?: AbortController) {
    return dataProviderCore.Update<TData>({
      data: data as TData,
      type: 'PATCH',
      resource,
      apiResource,
      options: options as DataProviderOptions<TData>,
      abortController,
    }) as Promise<TPatchResult>
  }

  function DELETE(data: Partial<TData>, abortController?: AbortController) {
    return dataProviderCore.Update<TData>({
      data: data as TData,
      type: 'DELETE',
      resource,
      apiResource,
      options: options as DataProviderOptions<TData>,
      abortController,
    }) as Promise<TDeleteResult>
  }

  return { GET, GET_FIRST, POST, PUT, PATCH, DELETE, IDs: options?.id ?? [] }
}
