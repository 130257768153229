import { IM, IMLayout, useLanguage, useModalController, Utils } from '@infominds/react-native-components'
import { AssetUtils, LiteAsset } from '@infominds/react-native-media-lite'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api } from '../../../apis/apiCalls'
import { ActivityArticle } from '../../../apis/types/apiResponseTypes'
import ArticleImage from '../../../components/activity/articles/ArticleImage'
import ApiDeleteButton from '../../../components/ApiDeleteButton'
import GroupSpacer from '../../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../../components/Infominds/hooks/useEditDataHandler'
import NumberInput from '../../../components/input/NumberInput'
import TextInput, { TextInputProps } from '../../../components/input/TextInput'
import ScrollViewForm from '../../../components/ScrollViewForm'
import ActivityTypeSelector from '../../../components/selectors/ActivityTypeSelector'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import DisplayAssetModal from '../../../modals/media/DisplayAssetModal'
import { ActivityArticleEditScreenProps, EditOrCreateViewRef, UploadStatus } from '../../../types'
import { articleUtils } from '../../../utils/ArticleUtils'
import SupplierArticlesView from './SupplierArticlesView'

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

function ActivityArticleEditView(
  { article, onDone, onUploadStatus, editable }: ActivityArticleEditScreenProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: () => createOrUpdate(),
  }))

  const { i18n } = useLanguage()
  const showAssetModal = useModalController<LiteAsset>()
  const { ergoTask, detail } = useActivityDetail()
  const showPrices = ergoTask?.paramList?.HIDE_APP_PRICE !== '1'

  const requiredFields = useMemo<EditDataHandlerRequiredFields<ActivityArticle>>(() => ['articleId', 'quantity'], [])
  const { state, createOrUpdate, handleDataChange } = useEditDataHandler<ActivityArticle>(undefined, api.activities.articles.put, undefined, {
    eventKeyModification: EVENT_KEYS.ACTIVITY_ARTICLES_UPDATED,
    onDone: onDone,
    onUploadStatus: onUploadStatus,
    editMode: true,
    initialValue: article,
    showErrorAlert: true,
    requiredFields,
    validate: data => (!data.quantity || data.quantity <= 0 ? ['quantity'] : []),
    modifyDataBeforeRequest: data => ({ ...data, measureUnitId: data.measureUnitId ?? data.measureUnit?.measureUnitId, determinePrice: true }),
  })

  const prices = useMemo(() => articleUtils.calcPrice(state), [state])

  function handleImagePressed(image: string) {
    AssetUtils.base64ToAsset(image, Utils.getUid(), 'tmp.jpeg', 'photo')

      .then(asset => showAssetModal.show(asset))
      .catch(console.error)
  }

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
    editable,
  }
  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <TextInput title={i18n.t('ARTICLE')} value={article.articleId} {...commonProps} editable={false} />
      <TextInput title={i18n.t('DESCRIPTION')} value={article.description} multiline {...commonProps} editable={false} />
      <ActivityTypeSelector
        initialValue={article?.activityArticlervActivityTypeId}
        onChange={value => handleDataChange({ activityArticlervActivityTypeId: value?.srvActivityTypeId })}
        required
        {...commonProps}
      />
      {showPrices && (
        <NumberInput
          title={i18n.t('UNIT_PRICE')}
          value={article.netPrice}
          min={Number.NEGATIVE_INFINITY}
          disableFastInputs
          unit={'€'}
          {...commonProps}
          editable={false}
        />
      )}
      <NumberInput
        title={i18n.t('QUANTITY')}
        required
        value={article.quantity}
        onValueChanged={value => handleDataChange({ quantity: value })}
        min={0}
        unit={article.measureUnit?.description}
        {...commonProps}
      />

      {!!prices.total && showPrices && (
        <NumberInput
          title={i18n.t('TOTAL')}
          details={`+${prices.vatRate}% ${i18n.t('VAT')} (${prices.vat}€)`}
          value={prices.total}
          disableFastInputs
          unit={'€'}
          {...commonProps}
          editable={false}
        />
      )}

      <GroupSpacer />

      {!!article.articleId && <SupplierArticlesView articleId={article.articleId} />}

      <IM.View spacing={'bottom'}>
        <ArticleImage
          article={article}
          size={200}
          style={{ marginBottom: IMLayout.verticalMargin }}
          onPress={handleImagePressed}
          taskId={detail?.taskId}
        />
      </IM.View>
      <GroupSpacer />

      {!!article && editable && (
        <ApiDeleteButton
          onDeleted={onDone}
          data={article}
          deleteAlertMessage={i18n.t('DELETE_ACTIVITY_ARTICLE_ALERT')}
          deleteAlertTitle={i18n.t('DELETE_ACTIVITY_ARTICLE')}
          deleteRequest={api.activities.articles.delete}
          eventKey={EVENT_KEYS.ACTIVITY_ARTICLES_UPDATED}
          {...commonProps}
        />
      )}
      <GroupSpacer />
      <DisplayAssetModal controller={showAssetModal} onDeleteAsset={() => null} />
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityArticleEditView)
