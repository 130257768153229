import { SpacingProps } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import useConstructionSiteLots from '../../../hooks/constructionSite/useConstructionSiteLots'
import useContractLots from '../../../hooks/contracts/useContractLots'
import ConstructionSiteLottoView from '../../../views/customer/ConstructionSiteLottoView'

interface Props {
  error?: ConstrainBoolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  changeable?: boolean
}

export default function ActivityDetailConstructionSiteLottoLottoIdCard({ changeable }: Props) {
  const { detail } = useActivityDetail()

  const { constructionSiteLots: data, loadingConstructionSiteLots, refreshConstructionSiteLots } = useConstructionSiteLots(detail?.objectId)

  const { contractLots, loadingContractLots, refreshObjectLots } = useContractLots(detail?.srvContractId)

  const loadingItems = loadingConstructionSiteLots || loadingContractLots

  const constructionSiteLotto = useMemo(
    () =>
      data?.find(
        q =>
          q.objectId === detail?.objectId &&
          (q.lotId === detail.objectLotId ||
            (!!detail?.srvContractLotId && contractLots?.find(c => c.srvContractLotId === detail.srvContractLotId)?.objectLotId === q.lotId))
      ),
    [data, detail, contractLots]
  )

  useEffect(() => {
    refreshConstructionSiteLots()
    if (detail?.srvContractId && detail?.srvContractLotId) {
      refreshObjectLots()
    }
  }, [detail?.objectId, detail?.objectLotId])

  if (!constructionSiteLotto && loadingItems === false) return <></>

  return <ConstructionSiteLottoView constructionSiteLotto={constructionSiteLotto} enableChange={changeable} loading={loadingItems} />
}
