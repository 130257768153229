import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { CommonStackParamList } from '../../navigation/types'
import FilterScreen from '../../screens/common/FilterScreen'
import { ModalScreenRef } from '../../types'
import { FilterViewProps } from '../../views/common/FilterView'
import BaseServiceModal from '../BaseServiceModal'

type Props<T> = {
  controller: ModalController<CommonStackParamList['Filter']>
} & FilterViewProps<T>

export default function FilterModal<T = object>({ controller, ...viewProps }: Props<T>) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <FilterScreen ref={ref} controller={controller} {...viewProps} />
    </BaseServiceModal>
  )
}
