import { IM, IMLayout, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Employee, Technician } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import EmployeeBadge from '../../components/EmployeeBadge'
import PressableIcon from '../../components/Infominds/PressableIcon'
import { EmployeeUtils } from '../../utils/EmployeeUtils'

interface Props {
  employee: Employee | Technician
  onPress?: () => void
  selected?: boolean
  borderless?: boolean
  spacing?: SpacingProps
  onDelete?: () => void
  disableDelete?: boolean
  titleStyle?: StyleProp<ViewStyle>
}

const EmployeeCard = ({ employee, onPress, selected, borderless, spacing, onDelete, disableDelete, titleStyle }: Props) => {
  const { theme } = useTheme()

  const name = useMemo(() => EmployeeUtils.getName(employee), [employee])

  const isEmployee = useMemo(() => !EmployeeUtils.isTechnicianType(employee) || employee.technicianType === 'employee', [employee])

  const content = (
    <IM.View style={[IMLayout.flex.row]}>
      <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, styles.content, titleStyle]}>
        <EmployeeBadge id={employee.id} name={EmployeeUtils.getName(employee)} fetchImage={isEmployee} />
        <IM.Text
          primary
          numberOfLines={1}
          style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }]}>
          {name}
        </IM.Text>
      </IM.View>
      {onDelete && (
        <IM.View>
          <PressableIcon icon={['fas', 'circle-minus']} size={20} color={IMStyle.palette.red} onPress={onDelete} disabled={disableDelete} />
        </IM.View>
      )}
    </IM.View>
  )

  if (borderless) {
    return <IM.View spacing={spacing}>{content}</IM.View>
  }

  return (
    <IM.Card
      head={borderless ? undefined : <CardLeftTitle color={selected && theme.primary} />}
      onPress={onPress}
      borderless={borderless}
      spacing={spacing}>
      {content}
    </IM.Card>
  )
}

export default EmployeeCard

const styles = StyleSheet.create({
  content: {
    gap: IMLayout.horizontalMargin,
    alignItems: 'center',
  },
})
