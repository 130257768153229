import { SpacingProps, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { ActivityType } from '../../apis/types/apiResponseTypes'
import ActivityTypeCard from '../../cards/activities/ActivityTypeCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ListSection, ThemeColorExpanded } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput from './selectInput/SelectInput'

interface Props {
  values?: number[]
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: ActivityType[] | undefined) => void
  required?: boolean
}

export default function MultiActivityTypeSelector({ values, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [search, setSearch] = useState('')
  const { languageSelect } = useLanguageSelect()

  const { item: data, loadItem, loading } = useControlledLoader(api.activities.types.getList)
  const filteredData = useDataSearchFilter(data, search, ['descriptionDe', 'descriptionIt', 'descriptionEn'])

  const selectedTypes = useMemo(() => data?.filter(d => values?.includes(d.srvActivityTypeId)), [values, data])

  const selectedText = useMemo(
    () =>
      selectedTypes?.map(se => languageSelect({ de: se.descriptionDe ?? '', en: se.descriptionEn ?? '', it: se.descriptionIt ?? '' })).join(', ') ??
      '',
    [selectedTypes]
  )

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadItem()

  const render = ({ item }: SectionListRenderItemInfo<ActivityType, ListSection<ActivityType>>, onPress?: () => void) => {
    return (
      <ActivityTypeCard
        activityType={item}
        onPress={() => onPress?.()}
        style={[values?.includes(item.srvActivityTypeId) && { backgroundColor: theme.card.cardBackground.selected }]}
        spacing={['bottom', 'horizontal']}
        showPlanColor
      />
    )
  }

  function handleChange(value: ActivityType | undefined) {
    if (!value) {
      onChange(undefined)
      return
    }
    if (selectedTypes?.find(se => se.srvActivityTypeId === value.srvActivityTypeId)) {
      onChange(selectedTypes.filter(se => se.srvActivityTypeId !== value.srvActivityTypeId))
      return
    }
    onChange(Utils.keepUniques([...(selectedTypes ?? []), value], q => q.srvActivityTypeId))
  }

  return (
    <SelectInput
      id={apiDtoIds.activityTypes}
      data={filteredData}
      value={selectedTypes?.find(se => !!se)}
      renderSelectedString={selectedText}
      showNoSelectionItem={false}
      loading={loading}
      refresh={refresh}
      title={i18n.t('ACTIVITY_TYPE')}
      screenTitle={i18n.t('ACTIVITY_TYPES')}
      noDataMessage={i18n.t('NO_ACTIVITY_TYPE')}
      renderItem={render}
      onChange={handleChange}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      disableFastInput
      multiline
      keepOpenOnChange
      {...props}
    />
  )
}
