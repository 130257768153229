import { apiClient } from '@infominds/react-api'
import { _INTERNAL_LicenseAuthenticationGlobals, _INTERNAL_LicenseDataGlobals } from '@infominds/react-native-license'

import { DataProvider } from '../dataProvider/DataProvider'
import { ActivityId } from '../types'
import { ExceptionUtils } from '../utils/ExceptionUtils'
import { OfflineConfig } from './offline/OfflineConfig'
import {
  ActivityClosingRequest,
  ActivityClosingSummaryRequest,
  ActivitySendEmailRequest,
  ActivityTypeUpdateOrderRequest,
  GetActivityArticleMedia,
  GetActivityArticlesRequest,
  GetActivityContractDetailRequest,
  GetActivityContractLotsRequest,
  GetActivityDetailRequest,
  GetActivityEmployeePlanningRequest,
  GetActivityEmployeeRequest,
  GetActivityMedia,
  GetActivityNotesRequest,
  GetActivityPlanningRequest,
  GetActivityRequest,
  GetActivityTimeRequest,
  GetActivityTravelRequest,
  GetArticlePriceList,
  GetArticleRequest,
  GetConstructionSiteContactsRequest,
  GetConstructionSiteDetailRequest,
  GetConstructionSiteLottosRequest,
  GetConstructionSiteRequest,
  GetCustomerConstructionSiteDetailRequest,
  GetCustomerConstructionSitesRequest,
  GetCustomerContactDetailRequest,
  GetCustomerContactsRequest,
  GetCustomerDestinationDetailRequest,
  GetCustomerDestinationRequest,
  GetCustomerDetailRequest,
  GetCustomersRequest,
  GetEmailModelsRequest,
  GetEmailSettingsRequest,
  GetEmployeeRequest,
  GetErgoTaskDetail,
  GetErgoTasksRequest,
  GetFollowupActivityRequest,
  GetHistoryArticleRequest,
  GetPriceListArticle,
  GetSupplierArticleRequest,
  GetTechniciansRequest,
  GetTimeSlotRequest,
  GetUnavailableRequest,
  GetVehiclesRequest,
  PostCustomerRequest,
  SendEmailRequest,
} from './types/apiRequestTypes'
import {
  Activity,
  ActivityArticle,
  ActivityArticleMedia,
  ActivityClosingSummary,
  ActivityEmployee,
  ActivityMedia,
  ActivityNote,
  ActivityNotePostResult,
  ActivityRDA,
  ActivityTime,
  ActivityTravel,
  ActivityType,
  Article,
  ArticlePriceList,
  ConstructionSite,
  ConstructionSiteLotto,
  Contact,
  Contract,
  ContractObjectLot,
  CorpPosition,
  Country,
  Customer,
  Destination,
  EmailModel,
  EmailSettings,
  Employee,
  ErgoLanguage,
  ErgoTask,
  ErgoTitle,
  MailingType,
  Mandant,
  MenuItems,
  Qualification,
  SendEmailResponse,
  SupplierArticle,
  Technician,
  TimeAttendance,
  TimeSlot,
  Unavailable,
  UnavailableType,
  UserSettings,
  Vehicle,
} from './types/apiResponseTypes'

const client = apiClient(_INTERNAL_LicenseDataGlobals, '/api/', _INTERNAL_LicenseAuthenticationGlobals, true, true)

// UserSettings
const userSettings = DataProvider<UserSettings>('userSettings', OfflineConfig.userSettings)
const menuItems = DataProvider<MenuItems>('menu', OfflineConfig.menuItems)

// Customer
const customers = DataProvider<Customer, GetCustomersRequest>('customers', OfflineConfig.customer)
const postCustomer = (request: PostCustomerRequest, abortController?: AbortController) =>
  client.POST<number>(`customers/${request.taskId ?? 0}`, request, abortController)

const customerDetail = DataProvider<Customer, GetCustomerDetailRequest>('customers/{customerId}', OfflineConfig.customerDetail)
const customerContacts = DataProvider<Contact, GetCustomerContactsRequest>('customers/{customerId}/contacts', OfflineConfig.customerContacts)
const customerContact = DataProvider<Contact, GetCustomerContactsRequest>('customers/contact', OfflineConfig.customerContacts)
const customerContactDetail = DataProvider<Contact, GetCustomerContactDetailRequest>(
  'customers/{customerId}/contacts/{contactId}',
  OfflineConfig.customerContactDetail
)
const customerDestinations = DataProvider<Destination, GetCustomerDestinationRequest>(
  'customers/{customerId}/destinations',
  OfflineConfig.customerDestinations
)
const customerDestinationDetail = DataProvider<Destination, GetCustomerDestinationDetailRequest>(
  'customers/{customerId}/destinations/{destinationId}',
  OfflineConfig.customerDestinationDetail
)
const customerConstructionSites = DataProvider<ConstructionSite, GetCustomerConstructionSitesRequest>(
  'customers/{customerId}/objects',
  OfflineConfig.customerConstructionSites
)
const customerConstructionSiteDetail = DataProvider<ConstructionSite, GetCustomerConstructionSiteDetailRequest>(
  'customers/{customerId}/objects/{objectId}',
  OfflineConfig.customerConstructionSiteDetail
)

// Objects (construction sites)
const constructionSites = DataProvider<ConstructionSite, GetConstructionSiteRequest>('objects', OfflineConfig.constructionSites)
const constructionSiteDetail = DataProvider<ConstructionSite, GetConstructionSiteDetailRequest>(
  'objects/{objectId}',
  OfflineConfig.constructionSiteDetail
)
const getConstructionSiteLottos = DataProvider<ConstructionSiteLotto, GetConstructionSiteLottosRequest>(
  'objects/{objectId}/lots',
  OfflineConfig.constructionSiteLottos
)
const constructionSiteLottos = DataProvider<ConstructionSiteLotto, GetConstructionSiteLottosRequest>(
  'objects/lots',
  OfflineConfig.constructionSiteLottos
)

const constructionSiteContacts = DataProvider<Contact, GetConstructionSiteContactsRequest>('objects/{objectId}/contacts')

// Activity
const activityTypes = DataProvider<ActivityType>('activityTypes', OfflineConfig.activityTypes)
const updateActivityTypeOrder = (request: ActivityTypeUpdateOrderRequest, abortController?: AbortController) =>
  client.PUT('activityTypes/ordernr', request, abortController)
const timeSlots = DataProvider<TimeSlot, GetTimeSlotRequest>('activityTimeSlots', OfflineConfig.timeSlots)
const contracts = DataProvider<Contract>('activityContracts', OfflineConfig.contracts)
const contractDetail = DataProvider<Contract, GetActivityContractDetailRequest>('activityContracts/{contractId}', OfflineConfig.contractDetail)
const contractLots = DataProvider<ContractObjectLot, GetActivityContractLotsRequest>('activityContracts/lots/{contractId}', OfflineConfig.contractLot)
const unavailableTypes = DataProvider<UnavailableType>('activityUnavailableTypes', OfflineConfig.unavailableType)
const unavailables = DataProvider<Unavailable, GetUnavailableRequest>('activityUnavailables')
const activityEmployee = DataProvider<ActivityEmployee, GetActivityEmployeeRequest>('activityEmployees', OfflineConfig.activityEmployees)
const activityEmployeePlanning = DataProvider<ActivityEmployee, GetActivityEmployeePlanningRequest>('activityEmployees/planning')
const activity = DataProvider<Activity, GetActivityRequest>('activities', OfflineConfig.activities)
const activityPlanning = DataProvider<Activity, GetActivityPlanningRequest>('activities/planning')
const activityDetail = DataProvider<Activity, GetActivityDetailRequest>('activities/detail', OfflineConfig.activityDetail)
const followUpActivity = DataProvider<Activity, GetFollowupActivityRequest>('activities/followup')
const activityNotes = DataProvider<ActivityNote, GetActivityNotesRequest, ActivityNotePostResult>('activityNotes', OfflineConfig.activityNotes)
const activityMedia = DataProvider<ActivityMedia, GetActivityMedia>('activityMedia', {
  id: ['srvActivityId', 'srvActivityNoteId', 'srvActivityTypeId', 'srvActivityYear', 'srvMediaId'],
  enableOffline: false,
})
const activityArticleMedia = DataProvider<ActivityArticleMedia, GetActivityArticleMedia>('activityMediaArticle', {
  id: ['srvActivityId', 'srvActivityArticleId', 'srvActivityTypeId', 'srvActivityYear', 'srvMediaId'],
  enableOffline: false,
})
const activityTimes = DataProvider<ActivityTime, GetActivityTimeRequest>('activityTime', OfflineConfig.activityTime)
const activityTravel = DataProvider<ActivityTravel, GetActivityTravelRequest>('activityTravel', OfflineConfig.activityTravel)
const activityArticles = DataProvider<ActivityArticle, GetActivityArticlesRequest>('activityArticles', OfflineConfig.activityArticle)
const articles = DataProvider<Article, GetArticleRequest>('articles', OfflineConfig.article)
const getHistoryArticles = DataProvider<Article, GetHistoryArticleRequest>('activityArticles/history')
const supplierArticles = DataProvider<SupplierArticle, GetSupplierArticleRequest>('articles/supplierArticles')
const articleImage = (articleId: string, taskId: number | undefined, baseUrl: string, token: string, abortController?: AbortController) => {
  let url = `${baseUrl}/api/articles/image/${articleId}`
  if (taskId) url += `/${taskId}`
  return fetch(url, {
    headers: { Authorization: token },
    signal: abortController?.signal,
  })
}
const articlePriceList = DataProvider<ArticlePriceList, GetArticlePriceList>('priceLists', OfflineConfig.articlePriceList)
const articlePriceListCatalogue = DataProvider<Article, GetPriceListArticle>('priceLists/catalogue', OfflineConfig.priceListArticle)
const activityRDA = DataProvider<ActivityRDA>('activities/rda')
// returns an email address if the customer of the activity requires a PDF to be sent
const activitySendPdfRequiredEmail = (request: ActivityId, abortController?: AbortController) =>
  client.GET<string>('activities/sendPdf', request, undefined, abortController)
const activitySendEmail = (request: ActivitySendEmailRequest, abortController?: AbortController) =>
  client.POST<SendEmailResponse>('activities/email/send', request, abortController)

// ERGO
const tasks = DataProvider<ErgoTask, GetErgoTasksRequest>('tasks', OfflineConfig.ergoTasks)
const taskDetail = DataProvider<ErgoTask, GetErgoTaskDetail>('tasks/detail/{taskId}', OfflineConfig.ergoTasksDetail)
const employees = DataProvider<Employee, GetEmployeeRequest>('employees', OfflineConfig.employee)
const mandants = DataProvider<Mandant>('mandants')
const timeAttendance = DataProvider<TimeAttendance>('timeAttendances')
const technicians = DataProvider<Technician, GetTechniciansRequest>('technicians', OfflineConfig.technicians)
const qualifications = DataProvider<Qualification>('technicians/qualification')
const ergoTitles = DataProvider<ErgoTitle>('ergoTables/title')
const corpPositions = DataProvider<CorpPosition>('ergoTables/corposition')
const mailingTypes = DataProvider<MailingType>('ergoTables/mailingList')
const languages = DataProvider<ErgoLanguage>('ergoTables/language')
const vehicles = DataProvider<Vehicle, GetVehiclesRequest>('vehicles')
const userVehicle = DataProvider<Vehicle>('vehicles/webuser')
const countries = DataProvider<Country>('countries')

// activity closing
const activityClosing = DataProvider<ActivityClosingRequest>('activities/closing')
const activityClosingSummary = DataProvider<ActivityClosingSummary, ActivityClosingSummaryRequest>('activities/closing/summary')

// email
const emailSettings = DataProvider<EmailSettings, GetEmailSettingsRequest>('emailSettings')
const emailModels = DataProvider<EmailModel, GetEmailModelsRequest>('emails')
const sendEmail = (request: SendEmailRequest, abortController?: AbortController) =>
  client.POST<SendEmailResponse>('sendEmail', request, abortController)

export const api = {
  userSettings: { get: userSettings.GET_FIRST },
  menuItems: { get: menuItems.GET_FIRST },
  mandants: { get: mandants.GET },
  tasks: { getList: tasks.GET, getDetail: taskDetail.GET_FIRST },
  ergoTitles: { getList: ergoTitles.GET },
  corpPositions: { getList: corpPositions.GET },
  countries: { getList: countries.GET },
  mailingTypes: { getList: mailingTypes.GET },
  languages: { getList: languages.GET },
  email: {
    settings: { get: emailSettings.GET, post: emailSettings.POST, put: emailSettings.PUT },
    models: {
      getList: emailModels.GET,
      post: emailModels.POST,
      put: emailModels.PUT,
      delete: emailModels.DELETE,
    },
    send: sendEmail,
  },
  customers: {
    getList: customers.GET,
    getDetail: customerDetail.GET_FIRST,
    post: postCustomer,
    contacts: {
      getList: customerContacts.GET,
      getDetail: customerContactDetail.GET_FIRST,
      post: customerContact.POST,
    },
    destinations: { getList: customerDestinations.GET, getDetail: customerDestinationDetail.GET_FIRST, post: customerDestinations.POST },
    constructionSites: { getList: customerConstructionSites.GET, getDetail: customerConstructionSiteDetail.GET_FIRST },
  },
  vehicles: { getList: vehicles.GET, getUserVehicle: userVehicle.GET_FIRST },
  activities: {
    types: {
      getList: activityTypes.GET,
      post: activityTypes.POST,
      put: activityTypes.PUT,
      delete: activityTypes.DELETE,
      updateOrder: updateActivityTypeOrder,
    },
    timeSlots: { getList: timeSlots.GET, post: timeSlots.POST, put: timeSlots.PUT, delete: timeSlots.DELETE },
    contracts: {
      getList: contracts.GET,
      post: contracts.POST,
      put: contracts.PUT,
      delete: contracts.DELETE,
      getDetail: contractDetail.GET_FIRST,
      lots: {
        getList: contractLots.GET,
        post: contractLots.POST,
        put: contractLots.PUT,
        delete: contractLots.DELETE,
      },
    },
    unavailables: {
      getList: unavailables.GET,
      post: unavailables.POST,
      put: unavailables.PUT,
      delete: unavailables.DELETE,
      types: { getList: unavailableTypes.GET, post: unavailableTypes.POST, put: unavailableTypes.PUT, delete: unavailableTypes.DELETE },
    },
    employees: {
      getList: activityEmployee.GET,
      post: activityEmployee.POST,
      put: activityEmployee.PUT,
      delete: activityEmployee.DELETE,
      getPlanned: activityEmployeePlanning.GET,
    },
    notes: { getList: activityNotes.GET, post: activityNotes.POST, put: activityNotes.PUT, delete: activityNotes.DELETE },
    media: { get: activityMedia.GET, post: activityMedia.POST, put: activityMedia.PUT, delete: activityMedia.DELETE },
    times: { getList: activityTimes.GET, post: activityTimes.POST, put: activityTimes.PUT, delete: activityTimes.DELETE },
    travels: { getList: activityTravel.GET, post: activityTravel.POST, put: activityTravel.PUT, delete: activityTravel.DELETE },
    articles: {
      getList: activityArticles.GET,
      post: activityArticles.POST,
      put: activityArticles.PUT,
      delete: activityArticles.DELETE,
      media: { get: activityArticleMedia.GET, post: activityArticleMedia.POST, put: activityArticleMedia.PUT, delete: activityArticleMedia.DELETE },
    },
    rda: {
      create: activityRDA.POST,
    },
    email: {
      getSendPdfRequiredEmail: activitySendPdfRequiredEmail,
      send: activitySendEmail,
    },
    getList: activity.GET,
    post: activity.POST,
    getDetail: activityDetail.GET_FIRST,
    put: activity.PUT,
    delete: activity.DELETE,
    getPlanning: activityPlanning.GET,
    closing: activityClosing.POST,
    closingSummary: activityClosingSummary.GET_FIRST,
    getFollowup: followUpActivity.GET_FIRST,
  },
  constructionsSites: {
    getList: constructionSites.GET,
    getDetail: constructionSiteDetail.GET_FIRST,
    lotto: {
      getList: getConstructionSiteLottos.GET,
      post: constructionSiteLottos.POST,
      put: constructionSiteLottos.PUT,
      delete: constructionSiteLottos.DELETE,
    },
    contacts: { getList: constructionSiteContacts.GET },
  },
  employees: {
    getDetail: employees.GET_FIRST,
    getList: employees.GET,
  },
  articles: {
    getList: articles.GET,
    priceList: { get: articlePriceList.GET_FIRST, getArticles: articlePriceListCatalogue.GET },
    getHistory: getHistoryArticles.GET,
    getImage: articleImage,
    supplierArticles: { getList: supplierArticles.GET },
  },
  technicians: {
    getList: technicians.GET,
    getQualification: qualifications.GET,
  },
  timeAttendance: {
    getList: timeAttendance.GET,
  },
  reports: {
    getPreview: async (
      request: Pick<Activity, 'srvActivityId' | 'srvActivityYear' | 'srvActivityTypeId' | 'paidAmount' | 'totalAmount' | 'discount'>,
      baseUrl: string,
      token: string,
      abortSignal?: AbortSignal
    ) => {
      const url = `${baseUrl}/api/reports/file`
      const response = await fetch(url, {
        signal: abortSignal,
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          Authorization: token,
          'Content-type': 'application/json; charset=UTF-8',
        },
      })

      if (response.ok) return response.blob()

      console.error(response)
      let errorMessage = response.statusText || 'Failed to create PDF'
      try {
        const errorJson = (await response.json()) as unknown
        errorMessage = ExceptionUtils.exceptionToString(errorJson)
      } catch (_) {
        _
      }
      throw new Error(errorMessage)
    },
    get: async (request: ActivityId, baseUrl: string, token: string, abortSignal?: AbortSignal) => {
      const url = `${baseUrl}/api/reports/file?srvActivityId=${request.srvActivityId}&srvActivityTypeId=${request.srvActivityTypeId}&srvActivityYear=${request.srvActivityYear}`

      const response = await fetch(url, {
        signal: abortSignal,
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-type': 'application/json; charset=UTF-8',
        },
      })

      if (response.ok) return response.blob()

      console.error(response)
      let errorMessage = response.statusText || 'Failed to load PDF'
      try {
        const errorJson = (await response.json()) as unknown
        errorMessage = ExceptionUtils.exceptionToString(errorJson)
      } catch (_) {
        _
      }
      throw new Error(errorMessage)
    },
  },
}

export const apiDtoIds = {
  activity: activity.IDs,
  activityTypes: activityTypes.IDs,
  timeSlots: timeSlots.IDs,
  contracts: contracts.IDs,
  unavaiables: unavailables.IDs,
  unavailableTypes: unavailableTypes.IDs,
  customers: customers.IDs,
  contacts: customerContacts.IDs,
  destinations: customerDestinations.IDs,
  constructionsSites: constructionSites.IDs,
  constructionsSiteLottos: getConstructionSiteLottos.IDs,
  activityEmployee: activityEmployee.IDs,
  tasks: tasks.IDs,
  employees: employees.IDs,
  technicians: technicians.IDs,
  activityNotes: activityNotes.IDs,
  media: activityMedia.IDs,
  activityTimes: activityTimes.IDs,
  activityTravels: activityTravel.IDs,
  activityArticles: activityArticles.IDs,
  articles: articles.IDs,
  activityArticleMedia: activityArticleMedia.IDs,
  articlePriceLists: articlePriceList.IDs,
}
