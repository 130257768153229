import { IM, SpacingProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import MediaSkeleton from './MediaSkeleton'

interface Props {
  width?: number | string
  height?: number | string
  spacing?: SpacingProps
  color?: string
  style?: StyleProp<ViewStyle>
  mediaCount?: number
  align?: 'left' | 'right'
}

export default function NoteSkeleton({ spacing, color, style, mediaCount, align = 'left' }: Props) {
  if (align === 'right') {
    return (
      <IM.View spacing={spacing} style={[style, styles.rightView]}>
        <IM.SkeletonContainer height={74} width={200} backgroundColor={color}>
          <IM.Rect x="168" y="0" rx="16" ry="16" width={32} height={32} />
          <IM.Rect x="65" y="3" rx="5" ry="5" width={100} height={12} />
          <IM.Rect x="75" y="17" rx="5" ry="5" width={90} height={12} />
          <IM.Rect x="0" y="62" rx="5" ry="5" width={200} height={12} />
        </IM.SkeletonContainer>

        {!!mediaCount && <MediaSkeleton spacing={'top'} align="right" mediaCount={mediaCount} />}
      </IM.View>
    )
  }

  return (
    <IM.View spacing={spacing} style={[style]}>
      <IM.SkeletonContainer height={74} width={200} backgroundColor={color}>
        <IM.Rect x="0" y="0" rx="16" ry="16" width={32} height={32} />
        <IM.Rect x="35" y="3" rx="5" ry="5" width={100} height={12} />
        <IM.Rect x="35" y="17" rx="5" ry="5" width={90} height={12} />
        <IM.Rect x="0" y="62" rx="5" ry="5" width={200} height={12} />
      </IM.SkeletonContainer>

      {!!mediaCount && <MediaSkeleton spacing={'top'} align="right" mediaCount={mediaCount} />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  rightView: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
})
