import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ActivityClosingRequest } from '../../../apis/types/apiRequestTypes'
import GroupSpacer from '../../../components/GroupSpacer'
import Separator from '../../../components/Infominds/Separator'
import EmailInput from '../../../components/input/EmailInput'
import EmailModuleSelector from '../../../components/selectors/EmailModuleSelector'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import { ActivityClosingState } from '../../../types'

export type ClosingEmailViewProps = {
  state: Partial<ActivityClosingState>
  handleChange(data: Partial<ActivityClosingRequest>): void
  emailRequired?: boolean
  loadingEmailSuggestion?: boolean
}

export default function ClosingEmailView({ state, handleChange, emailRequired, loadingEmailSuggestion }: ClosingEmailViewProps) {
  const { i18n } = useLanguage()
  const { detail } = useActivityDetail()
  const { theme } = useTheme()

  if (!detail) return null

  return (
    <IM.View>
      <GroupSpacer />
      <Separator />
      {emailRequired && (
        <IM.View>
          <IM.TextWithIcon
            iconSize={20}
            iconColor={theme.info}
            icon={['fal', 'triangle-exclamation']}
            style={{ color: theme.info }}
            viewStyle={styles.emailRequiredTextIconView}>
            {i18n.t('EMAIL_REQUIRED_INFO')}
          </IM.TextWithIcon>
        </IM.View>
      )}
      <EmailInput
        title={i18n.t('SEND_MAIL')}
        placeholder={i18n.t('RECEIVER')}
        value={state.srvEmailAdresses}
        onChangeText={value => handleChange({ srvEmailAdresses: value })}
        loading={loadingEmailSuggestion}
        spacing={'top'}
        required={emailRequired}
      />
      <EmailModuleSelector
        value={state.srvEmailId}
        srvActivityTypeId={detail.srvActivityTypeId}
        onChange={value => handleChange({ srvEmailId: value?.srvEmailId })}
        editable
        spacing={'top'}
        required={emailRequired}
        autoSelect
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  emailRequiredTextIconView: {
    alignItems: 'center',
  },
})
