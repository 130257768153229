import React, { createContext, Dispatch, ReactNode, SetStateAction, useRef, useState } from 'react'
import { NativeScrollPoint } from 'react-native'

import useFormContextInputs, { FormContextInputsResult } from '../hooks/useFormContextInputs'

export type FormContextProps = {
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  contentOffset: React.MutableRefObject<NativeScrollPoint | null>
} & FormContextInputsResult

const FormContext = createContext<FormContextProps | undefined>(undefined)

export const FormProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState(false)
  const contentOffset = useRef<NativeScrollPoint | null>(null)

  const formInputs = useFormContextInputs()

  return <FormContext.Provider value={{ error, setError, contentOffset, ...formInputs }}>{children}</FormContext.Provider>
}

export default FormContext
