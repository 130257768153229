import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import { api } from '../../../apis/apiCalls'
import { ActivityType, EmailModel } from '../../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../../components/ApiDeleteButton'
import Button from '../../../components/Button'
import GroupSpacer from '../../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../../components/Infominds/hooks/useEditDataHandler'
import BooleanInput from '../../../components/input/BooleanInput'
import MultiLangTextInput from '../../../components/input/MultiLangTextInput'
import { TextInputProps } from '../../../components/input/TextInput'
import ScrollViewForm from '../../../components/ScrollViewForm'
import ActivityTypeSelector from '../../../components/selectors/ActivityTypeSelector'
import MultiActivityTypeSelector from '../../../components/selectors/MultiActivityTypeSelector'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { AppStyle } from '../../../constants/Styles'
import useEmailModels from '../../../hooks/activity/useEmailModels'
import { EditOrCreateViewRef, UploadStatus } from '../../../types'

type Props = {
  emailModel?: EmailModel
  setIsDuplicating?: (isDuplicating: boolean) => void
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

const requiredFields: EditDataHandlerRequiredFields<EmailModel> = ['srvActivityTypeId', ['subject_De', 'subject_En', 'subject_It']]
function EmailModelCreateOrEditView({ emailModel, setIsDuplicating, onDone, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))

  const { emailModels: otherEmailModels } = useEmailModels()
  function isNewDefault(srvActivityTypeId: number | undefined) {
    return !!srvActivityTypeId && !otherEmailModels?.some(q => q.srvActivityTypeId === srvActivityTypeId && q.isDefault && !q.obsolete)
  }

  const [selectedActivityTypes, setSelectedActivityTypes] = useState<ActivityType[] | undefined>(undefined)

  const editable = useMemo(() => !emailModel || !emailModel.obsolete, [emailModel])

  const { state, editMode, createOrUpdate, handleDataChange } = useEditDataHandler<EmailModel>(handleCreate, api.email.models.put, undefined, {
    eventKeyCreation: EVENT_KEYS.UPDATED_EMAIL_MODELS,
    eventKeyModification: EVENT_KEYS.UPDATED_EMAIL_MODELS,
    onDone: onDone,
    onUploadStatus: onUploadStatus,
    editMode: !!emailModel,
    initialValue: emailModel,
    showErrorAlert: true,
    requiredFields,
  })

  async function handleCreate(data: Partial<EmailModel>, abortController?: AbortController) {
    if (!selectedActivityTypes?.length) return
    for (const type of selectedActivityTypes) {
      await api.email.models.post({ ...data, srvActivityTypeId: type.srvActivityTypeId }, abortController)
    }
  }

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: editable,
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      {editMode && !editable && (
        <IM.View style={AppStyle.center}>
          <IM.TextWithIcon
            iconSize={20}
            iconColor={theme.info}
            icon={['fal', 'triangle-exclamation']}
            style={{ color: theme.info }}
            viewStyle={styles.infoText}>
            {i18n.t('OBSOLETE_MODELS_NOT_EDITABLE')}
          </IM.TextWithIcon>
        </IM.View>
      )}
      {!editMode && (
        <MultiActivityTypeSelector
          required
          values={selectedActivityTypes?.map(q => q.srvActivityTypeId)}
          onChange={value => {
            setSelectedActivityTypes(value)
            // set any id to fulfill the required fields
            handleDataChange({ srvActivityTypeId: value?.at(0)?.srvActivityTypeId, isDefault: isNewDefault(value?.at(0)?.srvActivityTypeId) })
          }}
          {...commonProps}
        />
      )}
      {editMode && (
        <ActivityTypeSelector
          required
          initialValue={emailModel?.srvActivityTypeId}
          onChange={value => handleDataChange({ srvActivityTypeId: value?.srvActivityTypeId })}
          {...commonProps}
          editable={!editMode}
        />
      )}
      <MultiLangTextInput
        required
        title={i18n.t('SUBJECT')}
        placeholder={i18n.t('SUBJECT')}
        values={{ de: state?.subject_De, en: state?.subject_En, it: state?.subject_It }}
        onChangeText={values => handleDataChange({ subject_En: values.en, subject_De: values.de, subject_It: values.it })}
        multiline
        maxHeight={200}
        {...commonProps}
      />

      <MultiLangTextInput
        title={i18n.t('EMAIL_BODY')}
        placeholder={i18n.t('EMAIL_BODY')}
        values={{ de: state?.text_De, en: state?.text_En, it: state?.text_It }}
        onChangeText={values => handleDataChange({ text_En: values.en, text_De: values.de, text_It: values.it })}
        multiline
        maxHeight={200}
        {...commonProps}
      />

      <BooleanInput
        title={i18n.t('USE_AS_DEFAULT_MODEL')}
        value={!!state?.isDefault}
        onValueChange={value => handleDataChange({ isDefault: value })}
        disabled={!editable}
        {...commonProps}
      />
      {editMode && (
        <BooleanInput
          title={i18n.t('OBSOLETE')}
          value={!!state?.obsolete}
          onValueChange={value => handleDataChange({ obsolete: value })}
          disabled={!editable}
          {...commonProps}
        />
      )}

      {editMode && !!emailModel && (
        <>
          <GroupSpacer />
          {!!setIsDuplicating && (
            <Button
              icon={['fal', 'copy']}
              title={i18n.t('DUPLICATE')}
              onPress={() => {
                handleDataChange({
                  obsolete: false,
                  srvEmailId: undefined,
                  srvActivityTypeId: undefined,
                  isDefault: isNewDefault(state?.srvActivityTypeId),
                })
                setIsDuplicating(true)
              }}
            />
          )}
          <ApiDeleteButton
            onDeleted={onDone}
            data={emailModel}
            deleteAlertMessage={i18n.t('DELETE_EMAIL_MODEL_ALERT')}
            deleteAlertTitle={i18n.t('DELETE')}
            deleteRequest={api.email.models.delete}
            eventKey={EVENT_KEYS.UPDATED_EMAIL_MODELS}
            {...commonProps}
          />
        </>
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(EmailModelCreateOrEditView)

const styles = StyleSheet.create({
  infoText: {
    alignItems: 'center',
  },
})
