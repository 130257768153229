import { IM, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ActivityClosingSummary, Employee } from '../../../apis/types/apiResponseTypes'
import CollapsablePriceList, { CollapsablePriceListData } from '../../../components/CollapsablePriceList'
import LoadingStateView from '../../../components/LoadingStateView'
import ThreeLinesSkeleton from '../../../components/skeleton/ThreeLinesSkeleton'
import { LoadingType } from '../../../types'
import { EmployeeUtils } from '../../../utils/EmployeeUtils'

type ClosingSummaryViewProps = {
  summary: ActivityClosingSummary | undefined | null
  loading: LoadingType
  mode?: 'full' | 'total-only' | 'hide-prices'
  style?: StyleProp<ViewStyle>
  error?: string
}

export default function ClosingSummaryView({ summary, loading, mode, style, error }: ClosingSummaryViewProps) {
  const { i18n } = useLanguage()
  const articleData = useMemo<CollapsablePriceListData[]>(
    () =>
      summary?.articleSummary?.map(q => ({ name: q.title ?? q.articleId ?? '', value: q.totalNetPrice ?? 0, notToInvoice: q.notToInvoice })) ?? [],
    [summary]
  )
  const timeData = useMemo<CollapsablePriceListData[]>(
    () =>
      summary?.timeSummary?.reduce<CollapsablePriceListData[]>((result, entry) => {
        const name = entry.title ?? EmployeeUtils.getName(entry as Employee)
        const foundEntry = result.find(q => name === q.name && entry.notToInvoice === q.notToInvoice)
        if (foundEntry) {
          foundEntry.value += entry.totalNetPrice ?? 0
          return result
        }

        result.push({ name, value: entry.totalNetPrice ?? 0, notToInvoice: entry.notToInvoice })

        return result
      }, []) ?? [],
    [summary]
  )

  const travelData = useMemo<CollapsablePriceListData[]>(
    () =>
      summary?.travelSummary?.reduce<CollapsablePriceListData[]>((result, entry) => {
        const foundEntry = result.find(q => entry.articleId === q.name && entry.notToInvoice === q.notToInvoice)
        if (foundEntry) {
          foundEntry.value += entry.totalNetPrice ?? 0
          return result
        }

        result.push({ name: entry.articleId ?? '', value: entry.totalNetPrice ?? 0, notToInvoice: entry.notToInvoice })

        return result
      }, []) ?? [],
    [summary]
  )

  return (
    <IM.View style={style}>
      <LoadingStateView loading={loading} spacing={'all'} message={error}>
        <ThreeLinesSkeleton spacing={'all'} />
      </LoadingStateView>

      {!loading && (
        <>
          <IM.View spacing={'vertical'}>
            <CollapsablePriceList
              data={articleData}
              title={i18n.t('MATERIAL_USED')}
              total={summary?.totalArticle}
              disabled={mode === 'total-only'}
              hidePrices={mode === 'hide-prices'}
            />
            <CollapsablePriceList
              data={timeData}
              title={i18n.t('WORK')}
              total={summary?.totalTime}
              disabled={mode === 'total-only'}
              hidePrices={mode === 'hide-prices'}
            />
            <CollapsablePriceList
              data={travelData}
              title={i18n.t('EXPENSES')}
              total={summary?.totalTravel}
              disabled={mode === 'total-only'}
              hidePrices={mode === 'hide-prices'}
            />
          </IM.View>
        </>
      )}
    </IM.View>
  )
}
