import { IM, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'

import { api, apiDtoIds } from '../../../apis/apiCalls'
import { Activity, ActivityArticle } from '../../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../../components/ApiDeleteButton'
import GroupSpacer from '../../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../../components/Infominds/hooks/useEditDataHandler'
import DiscountInput from '../../../components/input/DiscountInput'
import NumberInput from '../../../components/input/NumberInput'
import PriceInput from '../../../components/input/PriceInput'
import TextInput, { TextInputProps } from '../../../components/input/TextInput'
import ScrollViewForm from '../../../components/ScrollViewForm'
import ActivityTypeSelector from '../../../components/selectors/ActivityTypeSelector'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { useArticleMedia } from '../../../contexts/activity/ArticleMediaContext'
import useObjectUtils from '../../../hooks/useObjectUtils'
import { EditOrCreateViewRef, UploadStatus } from '../../../types'
import { articleUtils } from '../../../utils/ArticleUtils'
import ArticleMediaView from './ArticleMediaView'

export type ActivityArticleEditViewProps = {
  activity: Activity
  article: ActivityArticle | undefined
  mediaArticleCode: string | undefined
  editable?: boolean
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

function ActivityMediaArticleCreateOrEditView(
  { activity, article, onDone, onUploadStatus, mediaArticleCode, editable }: ActivityArticleEditViewProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: () => createOrUpdate(),
  }))

  const { i18n } = useLanguage()
  const { newAssets } = useArticleMedia()
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)

  const requiredFields = useMemo<EditDataHandlerRequiredFields<ActivityArticle>>(() => ['articleId', 'quantity', 'title'], [])
  const { state, createOrUpdate, handleDataChange } = useEditDataHandler<ActivityArticle & { mediaHash?: string }>(
    (request, abortController) => articleUtils.postMediaArticle({ request, assets: newAssets }, abortController),
    (request, abortController) => articleUtils.updateMediaArticle({ request, assets: newAssets }, abortController),
    undefined,
    {
      eventKeyCreation: EVENT_KEYS.ACTIVITY_ARTICLES_UPDATED,
      eventKeyModification: EVENT_KEYS.ACTIVITY_ARTICLES_UPDATED,
      onDone: onDone,
      onUploadStatus: onUploadStatus,
      editMode: !!article,
      initialValue: article ?? {
        articleId: mediaArticleCode,
        quantity: 1,
        activityArticlervActivityTypeId: activity.srvActivityTypeId,
        ...activityObjectUtils.createRequestObject(activity),
      },
      showErrorAlert: true,
      requiredFields,
      validate: data => (!data.quantity || data.quantity <= 0 ? ['quantity'] : []),
      modifyDataBeforeRequest: data => ({
        ...data,
        measureUnitId: data.measureUnitId ?? data.measureUnit?.measureUnitId,
        determinePrice: true,
        netPrice: articleUtils.calcNetPriceMediaArticle(data) * (data.quantity ?? 0),
      }),
    }
  )

  useEffect(() => {
    const mediaHash = newAssets
      .map(a => a.id)
      .sort()
      .join('|')
    handleDataChange({ mediaHash })
  }, [newAssets])

  const prices = useMemo(() => articleUtils.calcPrice(state, true), [state])
  const totalVatRate = useMemo(() => (prices.vatRate ? `+${prices.vatRate}% ${i18n.t('VAT')} (${prices.vat}€)` : undefined), [prices])

  const total = useMemo(() => {
    const t = articleUtils.calcNetPriceMediaArticle(state)
    if (!t) return 0
    return t * (state?.quantity ?? 0)
  }, [state])

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
    editable: editable,
  }
  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <ActivityTypeSelector
        initialValue={state?.activityArticlervActivityTypeId}
        onChange={value => handleDataChange({ activityArticlervActivityTypeId: value?.srvActivityTypeId })}
        required
        {...commonProps}
      />
      <TextInput
        required
        title={i18n.t('TITLE')}
        value={state?.title}
        multiline
        onChangeText={value => handleDataChange({ title: value })}
        {...commonProps}
      />
      <TextInput
        title={i18n.t('DESCRIPTION')}
        value={state?.description}
        multiline
        onChangeText={value => handleDataChange({ description: value })}
        {...commonProps}
      />
      <NumberInput
        title={i18n.t('QUANTITY')}
        required
        value={state?.quantity}
        onValueChanged={value => handleDataChange({ quantity: value })}
        min={0}
        unit={article?.measureUnit?.description}
        {...commonProps}
      />

      <PriceInput
        title={i18n.t('PURCHASING_PRICE')}
        value={state?.buyPrice}
        onChangeValue={value => handleDataChange({ buyPrice: value })}
        selectTextOnFocus
        {...commonProps}
      />
      <PriceInput
        title={i18n.t('SALES_PRICE')}
        value={state?.unitPrice}
        onChangeValue={value => handleDataChange({ unitPrice: value })}
        selectTextOnFocus
        {...commonProps}
      />

      <DiscountInput title={i18n.t('DISCOUNT')} state={state} onChange={handleDataChange} {...commonProps} />

      <PriceInput title={i18n.t('TOTAL')} details={totalVatRate} value={total} {...commonProps} editable={false} />
      <GroupSpacer />
      <IM.View spacing={'bottom'}>
        <ArticleMediaView article={article} editable={editable} />
      </IM.View>

      {!!article && editable && (
        <ApiDeleteButton
          onDeleted={onDone}
          data={article}
          deleteAlertMessage={i18n.t('DELETE_ACTIVITY_ARTICLE_ALERT')}
          deleteAlertTitle={i18n.t('DELETE_ACTIVITY_ARTICLE')}
          deleteRequest={api.activities.articles.delete}
          eventKey={EVENT_KEYS.ACTIVITY_ARTICLES_UPDATED}
          {...commonProps}
        />
      )}
      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityMediaArticleCreateOrEditView)
