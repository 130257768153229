import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { GetConstructionSiteRequest, GetCustomerConstructionSitesRequest } from '../../apis/types/apiRequestTypes'
import { ConstructionSite } from '../../apis/types/apiResponseTypes'
import ConstructionSiteCard from '../../cards/constructionSites/ConstructionSiteCard'
import { ListSection } from '../../types'
import constructionSiteUtils from '../../utils/constructionSiteUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import { BaseTextInputProps } from '../input/baseTextInput/BaseTextInput'
import SelectInput from './selectInput/SelectInput'

type Props = {
  customerId?: number
  onChange: (value: ConstructionSite | undefined) => void
  value?: number
  disabledInfo?: string | false
} & Pick<BaseTextInputProps, 'required' | 'spacing' | 'editable' | 'style' | 'tabIndex'>

function requestConstructionSites(request: GetCustomerConstructionSitesRequest | GetConstructionSiteRequest | undefined) {
  if (request && 'customerId' in request && request.customerId) return api.customers.constructionSites.getList(request)
  return api.constructionsSites.getList(request)
}

export default function ConstructionSiteSelector({ onChange, required, customerId, value, ...props }: Props) {
  const { i18n } = useLanguage()

  const {
    item: data,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
    setItem,
  } = useInfiniteLoader(requestConstructionSites, {
    chuckSize: 30,
  })
  const {
    item: selected,
    loadItem: loadSelectedConstructionSite,
    setItem: setSelectedConstructionSite,
  } = useControlledLoader(api.constructionsSites.getDetail)

  useEffect(() => {
    refresh()
  }, [customerId])

  useEffect(() => {
    if (value && selected?.objectId !== value) loadSelectedConstructionSite({ objectId: value })
    if (!value) setSelectedConstructionSite(undefined)
  }, [value])

  const refresh = (searchText?: string) => {
    setItem([])
    loadItem({ customerId, searchtext: searchText })
  }

  const render = ({ item }: SectionListRenderItemInfo<ConstructionSite, ListSection<ConstructionSite>>, onPress?: () => void) => {
    return (
      <ConstructionSiteCard
        constructionSite={item}
        onPress={onPress}
        selected={selected?.objectId === item.objectId}
        spacing={['horizontal', 'bottom']}
        lite
        includeIdInTitle
      />
    )
  }

  const handleOnChange = (newValue: ConstructionSite | undefined) => {
    onChange(newValue)
    setSelectedConstructionSite(newValue)
  }

  return (
    <SelectInput
      id={apiDtoIds.constructionsSites}
      data={data}
      value={selected ?? undefined}
      loading={loading}
      refresh={refresh}
      onSearchChange={refresh}
      title={i18n.t('CONSTRUCTION_SITE')}
      screenTitle={i18n.t('CONSTRUCTION_SITES')}
      noDataMessage={i18n.t('NO_CONSTRUCTION_SITE_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => constructionSiteUtils.getTitle(item)}
      required={required}
      allDataLoaded={allDataLoaded}
      onLoadMore={loadMore}
      disableLoadAfterMount
      disableFastInput
      {...props}
    />
  )
}
