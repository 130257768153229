import { IM, useLanguage, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { RepetitionUtils } from './RepetitionUtils'
import SelectorBar, { SelectorBarValue } from './SelectorBar'

export type WeekDaySelectorProps = {
  selectedWeekDays: number[] | undefined
  setWeekDays: (weekDays: number[]) => void
  title?: string
  required?: boolean
} & Pick<ViewProps, 'spacing' | 'style'>

export default function WeekDaySelector({ selectedWeekDays, setWeekDays, title, required, ...viewProps }: WeekDaySelectorProps) {
  const { i18n, language } = useLanguage()
  const { theme } = useTheme()

  const error = useMemo(() => !selectedWeekDays?.length, [selectedWeekDays])

  const weekDays = useMemo(() => RepetitionUtils.getDays(language, 0, true), [])
  const selectorValues = useMemo(() => {
    const result = weekDays.map<SelectorBarValue<number>>((text, index) => ({ value: index, text }))
    const wd0 = result.shift() as SelectorBarValue<number>
    return [...result, wd0]
  }, [])

  return (
    <IM.View {...viewProps}>
      <SelectorBar
        title={title}
        values={selectorValues}
        selectedValues={selectedWeekDays}
        required={required}
        onValuePressed={value => {
          const wasSelected = selectedWeekDays?.find(q => q === value) !== undefined
          if (wasSelected) setWeekDays(selectedWeekDays?.filter(q => q !== value) ?? [])
          else setWeekDays([...(selectedWeekDays ?? []), value])
        }}
      />
      {error && (
        <IM.View spacing={'top'}>
          <IM.Text secondary style={{ color: theme.error }}>
            {i18n.t('REPETITION_INFO_NO_WEEKDAYS')}
          </IM.Text>
        </IM.View>
      )}
    </IM.View>
  )
}
