import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'

import { api } from '../../apis/apiCalls'
import { PostCustomerRequest } from '../../apis/types/apiRequestTypes'
import { Customer, CustomerKindOfEnterprise } from '../../apis/types/apiResponseTypes'
import GroupSpacer from '../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerOptions, EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import AddressInput from '../../components/input/AddressInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import CustomerTypeSelector from '../../components/selectors/CustomerTypeSelector'
import LanguageSelector from '../../components/selectors/LanguageSelector'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { EditOrCreateViewRef, UploadStatus } from '../../types'

type Props = {
  customer?: Customer
  taskId: number
  onUploadStatus: (status: UploadStatus) => void
  onDone: EditDataHandlerOptions<Customer, number>['onDone']
}

function CustomerCreateOrEditView({ customer, onDone, onUploadStatus, taskId }: Props, ref: ForwardedRef<EditOrCreateViewRef>) {
  const { i18n } = useLanguage()

  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))

  const initialValue = useMemo<PostCustomerRequest>(() => ({ ...customer, taskId }) as PostCustomerRequest, [customer, taskId])

  const requiredFields = useCallback((state: PostCustomerRequest | undefined) => {
    const result: EditDataHandlerRequiredFields<Customer> = ['company1', 'feTypeDestination', ['taxId', 'vatId']]
    if (state?.feTypeDestination === 'PA') result.push('feCodeDestination')
    return result
  }, [])

  const { state, createOrUpdate, handleDataChange } = useEditDataHandler<PostCustomerRequest, number>(api.customers.post, undefined, undefined, {
    eventKeyCreation: EVENT_KEYS.UPDATED_ACTIVITY_TYPES,
    eventKeyModification: EVENT_KEYS.UPDATED_ACTIVITY_TYPES,
    onDone: onDone,
    onUploadStatus: onUploadStatus,
    editMode: !!customer,
    initialValue: initialValue,
    showErrorAlert: true,
    requiredFields,
    modifyDataBeforeRequest: data => ({
      ...data,
      invoice: { feCodeDestination: data.feCodeDestination, feTypeDestination: data.feTypeDestination },
      kindOfEnterprise: CustomerKindOfEnterprise.koeJuridical,
    }),
  })

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <TextInput
        required
        value={state?.company1}
        title={i18n.t('TITLE_COMPANY1')}
        placeholder={i18n.t('TITLE_COMPANY1')}
        onChangeText={value => handleDataChange({ company1: value })}
        showClearTextIcon
        {...commonProps}
      />
      <TextInput
        value={state?.company2}
        title={i18n.t('TITLE_COMPANY2')}
        placeholder={i18n.t('TITLE_COMPANY2')}
        onChangeText={value => handleDataChange({ company2: value })}
        showClearTextIcon
        {...commonProps}
      />
      <GroupSpacer />
      <AddressInput address={state?.address} onValueChange={value => handleDataChange({ address: value })} {...commonProps} />
      <GroupSpacer />
      <TextInput
        title={i18n.t('VAT')}
        placeholder={i18n.t('VAT')}
        value={state?.vatId}
        onChangeText={value => handleDataChange({ vatId: value })}
        required={!!state?.vatId || !state?.taxId}
        {...commonProps}
      />
      <TextInput
        title={i18n.t('TAX_CODE')}
        placeholder={i18n.t('TAX_CODE')}
        value={state?.taxId}
        onChangeText={value => handleDataChange({ taxId: value })}
        required={!!state?.taxId || !state?.vatId}
        {...commonProps}
      />
      <GroupSpacer />
      <TextInput
        title={i18n.t('PHONE')}
        placeholder={i18n.t('PHONE')}
        value={state?.phone}
        onChangeText={value => handleDataChange({ phone: value })}
        inputMode="tel"
        {...commonProps}
      />
      <TextInput
        title={i18n.t('PHONE') + ' 2'}
        placeholder={i18n.t('PHONE')}
        value={state?.phone2}
        onChangeText={value => handleDataChange({ phone2: value })}
        inputMode="tel"
        {...commonProps}
      />
      <TextInput
        title={i18n.t('EMAIL')}
        placeholder={i18n.t('EMAIL')}
        value={state?.email}
        onChangeText={value => handleDataChange({ email: value })}
        inputMode="email"
        {...commonProps}
      />
      <GroupSpacer />
      <LanguageSelector value={state?.languageId} onChange={value => handleDataChange({ languageId: value?.languageId })} {...commonProps} />
      <CustomerTypeSelector
        required
        value={state?.feTypeDestination}
        onChange={value => handleDataChange({ feTypeDestination: value })}
        {...commonProps}
      />
      <TextInput
        required={state?.feTypeDestination === 'PA'}
        title={i18n.t('CUSTOMER_RECIPIENT_CODE')}
        placeholder={i18n.t('CUSTOMER_RECIPIENT_CODE')}
        value={state?.feCodeDestination}
        onChangeText={value => handleDataChange({ feCodeDestination: value })}
        maxLength={10}
        {...commonProps}
      />
      <GroupSpacer />
      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(CustomerCreateOrEditView)
