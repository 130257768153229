import { IMLayout, ModalController } from '@infominds/react-native-components'
import React, { createRef, useMemo, useState } from 'react'
import { Keyboard } from 'react-native'

import { ActivityArticle, ErgoTask } from '../../apis/types/apiResponseTypes'
import ArticleSelectionDetailHeader from '../../components/screen/headers/ArticleSelectionDetailHeader'
import { Screen } from '../../components/screen/Screen'
import ArticleSearchModeSelector from '../../components/selectors/ArticleSearchModeSelector'
import { AppStyle } from '../../constants/Styles'
import { ArticleSearchContext, ArticleSearchContextProvider } from '../../contexts/activity/ArticleSearchContext'
import useLayout from '../../hooks/useLayout'
import { ModalScreenRef, UploadStatus } from '../../types'
import ActivityArticleSelectionView from '../../views/activities/articles/ActivityArticleSelectionView'
import BaseServiceModal from '../BaseServiceModal'

export type ActivityArticleSelectionModalProps = {
  ergoTask?: ErgoTask | null
  selectedArticles?: ActivityArticle[]
}

type Props = {
  controller: ModalController<ActivityArticleSelectionModalProps>
  onSave: (articles: ActivityArticle[]) => void
}

export default function ActivityArticleSelectionModal({ controller, onSave }: Props) {
  const ref = createRef<ModalScreenRef>()
  const { isSmallDevice } = useLayout()
  const [status, setStatus] = useState<UploadStatus>('done')

  const hasCatalogue = useMemo(() => {
    if (!controller.isShown) return false
    return !!controller.data?.ergoTask?.paramList?.PRICELISTID && controller.data?.ergoTask.paramList.PRICELISTID !== '0'
  }, [controller.data, controller.isShown])

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ArticleSearchContextProvider
        ergoTask={controller.data?.ergoTask}
        initSelectedArticles={controller.data?.selectedArticles ?? []}
        forceLayout={'small'}
        setStatus={setStatus}
        onDone={() => null}>
        <ArticleSearchContext.Consumer>
          {articleSearch => (
            <Screen forceDetailOpen transparent>
              <Screen.Header
                isModal
                goBack={controller.close}
                onRightIconPress={() => {
                  Keyboard.dismiss()
                  controller.close()
                  onSave((articleSearch?.selectedArticles?.current ?? []) as ActivityArticle[])
                }}
                rightIcon={['fal', 'check']}
                disable={status === 'done' || status === 'mandatoryMissing'}
                loading={status === 'uploading'}>
                <ArticleSearchModeSelector style={[IMLayout.flex.f1, AppStyle.center]} hasCatalogue={hasCatalogue} showSearch={!isSmallDevice} />
              </Screen.Header>

              <Screen.DetailHeader>
                <ArticleSelectionDetailHeader hasCatalogue={hasCatalogue} />
              </Screen.DetailHeader>

              <Screen.Content>
                <ActivityArticleSelectionView />
              </Screen.Content>
            </Screen>
          )}
        </ArticleSearchContext.Consumer>
      </ArticleSearchContextProvider>
    </BaseServiceModal>
  )
}
