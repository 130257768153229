import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'

export default function SplitText({
  leftText,
  leftText2,
  rightText,
  style,
  primary,
  secondary,
  ...viewProps
}: { leftText: string; leftText2?: string; rightText: string; style?: StyleProp<TextStyle>; primary?: boolean; secondary?: boolean } & Pick<
  ViewProps,
  'spacing'
>) {
  return (
    <IM.View style={[IMLayout.flex.row, styles.splitView]} {...viewProps}>
      <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
        <IM.Text style={[style]} primary={primary} secondary={secondary}>
          {leftText}
        </IM.Text>
        {!!leftText2 && (
          <IM.Text style={[style]} secondary>
            {leftText2}
          </IM.Text>
        )}
      </IM.View>
      <IM.Text style={[style]} primary={primary} secondary={secondary}>
        {rightText}
      </IM.Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  splitView: {
    justifyContent: 'space-between',
  },
})
