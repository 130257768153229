import { IM, IMLayout, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { api, apiDtoIds } from '../../../apis/apiCalls'
import { Activity, ActivityArticle } from '../../../apis/types/apiResponseTypes'
import ActivityArticleRecord from '../../../components/activity/articles/ActivityArticleRecord'
import Button from '../../../components/Button'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import useRequest from '../../../components/Infominds/hooks/useRequest'
import LoadingStateView from '../../../components/LoadingStateView'
import ThreeLinesSkeleton from '../../../components/skeleton/ThreeLinesSkeleton'
import { AppStyle } from '../../../constants/Styles'
import useActivityArticles from '../../../hooks/activity/useActivityArticles'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import useIsRdaEnabled from '../../../hooks/useIsRdaEnabled'
import useLayout from '../../../hooks/useLayout'
import useObjectUtils from '../../../hooks/useObjectUtils'
import ActivityArticleEditModal from '../../../modals/activity/ActivityArticleEditModal'
import ActivityMediaArticleModal from '../../../modals/activity/ActivityMediaArticleModal'
import { ActivityDetailStackParamList } from '../../../navigation/types'
import { ActivityArticleEditScreenProps, ActivityMediaArticleScreenProps } from '../../../types'
import { LoadedCardProps } from '../../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData' | 'loading'> & { title?: string; dontLoadOnMount?: boolean; canCreateRda?: boolean }

const ActivityDetailArticleCard = memo(function ActivityDetailArticleCard({ enableChange, title, dontLoadOnMount, canCreateRda, ...props }: Props) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const articleObjectUtils = useObjectUtils<ActivityArticle>(apiDtoIds.activityArticles)
  const { detail, isHistorical, ergoTask } = useActivityDetail()
  const navigation = useNavigation<NavigationProp<ActivityDetailStackParamList>>()
  const articleEditModal = useModalController<ActivityArticleEditScreenProps>()
  const mediaArticleModal = useModalController<ActivityMediaArticleScreenProps>()
  const isRdaEnabled = useIsRdaEnabled(ergoTask)
  const rdaEnabled = !!canCreateRda && isRdaEnabled
  const { articles, loadingArticles, setArticles, refreshArticles } = useActivityArticles(detail, { dontLoadOnMount: true, setRda: rdaEnabled })
  const articleUtils = useObjectUtils<ActivityArticle>(apiDtoIds.activityArticles)
  const mediaArticleCode = ergoTask?.paramList?.DEFAULT_ARTICLEID
  const canAddMediaArticles = !!mediaArticleCode
  const rdaButtonEnabled = useMemo(() => !!articles?.some(q => q.isRda && !q.refer2SerialNr), [articles])

  useEffect(() => {
    if (dontLoadOnMount) return
    refreshArticles()
  }, [ergoTask])

  function handleArticleChanged(changedArticle: ActivityArticle | null, reload?: boolean) {
    if (changedArticle) setArticles(prev => prev?.map(q => (articleObjectUtils.compare(q, changedArticle) ? changedArticle : q)))
    if (reload) refreshArticles()
  }

  const { request: createRDA, loading: createRDABusy } = useRequest(api.activities.rda.create, {
    showErrorAlert: true,
    // onError reset the isRda flag
    onError: () => updateSelectedArticles({ articles: articles?.filter(a => !a.refer2DocId)?.map(a => ({ ...a, isRda: false })) ?? [] }),
    onSuccess: () => {
      refreshArticles()
    },
  })
  const { request: updateSelectedArticles, loading: updatingArticles } = useRequest(
    (requestArticles: { articles: ActivityArticle[] }, abortController: AbortController) =>
      Promise.all(requestArticles.articles?.map(a => api.activities.articles.put(a, abortController)) ?? []),
    {
      showErrorAlert: true,
      onError: () => refreshArticles(),
      onSuccess: (_, request) => {
        if (detail && request.articles.some(a => a.isRda && !a.refer2DocId)) createRDA(activityObjectUtils.createRequestObject(detail))
      },
    }
  )

  const rdaBusy = createRDABusy === 'reloading' || updatingArticles === 'reloading'
  function handleCreateRDA() {
    if (!detail) return
    updateSelectedArticles({ articles: articles?.filter(q => !q.refer2DocId) ?? [] })
  }

  return (
    <IM.Card head={<CardLeftTitle text={title ?? i18n.t('ARTICLES')} />} noContentSpacing {...props}>
      <IM.View spacing={'all'} style={styles.contentView}>
        <LoadingStateView loading={loadingArticles}>
          <ThreeLinesSkeleton />
        </LoadingStateView>
        {!articles?.length && !loadingArticles && (
          <IM.View spacing={'all'}>
            <IM.Text secondary>{i18n.t('NO_ARTICLES')}</IM.Text>
          </IM.View>
        )}

        {!!detail &&
          articles?.map((article, index) => (
            <ActivityArticleRecord
              key={`ActivityArticleRecord#${articleUtils.createId(article)}`}
              article={article}
              separator={!!index}
              enableChange={!rdaBusy}
              onChange={handleArticleChanged}
              canCreateRda={rdaEnabled}
              onEdit={articleToEdit => {
                if (!detail) return
                if (articleToEdit.isJolly) {
                  if (!mediaArticleCode) return
                  const data = {
                    srvActivityId: detail.srvActivityId,
                    srvActivityTypeId: detail.srvActivityTypeId,
                    srvActivityYear: detail.srvActivityYear,
                    taskId: detail.taskId,
                    mediaArticleCode,
                    article: articleToEdit,
                    editable: !isHistorical,
                  }
                  if (isSmallDevice) {
                    navigation.navigate('ActivityMediaArticleScreen', data)
                  } else {
                    mediaArticleModal.show(data)
                  }
                } else {
                  if (isSmallDevice) {
                    navigation.navigate('ActivityArticleEditScreen', { activity: detail, article: articleToEdit, editable: !isHistorical })
                  } else {
                    articleEditModal.show({ activity: detail, article: articleToEdit, editable: !isHistorical })
                  }
                }
              }}
            />
          ))}

        {enableChange && detail && (
          <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd, { gap: IMLayout.horizontalMargin }]} spacing={'top'}>
            <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd, { gap: IMLayout.horizontalMargin }]}>
              {rdaEnabled && (
                <Button title={i18n.t('CREATE_RDA')} onPress={handleCreateRDA} color={theme.primary} disabled={!rdaButtonEnabled} loading={rdaBusy} />
              )}
              <Button
                title={i18n.t('ADD')}
                onPress={() => {
                  navigation.navigate('ActivityArticleSelectionScreen', {
                    srvActivityId: detail.srvActivityId,
                    srvActivityTypeId: detail.srvActivityTypeId,
                    srvActivityYear: detail.srvActivityYear,
                    taskId: detail.taskId,
                  })
                }}
                color={theme.primary}
              />
            </IM.View>
            {canAddMediaArticles && (
              <Button
                title={i18n.t('ADD_MEDIA_ARTICLE')}
                onPress={() => {
                  const data = {
                    srvActivityId: detail.srvActivityId,
                    srvActivityTypeId: detail.srvActivityTypeId,
                    srvActivityYear: detail.srvActivityYear,
                    taskId: detail.taskId,
                    mediaArticleCode,
                  }
                  if (isSmallDevice) {
                    navigation.navigate('ActivityMediaArticleScreen', data)
                  } else {
                    mediaArticleModal.show(data)
                  }
                }}
                color={theme.primary}
                numberOfLines={1}
                ellipsizeMode="tail"
                containerStyle={[styles.addMediaArticleButton]}
              />
            )}
          </IM.View>
        )}
      </IM.View>
      <ActivityMediaArticleModal controller={mediaArticleModal} />
      <ActivityArticleEditModal controller={articleEditModal} />
    </IM.Card>
  )
})

export default ActivityDetailArticleCard

const styles = StyleSheet.create({
  addMediaArticleButton: {
    flexShrink: 1,
  },
  contentView: {
    minHeight: 50,
    flexShrink: 1,
  },
})
