import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import { Button, Col, Flex, Row } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react'
import Scheduler, { DATE_FORMAT, DnDSource, EventItem, Resource, SchedulerData, ViewType } from 'react-big-scheduler-stch'

import './style.scss'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useAuthentication } from '@infominds/react-native-license'
import { StyleSheet } from 'react-native'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { ActivityState, Qualification } from '../../apis/types/apiResponseTypes'
import { AppStyle } from '../../constants/Styles'
import { DnDType, GanttChartRef, GanttChartView, ThemeColorExpanded } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import { ganttChartDateLabelAtom, ganttChartViewAtom } from '../../utils/stateManager'
import { utils } from '../../utils/utils'
import PressableIcon from '../Infominds/PressableIcon'
import GanttChartResourceHeader from './GanttChartResourceHeader'

interface Props {
  schedulerData: SchedulerData
  events: EventItem[] | undefined
  resources: Resource[]
  qualifications: Qualification[]
  dndSource: DnDSource[]
  parentRef: React.RefObject<HTMLDivElement>
  onRefresh: (date: Date) => void
  onSelect: (slotId: string, slotName: string, start: string, end: string, type: string) => void
  onMove: (event: EventItem, slotId: string, slotName: string, start: string, end: string) => void
  onMoveStart: (event: EventItem, start: string) => void
  onMoveEnd: (event: EventItem, end: string) => void
  onRemove: (event: EventItem) => void
  onDuplicate: (event: EventItem) => void
  onDuplicateUnlinked: (event: EventItem) => void
  onEditPlan: (event: EventItem) => void
  onEventPress?: (id: number, typeId: number, activityYear: number, activityState: ActivityState) => void
}

const GanttChart = (
  {
    schedulerData,
    parentRef,
    dndSource,
    resources,
    events,
    qualifications,
    onEventPress,
    onSelect,
    onRefresh,
    onMove,
    onMoveEnd,
    onMoveStart,
    onRemove,
    onDuplicate,
    onDuplicateUnlinked,
  }: Props,
  ref: ForwardedRef<GanttChartRef>
) => {
  useImperativeHandle(ref, () => ({
    next: _onNext,
    previous: _onPrevious,
    pick: _onDatePick,
    remove: _onRemove,
    add: _onAdd,
    move: _onMove,
    updateStart: _onUpdateStart,
    updateEnd: _onUpdateEnd,
    updateResources: _updateResources,
  }))

  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { sessionKey } = useAuthentication()

  const [_, setId] = useState(utils.generateUuid())

  const setDateLabel = useSetRecoilState(ganttChartDateLabelAtom(sessionKey))
  const view = useRecoilValue(ganttChartViewAtom(sessionKey))

  useEffect(() => {
    if (!events) return

    schedulerData.setEvents(events)
    _update()
  }, [events])

  useEffect(() => {
    _onViewChange(view)
  }, [view])

  // #region Graph update functions
  const _onPrevious = () => {
    schedulerData.prev()
    setDateLabel({ label: schedulerData.getDateLabel(), date: new Date(schedulerData.startDate) })
    onRefresh(new Date(schedulerData.startDate))
  }

  const _onNext = () => {
    schedulerData.next()
    setDateLabel({ label: schedulerData.getDateLabel(), date: new Date(schedulerData.startDate) })
    onRefresh(new Date(schedulerData.startDate))
  }

  const _onDatePick = (date: Date) => {
    if (dayjs(date).isSame(schedulerData.startDate, view)) {
      return
    }

    schedulerData.setDate(dayjs(date).format(DATE_FORMAT))
    setDateLabel({ label: schedulerData.getDateLabel(), date: new Date(schedulerData.startDate) })
    onRefresh(new Date(schedulerData.startDate))
  }

  const _onViewChange = (newView: GanttChartView) => {
    schedulerData.setViewType(newView === 'day' ? ViewType.Day : newView === 'week' ? ViewType.Week : ViewType.Month, false, false)
    setDateLabel({ label: schedulerData.getDateLabel(), date: new Date(schedulerData.startDate) })
    onRefresh(new Date(schedulerData.startDate))
  }

  const _onAdd = (newEvent: EventItem | EventItem[]) => {
    if (Array.isArray(newEvent)) {
      newEvent.forEach(ev => schedulerData.addEvent(ev))
    } else {
      schedulerData.addEvent(newEvent)
    }

    _update()
  }

  const _onMove = (event: EventItem, newSlotId: string, newSlotName: string, newStart: string, newEnd: string) => {
    schedulerData.moveEvent(event, newSlotId, newSlotName, newStart, newEnd)
  }

  const _onUpdateStart = (event: EventItem | EventItem[], newStart: string) => {
    if (Array.isArray(event)) {
      event.forEach(ev => schedulerData.updateEventStart(ev, newStart))
    } else {
      schedulerData.updateEventStart(event, newStart)
    }
  }

  const _onUpdateEnd = (event: EventItem | EventItem[], newEnd: string) => {
    if (Array.isArray(event)) {
      event.forEach(ev => schedulerData.updateEventEnd(ev, newEnd))
    } else {
      schedulerData.updateEventEnd(event, newEnd)
    }
  }

  const _onRemove = (event: EventItem | EventItem[]) => {
    if (Array.isArray(event)) {
      event.forEach(ev => schedulerData.removeEvent(ev))
    } else {
      schedulerData.removeEvent(event)
    }
  }

  const _updateResources = (currentResource: Resource[]) => {
    const totalAdded = currentResource.filter(x => !schedulerData.resources.includes(x))
    const totalRemoved = schedulerData.resources.filter(x => !currentResource.includes(x))

    for (const added of totalAdded) {
      schedulerData.addResource(added, true)
    }

    for (const removed of totalRemoved) {
      schedulerData.removeResource(removed, true)
    }

    _update()
  }

  const _update = () => {
    setId(utils.generateUuid())
  }
  // #endregion

  // #region Render functions
  const eventItemTemplateResolver = (
    event: EventItem,
    bgColor: string,
    isStart: boolean,
    isEnd: boolean,
    mustAddCssClass: string,
    mustBeHeight: number,
    _agendaMaxEventWidth: number
  ) => {
    const closed = activityUtils.isActivityClosed({ state: event.state as ActivityState })
    const borderWidthStart = isStart ? '4px' : '0'
    const borderWidthEnd = isEnd ? '4px' : '0'
    const borderRadiusStart = isStart ? '4px' : '0'
    const borderRadiusEnd = isEnd ? '4px' : '0'
    let icon: IconProp = ['fal', 'check']
    let eventType = DnDType.ACTIVITY
    let subtitle = ''
    let borderColor = ''
    let inElaboration = false

    if (Object.prototype.hasOwnProperty.call(event, 'objectId') && Object.prototype.hasOwnProperty.call(event, 'srvContractId')) {
      // @ts-ignore already checked
      icon = activityUtils.getActivityIconByActivity({ objectId: event.objectId, srvContractId: event.srvContractId })
    }

    if (Object.prototype.hasOwnProperty.call(event, 'subtitle')) {
      // @ts-ignore already checked
      subtitle = event.subtitle
    }

    if (Object.prototype.hasOwnProperty.call(event, 'borderColor')) {
      // @ts-ignore already checked
      borderColor = event.borderColor
    }

    if (Object.prototype.hasOwnProperty.call(event, 'type')) {
      // @ts-ignore already checked
      eventType = event.type
    }

    if (Object.prototype.hasOwnProperty.call(event, 'inElaboration')) {
      // @ts-ignore already checked
      inElaboration = event.inElaboration
    }

    const textColor = closed ? 'white' : borderColor
    const sameDay = dayjs(event.start).isSame(dayjs(event.end), 'day')

    return (
      <div
        key={event.id}
        className={mustAddCssClass}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          background:
            eventType === DnDType.ACTIVITY
              ? undefined
              : `repeating-linear-gradient(-45deg, ${borderColor}, ${borderColor} 10px, ${bgColor} 10px, ${bgColor} 20px)`,
          backgroundColor: eventType === DnDType.ACTIVITY ? bgColor : undefined,
          height: mustBeHeight,
          display: 'grid',
          borderTopLeftRadius: borderRadiusStart,
          borderBottomLeftRadius: borderRadiusStart,
          borderTopRightRadius: borderRadiusEnd,
          borderBottomRightRadius: borderRadiusEnd,
        }}>
        <IM.View
          style={[
            IMLayout.flex.row,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              backgroundColor: bgColor,
              margin: eventType === DnDType.ABSENCE ? 4 : 0,
              marginLeft: 0,
              marginRight: 0,
            },
          ]}>
          <div
            style={{
              ...styles.leftGrip,
              ...{
                borderLeft: `${borderWidthStart} solid ${borderColor}`,
              },
            }}
          />
          {eventType === DnDType.ACTIVITY && (
            <>
              {inElaboration ? (
                <div style={{ height: mustBeHeight }}>
                  {/* eslint-disable-next-line react-native/no-inline-styles */}
                  <IM.Icon icon={icon} size={20} style={[styles.icon, AppStyle.center, { paddingTop: 9 }]} color={textColor} />
                  {inElaboration && (
                    <IM.Icon
                      icon={['fal', 'person-running-fast']}
                      size={16}
                      // eslint-disable-next-line react-native/no-inline-styles
                      style={[styles.icon, AppStyle.center, { paddingTop: 3 }]}
                      color={textColor}
                    />
                  )}
                </div>
              ) : (
                <IM.Icon icon={icon} size={20} style={[styles.icon, AppStyle.center, { height: mustBeHeight }]} color={textColor} />
              )}
            </>
          )}
          <div style={{ ...styles.textContainer, ...(eventType === DnDType.ACTIVITY ? { marginTop: 5 } : { marginTop: 1 }) }}>
            <div style={{ ...styles.text, ...{ whiteSpace: 'nowrap', fontSize: '14px', color: textColor } }}>
              <span>{event.title}</span>
            </div>
            <div
              style={{
                ...styles.text,
                ...{
                  whiteSpace: 'nowrap',
                  fontSize: '11px',
                  color: textColor,
                },
              }}>
              <span>{subtitle}</span>
            </div>
            <div
              style={{
                ...styles.text,
                ...{
                  whiteSpace: 'nowrap',
                  fontSize: '11px',
                  color: textColor,
                  fontStyle: 'italic',
                },
              }}>
              <span>
                {dayjs(event.start).format(sameDay ? 'LT' : 'D MMM YYYY LT')} - {dayjs(event.end).format(sameDay ? 'LT' : 'D MMM YYYY LT')}
              </span>
            </div>
          </div>
          <div
            style={{
              ...styles.rightGrip,
              ...{
                borderRight: `${borderWidthEnd} solid ${borderColor}`,
              },
            }}
          />
        </IM.View>
      </div>
    )
  }

  const eventItemPopoverTemplateResolver = (eventItem: EventItem, title: string, start: Dayjs, end: Dayjs, statusColor: string) => {
    const subtitle = eventItem.subtitle as string
    const closed = activityUtils.isActivityClosed({ state: eventItem.state as ActivityState })
    let eventType = DnDType.ACTIVITY
    let activityId = 0
    let activityTypeId = 0
    let activityYearId = 0

    if (Object.prototype.hasOwnProperty.call(eventItem, 'type')) {
      // @ts-ignore already checked
      eventType = eventItem.type
    }

    if (Object.prototype.hasOwnProperty.call(eventItem, 'srvActivityId')) {
      // @ts-ignore already checked
      activityId = eventItem.srvActivityId
    }

    if (Object.prototype.hasOwnProperty.call(eventItem, 'srvActivityTypeId')) {
      // @ts-ignore already checked
      activityTypeId = eventItem.srvActivityTypeId
    }

    if (Object.prototype.hasOwnProperty.call(eventItem, 'srvActivityYear')) {
      // @ts-ignore already checked
      activityYearId = eventItem.srvActivityYear
    }

    return (
      // eslint-disable-next-line react-native/no-inline-styles
      <div style={{ width: '300px' }}>
        <Row align="middle">
          <Col span={21}>
            <Row align="middle">
              <Col span={2}>
                <div className="status-dot" style={{ backgroundColor: statusColor }} />
              </Col>
              <Col span={22} className="overflow-text">
                <span className="header2-text" title={title} style={{ color: theme.text }}>
                  {title}
                </span>
              </Col>

              {subtitle !== '' && (
                <>
                  <Col span={2}>
                    <div className="status-dot" />
                  </Col>
                  {/* eslint-disable-next-line react-native/no-inline-styles */}
                  <Col span={22} className="overflow-text" style={{ marginTop: -6 }}>
                    <span title={subtitle} style={{ color: theme.textDetail }}>
                      {subtitle}
                    </span>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          {!closed && (
            <Col span={3}>
              <PressableIcon icon={['fas', 'trash']} color={theme.general.error} size={19} onPress={() => onRemove(eventItem)} />
            </Col>
          )}
        </Row>

        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <div style={{ margin: 3 }} />
        <Row align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header1-text" style={{ color: theme.text }}>
              {dayjs(start.toISOString()).format('D MMM YYYY LT')} - {dayjs(end.toISOString()).format('D MMM YYYY LT')}
            </span>
          </Col>
        </Row>
        <>
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <div style={{ marginTop: 6 }} />
          <Row align="middle">
            <Col span={2}>
              <div />
            </Col>
            <Col span={22}>
              <Flex wrap="wrap" gap="small">
                {!closed && (
                  <Button onClick={() => onDuplicate(eventItem)} style={styles.button}>
                    {i18n.t('DUPLICATE')}
                  </Button>
                )}
                {!closed && eventType === DnDType.ACTIVITY && (
                  <Button onClick={() => onDuplicateUnlinked(eventItem)} style={styles.button}>
                    {i18n.t('DUPLICATE_UNLINKED')}
                  </Button>
                )}
                {eventType === DnDType.ACTIVITY && (
                  <Button
                    onClick={() => onEventPress?.(activityId, activityTypeId, activityYearId, eventItem.state as ActivityState)}
                    style={styles.button}>
                    {i18n.t('DETAIL')}
                  </Button>
                )}
              </Flex>
            </Col>
          </Row>
        </>
      </div>
    )
  }

  const customerResourceHeader = (resourceName: string) => {
    if (resources.length === 0) return undefined

    return <GanttChartResourceHeader resourceName={resourceName} resources={resources} qualifications={qualifications} />
  }
  // #endregion

  return (
    <Scheduler
      key={colorScheme}
      parentRef={parentRef}
      schedulerData={schedulerData}
      nextClick={_onNext}
      prevClick={_onPrevious}
      moveEvent={(_data, event, slotId, slotName, start, end) => {
        onMove(event, slotId, slotName, start, end)
      }}
      newEvent={(_data, slotId, slotName, start, end, type) => {
        onSelect(slotId, slotName, start, end, type)
      }}
      updateEventStart={(_data, event, newStart) => onMoveStart(event, newStart)}
      updateEventEnd={(_data, event, newEnd) => onMoveEnd(event, newEnd)}
      eventItemTemplateResolver={(_data, ...props) => eventItemTemplateResolver(...props)}
      onSelectDate={() => {
        return
      }}
      onViewChange={() => {
        return
      }}
      dndSources={dndSource}
      eventItemPopoverTemplateResolver={(_date, event, title, start, end, statusColor) =>
        eventItemPopoverTemplateResolver(event, title, start, end, statusColor)
      }
      customerResourceHeader={customerResourceHeader}
    />
  )
}

export default memo(forwardRef(GanttChart))

const styles = StyleSheet.create({
  leftGrip: { marginLeft: 3, marginRight: 4, marginTop: 3, marginBottom: 3, borderRadius: 6 },
  rightGrip: { marginLeft: 4, marginRight: 3, marginTop: 3, marginBottom: 3, borderRadius: 6 },
  icon: { marginRight: 4 },
  textContainer: { flexDirection: 'row', overflow: 'hidden', width: '100%' },
  //@ts-ignore: works on web
  text: { overflow: 'hidden', textOverflow: 'ellipsis', textAlignVertical: 'middle' },
  button: { backgroundColor: 'transparent' },
})
