import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { ActivityInElaborationFilter } from '../../apis/types/apiRequestTypes'
import { RdaStatus } from '../../apis/types/apiResponseTypes'
import DateInput from '../../components/input/DateInput'
import ActivityTypeSelector from '../../components/selectors/ActivityTypeSelector'
import ConstructionSiteLottoSelector from '../../components/selectors/ConstructionSiteLottoSelector'
import ConstructionSiteSelector from '../../components/selectors/ConstructionSiteSelector'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import InElaborationSelector from '../../components/selectors/InElaborationSelector'
import MultiEmployeeSelector from '../../components/selectors/MultiEmployeeSelector'
import RdaStatusSelector from '../../components/selectors/RdaStatusSelector'
import { FormProvider } from '../../contexts/FormContext'
import useFilter from '../../hooks/useFilter'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ApiFilterDataSorter, ApiFilterType } from '../../types'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import TimeUtils from '../../utils/TimeUtils'
import { FilterViewProps } from './FilterView'

export default function ApiFilterView<T = object>({ disabledFilters }: FilterViewProps<T>) {
  const { apiFilter, changeApiFilter } = useFilter()
  const { language, i18n } = useLanguage()
  const { languageSelect } = useLanguageSelect()

  const groupedFilter = useMemo(
    () =>
      apiFilter
        .filter(q => q.visible)
        .reduce<{ group: string; filter: ApiFilterDataSorter<void>[] }[]>((result, filter) => {
          // if (disabledFilters?.includes(filter.dataKey)) return result
          const group = result.find(r => r.group === filter.group)
          if (group) {
            group.filter.push(filter)
          } else {
            result.push({ group: filter.group ?? filter.id, filter: [filter] })
          }
          return result
        }, []),
    [apiFilter]
  )

  function parseValue(filter: ApiFilterDataSorter<void> | undefined) {
    if (!filter || filter.value === undefined || filter.value === null || !filter.active) return undefined
    if (typeof filter.value === 'string') return Number.parseInt(filter.value, 10)
    if (typeof filter.value === 'boolean') return undefined
    return filter.value
  }

  function parseValues(filter: ApiFilterDataSorter<void> | undefined) {
    if (!filter || filter.values === undefined || filter.values === null || !filter.active) return undefined
    return filter.values.map(Number)
  }

  const selectedCustomer = useMemo(() => {
    const foundCustomer = apiFilter.find(f => f.active && f.type === ApiFilterType.Customer)
    return parseValue(foundCustomer)
  }, [apiFilter])

  const selectedConstructionSite = useMemo(() => {
    const foundObject = apiFilter.find(f => f.active && f.type === ApiFilterType.ConstructionSite)
    return parseValue(foundObject)
  }, [apiFilter])

  function onCustomerChanged() {
    const constructionSiteFilter = apiFilter.find(f => f.type === ApiFilterType.ConstructionSite)
    const lottoFilter = apiFilter.find(f => f.type === ApiFilterType.Lotto)
    if (constructionSiteFilter) changeApiFilter(constructionSiteFilter.id)
    if (lottoFilter) changeApiFilter(lottoFilter.id)
  }

  function onConstructionSiteChanged() {
    const lottoFilter = apiFilter.find(f => f.type === ApiFilterType.Lotto)
    if (lottoFilter) changeApiFilter(lottoFilter.id)
  }

  function isFilterEditable(id: keyof T) {
    return !disabledFilters?.includes(id)
  }

  return (
    <FormProvider>
      <IM.View>
        {groupedFilter.map(filterGroup => (
          <IM.View key={filterGroup.group} style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin * 2 }]}>
            {filterGroup.filter.map(filter => (
              <IM.View key={filter.id} style={[IMLayout.flex.f1]}>
                {filter.type === ApiFilterType.ActivityType && (
                  <ActivityTypeSelector
                    editable={isFilterEditable(filter.dataKey)}
                    id={parseValue(filter)}
                    onChange={c => {
                      changeApiFilter(
                        filter.id,
                        c?.srvActivityTypeId,
                        languageSelect({ de: c?.descriptionDe, en: c?.descriptionEn, it: c?.descriptionIt })
                      )
                      onCustomerChanged()
                    }}
                    spacing={'bottom'}
                  />
                )}
                {filter.type === ApiFilterType.Date && (
                  <DateInput
                    editable={isFilterEditable(filter.dataKey)}
                    value={filter.active ? filter.value?.toString() : undefined}
                    title={filter.textKey ? i18n.t(filter.textKey) : i18n.t('DATE')}
                    onChangeDate={date => changeApiFilter(filter.id, date?.toISOString(), date ? TimeUtils.format(date, language) : '')}
                    spacing={'bottom'}
                  />
                )}
                {filter.type === ApiFilterType.Customer && (
                  <CustomerSelector
                    editable={isFilterEditable(filter.dataKey)}
                    value={parseValue(filter)}
                    onChange={c => {
                      changeApiFilter(filter.id, c?.companyId, c?.company1)
                      onCustomerChanged()
                    }}
                    spacing={'bottom'}
                  />
                )}
                {filter.type === ApiFilterType.ConstructionSite && (
                  <ConstructionSiteSelector
                    editable={isFilterEditable(filter.dataKey)}
                    customerId={selectedCustomer}
                    value={parseValue(filter)}
                    onChange={c => {
                      changeApiFilter(filter.id, c?.objectId, c?.description)
                      onConstructionSiteChanged()
                    }}
                    spacing={'bottom'}
                  />
                )}

                {filter.type === ApiFilterType.Lotto && !!selectedConstructionSite && (
                  <ConstructionSiteLottoSelector
                    editable={isFilterEditable(filter.dataKey)}
                    objectId={selectedConstructionSite}
                    value={parseValue(filter)}
                    onChange={c => changeApiFilter(filter.id, c?.lotId, c?.description)}
                    spacing={'bottom'}
                  />
                )}

                {(filter.type === ApiFilterType.TechnicianFull || filter.type === ApiFilterType.Technician) && (
                  <MultiEmployeeSelector
                    editable={isFilterEditable(filter.dataKey)}
                    values={parseValues(filter)}
                    onChange={values =>
                      changeApiFilter(
                        filter.id,
                        values?.map(v => v.id),
                        values?.map(v => EmployeeUtils.getName(v)).join(' & ')
                      )
                    }
                    spacing={'bottom'}
                    filterDisabled={filter.type === ApiFilterType.Technician}
                  />
                )}

                {filter.type === ApiFilterType.InElaboration && (
                  <InElaborationSelector
                    editable={isFilterEditable(filter.dataKey)}
                    value={filter.value === undefined || filter.value === null ? undefined : (filter.value.toString() as ActivityInElaborationFilter)}
                    onChange={value =>
                      changeApiFilter(
                        filter.id,
                        value,
                        value === ActivityInElaborationFilter.InElaboration ? i18n.t('IN_ELABORATION') : i18n.t('NOT_IN_ELABORATION')
                      )
                    }
                    spacing={'bottom'}
                  />
                )}
                {filter.type === ApiFilterType.RdaStatus && (
                  <RdaStatusSelector
                    editable={isFilterEditable(filter.dataKey)}
                    value={!filter.values ? undefined : (filter.values as RdaStatus[])}
                    onChange={values => changeApiFilter(filter.id, values)}
                    spacing={'bottom'}
                  />
                )}
              </IM.View>
            ))}
          </IM.View>
        ))}
      </IM.View>
    </FormProvider>
  )
}
