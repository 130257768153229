import { IM, IMLayout, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useCallback, useEffect } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist'

import { api, apiDtoIds } from '../../../apis/apiCalls'
import { ActivityType } from '../../../apis/types/apiResponseTypes'
import ActivityTypeCard from '../../../cards/activities/ActivityTypeCard'
import { FlashListDataHeader } from '../../../components/FlashListData'
import AnimatedButton from '../../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../../components/Infominds/Pressable'
import ListSpacer from '../../../components/ListSpacer'
import { ADD_ACTIVITY_BUTTON_ID } from '../../../constants/ButtonIds'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { AppStyle } from '../../../constants/Styles'
import { useDragAndDrop } from '../../../contexts/DragAndDropContext'
import { useDataProvider } from '../../../dataProvider/hooks/useDataProvider'
import useLayout from '../../../hooks/useLayout'
import useObjectUtils from '../../../hooks/useObjectUtils'
import useUserSettings from '../../../hooks/useUserSettings'
import ActivityTypeCreationModal from '../../../modals/anagrafics/ActivityTypeCreationModal'

const buttonAnimationValue = new Animated.Value(0)

export default function ActivityTypeListView() {
  const { isSmallDevice } = useLayout()
  const { i18n } = useLanguage()
  const { userSettings } = useUserSettings()
  const { isOnline } = useDataProvider()
  const createActivityModal = useModalController<ActivityType | undefined>()
  const activityTypeObjectUtils = useObjectUtils<ActivityType>(apiDtoIds.activityTypes)

  const { dragActive, setDragActive: setActive, reset: resetReorderedData, onDragEnd, reorderedData } = useDragAndDrop<ActivityType>()

  const {
    item: activityTypes,
    loadItem: loadActivityTypes,
    loading: loadingActivityTypes,
  } = useControlledLoader(api.activities.types.getList, { onLoadedCallback: resetReorderedData })
  useEvent({ key: EVENT_KEYS.UPDATED_ACTIVITY_TYPES }, () => loadActivityTypes())

  const displayedActivityTypes = reorderedData?.length ? reorderedData : activityTypes

  useEffect(() => {
    loadActivityTypes()
  }, [userSettings])

  const renderItem = useCallback(
    ({ item, drag, isActive }: RenderItemParams<ActivityType>) => (
      <Pressable
        key={'pressable-' + activityTypeObjectUtils.createId(item)}
        disableEffects={Platform.OS === 'web'}
        noContentStyling
        onPressIn={dragActive ? drag : undefined}
        spacing={isActive ? ['left', 'bottom'] : 'bottom'}
        onPress={dragActive ? undefined : () => createActivityModal.show(item)}
        onLongPress={() => {
          setActive(true)
          drag()
        }}>
        <ActivityTypeCard style={AppStyle.card} activityType={item} showPlanColor endIcon={dragActive ? ['fal', 'grip-lines'] : undefined} disabled />
      </Pressable>
    ),
    [dragActive, isSmallDevice]
  )

  return (
    <IM.View style={[IMLayout.flex.f1]}>
      <DraggableFlatList
        data={displayedActivityTypes ?? []}
        style={[styles.list]}
        keyExtractor={item => `${item.srvActivityTypeId?.toString()}`}
        renderItem={renderItem}
        onDragEnd={items => {
          onDragEnd(items)
        }}
        ListHeaderComponent={
          <>
            <ListSpacer />
            {dragActive && !loadingActivityTypes && (
              <IM.View style={AppStyle.center} spacing={'bottom'}>
                <IM.Text secondary>{i18n.t('DRAG_ELEMENTS_TO_ORDER_THEM')}</IM.Text>
              </IM.View>
            )}
          </>
        }
        ListFooterComponent={
          <FlashListDataHeader data={displayedActivityTypes} noDataMessage={i18n.t('NO_ACTIVITY_TYPE_FOUND')} loading={loadingActivityTypes} />
        }
        activationDistance={1} // required for web
      />

      {!loadingActivityTypes && isOnline && !dragActive && (
        <IM.View style={styles.addButtonView}>
          <AnimatedButton
            id={ADD_ACTIVITY_BUTTON_ID}
            value={buttonAnimationValue}
            icon={['fal', 'plus']}
            onPress={() => {
              createActivityModal.show(undefined)
            }}
          />
        </IM.View>
      )}
      <ActivityTypeCreationModal controller={createActivityModal} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  addButtonView: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  list: {
    height: '100%',
  },
})
