import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { MasterDetailProvider } from '../../components/MasterDetail/contexts/MasterDetailContext'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import DragAndDropHeader from '../../components/screen/headers/DragAndDropHeader'
import { Screen } from '../../components/screen/Screen'
import { DragAndDropProvider } from '../../contexts/DragAndDropContext'
import useLayout from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import { AnagraficsMasterDetailSelection } from '../../types'
import AnagraficsView from '../../views/anagrafics/AnagraficsView'

export default function AnagraficsScreen() {
  return (
    <MasterDetailProvider storageKey="AnagraficsMasterDetail">
      <DragAndDropProvider>
        <SearchProvider>
          <Screen>
            <Screen.Header>
              <Header />
            </Screen.Header>
            <Screen.Content>
              <AnagraficsView />
            </Screen.Content>
          </Screen>
        </SearchProvider>
      </DragAndDropProvider>
    </MasterDetailProvider>
  )
}

function Header() {
  const { i18n } = useLanguage()
  const { isLargeDevice } = useLayout()
  const { isOnlyReport } = useUserSettings()

  const { detailParams } = useMasterDetail<AnagraficsMasterDetailSelection>()

  return (
    <DragAndDropHeader
      title={i18n.t(isOnlyReport ? 'ACTIVITY_TYPES' : 'TAB_ANAGRAFICS')}
      enabled={isLargeDevice && detailParams?.id === 'ActivityType'}
    />
  )
}
