import { IM, useLanguage, ViewProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import useArticleSearch from '../../hooks/activity/useArticleSearch'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useLayout from '../../hooks/useLayout'
import { ArticleSearchModeSelection } from '../../types'
import DefaultSearchDetailHeader from '../screen/headers/default/DefaultSearchDetailHeader'
import TabSelector from '../TabSelector'

export type ArticleSearchModeSelectorRef = { hasCatalogue?: boolean; hasHistory?: boolean; showSearch?: boolean } & Pick<ViewProps, 'style'>

export const SupportedArticleSearchModes: ArticleSearchModeSelection[] = [
  { mode: 'all', languageKey: 'ARTICLE_SEARCH_MODE_ALL' },
  { mode: 'catalogue', languageKey: 'ARTICLE_SEARCH_MODE_CATALOGUE' },
  { mode: 'history', languageKey: 'ARTICLE_SEARCH_MODE_HISTORY' },
]

function ArticleSearchModeSelector({ hasCatalogue, hasHistory, style, showSearch }: ArticleSearchModeSelectorRef) {
  const { theme } = useExtendedTheme()
  const { isSmallDevice } = useLayout()
  const { mode, setMode } = useArticleSearch()

  const { i18n } = useLanguage()

  const backgroundColor = theme.header.detail.background
  const dividerColor = theme.header.detail.skeletonBackground

  const filteredSearchModes = React.useMemo(
    () => SupportedArticleSearchModes.filter(m => (m.mode !== 'catalogue' || !!hasCatalogue) && (m.mode !== 'history' || !!hasHistory)),
    [hasCatalogue]
  )

  if (filteredSearchModes.length <= 1 && !showSearch) return <></>

  return (
    <IM.View style={[styles.container, style]}>
      {!!showSearch && <DefaultSearchDetailHeader enableQR disableAutofocus forceLayout={'small'} />}
      {filteredSearchModes.length > 1 && (
        <TabSelector height={35} width={isSmallDevice ? 200 : 220}>
          {filteredSearchModes.map((searchMode, index) => (
            <TabSelector.Item
              key={`ArticleSearchModeTab${searchMode.mode}`}
              text={i18n.t(searchMode.languageKey)}
              selected={mode === searchMode.mode}
              textColor="white"
              selectedTextColor={theme.general.info}
              onPress={() => setMode(searchMode.mode)}
              backgroundColor={backgroundColor}
              dividerColor={dividerColor}
              disableDivider={index === filteredSearchModes.length - 1}
              selectionColor={backgroundColor}
            />
          ))}
        </TabSelector>
      )}
    </IM.View>
  )
}

export default ArticleSearchModeSelector

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
