import { useAlert, useEvent, useLanguage } from '@infominds/react-native-components'

import { api } from '../../apis/apiCalls'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useRequest from '../Infominds/hooks/useRequest'

export default function useGanttChartWrapperRequestApi() {
  const { alert } = useAlert()
  const { i18n } = useLanguage()

  const { emit: eventRefreshGraph } = useEvent({ key: EVENT_KEYS.GANTT_CHART_REFRESH })

  const { request: removeUnavailable } = useRequest(api.activities.unavailables.delete, {
    onError: () => {
      alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_EVENT'), [
        {
          text: i18n.t('OK'),
          onPress: () => eventRefreshGraph(),
        },
      ])
    },
  })
  const { request: editUnavailable } = useRequest(api.activities.unavailables.put, {
    onError: () => {
      alert(i18n.t('ERROR'), i18n.t('ERROR_EDIT_EVENT'), [
        {
          text: i18n.t('OK'),
          onPress: () => eventRefreshGraph(),
        },
      ])
    },
  })

  return { removeUnavailable, editUnavailable }
}
