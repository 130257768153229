import { useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { SectionListRenderItemInfo, StyleSheet } from 'react-native'

import { api } from '../../apis/apiCalls'
import { GetCustomerDestinationRequest } from '../../apis/types/apiRequestTypes'
import { Destination } from '../../apis/types/apiResponseTypes'
import CustomerDestinationCard from '../../cards/customer/CustomerDestinationCard'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import { ADD_CONTACT_DESTINATION_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import DestinationEditOrCreateModal from '../../modals/customer/DestinationEditOrCreateModal'
import { DestinationViewRef, ListSection } from '../../types'

interface Props {
  customerId: number
  show: boolean
  showTitle?: boolean
  skeletonNumber?: number
  closeEndThreshold?: number
  hideSectionTitle?: boolean
  onPress?: (destination: Destination) => void
  onRefresh?: () => void
}

const DestinationsView = (
  { show, customerId, showTitle, skeletonNumber, hideSectionTitle, closeEndThreshold, onRefresh }: Props,
  ref: ForwardedRef<DestinationViewRef>
) => {
  useImperativeHandle(ref, () => ({
    openModal: () => destinationEditOrCreateModalController.show(undefined),
  }))

  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { colorScheme } = useTheme()

  const destinationEditOrCreateModalController = useModalController<Destination | undefined>()

  const {
    item: destinations,
    loadItem: loadDestinations,
    loading,
    allDataLoaded,
    loadMore,
  } = useInfiniteLoader<GetCustomerDestinationRequest, Destination>(api.customers.destinations.getList, {
    chuckSize: CONSTANTS.destinationsChunkSize,
  })
  useEvent({ key: EVENT_KEYS.UPDATED_DESTINATIONS }, () => refresh())

  useEffect(() => {
    refresh()
  }, [search])

  const refresh = () => {
    onRefresh?.()
    loadDestinations({ customerId: customerId })
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<Destination, ListSection<Destination>>) => {
    return (
      <CustomerDestinationCard
        destination={item}
        spacing={['horizontal', 'bottom']}
        onPress={() => {
          destinationEditOrCreateModalController.show(item)
        }}
      />
    )
  }

  const data: ListSection<Destination>[] = useMemo(() => {
    const displayData: ListSection<Destination>[] = []

    if (destinations?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        title: showTitle ? i18n.t('DESTINATIONS') : undefined,
        data: destinations,
      })
    }

    return displayData
  }, [destinations, loading])

  return (
    <>
      {show && (
        <SectionList
          contentContainerStyle={stylesList.list}
          loading={loading}
          noDataIcon={['fal', 'location-dot-slash']}
          loadingSection={i18n.t('DESTINATIONS')}
          noDataSection={i18n.t('DESTINATIONS')}
          noDataMessage={i18n.t('NO_DESTINATION_FOUND')}
          sections={data}
          onRefresh={refresh}
          renderItem={renderItem}
          skeletonElements={skeletonNumber ?? CONSTANTS.skeletonCards}
          onLoadMore={loadMore}
          allDataLoaded={allDataLoaded}
          closeEndThreshold={closeEndThreshold ?? 90}
          hideButtonId={ADD_CONTACT_DESTINATION_BUTTON_ID}
          hideSectionTitle={hideSectionTitle}
          indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
        />
      )}
      <DestinationEditOrCreateModal customerId={customerId} controller={destinationEditOrCreateModalController} />
    </>
  )
}

const stylesList = StyleSheet.create({
  list: { paddingTop: 4 },
})

export default forwardRef(DestinationsView)
