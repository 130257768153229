import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'

import { api } from '../../apis/apiCalls'
import { EmailModel, EmailSettings } from '../../apis/types/apiResponseTypes'
import Button from '../../components/Button'
import GroupSpacer from '../../components/GroupSpacer'
import useRequest from '../../components/Infominds/hooks/useRequest'
import Separator from '../../components/Infominds/Separator'
import EmailInput from '../../components/input/EmailInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import EmailModuleSelector from '../../components/selectors/EmailModuleSelector'
import { AppStyle } from '../../constants/Styles'
import useEmailNotifications from '../../hooks/email/useEmailNotifications'
import useLanguageSelect from '../../hooks/useLanguageSelect'

export type TestEmailViewProps = { settings: EmailSettings | undefined }

type TestEmailState = {
  receiver?: string
  subject?: string
  body?: string
  model?: EmailModel
}

export default function TestEmailView({ settings }: TestEmailViewProps) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { languageSelect } = useLanguageSelect()

  const emailNotifications = useEmailNotifications()
  const [enabled, setEnabled] = useState(false)
  const [state, setState] = useState<TestEmailState>({})

  const { request, loading } = useRequest(api.email.send, {
    onSuccess: result => {
      if (result.hasSended) {
        emailNotifications.onSuccess()
      } else {
        emailNotifications.onError(result.message)
      }
    },
    onError: error => {
      emailNotifications.onError(error)
    },
  })

  const commonProps = {
    spacing: ['bottom'],
    editable: true,
  } satisfies TextInputProps

  return (
    <IM.View>
      <GroupSpacer />
      {!enabled && (
        <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd]}>
          <Button title={i18n.t('SEND_MAIL')} color={theme.primary} icon={['fal', 'paper-plane']} onPress={() => setEnabled(true)} />
        </IM.View>
      )}
      {enabled && (
        <IM.View>
          <Separator />
          <EmailInput
            title={i18n.t('RECEIVER')}
            value={state.receiver}
            onChangeText={v => setState(prev => ({ ...prev, receiver: v }))}
            required
            {...commonProps}
          />
          <EmailModuleSelector
            value={state.model?.srvEmailId}
            onChange={value =>
              setState(prev => ({
                ...prev,
                model: value,
                subject: languageSelect({ de: value?.subject_De, en: value?.subject_En, it: value?.subject_It }),
                body: languageSelect({ de: value?.text_De, en: value?.text_En, it: value?.text_It }),
              }))
            }
            selectedActivityId={state.model?.srvActivityTypeId}
            {...commonProps}
          />
          <TextInput
            title={i18n.t('SUBJECT')}
            value={state.subject}
            onChangeText={v => setState(prev => ({ ...prev, subject: v, model: undefined }))}
            required
            {...commonProps}
          />
          <TextInput
            title={i18n.t('EMAIL_BODY')}
            value={state.body}
            onChangeText={v => setState(prev => ({ ...prev, body: v, model: undefined }))}
            multiline
            {...commonProps}
          />
          <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd, { gap: IMLayout.horizontalMargin }]}>
            <Button title={i18n.t('CANCEL')} color={theme.info} onPress={() => setEnabled(false)} />
            <Button
              title={i18n.t('SEND_MAIL')}
              color={theme.primary}
              disabled={!state.receiver || !state.subject || !settings?.server || !settings?.port || !settings?.email || !settings?.password}
              loading={loading === 'reloading'}
              icon={['fal', 'paper-plane']}
              onPress={() =>
                request({
                  smtp: {
                    host: settings?.server ?? '',
                    port: settings?.port ?? 0,
                    password: settings?.password ?? '',
                  },
                  message: {
                    from: settings?.email ?? '',
                    to: state.receiver ?? '',
                    subject: state.subject,
                    body: state.body,
                  },
                })
              }
            />
          </IM.View>
          <GroupSpacer />
        </IM.View>
      )}
    </IM.View>
  )
}
