import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import ActivityDetailConstructionSiteContactIdCard from '../../cards/activities/detail/ActivityDetailConstructionSiteContactIdCard'
import ActivityDetailConstructionSiteIdCard from '../../cards/activities/detail/ActivityDetailConstructionSiteIdCard'
import ActivityDetailContactIdCard from '../../cards/activities/detail/ActivityDetailContactIdCard'
import ActivityDetailContractIdCard from '../../cards/activities/detail/ActivityDetailContractIdCard'
import ActivityDetailCustomerIdCard from '../../cards/activities/detail/ActivityDetailCustomerIdCard'
import ActivityDetailDescriptionCard from '../../cards/activities/detail/ActivityDetailDescriptionCard'
import ActivityDetailDestinationIdCard from '../../cards/activities/detail/ActivityDetailDestinationIdCard'
import ActivityDetailDeviceCard from '../../cards/activities/detail/ActivityDetailDeviceCard'
import ActivityDetailInfoCard from '../../cards/activities/detail/ActivityDetailInfoCard'
import ActivityButton from '../../components/ActivityButton'
import AssignToMeButton from '../../components/AssignToMeButton'
import DynamicView from '../../components/Infominds/DynamicView'
import ListSpacer from '../../components/ListSpacer'
import ScrollViewData from '../../components/ScrollViewData'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useLayout from '../../hooks/useLayout'
import { ActivityDetailStackParamList } from '../../navigation/types'
import { activityUtils } from '../../utils/ActivityUtils'
import ActivityPreparationView from './ActivityPreparationView'

export default function ActivityDetailView() {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation<NavigationProp<ActivityDetailStackParamList>>()
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityDetail'>>()

  const [preparationMode, setPreparationMode] = useState(false)

  const { detail, refreshDetail, loadingDetail } = useActivityDetail()

  const isHistorical = useMemo(() => activityUtils.isActivityClosed(detail), [detail?.state])

  const showContactCard = !!detail?.contactId
  const showConstructionSiteContactCard = !!detail?.objectContactId
  const showDestinationCard = !!detail?.destinationId
  const showConstructionSiteCard = !!detail?.objectId || !!detail?.objectLotId

  return (
    <>
      <ScrollViewData refresh={refreshDetail} loading={loadingDetail}>
        {detail && (
          <>
            <ActivityDetailInfoCard type="normal" activity={detail} assignable={false} />
            <DynamicView rowStyle={{ gap: IMLayout.horizontalMargin }}>
              <IM.View style={IMLayout.flex.f6}>
                <ActivityDetailCustomerIdCard spacing="top" style={styles.grow} />
              </IM.View>

              {showContactCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailContactIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}

              {showDestinationCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailDestinationIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}
              {showConstructionSiteCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailConstructionSiteIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}
              {showConstructionSiteContactCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailConstructionSiteContactIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}

              {detail.device && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailDeviceCard style={IMLayout.flex.f1} spacing={'top'} device={detail.device} />
                </IM.View>
              )}

              {(!!detail.description || !!detail.technicalDescription) && (
                <IM.View style={IMLayout.flex.f12}>
                  <ActivityDetailDescriptionCard
                    description={detail.description}
                    tecDescription={detail.technicalDescription}
                    spacing="top"
                    customerDescription={undefined}
                    numberOfLines={5}
                  />
                </IM.View>
              )}
              {!!detail.srvContractId && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailContractIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}
            </DynamicView>

            {preparationMode && <ActivityPreparationView />}

            <IM.View
              style={[IMLayout.flex.row, styles.buttonsView, isSmallDevice && !preparationMode && styles.smallDeviceTwoButtons]}
              spacing={'vertical'}>
              {!preparationMode && (
                <ActivityButton
                  style={[styles.startButton, { backgroundColor: theme.card.secondaryBackground }]}
                  title={i18n.t('PREPARATION')}
                  icon={['fas', 'play']}
                  onPress={() => setPreparationMode(true)}
                />
              )}
              {!!detail.assignedUser?.length && (
                <ActivityButton
                  style={styles.startButton}
                  title={i18n.t('START')}
                  icon={['fas', 'play']}
                  onPress={() => navigation.navigate('ActivityDetailWork', { ...route.params })}
                />
              )}
              {!detail.assignedUser?.length && !isHistorical && <AssignToMeButton activity={detail} onAssigned={refreshDetail} />}
            </IM.View>
            <ListSpacer height={100} />
          </>
        )}
      </ScrollViewData>
    </>
  )
}

const styles = StyleSheet.create({
  smallDeviceTwoButtons: {
    justifyContent: 'space-between',
  },
  grow: {
    flexGrow: 1,
  },
  startButton: {
    width: 150,
  },
  buttonsView: {
    justifyContent: 'flex-end',
    gap: IMLayout.horizontalMargin * 2,
  },
})
