import { useEvent, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React from 'react'

import { Screen } from '../../components/screen/Screen'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { useReportActivity } from '../../contexts/report/ReportActivityContext'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ReportStackParamList } from '../../navigation/types'
import ActivityClosingView from '../../views/activities/ActivityClosingView'

export default function ReportClosingScreen() {
  const { i18n } = useLanguage()
  const { activity, setActivity } = useReportActivity()
  const { detail } = useActivityDetail(activity)
  const navigation = useNavigation<NavigationProp<ReportStackParamList>>()

  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
  })

  const { emit: emitActivityClosedEvent } = useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED })
  function onActivityClosed() {
    setActivity(undefined)
    emitActivityClosedEvent()
    navigation.navigate('ReportScreen')
  }

  return (
    <Screen>
      <Screen.Header
        goBack={() => handleGoBack()}
        backHandlerCallback={handleGoBack}
        title={`${i18n.t('ACTIVITY_CLOSING')}: ${detail?.title ?? ''}`}
      />
      <Screen.Content>
        <ActivityClosingView setStatus={setStatus} onActivityClosed={onActivityClosed} onActivityAlreadyClosed={onActivityClosed} />
      </Screen.Content>
    </Screen>
  )
}
