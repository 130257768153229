import { CardProps, IM } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import { Vehicle } from '../../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import useExtendedTheme from '../../../hooks/useExtendedTheme'

export type ActivityTypeCardProps = {
  vehicle: Vehicle
} & CardProps

export default memo(function ActivityTypeCard({ vehicle, selected, style, ...otherProps }: ActivityTypeCardProps) {
  const { theme } = useExtendedTheme()

  return (
    <IM.Card {...otherProps} style={[styles.card, style]} head={<CardLeftTitle color={selected ? theme.primary : undefined} />}>
      <IM.TextWithIcon icon={['fal', 'truck']} primary>
        {vehicle.registrationNr}
      </IM.TextWithIcon>
      {!!vehicle.description && (
        <IM.Text numberOfLines={1} secondary>
          {vehicle.description}
        </IM.Text>
      )}
    </IM.Card>
  )
})

const styles = StyleSheet.create({
  card: {
    minHeight: 44, // set to avoid size change if icon is made visible. empirically determined
  },
})
