import { useContext } from 'react'

import { MasterContext } from '../contexts/MasterContext'

export default function useMaster<T extends object>() {
  const context = useContext(MasterContext)

  if (!context) {
    throw new Error('useMaster can only be called inside MasterProvider')
  }

  return { detail: context.detail as T | undefined, setDetail: (detail: T) => context.setDetail(detail) }
}
