import { ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { Contact } from '../../apis/types/apiResponseTypes'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { EditOrCreateViewRef, ModalScreenRef } from '../../types'
import CustomerContactCreateOrEditView from '../../views/customer/CustomerContactCreateOrEditView'

interface Props {
  controller: ModalController<Contact | undefined>
  customerId: number
  onContactCreated?: (customer: Contact) => void
}

function CustomerContactCreationScreen({ controller, customerId, onContactCreated }: Props, ref: ForwardedRef<ModalScreenRef>) {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const customerToEdit = controller.data

  return (
    <SearchProvider>
      <Screen transparent>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal
          title={customerToEdit ? i18n.t('EDIT_CONTACT') : i18n.t('CREATE_CONTACT')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            <CustomerContactCreateOrEditView
              customerId={customerId}
              contact={customerToEdit}
              ref={createViewRef}
              onDone={(_type, result, request) => {
                handleGoBack(true)
                const createdContactId = result?.find(r => r.contactId)?.contactId
                if (!createdContactId) return
                onContactCreated?.({ ...request, contactId: createdContactId } as Contact)
              }}
              onUploadStatus={setStatus}
            />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(CustomerContactCreationScreen)
