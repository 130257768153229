import { IM, useModalController } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import useExtendedTheme from '../../hooks/useExtendedTheme'
import ColorPickerModal from '../../modals/ColorPickerModal'
import { TextInputRef } from '../../types'
import appUtils from '../../utils/appUtils'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type ColorInputProps = Pick<
  BaseTextInputProps,
  'value' | 'placeholder' | 'editable' | 'spacing' | 'style' | 'textStyle' | 'required' | 'disableBorderRadius'
> &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    disabledInfo?: string | false
    onChangeColor: (color: string) => void
  }

const charMask = /[\da-fA-f]/
const ColorInput = memo(
  forwardRef(function ColorInput(
    {
      title,
      titleFontWeight,
      details,
      editable = true,
      error,
      loading,
      disableFocus,
      disabledInfo,
      textStyle,
      onChangeColor,
      ...textInputProps
    }: ColorInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { theme } = useExtendedTheme()
    const pickerModal = useModalController()

    return (
      <BaseTextInputProvider editable={false} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput
          ref={ref}
          editable={false}
          onChangeText={onChangeColor}
          mask={() => ['#', charMask, charMask, charMask, charMask, charMask, charMask]}
          textStyle={[textStyle, { color: appUtils.isValidColor(textInputProps.value) ? textInputProps.value : theme.text }]}
          {...textInputProps}
          style={{ borderColor: theme.inputBox.border.active }}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />}

          {loading && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              {loading && <BaseTextInputSpinningIcon loading={loading} />}
            </BaseTextInput.RightIcon>
          )}
          {!editable && !loading && disabledInfo && (
            <BaseTextInput.RightIcon>
              <IM.Text style={[{ color: theme.textPlaceholder }, styles.disabledText]}>{disabledInfo}</IM.Text>
            </BaseTextInput.RightIcon>
          )}
          {!loading && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius} style={{ borderColor: theme.inputBox.border.active }}>
              <PressableIcon icon={['fal', 'palette']} color={theme.textDetail} size={20} onPress={() => pickerModal.show()} disabled={!editable} />
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
        <ColorPickerModal controller={pickerModal} color={textInputProps.value ?? ''} setColor={onChangeColor} />
      </BaseTextInputProvider>
    )
  })
)

export default ColorInput

const styles = StyleSheet.create({
  disabledText: {
    paddingRight: 9,
  },
})
