import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IconProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { GroupDataSorter, OrderDataSorter } from '../types'
import Tag from './Tag'

interface Props<T> extends Partial<IconProps> {
  sorter: OrderDataSorter<T> | GroupDataSorter<T>
  isHeader?: boolean
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: (id: string) => void
}

export default function SorterTag<T>({ sorter, icon: propsIcon, ...iconProps }: Props<T>) {
  const { i18n } = useLanguage()

  const icon = useMemo<IconProp | undefined>(() => {
    if (sorter.type === 'order') {
      if (sorter.options?.modifier === 'inverse') return ['fal', 'arrow-down-wide-short']
      return ['fal', 'arrow-down-short-wide']
    }

    return undefined
  }, [sorter])

  return <Tag id={sorter.id} name={i18n.t(sorter.textKey)} active={sorter.active} icon={propsIcon ?? icon} {...iconProps} />
}
