import { IMLayout, useLanguage } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { forwardRef, PropsWithChildren } from 'react'
import { Keyboard } from 'react-native'

import { ErgoTask } from '../../apis/types/apiResponseTypes'
import ArticleSelectionDetailHeader from '../../components/screen/headers/ArticleSelectionDetailHeader'
import { Screen } from '../../components/screen/Screen'
import ArticleSearchModeSelector from '../../components/selectors/ArticleSearchModeSelector'
import { AppStyle } from '../../constants/Styles'
import { ArticleSearchContext, ArticleSearchContextProvider, ArticleSearchContextResult } from '../../contexts/activity/ArticleSearchContext'
import useActivityArticles from '../../hooks/activity/useActivityArticles'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import useErgoTask from '../../hooks/useErgoTask'
import useLayout from '../../hooks/useLayout'
import { ActivityDetailStackParamList } from '../../navigation/types'
import { UploadStatus } from '../../types'
import ActivityArticleSelectionView from '../../views/activities/articles/ActivityArticleSelectionView'
import ArticleListView from '../../views/activities/articles/ArticleListView'

function ActivityArticleSelectionScreen() {
  const { i18n } = useLanguage()
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityArticleSelectionScreen'>>()

  const { ergoTask, loadingErgoTask } = useErgoTask(route.params?.taskId)
  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
  })

  const {
    articles: activityArticles,
    loadingArticles: loadingActivityArticles,
    refreshArticles: refreshActivityArticles,
  } = useActivityArticles(route.params)

  // during loading of activityArticles show loading view. this is done in this way to make sure the ArticleSearchContextProvider is only called after the articles are loaded
  if (
    loadingActivityArticles === 'init' ||
    loadingActivityArticles === 'reloading' ||
    loadingErgoTask === 'init' ||
    loadingErgoTask === 'reloading'
  ) {
    return (
      <ScreenWrapper handleGoBack={handleGoBack} status={status} initializing ergoTask={ergoTask}>
        <ArticleListView articles={[]} loadingArticles={loadingActivityArticles} refreshArticles={refreshActivityArticles} />
      </ScreenWrapper>
    )
  }

  return (
    <ArticleSearchContextProvider
      activity={route.params}
      ergoTask={ergoTask}
      initSelectedArticles={activityArticles ?? []}
      setStatus={setStatus}
      onDone={() => handleGoBack(true)}>
      <ArticleSearchContext.Consumer>
        {articleSearch => (
          <ScreenWrapper articleSearch={articleSearch} status={status} handleGoBack={handleGoBack} ergoTask={ergoTask}>
            <ActivityArticleSelectionView />
          </ScreenWrapper>
        )}
      </ArticleSearchContext.Consumer>
    </ArticleSearchContextProvider>
  )
}

type ContentProps = {
  articleSearch?: ArticleSearchContextResult | null
  status: UploadStatus
  handleGoBack: (ignoreUnsavedChanges?: boolean | undefined) => void
  initializing?: boolean
  ergoTask: ErgoTask | null | undefined
} & PropsWithChildren
function ScreenWrapper({ articleSearch, status, handleGoBack, initializing, children, ergoTask }: ContentProps) {
  const { isSmallDevice } = useLayout()

  const hasCatalogue = !!ergoTask?.paramList?.PRICELISTID && ergoTask.paramList.PRICELISTID !== '0'

  return (
    <Screen forceDetailOpen={isSmallDevice && !initializing}>
      <Screen.Header
        goBack={() => handleGoBack()}
        backHandlerCallback={handleGoBack}
        onRightIconPress={() => {
          Keyboard.dismiss()
          articleSearch?.submitArticles()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing' || initializing}
        loading={status === 'uploading'}>
        {isSmallDevice && !initializing && (
          <ArticleSearchModeSelector style={[IMLayout.flex.f1, AppStyle.center]} hasCatalogue={hasCatalogue} hasHistory />
        )}
        {!isSmallDevice && !initializing && <ArticleSelectionDetailHeader hasCatalogue={hasCatalogue} hasHistory />}
      </Screen.Header>
      {isSmallDevice && !initializing && (
        <Screen.DetailHeader>
          <ArticleSelectionDetailHeader hasCatalogue={hasCatalogue} hasHistory />
        </Screen.DetailHeader>
      )}
      <Screen.Content>{children}</Screen.Content>
    </Screen>
  )
}

export default forwardRef(ActivityArticleSelectionScreen)
