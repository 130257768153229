import { useEvent } from '@infominds/react-native-components'
import React, { PropsWithChildren, useMemo, useState } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity, ErgoTask } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useErgoTask from '../../hooks/useErgoTask'
import useObjectUtils from '../../hooks/useObjectUtils'
import { ActivityId, LoadingType } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'

type ActivityDetailDataCounter = Record<'times' | 'notes' | 'articles' | 'travels', number>

export type ActivityDetailContextProps = {
  detail: Activity | null | undefined
  loadingDetail: LoadingType
  editDetail: (detail: Partial<Activity>) => void
  refreshDetail: () => void
  loadDetail: (id: ActivityId, reload?: boolean) => void
  setDetail: React.Dispatch<React.SetStateAction<Activity | null | undefined>>
  ergoTask: ErgoTask | null | undefined
  dataCount: ActivityDetailDataCounter
  setDataCount: React.Dispatch<React.SetStateAction<ActivityDetailDataCounter>>
  isOpen: boolean
  isHistorical: boolean
}

export const ActivityDetailContext = React.createContext<ActivityDetailContextProps | null>(null)

export function ActivityDetailContextProvider({ children }: PropsWithChildren) {
  const objectUtils = useObjectUtils<Activity>(apiDtoIds.activity)

  const [dataCount, setDataCount] = useState<ActivityDetailDataCounter>({ articles: 0, notes: 0, times: 0, travels: 0 })
  const { item: detail, loadItem: loadDetail, loading: loadingDetail, setItem: setDetail } = useControlledLoader(api.activities.getDetail)
  const { ergoTask } = useErgoTask(detail?.taskId)
  const { request: editActivity } = useRequest(api.activities.put)
  const isHistorical = useMemo(() => activityUtils.isActivityClosed(detail), [detail?.state])

  useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED }, refreshDetail)

  function load(id: ActivityId, reload?: boolean) {
    if (!id) return
    if (!!detail && !reload && objectUtils.compare(id, detail)) return
    loadDetail({ ...id })
  }

  function refreshDetail() {
    if (detail) {
      loadDetail({ srvActivityId: detail.srvActivityId, srvActivityTypeId: detail.srvActivityTypeId, srvActivityYear: detail.srvActivityYear })
    }
  }

  function editDetail(detailToEdit: Partial<Activity>) {
    setDetail(prev => {
      if (!prev) return prev
      Object.assign(prev, detailToEdit)
      editActivity({ ...prev })
      return { ...prev }
    })
  }

  return (
    <ActivityDetailContext.Provider
      value={{
        detail,
        loadingDetail,
        editDetail,
        refreshDetail,
        loadDetail: load,
        setDetail,
        ergoTask,
        dataCount,
        setDataCount,
        isOpen: !isHistorical,
        isHistorical,
      }}>
      {children}
    </ActivityDetailContext.Provider>
  )
}
