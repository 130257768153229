import {
  EventItem,
  HighlightDates,
  MomentConfig,
  OnChangeProps,
  PackedEvent,
  ThemeProperties,
  TimelineCalendar,
  TimelineCalendarHandle,
} from '@howljs/calendar-kit'
import { IM, IMLayout, IMStyle, LoadingSpinnerModal, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { endOfWeek, startOfWeek } from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet, useWindowDimensions } from 'react-native'
import { SharedValue } from 'react-native-reanimated'
import { useRecoilValue } from 'recoil'

import { ActivityGroupBy, GetActivityRequest, GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Activity, ActivityPlanTime } from '../../apis/types/apiResponseTypes'
import ActivityCalenderCard from '../../cards/activityList/ActivityCalenderCard'
import Pressable from '../../components/Infominds/Pressable'
import PressableIcon from '../../components/Infominds/PressableIcon'
import useSearch from '../../components/screen/hooks/useSearch'
import useActivities from '../../hooks/activity/useActivities'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import useOnLayout from '../../hooks/useOnLayout'
import useUserSettings from '../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import { chartUtils } from '../../utils/chartUtils'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import { calendarViewAtom } from '../../utils/stateManager'
import TimeUtils from '../../utils/TimeUtils'
import { utils } from '../../utils/utils'
import { ActivityListProps } from './ActivityListView'

const unavailableHours = {
  //Sunday
  '0': [{ start: 0, end: 24 }],
  //Monday
  '1': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Tuesday
  '2': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Wednesday
  '3': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Thursday
  '4': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Friday
  '5': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Saturday
  '6': [{ start: 0, end: 24 }],
  //specific date
  /* '2022-12-01': [
    { start: 0, end: 7.5 },
    { start: 12, end: 13.5 },
    { start: 17, end: 24 },
  ], */
}

const holidays = ['2023-08-15'] // -> Should get holiday based on locale

export default function ActivityListCalendarView({ onActivityPressed }: ActivityListProps) {
  const { i18n, language } = useLanguage()
  const { colorScheme, theme } = useTheme<ThemeColorExpanded>()
  const { width } = useWindowDimensions()
  const { layout, onLayout } = useOnLayout()
  const { isSmallDevice } = useLayout()
  const { user, taskType } = useUserSettings()
  const { sessionKey } = useAuthentication()

  const [date, setDate] = useState(new Date())
  const formattedDate = useMemo(() => formatDate(date), [date])
  const [key, setKey] = useState(utils.generateUuid())
  const viewMode = useRecoilValue(calendarViewAtom(sessionKey))

  const { search } = useSearch()
  const { requestFilter: ogFilter } = useFilter<Activity, GetActivityRequest>()

  const startOfWeekString = useMemo(() => startOfWeek(date).toISOString(), [date])
  const endOfWeekString = useMemo(() => endOfWeek(date).toISOString(), [date])
  const requestFilter = useMemo<GetActivityRequest | undefined>(
    () => ({
      ...ogFilter,
      groupBy: ActivityGroupBy.PlanDate,
      planDateFrom: startOfWeekString,
      planDateTo: endOfWeekString,
      taskType,
    }),
    [ogFilter, startOfWeekString, endOfWeekString]
  )

  const { activities, loadingActivities: loading } = useActivities({
    enabled: true,
    assignedMode: GetActivityRequestAssignedUser.Assigned,
    states: undefined,
    filter: requestFilter,
    searchText: search,
    chunkSize: 0,
    dontResetOnLoad: true,
  })

  const lastSelectedRef = useRef<Activity | null>()
  const calendarRef = useRef<TimelineCalendarHandle>(null)

  useEffect(() => {
    const id = setTimeout(() => {
      setKey(utils.generateUuid())
    }, 500)

    return () => {
      clearTimeout(id)
    }
  }, [width])

  useEffect(() => {
    //set language of weekdays
    MomentConfig.updateLocale('de', {
      weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
    })
    MomentConfig.updateLocale('it', {
      weekdaysShort: 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
    })
  }, [])

  function formatDate(dateToFormat: Date) {
    return TimeUtils.format(dateToFormat, language, 'LLLL yyyy')
  }

  function onCalendarScroll(onChangeProps: OnChangeProps) {
    setDate(new Date(onChangeProps.date))
  }

  function jumpToToday() {
    const optionalProps = {
      hourScroll: true,
      animatedHour: true,
      animatedDate: true,
    }

    calendarRef.current?.goToDate(optionalProps)
  }

  const events: EventItem[] = useMemo(() => {
    const calendarEntries: EventItem[] = []

    Utils.keepUniques(activities, q => activityUtils.createId(q, true)).forEach((activity: Activity) => {
      const planTimes = activity.assignedUser?.reduce<ActivityPlanTime[]>((times, assignedUser) => {
        if (user.isTechnician && !EmployeeUtils.compare(user.technician, assignedUser)) return times
        if (assignedUser.planTimes) times.push(...assignedUser.planTimes)
        return times
      }, [])
      const filteredPlanTimes = Utils.keepUniques(planTimes ?? [], q => `${q.planDateFrom}#${q.planDateTo}`)
      filteredPlanTimes?.forEach((planTime, index) => {
        if (planTime.planDateTo && planTime.planDateFrom) {
          const color = activityUtils.isActivityClosed(activity)
            ? chartUtils.getActivityBlockColor(colorScheme)
            : activity.planColor ?? activityUtils.getCardHeadColorByActivity(activity, theme)

          calendarEntries.push({
            id: `${activityUtils.createId(activity)}#${index}`,
            title: activity.title ?? '',
            start: planTime.planDateFrom,
            end: planTime.planDateTo,
            color: color,
            containerStyle: { opacity: 0.8 },
          })
        }
      })
    })

    return calendarEntries
  }, [activities, colorScheme, user])

  function handleActivityPressed(activity: Activity) {
    lastSelectedRef.current = activity
    onActivityPressed(activity)
  }

  function renderEventContent(event: PackedEvent, _timeIntervalHeight: SharedValue<number>) {
    const activity = activities.find(activityFound => activityUtils.createId(activityFound) === event.id.split('#').slice(0, 3).join('#'))
    if (!event || !activity) return <></>
    return <ActivityCalenderCard activity={activity} onPress={() => handleActivityPressed(activity)} width={event.width} />
  }

  const highlightDates: HighlightDates = useMemo(
    () =>
      holidays.reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value]: {
            dayNameColor: IMStyle.palette.red,
            dayNumberColor: IMStyle.palette.white,
            dayNumberBackgroundColor: IMStyle.palette.red,
          },
        }
      }, {}),
    []
  )

  const calendarTheme: ThemeProperties = {
    nowIndicatorColor: theme.general.info,
    loadingBarColor: theme.general.info,
    unavailableBackgroundColor: colorScheme === 'dark' ? '#282828' : '#F1F1F1',
    backgroundColor: colorScheme === 'dark' ? theme.background : '#FFFFFF', //theme.background,
    cellBorderColor: colorScheme === 'dark' ? '#464646' : '#E2E2E2',

    todayName: { color: theme.general.info },
    todayNumber: { color: IMStyle.palette.white },
    todayNumberContainer: { backgroundColor: theme.general.info },

    dayName: { color: theme.text },
    dayNumber: { color: theme.text },
    dayNumberContainer: { backgroundColor: theme.background },

    saturdayName: { color: theme.textDetail },
    saturdayNumber: { color: theme.textDetail },
    saturdayNumberContainer: { backgroundColor: theme.background },

    sundayName: { color: theme.textDetail },
    sundayNumber: { color: theme.textDetail },
    sundayNumberContainer: { backgroundColor: theme.background },

    hourText: { color: theme.text },
  }

  return (
    <IM.View style={[IMLayout.flex.f1, { backgroundColor: theme.background }, IMLayout.shadow]} onLayout={onLayout}>
      <LoadingSpinnerModal visible={loading === 'reloading'} />
      <IM.View
        style={[
          styles.header,
          {
            backgroundColor: theme.background,
          },
        ]}>
        <PressableIcon
          icon={['fal', 'chevron-left']}
          size={20}
          onPress={() => calendarRef.current?.goToPrevPage()}
          style={[IMLayout.flex.f1, styles.leftIcon]}
        />
        <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular }}>{formattedDate}</IM.Text>
        <PressableIcon
          icon={['fal', 'chevron-right']}
          size={20}
          onPress={() => calendarRef.current?.goToNextPage()}
          style={[IMLayout.flex.f1, styles.rightIcon]}
        />
      </IM.View>
      {layout?.width && (
        <TimelineCalendar
          key={key}
          ref={calendarRef}
          viewMode={viewMode ?? (isSmallDevice ? 'threeDays' : 'week')}
          events={events}
          scrollToNow
          showNowIndicator
          allowPinchToZoom
          initialTimeIntervalHeight={60}
          minTimeIntervalHeight={30}
          maxTimeIntervalHeight={110}
          isLoading={false}
          theme={calendarTheme}
          unavailableHours={unavailableHours}
          holidays={holidays}
          locale={language}
          highlightDates={highlightDates}
          renderEventContent={renderEventContent}
          onChange={onCalendarScroll}
          calendarWidth={layout?.width}
        />
      )}
      <Pressable
        style={[IMLayout.shadow, styles.todayButton, { backgroundColor: theme.background }]}
        containerStyle={styles.todayButtonContainer}
        onPress={() => jumpToToday()}>
        <IM.Text>{i18n.t('CALENDAR_TODAY')}</IM.Text>
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  header: { alignItems: 'center', justifyContent: 'space-around', paddingTop: 6, paddingHorizontal: 6, flexDirection: 'row', zIndex: 100 },
  todayButton: { padding: 15, paddingHorizontal: 20, borderRadius: 50 },
  todayButtonContainer: { position: 'absolute', right: 20, bottom: 20 },
  leftIcon: { alignItems: 'flex-start' },
  rightIcon: { alignItems: 'flex-end' },
})
