import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import Separator from '../../../components/Infominds/Separator'
import { AppStyle } from '../../../constants/Styles'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import ActivityCardInfoIcons from './ActivityCardInfoIcons'

type Props = {
  activity: Activity
  enableSeparator?: boolean
  numberOfLines?: number
  showLocationMissingIndicator?: boolean
  forceLayout?: ForceLayoutType
  disableHighlight?: boolean
  lite?: boolean
}

export default function ActivityCardDetailInfo({
  activity,
  enableSeparator,
  numberOfLines = 1,
  style,
  forceLayout,
  showLocationMissingIndicator,
  disableHighlight,
  lite,
  ...props
}: Props & ViewProps) {
  const { isSmallDevice } = useLayout(false, forceLayout)

  const { lotto, lotId } = useMemo(() => {
    if (lite) return {}
    if (activity.device) return { lotto: activity.device, lotId: undefined }
    if (activity.objectLotId) return { lotto: activity.objectLotDescription, lotId: activity.objectLotId }
    if (activity.srvContractLotId) return { lotto: activity.contractLotDescription, lotId: activity.srvContractLotId }
    return {}
  }, [lite, activity])

  const highlighCustomer = disableHighlight ? false : !activity.destinationDescription && !activity.constructionSiteDescription
  const highlighConstructionSite = disableHighlight ? false : !activity.destinationDescription && !!activity.constructionSiteDescription
  const highlighDestination = disableHighlight ? false : !!activity.destinationDescription

  return (
    <IM.View style={[style, IMLayout.flex.row, styles.root]} {...props}>
      <IM.View style={IMLayout.flex.f1} spacing="right">
        {enableSeparator && <Separator />}

        {(!lite || !activity.constructionSiteDescription) && (
          <ActivityDescription
            icon={['fal', 'building']}
            text={activity.customerDescription}
            descId={activity.companyId}
            highLight={highlighCustomer}
            style={styles.descriptions}
            numberOfLines={numberOfLines}
          />
        )}

        {!lite && <ActivityDescription icon={['fal', 'contact-book']} text={activity.contactDescription} style={styles.descriptions} />}

        <ActivityDescription
          icon={['fal', 'hard-hat']}
          text={activity.constructionSiteDescription}
          descId={activity.objectId}
          highLight={highlighConstructionSite}
          style={styles.descriptions}
          numberOfLines={numberOfLines}
        />
        {!lite && (
          <ActivityDescription
            icon={['fal', 'user-helmet-safety']}
            text={activity.objectContactDescription}
            style={styles.descriptions}
            numberOfLines={numberOfLines}
          />
        )}

        {!lite && (
          <ActivityDescription
            icon={['fal', 'location-dot']}
            text={activity.destinationDescription}
            highLight={highlighDestination}
            style={styles.descriptions}
            numberOfLines={numberOfLines}
          />
        )}

        {!lite && <ActivityDescription icon={['fal', 'engine']} text={lotto} descId={lotId} style={styles.descriptions} />}
      </IM.View>

      {isSmallDevice && (
        <ActivityCardInfoIcons activity={activity} showIcons style={styles.icons} showLocationMissingIndicator={showLocationMissingIndicator} />
      )}
    </IM.View>
  )
}

type FormattedDescriptionProps = {
  icon: IconProp
  text: string | undefined
  descId?: number
  highLight?: boolean
  numberOfLines?: number
} & ViewProps
function ActivityDescription({ icon, text, descId, style, highLight, numberOfLines = 1, ...viewProps }: FormattedDescriptionProps) {
  const { theme } = useTheme()

  if (!text?.trim()) return <></>

  return (
    <IM.View style={[IMLayout.flex.row, styles.descriptionContainer, style]} {...viewProps}>
      <IM.View style={AppStyle.center}>
        <IM.Icon color={theme.textDetail} icon={icon} />
      </IM.View>
      {!!descId && (
        <IM.View style={AppStyle.center}>
          <IM.Text secondary style={{ color: theme.textDetail }}>
            {`(${descId})`}
          </IM.Text>
        </IM.View>
      )}
      <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
        <IM.Text primary={highLight} numberOfLines={numberOfLines}>
          {text}
        </IM.Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  root: {
    overflow: 'hidden',
  },
  icons: {
    alignSelf: 'flex-end',
  },
  descriptions: {
    marginBottom: IMLayout.verticalMargin / 2,
  },
  descriptionContainer: {
    gap: 5,
  },
})
