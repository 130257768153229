import { IM, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { apiDtoIds } from '../../../apis/apiCalls'
import { Activity, ActivityTravel, ActivityType } from '../../../apis/types/apiResponseTypes'
import useObjectUtils from '../../../hooks/useObjectUtils'
import { GroupedActivityTravel } from '../../../types'
import TimeUtils from '../../../utils/TimeUtils'
import Separator from '../../Infominds/Separator'
import ActivityTravelRecord from './ActivityTravelRecord'

export type GroupedActivityTravelViewProps = {
  activity: Activity
  travel: GroupedActivityTravel
  separator?: boolean
  activityTypes?: ActivityType[]
  onEdit: (travel: ActivityTravel) => void
  enableChange?: boolean
}

export default function GroupedActivityTravelView({
  activity,
  travel,
  separator,
  activityTypes,
  onEdit,
  enableChange,
}: GroupedActivityTravelViewProps) {
  const activityTravelsObjectUtils = useObjectUtils<ActivityTravel>(apiDtoIds.activityTravels)
  const { language } = useLanguage()
  const date = useMemo(() => !!travel.date && TimeUtils.format(travel.date, language), [language, travel.date])

  return (
    <IM.View>
      {separator && <Separator />}
      {!!date && (
        <IM.View spacing={'horizontal'}>
          <IM.Text primary>{date}</IM.Text>
        </IM.View>
      )}
      {travel.travels.map(travelRecord => (
        <ActivityTravelRecord
          onEdit={() => onEdit(travelRecord)}
          key={activityTravelsObjectUtils.createId(travelRecord)}
          travel={travelRecord}
          activityTypes={activityTypes}
          showType={travelRecord.activityTravelSrvActivityTypeId !== activity.srvActivityTypeId}
          enableChange={enableChange}
        />
      ))}
    </IM.View>
  )
}
