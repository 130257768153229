import React from 'react'

import useArticleSearch from '../../../hooks/activity/useArticleSearch'
import ArticleCatalogueSearchView from './ArticleCatalogueSearchView'
import ArticleFullSearchView from './ArticleFullSearchView'
import ArticleHistorySearchView from './ArticleHistorySearchView'

export default function ActivityArticleSelectionView() {
  const { mode } = useArticleSearch()

  return (
    <>
      {mode === 'all' && <ArticleFullSearchView />}
      {mode === 'catalogue' && <ArticleCatalogueSearchView />}
      {mode === 'history' && <ArticleHistorySearchView />}
    </>
  )
}
