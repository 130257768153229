import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import HeaderWithPressable from './HeaderWithPressable'

interface Props {
  title: string
  description?: string
  icon?: IconProp
  onPress?: () => void
}

export default function HeaderWithSync({ title, icon, description, onPress }: Props) {
  return (
    <IM.View style={styles.container}>
      <HeaderWithPressable title={title} icon={icon} description={description} onPress={onPress} />
      {/* <SyncIcon /> */}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
})
