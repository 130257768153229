import { useLanguage } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityDetailStackParamList } from '../../navigation/types'
import { EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import ActivityTimeCreateOrEditView, { ActivityTimeCreateOrEditViewProps } from '../../views/activities/ActivityTimeCreateOrEditView'

const ActivityTimeCreateOrEditScreen = ({ controller }: ModalScreenProps<ActivityTimeCreateOrEditViewProps>, ref: ForwardedRef<ModalScreenRef>) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityTimeEditScreen'>>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined
  const activity = isModal ? controller.data?.activity : route.params?.activity
  const activityTime = isModal ? controller.data?.activityTime : route.params?.activityTime

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal={isModal}
          title={activityTime ? i18n.t('EDIT_ACTIVITY_TIME') : i18n.t('CREATE_ACTIVITY_TIME')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            {!!activity && (
              <ActivityTimeCreateOrEditView
                ref={createViewRef}
                activity={activity}
                activityTime={activityTime}
                onDone={() => handleGoBack(true)}
                onUploadStatus={setStatus}
              />
            )}
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(ActivityTimeCreateOrEditScreen)
