import { DateUtils, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { Keyboard } from 'react-native'

import { Contract } from '../../apis/types/apiResponseTypes'
import { RepetitionUtils } from '../../components/repetition/RepetitionUtils'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ContractActivity } from '../../types'
import { objectUtils } from '../../utils/objectUtils'
import { ValidationUtils } from '../../utils/ValidationUtils'
import ContractActivityCreationView from '../../views/anagrafics/ContractActivityCreationView'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ContractActivity | undefined>
  onDone: (contractActivity: ContractActivity) => void
  contract?: Contract
}

export default function ContractActivityCreationModal({ controller, onDone, contract }: Props) {
  const { i18n } = useLanguage()

  const startDate = useMemo(() => (contract?.startDate ? DateUtils.dateify(contract?.startDate) : undefined), [contract?.startDate])
  const expiredDate = useMemo(() => (contract?.expiredDate ? DateUtils.dateify(contract?.expiredDate) : undefined), [contract?.expiredDate])
  const defaultState = useMemo(() => ({ activity: {}, repetition: RepetitionUtils.initRepetition(startDate, expiredDate) }), [startDate, expiredDate])
  const [state, setState] = useState<ContractActivity>({ ...defaultState })

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  useEffect(() => {
    if (!controller.isShown) return
    setStatus('done')
    if (controller.data) setState({ activity: { ...controller.data.activity }, repetition: { ...controller.data.repetition } })
    else setState({ ...defaultState })
  }, [controller.isShown])

  useEffect(() => {
    const done = objectUtils.compareObjects(state, controller.data ?? defaultState)
    if (done) {
      setStatus('done')
      return
    }
    if (
      !objectUtils.validateItemRequiredFields(state.activity, ['srvActivityTypeId', 'taskId', 'title']) ||
      (!!state.planned &&
        (!objectUtils.validateItemRequiredFields(state.planned, ['planDateFrom', 'planDateTo']) ||
          !ValidationUtils.timeSpan(state.planned.planDateFrom, state.planned.planDateTo)))
    ) {
      setStatus('mandatoryMissing')
      return
    }
    setStatus('waiting')
  }, [state, controller.data])

  return (
    <BaseServiceModal controller={controller}>
      <Screen>
        <Screen.Header
          goBack={() => handleGoBack(true)}
          backHandlerCallback={() => handleGoBack(true)}
          title={i18n.t('CREATE_ACTIVITIES')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            onDone({ ...state })
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
          isModal
        />
        <Screen.Content>
          <FormProvider>
            <ContractActivityCreationView value={state} setValue={setState} contract={contract} />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </BaseServiceModal>
  )
}
