import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import useOnLayout from '../../hooks/useOnLayout'
import { ThemeColorExpanded } from '../../types'
import { INDICATOR_SIZE, INDICATOR_STROKE_WIDTH, TopAndBottomIndicatorProps } from './Indicator'

const styles = StyleSheet.create({
  view: {
    width: INDICATOR_SIZE,
  },
})

export function IndicatorBottom({ style, color }: TopAndBottomIndicatorProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { layout, onLayout } = useOnLayout()

  return (
    <IM.View onLayout={onLayout} style={[styles.view, style]}>
      <IM.View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          backgroundColor: color ?? theme.timeline.background,
          width: INDICATOR_SIZE,
          height: INDICATOR_SIZE,
          borderRadius: 50,
          position: 'absolute',
          bottom: 0,
        }}
      />
      <IM.View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          backgroundColor: color ?? theme.timeline.background,
          width: INDICATOR_STROKE_WIDTH,
          height: (layout?.height ?? 15) - INDICATOR_SIZE / 2 + 1, // subtract bottom offset from height
          borderRadius: 0,
          position: 'absolute',
          left: INDICATOR_SIZE / 2 - INDICATOR_STROKE_WIDTH / 2,
          bottom: INDICATOR_SIZE / 2,
        }}
      />
    </IM.View>
  )
}
