import { IM, IMLayout, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import React, { createRef, useEffect, useState } from 'react'
import { Animated, Platform } from 'react-native'

import { api } from '../../../apis/apiCalls'
import { UnavailableType } from '../../../apis/types/apiResponseTypes'
import UnavailableTypeCard from '../../../cards/activities/UnavailableTypeCard'
import FlashListData from '../../../components/FlashListData'
import AnimatedButton from '../../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import useSearch from '../../../components/screen/hooks/useSearch'
import { ADD_ACTIVITY_BUTTON_ID } from '../../../constants/ButtonIds'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { AppStyle } from '../../../constants/Styles'
import { useDataProvider } from '../../../dataProvider/hooks/useDataProvider'
import useLayout from '../../../hooks/useLayout'
import useUserSettings from '../../../hooks/useUserSettings'
import UnavailableCreationModal from '../../../modals/anagrafics/UnavailableCreationModal'
import { utils } from '../../../utils/utils'

const buttonAnimationValue = new Animated.Value(0)

export default function UnavailableTypeListView() {
  const { search } = useSearch()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation()
  const { userSettings } = useUserSettings()
  const { isOnline } = useDataProvider()
  const [uuid, setUuid] = useState(utils.generateUuid())
  const createActivityModal = useModalController<UnavailableType | undefined>()

  const {
    item: unavailableTypes,
    loadItem: loadUnavailableTypes,
    loading: loadingUnavailableTypes,
  } = useControlledLoader(api.activities.unavailables.types.getList)
  useEvent({ key: EVENT_KEYS.UPDATED_UNAVAILABLE_TYPES }, () => loadUnavailableTypes())

  const listRef = createRef<FlashList<string | UnavailableType>>()

  useEffect(() => {
    loadUnavailableTypes()
  }, [userSettings])

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [])

  useEffect(() => {
    setUuid(utils.generateUuid())
  }, [loadingUnavailableTypes])

  const renderItem = (elem: ListRenderItemInfo<UnavailableType | string>) => {
    const item = elem.item

    if (typeof item === 'string') {
      return <IM.Text>{item}</IM.Text>
    } else {
      return (
        <UnavailableTypeCard
          style={AppStyle.card}
          spacing="bottom"
          unavailableType={item}
          onPress={() => {
            isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
              ? navigation.navigate('UnavailableTypeCreateOrEdit', { unavailableType: item })
              : createActivityModal.show(item)
          }}
        />
      )
    }
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlashListData
        ref={listRef}
        data={unavailableTypes}
        loading={loadingUnavailableTypes}
        noDataMessage={i18n.t('NO_UNAVAILABLE_TYPE_FOUND')}
        renderItem={renderItem}
        isSearching={search !== ''}
        refresh={loadUnavailableTypes}
        extraData={uuid}
      />
      {!loadingUnavailableTypes && isOnline && (
        <>
          <AnimatedButton
            id={ADD_ACTIVITY_BUTTON_ID}
            value={buttonAnimationValue}
            icon={['fal', 'plus']}
            onPress={() => {
              isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                ? navigation.navigate('UnavailableTypeCreateOrEdit', {})
                : createActivityModal.show(undefined)
            }}
          />
        </>
      )}
      <UnavailableCreationModal controller={createActivityModal} />
    </IM.View>
  )
}
