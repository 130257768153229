import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { Platform } from 'react-native'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'
import { ToastProvider } from 'react-native-toast-notifications'

import CONSTANTS from '../../constants/Constants'

export default function ModalContentWrapper({ children }: PropsWithChildren) {
  const { colorScheme } = useTheme()

  return (
    <SafeAreaProvider
      initialMetrics={initialWindowMetrics}
      style={Platform.select({ web: { colorScheme: colorScheme === 'dark' ? 'dark' : 'light', height: '100%' } })}>
      <IM.AlertProvider useNewModalComponent>
        <ToastProvider
          placement="bottom"
          duration={CONSTANTS.toastVisibilityTime}
          offsetBottom={IMLayout.verticalMargin * (Platform.OS === 'ios' ? 4 : 2)}
          offset={20}
          swipeEnabled={true}>
          {children}
        </ToastProvider>
      </IM.AlertProvider>
    </SafeAreaProvider>
  )
}
