import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import SkeletonText from './SkeletonText'

const SKELETON_ELEM = 35

type Props = {
  count?: number
  elementStyle?: StyleProp<ViewStyle>
  elementHeight?: number
} & Pick<ViewProps, 'spacing'>

export default function SkeletonScrollView({ count = SKELETON_ELEM, elementStyle, elementHeight, ...viewProps }: Props) {
  return (
    <>
      {Array(count)
        .fill(0)
        .map((_, index) => {
          const isFirst = index === 0

          return (
            <IM.View
              key={`SkeletonScrollView-${index}`}
              style={[
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  marginHorizontal: 2 * IMLayout.horizontalMargin,
                  marginTop: isFirst ? 2 * IMLayout.verticalMargin : 0,
                  marginBottom: 1.4 * IMLayout.verticalMargin,
                },
                elementStyle,
              ]}
              {...viewProps}>
              <SkeletonText width="100%" height={elementHeight} />
            </IM.View>
          )
        })}
    </>
  )
}
