import { CardButton, CardProps, IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import type { ConstructionSiteLotto } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import ChangeButton from '../../components/ChangeButton'
import Error from '../../components/Error'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import { InfiniteLoadingType, ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import constructionSiteUtils from '../../utils/constructionSiteUtils'

type Props = CardProps & {
  constructionSiteLotto: ConstructionSiteLotto | undefined | null
  disableIcon?: boolean
  leftText?: string
  enableChange?: boolean
  loading?: InfiniteLoadingType
  hideButtons?: boolean
  onChange?: () => void
  lite?: boolean
}

const ConstructionSiteLottoCard = memo(function ConstructionSiteLottoCard({
  constructionSiteLotto,
  disableIcon,
  leftText,
  selected,
  enableChange,
  hideButtons,
  loading = false,
  onChange,
  lite,
  ...other
}: Props) {
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  const inactive = false
  const buttons: CardButton[] = []

  const opacity = inactive ? appUtils.getOpacity(colorScheme) : 1

  const title = useMemo(() => constructionSiteUtils.getLottoTitle(constructionSiteLotto), [constructionSiteLotto])

  return (
    <IM.Card
      head={<CardLeftTitle color={selected && theme.general.info} text={leftText} />}
      buttons={
        lite
          ? undefined
          : {
              buttons: hideButtons || loading !== false ? [] : buttons,
            }
      }
      {...other}
      style={[other.style, { opacity }]}>
      {loading === false || loading === 'init' ? (
        <IM.View style={styles.content}>
          {constructionSiteLotto ? (
            <>
              <TextWithIcon
                secondary={inactive}
                icon={disableIcon ? undefined : ['fal', 'briefcase']}
                iconSize={20}
                style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }]}>
                {title}
              </TextWithIcon>
            </>
          ) : (
            <IM.Text secondary>{i18n.t('NO_CONSTRUCTION_SITE_LOTTO_SELECTED')}</IM.Text>
          )}
          {enableChange && <ChangeButton onPress={() => onChange?.()} />}
        </IM.View>
      ) : (
        <>
          {loading === 'catched' ? (
            <>
              <Error />
              {enableChange && <ChangeButton onPress={() => onChange?.()} />}
            </>
          ) : (
            <>
              <SkeletonText width="70%" />
              <SkeletonText spacing="top" width="100%" />
            </>
          )}
        </>
      )}
    </IM.Card>
  )
})

export default ConstructionSiteLottoCard

const styles = StyleSheet.create({
  content: { flex: 1, justifyContent: 'space-between' },
})
