import { IM, IMLayout, useLanguage, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity, ActivityTime } from '../../../apis/types/apiResponseTypes'
import ActivityCardIcon from '../../../cards/activityList/components/ActivityCardIcon'
import ActivityCardTitle from '../../../cards/activityList/components/ActivityCardTitle'
import { AppStyle } from '../../../constants/Styles'
import TimeUtils from '../../../utils/TimeUtils'
import TextTable from '../../TextTable'

export type PlannedEmployeeRecordProps = { time: ActivityTime; activity: Activity } & Pick<ViewProps, 'spacing' | 'style'>

export default function PlannedEmployeeRecord({ time, activity, style, ...viewProps }: PlannedEmployeeRecordProps) {
  const { i18n } = useLanguage()

  const startTime = useMemo(() => TimeUtils.formatTimeStamp(time.startTime) ?? '', [time.startTime])
  const stopTime = useMemo(() => TimeUtils.formatTimeStamp(time.stopTime) ?? '', [time.stopTime])
  const duration = useMemo(() => TimeUtils.formatTimeStamp(time.total) ?? '', [time.total])
  const showTime = !!startTime && !!stopTime

  return (
    <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }, style]} {...viewProps}>
      <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
        <ActivityCardIcon activity={activity} type="normal" size={25} />
        <ActivityCardTitle activity={activity} spacing={'left'} />
      </IM.View>
      {showTime && (
        <IM.View>
          <TextTable
            style={[AppStyle.justifyContentStart]}
            textViewStyle={styles.textWidth}
            text={startTime}
            position={'top'}
            alignIndicator="left"
          />
          <TextTable
            style={[AppStyle.justifyContentStart]}
            textViewStyle={styles.textWidth}
            text={stopTime}
            position={'bottom'}
            alignIndicator="left"
          />
        </IM.View>
      )}
      {!!duration && !showTime && (
        <IM.View style={[styles.infoView, showTime && styles.alignEnd]}>
          <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
            <IM.Text secondary>{i18n.t('DURATION')}:</IM.Text>
            <IM.Text numberOfLines={1} primary>
              {duration}
            </IM.Text>
          </IM.View>
        </IM.View>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  infoView: {
    justifyContent: 'center',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  textWidth: {},
})
