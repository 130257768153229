import React, { useRef } from 'react'

import { Activity } from '../../apis/types/apiResponseTypes'
import useMap from '../../components/map/hooks/useMap'
import useUserSettings from '../../hooks/useUserSettings'
import { activityUtils } from '../../utils/ActivityUtils'
import ActivityListView, { ActivityListProps } from './ActivityListView'

type Props = {
  showSmallDeviceCards?: boolean
  onActivityPressed: (activity: Activity) => void
} & ActivityListProps

export default function ActivityMapList({ onActivityPressed, ...others }: Props) {
  const { user } = useUserSettings()
  const { selectedItem, moveMapToItem, setSelectedItem } = useMap<Activity>()
  const lastSelectedRef = useRef<Activity | null>(null)

  function handleTicketPressed(activity: Activity) {
    lastSelectedRef.current = activity
    if (activityUtils.compareActivities(selectedItem, activity)) {
      onActivityPressed(activity)
      return
    }

    moveMapToItem(activity)
    setSelectedItem(activity)
  }

  return (
    <ActivityListView
      onActivityPressed={handleTicketPressed}
      showTabs={user.can.see.unassignedActivities}
      selectedActivity={selectedItem}
      type="normal"
      {...others}
    />
  )
}
