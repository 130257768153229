export const USER_COLORS = [
  { primary: '#AA82FF', secondary: '#5428B4' },
  { primary: '#8894FF', secondary: '#3B47AF' },
  { primary: '#7CB1FF', secondary: '#164FA4' },
  { primary: '#57C1FD', secondary: '#2E627F' },
  { primary: '#00C8A4', secondary: '#006452' },
  { primary: '#2DD182', secondary: '#005C40' },
  { primary: '#FF6B6B', secondary: '#8A3535' },
  { primary: '#FF806F', secondary: '#8E3F34' },
  { primary: '#FF9154', secondary: '#A74A15' },
  { primary: '#FDA757', secondary: '#8F4500' },
  { primary: '#FFC429', secondary: '#906903' },
]
