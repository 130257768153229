import { IM, SpacingProps, useLanguage, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Technician } from '../../apis/types/apiResponseTypes'
import EmployeeCard from '../../cards/common/EmployeeCard'
import { TechniciansFilterConfig } from '../../constants/Filters'
import useObjectUtils from '../../hooks/useObjectUtils'
import { ListSection } from '../../types'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import { filterUtils } from '../../utils/FilterUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  spacing?: SpacingProps
  selectedTechnicians?: Technician[]
  onChange: (values: Technician[] | undefined) => void
  filter?: (technician: Technician) => boolean
  noTitle?: boolean
  taskId?: number
} & Pick<
  SelectInputProps<Technician>,
  'disableBorderRadius' | 'required' | 'hideNoSelectionItem' | 'disableFastInput' | 'editable' | 'placeholder' | 'disabledInfo' | 'title' | 'subTitle'
>

export default function MultiTechnicianSelector({ onChange, disableFastInput, selectedTechnicians, filter, noTitle, taskId, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const objectUtils = useObjectUtils<Technician>(apiDtoIds.employees)
  const { item: allData, loadItem, loading } = useControlledLoader(api.technicians.getList)
  const data = useMemo(() => (filter ? allData?.filter(filter) : allData), [allData, filter])
  const filteredData = useMemo(
    () => filterUtils.filterItemsBySearch(data, search, TechniciansFilterConfig.searchKeys).filter(t => !t.disabled),
    [data, search]
  )
  const sortedData = useMemo(() => filteredData?.sort(EmployeeUtils.sortTechnicians) ?? [], [filteredData])

  useEffect(() => {
    refresh()
  }, [taskId])

  const refresh = () => loadItem({ taskId })

  useEffect(() => {
    if (!selectedTechnicians?.length || loading !== false) return
    const techniciansToRemove: Technician[] = []
    selectedTechnicians.forEach(st => {
      if (!allData?.find(dt => objectUtils.compare(st, dt) && !dt.disabled)) techniciansToRemove.push(st)
    })
    if (techniciansToRemove.length) onChange(selectedTechnicians.filter(st => !techniciansToRemove.find(t => objectUtils.compare(st, t))))
  }, [allData])

  const render = ({ item, index }: SectionListRenderItemInfo<Technician, ListSection<Technician>>, onPress?: () => void) => {
    return (
      <>
        {item.technicianType === 'supplier' && (index === 0 || sortedData[index - 1].technicianType === 'employee') && (
          <IM.View spacing={['horizontal', 'bottom']}>
            <IM.Text primary>{i18n.t('SUPPLIER')}</IM.Text>
          </IM.View>
        )}
        <EmployeeCard
          employee={item}
          selected={!!selectedTechnicians?.find(selected => objectUtils.compare(item, selected))}
          onPress={onPress}
          spacing={['horizontal', 'bottom']}
        />
      </>
    )
  }

  const selectedText = useMemo(
    () =>
      EmployeeUtils.sort(selectedTechnicians)
        ?.map(se => `• ${EmployeeUtils.getName(se)}`)
        .join('\n') ?? '',
    [selectedTechnicians]
  )

  function handleEmployeeSelected(value: Technician | undefined) {
    if (!value) {
      onChange([])
      return
    }
    if (selectedTechnicians?.find(st => objectUtils.compare(st, value))) {
      onChange(selectedTechnicians.filter(st => !objectUtils.compare(st, value)))
      return
    }
    onChange(Utils.keepUniques([...(selectedTechnicians ?? []), value], q => objectUtils.createId(q)))
  }

  return (
    <SelectInput
      id={apiDtoIds.employees}
      data={sortedData ?? []}
      value={selectedTechnicians?.find(st => !!st)}
      loading={loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={noTitle ? undefined : i18n.t('TECHNICIANS')}
      screenTitle={i18n.t('TECHNICIANS')}
      noDataMessage={i18n.t('NO_EMPLOYEE_FOUND')}
      renderItem={render}
      onChange={handleEmployeeSelected}
      renderSelectedString={selectedText}
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      keepOpenOnChange
      multiline
      {...props}
    />
  )
}
