import { IM, IMStyle, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import CardLeftTitle from '../../../components/card/left/CardLeftTitle'

type Props = {
  device: string
  spacing?: SpacingProps
  numberOfLines?: number
  style?: StyleProp<ViewStyle>
}

const ActivityDetailDeviceCard = memo(function ActivityDetailDeviceCard({ device, spacing, numberOfLines, style }: Props) {
  const { i18n } = useLanguage()

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('LOTTO_DEVICE')} />} spacing={spacing} style={[styles.card, style]}>
      <IM.View spacing={'none'}>
        <IM.Text numberOfLines={numberOfLines}>{device}</IM.Text>
      </IM.View>
    </IM.Card>
  )
})

export default ActivityDetailDeviceCard

const styles = StyleSheet.create({
  card: {
    minHeight: 75,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
    fontWeight: IMStyle.typography.fontWeightBold,
  },
})
