import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { TimeSlot } from '../../apis/types/apiResponseTypes'
import TimeSlotCard from '../../cards/activities/TimeSlotCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ListSection } from '../../types'
import TimeUtils from '../../utils/TimeUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  initialValue?: number
} & Pick<SelectInputProps<TimeSlot>, 'disabledInfo' | 'required' | 'disableFastInput' | 'spacing' | 'editable' | 'onChange' | 'subTitle'>

export default function TimeSlotSelector({ onChange, disableFastInput, initialValue, ...props }: Props) {
  const { i18n } = useLanguage()
  const { languageSelect } = useLanguageSelect()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<TimeSlot>()
  const { item: data, loadItem, loading } = useControlledLoader(api.activities.timeSlots.getList)
  const filteredData = useDataSearchFilter(data, search, ['titleDe', 'titleEn', 'titleIt', 'descriptionDe', 'descriptionIt', 'descriptionEn'])

  useEffect(() => {
    setSelected(undefined)
    refresh()
  }, [])

  const refresh = () => loadItem({})

  useEffect(() => {
    if (!initialValue) return
    const foundItem = data?.find(d => d.srvTimeSlotId === initialValue)
    if (foundItem) setSelected(foundItem)
  }, [data])

  const render = ({ item }: SectionListRenderItemInfo<TimeSlot, ListSection<TimeSlot>>, onPress?: () => void) => {
    return (
      <TimeSlotCard timeSlot={item} selected={item.srvTimeSlotId === selected?.srvTimeSlotId} onPress={onPress} spacing={['horizontal', 'bottom']} />
    )
  }

  const handleOnChange = (newValue: TimeSlot | undefined) => {
    setSelected(newValue)
    onChange(newValue)
  }

  return (
    <SelectInput
      id={apiDtoIds.timeSlots}
      data={filteredData}
      value={selected}
      loading={loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={i18n.t('TIME_SLOT')}
      screenTitle={i18n.t('TIME_SLOTS')}
      noDataMessage={i18n.t('NO_TIME_SLOT_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item =>
        `${languageSelect({ en: item.titleEn, de: item.titleDe, it: item.titleIt }) ?? ''} (${
          item.hourFrom && item.hourTo ? TimeUtils.formateTimeStampFromTo(item.hourFrom, item.hourTo) : '-'
        }) ${item.increasePercentage ? `+${item.increasePercentage}%` : ''}`
      }
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      {...props}
    />
  )
}
