import { useContext } from 'react'

import { DetailContext } from '../contexts/DetailContext'

export default function useDetail<T extends object>() {
  const context = useContext(DetailContext)

  if (context === undefined) {
    throw new Error('useDetail can only be called inside DetailProvider')
  }

  function setDetail(detailToSet: T | undefined) {
    context?.setDetail(detailToSet)
  }

  return { detail: context.detail as T | undefined, setDetail }
}

export function useIsDetail() {
  const context = useContext(DetailContext)
  return !!context
}
