import { Platform, Vibration } from 'react-native'

export default function useVibrationFeedback() {
  function vibrationFeedback() {
    if (__DEV__) return
    // android only for the moment, since ios vibration minimum is too long for a simple feedback
    if (Platform.OS !== 'android') return
    Vibration.vibrate(50)
  }

  return { vibrationFeedback }
}
