import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'

import { api } from '../../../apis/apiCalls'
import { ActivityType } from '../../../apis/types/apiResponseTypes'
import { useDragAndDrop } from '../../../contexts/DragAndDropContext'
import useExtendedTheme from '../../../hooks/useExtendedTheme'
import Button from '../../Button'
import useRequest from '../../Infominds/hooks/useRequest'
import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'
import { SCREEN_CONSTANTS } from '../constants/constants'
import DefaultHeader, { DefaultHeaderProps } from './default/DefaultHeader'

type Props = { enabled?: boolean } & Pick<DefaultHeaderProps, 'title' | 'subtitle'>

export default function DragAndDropHeader({ title, subtitle, enabled }: Props) {
  const { dragActive, setDragActive, reorderedData, changed, setChanged } = useDragAndDrop<ActivityType>()
  const { request, loading } = useRequest(api.activities.types.updateOrder, { showErrorAlert: true, onSuccess: () => setChanged(false) })

  const { i18n } = useLanguage()

  const { theme } = useExtendedTheme()

  function onSave() {
    setDragActive(false)
    if (!reorderedData?.length) return
    request({ activityTypeId: reorderedData.map(r => r.srvActivityTypeId) })
  }

  function onCancel() {
    setDragActive(false)
    setChanged(false)
  }

  useEffect(() => {
    if (!enabled) setDragActive(false)
  }, [enabled])

  return (
    <DefaultHeader style={styles.container} title={title} subtitle={subtitle}>
      {enabled && (
        <IM.View style={[styles.rightContainer]}>
          <IM.View>
            <IM.View>
              {(!!dragActive || loading === 'reloading') && (
                <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
                  <Button color={IMStyle.palette.red} title={i18n.t('CANCEL')} onPress={onCancel} disabled={loading === 'reloading'} />
                  <Button
                    disabled={!changed}
                    color={IMStyle.palette.tint}
                    title={i18n.t('SAVE')}
                    onPress={onSave}
                    loading={loading === 'reloading'}
                  />
                </IM.View>
              )}
              {!dragActive && loading !== 'reloading' && (
                <>
                  <Pressable
                    onPress={() => setDragActive(true)}
                    style={[styles.tag, { backgroundColor: theme.header.detail.background }]}
                    noContentStyling>
                    <TextWithIcon
                      numberOfLines={1}
                      color={IMStyle.palette.white}
                      icon={['fal', 'list-ol']}
                      viewStyle={[styles.text, styles.itemText, styles.tabSelectorItem]}>
                      {i18n.t('REORDER')}
                    </TextWithIcon>
                  </Pressable>
                </>
              )}
            </IM.View>
          </IM.View>
        </IM.View>
      )}
    </DefaultHeader>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row' },
  rightContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  tabSelectorItem: {
    paddingHorizontal: 12,
  },

  text: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tag: {
    flexDirection: 'row',
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
    borderRadius: SCREEN_CONSTANTS.headerRadius,
  },
  itemText: {
    height: '100%',
    paddingHorizontal: 5,
  },
})
