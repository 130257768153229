import { IM, IMLayout, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { TextStyle } from 'react-native'

import { Address } from '../../apis/types/apiResponseTypes'
import CountrySelector from '../selectors/CountrySelector'
import BaseTextInputTitle from './baseTextInput/BaseTextInputTitle'
import TextInput from './TextInput'

export interface AddressInputProps {
  address: Address | undefined
  onValueChange: (value: Address) => void
  title?: string
  titleFontWeight?: TextStyle['fontWeight']
  editable?: boolean
  spacing?: SpacingProps
}

const AddressInput = memo(function AddressInput({ address, title, titleFontWeight, editable, spacing, onValueChange }: AddressInputProps) {
  const { i18n } = useLanguage()

  function handleChange(value: Partial<Address>) {
    onValueChange({ ...address, ...value })
  }

  return (
    <IM.View spacing={spacing}>
      {title && <BaseTextInputTitle title={title} fontWeight={titleFontWeight} />}
      <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]} spacing={'bottom'}>
        <IM.View style={IMLayout.flex.f2}>
          <TextInput
            title={i18n.t('ADDRESS_STREET')}
            placeholder={i18n.t('ADDRESS_STREET')}
            value={address?.street}
            onChangeText={value => handleChange({ street: value })}
            editable={editable}
          />
        </IM.View>
        <IM.View style={IMLayout.flex.f1}>
          <TextInput
            style={IMLayout.flex.f1}
            title={i18n.t('ADDRESS_ZIP')}
            placeholder={i18n.t('ADDRESS_ZIP')}
            value={address?.zip}
            onChangeText={value => handleChange({ zip: value })}
            editable={editable}
          />
        </IM.View>
      </IM.View>
      <TextInput
        title={i18n.t('ADDRESS_PLACE')}
        placeholder={i18n.t('ADDRESS_PLACE')}
        value={address?.place}
        onChangeText={value => handleChange({ place: value })}
        editable={editable}
        spacing={'bottom'}
      />
      <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]} spacing={'bottom'}>
        <IM.View style={IMLayout.flex.f1}>
          <TextInput
            title={i18n.t('ADDRESS_PROVINCE')}
            placeholder={i18n.t('ADDRESS_PROVINCE')}
            value={address?.province}
            onChangeText={value => handleChange({ province: value.toUpperCase() })}
            editable={editable}
          />
        </IM.View>
        <IM.View style={IMLayout.flex.f1}>
          <CountrySelector value={address?.state} onChange={value => handleChange({ state: value?.countryId })} editable={editable} />
        </IM.View>
      </IM.View>
    </IM.View>
  )
})

export default AddressInput
