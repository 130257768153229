import React, { memo } from 'react'
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd'

import AbsenceCard, { AbsenceCardProps } from './AbsenceCard'

interface Props extends AbsenceCardProps {
  isDragging?: boolean
  connectDragSource?: ConnectDragSource
  connectDragPreview?: ConnectDragPreview
}

const DndAbsenceCard = memo(function DndAbsenceCard({ isDragging, connectDragSource, connectDragPreview, ...props }: Props) {
  const dragContent = (
    <li>
      <AbsenceCard {...props} />
    </li>
  )

  if (connectDragPreview === undefined || connectDragSource === undefined) return null

  return isDragging ? dragContent : <div>{connectDragPreview(connectDragSource(dragContent))}</div>
})

export default DndAbsenceCard
