import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import ActivityArticleEditScreen from '../../screens/activity/ActivityArticleEditScreen'
import { ActivityArticleEditScreenProps, ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ActivityArticleEditScreenProps>
}

export default function ActivityArticleEditModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ActivityArticleEditScreen ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}
