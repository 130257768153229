import { LiteAsset } from '@infominds/react-native-media-lite'
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { ActivityArticle, ActivityArticleMedia } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useObjectUtils from '../../hooks/useObjectUtils'
import { LoadingType } from '../../types'
import { articleUtils } from '../../utils/ArticleUtils'

type ArticleMediaContextProps = {
  media: ActivityArticleMedia[] | undefined
  assets: LiteAsset[]
  setAssets: React.Dispatch<React.SetStateAction<LiteAsset[]>>
  newAssets: LiteAsset[]
  setNewAssets: React.Dispatch<React.SetStateAction<LiteAsset[]>>
  loadingMedia: LoadingType
  reloadMedia(): void
}

export const ArticleMediaContext = createContext<ArticleMediaContextProps | null>(null)

export function ArticleMediaProvider({ children, article }: { article: ActivityArticle | undefined } & PropsWithChildren) {
  const articleObjectUtils = useObjectUtils<ActivityArticle>(apiDtoIds.activityArticles)
  const { item: media, loadItem: loadMedia, loading: loadingMedia, setItem: setMedia } = useControlledLoader(api.activities.articles.media.get)

  const [assets, setAssets] = useState<LiteAsset[]>([])
  const [newAssets, setNewAssets] = useState<LiteAsset[]>([])

  useEffect(reloadMedia, [article])
  function reloadMedia() {
    if (!article) {
      setMedia([])
      return
    }
    loadMedia(articleObjectUtils.createRequestObject(article))
  }

  useEffect(() => {
    if (!media?.length) return

    articleUtils.convertMediaToLiteAssets(media).then(setAssets).catch(console.error)
  }, [media])

  return (
    <ArticleMediaContext.Provider value={{ media, assets, setAssets, newAssets, setNewAssets, loadingMedia, reloadMedia }}>
      {children}
    </ArticleMediaContext.Provider>
  )
}

export function useArticleMedia() {
  const context = React.useContext(ArticleMediaContext)
  if (!context) {
    throw new Error('useArticleMedia must be used within a ArticleMediaProvider')
  }
  return context
}
