import { useEffect } from 'react'

import { api } from '../apis/apiCalls'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'

export default function useErgoTask(taskId: number | undefined) {
  const { item: ergoTask, loading: loadingErgoTask, loadItem: loadErgoTask, setItem: setErgoTask } = useControlledLoader(api.tasks.getDetail)

  useEffect(reloadErgoTask, [taskId])

  function reloadErgoTask() {
    if (taskId) loadErgoTask({ taskId })
    else setErgoTask(undefined)
  }

  return { ergoTask, loadingErgoTask, reloadErgoTask }
}
