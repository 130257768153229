import { DateUtils, IM, useLanguage } from '@infominds/react-native-components'
import { startOfDay } from 'date-fns'
import React, { useEffect, useMemo } from 'react'

import { ActivityGroupBy, ErgoTaskType, GetActivityRequest, GetActivityRequestAssignedUser } from '../../../apis/types/apiRequestTypes'
import { Activity, ActivityState, ActivityTime } from '../../../apis/types/apiResponseTypes'
import PlannedEmployeeRecord from '../../../components/activity/times/PlannedEmployeeRecord'
import Separator from '../../../components/Infominds/Separator'
import LoadingStateView from '../../../components/LoadingStateView'
import NoEntry from '../../../components/NoEntry'
import { ActivityFilterConfig } from '../../../constants/Filters'
import { AppStyle } from '../../../constants/Styles'
import useActivities from '../../../hooks/activity/useActivities'
import { PlannedActivityEmployee } from '../../../types'
import { ActivityTimeUtils } from '../../../utils/ActivityTimeUtils'
import TimeUtils from '../../../utils/TimeUtils'

export type TechnicianPlannedActivitiesViewProps = { employeeId: number; filterTime?: PlannedActivityEmployee }

type ExtendedActivityTime = ActivityTime & { activity: Activity }

type GroupedList = {
  date: string
  times: ExtendedActivityTime[]
}

export default function TechnicianPlannedActivitiesView({ employeeId, filterTime }: TechnicianPlannedActivitiesViewProps) {
  const { i18n, language } = useLanguage()
  const filter = useMemo<GetActivityRequest>(
    () => ({
      assignedUserId: [employeeId],
      planDateFrom: startOfDay(new Date()).toISOString(),
      taskType: ErgoTaskType.Service,
      groupBy: ActivityGroupBy.PlanDate,
    }),
    [employeeId]
  )

  const {
    activities: activities,
    loadActivities,
    loadingActivities,
  } = useActivities({
    enabled: true,
    assignedMode: GetActivityRequestAssignedUser.AssignedTo,
    states: [ActivityState.Open],
    filter,
    filterConfig: ActivityFilterConfig,
    chunkSize: 1000000,
  })
  useEffect(() => loadActivities(), [employeeId])

  const plannedActivityTimes = useMemo(() => {
    const grouped = activities.reduce<GroupedList[]>((result, activity) => {
      const user = activity.assignedUser?.find(au => au.id === employeeId && au.technicianType === 'employee')
      if (user?.planTimes) {
        // filter and map times of user
        const times = user.planTimes
          .filter(pt => !filterTime?.srvActivityId || pt.planDateFrom !== filterTime.planDateFrom || pt.planDateTo !== filterTime.planDateTo)
          .map(
            pt =>
              ({
                startTime: TimeUtils.format(pt.planDateFrom, language, 'p'),
                stopTime: TimeUtils.format(pt.planDateTo, language, 'p'),
                technician: user,
                timeDate: pt.planDateFrom,
                activity: activity,
              }) as ExtendedActivityTime
          )
          .sort(ActivityTimeUtils.sortPlanTimes)

        if (times.length) {
          const title = activity.planDate ? TimeUtils.format(DateUtils.dateify(activity.planDate), language) : ''
          const group = result.find(g => g.date === title)
          if (group) {
            group.times.push(...times)
          } else {
            result.push({ date: title, times })
          }
        }
      }
      return result
    }, [])

    return grouped.reduce<(ExtendedActivityTime | string)[]>((result, element) => {
      if (!element.times.length) return result
      result.push(element.date)
      result.push(...element.times.sort(ActivityTimeUtils.sortPlanTimes))
      return result
    }, [])
  }, [activities])

  return (
    <IM.View>
      <IM.View style={AppStyle.center}>
        <IM.Text primary>{i18n.t('PLANNED_ACTIVITIES')}</IM.Text>
      </IM.View>
      <LoadingStateView spacing={'vertical'} loading={loadingActivities} />
      {plannedActivityTimes.length === 0 && !loadingActivities && <NoEntry description={i18n.t('NO_ACTIVITIES_PLANNED')} />}
      {plannedActivityTimes.map((item, index) => (
        <IM.View
          key={`PlannedTime${typeof item === 'string' ? item : `${item.timeDate ?? ''}-${item.startTime ?? ''}-${item.stopTime ?? ''}`}`}
          spacing={typeof item === 'string' ? ['top'] : undefined}>
          {typeof item === 'string' && (
            <IM.View>
              {!!index && <Separator />}
              <IM.Text>{item}</IM.Text>
            </IM.View>
          )}
          {typeof item === 'object' && <PlannedEmployeeRecord time={item} activity={item.activity} spacing={'top'} />}
        </IM.View>
      ))}
    </IM.View>
  )
}
