import { useLanguage } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'
import { Keyboard } from 'react-native'

import LoadingStateView from '../../components/LoadingStateView'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { ArticleMediaProvider } from '../../contexts/activity/ArticleMediaContext'
import { FormProvider } from '../../contexts/FormContext'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityDetailStackParamList } from '../../navigation/types'
import { ActivityMediaArticleScreenProps, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import ActivityMediaArticleCreateOrEditView from '../../views/activities/articles/ActivityMediaArticleCreateOrEditView'

const ActivityMediaArticleScreen = ({ controller }: ModalScreenProps<ActivityMediaArticleScreenProps>, ref: ForwardedRef<ModalScreenRef>) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityMediaArticleScreen'>>()
  const isModal = !!controller
  const params = isModal ? controller.data : route.params
  const { detail, loadingDetail } = useActivityDetail(params)

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const modalTitle = useMemo(() => {
    if (!params?.editable && params?.article?.title) return params?.article?.title
    return params?.article ? i18n.t('EDIT_MEDIA_ARTICLE') : i18n.t('ADD_MEDIA_ARTICLE')
  }, [params?.article, params?.editable])

  return (
    <ArticleMediaProvider article={params?.article}>
      <SearchProvider>
        <Screen transparent={isModal}>
          <Screen.Header
            goBack={() => handleGoBack()}
            backHandlerCallback={handleGoBack}
            isModal={isModal}
            title={modalTitle}
            onRightIconPress={() => {
              Keyboard.dismiss()
              createViewRef.current?.handleUpload()
            }}
            rightIcon={['fal', 'check']}
            disable={status === 'done' || status === 'mandatoryMissing'}
            loading={status === 'uploading'}
          />
          <Screen.Content>
            <FormProvider>
              {loadingDetail && <LoadingStateView loading={true} />}
              {!!detail && !loadingDetail && (
                <ActivityMediaArticleCreateOrEditView
                  ref={createViewRef}
                  activity={detail}
                  article={params?.article}
                  onDone={() => handleGoBack(true)}
                  onUploadStatus={setStatus}
                  mediaArticleCode={params?.mediaArticleCode}
                  editable={(params?.editable || !params?.article) && !params?.article?.refer2DocId}
                />
              )}
            </FormProvider>
          </Screen.Content>
        </Screen>
      </SearchProvider>
    </ArticleMediaProvider>
  )
}

export default forwardRef(ActivityMediaArticleScreen)
