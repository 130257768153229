import { IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import ArticleSearchModeSelector from '../../selectors/ArticleSearchModeSelector'
import { DetailHeaderType } from '../types'
import DefaultSearchDetailHeader from './default/DefaultSearchDetailHeader'

type ArticleSelectionDetailHeaderProps = {
  hasCatalogue?: boolean
  hasHistory?: boolean
  forceLayout?: ForceLayoutType
}
export default function ArticleSelectionDetailHeader({ hasCatalogue, hasHistory, forceLayout }: ArticleSelectionDetailHeaderProps) {
  const { isSmallDevice } = useLayout(false, forceLayout)
  return (
    <IM.View style={[styles.container]} spacing={'horizontal'}>
      <DefaultSearchDetailHeader enableQR disableAutofocus />
      {!isSmallDevice && <ArticleSearchModeSelector hasCatalogue={hasCatalogue} hasHistory={hasHistory} />}
    </IM.View>
  )
}

ArticleSelectionDetailHeader.type = DetailHeaderType.SEARCH

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
})
