import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { TimeSlot } from '../../apis/types/apiResponseTypes'
import TimeSlotCreationScreen from '../../screens/activity/timeSlots/TimeSlotCreationScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<TimeSlot | undefined>
}

export default function TimeSlotCreationModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <TimeSlotCreationScreen ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}
