import { useEvent } from '@infominds/react-native-components'
import { useEffect } from 'react'

import { api } from '../../apis/apiCalls'
import { EmailModel } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { EVENT_KEYS } from '../../constants/EventKeys'

export default function useEmailModels(srvActivityTypeId?: number, srvEmailId?: number, onLoaded?: (emailModels: EmailModel[] | undefined) => void) {
  const {
    item: emailModels,
    loadItem: loadEmailModels,
    loading: loadingEmailModels,
  } = useControlledLoader(api.email.models.getList, { onLoadedCallback: onLoaded })

  useEffect(reloadEmailModels, [srvActivityTypeId, srvEmailId])
  useEvent({ key: EVENT_KEYS.UPDATED_EMAIL_MODELS }, () => reloadEmailModels())

  function reloadEmailModels() {
    loadEmailModels({ srvEmailId, srvActivityTypeId })
  }

  return {
    emailModels,
    loadingEmailModels,
    reloadEmailModels,
  }
}
