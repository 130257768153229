import { IM, IMLayout } from '@infominds/react-native-components'
import React, { memo, useCallback, useMemo } from 'react'

import Separator from '../../../components/Infominds/Separator'
import ListSpacer from '../../../components/ListSpacer'
import ScrollViewData from '../../../components/ScrollViewData'
import CardSkeleton from '../../../components/skeleton/CardSkeleton'
import CONSTANTS from '../../../constants/Constants'
import useArticleSearch from '../../../hooks/activity/useArticleSearch'
import useLayout from '../../../hooks/useLayout'
import { articleUtils } from '../../../utils/ArticleUtils'
import ArticleListView from './ArticleListView'
import ArticlePriceListNodeView from './ArticlePriceListNodeView'

function ArticleCatalogueSearchView() {
  const {
    search,
    articles,
    priceList,
    loadingPriceList,
    refreshPriceList,
    selectedCategory,
    priceListArticles,
    loadingPriceListArticles,
    refreshArticles,
    loadMoreArticles,
    forceLayout,
  } = useArticleSearch()
  const { isLargeDevice } = useLayout(false, forceLayout)

  const articlesToDisplay = useMemo(() => {
    if (!isLargeDevice) return []
    if (search) {
      if (selectedCategory) return articles.filter(a => articleUtils.compareGoodCategories(a.goodCategory, selectedCategory))
      return articleUtils.filterArticlesByPriceList(articles, priceList)
    }
    if (!selectedCategory) return []
    return priceListArticles?.filter(a => articleUtils.compareGoodCategories(a.goodCategory, selectedCategory)) ?? []
  }, [isLargeDevice, articles, search, selectedCategory, priceList, priceListArticles])

  const Skeleton = useCallback(
    (index: number) => (
      <CardSkeleton
        key={`Skeleton${index}`}
        style={{ marginLeft: (index % 3) * CONSTANTS.articleNodeListIndentWidth }}
        height={45}
        spacing={['bottom', 'horizontal']}
      />
    ),
    []
  )

  return (
    <IM.View style={[IMLayout.flex.f1, isLargeDevice && IMLayout.flex.row]}>
      <IM.View style={IMLayout.flex.f2}>
        <ScrollViewData loading={loadingPriceList} refresh={refreshPriceList} style={IMLayout.flex.f1} skeletonComponent={Skeleton}>
          {!!priceList?.categoryList && <ArticlePriceListNodeView categories={priceList?.categoryList} />}
          <ListSpacer />
        </ScrollViewData>
      </IM.View>
      {isLargeDevice && (
        <>
          <Separator type="vertical" />
          <IM.View style={IMLayout.flex.f3}>
            <ArticleListView
              articles={articlesToDisplay}
              loadingArticles={loadingPriceListArticles}
              refreshArticles={refreshArticles}
              loadMoreArticles={loadMoreArticles}
            />
          </IM.View>
        </>
      )}
    </IM.View>
  )
}

export default memo(ArticleCatalogueSearchView)
