import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import { StyleSheet } from 'react-native'
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist'

import Pressable from '../../components/Infominds/Pressable'
import { Screen } from '../../components/screen/Screen'
import useMenu from '../../hooks/useMenu'
import useUserSettings from '../../hooks/useUserSettings'
import { AllBottomTabRoutesParamList, TabNavigationScreen } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import navigationUtils from '../../utils/navigationUtils'

export default function EditNavigationScreen() {
  const navigation = useNavigation()
  const { menuItems } = useUserSettings()
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { tabs, reorder, maxDynamicElements } = useMenu<keyof AllBottomTabRoutesParamList>()

  // TODO RT: there is a bug when exiting rapidly outside while holding the press.
  // The component is not activated but when pressing in again, the previously pressed component shift to the previous position

  const renderItem = useCallback(
    ({ item, drag, isActive }: RenderItemParams<TabNavigationScreen<keyof AllBottomTabRoutesParamList>>) => {
      const bckgColor = isActive ? theme.drag.active : 'transparent'

      return (
        <>
          {item.isFixed && (
            <IM.Text
              style={[
                styles.moreText,
                {
                  color: theme.textDetail,
                },
              ]}>
              {i18n.t('TAB_MORE')}
            </IM.Text>
          )}
          {!item.isFixed && (
            <Pressable
              onLongPress={drag}
              delayLongPress={0}
              disabled={item.isFixed ?? isActive}
              style={[styles.rowItem, { backgroundColor: bckgColor }]}>
              <IM.View style={styles.itemContainer}>
                <IM.View style={styles.itemIcon}>
                  <IM.Icon icon={item.icon} size={item.iconSize ?? 0 * 1.2} />
                </IM.View>
                <IM.View spacing="left">
                  <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular }}>{i18n.t(item.title)}</IM.Text>
                </IM.View>
              </IM.View>
              <IM.View spacing="right">
                {!item.isFixed && !isActive && <IM.Icon icon={['fal', 'grip-lines']} color={theme.textDetail} size={20} />}
              </IM.View>
            </Pressable>
          )}
        </>
      )
    },
    [theme]
  )

  const handleGoBack = () => {
    navigation.goBack()
  }

  return (
    <Screen transparent>
      <Screen.Header goBack={() => handleGoBack()} backHandlerCallback={handleGoBack} title={i18n.t('TITLE_EDIT_NAVIGATION')} />
      <Screen.Content>
        <DraggableFlatList
          data={tabs}
          style={styles.list}
          keyExtractor={item => item.name}
          renderItem={renderItem}
          onDragEnd={({ data }) => {
            if (menuItems) {
              let newData = navigationUtils.resetIndexBasedOnOrder(data)
              newData = navigationUtils.sortTabs(newData, maxDynamicElements, menuItems, 'navigation')

              reorder(newData)
            }
          }}
        />
      </Screen.Content>
    </Screen>
  )
}

const styles = StyleSheet.create({
  rowItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  itemContainer: { flexDirection: 'row', flex: 1, padding: 8 },
  itemIcon: { width: 30, justifyContent: 'center' },
  moreText: { paddingHorizontal: 10, paddingTop: 20, paddingBottom: 10 },
  list: { height: '100%' },
})
