import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import EmailModelListView from '../../views/anagrafics/email/EmailModelListView'

export default function EmailModelListScreen() {
  const { i18n } = useLanguage()

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header goBack title={i18n.t('EMAIL_MODELS')} />

        <Screen.Content>
          <EmailModelListView />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
