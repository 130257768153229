import { useDidUpdate } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useEffect, useState } from 'react'

export default function useAsyncStorageState<T extends object | undefined | null>(key: string, initialValue: T) {
  const [state, setState] = useState<T>(initialValue)
  const [initOk, setInitOk] = useState(false)

  useEffect(() => {
    const loadState = async () => {
      try {
        const jsonValue = await AsyncStorage.getItem(key)
        if (jsonValue) {
          setState(JSON.parse(jsonValue) as T)
        }
      } catch (e) {
        console.error(e)
      }
    }

    loadState()
      .catch(console.error)
      .finally(() => setInitOk(true))
  }, [])

  useDidUpdate(() => {
    if (initOk) {
      saveState(state).catch(console.error)
    }
  }, [state])

  const saveState = async (value: T) => {
    try {
      const jsonValue = value ? JSON.stringify(value) : ''
      await AsyncStorage.setItem(key, jsonValue)
      setState(value)
    } catch (e) {
      console.error(e)
    }
  }

  return [state, setState, initOk] as const
}
