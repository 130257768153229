import { IM, IMLayout, Language, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import { Border, SupportedLanguage, SupportedLanguages } from '../../types'
import Pressable from '../Infominds/Pressable'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputContext, { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

type LanguageTexts = Partial<Record<Language, string>>

export type MultiLangTextInputProps = Omit<BaseTextInputProps, 'value' | 'onChangeText'> &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    values?: LanguageTexts
    onChangeText?: (values: LanguageTexts) => void
    filterLanguages?: Language[]
  }

const MultiLangTextInput = memo(function TextInput({
  title,
  titleFontWeight,
  details,
  editable,
  error,
  loading,
  disableFocus,
  values,
  onChangeText,
  spacing,
  style,
  placeholder,
  filterLanguages,
  ...textInputProps
}: MultiLangTextInputProps) {
  const { theme } = useTheme()
  const { language: appLanguage } = useLanguage()

  const [open, setOpen] = useState(false)
  const languages = useMemo(() => {
    const sortedLanguages = [...SupportedLanguages]
      .filter(q => !filterLanguages?.length || filterLanguages.includes(q.value))
      .sort((a, _b) => (a.value === appLanguage ? -1 : 1))
    if (open) return sortedLanguages
    const fountLangWithValue = sortedLanguages.find(l => !!values?.[l.value])
    if (fountLangWithValue) return [fountLangWithValue]
    return sortedLanguages.slice(0, 1)
  }, [appLanguage, open])

  const RenderItem = useCallback(
    (language: SupportedLanguage, index: number) => {
      const isFirst = index === 0
      const isLast = index === languages.length - 1
      const disableBorderRadius: Border[] = []
      if (!isFirst) disableBorderRadius.push('top')
      if (!isLast) disableBorderRadius.push('bottom')
      const placeholderWithLang = placeholder && `${placeholder} (${language.value})`

      return (
        <BaseTextInputProvider
          editable={editable}
          error={error}
          loading={loading}
          disableFocus={disableFocus}
          key={`MultiLangInput${language.label}`}>
          <BaseTextInputContext.Consumer>
            {inputContext => (
              <BaseTextInput
                value={values?.[language.value]}
                onChangeText={value => onChangeText?.({ ...values, [language.value]: value })}
                disableBorderRadius={disableBorderRadius}
                style={[styles.input, (isLast || !!inputContext?.focus) && styles.inputBottomBorder]}
                placeholder={placeholderWithLang}
                {...textInputProps}>
                {title && isFirst && (
                  <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />
                )}

                <BaseTextInput.RightIcon
                  style={[styles.input, (isLast || !!inputContext?.focus) && styles.inputBottomBorder]}
                  disableBorder={disableBorderRadius}>
                  <Pressable style={[IMLayout.flex.row, styles.openIcon]} disabled={!isFirst} onPress={() => setOpen(prev => !prev)}>
                    <IM.Text style={{ color: theme.textDetail }}>{language.value}</IM.Text>
                    {isFirst && <IM.Icon color={theme.textDetail} icon={open ? ['fal', 'chevron-down'] : ['fal', 'chevron-right']} />}
                  </Pressable>
                </BaseTextInput.RightIcon>
              </BaseTextInput>
            )}
          </BaseTextInputContext.Consumer>
        </BaseTextInputProvider>
      )
    },
    [values, textInputProps, title]
  )

  return (
    <IM.View spacing={spacing} style={style}>
      {languages.map((lang, index) => RenderItem(lang, index))}
    </IM.View>
  )
})

export default MultiLangTextInput

const styles = StyleSheet.create({
  openIcon: {
    gap: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    borderBottomWidth: 1,
  },
  inputBottomBorder: {
    borderBottomWidth: 1,
  },
})
