import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet } from 'react-native'

import useLayout from '../../hooks/useLayout'
import { RootStackParamList } from '../../navigation/types'
import Button from '../Button'
import SignatureViewer from '../SignatureViewer'
import SwitchWithText from '../SwitchWithText'

type SignatureInputProps = {
  required?: boolean
  signature: string | undefined
  id: string
  setSignature: (signature: string | undefined) => void
  title: string
  subTitle?: string
}

function SignatureInput({ id, signature, required, setSignature, title, subTitle }: SignatureInputProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const { isSmallDevice } = useLayout()
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>()

  function addSignature() {
    rootNavigation.navigate('Signature', { id, title, subTitle })
  }

  if (required) {
    return (
      <IM.View spacing={'vertical'}>
        <IM.Text primary>{`${title} *`}</IM.Text>
        {!!subTitle && <IM.Text secondary>{subTitle}</IM.Text>}
        {signature && <SignatureViewer height={isSmallDevice ? 150 : 250} signature={signature} onPress={addSignature} />}
        {!signature && (
          <IM.View style={[IMLayout.flex.row]} spacing={'top'}>
            <Button color={theme.primary} title={i18n.t('ADD')} onPress={addSignature} />
          </IM.View>
        )}
      </IM.View>
    )
  }

  return (
    <>
      <SwitchWithText
        required={required}
        text={title}
        textProps={{ primary: true }}
        value={!!signature}
        onValueChange={value => {
          if (value) addSignature()
          else setSignature(undefined)
        }}
        style={[styles.switch]}
        spacing={'vertical'}
      />
      {!!signature && !!subTitle && <IM.Text secondary>{subTitle}</IM.Text>}
      {signature && <SignatureViewer height={isSmallDevice ? 150 : 250} signature={signature} onPress={addSignature} />}
    </>
  )
}

export default SignatureInput

const styles = StyleSheet.create({
  switch: {},
})
