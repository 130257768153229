import { useEvent } from '@infominds/react-native-components'
import { useEffect, useMemo } from 'react'

import { api } from '../../apis/apiCalls'
import { GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Contract } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { activityUtils } from '../../utils/ActivityUtils'
import useUserSettings from '../useUserSettings'

export default function useContractActivities(contract: Contract | null | undefined) {
  const { taskType } = useUserSettings()
  useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED }, refreshContractActivities)
  useEvent({ key: EVENT_KEYS.ACTIVITY_ASSIGNED }, refreshContractActivities)

  const {
    item: contractActivities,
    loadItem: loadContractActivities,
    loading: loadingContractActivities,
    setItem,
  } = useControlledLoader(api.activities.getList)
  const sortedData = useMemo(() => [...(contractActivities ?? [])].sort(activityUtils.sortByExpireDate), [contractActivities])

  useEffect(refreshContractActivities, [contract?.srvContractId])

  function refreshContractActivities() {
    if (!contract?.srvContractId) {
      setItem([])
      return
    }
    loadContractActivities({ assignedUser: GetActivityRequestAssignedUser.All, srvContractId: contract.srvContractId, taskType })
  }

  return {
    contractActivities: sortedData,
    loadingContractActivities,
    refreshContractActivities,
  }
}
