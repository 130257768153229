import { objectUtils } from '../utils/objectUtils'

export default function useObjectUtils<T extends object>(ids: keyof T | (keyof T)[]) {
  return {
    compare: (a: Partial<T> | undefined | null, b: Partial<T> | undefined | null) => objectUtils.compareItemsWithKeys(a, b, ids),
    createId: (a: T) => objectUtils.createUniqueIdFromKeys(a, ids),
    parseId: (id: string): T | undefined => objectUtils.parseUniqueIdToKeys(id, ids),
    createRequestObject: <TResult = Partial<T>>(a: Partial<T>) => objectUtils.createRequestObject<Partial<T>, TResult>(a, ids),
  }
}
