import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { createDrawerNavigator, DrawerContentComponentProps, DrawerNavigationOptions } from '@react-navigation/drawer'
import { DrawerActions, useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useRef } from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'

import useEmbed from '../../hooks/useEmbed'
import useLayout from '../../hooks/useLayout'
import useMenu from '../../hooks/useMenu'
import useUserSettings from '../../hooks/useUserSettings'
import { BottomTabParamList, RootStackParamList, TabNavigationScreen } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import navigationUtils from '../../utils/navigationUtils'
import EmployeeBadge from '../EmployeeBadge'
import Text from '../Text'
import useDrawer from './hooks/useDrawer'
import useDrawerRoute from './hooks/useDrawerRoute'
import HoverView from './HooverView'
import PressableIcon from './PressableIcon'

const Drawer = createDrawerNavigator()

const SETTINGS = {
  iconSize: 22,
}

interface TabNavigatorProps {
  initialRouteName?: keyof BottomTabParamList
  screenOptions?: DrawerNavigationOptions
}

export function DrawerNavigator<RouteName extends keyof BottomTabParamList>({ initialRouteName, screenOptions }: TabNavigatorProps) {
  const { menuItems } = useUserSettings()
  const { tabs, maxDynamicElements } = useMenu<RouteName>()
  const { theme } = useTheme<ThemeColorExpanded>()

  const { i18n } = useLanguage()
  const sortedTabs = useMemo(() => {
    const sorted = navigationUtils.sortTabs(tabs, maxDynamicElements, menuItems)
    sorted.push(
      sorted.splice(
        sorted.findIndex(e => e.name === 'SettingsStack'),
        1
      )[0]
    )

    return sorted
  }, [tabs, maxDynamicElements, menuItems])

  if (tabs.length === 0) {
    if (Platform.OS !== 'web') {
      return <IM.LoadingSpinner isVisible />
    } else {
      return <></>
    }
  }

  return (
    <Drawer.Navigator
      initialRouteName={initialRouteName}
      drawerContent={DrawerContent}
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        drawerStyle: {
          width: 'auto',
          backgroundColor: theme.drawerNavigator.background,
          borderRightColor: theme.drawerNavigator.background,
        },
        ...screenOptions,
      }}>
      {menuItems &&
        sortedTabs.map(tab => {
          if (tab.name === 'MoreEmpty') {
            return null
          } else {
            return (
              <Drawer.Screen
                key={`BottomTabScreen${tab.name}`}
                name={tab.name}
                component={tab.component}
                options={{
                  ...(tab.options as DrawerContentComponentProps),
                  title: i18n.t(tab.title),
                }}
              />
            )
          }
        })}
    </Drawer.Navigator>
  )
}

function DrawerContent() {
  return <></>
}

export type TabBarWebProps = {
  defaultRoute?: keyof BottomTabParamList
}

export function TabBarWeb({ defaultRoute }: TabBarWebProps) {
  const { tabs, maxDynamicElements } = useMenu<keyof BottomTabParamList>()
  const { menuItems } = useUserSettings()
  const navigation = useNavigation()
  const { isLargeDevice } = useLayout(true)
  const { embed } = useEmbed()
  const { open, setOpen } = useDrawer()
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const prevDeviceSize = useRef<'small' | 'large'>(isLargeDevice ? 'large' : 'small')
  const { route } = useDrawerRoute('DrawerNavigatorStack', bottomTabName, defaultRoute)
  const name = useMemo(() => userSettings && EmployeeUtils.getName(userSettings.employee), [userSettings?.employee])

  const sortedTabs = useMemo(() => {
    const sorted = navigationUtils.sortTabs(tabs, maxDynamicElements, menuItems)
    sorted.push(
      sorted.splice(
        sorted.findIndex(e => e.name === 'SettingsStack'),
        1
      )[0]
    )

    return sorted
  }, [tabs, maxDynamicElements, menuItems])

  useEffect(() => {
    if (!isLargeDevice && prevDeviceSize.current === 'large') {
      setOpen(false)
      prevDeviceSize.current = 'small'
    }

    if (isLargeDevice && prevDeviceSize.current === 'small') {
      prevDeviceSize.current = 'large'
    }
  }, [isLargeDevice])

  if (tabs.length === 0) {
    return <></>
  }

  return (
    <>
      {embed ? null : (
        <IM.View style={[styles.tabBarRoot, IMStyle.layout.shadow]}>
          <IM.View
            style={[
              styles.main,
              styles.tabBarContent,
              { backgroundColor: theme.drawerNavigator.bar },
              (open || !isLargeDevice) && styles.tabBarOpen,
            ]}>
            <IM.View style={styles.tabContainer}>
              {userSettings && (
                <>
                  <IM.View style={[styles.firstElement, styles.tabBarEmployeeBadge]}>
                    <EmployeeBadge
                      size={38}
                      showName={false}
                      id={userSettings?.employee?.id || undefined}
                      name={name ?? ''}
                      color={name === undefined ? theme.drawerNavigator.bar : undefined}
                    />
                  </IM.View>

                  <IM.View style={IMStyle.layout.flex.f1}>
                    {sortedTabs.map((tab, index) => {
                      return (
                        <TabContent
                          key={`BottomTab${index}`}
                          routeKey={tab.id.toString()}
                          tabProps={tab}
                          end={tab.name === 'SettingsStack'}
                          onlyText={false}
                          open={open}
                          isFocused={route === tab.name}
                        />
                      )
                    })}
                  </IM.View>

                  {isLargeDevice && (
                    <PressableIcon
                      icon={['fal', open ? 'angles-left' : 'angles-right']}
                      size={15}
                      color="#ffffff"
                      onPress={() => {
                        setOpen(prev => !prev)
                      }}
                    />
                  )}
                </>
              )}
            </IM.View>
          </IM.View>
          {((isLargeDevice && open) || !isLargeDevice) && (
            <IM.View
              style={[
                styles.drawerOpen,
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  paddingBottom: isLargeDevice ? 44 : 8,
                  backgroundColor: theme.drawerNavigator.bar,
                },
              ]}>
              <IM.View style={styles.employeeContainer}>
                {userSettings && userSettings.employee?.id && (
                  <IM.View style={[styles.firstElement, styles.drawerEmployee]}>
                    <IM.View style={[IMLayout.flex.row]}>
                      <IM.View style={[styles.employeeName, IMLayout.flex.f1]}>
                        {!!userSettings.employee.firstName && <Text style={styles.drawerEmployeeFirst}>{userSettings.employee.firstName}</Text>}
                        {!!userSettings.employee.lastName && <Text style={styles.drawerEmployeeSecond}>{userSettings.employee.lastName}</Text>}
                      </IM.View>
                      {!isLargeDevice && (
                        <PressableIcon
                          icon={['fal', 'times']}
                          color={IMStyle.palette.white}
                          onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
                          size={26}
                        />
                      )}
                    </IM.View>
                  </IM.View>
                )}
              </IM.View>
              {sortedTabs.map((tab, index) => {
                return (
                  <TabContent
                    key={`BottomTab${index}`}
                    routeKey={tab.id.toString()}
                    tabProps={tab}
                    end={tab.name === 'SettingsStack'}
                    onlyText
                    open={(isLargeDevice && open) || !isLargeDevice}
                    isFocused={route === tab.name}
                  />
                )
              })}
            </IM.View>
          )}
        </IM.View>
      )}
    </>
  )
}

const bottomTabName: keyof RootStackParamList = 'BottomTab'
function TabContent({
  routeKey,
  tabProps,
  isFocused,
  onlyText,
  end,
  open,
}: {
  routeKey: string
  tabProps: TabNavigationScreen<keyof BottomTabParamList>
  end: boolean
  onlyText: boolean
  open: boolean
  isFocused: boolean
}) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation()
  const { i18n } = useLanguage()
  const label = tabProps.title ? i18n.t(tabProps.title) : ''

  const onPress = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: bottomTabName,
          params: {
            screen: tabProps.name,
          },
        },
      ],
    })
  }

  return (
    <IM.View style={end && styles.tabContentRoot}>
      <TouchableOpacity key={`BottomTab${routeKey}`} onPress={onPress} style={styles.tab}>
        {isFocused && (
          <>
            {!onlyText && (
              <IM.View style={[styles.iconContainer, { backgroundColor: theme.tabNavigator.focused.iconBackground }]}>
                <IM.Icon icon={tabProps.icon} size={SETTINGS.iconSize} color={theme.drawerNavigator.icon.focused} />
              </IM.View>
            )}
          </>
        )}
        {!isFocused && !onlyText ? (
          <HoverView style={styles.iconContainer}>
            <IM.Icon icon={tabProps.icon} size={SETTINGS.iconSize} color={theme.drawerNavigator.icon.unFocused} />
          </HoverView>
        ) : (
          <>
            {open && onlyText && (
              // eslint-disable-next-line react-native/no-inline-styles
              <HoverView style={[styles.iconContainer, { alignItems: 'flex-start' }]}>
                <Text style={[styles.text, styles.tabContentText, isFocused && { color: theme.primary }]}>{label}</Text>
              </HoverView>
            )}
          </>
        )}
      </TouchableOpacity>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  firstElement: {
    height: 70,
  },
  tabBarEmployeeBadge: {
    alignItems: 'center',
    marginTop: 6,
  },
  tabBarContent: {
    width: 60,
  },
  tabBarOpen: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  tabContentText: {
    color: '#ffffff',
    marginLeft: 15,
  },
  tabContentRoot: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  main: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    borderRadius: 12,
  },
  iconContainer: {
    height: 45,
    marginBottom: 8,
    aspectRatio: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabContainer: {
    flexDirection: 'column',
    padding: 8,
    flex: 1,
  },
  tab: {
    justifyContent: 'center',
  },
  tabBarRoot: { flex: 1, margin: 10, flexDirection: 'row', borderRadius: 12 },
  text: {
    alignSelf: 'center',
  },
  drawerOpen: {
    padding: 8,
    width: 200,
    opacity: 0.8,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  drawerEmployee: { marginTop: 6 },
  drawerEmployeeFirst: { color: '#ffffff', fontSize: IMStyle.typography.fontSizeRegular },
  drawerEmployeeSecond: { color: '#ffffff', fontSize: IMStyle.typography.fontSizeRegular, fontWeight: IMStyle.typography.fontWeightBold },
  employeeName: {
    marginLeft: 15,
    marginTop: 1,
  },
  employeeContainer: {
    height: 76,
  },
})
