import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { ConstructionSiteLotto } from '../../apis/types/apiResponseTypes'
import ConstructionSiteLottoCard from '../../cards/constructionSites/ConstructionSiteLottoCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { Border, ListSection } from '../../types'
import constructionSiteUtils from '../../utils/constructionSiteUtils'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import SelectInput from './selectInput/SelectInput'

type Props = {
  editable?: boolean
  spacing?: SpacingProps
  objectId?: number
  onChange: (value: ConstructionSiteLotto[] | undefined) => void
  required?: boolean
  value?: number[]
  disabledInfo?: string | false
  filter?: (value: ConstructionSiteLotto | undefined) => boolean
  disableBorderRadius?: Border | Border[] | undefined
  title?: string
}

export default function MultiConstructionSiteLottoSelector({ onChange, required, objectId, value, title, filter, ...props }: Props) {
  const { i18n } = useLanguage()

  const {
    item: allData,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
    setItem,
  } = useInfiniteLoader(api.constructionsSites.lotto.getList, { chuckSize: 10000 })

  const [search, setSearch] = useState('')
  const filteredData = useDataSearchFilter(allData, search, ['description'])

  const data = useMemo(() => (filter ? filteredData.filter(filter) : filteredData), [filteredData, filter])
  const selected = useMemo(() => data.filter(d => value?.find(v => v === d.lotId)), [value, data])

  useEffect(() => {
    refresh()
  }, [objectId])

  const refresh = (_searchText?: string) => {
    if (!objectId) {
      setItem([])
      return
    }
    loadItem({ objectId })
  }

  const render = ({ item }: SectionListRenderItemInfo<ConstructionSiteLotto, ListSection<ConstructionSiteLotto>>, onPress?: () => void) => {
    return (
      <ConstructionSiteLottoCard
        constructionSiteLotto={item}
        onPress={onPress}
        selected={!!value?.find(v => v === item.lotId)}
        spacing={['horizontal', 'bottom']}
        lite
      />
    )
  }

  const handleOnChange = (newValue: ConstructionSiteLotto | undefined) => {
    if (!newValue) {
      onChange([])
      return
    }
    if (allData.length > 1 && selected.find(s => s.lotId === newValue?.lotId)) {
      onChange(selected.filter(s => s.lotId !== newValue?.lotId))
      return
    }

    onChange([...selected, newValue])
  }

  return (
    <SelectInput
      id={apiDtoIds.constructionsSiteLottos}
      data={data}
      loading={loading}
      value={selected.find(q => q.lotId)}
      refresh={refresh}
      onSearchChange={setSearch}
      title={title ?? i18n.t('CONSTRUCTION_SITE_LOTTO')}
      screenTitle={i18n.t('CONSTRUCTION_SITE_LOTTO')}
      noDataMessage={i18n.t('NO_CONSTRUCTION_SITE_LOTTO_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={() => selected.map(item => `• ${constructionSiteUtils.getLottoTitle(item)}`).join('\n')}
      required={required}
      allDataLoaded={allDataLoaded}
      onLoadMore={loadMore}
      disableLoadAfterMount
      disableFastInput
      keepOpenOnChange={allData.length > 1}
      multiline
      {...props}
    />
  )
}
