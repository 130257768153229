import { IM, IMLayout, IMStyle, useLanguage, useModalController } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { useNavigation } from '@react-navigation/native'
import { ListRenderItemInfo } from '@shopify/flash-list'
import Color from 'color'
import dayjs from 'dayjs'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import useScrollbarSize from 'react-scrollbar-size'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { GetActivityRequest, GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Activity, ActivityState } from '../../apis/types/apiResponseTypes'
import ActivityListBriefCard from '../../cards/activityList/ActivityListBriefCard'
import ActivityListCard, { ActivityListCardProps } from '../../cards/activityList/ActivityListCard'
import FlashListData from '../../components/FlashListData'
import CONSTANTS from '../../constants/Constants'
import { PlanningActivityFilterConfig } from '../../constants/Filters'
import useActivities from '../../hooks/activity/useActivities'
import useEmbed from '../../hooks/useEmbed'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useFilter from '../../hooks/useFilter'
import FilterModal from '../../modals/common/FilterModal'
import { CommonStackParamList } from '../../navigation/types'
import { filterUtils } from '../../utils/FilterUtils'
import { imDateUtils } from '../../utils/infominds/imDateUtils'
import { plannerActivitySearchFilterCountAtom, plannerShowActivityExtendedViewAtom, plannerShowClosedActivitiesAtom } from '../../utils/stateManager'
import { PlannerSideViewRef } from './PlannerSideView'

interface Props {
  height: number | undefined
  companyId: number | undefined
  objectId: number | undefined
  onDatePick: (date: Date) => void
}

const ActivitiesSearchView = ({ height, companyId, objectId, onDatePick }: Props, ref: ForwardedRef<PlannerSideViewRef>) => {
  useImperativeHandle(ref, () => ({
    openFilterModal: () => controller.show(undefined),
  }))
  const { sessionKey } = useAuthentication()

  const [search, setSearch] = useState('')
  const { requestFilter, apiFilter, initOk, changeApiFilter } = useFilter<Activity, GetActivityRequest>()

  const showClosedActivities = useRecoilValue(plannerShowClosedActivitiesAtom(sessionKey))
  const effectiveRequestFilter = useMemo(() => {
    const result = requestFilter ? { ...requestFilter } : {}
    if (companyId) Object.assign(result, { customerId: companyId })
    if (objectId) Object.assign(result, { objectId })

    return result as GetActivityRequest
  }, [requestFilter, companyId, objectId])

  const activityRequestStates = useMemo(() => {
    const states = [ActivityState.Open, ActivityState.Suspended]
    if (showClosedActivities) states.push(ActivityState.Closed, ActivityState.ClosedWithInterventionConnected)
    return states
  }, [showClosedActivities])

  const {
    groupedActivities: activities,
    loadActivities,
    loadingActivities,
    loadMoreActivities,
  } = useActivities({
    assignedMode: GetActivityRequestAssignedUser.All,

    states: activityRequestStates,
    chunkSize: CONSTANTS.activityChunkSize,
    enabled: true,
    searchText: search,
    filter: effectiveRequestFilter,
    filterConfig: PlanningActivityFilterConfig,
  })

  const { embed: isEmbedded } = useEmbed()
  const { i18n } = useLanguage()
  const { width } = useScrollbarSize()
  const { theme, colorScheme } = useExtendedTheme()
  const controller = useModalController<CommonStackParamList['Filter']>()
  const navigation = useNavigation()

  const setEnableFilter = useSetRecoilState(plannerActivitySearchFilterCountAtom)
  const cardExtended = useRecoilValue(plannerShowActivityExtendedViewAtom(sessionKey))

  const activeFilters = useMemo(() => filterUtils.getActiveApiFilters(apiFilter), [apiFilter])

  useEffect(() => {
    if (loadingActivities === false) return
    setEnableFilter(activeFilters.length)
  }, [loadingActivities, activeFilters])

  useEffect(() => {
    setEnableFilter(activeFilters.length)
  }, [activeFilters])

  const disabledFilters = useMemo(() => {
    const result: (keyof GetActivityRequest)[] = []
    if (companyId) result.push('customerId')
    if (objectId) result.push('objectId')
    return result
  }, [companyId, objectId])

  useEffect(() => {
    // set company and object filter if they are given in embedded mode.
    // It's important to also reset them to avoid having old filter values.
    // This will inevitably cause customer and object filters to reset every time in the embedded mode, but imo this is the better solution.
    if (!isEmbedded || !initOk) return

    const companyFilter = apiFilter.find(f => f.dataKey === 'customerId')
    if (companyFilter) {
      changeApiFilter(companyFilter.id, companyId, undefined, !!companyId)
    }

    const objectFilter = apiFilter.find(f => f.dataKey === 'objectId')
    if (objectFilter) {
      changeApiFilter(objectFilter.id, objectId, undefined, !!objectId)
    }
  }, [initOk])

  const renderItem = (elem: ListRenderItemInfo<Activity | string>) => {
    if (!activities) return <></>

    const isFirst = elem.index === 0
    const isLast = elem.index === activities.length - 1
    const item = elem.item

    if (typeof item === 'string') {
      return (
        <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}>
          <IM.Text numberOfLines={1}>{item}</IM.Text>
        </IM.View>
      )
    }

    const common: ActivityListCardProps = {
      onPress: () =>
        navigation.navigate('ActivityDetailStack', {
          screen: 'ActivityDetail',
          params: {
            srvActivityId: item.srvActivityId,
            srvActivityTypeId: item.srvActivityTypeId,
            srvActivityYear: item.srvActivityYear,
          },
        }),
      onTagPress: handleTagPress,
      activity: item,
      forceLayout: 'small',
      type: 'gantt',
      spacing: 'none',
      showPlanTag: true,
      style: {
        marginTop: isFirst ? 0 : IMLayout.horizontalMargin,
        marginBottom: isLast ? IMLayout.horizontalMargin : 0,
        marginLeft: IMLayout.horizontalMargin,
        marginRight: width + IMLayout.horizontalMargin,
      },
      disabled: isEmbedded,
    }

    return <>{cardExtended ? <ActivityListCard {...common} /> : <ActivityListBriefCard {...common} />}</>
  }

  const handleTagPress = (date: string) => onDatePick(dayjs(imDateUtils.toUTC(date)).toDate())

  return (
    <>
      <IM.View style={[height === undefined && IMLayout.flex.f1, { height }]}>
        {height === undefined && (
          <IM.SearchBox
            initialValue={search}
            placeholder={i18n.t('SEARCH_ACTIVITIES_PLACEHOLDER')}
            onEnterEnd={setSearch}
            placeholderTextColor={colorScheme === 'dark' ? theme.textPlaceholder : Color(IMStyle.palette.white).darken(0.26).toString()}
            inputMode="search"
            hideIcon
            hideBorder
            autoCapitalize="none"
            height={32}
            spacing="all"
          />
        )}
        <FlashListData
          data={activities}
          loading={loadingActivities}
          noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
          renderItem={renderItem}
          refresh={() => loadActivities()}
          onLoadMore={loadMoreActivities}
          listSpacer={4}
          ListFooterComponent={<></>}
          extraData={[cardExtended, showClosedActivities]}
        />
      </IM.View>
      <FilterModal controller={controller} disabledFilters={disabledFilters} />
    </>
  )
}

export default memo(forwardRef(ActivitiesSearchView))
