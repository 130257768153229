import AppNewsProvider from '@infominds/react-native-app-news'
import { useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { PropsWithChildren } from 'react'

import useUserSettings from '../hooks/useUserSettings'

export type AppNewsProps = PropsWithChildren

export default function AppNews({ children }: AppNewsProps) {
  const { language } = useLanguage()
  const { isLoggedIn, projectCode } = useAuthentication()
  const { userSettings } = useUserSettings()

  return (
    <AppNewsProvider softwareCode={projectCode ?? 'APP-EMSRV'} language={language} isLoggedIn={!!isLoggedIn && !!userSettings}>
      {children}
    </AppNewsProvider>
  )
}
