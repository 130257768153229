import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import Color from 'color'
import React, { createRef } from 'react'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'
import { useRecoilState } from 'recoil'

import { Customer } from '../../apis/types/apiResponseTypes'
import ContactInformation from '../../components/ContactInformation'
import Map from '../../components/map/Map'
import { SCREEN_CONSTANTS, ScreenCardsHeaderShadows } from '../../components/screen/constants/constants'
import useScreen from '../../components/screen/hooks/useScreen'
import TabSelector from '../../components/TabSelector'
import { ContactViewRef, DestinationViewRef, ThemeColorExpanded } from '../../types'
import { selectedCustomerDetailTabAtom } from '../../utils/stateManager'
import { utils } from '../../utils/utils'
import ConstructionSitesView from './ConstructionSitesView'
import ContactsView from './ContactsView'
import DestinationsView from './DestinationsView'

interface Props {
  customer: Customer
}

export type TabType = 'contacts' | 'destinations' | 'construction-sites'

const CustomerDetailsSmallDeviceView = ({ customer }: Props) => {
  const contactViewRef = createRef<ContactViewRef>()
  const destinationViewRef = createRef<DestinationViewRef>()

  const alert = useAlert()
  const { detail } = useScreen()
  const { i18n } = useLanguage()
  const { height } = useWindowDimensions()
  const { sessionKey } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [tab, setTab] = useRecoilState(selectedCustomerDetailTabAtom(sessionKey))

  const mapShown = detail.open === false && !!customer?.address?.latitude && !!customer?.address?.longitude
  const top = mapShown ? undefined : 0
  const fixedHeight = Math.floor(height * 0.65)
  const mapHeight = mapShown ? fixedHeight : '100%'

  return (
    <>
      <IM.View style={IMLayout.flex.f1}>
        {mapShown && (
          <Map
            items={[customer]}
            mapMarkerRenderItem={MapMarker}
            mapPadding={{ bottom: fixedHeight, top: 0, left: 0, right: 0 }}
            hideMyLocationButton
            hideCenterButton
            disableMapTouch
            delta={0.002}
            onMapPress={() => utils.openMaps(customer.address?.latitude, customer.address?.longitude, alert, i18n.t('OPEN_MAP_LINK_ERROR'))}
            style={Platform.OS === 'web' && { marginBottom: fixedHeight }}
          />
        )}
        <IM.View style={[styles.screen, { height: mapHeight, top: top }, Platform.OS !== 'web' && ScreenCardsHeaderShadows]}>
          <IM.View style={[styles.screenBorder, IMLayout.shadow, IMLayout.flex.f1, { backgroundColor: theme.tabNavigator.background }]}>
            <ContactInformation customer={customer} />
            <IM.View
              style={[
                IMLayout.flex.f1,
                styles.screenBorder,
                {
                  backgroundColor: theme.background,
                },
                ScreenCardsHeaderShadows,
              ]}>
              <IM.View spacing={'horizontal'}>
                <TabSelector>
                  <TabSelector.Item
                    icon={['fal', 'address-book']}
                    iconSize={18}
                    text={i18n.t('CONTACTS')}
                    selected={tab === 'contacts'}
                    onPress={() => setTab('contacts')}
                    selectionColor={Color(theme.header.main.background).toString()}
                  />
                  <TabSelector.Item
                    icon={['fal', 'hard-hat']}
                    iconSize={18}
                    text={i18n.t('CONSTRUCTION_SITES')}
                    selected={tab === 'construction-sites'}
                    onPress={() => setTab('construction-sites')}
                    selectionColor={Color(theme.header.main.background).toString()}
                  />
                  <TabSelector.Item
                    icon={['fal', 'location-dot']}
                    iconSize={18}
                    text={i18n.t('DESTINATIONS')}
                    selected={tab === 'destinations'}
                    onPress={() => setTab('destinations')}
                    selectionColor={Color(theme.header.main.background).toString()}
                    disableDivider
                  />
                </TabSelector>
              </IM.View>
              <ContactsView
                ref={contactViewRef}
                customerId={customer.companyId}
                hideSectionTitle
                show={tab === 'contacts'}
                canCreateNew
                // onRefresh={refresh}
              />
              <DestinationsView
                ref={destinationViewRef}
                customerId={customer.companyId}
                hideSectionTitle
                show={tab === 'destinations'}
                // onRefresh={refresh}
              />
              <ConstructionSitesView
                customerId={customer.companyId}
                hideSectionTitle
                show={tab === 'construction-sites'}
                // onRefresh={refresh}
              />
            </IM.View>
          </IM.View>
        </IM.View>
      </IM.View>
    </>
  )
}

function MapMarker() {
  return <IM.Icon icon={['fas', 'location-dot']} color={IMStyle.palette.red} size={30} />
}

const styles = StyleSheet.create({
  screen: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  screenBorder: {
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
})

export default CustomerDetailsSmallDeviceView
