import { IM, IMLayout } from '@infominds/react-native-components'
import React, { Children, PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react'

import Separator from './Infominds/Separator'
import KeyboardAwareIOS from './KeyboardAwareIOS'
import ScrollViewData from './ScrollViewData'

export type SplitViewProps = {
  showLeft?: boolean
  showRight?: boolean
  mode?: 'split' | 'split-reverse' | 'stacked' | 'stacked-reverse'
  separator?: boolean | 'split-only'
  refreshLeft?: () => void
  refreshRight?: () => void
} & PropsWithChildren

function DynamicScrollView({ showLeft = true, showRight = true, mode = 'split', separator, children, refreshLeft, refreshRight }: SplitViewProps) {
  const row = mode === 'split' || mode === 'split-reverse'
  const reverse = mode === 'split-reverse' || mode === 'stacked-reverse'

  const components = useMemo(() => {
    const childArray = Children.toArray(children)
    const leftComp = showLeft ? childArray.filter(q => (q as ReactElement).type === (reverse ? RightContent : LeftContent)) : false
    const rightComp = showRight ? childArray.filter(q => (q as ReactElement).type === (reverse ? LeftContent : RightContent)) : false
    const separatorComp = separator && (separator !== 'split-only' || row) ? <Separator type={row ? 'vertical' : 'horizontal'} /> : false

    const result: Record<'left' | 'right' | 'separator', ReactNode> = {
      left: (
        <>
          {leftComp}
          {!row && separatorComp ? separatorComp : <IM.View style={{ height: IMLayout.verticalMargin * 2 }} />}
          {!row && rightComp}
        </>
      ),
      right: row ? rightComp : false,
      separator: row ? separatorComp : false,
    }
    return result
  }, [children, showLeft, showRight, separator, mode])

  return (
    <IM.View style={[IMLayout.flex.f1, row && IMLayout.flex.row]}>
      <KeyboardAwareIOS>
        <ScrollViewData style={[IMLayout.flex.f1]} refresh={refreshLeft}>
          {components.left}
        </ScrollViewData>
      </KeyboardAwareIOS>
      {components.separator}
      {!!components.right && (
        <KeyboardAwareIOS>
          <ScrollViewData style={[IMLayout.flex.f1]} refresh={refreshRight}>
            {components.right}
          </ScrollViewData>
        </KeyboardAwareIOS>
      )}
    </IM.View>
  )
}

function LeftContent({ children }: PropsWithChildren) {
  return <>{children}</>
}

function RightContent({ children }: PropsWithChildren) {
  return <>{children}</>
}

const DynamicScrollViewNamespace = Object.assign(DynamicScrollView, {
  Left: LeftContent,
  Right: RightContent,
})

export { DynamicScrollViewNamespace as DynamicScrollView }
