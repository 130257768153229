import { useEvent, useModalController } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { Animated, Platform } from 'react-native'

import { ActivityGroupBy, GetActivityRequest, GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Activity } from '../../apis/types/apiResponseTypes'
import useScreen from '../../components/screen/hooks/useScreen'
import useSearch from '../../components/screen/hooks/useSearch'
import { ScreenViewType } from '../../components/screen/types'
import { OpenActivityStates } from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { ActivityFilterConfig } from '../../constants/Filters'
import useActivities from '../../hooks/activity/useActivities'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import ActivityCreationModal from '../../modals/activity/ActivityCreationModal'
import { ActivityCreateOrEditViewProps } from './ActivityCreateOrEditView'
import ActivityListCalendarView from './ActivityListCalendarView'
import ActivityListMapView from './ActivityListMapView'
import ActivityListView, { ActivityListProps } from './ActivityListView'

const buttonAnimationValue = new Animated.Value(0)

export default function ActivitiesView() {
  const { view } = useScreen()
  const { search } = useSearch()
  const navigation = useNavigation()
  const createActivityModal = useModalController<ActivityCreateOrEditViewProps>()
  const { isSmallDevice } = useLayout()
  const { userSettings, user } = useUserSettings()

  const { requestFilter } = useFilter<Activity, GetActivityRequest>()

  const {
    groupedActivities: activities,
    loadActivities,
    loadMoreActivities,
    loadingActivities: loading,
  } = useActivities({
    enabled: true,
    assignedMode: user.can.see.allActivities ? GetActivityRequestAssignedUser.Assigned : GetActivityRequestAssignedUser.AssignedTo,
    states: OpenActivityStates,
    filter: requestFilter,
    searchText: search,
    filterConfig: ActivityFilterConfig,
  })

  // remove planDate as grouping for unassigned activities since it makes no sense
  const unassignedRequestFilter = useMemo<GetActivityRequest | undefined>(
    () => (requestFilter?.groupBy === ActivityGroupBy.PlanDate ? { ...requestFilter, groupBy: undefined } : requestFilter),
    [requestFilter]
  )
  const {
    groupedActivities: unassignedActivities,
    loadActivities: loadUnassignedActivities,
    loadMoreActivities: loadMoreUnassignedActivities,
    loadingActivities: loadingUnassignedActivities,
  } = useActivities({
    enabled: user.can.see.unassignedActivities,
    assignedMode: GetActivityRequestAssignedUser.Unassigned,
    states: OpenActivityStates,
    filter: unassignedRequestFilter,
    searchText: search,
  })

  useEvent<Activity>({ key: EVENT_KEYS.ACTIVITY_ASSIGNED }, activity => {
    if (!activity || !userSettings?.employee?.id) return
    onActivityPressed(activity)
  })

  function handleActivityCreation() {
    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      navigation.navigate('ActivityCreation', { screen: 'ActivityCreation', params: {} })
    } else {
      createActivityModal.show({})
    }
  }

  function onActivityPressed(activity: Activity) {
    navigation.navigate('ActivityDetailStack', {
      screen: 'ActivityDetail',
      params: {
        srvActivityId: activity.srvActivityId,
        srvActivityTypeId: activity.srvActivityTypeId,
        srvActivityYear: activity.srvActivityYear,
        title: activity.title,
      },
    })
  }

  const common = {
    activities: activities ?? [],
    unassignedActivities: unassignedActivities ?? [],
    loading,
    loadingUnassigned: loadingUnassignedActivities,
    buttonAnimationValue: buttonAnimationValue,
    onCreate: handleActivityCreation,
    reloadActivities: loadActivities,
    reloadActivitiesUnassigned: loadUnassignedActivities,
    loadMoreActivities: loadMoreActivities,
    loadMoreUnassignedActivities: loadMoreUnassignedActivities,
    onActivityPressed,
  } satisfies ActivityListProps

  return (
    <>
      {view === ScreenViewType.LIST && <ActivityListView type="normal" showTabs={user.can.see.unassignedActivities} {...common} />}

      {view === ScreenViewType.LOCATION && <ActivityListMapView {...common} />}

      {view === ScreenViewType.CALENDAR && <ActivityListCalendarView {...common} />}

      <ActivityCreationModal controller={createActivityModal} />
    </>
  )
}
