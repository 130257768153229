import { ConstructionSite, ConstructionSiteLotto } from '../apis/types/apiResponseTypes'

const constructionSiteUtils = {
  getTitle(constructionSite: ConstructionSite | null | undefined) {
    if (!constructionSite) return ''
    return `(${constructionSite.objectId}) ${constructionSite.description?.trim() ?? ''}`
  },
  getLottoTitle(lotto: ConstructionSiteLotto | null | undefined) {
    if (!lotto) return ''
    return `(${lotto.lotId}) ${lotto.description?.trim() ?? ''}`
  },
}

export default constructionSiteUtils
