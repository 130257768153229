import { CardButton, IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import type { Contact } from '../../apis/types/apiResponseTypes'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import useClipboard from '../../hooks/useClipboard'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'
import LoaderCard, { LoadedCardProps } from '../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData' | 'buttons'> & { contact: Contact | undefined | null; disableIcon?: boolean }

const CustomerContactCard = memo(function CustomerContactCard({ contact, disableIcon, hideButtons, loading = false, ...props }: Props) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const hasData = contact?.contactId
  const { copyToClipboard } = useClipboard()

  const inactive = false
  const phone = contact?.phone
  const email = contact?.email

  const buttons = useMemo<CardButton[]>(() => {
    const result: CardButton[] = []
    if (phone) {
      result.push({
        icon: ['fal', 'phone'],
        ...appUtils.getCardButtonColor(!inactive, theme),
        overrideDisabled: true,
        onPress: inactive ? undefined : handlePhonePress,
        onLongPress: inactive ? undefined : () => copyToClipboard(phone),
      })
    }

    if (email) {
      result.push({
        icon: ['fal', 'envelope'],
        ...appUtils.getCardButtonColor(!inactive, theme),
        overrideDisabled: true,
        onPress: inactive ? undefined : handleEmailPress,
        onLongPress: inactive ? undefined : () => copyToClipboard(email),
      })
    }
    return result
  }, [phone, email, inactive, theme])

  const { contactText, contactDescription } = useMemo(() => customerUtils.getContactInfo(contact), [contact])

  function handlePhonePress() {
    if (phone) appUtils.openPhone(phone, alert, i18n.t('OPEN_PHONE_ERROR'))
  }

  function handleEmailPress() {
    if (email) appUtils.openEmail(alert, i18n.t('OPEN_EMAIL_ERROR'), email)
  }

  return (
    <LoaderCard
      noDataText={i18n.t('NO_CONTACT_SELECTED')}
      hasData={!!contact?.contactId}
      loading={loading}
      buttons={{ buttons: hideButtons || !hasData || loading !== false ? [] : buttons }}
      {...props}>
      <TextWithIcon
        secondary={inactive}
        icon={disableIcon ? undefined : contact?.objectId ? ['fal', 'user-helmet-safety'] : ['fal', 'contact-book']}
        iconSize={20}
        style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }]}>
        {contactText}
      </TextWithIcon>
      {!!contactDescription && <IM.Text secondary>{contactDescription}</IM.Text>}
      {(phone || email || contact?.position) && (
        <IM.View style={[IMLayout.flex.f1, disableIcon === false && styles.margin]} spacing="top">
          {phone && <IM.Text secondary={inactive}>{phone}</IM.Text>}
          {email && <IM.Text secondary={inactive}>{email}</IM.Text>}
          {contact.position && <IM.Text secondary={inactive}>{contact.position}</IM.Text>}
        </IM.View>
      )}
    </LoaderCard>
  )
})

export default CustomerContactCard

const styles = StyleSheet.create({
  margin: { margin: 4 },
})
