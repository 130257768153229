import { IM, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api } from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import DeleteButton from '../../components/DeleteButton'
import GroupSpacer from '../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerOptions, EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ContactTypeSelector from '../../components/selectors/ContactTypeSelector'
import CorpPositionSelector from '../../components/selectors/CorpPositionSelector'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import ErgoTitleSelector from '../../components/selectors/ErgoTitleSelector'
import GenderSelector from '../../components/selectors/GenderSelector'
import LanguageSelector from '../../components/selectors/LanguageSelector'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { EditOrCreateViewRef, UploadStatus } from '../../types'

export type ContactCreateOrEditViewProps = {
  customerId?: number
  contact?: Contact
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: EditDataHandlerOptions<Contact, Partial<Contact>>['onDone']
}

function CustomerContactCreateOrEditView(
  { contact, customerId, onDone, onUploadStatus }: ContactCreateOrEditViewProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))
  const { i18n } = useLanguage()

  const editable = !contact
  const requiredFields = useMemo<EditDataHandlerRequiredFields<Contact>>(() => ['firstName', 'lastName', 'companyId'], [])
  const { state, editMode, createOrUpdate, deleteData, handleDataChange } = useEditDataHandler<Contact, Partial<Contact>>(
    api.customers.contacts.post,
    undefined,
    undefined,
    {
      eventKeyCreation: EVENT_KEYS.CONTACT_CREATED_OR_MODIFIED,
      eventKeyModification: EVENT_KEYS.CONTACT_CREATED_OR_MODIFIED,
      eventKeyDeletion: EVENT_KEYS.CONTACT_CREATED_OR_MODIFIED,
      onDone: onDone,
      onUploadStatus: onUploadStatus,
      editMode: !!contact,
      initialValue: { ...contact, companyId: customerId },
      showErrorAlert: true,
      requiredFields,
    }
  )

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
    editable: editable === false ? false : undefined,
  }
  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <CustomerSelector
        onChange={value => handleDataChange({ companyId: value?.companyId })}
        value={state?.companyId}
        required
        {...commonProps}
        editable={false}
      />

      <TextInput
        title={i18n.t('SALUTATION')}
        placeholder={i18n.t('SALUTATION')}
        editable={!editMode}
        value={state?.salutation}
        onChangeText={value => handleDataChange({ salutation: value })}
        {...commonProps}
      />
      <TextInput
        required
        title={i18n.t('SURNAME')}
        placeholder={i18n.t('SURNAME')}
        value={state?.lastName}
        onChangeText={value => handleDataChange({ lastName: value })}
        {...commonProps}
      />
      <TextInput
        required
        title={i18n.t('NAME')}
        placeholder={i18n.t('NAME')}
        editable={!editMode}
        value={state?.firstName}
        onChangeText={value => handleDataChange({ firstName: value })}
        {...commonProps}
      />

      <GenderSelector editable={!editMode} value={state?.sex} onChange={value => handleDataChange({ sex: value })} {...commonProps} />
      <ErgoTitleSelector
        editable={!editMode}
        value={state?.titleId}
        onChange={value => handleDataChange({ titleId: value?.titleId })}
        {...commonProps}
      />
      <CorpPositionSelector
        editable={!editMode}
        value={state?.corpPositionId}
        onChange={value => handleDataChange({ corpPositionId: value?.corpPositionId })}
        {...commonProps}
      />
      <LanguageSelector
        editable={!editMode}
        value={state?.languageId}
        onChange={value => handleDataChange({ languageId: value?.languageId })}
        {...commonProps}
      />
      <ContactTypeSelector
        editable={!editMode}
        value={state?.contactType}
        onChange={value => handleDataChange({ contactType: value })}
        {...commonProps}
      />
      <GroupSpacer />
      <TextInput
        title={i18n.t('PHONE')}
        placeholder={i18n.t('PHONE')}
        value={state?.phone}
        onChangeText={value => handleDataChange({ phone: value })}
        inputMode="tel"
        {...commonProps}
      />
      <TextInput
        title={i18n.t('PHONE') + ' 2'}
        placeholder={i18n.t('PHONE') + ' 2'}
        value={state?.phone2}
        onChangeText={value => handleDataChange({ phone2: value })}
        inputMode="tel"
        {...commonProps}
      />
      <TextInput
        title={i18n.t('EMAIL')}
        placeholder={i18n.t('EMAIL')}
        value={state?.email}
        onChangeText={value => handleDataChange({ email: value })}
        inputMode="email"
        {...commonProps}
      />
      <TextInput
        title={i18n.t('FAX')}
        placeholder={i18n.t('FAX')}
        value={state?.fax}
        onChangeText={value => handleDataChange({ fax: value })}
        {...commonProps}
      />
      <GroupSpacer />

      <TextInput
        title={i18n.t('NOTE')}
        placeholder={i18n.t('NOTE')}
        value={state?.note}
        onChangeText={value => handleDataChange({ note: value })}
        maxHeight={200} // maxHeight is necessary, because if the input gets to big, the user is no longer able to scroll. (especially on an iOS tablet)
        multiline
        {...commonProps}
      />
      <GroupSpacer />

      {editMode && editable && (
        <IM.View {...commonProps}>
          <DeleteButton title={i18n.t('DELETE')} onPress={deleteData} />
        </IM.View>
      )}
      <GroupSpacer />
      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(CustomerContactCreateOrEditView)
