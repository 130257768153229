import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api } from '../../apis/apiCalls'
import { EmailModel } from '../../apis/types/apiResponseTypes'
import EmailModelCard from '../../cards/activities/EmailModelCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  value: number | undefined
  selectedActivityId?: number
  editable?: boolean
  spacing?: SpacingProps
  srvActivityTypeId?: number
  onChange: (value: EmailModel | undefined) => void
  autoSelect?: boolean
} & Pick<SelectInputProps<EmailModel>, 'required' | 'disableFastInput' | 'title' | 'placeholder'>

export default function EmailModuleSelector({
  value,
  onChange,
  title,
  srvActivityTypeId,
  required,
  editable,
  selectedActivityId,
  autoSelect,
  ...props
}: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const {
    item: allData,
    loadItem,
    loading,
    error,
  } = useControlledLoader(api.email.models.getList, {
    onLoadedCallback: result => {
      if (!autoSelect || !result?.length || value) return
      const filtered = result.filter(d => !d.obsolete)
      const defaultItem = filtered.find(d => d.isDefault && !d.obsolete)
      if (defaultItem) {
        onChange(defaultItem)
        return
      }
      if (filtered.length === 1) onChange(filtered.at(0))
    },
  })
  const data = useMemo(() => allData?.filter(d => !d.obsolete), [allData])
  const filteredData = useDataSearchFilter(data, search, ['subject_De', 'subject_En', 'subject_It', 'text_De', 'text_En', 'text_It'])

  const selectedItem = useMemo(
    () => data?.find(d => d.srvEmailId === value && (!selectedActivityId || d.srvActivityTypeId === selectedActivityId)),
    [data, value]
  )

  useEffect(reload, [])

  function reload() {
    loadItem({ srvActivityTypeId })
  }

  const render = ({ item }: SectionListRenderItemInfo<EmailModel, ListSection<EmailModel>>, onPress?: () => void) => {
    return (
      <EmailModelCard
        key={item.srvEmailId}
        onPress={onPress}
        selected={item.srvEmailId === value && (!selectedActivityId || item.srvActivityTypeId === selectedActivityId)}
        model={item}
        spacing={['bottom', 'horizontal']}
      />
    )
  }

  return (
    <>
      <SelectInput
        id={['srvEmailId']}
        data={filteredData}
        value={selectedItem}
        loading={loading}
        title={title ?? i18n.t('EMAIL_MODEL')}
        screenTitle={title ?? i18n.t('EMAIL_MODEL')}
        noDataMessage={i18n.t('NO_EMAIL_MODELS_FOUND')}
        renderItem={render}
        onChange={onChange}
        onSearchChange={setSearch}
        refresh={reload}
        renderSelectedString={item => item.subject ?? ''}
        disableLoadAfterMount
        error={error}
        disableFastInput
        required={required}
        editable={editable && data?.length !== 1}
        {...props}
      />
    </>
  )
}
