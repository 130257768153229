import { IM, IMLayout, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { api, apiDtoIds } from '../../../apis/apiCalls'
import { GetActivityMedia } from '../../../apis/types/apiRequestTypes'
import { ActivityMedia, ActivityNote } from '../../../apis/types/apiResponseTypes'
import { useActivityNote } from '../../../contexts/activity/ActivityNoteContext'
import useObjectUtils from '../../../hooks/useObjectUtils'
import useUserSettings from '../../../hooks/useUserSettings'
import DisplayAssetModal from '../../../modals/media/DisplayAssetModal'
import { AppMediaUtils } from '../../../utils/AppMediaUtils'
import appUtils from '../../../utils/appUtils'
import { noteUtils } from '../../../utils/noteUtils'
import DeleteButton from '../../DeleteButton'
import EmployeeDateRecord from '../../EmployeeDateRecord'
import useControlledLoader from '../../Infominds/hooks/useControlledLoader'
import useRequest from '../../Infominds/hooks/useRequest'
import PressableIcon from '../../Infominds/PressableIcon'
import Separator from '../../Infominds/Separator'
import NoteInput from '../../input/NoteInput'
import MediaView from '../../media/MediaView'
import MediaSkeleton from '../../skeleton/MediaSkeleton'

export type ActivityNotePostProps = {
  note: ActivityNote
  separator?: boolean
  enableChange?: boolean
}

export default function ActivityNotePost({ note, separator, enableChange }: ActivityNotePostProps) {
  const { userSettings } = useUserSettings()
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const alert = useAlert()
  const showAssetModal = useModalController<LiteAsset>()
  const objectUtils = useObjectUtils<ActivityNote>(apiDtoIds.activityNotes)
  const mediaObjectUtils = useObjectUtils<ActivityMedia>(apiDtoIds.media)
  const isUserNote = userSettings?.employee?.id === note.employee?.id
  const editable = !!isUserNote && !!enableChange

  const { item: media, loadItem: loadMedia, loading: loadingMedia, setItem: setMedia } = useControlledLoader(api.activities.media.get)
  const [transformingImages, setTransformingImages] = useState(false)

  const [assets, setAssets] = useState<LiteAsset[]>([])
  const assetsToShow = assets
  const [editMode, setEditMode] = useState(false)
  const { request: updateNote, loading: busyUpdatingNote } = useRequest(api.activities.notes.put, { showErrorAlert: true })
  const [deletingMediaInProgress, setDeletingMediaInProgress] = useState<string[]>([])
  const { deleteNote, deletingNote } = useActivityNote()

  useEffect(reloadMedia, [note])
  function reloadMedia() {
    if (!note.mediaCount) {
      setMedia([])
      return
    }
    loadMedia({ ...objectUtils.createRequestObject<GetActivityMedia>(note) })
  }

  useEffect(() => {
    setTransformingImages(true)
    noteUtils
      .convertMediaToLiteAssets(media)
      .then(convertedAssets => {
        setAssets(convertedAssets)
      })
      .catch(console.error)
      .finally(() => setTransformingImages(false))
  }, [media])

  function handleSaveNote(text: string) {
    setEditMode(false)
    if (text !== note.notes) updateNote({ ...note, employeeId: userSettings?.employee?.id, notes: text })
  }

  function handleDeleteMedia(assetsToDelete: LiteAsset[], skipConfirmation?: boolean) {
    if (!isUserNote || !editable) {
      alert.alert(i18n.t('INFO'), i18n.t('MEDIA_DELETE_DENIED'))
      return
    }
    const mediaToDelete = media?.filter(m => assetsToDelete.some(a => a.id === mediaObjectUtils.createId(m)))

    if (mediaToDelete?.length) {
      if (skipConfirmation) {
        doDeleteMedia(assetsToDelete, mediaToDelete)
        return
      }
      alert.alert(i18n.t('DELETE_MEDIA'), i18n.t('DELETE_MEDIA_ALERT'), [
        {
          text: i18n.t('REMOVE'),
          onPress: () => {
            doDeleteMedia(assetsToDelete, mediaToDelete)
          },
          style: 'destructive',
        },
        {
          text: i18n.t('CANCEL'),
          style: 'cancel',
        },
      ])
    }
  }

  function doDeleteMedia(assetsToDelete: LiteAsset[], mediaToDelete: ActivityMedia[]) {
    const assetIds = assetsToDelete.map(a => a.id)
    setDeletingMediaInProgress(prev => [...prev, ...assetIds])
    Promise.all(mediaToDelete.map(m => api.activities.media.delete(mediaObjectUtils.createRequestObject(m))))
      .then(() => {
        setAssets(prev => prev.filter(a => !assetIds.some(id => id === a.id)))
        // if note has no text and no media, delete the note
        if (!note.notes && assets.length - assetsToDelete.length === 0) {
          deleteNote(note, true)
        }
      })
      .catch(error => {
        const errorMessage = appUtils.getBackendErrorMessage(error)
        alert.alert(i18n.t('API_CATCH_TITLE'), errorMessage)
        reloadMedia()
        setAssets([])
        setEditMode(false)
      })
      .finally(() => {
        setDeletingMediaInProgress(prev => prev.filter(m => !assetIds.some(a => a === m)))
      })
  }

  function handleAssetPressed(asset: LiteAsset) {
    if (deletingMediaInProgress.length) return
    if (asset.type === 'file') {
      AppMediaUtils.shareFile(asset).catch(console.error)
    } else {
      showAssetModal.show(asset)
    }
  }

  return (
    <IM.View>
      {separator && <Separator />}
      <EmployeeDateRecord employee={note.employee} date={note.noteDate} reverse={isUserNote}>
        {editable && !busyUpdatingNote && (
          <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, styles.headerContainerView]}>
            {!editMode && <PressableIcon size={20} color={theme.textPlaceholder} icon={['fal', 'pen-to-square']} onPress={() => setEditMode(true)} />}
            {editMode && <DeleteButton title={i18n.t('DELETE')} onPress={() => deleteNote(note)} isBeingDeleted={!!deletingNote} />}
          </IM.View>
        )}
      </EmployeeDateRecord>

      <MediaView
        spacing={'top'}
        assets={assetsToShow ?? []}
        onAssetPressed={handleAssetPressed}
        align={isUserNote ? 'right' : 'left'}
        loading={loadingMedia || transformingImages}
        loadingSkeleton={<MediaSkeleton mediaCount={note.mediaCount ?? 3} align={isUserNote ? 'right' : 'left'} />}
        editMode={editMode}
        onDeleteAsset={handleDeleteMedia}
        assetsBeingDeleted={deletingMediaInProgress}
      />

      <NoteInput
        spacing={'top'}
        value={note.notes}
        editable={editMode}
        align={isUserNote ? 'right' : 'left'}
        onSaveNote={handleSaveNote}
        loading={!!busyUpdatingNote}
      />

      <DisplayAssetModal
        assets={assets}
        controller={showAssetModal}
        onDeleteAsset={assetsToDelete => handleDeleteMedia(assetsToDelete, Platform.OS !== 'web')}
        allowAssetDeletion={editable}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  headerContainerView: {
    justifyContent: 'space-between',
  },
})
