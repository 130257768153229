import { useEffect } from 'react'

import useMenu from './useMenu'

export default function useHideBottomTab() {
  const menu = useMenu()
  useEffect(() => {
    menu.setTabBarHidden(true)

    return () => menu.setTabBarHidden(false)
  }, [])
}
