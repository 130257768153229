import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Contract } from '../../apis/types/apiResponseTypes'
import ContractCard from '../../cards/activities/ContractCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ListSection, LoadingType } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: number
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Contract | undefined) => void
  required?: boolean
  initialValue?: number
}

export default function ContractSelector({ id, onChange, initialValue, ...props }: Props) {
  const { i18n } = useLanguage()

  const [search, setSearch] = useState('')
  const { languageSelect } = useLanguageSelect()
  const [selectedId, setSelectedId] = useState(id)
  const [loadingDetail, setLoadingDetail] = useState<LoadingType>(false)

  const { item: data, loadItem, loading } = useControlledLoader(api.activities.contracts.getList)
  const filteredData = useDataSearchFilter(data, search, ['noteDe', 'noteEn', 'noteIt', 'titleDe', 'titleEn', 'titleIt'])

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadItem()

  useEffect(() => {
    if (!initialValue) return
    const foundItem = data?.find(d => d.srvContractId === initialValue)
    if (foundItem) {
      handleOnChange(foundItem)
    }
  }, [data])

  function getText(item: Contract) {
    return languageSelect({ de: item.titleDe, en: item.titleEn, it: item.titleIt }) ?? ''
  }

  const render = ({ item }: SectionListRenderItemInfo<Contract, ListSection<Contract>>, onPress?: () => void) => {
    return <ContractCard contract={item} lite onPress={onPress} selected={selectedId === item.srvContractId} spacing={['horizontal', 'bottom']} />
  }

  function handleOnChange(newValue: Contract | undefined) {
    if (!newValue?.srvContractId) {
      setSelectedId(undefined)
      onChange(undefined)
      return
    }
    setLoadingDetail('reloading')
    setSelectedId(undefined)

    api.activities.contracts
      .getDetail({ contractId: newValue?.srvContractId })
      .then(result => {
        if (!result) return
        setSelectedId(result?.srvContractId)
        onChange(result)
      })
      .catch(console.error)
      .finally(() => setLoadingDetail(false))
  }

  return (
    <SelectInput
      id={apiDtoIds.contracts}
      data={filteredData}
      value={data?.find(elem => elem.srvContractId === selectedId)}
      loading={loading || loadingDetail}
      refresh={refresh}
      title={i18n.t('CONTRACT')}
      screenTitle={i18n.t('CONTRACTS')}
      noDataMessage={i18n.t('NO_CONTRACT_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => getText(item)}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      disableFastInput
      {...props}
    />
  )
}
