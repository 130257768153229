export const EVENT_KEYS = {
  UPDATED_ACTIVITY_TYPES: 'UpdatedActivityTypesEvent',
  UPDATED_TIME_SLOTS: 'UpdatedTimeSlotsEvent',
  UPDATED_UNAVAILABLE_TYPES: 'UpdatedUnavailableTypeEvent',
  UPDATED_CONTRACTS: 'ContractUpdatedEvent',
  UPDATED_CONTRACT_LOTS: 'ContractLotsUpdatedEvent',
  UPDATED_EMAIL_MODELS: 'EmailModelsUpdatedEvent',
  UPDATED_EMAIL_SETTINGS: 'EmailSettingsUpdatedEvent',
  ACTIVITY_UPDATED: 'ActivityUpdatedEvent',
  ACTIVITY_ASSIGNED: 'ActivityAssignedEvent',
  ACTIVITY_TIME_MODIFIED: 'ActivityTimeModifiedEvent',
  ACTIVITY_ARTICLES_UPDATED: 'ActivityArticlesUpdatedEvent',
  ACTIVITY_TRAVELS_UPDATED: 'ActivityTravelsUpdatedEvent',
  CONTACT_CREATED_OR_MODIFIED: 'ContactCreatedOrModifiedEvent',
  DESTINATION_CREATED_OR_MODIFIED: 'DestinationCreatedOrModifiedEvent',
  LOTTO_CREATED_OR_MODIFIED: 'LottoCreatedOrModifiedEvent',
  UPDATED_NOTES: 'UpdatedNotesEvent',
  GANTT_CHART_REFRESH: 'GanttChartRefreshEvent',
  GANTT_CHART_REMOVE: 'GanttChartRemoveEvent',
  GANTT_CHART_NEXT: 'GanttChartNextEvent',
  GANTT_CHART_PREVIOUS: 'GanttChartNextPreviousEvent',
  GANTT_CHART_DATE_PICK: 'GanttChartDatePickerEvent',
  GANTT_CHART_BLUR: 'GanttChartBlurEvent',
  GANTT_CHART_TIME_RANGE_REFRESH: 'GanttChartTimeRangeRefreshEvent',
  GANTT_CHART_TO_PLAN_REFRESH: 'GanttChartToPlanRefreshEvent',
  GANTT_CHART_UPDATE_START_EVENT: 'GanttChartUpdateStartEvent',
  GANTT_CHART_UPDATE_END_EVENT: 'GanttChartUpdateEndEvent',
  SIGNATURE_ADDED: 'AddedSignatureEvent',
  UPDATED_DESTINATIONS: 'UpdatedDestinationsEvent',
  SECTION_LIST_CLOSE_TO_END_EVENT_KEY: 'SectionListCloseToEndEvent',
}
