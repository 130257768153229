import { useAlert, useLanguage, Utils } from '@infominds/react-native-components'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { api } from '../../apis/apiCalls'
import { Activity, Contract } from '../../apis/types/apiResponseTypes'
import useEditDataHandler, {
  EditDataHandlerRequestType,
  EditDataHandlerRequiredFields,
  EditDataHandlerResult,
} from '../../components/Infominds/hooks/useEditDataHandler'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { ContractActivity, ContractLotSelection, UploadStatus } from '../../types'
import { ContractUtils } from '../../utils/ContractUtils'
import { ValidationUtils } from '../../utils/ValidationUtils'

export type ContractCreationContextResult = {
  objectLots: ContractLotSelection[]
  setObjectLots: React.Dispatch<React.SetStateAction<ContractLotSelection[]>>
  clearObjectLots: (objectId?: number) => void
  deviceActivity: ContractActivity | undefined
  setDeviceActivity: React.Dispatch<React.SetStateAction<ContractActivity | undefined>>
  contractActivities: Activity[] | null
} & EditDataHandlerResult<Contract> &
  Omit<ContractCreationContextProviderProps, 'children'>

export const ContractCreationContext = React.createContext<ContractCreationContextResult | null>(null)

export type ContractCreationContextProviderProps = PropsWithChildren<{
  contract?: Contract | null
  onUploadStatus: (status: UploadStatus) => void
  onDone: (contract?: Contract, deleted?: boolean) => void
  contractActivities: Activity[] | null
}>

type ContractPostResult = { srvContractId: number }
export function ContractCreationContextProvider({
  contract,
  onUploadStatus,
  onDone,
  children,
  contractActivities,
}: ContractCreationContextProviderProps) {
  const { i18n } = useLanguage()
  const [objectLots, setObjectLots] = useState<ContractLotSelection[]>(
    contract?.lots?.map(lot => ({
      id: lot.srvContractLotId.toString(),
      constructionSiteId: contract.objectId ?? 0,
      isDeleted: false,
      lots: [lot],
    })) ?? []
  )
  const [deviceActivity, setDeviceActivity] = useState<ContractActivity | undefined>(undefined)

  const alert = useAlert()

  const requiredFields = useMemo<EditDataHandlerRequiredFields<Contract>>(
    () => ['startDate', 'expiredDate', ['titleDe', 'titleEn', 'titleIt'], 'companyId'],
    []
  )
  const dataHandler = useEditDataHandler<Contract, ContractPostResult>(
    api.activities.contracts.post,
    api.activities.contracts.put,
    api.activities.contracts.delete,
    {
      eventKeyCreation: EVENT_KEYS.UPDATED_CONTRACTS,
      eventKeyModification: EVENT_KEYS.UPDATED_CONTRACTS,
      eventKeyDeletion: EVENT_KEYS.UPDATED_CONTRACTS,
      onDone: handleDone,
      onUploadStatus: onUploadStatus,
      editMode: !!contract,
      initialValue: contract ?? undefined,
      showErrorAlert: true,
      requiredFields,
      validate: state => {
        if (!ValidationUtils.dateLT(state.startDate, state.expiredDate)) return ['expiredDate']
        if (!state.device && !objectLots.find(ol => !!ol.lots.length)) return ['device', 'createObjectLots']
        return []
      },
      modifyDataBeforeRequest: (data, type) => {
        if (!data?.startDate || !data?.expiredDate || type !== 'create') return data
        const createActivities = ContractUtils.createContractActivities(deviceActivity, objectLots)
        return { ...data, ...createActivities }
      },
      preRequestRoutine: (request, type) => {
        if (type !== 'create') return
        const numberOfActivitiesToCreate =
          (request.createActivities?.length ?? 0) + Utils.sum(request.createObjectLots, q => q.createActivities?.length ?? 0)
        if (numberOfActivitiesToCreate < CONSTANTS.numberOfMaxContractActivitiesForAlert) return
        return new Promise<'abort' | false>(resolve => {
          alert.alert(i18n.t('WARNING'), Utils.stringValueReplacer(i18n.t('CONTRACT_ACTIVITY_CREATION_ALERT'), numberOfActivitiesToCreate), [
            { text: i18n.t('OK'), onPress: () => resolve(false) },
            { text: i18n.t('CANCEL'), isPreferred: true, style: 'cancel', onPress: () => resolve('abort') },
          ])
        })
      },
    }
  )

  function handleDone(type: EditDataHandlerRequestType, result?: ContractPostResult[]) {
    if (type === 'create' && result?.length) onDone({ ...dataHandler.state, ...result[0] })
    else onDone()
  }

  function clearObjectLots(objectId?: number) {
    setDeviceActivity(undefined)
    if (!!objectId && !objectLots.find(ol => ol.constructionSiteId !== objectId)) return
    if (!objectLots.length) return
    setObjectLots([])
  }

  useEffect(dataHandler.reValidate, [objectLots])

  return (
    <ContractCreationContext.Provider
      value={{
        ...dataHandler,
        contract,
        onUploadStatus,
        onDone,
        objectLots,
        setObjectLots,
        clearObjectLots,
        deviceActivity,
        setDeviceActivity,
        contractActivities,
      }}>
      {children}
    </ContractCreationContext.Provider>
  )
}

export function useContractCreation() {
  const context = React.useContext(ContractCreationContext)

  if (!context) throw new Error('useContractCreation() can only be used inside of <ContractCreationContextProvider>')

  return context
}
