import { useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'

import { AppStyle } from '../constants/Styles'
import { ThemeColorExpanded } from '../types'
import Button from './Button'

interface Props {
  title: string
  onPress: () => void
  isBeingDeleted?: boolean
  disabled?: boolean
}

export default function DeleteButton({ title, onPress, isBeingDeleted, disabled }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <>
      <Button
        title={title.toUpperCase()}
        disabled={disabled}
        icon={['fal', 'trash']}
        loading={isBeingDeleted}
        color={disabled ? theme.button.disabledBackground : theme.header.main.error}
        iconSize={16}
        iconColor={disabled ? theme.button.disabledBackground : theme.header.main.error}
        containerStyle={[
          {
            backgroundColor: Color(disabled ? theme.button.disabledBackground : theme.header.main.error)
              .fade(0.9)
              .toString(),
          },
          AppStyle.center,
        ]}
        onPress={onPress}
      />
    </>
  )
}
