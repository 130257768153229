import DataProviderHelper from '../../dataProvider/utils/DataProviderHelper'
import OfflineProvider from '../../dataProvider/utils/OfflineProvider'
import {
  GetActivityArticlesRequest,
  GetActivityContractDetailRequest,
  GetActivityContractLotsRequest,
  GetActivityDetailRequest,
  GetActivityEmployeeRequest,
  GetActivityNotesRequest,
  GetActivityRequest,
  GetActivityTimeRequest,
  GetActivityTravelRequest,
  GetArticlePriceList,
  GetArticleRequest,
  GetConstructionSiteDetailRequest,
  GetConstructionSiteLottosRequest,
  GetConstructionSiteRequest,
  GetCustomerConstructionSiteDetailRequest,
  GetCustomerConstructionSitesRequest,
  GetCustomerContactDetailRequest,
  GetCustomerContactsRequest,
  GetCustomerDestinationDetailRequest,
  GetCustomerDestinationRequest,
  GetCustomerDetailRequest,
  GetCustomersRequest,
  GetEmployeeRequest,
  GetErgoTaskDetail,
  GetErgoTasksRequest,
  GetPriceListArticle,
  GetTechniciansRequest,
  GetTimeSlotRequest,
} from '../types/apiRequestTypes'
import {
  Activity,
  ActivityArticle,
  ActivityEmployee,
  ActivityNote,
  ActivityNotePostResult,
  ActivityTime,
  ActivityTravel,
  ActivityType,
  Article,
  ArticlePriceList,
  ConstructionSite,
  ConstructionSiteLotto,
  Contact,
  Contract,
  ContractObjectLot,
  Customer,
  Destination,
  Employee,
  ErgoTask,
  MenuItems,
  Technician,
  TimeSlot,
  UnavailableType,
  UserSettings,
} from '../types/apiResponseTypes'

export const OfflineConfig = {
  userSettings: OfflineProvider<UserSettings>({
    singleObject: true,
  }),
  menuItems: OfflineProvider<MenuItems>({
    singleObject: true,
  }),
  // customer
  customer: OfflineProvider<Customer, GetCustomersRequest>({
    id: ['companyId'],
    localResource: 'customer',
    get_queries: [
      DataProviderHelper.query.matchLike('searchtext', ['company1', 'email', 'firstName', 'lastName', 'companyId']),
      DataProviderHelper.query.matchAsNumber('searchtext', 'companyId'),
    ], // TODO define search fields
    get_modifiers: [DataProviderHelper.modify.sort(['company1']), DataProviderHelper.modify.skipTake()],
  }),
  customerDetail: OfflineProvider<Customer, GetCustomerDetailRequest>({
    id: ['companyId'],
    localResource: 'customer',
    get_queries: [DataProviderHelper.query.match('customerId', 'companyId')],
  }),
  customerContacts: OfflineProvider<Contact, GetCustomerContactsRequest>({
    id: ['companyId', 'contactId'],
    localResource: 'contacts',
    get_queries: [DataProviderHelper.query.match('customerId', 'companyId')], // TODO define search fields
    get_modifiers: [DataProviderHelper.modify.sort(['firstName']), DataProviderHelper.modify.skipTake()],
  }),
  customerContactDetail: OfflineProvider<Contact, GetCustomerContactDetailRequest>({ id: ['companyId', 'contactId'], localResource: 'contacts' }),
  customerDestinations: OfflineProvider<Destination, GetCustomerDestinationRequest>({
    id: ['companyId', 'destinationId'],
    localResource: 'destinations',
    get_queries: [DataProviderHelper.query.match('customerId', 'companyId')], // TODO define search fields
    get_modifiers: [DataProviderHelper.modify.sort(['address']), DataProviderHelper.modify.skipTake()],
  }),
  customerDestinationDetail: OfflineProvider<Destination, GetCustomerDestinationDetailRequest>({
    id: ['companyId', 'destinationId'],
    localResource: 'destinations',
  }),
  customerConstructionSites: OfflineProvider<ConstructionSite, GetCustomerConstructionSitesRequest>({
    id: ['companyId', 'objectId'],
    localResource: 'constructionSites',
    get_queries: [DataProviderHelper.query.match('customerId', 'companyId')], // TODO define search fields
    get_modifiers: [DataProviderHelper.modify.sort(['description']), DataProviderHelper.modify.skipTake()],
  }),
  customerConstructionSiteDetail: OfflineProvider<ConstructionSite, GetCustomerConstructionSiteDetailRequest>({
    id: ['companyId', 'objectId'],
    localResource: 'constructionSites',
  }),
  // Activity
  activityTypes: OfflineProvider<ActivityType>({
    id: ['srvActivityTypeId'],
  }),
  timeSlots: OfflineProvider<TimeSlot, GetTimeSlotRequest>({
    id: ['srvTimeSlotId'],
  }),
  contracts: OfflineProvider<Contract>({
    id: ['srvContractId'],
    localResource: 'contracts',
  }),
  contractDetail: OfflineProvider<Contract, GetActivityContractDetailRequest>({
    id: ['srvContractId'],
    get_queries: [DataProviderHelper.query.match('contractId', 'srvContractId')],
    localResource: 'contracts',
  }),
  contractLot: OfflineProvider<ContractObjectLot, GetActivityContractLotsRequest>({
    id: ['srvContractId', 'srvContractLotId'],
    localResource: 'contractLots',
  }),
  unavailableType: OfflineProvider<UnavailableType>({
    id: ['srvUnavailableTypeId'],
  }),
  activities: OfflineProvider<Activity, GetActivityRequest>({
    id: ['srvActivityId', 'srvActivityTypeId', 'srvActivityYear'],
    localResource: 'activities',
    get_queries: [DataProviderHelper.query.match('state')],
  }),
  activityDetail: OfflineProvider<Activity, GetActivityDetailRequest>({
    id: ['srvActivityId', 'srvActivityTypeId', 'srvActivityYear'],
    get_queries: [
      DataProviderHelper.query.and(
        DataProviderHelper.query.match('srvActivityId'),
        DataProviderHelper.query.match('srvActivityTypeId'),
        DataProviderHelper.query.match('srvActivityYear')
      ),
    ],
  }),
  activityEmployees: OfflineProvider<ActivityEmployee, GetActivityEmployeeRequest>({
    id: ['srvActivityId', 'srvActivityTypeId', 'srvActivityYear', 'srvActivityEmployeeId'],
    localResource: 'activityEmployee',
  }),
  activityNotes: OfflineProvider<ActivityNote, GetActivityNotesRequest, ActivityNotePostResult>({
    id: ['srvActivityId', 'srvActivityTypeId', 'srvActivityYear', 'srvActivityNoteId'],
    localResource: 'activityNotes',
  }),
  activityTime: OfflineProvider<ActivityTime, GetActivityTimeRequest>({
    id: ['srvActivityId', 'srvActivityTypeId', 'srvActivityYear', 'srvActivityTimeId'],
  }),
  activityTravel: OfflineProvider<ActivityTravel, GetActivityTravelRequest>({
    id: ['srvActivityId', 'srvActivityTypeId', 'srvActivityYear', 'srvActivityTravelId'],
  }),
  article: OfflineProvider<Article, GetArticleRequest>({
    id: ['articleId'],
  }),
  activityArticle: OfflineProvider<ActivityArticle, GetActivityArticlesRequest>({
    id: ['srvActivityId', 'srvActivityTypeId', 'srvActivityYear', 'srvActivityArticleId'],
  }),
  articlePriceList: OfflineProvider<ArticlePriceList, GetArticlePriceList>({
    id: ['priceListId'],
  }),
  priceListArticle: OfflineProvider<Article, GetPriceListArticle>({
    id: ['articleId'],
    localResource: 'articles',
  }),
  // Objects (construction sites)
  constructionSites: OfflineProvider<ConstructionSite, GetConstructionSiteRequest>({
    id: ['companyId', 'objectId'],
  }),
  constructionSiteDetail: OfflineProvider<ConstructionSite, GetConstructionSiteDetailRequest>({
    id: ['companyId', 'objectId'],
  }),
  constructionSiteLottos: OfflineProvider<ConstructionSiteLotto, GetConstructionSiteLottosRequest>({
    id: ['lotId', 'objectId'],
  }),
  ergoTasks: OfflineProvider<ErgoTask, GetErgoTasksRequest>({ id: ['id'] }),
  ergoTasksDetail: OfflineProvider<ErgoTask, GetErgoTaskDetail>({ id: ['id'] }),
  employee: OfflineProvider<Employee, GetEmployeeRequest>({ id: ['id'] }),
  technicians: OfflineProvider<Technician, GetTechniciansRequest>({ id: ['id'] }),
}
