import { useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo } from 'react'
import { TextStyle } from 'react-native'

import { TextInputRef } from '../../types'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type SaveableTextInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    onSavePressed?: () => void
  }

const SaveableTextInput = memo(
  forwardRef(function SaveableTextInput(
    { title, titleFontWeight, details, editable, error, loading, disableFocus, onSavePressed, ...textInputProps }: SaveableTextInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { theme } = useTheme()

    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput ref={ref} {...textInputProps}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />}
          {loading && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius} style={{ backgroundColor: theme.card.background }}>
              {loading && <BaseTextInputSpinningIcon loading={loading} />}
            </BaseTextInput.RightIcon>
          )}
          {!!onSavePressed && editable && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              <PressableIcon icon={['fal', 'check']} size={19} disabledColor={theme.textDetail} color={theme.text} onPress={onSavePressed} />
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default SaveableTextInput
