import { IM, IMLayout, IMStyle, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { ListRenderItemInfo } from '@shopify/flash-list'
import Color from 'color'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { DnDSource, EventItem, SchedulerData } from 'react-big-scheduler-stch'
import { Animated } from 'react-native'
import useScrollbarSize from 'react-scrollbar-size'
import { useSetRecoilState } from 'recoil'

import { GetActivityRequest, GetActivityRequestAssignedUser, GetActivityRequestPlannedState } from '../../apis/types/apiRequestTypes'
import { Activity, ActivityState } from '../../apis/types/apiResponseTypes'
import FlashListData from '../../components/FlashListData'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import { ADD_ACTIVITY_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { PlannerToBePlannedActivityFilterConfig } from '../../constants/Filters'
import useActivities from '../../hooks/activity/useActivities'
import useEmbed from '../../hooks/useEmbed'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useFilter from '../../hooks/useFilter'
import ActivityCreationModal from '../../modals/activity/ActivityCreationModal'
import FilterModal from '../../modals/common/FilterModal'
import { CommonStackParamList } from '../../navigation/types'
import { filterUtils } from '../../utils/FilterUtils'
import { plannerActivityFilterCountAtom } from '../../utils/stateManager'
import { ActivityCreateOrEditViewProps } from '../activities/ActivityCreateOrEditView'
import { PlannerSideViewRef } from './PlannerSideView'

const buttonAnimationValue = new Animated.Value(0)

interface Props {
  height: number | undefined
  schedulerData: SchedulerData<EventItem> | undefined
  taskDndSource: DnDSource
  showCreateActivityButton?: boolean
  companyId: number | undefined
  objectId: number | undefined
  newEvent: (schedulerData: SchedulerData, slotId: string, slotName: string, start: string, end: string, type: string, item: EventItem) => void
}

const ActivitiesToBePlannedView = (
  { height, schedulerData, taskDndSource, companyId, objectId, showCreateActivityButton = false, newEvent }: Props,
  ref: ForwardedRef<PlannerSideViewRef>
) => {
  useImperativeHandle(ref, () => ({
    openFilterModal: () => controller.show(undefined),
  }))
  const { embed: isEmbedded } = useEmbed()
  const [search, setSearch] = useState('')
  const setEnableFilter = useSetRecoilState(plannerActivityFilterCountAtom)

  const { requestFilter, apiFilter, changeApiFilter, initOk } = useFilter<Activity, GetActivityRequest>()

  const effectiveRequestFilter = useMemo(() => {
    const result = requestFilter ? { ...requestFilter } : {}
    if (companyId) Object.assign(result, { customerId: companyId })
    if (objectId) Object.assign(result, { objectId })

    return result as GetActivityRequest
  }, [requestFilter, companyId, objectId])

  const {
    groupedActivities: activities,
    loadActivities,
    loadingActivities,
    loadMoreActivities,
  } = useActivities({
    assignedMode: GetActivityRequestAssignedUser.All,
    plannedState: GetActivityRequestPlannedState.NotPlanned,
    states: [ActivityState.Open, ActivityState.Suspended],
    chunkSize: CONSTANTS.activityChunkSize,
    enabled: true,
    searchText: search,
    filter: effectiveRequestFilter,
    filterConfig: PlannerToBePlannedActivityFilterConfig,
  })

  useEvent({ key: EVENT_KEYS.GANTT_CHART_TO_PLAN_REFRESH }, () => reload())

  const { i18n } = useLanguage()
  const { width } = useScrollbarSize()
  const { theme, colorScheme } = useExtendedTheme()
  const controller = useModalController<CommonStackParamList['Filter']>()
  const createActivityModal = useModalController<ActivityCreateOrEditViewProps>()

  const activeFilters = useMemo(() => filterUtils.getActiveApiFilters(apiFilter), [apiFilter])

  const TaskDnDRenderItem = taskDndSource.getDragSource()

  const reload = () => loadActivities()

  useEffect(() => {
    if (loadingActivities === false) return
    setEnableFilter(activeFilters.length)
  }, [loadingActivities, activeFilters])

  const disabledFilters = useMemo(() => {
    const result: (keyof GetActivityRequest)[] = []
    if (companyId) result.push('customerId')
    if (objectId) result.push('objectId')
    return result
  }, [companyId, objectId])

  useEffect(() => {
    // set company and object filter if they are given in embedded mode.
    // It's important to also reset them to avoid having old filter values.
    // This will inevitably cause customer and object filters to reset every time in the embedded mode, but imo this is the better solution.
    if (!isEmbedded || !initOk) return

    const companyFilter = apiFilter.find(f => f.dataKey === 'customerId')
    if (companyFilter) {
      changeApiFilter(companyFilter.id, companyId, undefined, !!companyId)
    }

    const objectFilter = apiFilter.find(f => f.dataKey === 'objectId')
    if (objectFilter) {
      changeApiFilter(objectFilter.id, objectId, undefined, !!objectId)
    }
  }, [initOk])

  const renderItem = (elem: ListRenderItemInfo<Activity | string>) => {
    if (!activities) return <></>

    const isFirst = elem.index === 0
    const isLast = elem.index === activities.length - 1
    const item = elem.item

    if (typeof item === 'string') {
      return (
        <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}>
          <IM.Text numberOfLines={1}>{item}</IM.Text>
        </IM.View>
      )
    }

    return (
      <TaskDnDRenderItem
        newEvent={newEvent}
        schedulerData={schedulerData}
        activity={item}
        forceLayout="small"
        type="gantt"
        spacing="none"
        style={
          // eslint-disable-next-line react-native/no-inline-styles
          {
            marginTop: isFirst ? 0 : IMLayout.horizontalMargin,
            marginBottom: isLast ? IMLayout.horizontalMargin : 0,
            marginLeft: IMLayout.horizontalMargin,
            marginRight: width + IMLayout.horizontalMargin,
          }
        }
      />
    )
  }

  return (
    <>
      <IM.View style={[height === undefined && IMLayout.flex.f1, { height }]}>
        {height === undefined && (
          <IM.SearchBox
            initialValue={search}
            placeholder={i18n.t('SEARCH_ACTIVITY_TO_BE_PLANNED_PLACEHOLDER')}
            onEnterEnd={setSearch}
            placeholderTextColor={colorScheme === 'dark' ? theme.textPlaceholder : Color(IMStyle.palette.white).darken(0.26).toString()}
            inputMode="search"
            hideIcon
            hideBorder
            autoCapitalize="none"
            height={32}
            spacing="all"
          />
        )}
        <FlashListData
          data={activities}
          loading={loadingActivities}
          noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
          onLoadMore={loadMoreActivities}
          renderItem={renderItem}
          refresh={() => reload()}
          listSpacer={4}
          ListFooterComponent={<></>}
        />
      </IM.View>
      {showCreateActivityButton && (
        <AnimatedButton
          id={ADD_ACTIVITY_BUTTON_ID}
          value={buttonAnimationValue}
          icon={['fal', 'plus']}
          onPress={() =>
            createActivityModal.show({
              presetActivity: {
                companyId,
                objectId,
              },
            })
          }
        />
      )}
      <FilterModal<GetActivityRequest> controller={controller} disabledFilters={disabledFilters} />
      <ActivityCreationModal controller={createActivityModal} />
    </>
  )
}

export default memo(forwardRef(ActivitiesToBePlannedView))
