import { IM, ViewProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import ActivityListTabs from '../../components/activity/ActivityListTabs'
import useLayout from '../../hooks/useLayout'
import { ActivityListProps } from './ActivityListView'

export type ActivityTabState = 'my' | 'unassigned'

export default function ActivityListHeader({
  activities,
  unassignedActivities,
  loading,
  loadingUnassigned,
  tab,
  setTab,
  style,
  showCount,
  ...viewProps
}: { tab: ActivityTabState; setTab: React.Dispatch<React.SetStateAction<ActivityTabState>>; showCount?: boolean } & Pick<
  ActivityListProps,
  'activities' | 'loading' | 'unassignedActivities' | 'loadingUnassigned'
> &
  ViewProps) {
  const { isLargeDevice, layoutSelect } = useLayout()

  if (!unassignedActivities || loadingUnassigned === undefined) return <></>

  return (
    <IM.View
      style={[styles.container, layoutSelect({ large: styles.containerLargeDevice, small: styles.containerSmallDevice }), style]}
      {...viewProps}>
      <IM.View style={[isLargeDevice && styles.tabSelectorLargeDevice]}>
        <ActivityListTabs
          activities={activities}
          tab={tab}
          setTab={setTab}
          unassignedActivities={unassignedActivities}
          loading={loading}
          loadingUnassigned={loadingUnassigned}
          showCount={showCount}
        />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    zIndex: 1,
  },
  containerLargeDevice: {
    alignItems: 'center',
  },
  containerSmallDevice: {},
  tabSelectorLargeDevice: {
    width: 400,
  },
})
