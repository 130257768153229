import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { Children, memo, ReactElement, useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import useLayout from '../../hooks/useLayout'
import Separator from './Separator'

type DynamicRow = ReactElement[]
type DynamicViewElement = ReactElement<{ style?: StyleProp<ViewStyle> }> | false | undefined | null | ''

export type DynamicViewProps = {
  flexLimitSmallDevice?: number
  flexLimitLargeDevice?: number
  rowStyle?: StyleProp<ViewStyle>
  children: DynamicViewElement | DynamicViewElement[]
  separatorHorizontal?: boolean | 'smallDeviceOnly' | 'largeDeviceOnly'
  separatorVertical?: boolean | 'smallDeviceOnly' | 'largeDeviceOnly'
  forceLayout?: false | 'small' | 'medium' | 'large'
}

/**
 * Dynamically aligns content by device-type (table/phone/web).
 * Layout is determined by the flex prop of the children and the flex-limit (default: small-device = 6 / large device = 12)
 
 * ``` tsx
 *  <DynamicView>
 *    <IM.View style={{ flex: 6}} />
 *    <IM.View style={{ flex: 6}} />
 *  </DynamicView>
// The two Views are next to each other on a large device and underneath each other on a small device.
 * ```
 */
const DynamicView = memo(function DynamicView({
  children,
  rowStyle,
  flexLimitSmallDevice = 6,
  flexLimitLargeDevice = 12,
  separatorHorizontal,
  separatorVertical,
  forceLayout,
  ...props
}: Omit<ViewProps, 'children'> & DynamicViewProps) {
  const { isSmallDevice } = useLayout(false, forceLayout)
  const flexLimit = isSmallDevice ? flexLimitSmallDevice : flexLimitLargeDevice
  const viewRows = useMemo(defineRows, [children])

  const showHorizontalSeparator =
    separatorHorizontal === true ||
    (separatorHorizontal === 'smallDeviceOnly' && isSmallDevice) ||
    (separatorHorizontal === 'largeDeviceOnly' && !isSmallDevice)
  const showVerticalSeparator =
    separatorVertical === true ||
    (separatorVertical === 'smallDeviceOnly' && isSmallDevice) ||
    (separatorVertical === 'largeDeviceOnly' && !isSmallDevice)

  function defineRows() {
    const rows: DynamicRow[] = []
    let currentRow: DynamicRow = []
    let flexSum = 0
    Children.forEach(children, child => {
      if (!child) return
      const childFlex = StyleSheet.flatten(child.props.style)?.flex ?? 1
      if (childFlex + flexSum > flexLimit) {
        rows.push(currentRow)
        currentRow = []
        flexSum = 0
      }
      currentRow.push(child)
      flexSum += childFlex
    })
    if (currentRow.length) rows.push(currentRow)
    return rows
  }

  return (
    <IM.View {...props}>
      {viewRows.map((row, rowIndex) => (
        <IM.View key={`DynamicRow${rowIndex}`}>
          <IM.View style={[rowStyle, IMLayout.flex.row]}>
            {row.map((element, elementIndex) => (
              <React.Fragment key={`DynamicRow${rowIndex}Element${elementIndex}`}>
                {element}
                {showVerticalSeparator && elementIndex < row.length - 1 && <Separator type="vertical" />}
              </React.Fragment>
            ))}
          </IM.View>
          {showHorizontalSeparator && rowIndex < viewRows.length - 1 && <Separator type="horizontal" />}
        </IM.View>
      ))}
    </IM.View>
  )
})

export default DynamicView
