import { IM, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { Customer } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import customerUtils from '../../utils/customerUtils'

interface Props {
  customer: Customer
  onPress?: () => void
  selected?: boolean
  borderless?: boolean
  spacing?: SpacingProps
}

const CustomerCard = ({ customer, onPress, selected, borderless, spacing }: Props) => {
  const { theme } = useTheme()

  const address = useMemo(() => customerUtils.formatAddress(customer.address), [customer.address])
  const title = useMemo(() => customerUtils.getTitle(customer), [customer])

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} onPress={onPress} borderless={borderless} spacing={spacing}>
      <IM.TextWithIcon
        icon={['fal', 'building']}
        primary
        numberOfLines={1}
        style={{ fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }}>
        {title}
      </IM.TextWithIcon>
      <IM.Text secondary numberOfLines={3}>
        {address}
      </IM.Text>
    </IM.Card>
  )
}

export default CustomerCard
