import { IM, useAlert, useEvent, useLanguage, ViewProps } from '@infominds/react-native-components'
import React from 'react'

import DeleteButton from './DeleteButton'
import useRequest from './Infominds/hooks/useRequest'

type ApiDeleteButtonProps<T> = {
  data: T
  onDeleted: () => void
  deleteRequest: (data: Partial<T>, abortController?: AbortController | undefined) => Promise<unknown>
  deleteAlertTitle: string
  deleteAlertMessage: string
  eventKey?: string
  title?: string
  disabled?: boolean
  additionalRequestData?: Partial<T>
} & Pick<ViewProps, 'spacing' | 'style'>

export default function ApiDeleteButton<T extends object>({
  onDeleted,
  deleteRequest,
  data,
  deleteAlertTitle,
  deleteAlertMessage,
  eventKey,
  title,
  disabled,
  additionalRequestData,
  ...viewProps
}: ApiDeleteButtonProps<T>) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  const { emit } = useEvent({ key: eventKey ?? '' })

  const { request, loading: busy } = useRequest(deleteRequest, {
    showErrorAlert: true,
    onSuccess: () => {
      if (eventKey) emit()
      onDeleted()
    },
  })

  function handleDeletePressed() {
    alert.alert(deleteAlertTitle, deleteAlertMessage, [
      {
        text: i18n.t('DELETE'),
        onPress: () => {
          request({ ...additionalRequestData, ...data })
        },
        style: 'destructive',
      },
      { text: i18n.t('CANCEL'), isPreferred: true },
    ])
  }
  return (
    <IM.View {...viewProps}>
      <DeleteButton title={title ?? i18n.t('DELETE')} onPress={handleDeletePressed} disabled={disabled} isBeingDeleted={busy === 'reloading'} />
    </IM.View>
  )
}
