import { IM, IMLayout, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Modal, Platform, StyleSheet } from 'react-native'
import WebModal from 'react-native-modal'

import PressableIcon from '../components/Infominds/PressableIcon'
import { USER_COLORS } from '../constants/Colors'
import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'

interface Props {
  controller: ModalController
  color: string
  setColor: (color: string) => void
}

export default function ColorPickerModal({ controller, setColor, color }: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const handleClose = () => controller.close()

  const content = (
    <IM.View
      style={[
        styles.centeredView,
        Platform.select({
          native: {
            flex: 1,
            backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100}`,
          },
        }),
      ]}>
      <IM.View style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme) }]}>
        <IM.View spacing="bottom" style={styles.header}>
          <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
            <IM.Text style={styles.headerText}>{i18n.t('COLOR')}</IM.Text>
            <IM.Text style={[styles.headerText, { color }]}>{color}</IM.Text>
          </IM.View>
          <PressableIcon icon={['fal', 'times']} size={22} onPress={handleClose} />
        </IM.View>
        <IM.View style={styles.contentContainer}>
          {USER_COLORS.map(({ primary }, index) => (
            <PressableIcon
              key={`ColorPickerElement#${index}`}
              icon={['fas', 'circle']}
              color={primary}
              onPress={() => {
                setColor(primary)
                handleClose()
              }}
              pressableStyle={styles.pressableStyle}
              style={styles.pressable}
            />
          ))}
        </IM.View>
      </IM.View>
    </IM.View>
  )

  return (
    <>
      {Platform.OS === 'web' ? (
        <WebModal
          statusBarTranslucent
          isVisible={controller.isShown}
          backdropOpacity={appUtils.getModalOpacity(colorScheme)}
          propagateSwipe
          swipeDirection={[]}
          style={styles.modal}
          hideModalContentWhileAnimating
          onBackButtonPress={handleClose}
          onBackdropPress={handleClose}>
          {content}
        </WebModal>
      ) : (
        <Modal statusBarTranslucent visible={controller.isShown} onRequestClose={handleClose} transparent>
          {content}
        </Modal>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  headerText: {
    fontSize: IMStyle.typography.fontSizeRegular + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },

  modal: { margin: 0 },
  centeredView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  modalView: {
    borderRadius: 20,
    padding: 35,
    paddingTop: 18,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '90%',
    maxWidth: 400,
  },
  contentContainer: { flexWrap: 'wrap', flexDirection: 'row' },
  pressableStyle: { padding: 6 },
  pressable: { paddingRight: 6 },
})
