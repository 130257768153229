import { useDidUpdate, useEvent, Utils } from '@infominds/react-native-components'
import lodash from 'lodash'
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'

import { api } from '../../apis/apiCalls'
import { GetArticleRequest } from '../../apis/types/apiRequestTypes'
import { ActivityArticle, Article, ArticlePriceList, ArticlePriceListNode, ErgoTask } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import useSearch from '../../components/screen/hooks/useSearch'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { ForceLayoutType } from '../../hooks/useLayout'
import useVibrationFeedback from '../../hooks/useVibrationFeedback'
import { ActivityId, ArticleSearchMode, InfiniteLoadingType, LoadingType, UploadStatus } from '../../types'
import { articleUtils } from '../../utils/ArticleUtils'
import { priceListUtils } from '../../utils/PriceListUtils'

type ArticleSearchContextProps = PropsWithChildren<{
  activity?: ActivityId
  ergoTask: ErgoTask | null | undefined
  initSelectedArticles: ActivityArticle[]
  setStatus: (newStatus: UploadStatus) => void
  onDone: () => void
  forceLayout?: ForceLayoutType
}>

export type ArticleSearchContextResult = {
  selectedArticles: React.MutableRefObject<Partial<ActivityArticle>[]>
  activity: ActivityId | undefined
  taskId: number | undefined
  articles: Article[]
  priceListArticles: Article[] | undefined
  historyArticles: Article[]
  loadingArticles: InfiniteLoadingType
  loadingHistoryArticles: InfiniteLoadingType
  loadMoreArticles: () => void
  loadMoreHistoryArticles: () => void
  refreshArticles: () => void
  refreshHistoryArticles: () => void
  allArticlesLoaded: boolean
  allHistoryArticlesLoaded: boolean
  priceList: ArticlePriceList | null | undefined
  refreshPriceList: () => void
  loadingPriceList: LoadingType
  loadingPriceListArticles: LoadingType | InfiniteLoadingType
  openCategories: string[]
  setOpenCategories: React.Dispatch<React.SetStateAction<string[]>>
  loadPriceListArticles: (request: ArticlePriceListNode, reload?: boolean) => void
  selectedCategory: ArticlePriceListNode | undefined
  setSelectedCategory: React.Dispatch<React.SetStateAction<ArticlePriceListNode | undefined>>
  submitArticles: () => void
  setStatus: (newStatus: UploadStatus) => void
  forceLayout?: ForceLayoutType
  lastArticlesRequest: GetArticleRequest | null
  ergoTask: ErgoTask | null | undefined
}

export const ArticleSearchContext = React.createContext<ArticleSearchContextResult | null>(null)

function ArticleSearchContextProviderLogic({
  children,
  activity,
  ergoTask,
  initSelectedArticles,
  setStatus,
  onDone,
  forceLayout,
}: ArticleSearchContextProps) {
  // using useRef here to optimize performance. values are saved in state by ArticleSelectionCard. This is a huge performance increase since no re-rendering happens when the quantity of an article is changed
  const selectedArticles = useRef<Partial<ActivityArticle>[]>(lodash.cloneDeep(initSelectedArticles))
  const [openCategories, setOpenCategories] = useState<string[]>([])
  const [selectedCategory, setSelectedCategory] = useState<ArticlePriceListNode | undefined>()
  const { search } = useSearch()
  const { vibrationFeedback } = useVibrationFeedback()
  const wareHouseId = useMemo(
    () => (ergoTask?.paramList?.WAREHOUSEID ? Utils.parseIntegerFromText(ergoTask?.paramList?.WAREHOUSEID) : undefined),
    [ergoTask?.paramList?.WAREHOUSEID]
  )
  const canUseWarehouseFromGoodCategory = !!ergoTask?.paramList?.CAN_USE_WAREHOUSEID_FROM_GOODCATEGORY

  // article full-search
  const {
    item: articles,
    loadItem: loadArticles,
    loading: loadingArticles,
    loadMore: loadMoreArticles,
    allDataLoaded: allArticlesLoaded,
    lastSuccessfulRequest: lastArticlesRequest,
  } = useInfiniteLoader(api.articles.getList, { chuckSize: search ? 1000000 : CONSTANTS.defaultChunkSize })

  // article search with levels from task
  const { item: priceList, loadItem: loadPriceList, loading: loadingPriceList } = useControlledLoader(api.articles.priceList.get)
  const {
    item: priceListArticles,
    loadItem: getPriceListArticles,
    loading: loadingPriceListArticles,
  } = useControlledLoader(api.articles.priceList.getArticles, { caching: true, idProvider: item => item.articleId })
  const filteredPriceList = useMemo(() => {
    const cleanedPriceList = !!priceList && priceListUtils.cleanPriceList(priceList)
    if (!cleanedPriceList || !cleanedPriceList.categoryList || !Object.values(cleanedPriceList.categoryList).length) return undefined
    if (!search) return cleanedPriceList
    const result = priceListUtils.reducePriceListByArticles(cleanedPriceList, articles)
    if (result) setOpenCategories(priceListUtils.getAllCategoryIdsFromPriceList(result))
    return result
  }, [search, priceList, articles])

  // article search in history of activities
  const {
    item: historyArticles,
    loadItem: loadHistoryArticles,
    loading: loadingHistoryArticles,
    loadMore: loadMoreHistoryArticles,
    allDataLoaded: allHistoryArticlesLoaded,
  } = useInfiniteLoader(api.articles.getHistory, { chuckSize: CONSTANTS.defaultChunkSize })

  useEffect(refreshPriceList, [])
  function refreshPriceList() {
    if (!ergoTask) return
    loadPriceList({ taskId: ergoTask.id })
  }

  useEffect(refreshArticles, [search])
  function refreshArticles() {
    loadArticles({
      searchtext: search,
      returnGoodCategory: true,
      validTo: true,
      wareHouseId,
      canUseWarehouseFromGoodCategory,
      returnSupplyArticle: true,
    })
  }

  useEffect(refreshHistoryArticles, [activity, search])
  function refreshHistoryArticles() {
    if (!activity) return
    loadHistoryArticles({ ...activity, searchtext: search })
  }

  useDidUpdate(() => {
    if (!search) {
      setOpenCategories([])
      setSelectedCategory(undefined)
    }
    if (search) setSelectedCategory(undefined)
  }, [search])

  function loadPriceListArticles(request: ArticlePriceListNode) {
    if (!priceList?.priceListId) {
      return
    }
    getPriceListArticles({
      priceListId: priceList.priceListId,
      // validTo: true,
      goodCategoryId1: request.goodCategoryId1 ?? -1,
      goodCategoryId2: request.goodCategoryId2 ?? -1,
      goodCategoryId3: request.goodCategoryId3 ?? -1,
      goodCategoryId4: request.goodCategoryId4 ?? -1,
      returnGoodCategory: true,
      wareHouseId,
      canUseWarehouseFromGoodCategory,
    })
  }

  const activityArticlesEvent = useEvent({ key: EVENT_KEYS.ACTIVITY_ARTICLES_UPDATED })
  const { request: submitArticles } = useRequest(createOrUpdateActivityArticles, {
    showErrorAlert: true,
    onError: () => setStatus('waiting'),
    onSuccess: () => {
      onDone()
      setStatus('done')
      activityArticlesEvent.emit()
      vibrationFeedback()
    },
  })
  async function createOrUpdateActivityArticles() {
    if (!activity) return
    setStatus('uploading')
    await articleUtils.createOrUpdateArticles(selectedArticles.current ?? [], activity, initSelectedArticles)
  }

  return (
    <ArticleSearchContext.Provider
      value={{
        selectedArticles,
        activity,
        taskId: ergoTask?.id,
        articles,
        priceListArticles,
        historyArticles,
        loadingArticles,
        loadingHistoryArticles,
        refreshArticles,
        refreshHistoryArticles,
        loadMoreArticles,
        loadMoreHistoryArticles,
        allArticlesLoaded,
        allHistoryArticlesLoaded,
        priceList: filteredPriceList,
        refreshPriceList,
        loadingPriceList,
        loadingPriceListArticles: search ? loadingArticles : loadingPriceListArticles,
        openCategories,
        setOpenCategories,
        loadPriceListArticles,
        selectedCategory,
        setSelectedCategory,
        submitArticles: () => submitArticles({}),
        setStatus,
        forceLayout,
        lastArticlesRequest,
        ergoTask,
      }}>
      {children}
    </ArticleSearchContext.Provider>
  )
}

export function ArticleSearchContextProvider(props: ArticleSearchContextProps) {
  return (
    <SearchProvider
      defaultMode={
        !!props.ergoTask?.paramList?.PRICELISTID && props.ergoTask.paramList.PRICELISTID !== '0' ? 'catalogue' : ('all' as ArticleSearchMode)
      }>
      <ArticleSearchContextProviderLogic {...props} />
    </SearchProvider>
  )
}
