import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Button, Image, StyleSheet } from 'react-native'

import './NotFoundScreen.scss'

import { useNavigation } from '@react-navigation/native'

import { ThemeColorExpanded } from '../../types'

export default function NotFoundScreen() {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={[styles.container, { backgroundColor: theme.general.info }]}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <Image source={{ uri: require('../../assets/img/launch_screen.png') }} style={styles.base} />
      <IM.View style={styles.textContainer}>
        <IM.Text style={styles.title}>{i18n.t('PAGE_NOT_FOUND')}</IM.Text>
        <IM.View style={styles.descriptionContainer}>
          <IM.Text style={styles.description}>{i18n.t('PAGE_NOT_FOUND_DESCRIPTION')}</IM.Text>
        </IM.View>
      </IM.View>
      <IM.View style={styles.buttonContainer}>
        <Button
          title="Home"
          color="#343b47"
          onPress={() => navigation.navigate('BottomTab', { screen: 'ActivitiesStack', params: { screen: 'Activities' } })}
        />
        <Button title="Go Back" color={theme.general.info} onPress={() => history.back()} />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  base: {
    width: 200,
    aspectRatio: 2.055,
  },
  buttonContainer: {
    marginTop: 24,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    fontSize: 17,
  },
  descriptionContainer: {
    marginTop: 8,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  textContainer: {
    minWidth: 440,
    width: '70%',
    alignItems: 'center',
    marginTop: 24,
    textAlign: 'center',
  },
  title: {
    fontWeight: IMStyle.typography.fontWeightBold,
    fontSize: IMStyle.typography.fontSizeMedium,
  },
})
