import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { SupplierArticle } from '../../apis/types/apiResponseTypes'

export type SupplierArticleCardProps = { supplierArticle: SupplierArticle } & CardProps

export default function SupplierArticleCard({ supplierArticle, ...cardProps }: SupplierArticleCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.TextWithIcon icon={['fal', 'barcode']} primary>
        {supplierArticle.supplyArticleId}
      </IM.TextWithIcon>
      {!!supplierArticle.supplyArticleDescription && <IM.Text secondary>{supplierArticle.supplyArticleDescription}</IM.Text>}
    </IM.Card>
  )
}
