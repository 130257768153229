import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import {
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput,
  TextInputEndEditingEventData,
  TextInputFocusEventData,
  TextInputProps,
  ViewStyle,
} from 'react-native'

import useExtendedTheme from '../hooks/useExtendedTheme'
import { numberUtils } from '../utils/NumberUtils'

export type BaseNumberInputProps = {
  value: number | undefined
  onChangeValue: (value: number) => void
  containerStyle?: StyleProp<ViewStyle>
  unit?: ReactNode
  minValue?: number
  maxValue?: number
  internalValueFormatter?: (value: number) => string
} & Omit<TextInputProps, 'value' | 'onChangeValue'> &
  Pick<ViewProps, 'spacing'>

export function BaseNumberInput({
  value,
  onChangeValue,
  style,
  inputMode,
  onEndEditing,
  spacing,
  containerStyle,
  unit,
  minValue,
  maxValue,
  internalValueFormatter,
  ...textProps
}: BaseNumberInputProps) {
  const [internalValue, setInternalValue] = useState(value?.toString() ?? '')
  const displayedText = useMemo(() => internalValue.replace('.', ','), [internalValue])
  const { theme } = useExtendedTheme()
  const inputRef = useRef<TextInput | null>(null)

  function formatAndSetInternalValue(newValue: number | undefined) {
    const newValueAsString = internalValueFormatter && newValue !== undefined ? internalValueFormatter(newValue) : newValue?.toString() ?? ''
    setInternalValue(newValueAsString)
  }

  useEffect(() => {
    if (inputRef.current && !inputRef.current.isFocused()) formatAndSetInternalValue(value)
  }, [value])

  function parseText(text: string) {
    const parsedValue = numberUtils.parseFloatFromText(text)
    if (parsedValue === undefined || Number.isNaN(parsedValue)) return null
    return parsedValue
  }

  function handleChangeText(text: string) {
    setInternalValue(text)
    const parsedValue = parseText(text)
    if (parsedValue === null) return
    onChangeValue(parsedValue)
  }

  function handleEndEditing(e: NativeSyntheticEvent<TextInputEndEditingEventData | TextInputFocusEventData>) {
    let finalValue = parseText(e.nativeEvent.text) ?? 0
    if (minValue !== undefined) finalValue = Math.max(finalValue, minValue)
    if (maxValue !== undefined) finalValue = Math.min(finalValue, maxValue)
    formatAndSetInternalValue(finalValue)
    onChangeValue(finalValue)
    onEndEditing?.(e)
  }

  return (
    <IM.View spacing={spacing} style={[IMLayout.flex.row, containerStyle]}>
      <TextInput
        ref={inputRef}
        value={displayedText}
        onChangeText={handleChangeText}
        onEndEditing={Platform.OS !== 'web' ? handleEndEditing : undefined}
        onBlur={Platform.OS === 'web' ? handleEndEditing : undefined}
        style={[{ color: theme.text, backgroundColor: theme.input.background }, style]}
        inputMode={inputMode ?? 'decimal'}
        {...textProps}
      />
      {unit}
    </IM.View>
  )
}
