import { IM, SpacingProps } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { TextStyle } from 'react-native'

import Switch from '../Switch'
import BaseTextInputTitle from './baseTextInput/BaseTextInputTitle'

export interface BooleanInputProps {
  value: boolean
  onValueChange: (value: boolean) => void
  title?: string
  titleFontWeight?: TextStyle['fontWeight']
  disabled?: boolean
  spacing?: SpacingProps
}

const BooleanInput = memo(function BooleanInput({ value, title, titleFontWeight, disabled, spacing, onValueChange }: BooleanInputProps) {
  return (
    <IM.View spacing={spacing}>
      {title && <BaseTextInputTitle title={title} fontWeight={titleFontWeight} />}
      <Switch value={value} onValueChange={onValueChange} disabled={disabled} />
    </IM.View>
  )
})

export default BooleanInput
