import { IM, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import { Tooltip } from 'antd'
import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { UnavailableType } from '../apis/types/apiResponseTypes'
import CardLeftTitle from '../components/card/left/CardLeftTitle'
import Text from '../components/Text'
import useLanguageSelect from '../hooks/useLanguageSelect'
import { ThemeColorExpanded } from '../types'

const styles = StyleSheet.create({
  card: { minWidth: 100 },
  noteContainer: { maxWidth: 200, marginTop: 2 },
  italic: { fontStyle: 'italic' },
  pressable: { top: -5, right: -5, position: 'absolute', borderRadius: 50, zIndex: 10 },
})

export interface AbsenceCardProps {
  absence: UnavailableType
  spacing?: SpacingProps
}

export default function AbsenceCard({ absence, spacing }: AbsenceCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { value: title } = useLanguageSelect({ en: absence.titleEn, it: absence.titleIt, de: absence.titleDe })
  const { value: note } = useLanguageSelect({ en: absence.noteEn, it: absence.noteIt, de: absence.noteDe })

  const tooltipContainer = () => <Text style={{ fontSize: IMStyle.typography.fontSizeSmall - 2 }}>{note}</Text>

  return (
    <IM.Card head={<CardLeftTitle color={absence.planColor} />} noContentSpacing spacing={spacing} style={styles.card}>
      {note && (
        <Tooltip title={tooltipContainer} color={theme.card.background}>
          <Pressable style={[styles.pressable, { backgroundColor: theme.card.background }]}>
            <IM.Icon icon={['fas', 'circle-info']} color={theme.textDetail} size={16} />
          </Pressable>
        </Tooltip>
      )}
      <IM.View spacing="all">
        <Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{title}</Text>
        <Text style={[{ fontSize: IMStyle.typography.fontSizeSmall - 2 }, styles.italic]} secondary>
          {absence.type}
        </Text>
      </IM.View>
    </IM.Card>
  )
}
