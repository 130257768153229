import { useLanguage } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { UnavailableType } from '../../../apis/types/apiResponseTypes'
import { SearchProvider } from '../../../components/screen/contexts/SearchContext'
import { Screen } from '../../../components/screen/Screen'
import { FormProvider } from '../../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../../hooks/useEditOrCreateScreenBackManager'
import { RootStackParamList } from '../../../navigation/types'
import { EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../../types'
import UnavailableTypeCreateOrEditView from '../../../views/activities/unavailables/UnavailableTypeCreateOrEditView'

const UnavailableTypeCreationScreen = ({ controller }: ModalScreenProps<UnavailableType | undefined>, ref: ForwardedRef<ModalScreenRef>) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()
  const route = useRoute<RouteProp<RootStackParamList, 'UnavailableTypeCreateOrEdit'>>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined
  const unavailableToEdit = isModal ? controller.data : route?.params.unavailableType

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal={isModal}
          title={unavailableToEdit ? i18n.t('EDIT_UNAVAILABLE_TYPE') : i18n.t('CREATE_UNAVAILABLE_TYPE')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            <UnavailableTypeCreateOrEditView
              unavailableType={unavailableToEdit}
              ref={createViewRef}
              onDone={() => handleGoBack(true)}
              onUploadStatus={setStatus}
            />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(UnavailableTypeCreationScreen)
