import { Utils } from '@infominds/react-native-components'

import { Article, ArticleGoodCategory, ArticlePriceList, ArticlePriceListCategories, ArticlePriceListNode } from '../apis/types/apiResponseTypes'
import { articleUtils } from './ArticleUtils'

export const priceListUtils = {
  hasValidGoodCategory: (value: ArticleGoodCategory | ArticlePriceListNode | undefined) => {
    return !!value && !![value.goodCategoryId1, value.goodCategoryId2, value.goodCategoryId3, value.goodCategoryId4].find(g => !!g && g > 0)
  },
  cleanPriceList(priceList: ArticlePriceList): ArticlePriceList {
    const categories: ArticlePriceListCategories = {}
    if (!priceList.categoryList) return priceList
    Object.entries(priceList.categoryList).forEach(([key, value]) => {
      if (!this.hasValidGoodCategory(value)) return
      Object.assign(categories, { [key]: value })
    })
    return { ...priceList, categoryList: categories }
  },
  reducePriceListByArticles(priceList: ArticlePriceList | null | undefined, articles: Article[]): ArticlePriceList | undefined {
    if (!priceList || !articles?.length) return undefined
    const result = filterCategoriesByArticles(priceList.categoryList, articles)
    if (result) {
      return { ...priceList, categoryList: result }
    }
    return undefined
  },
  getAllCategoryIdsFromPriceList(priceList: ArticlePriceList) {
    if (!priceList) return []
    return Utils.keepUniques(
      reduceCategories(priceList.categoryList, v => v.levelRowId),
      item => item
    )
  },
  getAllGoodCategoriesFromPriceList(priceList: ArticlePriceList) {
    if (!priceList) return []
    return Utils.keepUniques(
      reduceCategories<ArticleGoodCategory>(priceList.categoryList, v => ({
        goodCategoryId1: v.goodCategoryId1 ?? -1,
        goodCategoryId2: v.goodCategoryId2 ?? -1,
        goodCategoryId3: v.goodCategoryId3 ?? -1,
        goodCategoryId4: v.goodCategoryId4 ?? -1,
      })),
      item => item
    )
  },
}

function filterCategoriesByArticles(
  categoryList: ArticlePriceListCategories | null | undefined,
  articles: Article[]
): ArticlePriceListCategories | undefined {
  if (!categoryList) return undefined
  const elements = Object.entries(categoryList)
  const result: ArticlePriceListCategories = {}

  elements.forEach(([key, element]) => {
    if (!element) return

    if (element.categoryNodeList) {
      const subResult = filterCategoriesByArticles(element.categoryNodeList, articles)
      if (subResult) Object.assign(result, { [key]: { ...element, categoryNodeList: subResult } })
      return
    }

    if (articles.find(a => articleUtils.compareGoodCategories(a.goodCategory, element))) {
      Object.assign(result, { [key]: element })
    }
  })
  if (Object.values(result).length) return result
  return undefined
}

/**
 * Iterates recursively through all categories and sub categories and returns a list of the reduced values
 * @returns Array of reduced values
 */
function reduceCategories<T = string>(
  categoryList: ArticlePriceListCategories | null | undefined,
  valueProvider: (value: ArticlePriceListNode) => T | null
): T[] {
  if (!categoryList) return []
  const elements = Object.values(categoryList)
  const result: T[] = []
  elements.forEach(element => {
    if (!element) return
    const value = valueProvider(element)
    if (value !== null) {
      result.push(value)
    }
    result.push(...reduceCategories<T>(element.categoryNodeList, valueProvider))
  })
  return result
}
