import { IM, IMLayout } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import ActivityDetailArticleCard from '../../cards/activities/detail/ActivityDetailArticleCard'
import ActivityDetailConstructionSiteIdCard from '../../cards/activities/detail/ActivityDetailConstructionSiteIdCard'
import ActivityDetailContactIdCard from '../../cards/activities/detail/ActivityDetailContactIdCard'
import ActivityDetailContractIdCard from '../../cards/activities/detail/ActivityDetailContractIdCard'
import ActivityDetailCustomerIdCard from '../../cards/activities/detail/ActivityDetailCustomerIdCard'
import ActivityDetailDescriptionCard from '../../cards/activities/detail/ActivityDetailDescriptionCard'
import ActivityDetailDestinationIdCard from '../../cards/activities/detail/ActivityDetailDestinationIdCard'
import ActivityDetailDeviceCard from '../../cards/activities/detail/ActivityDetailDeviceCard'
import ActivityDetailInfoCard from '../../cards/activities/detail/ActivityDetailInfoCard'
import ActivityDetailNotesCard from '../../cards/activities/detail/ActivityDetailNotesCard'
import ActivityDetailTimesCard from '../../cards/activities/detail/ActivityDetailTimesCard'
import ActivityDetailTravelCard from '../../cards/activities/detail/ActivityDetailTravelCard'
import ActivitySummaryCard from '../../cards/activities/detail/ActivitySummaryCard'
import FollowupActivityCard from '../../cards/activities/detail/FollowupActivityCard'
import DynamicView from '../../components/Infominds/DynamicView'
import ListSpacer from '../../components/ListSpacer'
import ScrollViewData from '../../components/ScrollViewData'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useLayout from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import { ActivityId } from '../../types'

type Props = ActivityId

export default function HistoryDetailView({ srvActivityId, srvActivityTypeId, srvActivityYear }: Props) {
  const { isLargeDevice } = useLayout(true)
  const activityId = useMemo(() => ({ srvActivityId, srvActivityTypeId, srvActivityYear }), [srvActivityId, srvActivityTypeId, srvActivityYear])
  const { detail, refreshDetail, loadingDetail } = useActivityDetail(activityId)
  const { isOnlyReport } = useUserSettings()

  const showContactCard = !!detail?.contactId
  const showDestinationCard = !!detail?.destinationId
  const showConstructionSiteCard = !!detail?.objectId || !!detail?.objectLotId

  return (
    <>
      <ScrollViewData refresh={refreshDetail} loading={loadingDetail}>
        {detail && (
          <>
            <ActivityDetailInfoCard type="normal" activity={detail} assignable={false} />
            {!isOnlyReport && <FollowupActivityCard activity={detail} spacing={'top'} />}
            <DynamicView rowStyle={{ gap: IMLayout.horizontalMargin }}>
              <IM.View style={IMLayout.flex.f6}>
                <ActivityDetailCustomerIdCard spacing="top" style={styles.grow} disabled />
              </IM.View>

              {showContactCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailContactIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}

              {showDestinationCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailDestinationIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}
              {showConstructionSiteCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailConstructionSiteIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}
              {detail.device && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailDeviceCard style={IMLayout.flex.f1} spacing={'top'} device={detail.device} />
                </IM.View>
              )}
              {(!!detail.description || !!detail.technicalDescription) && (
                <IM.View style={IMLayout.flex.f12}>
                  <ActivityDetailDescriptionCard
                    description={detail.description}
                    tecDescription={detail.technicalDescription}
                    spacing="top"
                    customerDescription={undefined}
                    numberOfLines={5}
                  />
                </IM.View>
              )}
              {!!detail.srvContractId && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailContractIdCard spacing="top" style={styles.grow} />
                </IM.View>
              )}
            </DynamicView>

            <DynamicView
              flexLimitLargeDevice={Platform.OS === 'web' && isLargeDevice ? 12 : 6}
              rowStyle={{ gap: IMLayout.horizontalMargin }}
              spacing={'top'}>
              <IM.View style={IMLayout.flex.f6}>
                <ActivityDetailArticleCard spacing={'bottom'} enableChange={false} />
                {isLargeDevice && <ActivityDetailTravelCard spacing={'bottom'} enableChange={false} />}
                {!isLargeDevice && <ActivityDetailTimesCard spacing={'bottom'} enableChange={false} />}
                {Platform.OS === 'web' && <ActivitySummaryCard />}
              </IM.View>
              <IM.View style={IMLayout.flex.f6}>
                {isLargeDevice && <ActivityDetailTimesCard spacing={'bottom'} enableChange={false} />}
                {!isLargeDevice && <ActivityDetailTravelCard spacing={'bottom'} enableChange={false} />}
                {Platform.OS !== 'web' && <ActivitySummaryCard spacing={'bottom'} />}
                <ActivityDetailNotesCard spacing="bottom" style={[styles.notesCard]} enableChange={false} />
              </IM.View>
            </DynamicView>

            <ListSpacer height={100} />
          </>
        )}
      </ScrollViewData>
    </>
  )
}

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
  },
  notesCard: {
    flexShrink: 1,
  },
})
