import { useModalController } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import { Activity } from '../../apis/types/apiResponseTypes'
import ActivityCreationModal from '../../modals/activity/ActivityCreationModal'
import { ActivityCreateOrEditViewProps } from '../../views/activities/ActivityCreateOrEditView'
import SelectTextInput from './SelectTextInput'
import { TextInputProps } from './TextInput'

type ActivityInputProps = {
  preSetActivity?: Activity
  activity: Activity | undefined
  onChange: (activity: Activity | undefined) => void
  title?: string
} & Pick<TextInputProps, 'disableBorderRadius' | 'details' | 'placeholder' | 'spacing' | 'title'>

export default function ActivityInput({ preSetActivity, activity, onChange, ...textProps }: ActivityInputProps) {
  const createActivityModal = useModalController<ActivityCreateOrEditViewProps>()

  return (
    <View>
      <SelectTextInput
        onReset={() => onChange(undefined)}
        value={activity?.title}
        onPress={() => createActivityModal.show({ presetActivity: activity ?? preSetActivity })}
        editable
        {...textProps}
      />
      <ActivityCreationModal
        controller={createActivityModal}
        handleCreateActivity={value => {
          createActivityModal.close()
          onChange(value)
        }}
      />
    </View>
  )
}
