import { IM, useAlert, useLanguage, useTheme, useToastMessage } from '@infominds/react-native-components'
import React from 'react'

import { ExceptionUtils } from '../../utils/ExceptionUtils'

export default function useEmailNotifications() {
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const toast = useToastMessage({ placement: 'top', icon: <IM.Icon icon={['fal', 'paper-plane']} color="#fff" />, normalColor: theme.primary })
  const { alert } = useAlert()

  function onSuccess() {
    toast.show(i18n.t('EMAIL_SENT_ALERT'))
  }

  function onError(error: string | unknown) {
    const message = typeof error === 'string' ? error : ExceptionUtils.exceptionToString(error)
    alert(i18n.t('EMAIL_ERROR_ALERT'), message)
  }

  return { onSuccess, onError }
}
