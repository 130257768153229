import { DefaultTheme, IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, Pressable, PressableProps, StyleSheet } from 'react-native'

import useExtendedTheme from '../../hooks/useExtendedTheme'
import BaseTextInputTitle from '../input/baseTextInput/BaseTextInputTitle'

export type SelectorBarValue<T> = {
  value: T
  text: string
}

export type SelectorBarProps<T> = {
  title?: string
  values: SelectorBarValue<T>[]
  selectedValues?: T[]
  onValuePressed: (value: T) => void
  required?: boolean
} & Pick<ViewProps, 'spacing' | 'style'>

export default function SelectorBar<T>({ style, spacing, values, selectedValues, onValuePressed, title, required }: SelectorBarProps<T>) {
  return (
    <IM.View style={[style]} spacing={spacing}>
      {!!title && <BaseTextInputTitle title={title} required={required} />}
      <IM.View style={[IMLayout.flex.row]}>
        {values.map((element, index) => (
          <SelectorBarElement
            key={element.text}
            element={element}
            isFirst={index === 0}
            isLast={index === values.length - 1}
            selectedValues={selectedValues}
            onPress={() => onValuePressed(element.value)}
          />
        ))}
      </IM.View>
    </IM.View>
  )
}

type SelectorBarElementProps<T> = {
  element: SelectorBarValue<T>
  isFirst: boolean
  isLast: boolean
} & Pick<SelectorBarProps<T>, 'selectedValues'> &
  Pick<PressableProps, 'onPress' | 'disabled'>
function SelectorBarElement<T>({ element, isFirst, isLast, selectedValues, ...pressableProps }: SelectorBarElementProps<T>) {
  const { theme } = useExtendedTheme()

  const isSelected = useMemo(() => selectedValues?.find(q => q === element.value) !== undefined, [selectedValues])

  return (
    <Pressable
      // @ts-ignore it works but we need to discover how to implement it on ts
      style={({ pressed, hovered }) => [
        {
          backgroundColor: (Platform.OS === 'web' ? hovered : pressed) ? theme.button.pressedOpacity : theme.inputBox.background.active,
          opacity: pressed ? 0.5 : 1,
          borderColor: theme.inputBox.border.active,
        },
        isSelected && {
          backgroundColor: theme.filterTag.active.background,
        },
        styles.element,
        isFirst && styles.firstElement,
        isLast && styles.lastElement,
      ]}
      {...pressableProps}>
      <IM.Text numberOfLines={1} style={[isSelected && { color: DefaultTheme.dark.text }]}>
        {element.text}
      </IM.Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  element: {
    flexGrow: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 0,
  },
  firstElement: {
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
  lastElement: {
    borderRightWidth: 1,
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
  },
})
