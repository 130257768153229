import { Utils, type AlertContextProps, type Language } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Route } from '@react-navigation/native'
import Color from 'color'
import * as deLocale from 'dayjs/locale/de'
import * as enLocale from 'dayjs/locale/en'
import * as itLocale from 'dayjs/locale/it'
import { i18n } from 'i18next'
import lodash from 'lodash'
import { ColorSchemeName, Linking, NativeScrollEvent, NativeSyntheticEvent } from 'react-native'
import { AtomEffect, DefaultValue, WrappedValue } from 'recoil'
import { serializeError } from 'serialize-error'

import CONSTANTS from '../constants/Constants'
import { ApiError, ListSection, LoadingType, ThemeColorExpanded } from '../types'

type AbortError = {
  name: string
}

type setSelfType<T> =
  | T
  | DefaultValue
  | Promise<T | DefaultValue>
  | WrappedValue<T>
  | ((param: T | DefaultValue) => T | DefaultValue | WrappedValue<T>)

const appUtils = {
  getDayjsLocale(appLocale: Language) {
    switch (appLocale) {
      case 'en':
        return enLocale
      case 'it':
        return itLocale
      case 'de':
        return deLocale
    }
  },
  capitalizeFirstLetter(str: string) {
    if (str[0]) return str[0].toUpperCase() + str.slice(1)

    return str
  },
  localStorageEffect<T>(key: string): AtomEffect<T> {
    return ({ setSelf, onSet }) => {
      AsyncStorage.getItem(key)
        .then(savedValue => {
          if (savedValue !== null) {
            setSelf(JSON.parse(savedValue) as setSelfType<T>)
          }
        })
        .catch(err => console.error(`Failed fetching ${key}:`, err))

      onSet((newValue, _, isReset) => {
        if (isReset) {
          AsyncStorage.removeItem(key).catch(err => console.error(`Failed resetting ${key}:`, err))
        } else {
          AsyncStorage.setItem(key, JSON.stringify(newValue)).catch(err => console.error(`Failed saving ${key}:`, err))
        }
      })
    }
  },
  insert<T>(arr: T[], index: number, newItem: T) {
    return [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index),
    ]
  },
  isAbortError(err: AbortError | unknown): err is AbortError {
    return (err as AbortError).name !== undefined && (err as AbortError).name === 'AbortError'
  },
  calculateInitials(inputString: string) {
    if (!inputString) return ''
    const simplifiedString = inputString.replace(/[^a-zA-Z0-9]/g, '')
    let initial = ''

    if (simplifiedString.length === 0) {
      initial = Math.random().toString(36).slice(2).substring(2, 4).toUpperCase()
    } else if (simplifiedString.length === 1) {
      initial = simplifiedString[0] + Math.random().toString(36).slice(2).substring(2, 3).toUpperCase()
    } else {
      initial = `${simplifiedString[0]}${simplifiedString[1]}`
    }

    return initial
  },
  /**
   * @deprecated use objectUtils.filterItem instead
   */
  filter: <T>(data: T[], value: string, keys: (keyof T)[]) => {
    if (value === '') {
      return data
    }

    const lowerCaseSearchValue = value.toLowerCase()

    return data.filter(elem => {
      let found = false

      keys.forEach(key => {
        const objectElem = elem[key]

        if (typeof objectElem === 'string' && found === false) {
          found = objectElem.toLowerCase().includes(lowerCaseSearchValue)
        }
      })

      return found
    })
  },
  group: <T>(data: T[], groupByKey: keyof T, noGroupPlaceholder?: string) => {
    const toRet: ListSection<T>[] = []
    const lastElem: ListSection<T>[] = []

    const groups = lodash.groupBy(data, groupByKey)

    for (const key of Object.keys(groups)) {
      if (key === 'undefined' && noGroupPlaceholder) {
        lastElem.push({ title: noGroupPlaceholder, data: groups[key] })
      } else {
        toRet.push({ title: key, data: groups[key] })
      }
    }

    toRet.sort((a, b) => {
      if (!a.title || !b.title) return 0

      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1

      return 0
    })

    return [...toRet, ...lastElem]
  },
  arrayMove<T>(arr: (T | undefined)[], oldIndex: number, newIndex: number) {
    while (oldIndex < 0) {
      oldIndex += arr.length
    }

    while (newIndex < 0) {
      newIndex += arr.length
    }

    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  },
  openUrl: (url: string, alert: AlertContextProps, errorMessage: string) => {
    if (!url.includes('http://') && !url.includes('https://')) {
      url = 'https://' + url
    }

    Linking.openURL(url).catch(() => alert.alert(Utils.stringValueReplacer(errorMessage, url)))
  },
  openEmail: (alert: AlertContextProps, errorMessage: string, address?: string, body?: string, subject?: string) => {
    if (address === undefined && body === undefined && subject === undefined) throw new Error('body or email must be defined')

    if (address && !address.includes('mailto:')) {
      address = 'mailto:' + address

      if (body) address + '&body=' + encodeURIComponent(body)
      if (subject) address += '&subject=' + subject
    }

    if (address === undefined && body) {
      address = 'mailto:?body=' + encodeURIComponent(body)
      if (subject) address += '&subject=' + subject
    }

    address &&
      Linking.openURL(address).catch(() => {
        return alert.alert(Utils.stringValueReplacer(errorMessage, address))
      })
  },
  openPhone: (number: string, alert: AlertContextProps, errorMessage: string) => {
    const original = number

    if (/\d/.test(number)) {
      if (!number.includes('tel:')) {
        number = 'tel:' + number
      }

      number = number.replace(' ', '')

      Linking.openURL(number).catch(() => alert.alert(Utils.stringValueReplacer(errorMessage, original)))
    } else {
      alert.alert(Utils.stringValueReplacer(errorMessage, original))
    }
  },
  formatFileNumber(translator: i18n, elements: number) {
    if (elements === 0) {
      return translator.t('NO_DOCUMENT')
    } else if (elements === 1) {
      return `${elements} ${translator.t('DOCUMENT_LOWER_CASE')}`
    } else {
      return `${elements} ${translator.t('DOCUMENTS_LOWER_CASE')}`
    }
  },
  infoboxAlert(alert: AlertContextProps, translator: i18n, onDiscard: () => void) {
    alert.alert(translator.t('UNSAVED_CHANGES_TITLE'), translator.t('DISCARD_UNSAVED_CHANGES'), [
      {
        text: translator.t('DISCARD'),
        onPress: onDiscard,
        style: 'destructive',
      },
      {
        text: translator.t('CANCEL'),
        style: 'cancel',
      },
    ])
  },
  closeToEndDetector(e: NativeSyntheticEvent<NativeScrollEvent>, threshold: number, allDataLoaded: boolean) {
    if (allDataLoaded) {
      let paddingToBottom = threshold
      paddingToBottom += e.nativeEvent.layoutMeasurement.height //463

      const reference = e.nativeEvent.contentSize.height - paddingToBottom

      if (reference <= 0) return true

      if (e.nativeEvent.contentOffset.y >= reference) {
        return true
      }
    }

    return false
  },
  sortAlphabetically(array: string[]) {
    return array.sort((a, b) => a.localeCompare(b))
  },
  getDocumentTitle: (route: Route<string> | undefined, translator: i18n) => {
    const appName = 'EMService'
    if (route?.name.includes('Activities')) {
      return translator.t('TAB_ACTIVITIES') + ' | ' + appName
    } else if (route?.name.includes('Customers')) {
      return translator.t('CUSTOMERS') + ' | ' + appName
    } else if (route?.name.includes('Anagrafics')) {
      return translator.t('TAB_ANAGRAFICS') + ' | ' + appName
    } else if (route?.name.includes('History')) {
      return translator.t('TAB_HISTORY') + ' | ' + appName
    } else if (route?.name.includes('Settings')) {
      return translator.t('TAB_SETTINGS') + ' | ' + appName
    } else if (route?.name.includes('Sync')) {
      return translator.t('TAB_SYNCHRONIZATION') + ' | ' + appName
    } else if (route?.name.includes('Planner')) {
      return translator.t('TAB_PLANNING') + ' | ' + appName
    } else {
      return appName
    }
  },
  mergeLoading: (loadings: LoadingType[]): LoadingType => {
    if (loadings.find(el => el === 'catched') !== undefined) return 'catched'
    if (loadings.find(el => el === 'aborted') !== undefined) return 'aborted'
    if (loadings.find(el => el === 'reloading') !== undefined) return 'reloading'
    if (loadings.find(el => el === 'init') !== undefined) return 'init'

    return loadings[0]
  },
  isBackendError(obj: unknown): obj is ApiError {
    return (obj as ApiError).Message !== undefined
  },
  getBackendErrorMessage(obj: unknown) {
    const error = serializeError(obj)
    if (appUtils.isBackendError(error)) {
      return error.Message
    } else {
      if (error.code && error.message) {
        return `${error.code} - ${error.message}`
      } else if (error.code) {
        return error.code
      } else if (error.message) {
        return error.message
      } else {
        return 'Generic error'
      }
    }
  },
  getOpacity(colorScheme: NonNullable<ColorSchemeName>) {
    return colorScheme === 'light' ? CONSTANTS.disabledOpacityLight : CONSTANTS.disabledOpacityDark
  },
  getModalOpacity(colorScheme: NonNullable<ColorSchemeName>) {
    return colorScheme === 'light' ? 0.45 : 0.65
  },
  getModalBackground(colorScheme: NonNullable<ColorSchemeName>, theme: ThemeColorExpanded) {
    return colorScheme === 'light' ? theme.background : Color(theme.header.detail.background).darken(0.3).toString()
  },
  getCardButtonColor(active: boolean | undefined, theme: ThemeColorExpanded) {
    return {
      backGroundColor: active ? theme.card.button.active : theme.card.button.inactive,
      fontColor: active ? theme.card.button.icon.active : theme.card.button.icon.inactive,
    }
  },
  parseFloatFromText(text: string) {
    return Number.parseFloat((text ?? '').replace(',', '.').replace(/[^0-9+-.]/g, ''))
  },
  parseIntegerFromText(text: string) {
    return Number.parseInt((text ?? '').replace(',', '.').replace(/[^0-9+-.]/g, ''), 10)
  },
  isValidColor(strColor: string | undefined | null) {
    if (!strColor) return false
    return !!strColor && !!strColor.match(/(^#[\dA-F]{3}$)|(^#[\dA-F]{6}$)/i)
  },
  convertImageForRequest(imageBase64: string) {
    if (!imageBase64.startsWith('data:')) return imageBase64
    return imageBase64.substring(imageBase64.indexOf(',') + 1)
  },
  formatPrice(price: number, unit: string | null = '€') {
    const priceText = price % 1 === 0 ? price.toString() : price.toFixed(2)
    return `${priceText.replace('.', ',')}${unit ? ` ${unit}` : ''}`
  },
  createSimpleHash<T extends object>(item: T) {
    return JSON.stringify(item)
  },
}

export default appUtils
