import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { EmailModel } from '../../apis/types/apiResponseTypes'
import EmailModelCreationScreen from '../../screens/anagrafics/EmailModelCreationScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<EmailModel | undefined>
}

export default function EmailModelCreationModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <EmailModelCreationScreen ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}
