import { IM, IMLayout, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Activity, RdaStatus } from '../../../apis/types/apiResponseTypes'
import RdaState from '../../../components/activity/rda/RdaState'
import { activityUtils } from '../../../utils/ActivityUtils'

type Props = {
  activity: Activity
  hideActivityNumber?: boolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  showRda?: boolean
}

export default function ActivityCardTitle({ activity, hideActivityNumber = false, style, showRda, ...props }: Props) {
  const { i18n } = useLanguage()

  const activityNumber = useMemo(() => activityUtils.getActivityNumber(activity, i18n), [activity, i18n])
  const rdaState = useMemo(() => activity.rdaStatus ?? RdaStatus.None, [activity])

  return (
    <IM.View style={[style]} {...props}>
      <IM.View style={[IMLayout.flex.row, styles.firstLineView]}>
        {!!showRda && rdaState !== RdaStatus.None && <RdaState rdaStatus={rdaState} style={styles.rdaState} />}
        <IM.Text primary numberOfLines={2}>
          {activity.title}
        </IM.Text>
      </IM.View>

      {hideActivityNumber === false && activity.srvActivityId && <IM.Text secondary>{activityNumber}</IM.Text>}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  firstLineView: {
    gap: IMLayout.horizontalMargin,
    alignItems: 'center',
  },
  rdaState: {
    marginTop: 1,
  },
})
