import { useDimensions, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { Animated, SectionListRenderItemInfo, StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { api } from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import CustomerContactCard from '../../cards/customer/CustomerContactCard'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import ListSpacer from '../../components/ListSpacer'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import { ADD_CONTACT_DESTINATION_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import CustomerContactCreationModal from '../../modals/customer/CustomerContactCreationModal'
import { ContactViewRef, ListSection } from '../../types'

interface Props {
  customerId: number
  show: boolean
  showTitle?: boolean
  skeletonNumber?: number
  hideSectionTitle?: boolean
  hideContactButtons?: boolean
  selectedId?: string
  onPress?: (contact: Contact) => void
  onRefresh?: () => void
  canCreateNew?: boolean
}

const buttonAnimationValue = new Animated.Value(0)

const ContactsView = (
  { show, customerId, showTitle, hideSectionTitle, hideContactButtons, skeletonNumber, onRefresh, canCreateNew }: Props,
  ref: ForwardedRef<ContactViewRef>
) => {
  useImperativeHandle(ref, () => ({
    openModal: () => contactEditOrCreateModalController.show(undefined),
  }))

  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { colorScheme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const { bottom: safeAreaInsetBottom } = useSafeAreaInsets()
  useEvent({ key: EVENT_KEYS.CONTACT_CREATED_OR_MODIFIED }, refresh)

  const contactEditOrCreateModalController = useModalController<Contact | undefined>()

  const {
    item: contacts,
    loadItem: loadContacts,
    loading,
    allDataLoaded,
    loadMore,
  } = useInfiniteLoader(api.customers.contacts.getList, { chuckSize: CONSTANTS.defaultChunkSize })

  useEffect(() => {
    refresh()
  }, [search])

  function refresh() {
    onRefresh?.()
    loadContacts({ customerId })
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<Contact, ListSection<Contact>>) => {
    return (
      <CustomerContactCard
        contact={item}
        spacing={['horizontal', 'bottom']}
        // selected={item.id === selectedId}
        hideButtons={hideContactButtons}
        onPress={() => {
          contactEditOrCreateModalController.show(item)
        }}
      />
    )
  }

  const data: ListSection<Contact>[] = useMemo(() => {
    const displayData: ListSection<Contact>[] = []

    if (contacts?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        title: showTitle ? i18n.t('CONTACTS') : undefined,
        data: contacts,
      })
    }

    return displayData
  }, [showTitle, contacts, loading])

  return (
    <>
      {show && (
        <>
          <SectionList
            contentContainerStyle={stylesList.list}
            loading={loading}
            noDataIcon={['fal', 'user-slash']}
            loadingSection={i18n.t('CONTACTS')}
            noDataSection={i18n.t('CONTACTS')}
            noDataMessage={i18n.t('NO_CONTACT_FOUND')}
            sections={data}
            onRefresh={refresh}
            renderItem={renderItem}
            skeletonElements={skeletonNumber ?? CONSTANTS.skeletonCards}
            onLoadMore={loadMore}
            allDataLoaded={allDataLoaded}
            closeEndThreshold={isSmallDevice ? 90 : undefined}
            hideButtonId={ADD_CONTACT_DESTINATION_BUTTON_ID}
            hideSectionTitle={hideSectionTitle}
            indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
            ListFooterComponent={<ListSpacer />}
          />
          {!!canCreateNew && (
            <AnimatedButton
              id={ADD_CONTACT_DESTINATION_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'plus']}
              onPress={() => contactEditOrCreateModalController.show(undefined)}
              style={{ marginBottom: safeAreaInsetBottom }}
            />
          )}
          <CustomerContactCreationModal customerId={customerId} controller={contactEditOrCreateModalController} />
        </>
      )}
    </>
  )
}

const stylesList = StyleSheet.create({
  list: { paddingTop: 4 },
})

export default forwardRef(ContactsView)
