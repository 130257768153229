import React, { createContext, PropsWithChildren, useState } from 'react'

type ContextProps = {
  signature: string | null
  setSignature: React.Dispatch<React.SetStateAction<string | null>>
  resetSignature: () => void
}

export const SignatureContext = createContext<ContextProps | undefined>(undefined)

export const SignatureProvider = ({ children }: PropsWithChildren) => {
  const [signature, setSignature] = useState<string | null>(null)

  return (
    <SignatureContext.Provider
      value={{
        signature,
        setSignature,
        resetSignature: () => setSignature(null),
      }}>
      {children}
    </SignatureContext.Provider>
  )
}
