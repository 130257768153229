import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import ActivityMediaArticleScreen from '../../screens/activity/ActivityMediaArticleScreen'
import { ActivityMediaArticleScreenProps, ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ActivityMediaArticleScreenProps>
}

export default function ActivityMediaArticleModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ActivityMediaArticleScreen ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}
