import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { DimensionValue, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'

interface Props {
  color?: string
  height?: DimensionValue
  style?: StyleProp<ViewStyle>
}

const Divider = memo(function Divider({ color, height = '60%', style }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View
      style={[
        styles.divider,
        { height, backgroundColor: color ?? theme.header.detail.background, marginHorizontal: 2 * IMLayout.horizontalMargin },
        style,
      ]}
    />
  )
})

export default Divider

const styles = StyleSheet.create({
  divider: { width: 1, borderRadius: IMLayout.borderRadius, zIndex: 10000 },
})
