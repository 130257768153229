import { RouteProp, useRoute } from '@react-navigation/native'
import React from 'react'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import HeaderWithIcons from '../../components/screen/headers/HeaderWithIcons'
import { Screen } from '../../components/screen/Screen'
import { CustomerStackParamList } from '../../navigation/types'
import CustomerDetailsView from '../../views/customer/CustomerDetailsView'

export default function CustomerDetailsScreen() {
  const route = useRoute<RouteProp<CustomerStackParamList, 'CustomerDetail'>>()

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header goBack>
          <HeaderWithIcons title={route.params.customerName} disableFilter />
        </Screen.Header>
        <Screen.DetailHeader>
          <SearchDetailHeaderContainer />
        </Screen.DetailHeader>
        <Screen.Content>
          <CustomerDetailsView customerId={route.params.customerId} />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
