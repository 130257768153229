import React, { createContext, PropsWithChildren, useState } from 'react'
import { Platform } from 'react-native'

export type ContextProps = { embed: boolean; setEmbed: (value: boolean) => void }

const EmbedContext = createContext<ContextProps | undefined>(undefined)

export const EmbedProvider = ({ children }: PropsWithChildren) => {
  const [isEmbed, setIsEmbed] = useState(Platform.OS === 'web' ? window.location.href.includes('embed') : false)

  const onSetEmbed = (value: boolean) => {
    setIsEmbed(value)
  }

  return <EmbedContext.Provider value={{ embed: isEmbed, setEmbed: onSetEmbed }}>{children}</EmbedContext.Provider>
}

export default EmbedContext
