import { useEvent } from '@infominds/react-native-components'
import { useEffect } from 'react'

import { api } from '../../apis/apiCalls'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { EVENT_KEYS } from '../../constants/EventKeys'

export default function useContractLots(contractId: number | undefined) {
  const {
    item: contractLots,
    loadItem: loadContractLots,
    loading: loadingContractLots,
    setItem: setContractLots,
  } = useControlledLoader(api.activities.contracts.lots.getList)

  useEvent({ key: EVENT_KEYS.UPDATED_CONTRACT_LOTS }, refreshObjectLots)

  useEffect(refreshObjectLots, [contractId])

  function refreshObjectLots() {
    if (!contractId) {
      setContractLots([])
      return
    }
    loadContractLots({ contractId })
  }

  return { contractLots, loadingContractLots, refreshObjectLots }
}
