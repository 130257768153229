import { useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { View } from 'react-native'

import { Contract } from '../../../apis/types/apiResponseTypes'
import SelectTextInput from '../../../components/input/SelectTextInput'
import { TextInputProps } from '../../../components/input/TextInput'
import ContractActivityCreationModal from '../../../modals/anagrafics/ContractActivityCreationModal'
import { ContractActivity } from '../../../types'
import { ContractUtils } from '../../../utils/ContractUtils'

type ContractActivitySelectorProps = {
  contractActivity: ContractActivity | undefined
  onChange: (activity: ContractActivity | undefined) => void
  noTitle?: boolean
  contract?: Contract
} & Pick<TextInputProps, 'disableBorderRadius' | 'details' | 'placeholder'>

export default function ContractActivitySelector({
  contractActivity,
  onChange,
  noTitle,
  placeholder,
  contract,
  ...textProps
}: ContractActivitySelectorProps) {
  const createActivityModal = useModalController<ContractActivity | undefined>()

  const { i18n, language } = useLanguage()

  const selectionText = useMemo(() => ContractUtils.getContractRepText(i18n, contractActivity), [contractActivity, contract, language])

  return (
    <View>
      <SelectTextInput
        onReset={() => onChange(undefined)}
        title={noTitle ? undefined : i18n.t('CREATE_ACTIVITIES')}
        value={selectionText}
        onPress={() => createActivityModal.show(contractActivity)}
        editable
        placeholder={placeholder ?? i18n.t('NO_ACTIVITIES')}
        multiline
        {...textProps}
      />
      <ContractActivityCreationModal
        controller={createActivityModal}
        onDone={activity => {
          createActivityModal.close()
          onChange(activity)
        }}
        contract={contract}
      />
    </View>
  )
}
