import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { AppStyle } from '../constants/Styles'
import useExtendedTheme from '../hooks/useExtendedTheme'
import Text from './Text'

export default function PlannerSmallScreenError() {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()

  return (
    <IM.View style={[IMLayout.flex.f1, AppStyle.center]}>
      <IM.Icon icon={['fal', 'file-slash']} size={40} color={theme.textDetail} spacing="bottom" />
      <IM.View spacing="top">
        <Text style={{ color: theme.textDetail, fontSize: IMStyle.typography.fontSizeMedium - 2 }}>{i18n.t('PLANNER_VIEW_ERROR')}</Text>
      </IM.View>
      <Text style={[styles.description, { color: theme.textPlaceholder, fontSize: IMStyle.typography.fontSizeRegular }]}>
        {i18n.t('PLANNER_VIEW_ERROR_DESCRIPTION')}
      </Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({ description: { marginTop: 2 } })
