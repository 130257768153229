import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { RdaStatus } from '../apis/types/apiResponseTypes'
import { AppStyle } from '../constants/Styles'
import useExtendedTheme from '../hooks/useExtendedTheme'
import { ApiFilterType, GroupedApiFilterTag } from '../types'
import RdaState from './activity/rda/RdaState'
import Tag from './Tag'

type Props<T> = {
  filter: GroupedApiFilterTag<T>
  isHeader?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: (id: string) => void
}

export default function ApiFilterTag<T>({ filter, isHeader, style, onPress }: Props<T>) {
  const isRda = useMemo(() => filter.data.find(d => d.type === ApiFilterType.RdaStatus), [filter])
  const icon = useMemo<IconProp | undefined>(() => {
    const filterWithIcon = filter.data.find(d => d.icon)
    if (filterWithIcon) return filterWithIcon.icon
    const type = filter.data.find(d => d.type)?.type
    switch (type) {
      case ApiFilterType.Customer:
        return ['fal', 'building']
      case ApiFilterType.ConstructionSite:
        return ['fal', 'hard-hat']
      case ApiFilterType.Lotto:
        return ['fal', 'briefcase']
      case ApiFilterType.Date:
        return ['fal', 'calendar']
      case ApiFilterType.TechnicianFull:
        return ['fal', 'user']
    }

    return ['fal', 'filter']
  }, [filter])

  const key = useMemo(() => filter.data.map(d => d.id).join('#'), [filter])
  const active = useMemo(() => !!filter.data.find(d => d.active), [filter])
  const anyId = useMemo(() => filter.data.find(d => d.id)?.id ?? '', [filter])

  const text = useMemo(() => {
    if (filter.data.length === 1) return `${filter.data[0].textPrefix ?? ''}${filter.data[0].text ?? ''}`
    return filter.data.map(d => d.text).join(' - ')
  }, [filter])

  return (
    <Tag key={key} active={active} id={anyId} name={text} isHeader={isHeader} onPress={onPress} icon={icon} style={style}>
      {isRda && <RDAStatusTagContent filter={filter} active={active} isHeader={isHeader} />}
    </Tag>
  )
}

function RDAStatusTagContent<T>({ filter, isHeader, active }: Pick<Props<T>, 'filter' | 'isHeader'> & { active?: boolean }) {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()
  const values = useMemo(
    () =>
      filter.data
        .filter(q => q.active && !!q.values)
        .map(q => q.values)
        .flat()
        .sort() as RdaStatus[],
    [filter]
  )
  return (
    <IM.View style={[IMLayout.flex.row, AppStyle.center, { gap: IMLayout.horizontalMargin / 2 }]}>
      <IM.Icon icon={['fal', 'filter']} color={!isHeader && active ? theme.filterTag.active.text : theme.filterTag.default.text} />
      <IM.Text>{i18n.t('RDA')}</IM.Text>
      {values.map(status => (
        <RdaState key={status} rdaStatus={status} />
      ))}
    </IM.View>
  )
}
