import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, useImperativeHandle } from 'react'

import { api } from '../../apis/apiCalls'
import { EmailSettings } from '../../apis/types/apiResponseTypes'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import NumberInput from '../../components/input/NumberInput'
import PasswordInput from '../../components/input/PasswordInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import { UploadStatus } from '../../types'
import TestEmailView from './TestEmailView'

export type EmailSettingsViewProps = { updateUploadStatus: (status: UploadStatus) => void; onDone: () => void; emailSettings?: EmailSettings | null }
export type EmailSettingsViewRef = {
  onSave: () => void
}

const requiredFields: EditDataHandlerRequiredFields<EmailSettings> = ['server', 'port', 'email', 'password']
function EmailSettingsView({ updateUploadStatus, onDone, emailSettings }: EmailSettingsViewProps, ref: ForwardedRef<EmailSettingsViewRef>) {
  const { i18n } = useLanguage()
  useImperativeHandle(ref, () => ({
    onSave: () => {
      createOrUpdate()
    },
  }))

  const { state, createOrUpdate, handleDataChange } = useEditDataHandler<EmailSettings, Pick<EmailSettings, 'srvEmailSettingId'>>(
    api.email.settings.post,
    api.email.settings.put,
    undefined,
    {
      onDone: onDone,
      onUploadStatus: updateUploadStatus,
      editMode: !!emailSettings,
      initialValue: emailSettings,
      showErrorAlert: true,
      requiredFields,
    }
  )

  const commonProps = {
    spacing: ['bottom'],
    editable: true,
    required: true,
  } satisfies TextInputProps

  return (
    <ScrollViewForm>
      <TextInput
        value={state?.server}
        onChangeText={value => handleDataChange({ server: value })}
        title={i18n.t('EMAIL_SETTING_SERVER')}
        {...commonProps}
      />
      <NumberInput
        value={state?.port}
        onValueChanged={value => handleDataChange({ port: value })}
        title={i18n.t('EMAIL_SETTING_PORT')}
        disableFastInputs
        textAlign="left"
        {...commonProps}
      />
      <TextInput
        value={state?.email}
        onChangeText={value => handleDataChange({ email: value })}
        title={i18n.t('EMAIL_SETTING_USER')}
        autoComplete="off"
        {...commonProps}
      />
      <PasswordInput
        value={state?.password}
        onChangeText={value => handleDataChange({ password: value })}
        title={i18n.t('EMAIL_SETTING_PASSWORD')}
        autoComplete="off"
        {...commonProps}
      />
      <TestEmailView settings={state} />
    </ScrollViewForm>
  )
}

export default React.forwardRef(EmailSettingsView)
