import { ApiClient } from '@infominds/react-api'
import { useAlert, useLanguage } from '@infominds/react-native-components'
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { dataProviderSettingsAtom } from '../../utils/stateManager'
import { DataProviderCore } from '../DataProviderCore'
import { DataStorage } from '../DataStorage'
import { dataProviderCore } from '../InitDataProvider'
import { DataProviderState, DataProviderStateAction, DataProviderStateActions } from '../types'
import { DataProviderSyncManager } from '../utils/DataProviderSyncManager'

export type DataProviderContextType = {
  client: ApiClient
  dispatch: (action: DataProviderStateAction) => void
  dataProvider: DataProviderCore
  dataStorage: DataStorage
  dataSyncManager: DataProviderSyncManager
} & DataProviderState

export const DataProviderContext = createContext<DataProviderContextType | null>(null)

export type DataProviderContextProviderProps = PropsWithChildren<{
  /**
   * Automatic up-sync when back online
   * @default false
   */
  autoSync?: boolean
}>

export function DataProviderContextProvider({
  children,

  autoSync = false,
}: DataProviderContextProviderProps) {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const [init, setInit] = useState(false)
  const [state, setState] = useRecoilState(dataProviderSettingsAtom)

  useEffect(() => {
    dataProviderCore.state = state
  }, [state])

  useEffect(() => {
    if (init === true) return

    dataProviderCore.Init(state, dispatchState, { autoSync })
    setInit(true)
  }, [init])

  useEffect(() => {
    if (!state.failedRequests?.length) return

    alert.alert(i18n.t('ERROR'), i18n.t('DATA_SYNC_ERROR'), undefined, state.failedRequests?.length)
    // navigation.navigate('Synchronization')
  }, [state.failedRequests])

  function dispatchState(action: DataProviderStateAction) {
    setState(prevState => {
      const newState = { ...prevState }
      const { type, payload } = action

      switch (type) {
        case DataProviderStateActions.ClearError:
          if (!newState.failedRequests?.length) return newState
          return {
            ...newState,
            failedRequests: [],
          }
        case DataProviderStateActions.UpdateOnlineState:
          if (payload?.forcedOfflineMode !== undefined) {
            newState.forcedOfflineMode = payload.forcedOfflineMode
          }
          if (newState.forcedOfflineMode) {
            newState.isOnline = false
          } else if (payload?.isOnline !== undefined) {
            newState.isOnline = !!payload.isOnline
          }
          return {
            ...newState,
          }
        case DataProviderStateActions.UpdateDataToSync:
          return {
            ...newState,
            pendingDataToSync: payload?.pendingDataToSync,
          }
        case DataProviderStateActions.UpSyncFailed:
          if (!payload?.failedRequests?.length) return newState
          return {
            ...newState,
            failedRequests: payload?.failedRequests ?? [],
          }
        case DataProviderStateActions.Enable:
          return {
            ...newState,
            enabled: payload?.enabled,
          }
        case DataProviderStateActions.SyncComplete:
          return {
            ...newState,
            lastSyncDate: new Date(),
          }
        default:
          return newState
      }
    })
  }

  return (
    <DataProviderContext.Provider
      value={{
        ...state,
        client: dataProviderCore.client,
        dispatch: dispatchState,
        dataProvider: dataProviderCore,
        dataStorage: dataProviderCore.dataStorage,
        dataSyncManager: dataProviderCore.syncManager,
      }}>
      {init && children}
    </DataProviderContext.Provider>
  )
}
