import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { MediaUtils } from '@infominds/react-native-media-lite'
import React, { useEffect, useState } from 'react'
import { Image, ImageProps, Pressable, StyleSheet } from 'react-native'

import { api, apiDtoIds } from '../../../apis/apiCalls'
import { ActivityArticle } from '../../../apis/types/apiResponseTypes'
import useObjectUtils from '../../../hooks/useObjectUtils'
import MediaSkeleton from '../../skeleton/MediaSkeleton'

export type ArticleImageProps = {
  article?: ActivityArticle
  taskId?: number
  size?: number
  onImage?: (image: string) => void
  onPress?: (image: string) => void
} & Pick<ImageProps, 'style'>

export default function ArticleImage({ article, size = 75, style, onPress, onImage, taskId }: ArticleImageProps) {
  const { theme } = useTheme()
  const { url, bearerToken } = useAuthentication()
  const activityArticleObjectUtils = useObjectUtils<ActivityArticle>(apiDtoIds.activityArticles)

  const [loadingImage, setLoadingImage] = useState(false)
  const [image, setImage] = useState('')

  useEffect(() => {
    if (!article) return
    const abortController = new AbortController()
    if (article.isJolly) {
      getJollyImage(abortController)
    } else {
      getArticleImage(abortController)
    }

    return () => abortController.abort()
  }, [])

  function getArticleImage(abortController?: AbortController) {
    if (!article?.articleId || !url || !bearerToken) return
    api.articles
      .getImage(encodeURIComponent(article?.articleId), taskId, url, bearerToken, abortController)
      .then(response => {
        if (response.status === 200) return response.blob()
        return
      })
      .then(blob => {
        if (!blob) return
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
          if (!reader.result) return
          if (typeof reader.result === 'string') {
            setImage(reader.result?.toString())
            onImage?.(reader.result?.toString())
            return
          }
        }
      })
      .catch(console.error)
  }

  function getJollyImage(abortController?: AbortController) {
    if (!article || !article.mediaCount) return
    setLoadingImage(true)
    api.activities.articles.media
      .get(activityArticleObjectUtils.createRequestObject(article), abortController)
      .then(response => {
        const foundMedia = response.find(media => media.media && media.type === 'photo')
        if (!foundMedia?.media) return

        setImage(MediaUtils.base64ToUri(foundMedia.media, 'image/jpeg'))
      })

      .catch(console.error)
      .finally(() => setLoadingImage(false))
  }

  if (!image) {
    return (
      <>
        {loadingImage && (
          <IM.View style={[{ width: size, height: size }, styles.image, styles.loadingView, style, IMLayout.flex.row]}>
            <MediaSkeleton mediaCount={1} align="left" imageSize={size} />
          </IM.View>
        )}
      </>
    )
  }
  return (
    <Pressable onPress={() => onPress?.(image)} disabled={!onPress || loadingImage}>
      {!loadingImage && (
        <Image source={{ uri: image }} style={[{ width: size, height: size }, styles.image, { borderColor: theme.textPlaceholder }, style]} />
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  loadingView: {
    borderWidth: 0,
  },
  image: {
    borderRadius: IMLayout.borderRadius,
    borderWidth: 1,
  },
})
