import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import useBackgroundWorker from '../../../hooks/useBackgroundWorker'
import { InferArrayType, LoadingType } from '../../../types'
import appUtils from '../../../utils/appUtils'
import { ExceptionUtils } from '../../../utils/ExceptionUtils'

export type Options<TResponse> = {
  onLoadedCallback?: (item: TResponse) => void
  onErrorCallback?: () => void
  backgroundUpdaterInterval?: number
  modifyResult?: (result: TResponse) => TResponse
} & (
  | {
      /**
       * If true previous results will not be removed on request (only makes sense for array-results)
       */
      caching: true
      idProvider: (item: InferArrayType<TResponse>) => string
    }
  | { caching?: boolean; idProvider?: never }
)

interface Result<TRequest, TResponse> {
  item: TResponse | undefined
  loadItem: (request: TRequest, hidden?: boolean) => void
  loading: LoadingType
  setItem: Dispatch<SetStateAction<TResponse | undefined>>
  error: string | undefined
  setLoading: Dispatch<SetStateAction<LoadingType>>
}

export default function useControlledLoader<TRequest, TResponse>(
  getData: (request: TRequest, abortController: AbortController) => Promise<TResponse>,
  options?: Options<TResponse>
): Result<TRequest, TResponse> {
  const [item, setItem] = useState<TResponse>()
  const [loading, setLoading] = useState<LoadingType>('init')
  const [error, setError] = useState<string | undefined>(undefined)
  const request = useRef<TRequest>()
  const hiddenRequest = useRef<boolean>(false)
  const lastSuccessfulRequest = useRef<TRequest | null>(null)
  const lastResultHash = useRef<string | null>(null)

  useEffect(() => {
    if (loading !== 'reloading' && loading !== 'hiddenLoading') return

    const abortController = new AbortController()
    loader(abortController)

    return () => {
      abortController.abort()
    }
  }, [loading])

  const backgroundReloader = useCallback(() => {
    const memRequest = lastSuccessfulRequest.current
    if (!memRequest) return
    const abortController = new AbortController()
    getData(memRequest, abortController)
      .then(result => {
        handleResult(result, memRequest, true)
      })
      .catch(() => {
        lastSuccessfulRequest.current = null
      })
  }, [])
  useBackgroundWorker(options?.backgroundUpdaterInterval && !__DEV__ ? backgroundReloader : null, options?.backgroundUpdaterInterval ?? 1000)

  function loader(controller: AbortController) {
    lastSuccessfulRequest.current = null
    const memRequest = request.current as TRequest
    setError(undefined)
    getData(memRequest, controller)
      .then(response => handleResult(response, memRequest))
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          // setLoading('aborted')
          console.debug(`Aborted request:`, memRequest)
          return
        }

        options?.onErrorCallback?.()
        console.error('Failed loader() on useControlledLoader:', err)
        setError(ExceptionUtils.exceptionToString(err))
        setLoading('catched')
      })

    return
  }

  function handleResult(inResult: TResponse, memRequest: TRequest, hiddenLoading?: boolean) {
    const result = options?.modifyResult ? options?.modifyResult(inResult) : inResult
    if (!result) {
      throw new Error('Empty result')
    }
    const resultHash = appUtils.createSimpleHash(result)
    if (hiddenLoading) {
      if (!!lastResultHash.current && resultHash === lastResultHash.current) {
        return
      }
    }
    lastResultHash.current = resultHash
    options?.onLoadedCallback?.(result)
    if (options?.caching) {
      setItem(prev => {
        if (!prev || !Array.isArray(prev) || !Array.isArray(result) || !options.idProvider) return result

        const updatedElements = prev.map<InferArrayType<TResponse>>((element: InferArrayType<TResponse>) => {
          const foundResult = result.find((r: InferArrayType<TResponse>) => options.idProvider(r) === options.idProvider(element)) as
            | InferArrayType<TResponse>
            | undefined
          if (foundResult) {
            return foundResult
          } else {
            return element
          }
        })
        const newElements = result.filter(
          (r: InferArrayType<TResponse>) => !prev.find((p: InferArrayType<TResponse>) => options.idProvider(r) === options.idProvider(p))
        ) as unknown[]

        return [...updatedElements, ...newElements] as TResponse
      })
    } else {
      setItem(result)
    }
    lastSuccessfulRequest.current = { ...memRequest }
    setLoading(false)
  }

  function load(requestData: TRequest, hidden?: boolean) {
    request.current = requestData
    hiddenRequest.current = !!hidden
    if (hidden) {
      setLoading('hiddenLoading')
    } else {
      if (!options?.caching) setItem(undefined)
      setLoading('reloading')
    }
  }

  return { item, loadItem: load, loading, setItem, error, setLoading }
}
