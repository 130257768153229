import { IM, IMLayout, useLanguage, useModalController, useTheme, Utils } from '@infominds/react-native-components'
import { AssetUtils, LiteAsset, MediaUtils } from '@infominds/react-native-media-lite'
import React, { useEffect, useMemo } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { AppStyle } from '../constants/Styles'
import useFilePicker from '../hooks/media/useFilePicker'
import useExtendedTheme from '../hooks/useExtendedTheme'
import useOnLayout from '../hooks/useOnLayout'
import CameraModal from '../modals/media/CameraModal'
import { galleryUtils } from '../utils/gallery'
import PressableIcon, { PressableIconProps } from './Infominds/PressableIcon'
import LoadingIcon from './LoadingIcon'

type MediaPostInputButtonProps = Omit<PressableIconProps, 'style'> & {
  secondary?: boolean
  busy?: boolean
  round?: boolean
  validateAsset?: (asset: LiteAsset) => boolean
  assets: LiteAsset[]
  setAssets: React.Dispatch<React.SetStateAction<LiteAsset[]>>
  style?: StyleProp<ViewStyle>
}
export type MediaButtonsProps = Omit<MediaPostInputButtonProps, 'icon'>

export default function MediaButtons({ style, round, validateAsset, assets, setAssets, ...props }: MediaButtonsProps) {
  const { i18n } = useLanguage()
  const { colorScheme, theme } = useTheme()
  const { openFilePicker, filesContent } = useFilePicker()

  const cameraModal = useModalController()

  const fileCount = useMemo(() => (Platform.OS === 'web' ? assets.length : assets.filter(asset => asset.type === 'file').length), [assets])
  const mediaCount = useMemo(() => assets.filter(asset => asset.type !== 'file').length, [assets])

  useEffect(() => {
    filesContent.forEach(content => {
      const mime = content.content.replace('data:', '').split(';').at(0)
      setAssets(prev => [
        ...prev,
        {
          id: Utils.getUid(),
          type: mime ? AssetUtils.getAssetTypeByMime(mime) : 'file',
          base64: MediaUtils.uriToBase64(content.content),
          path: '',
          fileName: content.name,
        },
      ])
    })
  }, [filesContent])

  function handleCameraModalDone(newAssets: LiteAsset[]) {
    setAssets(newAssets)
  }

  return (
    <IM.View style={[IMLayout.flex.row, style]}>
      <MediaPostInputButton
        icon={['fal', 'photo-film-music']}
        size={27}
        onPress={() => {
          Platform.OS === 'web'
            ? openFilePicker()
            : galleryUtils
                .launchGallery(
                  assets,
                  setAssets,
                  i18n.t('CANCEL'),
                  i18n.t('OPEN_GALLERY_ACTION'),
                  i18n.t('OPEN_FILES_ACTION'),
                  colorScheme,
                  validateAsset
                )
                .catch(console.error)
        }}
        secondary
        badgeNumber={fileCount ? fileCount : undefined}
        style={[round && styles.leftRound, Platform.OS === 'web' && round && styles.rightRound]}
        {...props}
      />

      {(Platform.OS === 'android' || Platform.OS === 'ios') && (
        <>
          <IM.View style={{ borderRightColor: theme.textPlaceholder, borderRightWidth: StyleSheet.hairlineWidth }} />
          <MediaPostInputButton
            icon={['fal', 'camera']}
            onPress={() => cameraModal.show()}
            size={25}
            secondary
            badgeNumber={mediaCount ? mediaCount : undefined}
            style={[round && styles.rightRound]}
            {...props}
          />
        </>
      )}
      <CameraModal controller={cameraModal} onDone={handleCameraModalDone} preSetAssets={assets} />
    </IM.View>
  )
}

function MediaPostInputButton({
  style,
  color,
  secondary,
  busy,
  onLayout: externalOnLayout,
  ...props
}: Omit<MediaPostInputButtonProps, 'assets' | 'setAssets'>) {
  const { theme } = useExtendedTheme()
  const { layout, onLayout } = useOnLayout()

  return (
    <IM.View
      style={[
        !secondary && { backgroundColor: theme.card.headBackground, borderColor: theme.card.headBackground },
        secondary && { backgroundColor: theme.card.secondaryBackground, borderColor: theme.card.secondaryBackground },
        style,
      ]}
      onLayout={e => {
        onLayout(e)
        externalOnLayout?.(e)
      }}>
      {busy && (
        <IM.View style={[{ width: layout?.width, height: layout?.height }, AppStyle.center]}>
          <LoadingIcon />
        </IM.View>
      )}
      {!busy && (
        <PressableIcon pressableStyle={{ backgroundColor: undefined }} style={[styles.pressableIconMain]} color={color ?? '#fff'} {...props} />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  pressableIconMain: {
    padding: 10,
  },
  leftRound: {
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
  rightRound: {
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
  },
})
