import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../types'
import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'

type Props = {
  disableLogoutButton?: boolean
}

const SettingsHeader = ({ disableLogoutButton }: Props) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { logout } = useAuthentication()

  const handleLogout = () => {
    logout().catch(err => console.error('Logout error', err))
  }

  return (
    <IM.View style={styles.container}>
      <IM.Text style={[styles.contentText, styles.title]}>{i18n.t('TAB_SETTINGS')}</IM.Text>
      {!disableLogoutButton && (
        <Pressable onPress={handleLogout}>
          <TextWithIcon alignIcon="right" icon={['fal', 'person-to-door']} iconSize={22} color={theme.header.main.error}>
            {i18n.t('LOGOUT')}
          </TextWithIcon>
        </Pressable>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-between' },
  contentText: {
    color: IMStyle.palette.white,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
  },
})

export default SettingsHeader
