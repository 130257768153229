import { IM, IMLayout, useEvent, useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Resource } from 'react-big-scheduler-stch'
import { StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import { api } from '../../apis/apiCalls'
import { Technician } from '../../apis/types/apiResponseTypes'
import EmployeeBadge from '../../components/EmployeeBadge'
import Error from '../../components/Error'
import GanttChartWrapper from '../../components/gantt/GanttChartWrapper'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import { chartUtils } from '../../utils/chartUtils'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import { ganttChartRefreshAtom } from '../../utils/stateManager'

type Props = {
  companyId: number | undefined
  objectId: number | undefined
}

export default function PlannerView({ companyId, objectId }: Props) {
  const { i18n, language } = useLanguage()
  const { colorScheme, theme } = useExtendedTheme()

  const [hardLoading, setHardLoading] = useState(true)
  const [refresh, setRefresh] = useRecoilState(ganttChartRefreshAtom)

  const { item: allTechnicians, loadItem: loadTechnicians, loading: loadingTechnicians } = useControlledLoader(api.technicians.getList)
  const technicians = useMemo(() => allTechnicians?.filter(t => !t.disabled), [allTechnicians])
  const { item: qualifications, loadItem: loadQualifications, loading: loadingQualifications } = useControlledLoader(api.technicians.getQualification)
  const { item: timeSlots, loadItem: loadTimeSlots, loading: loadingTimeSlots } = useControlledLoader(api.activities.timeSlots.getList)

  useEvent({ key: EVENT_KEYS.GANTT_CHART_TIME_RANGE_REFRESH }, () => loadTimeSlots({}))

  useEffect(() => {
    onRefresh()
  }, [])

  useEffect(() => {
    refresh && onRefresh()
  }, [refresh])

  useEffect(() => {
    const isLoading = loadingTechnicians === 'reloading' || loadingTimeSlots === 'reloading' || loadingQualifications === 'reloading'
    setHardLoading(isLoading)
    !isLoading && setRefresh(false)
  }, [loadingTechnicians, loadingTimeSlots, loadingQualifications])

  const onRefresh = () => {
    loadTechnicians({})
    loadTimeSlots({})
    loadQualifications()
  }

  const createResources = useCallback((resources: Technician[] | undefined) => {
    if (!resources) return []

    const toRet: Resource[] = []

    resources.forEach(resource => {
      const name = EmployeeUtils.getName(resource, 'surname-name')
      const id = chartUtils.createTechnicianIdentifier(resource)

      toRet.push({
        id,
        name,
        leftComponent: resource.technicianType === 'employee' && (
          <EmployeeBadge key={resource.id} size={24} name={name} id={resource.id} style={styles.badge} spacing="right" />
        ),
        // @ts-ignore ok like this
        qualificationId: resource.qualification?.id ?? CONSTANTS.noQualificationId,
      })
    })

    return toRet
  }, [])

  const hasCatched = useMemo(
    () =>
      loadingTechnicians === 'catched' ||
      loadingTechnicians === 'aborted' ||
      loadingTimeSlots === 'catched' ||
      loadingTimeSlots === 'aborted' ||
      loadingQualifications === 'catched' ||
      loadingQualifications === 'aborted',
    [loadingTechnicians, loadingTimeSlots, loadingQualifications]
  )
  const graphReady = !hardLoading && !hasCatched
  const data = chartUtils.createInactiveTimeRanges(timeSlots)

  return (
    <IM.View style={IMLayout.flex.f1} key={colorScheme === 'light' ? 'light' : 'dark'}>
      {hardLoading ? (
        <IM.LoadingSpinner isVisible />
      ) : (
        <>
          {hasCatched && <Error style={styles.error} />}
          {graphReady && data && technicians && (
            <GanttChartWrapper
              resources={createResources(technicians)}
              qualifications={qualifications ?? []}
              technicians={technicians}
              inactiveSections={data.inactiveSlots}
              colorScheme={colorScheme}
              i18n={i18n}
              language={language}
              theme={theme}
              companyId={companyId}
              objectId={objectId}
            />
          )}
        </>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  error: {
    flex: 1,
    justifyContent: 'center',
  },
  badge: { alignItems: 'center', paddingVertical: 4 },
})
