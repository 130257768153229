import React from 'react'
import { Platform } from 'react-native'

import { Activity } from '../../apis/types/apiResponseTypes'
import useDetail from '../../components/MasterDetail/hooks/useDetail'
import MasterDetail from '../../components/MasterDetail/MasterDetails'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import FilterDetailHeader from '../../components/screen/headers/FilterDetailHeader'
import ServiceHeader from '../../components/screen/headers/ServiceHeader'
import { Screen } from '../../components/screen/Screen'
import FilterContext from '../../contexts/FilterContext'
import { filterUtils } from '../../utils/FilterUtils'
import HistoryDetailView from '../../views/history/HistoryDetailView'
import ReportView from '../../views/report/ReportView'

export default function ReportActivitiesScreen() {
  return (
    <SearchProvider>
      <FilterContext.Consumer>
        {context => {
          return (
            <Screen>
              <Screen.Header>
                <ServiceHeader
                  disableChangeViewIcon
                  tabletSearchComponent={<SearchDetailHeaderContainer dividers={['right']} enableQR />}
                  tabletFilterComponent={
                    <FilterDetailHeaderContainer dividers={['right']}>
                      <FilterDetailHeader enabled />
                    </FilterDetailHeaderContainer>
                  }
                  indicatorNumber={filterUtils.getActiveFilterCount(context?.filters ?? [], context?.apiFilter ?? [])}
                />
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <FilterDetailHeader enabled />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <MasterDetail flexRatio={Platform.OS === 'web' ? 0.33 : 0.4}>
                  <MasterDetail.Master>
                    <ReportView />
                  </MasterDetail.Master>
                  <MasterDetail.Detail>
                    <DetailView />
                  </MasterDetail.Detail>
                </MasterDetail>
              </Screen.Content>
            </Screen>
          )
        }}
      </FilterContext.Consumer>
    </SearchProvider>
  )
}

function DetailView() {
  const { detail } = useDetail<Activity>()

  if (!detail) return <></>

  return <HistoryDetailView {...detail} />
}
