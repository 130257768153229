import { IM, IMLayout, SwitchProps, TextProps, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { PropsWithChildren, useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import Animated from 'react-native-reanimated'

import { ThemeColorExpanded } from '../types'
import LoadingIcon from './LoadingIcon'

type Props = Omit<SwitchProps, 'onColor'> & {
  required?: boolean
  loading?: boolean
  loadingStyle?: StyleProp<Animated.AnimateStyle<StyleProp<ViewStyle>>>
  text: string
  textProps?: TextProps
  alignChildren?: 'right' | 'bottom'
} & PropsWithChildren &
  Pick<ViewProps, 'style' | 'spacing'>

export default function SwitchWithText({
  required,
  loading,
  loadingStyle,
  style,
  spacing,
  text,
  textProps,
  alignChildren = 'bottom',
  children,
  ...others
}: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const opacity = others.disabled ? 0.4 : 1

  const showChildren = !!others.value && children

  const titleText = useMemo(() => `${text} ${required ? ' *' : ''}`, [text, required])

  return (
    <IM.View style={[styles.main, style]} spacing={spacing}>
      <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }, styles.content]}>
        <IM.View style={[{ opacity }]}>
          {loading ? (
            <LoadingIcon size={20} color={theme.general.info} style={loadingStyle} />
          ) : (
            <IM.Switch {...others} onColor={theme.general.info} />
          )}
        </IM.View>
        <IM.Text {...textProps}>{titleText}</IM.Text>
        {showChildren && alignChildren === 'right' && children}
      </IM.View>
      {showChildren && alignChildren === 'bottom' && children}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {
    justifyContent: 'center',
  },
  content: {
    alignItems: 'center',
  },
})
