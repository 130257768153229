import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React from 'react'

import { AppStyle } from '../constants/Styles'
import useExtendedTheme from '../hooks/useExtendedTheme'

export type WarningProps = ViewProps & { message: string; icon?: IconProp; color?: string }

export default function Warning({ message, icon, style, color, ...viewProps }: WarningProps) {
  const { theme } = useExtendedTheme()

  const warnColor = color ?? theme.general.warn

  return (
    <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }, style]} {...viewProps}>
      <IM.View style={AppStyle.center}>
        <IM.Icon icon={icon ?? ['fas', 'triangle-exclamation']} color={warnColor} size={20} />
      </IM.View>
      <IM.Text
        style={{
          color: warnColor,
        }}>
        {message}
      </IM.Text>
    </IM.View>
  )
}
