import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { UnavailableTypeDefinition } from '../../apis/types/apiResponseTypes'
import UnavailableTypeDescriptionCard from '../../cards/anagrafics/UnavailableTypeDescriptionCard'
import { ListSection, SupportedUnavailableTypes, UnavailableTypeDescription } from '../../types'
import SelectInput from './selectInput/SelectInput'

interface Props {
  value: UnavailableTypeDefinition | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: UnavailableTypeDefinition | undefined) => void
  required?: boolean
}

export default function UnavailableTypeSelector({ value, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const [selectedType, setSelectedType] = useState<UnavailableTypeDescription | undefined>(SupportedUnavailableTypes.find(sp => sp.type === value))

  const onUnavailableTypePress = (newUnavailableType: UnavailableTypeDescription | undefined) => {
    setSelectedType(newUnavailableType)
    onChange(newUnavailableType?.type)
  }

  const render = ({ item }: SectionListRenderItemInfo<UnavailableTypeDescription, ListSection<UnavailableTypeDescription>>, onPress?: () => void) => {
    return (
      <UnavailableTypeDescriptionCard type={item} selected={item.type === selectedType?.type} onPress={onPress} spacing={['horizontal', 'bottom']} />
    )
  }

  return (
    <>
      <SelectInput
        id={['type']}
        data={SupportedUnavailableTypes}
        value={selectedType}
        title={i18n.t('TYPE')}
        screenTitle={i18n.t('UNAVAILABLE_TYPES')}
        noDataMessage={i18n.t('NO_UNAVAILABLE_TYPE_FOUND')}
        renderItem={render}
        onChange={onUnavailableTypePress}
        renderSelectedString={item => i18n.t(item.labelKey)}
        loading={false}
        {...props}
      />
    </>
  )
}
