import { ModalController } from '@infominds/react-native-components'
import React from 'react'

import SettingsHeader from '../../components/screen/headers/SettingsHeader'
import { Screen } from '../../components/screen/Screen'
import SettingsView from '../../views/settings/SettingsView'
import BaseServiceModal from '../BaseServiceModal'

export type SettingsModalProps = {
  showPlannerSettings: boolean
}

type Props = {
  controller: ModalController<SettingsModalProps>
}

export default function SettingsModal({ controller }: Props) {
  return (
    <BaseServiceModal controller={controller}>
      <Screen>
        <Screen.Header isModal goBack={controller.close}>
          <SettingsHeader disableLogoutButton />
        </Screen.Header>
        <Screen.Content>
          <SettingsView />
        </Screen.Content>
      </Screen>
    </BaseServiceModal>
  )
}
