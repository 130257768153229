import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { ErgoTaskType } from '../../apis/types/apiRequestTypes'
import { Activity, ErgoTask } from '../../apis/types/apiResponseTypes'
import ErgoTaskCard from '../../cards/activities/ErgoTaskCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import useUserSettings from '../../hooks/useUserSettings'
import { ListSection } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: ErgoTask | undefined) => void
  disableFastInput?: boolean
  required?: boolean
  initialValue?: Partial<Activity>
}

export default function TaskSelector({ onChange, disableFastInput, initialValue, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<ErgoTask>()
  const { item: data, loadItem, loading, setItem: setData } = useControlledLoader(api.tasks.getList)
  const filteredData = useDataSearchFilter(data, search, ['description'])
  const { isOnlyReport } = useUserSettings()

  useEffect(() => {
    setSelected(undefined)
    refresh()
  }, [])

  const refresh = () => loadItem({ taskType: isOnlyReport ? ErgoTaskType.Report : ErgoTaskType.Service })

  useEffect(() => {
    if (!initialValue?.taskId) return
    const foundItem = data?.find(d => d.id === initialValue.taskId)
    if (foundItem) {
      setSelected(foundItem)
    } else {
      const missingTask: ErgoTask = {
        id: initialValue.taskId,
        description: initialValue.taskDescription,
      }
      setData(prev => {
        prev?.unshift(missingTask)
        return prev ?? []
      })
      if (!selected?.id) setSelected(missingTask)
    }
  }, [data])

  const render = ({ item }: SectionListRenderItemInfo<ErgoTask, ListSection<ErgoTask>>, onPress?: () => void) => {
    return <ErgoTaskCard task={item} selected={item.id === selected?.id} onPress={onPress} spacing={['horizontal', 'bottom']} />
  }

  const handleOnChange = (newValue: ErgoTask | undefined) => {
    setSelected(newValue)
    onChange(newValue)
  }

  return (
    <SelectInput
      id={apiDtoIds.tasks}
      data={filteredData}
      value={selected}
      loading={loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={i18n.t('TASK')}
      screenTitle={i18n.t('TASKS')}
      noDataMessage={i18n.t('NO_TASK_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.description ?? ''}
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      fastInputEntries={2}
      autoSelectIfUnique
      {...props}
    />
  )
}
