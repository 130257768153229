import { IM, IMLayout, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import GroupedActivityTimeRecord from '../../../components/activity/times/GroupedActivityTimeView'
import Button from '../../../components/Button'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import LoadingStateView from '../../../components/LoadingStateView'
import MoreButton from '../../../components/MoreButton'
import ThreeLinesSkeleton from '../../../components/skeleton/ThreeLinesSkeleton'
import { AppStyle } from '../../../constants/Styles'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import useActivityTimes from '../../../hooks/activity/useActivityTimes'
import useActivityTypes from '../../../hooks/activity/useActivityTypes'
import useLayout from '../../../hooks/useLayout'
import usePartialList from '../../../hooks/usePartialList'
import ActivityTimeCreateOrEditModal from '../../../modals/activity/ActivityTimeCreateOrEditModal'
import { ActivityDetailStackParamList } from '../../../navigation/types'
import { ActivityTimeCreateOrEditViewProps } from '../../../views/activities/ActivityTimeCreateOrEditView'
import { LoadedCardProps } from '../../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData' | 'loading'> & { dontLoadOnMount?: boolean }

const ActivityDetailTimesCard = memo(function ActivityDetailTimesCard({ enableChange, dontLoadOnMount, ...props }: Props) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation<NavigationProp<ActivityDetailStackParamList>>()
  const modifyTimeModal = useModalController<ActivityTimeCreateOrEditViewProps>()
  const { detail, isHistorical } = useActivityDetail()
  const { groupedTimes, loadingTimes } = useActivityTimes(detail, dontLoadOnMount)
  const { activityTypes } = useActivityTypes()

  const { displayedItems: displayedTimes, displayMode, setDisplayMode } = usePartialList(groupedTimes, { reverse: true })

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('TIMES')} />} noContentSpacing {...props}>
      <IM.View spacing={'all'} style={styles.contentView}>
        <LoadingStateView loading={loadingTimes}>
          <ThreeLinesSkeleton />
        </LoadingStateView>
        {!groupedTimes?.length && !loadingTimes && (
          <IM.View spacing={'all'}>
            <IM.Text secondary>{i18n.t('NO_TIMES')}</IM.Text>
          </IM.View>
        )}
        {!!groupedTimes?.length && !loadingTimes && (
          <MoreButton
            allItemsCount={groupedTimes.length}
            shownItemsCount={displayedTimes?.length ?? 0}
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
          />
        )}

        {!!detail &&
          displayedTimes?.map((time, index) => (
            <GroupedActivityTimeRecord
              key={`ActivityTimeRecord#${time.date}`}
              activity={detail}
              time={time}
              separator={index > 0 || groupedTimes.length > 3}
              activityTypes={activityTypes ?? []}
              enableChange={enableChange && !isHistorical}
              onEdit={timeToEdit => {
                if (!detail) return
                if (isSmallDevice) navigation.navigate('ActivityTimeEditScreen', { activity: detail, activityTime: timeToEdit })
                else modifyTimeModal.show({ activity: detail, activityTime: timeToEdit })
              }}
            />
          ))}

        {enableChange && detail && (
          <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd]}>
            <Button
              title={i18n.t('ADD')}
              onPress={() => {
                if (isSmallDevice) navigation.navigate('ActivityTimeEditScreen', { activity: detail })
                else modifyTimeModal.show({ activity: detail })
              }}
              color={theme.primary}
            />
          </IM.View>
        )}
      </IM.View>
      <ActivityTimeCreateOrEditModal controller={modifyTimeModal} />
    </IM.Card>
  )
})

export default ActivityDetailTimesCard

const styles = StyleSheet.create({
  contentView: {
    minHeight: 50,
    flexShrink: 1,
  },
})
