import { ModalController, useLanguage, Utils } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'
import { Keyboard } from 'react-native'

import { ConstructionSiteLotto, ErgoTask } from '../../apis/types/apiResponseTypes'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { EditOrCreateViewRef, ModalScreenRef } from '../../types'
import ConstructionSiteLottoCreationView from '../../views/constructionSite/ConstructionSiteLottoCreationView'

interface Props {
  controller: ModalController<ConstructionSiteLotto | undefined>
  objectId: number
  onLotCreated?: (customer: ConstructionSiteLotto) => void
  preset?: Partial<ConstructionSiteLotto>
  ergoTask?: ErgoTask | null
}

function ConstructionSiteLottCreationScreen({ controller, objectId, onLotCreated, preset, ergoTask }: Props, ref: ForwardedRef<ModalScreenRef>) {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const lotToEdit = controller.data

  const presetToUse = useMemo(() => {
    const result = { ...preset }
    const objectLotInitialStatus = ergoTask?.paramList?.OBJECTSTATUS_START
      ? Utils.parseIntegerFromText(ergoTask?.paramList?.OBJECTSTATUS_START)
      : undefined
    if (objectLotInitialStatus) Object.assign(result, { objectStatusId: objectLotInitialStatus })
    return result
  }, [preset, ergoTask])

  return (
    <SearchProvider>
      <Screen transparent>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal
          title={lotToEdit ? i18n.t('EDIT_CONSTRUCTION_SITE_LOTTO') : i18n.t('CREATE_CONSTRUCTION_SITE_LOTTO')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            <ConstructionSiteLottoCreationView
              objectId={objectId}
              constructionSiteLotto={lotToEdit}
              ref={createViewRef}
              onDone={(_type, result, request) => {
                handleGoBack(true)
                const lotId = result?.find(r => r.lotId)?.lotId
                if (!lotId) return
                onLotCreated?.({ ...request, lotId } as ConstructionSiteLotto)
              }}
              onUploadStatus={setStatus}
              preset={presetToUse}
            />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(ConstructionSiteLottCreationScreen)
