import { IM, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useState } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import { TextInputRef } from '../../types'
import { ValidationUtils } from '../../utils/ValidationUtils'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type EmailInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    disabledInfo?: string | false
    showClearTextIcon?: boolean
    validate?: boolean
  }

const EmailInput = memo(
  forwardRef(function EmailInput(
    {
      title,
      titleFontWeight,
      details,
      editable = true,
      error,
      loading,
      disableFocus,
      disabledInfo,
      showClearTextIcon,
      ...textInputProps
    }: EmailInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { theme } = useTheme()

    const [validationError, setValidationError] = useState(false)

    function validateEmail() {
      setValidationError(!!textInputProps.value && ValidationUtils.email(textInputProps.value) === false)
    }

    return (
      <BaseTextInputProvider editable={editable} error={error || validationError} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput ref={ref} type="email" onChange={() => setValidationError(false)} onBlur={() => validateEmail()} {...textInputProps}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />}
          {editable && !loading && !!textInputProps.value && showClearTextIcon && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius} key={'ClearIcon'}>
              <PressableIcon icon={['fal', 'times']} size={18} color={theme.textPlaceholder} onPress={() => textInputProps.onChangeText?.('')} />
            </BaseTextInput.RightIcon>
          )}
          {loading && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              {loading && <BaseTextInputSpinningIcon key={'LoadingSpinnerIcon'} loading={loading} />}
            </BaseTextInput.RightIcon>
          )}
          {!editable && !loading && disabledInfo && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              <IM.Text key={'DisabledInfoText'} style={[{ color: theme.textPlaceholder }, styles.disabledText]}>
                {disabledInfo}
              </IM.Text>
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default EmailInput

const styles = StyleSheet.create({
  disabledText: {
    paddingRight: 9,
  },
})
