import { IM, TextProps, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useImperativeHandle, useState } from 'react'

import { AppStyle } from '../constants/Styles'
import Pressable from './Infominds/Pressable'
import TextTruncated from './TextTruncated'

type Props = Omit<TextProps, 'ref'>
export interface TextExpandableRef {
  reset: () => void
}

const TextExpandable = memo(
  forwardRef(function TextExpandable({ numberOfLines, children, ...others }: Props, ref: ForwardedRef<TextExpandableRef>) {
    useImperativeHandle(ref, () => ({
      reset: () => handleReset(),
    }))

    const { i18n } = useLanguage()

    const [shownMore, setShownMore] = useState(false)
    const [hasHiddenLines, setHasHiddenLines] = useState(false)

    const handleChange = () => setShownMore(prev => !prev)

    const handleReset = () => {
      setShownMore(false)
      setHasHiddenLines(false)
    }

    return (
      <>
        <TextTruncated shownMore={shownMore} numberOfLines={numberOfLines} onHiddenLines={setHasHiddenLines} {...others}>
          {children}
        </TextTruncated>
        {hasHiddenLines && (
          <Pressable onPress={() => handleChange()} style={AppStyle.center}>
            <IM.TextWithIcon alignIcon="right" icon={shownMore ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']} secondary>
              {shownMore ? i18n.t('SHOW_LESS') : i18n.t('SHOW_MORE')}
            </IM.TextWithIcon>
          </Pressable>
        )}
      </>
    )
  })
)

export default TextExpandable
