import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api } from '../../apis/apiCalls'
import { ErgoLanguage } from '../../apis/types/apiResponseTypes'
import SelectorCard from '../../cards/SelectorCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  value: number | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: ErgoLanguage | undefined) => void
} & Pick<SelectInputProps<ErgoLanguage>, 'required' | 'disableFastInput' | 'title' | 'placeholder'>

export default function LanguageSelector({ value, onChange, title, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const { item: data, loadItem, loading, error } = useControlledLoader(api.languages.getList)
  const filteredData = useDataSearchFilter(data, search, ['description'])
  const selectedItem = useMemo(() => data?.find(d => d.languageId === value), [data, value])

  useEffect(loadItem, [])

  const render = ({ item }: SectionListRenderItemInfo<ErgoLanguage, ListSection<ErgoLanguage>>, onPress?: () => void) => {
    return (
      <SelectorCard
        key={item.languageId}
        onPress={onPress}
        selected={item.languageId === value}
        value={item.description}
        spacing={['bottom', 'horizontal']}
      />
    )
  }

  return (
    <>
      <SelectInput
        id={['languageId']}
        data={filteredData}
        value={selectedItem}
        loading={loading}
        title={title ?? i18n.t('LANGUAGE')}
        screenTitle={title ?? i18n.t('LANGUAGE')}
        noDataMessage={i18n.t('NO_LANGUAGE_FOUND')}
        renderItem={render}
        onChange={onChange}
        onSearchChange={setSearch}
        refresh={loadItem}
        renderSelectedString={item => item.description}
        disableLoadAfterMount
        error={error}
        {...props}
      />
    </>
  )
}
