import { useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { GetActivityNotesRequest } from '../../apis/types/apiRequestTypes'
import { Activity, ActivityNote } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useObjectUtils from '../../hooks/useObjectUtils'
import usePartialList from '../../hooks/usePartialList'
import useUserSettings from '../../hooks/useUserSettings'
import { ListDisplayMode, LoadingType, MediaPostInputResult } from '../../types'
import { noteUtils } from '../../utils/noteUtils'
import { ActivityDetailContext } from './ActivityDetailContext'

export type ActivityNoteContextType = {
  notes: ActivityNote[] | undefined
  displayedNotes: ActivityNote[]
  setNotes: React.Dispatch<React.SetStateAction<ActivityNote[] | undefined>>
  loadingNotes: LoadingType
  sendNote: (noteToSend: MediaPostInputResult) => void
  sendingNote: LoadingType
  displayMode: ListDisplayMode
  setDisplayMode: React.Dispatch<React.SetStateAction<ListDisplayMode>>
  deleteNote: (note: ActivityNote, skipConfirmation?: boolean) => void
  deletingNote: LoadingType
  newAssets: LiteAsset[]
  setNewAssets: React.Dispatch<React.SetStateAction<LiteAsset[]>>
}

export const ActivityNoteContext = React.createContext<ActivityNoteContextType | null>(null)

export function ActivityNoteContextProvider({
  children,
  activityDetail,
  dontLoadOnMount,
}: PropsWithChildren<{ activityDetail: Activity | null | undefined; dontLoadOnMount?: boolean }>) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const activityNoteObjectUtils = useObjectUtils<ActivityNote>(apiDtoIds.activityNotes)
  const { userSettings } = useUserSettings()
  const [newAssets, setNewAssets] = useState<LiteAsset[]>([])

  const {
    item: notes,
    loadItem: loadNotes,
    loading: loadingNotes,
    setItem: setNotes,
    setLoading,
  } = useControlledLoader(api.activities.notes.getList, { backgroundUpdaterInterval: CONSTANTS.backgroundUpdateInterval })

  const { displayedItems: displayedNotes, displayMode, setDisplayMode } = usePartialList(notes ?? [], { reverse: true })

  useEvent({ key: EVENT_KEYS.UPDATED_NOTES }, refreshNotes)
  useEffect(() => {
    if (dontLoadOnMount) {
      setLoading(false)
      return
    }
    refreshNotes()
  }, [activityDetail])

  function refreshNotes(hidden?: boolean) {
    if (!activityDetail) return
    loadNotes(activityObjectUtils.createRequestObject<GetActivityNotesRequest>(activityDetail), hidden)
  }

  const { request, loading: sendingNote } = useRequest(noteUtils.sendNote, {
    onSuccess: (result, postRequest) => {
      setNewAssets([])
      if (!result || !postRequest) return
      const newNote = noteUtils.createNoteFromPostResult(result, postRequest, userSettings)
      setNotes(prev => {
        if (!prev) return [newNote]
        prev?.unshift(newNote)
        return [...(prev ?? [])]
      })
    },
    onError: console.error,
    showErrorAlert: true,
  })

  function sendNote(noteToSend: MediaPostInputResult) {
    if (!activityDetail) return
    request({
      noteToSend,
      activityId: activityObjectUtils.createRequestObject(activityDetail),
      employeeId: userSettings?.employee?.id,
      noteDate: new Date().toISOString(),
    })
  }

  const { request: deleteNoteRequest, loading: deletingNote } = useRequest(api.activities.notes.delete, {
    onSuccess: (_, postRequest) => {
      setNotes(prev => [...(prev?.filter(p => !activityNoteObjectUtils.compare(p, postRequest)) ?? [])])
    },
    onError: console.error,
    showErrorAlert: true,
  })

  function deleteNote(note: ActivityNote, skipConfirmation?: boolean) {
    if (skipConfirmation) {
      deleteNoteRequest({ ...activityNoteObjectUtils.createRequestObject(note) })
      return
    }
    alert.alert(i18n.t('DELETE_NOTE'), i18n.t('DELETE_NOTE_ALERT'), [
      {
        text: i18n.t('REMOVE'),
        onPress: () => deleteNoteRequest({ ...activityNoteObjectUtils.createRequestObject(note) }),
        style: 'destructive',
      },
      {
        text: i18n.t('CANCEL'),
        style: 'cancel',
      },
    ])
  }

  const detailContext = useContext(ActivityDetailContext) // using useContext instead of custom hook here to allow usage outside of context provider
  useEffect(() => detailContext?.setDataCount(prev => ({ ...prev, notes: notes?.length ?? 0 })), [notes])

  return (
    <ActivityNoteContext.Provider
      value={{
        notes,
        displayedNotes,
        loadingNotes,
        setNotes,
        sendNote,
        sendingNote,
        displayMode,
        setDisplayMode,
        deleteNote,
        deletingNote,
        newAssets,
        setNewAssets,
      }}>
      {children}
    </ActivityNoteContext.Provider>
  )
}

export function useActivityNote() {
  const context = useContext(ActivityNoteContext)

  if (!context) throw new Error('useActivityNote() can only be used inside of <ActivityNoteContextProvider>')

  return context
}
