import { useLanguage } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityDetailStackParamList } from '../../navigation/types'
import { ActivityArticleEditScreenProps, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import ActivityArticleEditView from '../../views/activities/articles/ActivityArticleEditView'

const ActivityArticleEditScreen = ({ controller }: ModalScreenProps<ActivityArticleEditScreenProps>, ref: ForwardedRef<ModalScreenRef>) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()

  const createViewRef = createRef<EditOrCreateViewRef>()
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityArticleEditScreen'>>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined
  const activity = isModal ? controller.data?.activity : route.params?.activity
  const article = isModal ? controller.data?.article : route.params?.article
  const editable = (isModal ? controller.data?.editable : route.params?.editable) && !article?.refer2DocId

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal={isModal}
          title={article?.articleId ?? i18n.t('EDIT_ARTICLE')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            {!!activity && !!article && (
              <ActivityArticleEditView
                ref={createViewRef}
                activity={activity}
                article={article}
                onDone={() => handleGoBack(true)}
                onUploadStatus={setStatus}
                editable={editable}
              />
            )}
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(ActivityArticleEditScreen)
