import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'

interface Props {
  title: string
  buttonTitle?: string
  showButton: boolean
  icon: IconProp
  iconSize?: number
  onPress: () => void
}

const EditOrCreateHeader = ({ title, showButton, icon, iconSize, buttonTitle, onPress }: Props) => {
  const { i18n } = useLanguage()

  return (
    <IM.View style={styles.container}>
      <IM.View style={IMLayout.flex.f1}>
        <IM.Text numberOfLines={1} style={[styles.contentText, styles.title]}>
          {title}
        </IM.Text>
      </IM.View>
      {showButton && (
        <IM.View style={[IMLayout.flex.row]}>
          <Pressable onPress={onPress}>
            <TextWithIcon alignIcon="right" icon={icon} iconSize={iconSize} color={styles.contentText.color} style={styles.title}>
              {buttonTitle ?? i18n.t('EDIT')}
            </TextWithIcon>
          </Pressable>
        </IM.View>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-between' },
  contentText: {
    color: IMStyle.palette.white,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
  },
})

export default EditOrCreateHeader
