import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { IndicatorBottom } from './IndicatorBottom'
import { IndicatorTop } from './IndicatorTop'

export const INDICATOR_SIZE = 8
export const INDICATOR_STROKE_WIDTH = 2

const styles = StyleSheet.create({
  indicatorBottom: {
    marginBottom: 4,
    marginLeft: 5,
    width: 10,
  },
  indicatorTop: {
    marginTop: 4,
    marginLeft: 5,
    width: 10,
  },
})

export type TopAndBottomIndicatorProps = {
  color?: string
  style: StyleProp<ViewStyle>
}

export type IndicatorProps = {
  position: 'top' | 'bottom'
}

type Props = IndicatorProps & {
  color?: string
  style?: StyleProp<ViewStyle>
}

export default function Indicator({ position, style, ...others }: Props) {
  return (
    <>
      {position === 'top' && <IndicatorTop style={[styles.indicatorTop, style]} {...others} />}
      {position === 'bottom' && <IndicatorBottom style={[styles.indicatorBottom, style]} {...others} />}
    </>
  )
}
