import { RouteProp, useRoute } from '@react-navigation/native'
import React from 'react'

import HeaderWithSync from '../../components/screen/headers/HeaderWithSync'
import { Screen } from '../../components/screen/Screen'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import { ActivityDetailStackParamList } from '../../navigation/types'
import ActivityWorkView from '../../views/activities/ActivityWorkView'

export default function ActivityDetailWorkScreen() {
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityDetailWork'>>()

  const { detail } = useActivityDetail(route.params)

  return (
    <Screen>
      <Screen.Header goBack>
        <HeaderWithSync title={detail?.title ?? route.params.title ?? ''} />
      </Screen.Header>

      <Screen.Content>
        <ActivityWorkView />
      </Screen.Content>
    </Screen>
  )
}
