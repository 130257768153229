import { useEvent, useLanguage } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React from 'react'

import { Screen } from '../../components/screen/Screen'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useActivityBackNavigation from '../../hooks/activity/useActivityBackNavigation'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityDetailStackParamList } from '../../navigation/types'
import ActivityClosingView from '../../views/activities/ActivityClosingView'

export default function ActivityClosingScreen() {
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityClosing'>>()
  const { i18n } = useLanguage()
  const { detail } = useActivityDetail(route.params)

  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
  })

  const { goBackToTop: onActivityClosed } = useActivityBackNavigation()

  const { emit: emitActivityClosedEvent } = useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED })
  function onActivityAlreadyClosed() {
    emitActivityClosedEvent()
    onActivityClosed()
  }

  return (
    <Screen>
      <Screen.Header
        goBack={() => handleGoBack()}
        backHandlerCallback={handleGoBack}
        title={`${i18n.t('ACTIVITY_CLOSING')}: ${detail?.title || route.params?.title || ''}`}
      />
      <Screen.Content>
        <ActivityClosingView setStatus={setStatus} onActivityClosed={onActivityClosed} onActivityAlreadyClosed={onActivityAlreadyClosed} />
      </Screen.Content>
    </Screen>
  )
}
