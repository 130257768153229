import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import PressableTextIcon from './Infominds/PressableTextIcon'

interface Props {
  onPress: () => void
  style?: StyleProp<ViewStyle>
  mode?: 'edit' | 'change' | 'ok' | 'abort'
  spacing?: SpacingProps
}

export default function ChangeButton({ onPress, style, mode = 'change', spacing }: Props) {
  const { i18n } = useLanguage()

  const text = useMemo(() => {
    if (mode === 'edit') return i18n.t('EDIT')
    if (mode === 'ok') return i18n.t('OK')
    if (mode === 'abort') return i18n.t('DONE')
    return i18n.t('CHANGE')
  }, [mode])

  const icon = useMemo<IconProp>(() => {
    if (mode === 'ok') return ['fal', 'check']
    if (mode === 'abort') return ['fal', 'times']
    return ['fal', 'chevron-right']
  }, [mode])

  return (
    <PressableTextIcon spacing={spacing ?? 'top'} icon={icon} alignIcon="right" secondary iconSize={14} onPress={onPress} containerStyle={style}>
      {text}
    </PressableTextIcon>
  )
}
