import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import { ListRenderItemInfo } from '@shopify/flash-list'
import React, { useCallback } from 'react'

import { Article } from '../../../apis/types/apiResponseTypes'
import ArticleContextSelectionCard from '../../../cards/articles/ArticleContextSelectionCard'
import FlashListData from '../../../components/FlashListData'
import CardSkeleton from '../../../components/skeleton/CardSkeleton'
import { InfiniteLoadingType, LoadingType } from '../../../types'

export type ArticleListViewProps = {
  articles: Article[]
  loadingArticles: InfiniteLoadingType | LoadingType
  refreshArticles?: () => void
  loadMoreArticles?: () => void
}

export default function ArticleListView({ articles, loadingArticles, refreshArticles, loadMoreArticles }: ArticleListViewProps) {
  const { i18n } = useLanguage()

  const renderItem = useCallback(({ item }: ListRenderItemInfo<Article | string>) => {
    if (typeof item === 'string') return <></>
    return (
      <IM.View spacing={['bottom', 'horizontal']}>
        <ArticleContextSelectionCard article={item} spacing={'horizontal'} />
      </IM.View>
    )
  }, [])

  return (
    <IM.View style={[IMLayout.flex.f1]}>
      <FlashListData
        data={articles}
        renderItem={renderItem}
        noDataMessage={i18n.t('NO_ARTICLE_FOUND')}
        keyExtractor={d => d.articleId}
        loading={loadingArticles}
        listSpacer
        refresh={refreshArticles}
        onLoadMore={loadMoreArticles}
        skeletonNumberLoading={3}
        skeletonComponent={<CardSkeleton />}
      />
    </IM.View>
  )
}
