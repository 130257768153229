import { useTheme } from '@infominds/react-native-components'

import { Employee } from '../apis/types/apiResponseTypes'

export default function useEmployeeColor() {
  const { themeUtils } = useTheme()

  function getEmployeeColor(employee: Employee | Employee | number | string | undefined | null) {
    if (!employee) return themeUtils.getRandomColorFromTheme(0)
    const id = typeof employee === 'object' ? employee.id : employee
    return themeUtils.getRandomColorFromTheme(id)
  }

  return {
    getEmployeeColor,
  }
}
