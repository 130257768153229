import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { SectionListRenderItemInfo, StyleSheet } from 'react-native'

import { api } from '../../apis/apiCalls'
import { ConstructionSite } from '../../apis/types/apiResponseTypes'
import ConstructionSiteCard from '../../cards/constructionSites/ConstructionSiteCard'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import { ADD_CONTACT_DESTINATION_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { ListSection } from '../../types'

interface Props {
  customerId: number
  show: boolean
  showTitle?: boolean
  skeletonNumber?: number
  closeEndThreshold?: number
  hideSectionTitle?: boolean
  selectedId?: string
  onPress?: (constructionSite: ConstructionSite) => void
  onRefresh?: () => void
}

const ConstructionSitesView = ({ show, customerId, showTitle, skeletonNumber, hideSectionTitle, closeEndThreshold, onRefresh }: Props) => {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { colorScheme } = useTheme()

  const {
    item: constructionSites,
    loadItem: loadConstructionSites,
    loading,
    allDataLoaded,
    loadMore,
  } = useInfiniteLoader(api.customers.constructionSites.getList, { chuckSize: CONSTANTS.defaultChunkSize })

  useEffect(() => {
    refresh()
  }, [search])

  const refresh = () => {
    onRefresh?.()
    loadConstructionSites({ customerId: customerId })
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<ConstructionSite, ListSection<ConstructionSite>>) => {
    return (
      <ConstructionSiteCard
        constructionSite={item}
        spacing={['horizontal', 'bottom']}
        // selected={item.id === selectedId}
        // onPress={() => {
        //   if (onPress) {
        //     onPress?.(item)
        //   } else {
        //     const data = { destination: item, customerId }

        //     isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
        //       ? navigation.navigate('CustomerDetailDestinationEditOrCreate', data)
        //       : destinationEditOrCreateModalController.show(data)
        //   }
        // }}
      />
    )
  }

  const data: ListSection<ConstructionSite>[] = useMemo(() => {
    const displayData: ListSection<ConstructionSite>[] = []

    if (constructionSites?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        title: showTitle ? i18n.t('CONSTRUCTION_SITES') : undefined,
        data: constructionSites,
      })
    }

    return displayData
  }, [constructionSites, loading])

  return (
    <>
      {show && (
        <SectionList
          contentContainerStyle={stylesList.list}
          loading={loading}
          noDataIcon={['fal', 'location-dot-slash']}
          loadingSection={i18n.t('CONSTRUCTION_SITES')}
          noDataSection={i18n.t('CONSTRUCTION_SITES')}
          noDataMessage={i18n.t('NO_CONSTRUCTION_SITE_FOUND')}
          sections={data}
          onRefresh={refresh}
          renderItem={renderItem}
          skeletonElements={skeletonNumber ?? CONSTANTS.skeletonCards}
          onLoadMore={loadMore}
          allDataLoaded={allDataLoaded}
          closeEndThreshold={closeEndThreshold ?? 90}
          hideButtonId={ADD_CONTACT_DESTINATION_BUTTON_ID}
          hideSectionTitle={hideSectionTitle}
          indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
        />
      )}
      {/* <DestinationEditOrCreateModal controller={destinationEditOrCreateModalController} /> */}
    </>
  )
}

const stylesList = StyleSheet.create({
  list: { paddingTop: 4 },
})

export default ConstructionSitesView
