import { useAuthentication } from '@infominds/react-native-license'
import { useNavigation } from '@react-navigation/native'
import React, { memo } from 'react'
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd'
import { useRecoilValue } from 'recoil'

import useEmbed from '../../hooks/useEmbed'
import { plannerShowActivityExtendedViewAtom } from '../../utils/stateManager'
import ActivityListBriefCard from './ActivityListBriefCard'
import ActivityListCard, { ActivityListCardProps } from './ActivityListCard'

interface Props extends ActivityListCardProps {
  isDragging?: boolean
  connectDragSource?: ConnectDragSource
  connectDragPreview?: ConnectDragPreview
}

const DndActivityListCard = memo(function DndActivityListCard({ isDragging, connectDragSource, connectDragPreview, ...props }: Props) {
  const { embed } = useEmbed()
  const { sessionKey } = useAuthentication()
  const navigation = useNavigation()
  const cardExtended = useRecoilValue(plannerShowActivityExtendedViewAtom(sessionKey))

  const handlePress = () =>
    navigation.navigate('ActivityDetailStack', {
      screen: 'ActivityDetail',
      params: {
        srvActivityId: props.activity.srvActivityId,
        srvActivityTypeId: props.activity.srvActivityTypeId,
        srvActivityYear: props.activity.srvActivityYear,
        source: 'PlannerStack',
      },
    })

  const dragContent = (
    <li>
      {cardExtended ? (
        <ActivityListCard disabled={embed} onPress={handlePress} {...props} />
      ) : (
        <ActivityListBriefCard disabled={embed} onPress={handlePress} {...props} />
      )}
    </li>
  )

  if (connectDragPreview === undefined || connectDragSource === undefined) return null

  return isDragging ? dragContent : <div>{connectDragPreview(connectDragSource(dragContent))}</div>
})

export default DndActivityListCard
