import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { UnavailableType } from '../../apis/types/apiResponseTypes'
import UnavailableTypeCreationScreen from '../../screens/activity/unavailables/UnavailableTypeCreationScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<UnavailableType | undefined>
}

export default function UnavailableCreationModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <UnavailableTypeCreationScreen ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}
