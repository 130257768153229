import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { AppStyle } from '../constants/Styles'
import useExtendedTheme from '../hooks/useExtendedTheme'
import useOnLayout from '../hooks/useOnLayout'
import Pressable, { PressableProps } from './Infominds/Pressable'
import LoadingIcon from './LoadingIcon'

export type ActivityButtonProps = {
  icon?: IconProp
  title?: string
  loading?: boolean
  style?: StyleProp<ViewStyle>
} & Omit<PressableProps, 'style'>

export default function ActivityButton({ title, icon, loading, style, disabled, ...pressableProps }: ActivityButtonProps) {
  const { theme } = useExtendedTheme()
  const { layout, onLayout } = useOnLayout()

  return (
    <Pressable
      style={[
        IMLayout.flex.row,
        styles.main,
        {
          backgroundColor: theme.primary,
        },
        disabled && styles.disabled,
        disabled && { backgroundColor: theme.button.disabledBackground },
        style,
        !disabled && IMLayout.shadow,
      ]}
      disabled={disabled || loading}
      {...pressableProps}>
      {!!title && (
        <IM.Text numberOfLines={1} primary style={[styles.text]}>
          {title}
        </IM.Text>
      )}
      {loading && icon && (
        <IM.View style={[{ width: layout?.width, height: layout?.height }, AppStyle.center]}>
          <LoadingIcon color={'#fff'} />
        </IM.View>
      )}
      {!loading && icon && (
        <IM.View style={styles.badge} onLayout={onLayout}>
          <IM.Icon icon={icon} color={'#fff'} />
        </IM.View>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  disabled: {
    opacity: 0.7,
  },
  main: {
    borderRadius: 50,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: IMLayout.horizontalMargin,
    paddingRight: IMLayout.horizontalMargin * 1.5,
    paddingVertical: IMLayout.horizontalMargin,
    paddingLeft: IMLayout.horizontalMargin * 1.5,
  },
  text: {
    color: '#FFF',
  },
  badge: {},
})
