import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import PlannerSmallScreenError from '../../components/PlannerSmallScreenError'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import ChartHeader from '../../components/screen/headers/ChartHeader'
import { Screen } from '../../components/screen/Screen'
import useEmbed from '../../hooks/useEmbed'
import useLayout from '../../hooks/useLayout'
import { PlannerStackParamList } from '../../navigation/types'
import PlannerView from '../../views/planner/PlannerView'

export default function PlannerScreen() {
  const { embed } = useEmbed()
  const { setLanguageAsync } = useLanguage()
  const { isLargeDevice } = useLayout(true)
  const { updateToken } = useAuthentication()
  const route = useRoute<RouteProp<PlannerStackParamList, 'Planner' | 'PlannerEmbed'>>()

  const [ready, setReady] = useState(route.name.includes('Embed') ? false : true)

  useEffect(() => {
    updateToken(route.params?.code?.toString(), route.params?.language)
      .then(() => {
        if (route.params?.language) {
          setLanguageAsync(route.params?.language)
            .catch(err => console.error('Error setting language', err))
            .finally(() => setReady(true))
        } else {
          setReady(true)
        }
      })
      .catch(err => console.error('Error updating token', err))
  }, [])

  if (!ready) return <></>

  return (
    <>
      {embed && !isLargeDevice ? (
        <PlannerSmallScreenError />
      ) : (
        <SearchProvider>
          <Screen>
            <Screen.Header>{isLargeDevice && <ChartHeader />}</Screen.Header>
            <Screen.Content>
              {!isLargeDevice && <PlannerSmallScreenError />}

              <IM.View style={[!isLargeDevice && styles.container, IMLayout.flex.f1]}>
                <PlannerView companyId={route.params?.companyId} objectId={route.params?.objectId} />
              </IM.View>
            </Screen.Content>
          </Screen>
        </SearchProvider>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: { display: 'none' },
})
