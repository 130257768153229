import { IM, SpacingProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

interface Props {
  width?: number | string
  height?: number | string
  spacing?: SpacingProps
  color?: string
  style?: StyleProp<ViewStyle>
}

export default function TravelSkeleton({ spacing, color, style }: Props) {
  return (
    <IM.View spacing={spacing} style={[style]}>
      <IM.SkeletonContainer height={74} width={200} backgroundColor={color}>
        <IM.Rect x="0" y="0" rx="5" ry="5" width={80} height={12} />
        <IM.Rect x="0" y="20" rx="16" ry="16" width={32} height={32} />
        <IM.Rect x="35" y="23" rx="5" ry="5" width={100} height={12} />
        <IM.Rect x="35" y="37" rx="5" ry="5" width={90} height={12} />
      </IM.SkeletonContainer>
    </IM.View>
  )
}
