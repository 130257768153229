import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Animated, Easing, Platform } from 'react-native'

import { ThemeColorExpanded } from '../types'

interface Props {
  loading: boolean
  size?: number
  icon?: IconProp
}

export default function SpinningIcon({ loading, size, icon }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const spinValue = new Animated.Value(0)

  loading &&
    Animated.loop(
      Animated.timing(spinValue, {
        toValue: 1,
        duration: 800,
        easing: Easing.linear,
        useNativeDriver: Platform.OS === 'android' || Platform.OS === 'ios',
      })
    ).start()

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  })

  return (
    <Animated.View style={{ transform: [{ rotate: spin }] }}>
      <IM.Icon icon={icon ?? ['fal', 'circle-notch']} color={theme.textPlaceholder} size={size} />
    </Animated.View>
  )
}
