import { IM, IMLayout, useLanguage, Utils, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { AppStyle } from '../../constants/Styles'
import { Repetition } from '../../types'
import Pressable from '../Infominds/Pressable'
import BaseTextInputTitle from '../input/baseTextInput/BaseTextInputTitle'
import DateInput from '../input/DateInput'
import NumberInput from '../input/NumberInput'
import RadioButton from '../RadioButton'
import DaySelector from './DaySelector'
import FirstLastSelector from './FirstLastSelector'
import MonthSelector from './MonthSelector'
import RepetitionTypeSelector from './RepetitionTypeSelector'
import { RepetitionUtils } from './RepetitionUtils'
import WeekDaySelector from './WeekDaySelector'

export type RepetitionSelectorProps = {
  repetition?: Repetition
  onChange: (value: Repetition) => void
} & Pick<ViewProps, 'spacing' | 'style'>

export default function RepetitionSelector({ repetition, onChange, ...viewProps }: RepetitionSelectorProps) {
  const { i18n, language } = useLanguage()

  const planDates = useMemo(() => !!repetition && RepetitionUtils.calculateRepetitionDates(repetition), [repetition])
  const repetitionText = useMemo(() => !!repetition && RepetitionUtils.getRepetitionText(i18n, repetition, language), [repetition])

  const { calculatedEndDate, calculatedTotalRepetitions } = useMemo(() => {
    if (repetition?.type === 'none' || !planDates || !planDates.length) return {}
    if (!repetition?.endDate && !!repetition?.totalRepetitions) return { calculatedEndDate: planDates[planDates.length - 1] }
    if (!!repetition?.endDate && !repetition.totalRepetitions) return { calculatedTotalRepetitions: planDates.length }
    return {}
  }, [repetition, planDates])

  const repeatEveryText = useMemo(() => {
    switch (repetition?.type) {
      case 'day':
        return i18n.t('REPETITION_INTERVAL_DAY')
      case 'week':
        return i18n.t('REPETITION_INTERVAL_WEEK')
      case 'month':
        return i18n.t('REPETITION_INTERVAL_MONTH')
      case 'year':
        return i18n.t('REPETITION_INTERVAL_YEAR')
      default:
        return ''
    }
  }, [repetition?.type])

  const commonProps = {
    spacing: 'vertical',
  } satisfies Partial<ViewProps>

  function handleChange(value: Partial<Repetition>) {
    onChange({ ...repetition, ...value } as Repetition)
  }

  return (
    <IM.View {...viewProps}>
      <RepetitionTypeSelector title={i18n.t('REPETITION')} selected={repetition?.type} onChange={type => onChange({ type })} {...commonProps} />
      {repetition?.type !== 'none' && (
        <>
          <DateInput
            title={i18n.t('START')}
            value={repetition?.startDate?.toISOString()}
            onChangeDate={date => handleChange({ startDate: date })}
            required
            {...commonProps}
          />
          {repetition?.type !== 'year' && (
            <NumberInput
              title={i18n.t('REPETITION_EVERY')}
              value={repetition?.repeatEvery}
              onValueChanged={value => handleChange({ repeatEvery: value })}
              disableFastInputs
              unit={repeatEveryText}
              required
              {...commonProps}
            />
          )}
          {repetition?.type === 'week' && (
            <>
              <WeekDaySelector
                title={i18n.t('REPETITION_ALLOWED_DAYS')}
                selectedWeekDays={repetition.weekDays}
                setWeekDays={weekDays => handleChange({ weekDays })}
                required
                {...commonProps}
              />
            </>
          )}
          {repetition?.type === 'year' && (
            <MonthSelector
              title={i18n.t('REPETITION_EVERY_MONTH')}
              value={repetition.repeatEveryMonth}
              onChange={value => handleChange({ repeatEveryMonth: value })}
              required
              {...commonProps}
            />
          )}

          {(repetition?.type === 'month' || repetition?.type === 'year') && (
            <>
              <IM.View {...commonProps}>
                <BaseTextInputTitle title={`${i18n.t('REPETITION_ON')} *`} />
                <IM.View spacing={'bottom'}>
                  <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin * 3 }]}>
                    <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
                      <IM.View style={AppStyle.center}>
                        <Pressable onPress={() => handleChange({ onDayType: 'numeric' })}>
                          <RadioButton selected={!repetition.onDayType || repetition.onDayType === 'numeric'} />
                        </Pressable>
                      </IM.View>
                      <IM.View style={IMLayout.flex.f1}>
                        <NumberInput
                          value={repetition?.onDay}
                          onValueChanged={value => handleChange({ onDay: value, onDayType: 'numeric' })}
                          disableFastInputs
                          unit={i18n.t('DAY')}
                        />
                      </IM.View>
                    </IM.View>
                    <IM.View style={[IMLayout.flex.f1]} />
                  </IM.View>
                  {!!repetition.onDay && repetition.onDay > 28 && (
                    <IM.Text secondary>{Utils.stringValueReplacer(i18n.t('REPETITION_WARN_DAY_TO_BIG'), repetition.onDay)}</IM.Text>
                  )}
                </IM.View>

                <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin * 3 }]} spacing={'top'}>
                  <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
                    <IM.View style={AppStyle.center}>
                      <Pressable onPress={() => handleChange({ onDayType: 'relative' })}>
                        <RadioButton selected={repetition.onDayType === 'relative'} />
                      </Pressable>
                    </IM.View>
                    <IM.View style={IMLayout.flex.f1}>
                      <FirstLastSelector
                        value={repetition.onDayRelativeType}
                        onChange={value => handleChange({ onDayRelativeType: value, onDayType: 'relative' })}
                      />
                    </IM.View>
                  </IM.View>
                  <IM.View style={[IMLayout.flex.f1]}>
                    <DaySelector
                      value={repetition.onWeekDay}
                      onChange={value => handleChange({ onWeekDay: value, onDayType: 'relative' })}
                      placeholder="Day"
                      required
                    />
                  </IM.View>
                </IM.View>
              </IM.View>
            </>
          )}

          <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin * 3 }]}>
            <IM.View style={IMLayout.flex.f1}>
              <DateInput
                title={i18n.t('END')}
                value={repetition?.endDate?.toISOString() ?? calculatedEndDate?.toISOString()}
                // set totalRepetitions to undefined since only one of the 2 can have an effect
                onChangeDate={date => handleChange({ endDate: date, totalRepetitions: undefined })}
                required
                {...commonProps}
              />
            </IM.View>
            <IM.View style={IMLayout.flex.f1}>
              <NumberInput
                title={i18n.t('TOTAL')} // required to
                value={repetition?.totalRepetitions ?? calculatedTotalRepetitions}
                // set endDate to undefined since only one of the 2 can have an effect
                onValueChanged={value => handleChange({ totalRepetitions: value, endDate: undefined })}
                disableFastInputs
                unit={i18n.t('REPETITION_N_TIMES')}
                style={IMLayout.flex.f1}
                {...commonProps}
              />
            </IM.View>
          </IM.View>
        </>
      )}

      {!!repetitionText && <IM.Text secondary>{repetitionText}</IM.Text>}

      {/* {!!planDates && (
        <IM.View spacing={'top'}>
          <IM.Text secondary>{i18n.t('REPETITION_DATES_INFO')}</IM.Text>
          {planDates?.map((date, index) => (
            <IM.Text secondary key={index}>
              {TimeUtils.format(date, language, 'P EEEE')}
            </IM.Text>
          ))}
        </IM.View>
      )} */}
    </IM.View>
  )
}
