import { ModalController, useDimensions } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import React, { PropsWithChildren, useEffect, useState } from 'react'

import { ModalScreenRef } from '../types'
import Modal from './components/Modal'
import ModalContentWrapper from './components/ModalContentWrapper'
import { ModalProps } from './components/type'

interface Props<T> extends PropsWithChildren, Omit<ModalProps, 'visible' | 'onClose'> {
  childRef?: React.RefObject<ModalScreenRef>
  controller: ModalController<T>
  hideOnBlur?: boolean
}

export default function BaseServiceModal<T>({ children, controller, childRef, hideOnBlur, ...otherProps }: Props<T>) {
  const isFocused = useIsFocused()
  const { isSmallDevice } = useDimensions()

  const [hide, setHide] = useState(false)

  useEffect(() => {
    if (!hideOnBlur) return
    setHide(!isFocused)
  }, [isFocused])

  const handleClose = () => {
    if (childRef) return childRef.current?.handleGoBack()

    controller.close()
  }

  return (
    <Modal
      visible={controller.isShown && !hide}
      onClose={handleClose}
      backdropOpacity={isSmallDevice || otherProps.fullWidth ? 0 : undefined}
      {...otherProps}>
      <ModalContentWrapper>{children}</ModalContentWrapper>
    </Modal>
  )
}
