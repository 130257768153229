import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import PriceInput, { PriceInputProps } from './PriceInput'

enum DiscountKeys {
  rowDiscount1 = 'rowDiscount1',
  rowDiscount2 = 'rowDiscount2',
  rowDiscount3 = 'rowDiscount3',
  rowDiscount4 = 'rowDiscount4',
}
type DiscountState = Partial<{ [key in DiscountKeys]: number }>
const discountKeys = Object.values(DiscountKeys)

export type DiscountInputProps = {
  state: DiscountState | undefined
  onChange: (discounts: DiscountState) => void
} & Pick<ViewProps, 'style' | 'spacing'> &
  Pick<PriceInputProps, 'editable' | 'title'>

export default function DiscountInput({ state, onChange, style, spacing, title, ...inputProps }: DiscountInputProps) {
  const displayedInputs = useMemo(() => {
    if (!state) return [DiscountKeys.rowDiscount1]

    const keys: DiscountKeys[] = []
    for (const key of discountKeys) {
      keys.push(key as DiscountKeys)
      const value = state[key]
      if (!value) return keys
    }
    return keys
  }, [state])

  function handleChange(key: DiscountKeys, value: number) {
    const newState = { [key]: value }
    // if value is 0 reset all following discounts
    if (!value) {
      const indexOfKey = discountKeys.indexOf(key)
      for (const discountKey of discountKeys.slice(indexOfKey + 1)) {
        newState[discountKey] = 0
      }
    }
    onChange(newState)
  }

  return (
    <IM.View style={[IMLayout.flex.row, styles.container, style]} spacing={spacing}>
      {displayedInputs.map((key, index) => {
        return (
          <IM.View style={[styles.inputView]} key={key}>
            <PriceInput
              key={key}
              value={state ? state[key] : undefined}
              onEndEditing={value => handleChange(key, value)}
              title={index === 0 ? title : title ? ' ' : undefined}
              selectTextOnFocus
              {...inputProps}
            />
          </IM.View>
        )
      })}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: IMLayout.horizontalMargin,
  },
  inputView: {
    flex: 1,
  },
})
