import { ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef } from 'react'
import { Keyboard } from 'react-native'

import { Customer } from '../../apis/types/apiResponseTypes'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { EditOrCreateViewRef, ModalScreenRef } from '../../types'
import CustomerCreateOrEditView from '../../views/customer/CustomerCreateOrEditView'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<Customer | undefined>
  onCustomerCreated: (customer: Customer) => void
  taskId: number
}

export default function CustomerCreationModal({ controller, ...props }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <SearchProvider>
        <ContentWrapper {...props} controller={controller} />
      </SearchProvider>
    </BaseServiceModal>
  )
}

// Wrap the content of the modal in a separate component to make sure the alert of the useEditOrCreateScreenBackManager is displayed correctly
function ContentWrapper({ controller, onCustomerCreated, taskId }: Props) {
  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const customerToEdit = controller.data

  return (
    <Screen transparent>
      <Screen.Header
        goBack={() => handleGoBack()}
        backHandlerCallback={handleGoBack}
        isModal
        title={customerToEdit ? i18n.t('EDIT_CUSTOMER') : i18n.t('CREATE_CUSTOMER')}
        onRightIconPress={() => {
          Keyboard.dismiss()
          createViewRef.current?.handleUpload()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}
      />
      <Screen.Content>
        <FormProvider>
          <CustomerCreateOrEditView
            taskId={taskId}
            customer={customerToEdit}
            ref={createViewRef}
            onDone={(_, result, request) => {
              handleGoBack(true)
              const createdCompanyId = result?.at(0)
              if (!createdCompanyId) {
                console.debug('Failed to get created companyId')
                return
              }
              onCustomerCreated({ ...request, companyId: createdCompanyId } as Customer)
            }}
            onUploadStatus={setStatus}
          />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}
