import { useModalController } from '@infominds/react-native-components'
import React from 'react'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import ActivityDetailEditHeader from '../../components/screen/headers/ActivityDetailEditHeader'
import { Screen } from '../../components/screen/Screen'
import ReportActivityCreationModal from '../../modals/activity/ReportActivityCreationModal'
import { ReportActivityCreateOrEditViewProps } from '../../views/activities/ReportActivityCreateOrEditView'
import NewReportView from '../../views/report/NewReportView'

export default function NewReportScreen() {
  const editActivityModal = useModalController<ReportActivityCreateOrEditViewProps>()

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header>
          <ActivityDetailEditHeader onActivityEdit={detail => editActivityModal.show({ activity: detail })} />
        </Screen.Header>
        <Screen.Content>
          <NewReportView />
        </Screen.Content>
      </Screen>
      <ReportActivityCreationModal controller={editActivityModal} />
    </SearchProvider>
  )
}
