import { useEvent } from '@infominds/react-native-components'
import { useContext, useEffect, useRef } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity, ActivityArticle } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { ActivityDetailContext } from '../../contexts/activity/ActivityDetailContext'
import { ActivityId } from '../../types'
import useObjectUtils from '../useObjectUtils'

export type UseActivityArticlesOptions = {
  dontLoadOnMount?: boolean
  setRda?: boolean
}

export default function useActivityArticles(activity: ActivityId | null | undefined, options?: UseActivityArticlesOptions) {
  const activityUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const apiValues = useRef<ActivityArticle[]>([])

  const {
    item: articles,
    loadItem: loadArticles,
    loading: loadingArticles,
    setItem: setArticles,
    setLoading,
  } = useControlledLoader(api.activities.articles.getList, {
    backgroundUpdaterInterval: CONSTANTS.backgroundUpdateInterval,
    onLoadedCallback: values => {
      apiValues.current = values
    },
    modifyResult: options?.setRda ? r => r.map(a => ({ ...a, isRda: true })) : undefined,
  })

  useEvent({ key: EVENT_KEYS.ACTIVITY_ARTICLES_UPDATED }, refreshArticles)
  useEffect(() => {
    if (options?.dontLoadOnMount) {
      setLoading(false)
      return
    }
    refreshArticles()
  }, [activity])

  function refreshArticles() {
    if (!activity) {
      setArticles([])
      return
    }
    loadArticles(activityUtils.createRequestObject(activity))
  }

  const detailContext = useContext(ActivityDetailContext) // using useContext instead of custom hook here to allow usage outside of context provider
  useEffect(() => detailContext?.setDataCount(prev => ({ ...prev, articles: articles?.length ?? 0 })), [articles])

  return { articles, loadingArticles, refreshArticles, setArticles, apiValues: apiValues.current }
}
