import { CardButton, IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'

import type { Destination } from '../../apis/types/apiResponseTypes'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import useClipboard from '../../hooks/useClipboard'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'
import { utils } from '../../utils/utils'
import LoaderCard, { LoadedCardProps } from '../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData' | 'buttons'> & { destination: Destination | undefined | null; disableIcon?: boolean }

const CustomerDestinationCard = memo(function CustomerDestinationCard({ destination, disableIcon, hideButtons, loading = false, ...props }: Props) {
  const alert = useAlert()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()
  const hasData = destination?.destinationId
  const { copyToClipboard } = useClipboard()

  const inactive = false
  const phone = destination?.phone
  const buttons: CardButton[] = []

  const handlePhonePress = () => {
    phone && appUtils.openPhone(phone, alert, i18n.t('OPEN_PHONE_ERROR'))
  }

  const address = useMemo(() => customerUtils.formatAddress(destination?.address, 'street'), [destination])
  const location = useMemo(() => customerUtils.formatAddress(destination?.address, 'location'), [destination])

  const handleRoutePress = () => {
    if (destination?.address?.latitude && destination.address.longitude) {
      utils.openMaps(destination?.address?.latitude, destination.address.longitude, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
    } else {
      utils.openMapsWithQuery(address + ' ' + location, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
    }
  }

  if (address !== '' && location !== '') {
    buttons.push({
      icon: ['fal', 'route'],
      ...appUtils.getCardButtonColor(!inactive, theme),
      overrideDisabled: true,
      onPress: inactive ? undefined : handleRoutePress,
      onLongPress: inactive ? undefined : () => copyToClipboard(address + ' ' + location),
    })
  }

  if (phone) {
    buttons.push({
      icon: ['fal', 'phone'],
      ...appUtils.getCardButtonColor(!inactive, theme),
      overrideDisabled: true,
      onPress: inactive ? undefined : handlePhonePress,
      onLongPress: inactive ? undefined : () => copyToClipboard(phone),
    })
  }

  return (
    <LoaderCard
      noDataText={i18n.t('NO_DESTINATION_SELECTED')}
      hasData={!!destination?.destinationId}
      loading={loading}
      buttons={{ buttons: hideButtons || !hasData || loading !== false ? [] : buttons }}
      {...props}>
      <TextWithIcon
        secondary={inactive}
        icon={disableIcon ? undefined : ['fal', 'location-dot']}
        iconSize={20}
        style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }]}>
        {destination?.description}
      </TextWithIcon>
      {address !== '' && (
        <IM.View style={IMLayout.flex.f1} spacing="top">
          <IM.Text secondary={inactive}>{customerUtils.formatAddress(destination?.address, 'street')}</IM.Text>
          <IM.Text secondary={inactive}>{customerUtils.formatAddress(destination?.address, 'location')}</IM.Text>
          {phone && <IM.Text secondary={inactive}>{phone}</IM.Text>}
        </IM.View>
      )}
    </LoaderCard>
  )
})

export default CustomerDestinationCard
