import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { Platform, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'

import { api } from '../../apis/apiCalls'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { ScreenHeaderShadows } from '../../components/screen/constants/constants'
import useLayout from '../../hooks/useLayout'
import CustomerDetailsLargeDeviceView from './CustomerDetailsLargeDeviceView'
import CustomerDetailsSmallDeviceView from './CustomerDetailsSmallDeviceView'

interface Props {
  customerId: number
}

const CustomerDetailsView = ({ customerId }: Props) => {
  const { colorScheme } = useTheme()
  const { isSmallDevice } = useLayout()
  const { width } = useWindowDimensions()
  const { item: customer, loadItem: loadCustomer, loading } = useControlledLoader(api.customers.getDetail)

  useEffect(() => {
    refresh()
  }, [customerId])

  function refresh() {
    loadCustomer({ customerId })
  }

  return (
    <ScrollView
      canCancelContentTouches={false}
      keyboardShouldPersistTaps="never"
      contentContainerStyle={[IMLayout.flex.f1, ScreenHeaderShadows]}
      horizontal
      indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
      style={styles.container}>
      {loading === false && !!customer ? (
        <>{isSmallDevice ? <CustomerDetailsSmallDeviceView customer={customer} /> : <CustomerDetailsLargeDeviceView customer={customer} />}</>
      ) : (
        <IM.SkeletonContainer style={IMLayout.flex.f1}>
          <IM.Rect x="20" y="20" rx="5" ry="5" width={'40%'} height={20} />
          <IM.Rect x={width - width * 0.4 - 20} y="20" rx="5" ry="5" width={width * 0.4} height={20} />
          <IM.Rect x="20" y="60" rx="5" ry="5" width={'40%'} height={20} />
          <IM.Rect x={width - width * 0.4 - 20} y="60" rx="5" ry="5" width={width * 0.4} height={20} />
          <IM.Rect x="0" y="100" rx="20" ry="20" width={'100%'} height={'100%'} />
        </IM.SkeletonContainer>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  // @ts-ignore missing overflowX on RN
  container: {
    ...Platform.select({
      web: {
        overflowX: 'hidden',
      },
    }),
  },
})

export default CustomerDetailsView
