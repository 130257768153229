import { CardProps, IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { TimeSlot } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import { ThemeColorExpanded } from '../../types'
import TimeUtils from '../../utils/TimeUtils'

export type TimeSlotCardProps = {
  timeSlot: TimeSlot
} & Pick<CardProps, 'style' | 'spacing' | 'onPress' | 'selected'>

export default function TimeSlotCard({ timeSlot, selected, ...otherProps }: TimeSlotCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { value: title } = useLanguageSelect({ en: timeSlot.titleEn, it: timeSlot.titleIt, de: timeSlot.titleDe })
  const { value: description } = useLanguageSelect({ en: timeSlot.descriptionEn, it: timeSlot.descriptionIt, de: timeSlot.descriptionDe })
  const time = useMemo(
    () => timeSlot.hourFrom && timeSlot.hourTo && TimeUtils.formateTimeStampFromTo(timeSlot.hourFrom, timeSlot.hourTo),
    [timeSlot.hourFrom, timeSlot.hourTo]
  )

  return (
    <IM.Card {...otherProps} head={<CardLeftTitle color={selected && theme.primary} />}>
      <IM.View style={IMLayout.flex.row}>
        <IM.View style={IMLayout.flex.f1}>
          <IM.View style={IMLayout.flex.row}>
            <IM.Text primary>{title}</IM.Text>
            {!!timeSlot.increasePercentage && (
              <IM.Text style={styles.percentageText} secondary>
                {`${timeSlot.increasePercentage > 0 ? '+' : '-'}${timeSlot.increasePercentage}%`}
              </IM.Text>
            )}
          </IM.View>
          {description && <IM.Text secondary>{description}</IM.Text>}
        </IM.View>
        <IM.View style={styles.infoView}>
          <IM.Text secondary>{time}</IM.Text>
        </IM.View>
      </IM.View>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  percentageText: {
    marginLeft: 10,
  },
  infoView: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
})
