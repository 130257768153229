import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, useLanguage, Utils, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { AppStyle } from '../constants/Styles'
import { ListDisplayMode } from '../types'
import PressableTextIcon from './Infominds/PressableTextIcon'

export type MoreButtonProps = ViewProps & {
  allItemsCount: number
  shownItemsCount: number
  displayMode: ListDisplayMode
  setDisplayMode: React.Dispatch<React.SetStateAction<ListDisplayMode>>
  direction?: 'up' | 'down'
}

export default function MoreButton({
  allItemsCount,
  shownItemsCount,
  displayMode,
  setDisplayMode,
  style,
  direction = 'down',
  ...viewProps
}: MoreButtonProps) {
  const { i18n } = useLanguage()

  const moreCount = allItemsCount - shownItemsCount
  const text = useMemo(() => {
    if (displayMode === 'full') return i18n.t('SHOW_LESS')
    return Utils.stringValueReplacer(i18n.t('N_MORE'), moreCount)
  }, [displayMode, allItemsCount, shownItemsCount])

  const icon = useMemo<IconProp>(() => {
    let down = displayMode === 'full'
    if (direction !== 'down') down = !down
    return down ? ['fal', 'chevron-down'] : ['fal', 'chevron-up']
  }, [direction, displayMode])

  if (displayMode === 'chunk' && moreCount <= 0) return <></>

  return (
    <IM.View style={[AppStyle.center, style]} {...viewProps}>
      <PressableTextIcon icon={icon} onPress={() => setDisplayMode(prev => (prev === 'chunk' ? 'full' : 'chunk'))}>
        {text}
      </PressableTextIcon>
    </IM.View>
  )
}
