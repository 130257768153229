import { ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef } from 'react'
import { Keyboard } from 'react-native'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { EditOrCreateViewRef, ModalScreenRef } from '../../types'
import ReportActivityCreateOrEditView, { ReportActivityCreateOrEditViewProps } from '../../views/activities/ReportActivityCreateOrEditView'
import BaseServiceModal from '../BaseServiceModal'

type Props = {
  controller: ModalController<ReportActivityCreateOrEditViewProps>
}

export default function ReportActivityCreationModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ModalContent ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}

function ModalContent({ controller }: Props & { ref: ForwardedRef<ModalScreenRef> }) {
  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const activity = controller.data?.activity

  return (
    <SearchProvider>
      <Screen transparent>
        <Screen.Header
          goBack={() => handleGoBack()}
          backHandlerCallback={handleGoBack}
          isModal
          title={activity ? i18n.t('EDIT_ACTIVITY') : i18n.t('CREATE_ACTIVITY')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            <ReportActivityCreateOrEditView
              ref={createViewRef}
              activity={activity}
              onDone={() => handleGoBack(true)}
              onDeleted={() => {
                controller?.close()
              }}
              onUploadStatus={setStatus}
            />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
