import { useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'
import { Platform } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import useLayout from '../../../hooks/useLayout'
import useUserSettings from '../../../hooks/useUserSettings'
import ActivityCreationModal from '../../../modals/activity/ActivityCreationModal'
import { ActivityDetailStackParamList } from '../../../navigation/types'
import { ActivityCreateOrEditViewProps } from '../../../views/activities/ActivityCreateOrEditView'
import EditOrCreateHeader from './EditOrCreateHeader'

type Props = {
  onActivityEdit?: (detail: Activity) => void
}

export default function ActivityDetailEditHeader({ onActivityEdit }: Props) {
  const { i18n } = useLanguage()
  const { detail, isOpen } = useActivityDetail()

  const { isSmallDevice } = useLayout()
  const navigation = useNavigation<NavigationProp<ActivityDetailStackParamList, 'ActivityDetail'>>()
  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityDetail'>>()
  const editActivityModal = useModalController<ActivityCreateOrEditViewProps>()
  const { user } = useUserSettings()

  function handleActivityEdit() {
    if (!detail) return
    if (onActivityEdit) {
      onActivityEdit(detail)
      return
    }
    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      navigation.navigate('ActivityEditStack', { screen: 'ActivityCreation', params: { activity: detail, source: route.params.source } })
    } else {
      editActivityModal.show({ activity: detail })
    }
  }

  return (
    <>
      <EditOrCreateHeader
        title={detail?.title ?? route.params?.title ?? ''}
        icon={['fal', 'pen-field']}
        iconSize={20}
        showButton={!!detail && !!isOpen && user.can.modify.activity}
        buttonTitle={i18n.t('EDIT')}
        onPress={handleActivityEdit}
      />
      <ActivityCreationModal controller={editActivityModal} />
    </>
  )
}
