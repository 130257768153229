import { useDimensions, useOrientation } from '@infominds/react-native-components'
import { Platform } from 'react-native'

export type LayoutType = 'small' | 'medium' | 'large'

export type ForceLayoutType = false | LayoutType

type LayoutSelect<T> = {
  small: T
  large: T
  medium?: T
}
const mediumKey: keyof LayoutSelect<unknown> = 'medium'

export default function useLayout(
  detectMediumDevice?: boolean,
  forceLayout?: ForceLayoutType
): {
  isSmallDevice: boolean
  isMediumDevice: boolean
  isLargeDevice: boolean
  isLandscape: boolean
  isPortrait: boolean
  isDesktop: boolean
  layoutSelect: <T>(values: LayoutSelect<T>) => T
} {
  const { isSmallDevice } = useDimensions()
  const { portrait, landscape } = useOrientation()
  const isMedium = !isSmallDevice && !!detectMediumDevice && portrait
  const isLarge = !isSmallDevice && (!detectMediumDevice || landscape)

  function layoutSelect<T>(values: LayoutSelect<T>) {
    if (isSmallDevice || forceLayout === 'small') return values.small
    if ((isMedium && mediumKey in values) || forceLayout === 'medium') return values.medium as T
    if (isLarge || forceLayout === 'large' || (isMedium && mediumKey in values === false)) return values.large
    return undefined as T
  }

  if (forceLayout) {
    return {
      isSmallDevice: forceLayout === 'small',
      isMediumDevice: forceLayout === 'medium',
      isLargeDevice: forceLayout === 'large',
      isLandscape: landscape,
      isPortrait: portrait,
      isDesktop: Platform.OS === 'web' && (forceLayout === 'medium' || forceLayout === 'large'),
      layoutSelect,
    }
  }

  return {
    isSmallDevice,
    isMediumDevice: isMedium,
    isLargeDevice: isLarge,
    isLandscape: landscape,
    isPortrait: portrait,
    isDesktop: Platform.OS === 'web' && (isMedium || isLarge),
    layoutSelect,
  }
}
