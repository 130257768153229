import { ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api } from '../../../apis/apiCalls'
import { Vehicle } from '../../../apis/types/apiResponseTypes'
import VehicleCard from '../../../cards/activities/rda/VehicleCard'
import useInfiniteLoader from '../../../components/Infominds/hooks/useInfiniteLoader'
import { SearchProvider } from '../../../components/screen/contexts/SearchContext'
import useSearch from '../../../components/screen/hooks/useSearch'
import CONSTANTS from '../../../constants/Constants'
import { ListSection } from '../../../types'
import { filterUtils } from '../../../utils/FilterUtils'
import ScreenSelectModal from '../../ScreenSelectModal'

export type RdaVehicleSelectionModalProps = {
  controller: ModalController<string | undefined>
  onChange: (vehicle: Vehicle | undefined) => void
  selectedVehicleRegistrationNr: string | undefined
}

export default function RdaVehicleSelectionModal(props: RdaVehicleSelectionModalProps) {
  return (
    <SearchProvider>
      <Content {...props} />
    </SearchProvider>
  )
}

function Content({ controller, onChange, selectedVehicleRegistrationNr }: RdaVehicleSelectionModalProps) {
  const { i18n } = useLanguage()

  const { search, setSearch } = useSearch()

  const {
    item: allData,
    loadItem: loadData,
    loadMore,
    loading,
    allDataLoaded,
  } = useInfiniteLoader(api.vehicles.getList, { chuckSize: CONSTANTS.defaultChunkSize })

  const data = useMemo(
    () =>
      filterUtils.filterItemsBySearch(
        allData.filter(q => q.registrationNr),
        search
      ),
    [allData, search]
  )
  const selected = useMemo(() => data.find(q => q.registrationNr === selectedVehicleRegistrationNr), [data, selectedVehicleRegistrationNr])

  useEffect(() => {
    if (!controller.isShown) return
    if (!data?.length) loadData({})
  }, [controller.isShown])

  const renderItem = ({ item }: SectionListRenderItemInfo<Vehicle, ListSection<Vehicle>>, onPress?: () => void) => {
    return (
      <VehicleCard
        vehicle={item}
        onPress={onPress}
        selected={selectedVehicleRegistrationNr === item.registrationNr}
        spacing={['horizontal', 'bottom']}
      />
    )
  }

  return (
    <ScreenSelectModal
      showNoSelectionItem
      isVisible={!!controller.isShown}
      onClose={controller.close}
      screenTitle={i18n.t('SELECT_VEHICLE')}
      selected={selected}
      noDataMessage={i18n.t('NO_VEHICLE_FOUND')}
      data={data}
      loading={loading}
      onLoadMore={loadMore}
      allDataLoaded={allDataLoaded}
      renderItem={renderItem}
      onChange={onChange}
      setIsVisible={controller.close}
      onSearchChange={setSearch}
    />
  )
}
