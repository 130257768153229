import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { api } from '../../../apis/apiCalls'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import useUserSettings from '../../../hooks/useUserSettings'
import ContractCard from '../ContractCard'

interface Props {
  error?: ConstrainBoolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  changeable?: boolean
}

export default function ActivityDetailContractIdCard({ error, spacing, style, changeable }: Props) {
  const { i18n } = useLanguage()
  const navigation = useNavigation()

  const { detail } = useActivityDetail()
  const { user } = useUserSettings()

  const {
    item: contract,
    loadItem: loadContract,
    loading: loadingContract,
    setItem: setContract,
  } = useControlledLoader(api.activities.contracts.getDetail)

  useEffect(() => {
    load()
  }, [detail?.srvContractId])

  function load() {
    if (!detail?.srvContractId) {
      setContract(undefined)
      return
    }
    loadContract({ contractId: detail.srvContractId })
  }

  return (
    <>
      <ContractCard
        contract={contract}
        leftText={i18n.t('CONTRACT')}
        disabled={!contract || !user.can.see.anagrafics}
        enableChange={changeable}
        loading={loadingContract}
        onPress={
          !error && !!contract
            ? () => {
                navigation.navigate('ContractDetailStack', { screen: 'ContractDetail', params: { contractId: contract.srvContractId } })
              }
            : undefined
        }
        style={style}
        spacing={spacing}
      />
    </>
  )
}
