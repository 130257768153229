import { useDidUpdate, Utils } from '@infominds/react-native-components'
import { useEffect, useState } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity, ActivityEmployee } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { PlannedActivityEmployee } from '../../types'
import { imDateUtils } from '../../utils/infominds/imDateUtils'
import useObjectUtils from '../useObjectUtils'
import useUserSettings from '../useUserSettings'

export default function useActivityEmployeePlaner(activity?: Activity, preset?: PlannedActivityEmployee[]) {
  const activityObjUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const activityEmployeeUtils = useObjectUtils<ActivityEmployee>(apiDtoIds.activityEmployee)

  const { userSettings, user } = useUserSettings()

  const [plannedEmployees, setPlannedEmployees] = useState<PlannedActivityEmployee[]>(
    preset ??
      (!activity && userSettings?.employee && user.isTeamLeader
        ? [{ id: Utils.getUid(), technician: { technicianType: 'employee', ...userSettings?.employee } }]
        : [])
  )

  const {
    item: loadedPlannedEmployees,
    loadItem: loadPlannedEmployees,
    loading: loadingPlannedEmployees,
  } = useControlledLoader(api.activities.employees.getList)

  useEffect(() => {
    if (!activity) return
    loadPlannedEmployees(activityObjUtils.createRequestObject(activity))
  }, [])

  useDidUpdate(() => {
    if (loadedPlannedEmployees?.length) {
      setPlannedEmployees([
        ...loadedPlannedEmployees.map(pl => ({ ...pl, id: activityEmployeeUtils.createId(pl) }) satisfies PlannedActivityEmployee),
      ])
    }
  }, [loadedPlannedEmployees])

  async function handleUpdatePlannedEmployees() {
    for (const userToModify of plannedEmployees) {
      if (userToModify.isDeleted) {
        if (!userToModify.srvActivityEmployeeId) continue
        await api.activities.employees.delete(userToModify)
      } else {
        if (
          !!userToModify.planDateFrom !== !!userToModify.planDateTo ||
          (!!userToModify.planDateFrom && !!userToModify.planDateTo && userToModify.planDateFrom >= userToModify.planDateTo)
        ) {
          continue
        }

        // Save on DB UTC time
        userToModify.planDateFrom = imDateUtils.toUTC(userToModify.planDateFrom)
        userToModify.planDateTo = imDateUtils.toUTC(userToModify.planDateTo)

        if (userToModify.srvActivityEmployeeId) {
          // check if anything changed
          const foundPlannedEmployee = loadedPlannedEmployees?.find(pl => activityEmployeeUtils.compare(pl, userToModify))
          if (
            !foundPlannedEmployee ||
            (foundPlannedEmployee.planDateFrom === userToModify.planDateFrom && foundPlannedEmployee.planDateTo === userToModify.planDateTo)
          ) {
            continue
          }
          // update existing entry
          await api.activities.employees.put({
            ...userToModify,
            technicianId: userToModify.technician?.id,
            technicianType: userToModify.technician?.technicianType,
          })
          continue
        }
        if (!activity) continue
        // create new entry
        await api.activities.employees.post({
          ...userToModify,
          ...activityObjUtils.createRequestObject(activity),
          technicianId: userToModify.technician?.id,
          technicianType: userToModify.technician?.technicianType,
        })
      }
    }
  }

  return {
    plannedEmployees,
    setPlannedEmployees,
    loadingPlannedEmployees,
    handleUpdatePlannedEmployees,
  }
}
