import { IM, IMLayout, useLanguage, ViewProps } from '@infominds/react-native-components'
import React, { PropsWithChildren, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Employee } from '../apis/types/apiResponseTypes'
import { EmployeeUtils } from '../utils/EmployeeUtils'
import TimeUtils from '../utils/TimeUtils'
import EmployeeBadge from './EmployeeBadge'

export type EmployeeDateRecordProps = PropsWithChildren<
  {
    employee: Employee | undefined
    date?: string | Date
    info?: string
    reverse?: boolean
  } & Pick<ViewProps, 'spacing' | 'style'>
>

export default function EmployeeDateRecord({ employee, date, info, style, reverse, children, ...viewProps }: EmployeeDateRecordProps) {
  const userName = useMemo(() => EmployeeUtils.getName(employee), [employee])
  const { language } = useLanguage()

  const dateText = useMemo(() => {
    if (!date) return ''
    return TimeUtils.format(date, language)
  }, [date, language])

  return (
    <IM.View style={[styles.employeeView, IMLayout.flex.row, reverse && styles.reverseView, style]} {...viewProps}>
      <IM.View>
        <EmployeeBadge id={employee?.id} name={userName} size={32} />
      </IM.View>
      <IM.View>
        <IM.Text>{userName}</IM.Text>
        {(!!date || !!info) && <IM.Text secondary>{dateText || info}</IM.Text>}
      </IM.View>
      {children}
    </IM.View>
  )
}
const styles = StyleSheet.create({
  employeeView: {
    gap: IMLayout.horizontalMargin,
    alignItems: 'center',
  },
  reverseView: {
    flexDirection: 'row-reverse',
  },
})
