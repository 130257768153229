import { IM } from '@infominds/react-native-components'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { NativeSyntheticEvent, Platform, StyleSheet, TextInputEndEditingEventData, TextInputFocusEventData } from 'react-native'

import { TextInputRef } from '../../types'
import { numberUtils } from '../../utils/NumberUtils'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'
import { DefaultBaseTextInputTitleProps } from './baseTextInput/DefaultBaseTextInputTitle'

export type PriceInputProps = {
  value: number | undefined
  onChangeValue?: (value: number) => void
  unit?: ReactNode
  minValue?: number
  maxValue?: number
  onEndEditing?: (value: number) => void
  internalValueFormatter?: (value: number) => string
} & Pick<BaseTextInputProviderProps, 'error' | 'loading' | 'disableFocus'> &
  Omit<BaseTextInputProps, 'value' | 'onEndEditing'> &
  Pick<DefaultBaseTextInputTitleProps, 'title' | 'details'>

export default function PriceInput({
  value,
  onChangeValue,
  style,
  onEndEditing,
  minValue,
  maxValue,
  editable,
  error,
  loading,
  disableFocus,
  internalValueFormatter,
  title,
  details,
  ...textInputProps
}: PriceInputProps) {
  const [internalValue, setInternalValue] = useState(value?.toString() ?? '')
  const displayedText = useMemo(() => internalValue.replace('.', ','), [internalValue])
  const inputRef = useRef<TextInputRef>(null)

  function formatAndSetInternalValue(newValue: number | undefined) {
    const newValueAsString = internalValueFormatter && newValue !== undefined ? internalValueFormatter(newValue) : newValue?.toString() ?? ''
    setInternalValue(newValueAsString)
  }

  useEffect(() => {
    if (inputRef.current && !inputRef.current.isFocused()) formatAndSetInternalValue(value)
  }, [value])

  function parseText(text: string) {
    const parsedValue = numberUtils.parseFloatFromText(text)
    if (parsedValue === undefined || Number.isNaN(parsedValue)) return null
    return parsedValue
  }

  function handleChangeText(text: string) {
    setInternalValue(text)
    const parsedValue = parseText(text)
    if (parsedValue === null) return
    onChangeValue?.(parsedValue)
  }

  function handleEndEditing(e: NativeSyntheticEvent<TextInputEndEditingEventData | TextInputFocusEventData>) {
    let finalValue = parseText(e.nativeEvent.text) ?? 0
    if (minValue !== undefined) finalValue = Math.max(finalValue, minValue)
    if (maxValue !== undefined) finalValue = Math.min(finalValue, maxValue)
    formatAndSetInternalValue(finalValue)
    onChangeValue?.(finalValue)
    onEndEditing?.(finalValue)
  }

  return (
    <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
      <BaseTextInput
        ref={inputRef}
        value={displayedText}
        {...textInputProps}
        onChangeText={handleChangeText}
        onEndEditing={Platform.OS !== 'web' ? handleEndEditing : undefined}
        onBlur={Platform.OS === 'web' ? handleEndEditing : undefined}
        type={'decimal'}
        textAlign="right"
        textStyle={[styles.text, style]}
        multiline // required to fix issue with scrollview not working on android if textAlign is set to right https://github.com/facebook/react-native/issues/12167
        numberOfLines={1}>
        {title && <BaseTextInput.Title title={title} details={details} required={textInputProps.required} />}

        <BaseTextInput.RightIcon>
          <IM.Text secondary style={styles.marginHorizontal}>
            €
          </IM.Text>
        </BaseTextInput.RightIcon>
      </BaseTextInput>
    </BaseTextInputProvider>
  )
}

const styles = StyleSheet.create({
  iconContainer: { paddingHorizontal: 0 },
  pressable: { borderRadius: 0, flex: 1 },
  marginHorizontal: {
    marginHorizontal: 4,
  },
  text: {
    textAlign: 'right',
  },
})
