import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../../types'
import PressableIcon from '../Infominds/PressableIcon'
import useScreen from './hooks/useScreen'
import useSearch from './hooks/useSearch'
import { DetailHeaderType } from './types'

export default function SearchIcon() {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { search } = useSearch()
  const { detail, setDetailOpen } = useScreen()

  const searchOpen = detail.type === DetailHeaderType.SEARCH && detail.open

  return (
    <IM.View
      style={
        detail.open &&
        searchOpen && {
          backgroundColor: theme.header.detail.background,
          borderRadius: IMLayout.iconRadius,
        }
      }>
      <PressableIcon
        icon={['fal', 'magnifying-glass']}
        size={17}
        color={search !== '' ? theme.general.info : theme.header.main.text.primary}
        onPress={() => setDetailOpen(DetailHeaderType.SEARCH)}
      />
    </IM.View>
  )
}
