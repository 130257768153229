import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'

import { Activity } from '../../apis/types/apiResponseTypes'
import PressableIcon from '../../components/Infominds/PressableIcon'
import DefaultHeader from '../../components/screen/headers/default/DefaultHeader'
import { Screen } from '../../components/screen/Screen'
import useFilter from '../../hooks/useFilter'
import { CommonStackParamList } from '../../navigation/types'
import { ModalScreenProps, ModalScreenRef, ThemeColorExpanded } from '../../types'
import FilterView, { FilterViewProps } from '../../views/common/FilterView'

type Props<T> = ModalScreenProps<CommonStackParamList['Filter']> & FilterViewProps<T>

function FilterScreen<T = object>({ controller, ...viewProps }: Props<T>, ref: ForwardedRef<ModalScreenRef>) {
  useImperativeHandle(ref, () => ({
    handleGoBack: () => handleGoBack(),
  }))

  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { clear } = useFilter<Activity>()

  const handleGoBack = () => {
    controller?.close()
  }

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header goBack={() => handleGoBack()} backHandlerCallback={handleGoBack} isModal={isModal}>
        <DefaultHeader title={i18n.t('FILTERS')} />
        <PressableIcon
          icon={['fal', 'filter-circle-xmark']}
          color={theme.header.main.text.primary}
          onPress={() => clear({ apiFilters: viewProps.disabledFilters })}
          size={19}
        />
        <PressableIcon icon={['fal', 'check']} color={theme.header.main.text.primary} onPress={handleGoBack} size={20} />
      </Screen.Header>
      <Screen.Content>
        <FilterView {...viewProps} />
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(FilterScreen) as <T>(props: Props<T> & { ref: ForwardedRef<ModalScreenRef> }) => ReturnType<typeof FilterScreen>
