import { IM, IMLayout, useDidUpdate, ViewProps } from '@infominds/react-native-components'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { apiDtoIds } from '../../apis/apiCalls'
import { Article } from '../../apis/types/apiResponseTypes'
import { AppStyle } from '../../constants/Styles'
import useArticleSearch from '../../hooks/activity/useArticleSearch'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useLayout from '../../hooks/useLayout'
import useObjectUtils from '../../hooks/useObjectUtils'
import { articleUtils } from '../../utils/ArticleUtils'
import CardNumberInput from './CardNumberInput'

export type ArticleContextSelectionCardProps = {
  article: Article
} & Pick<ViewProps, 'spacing' | 'style'>

function ArticleContextSelectionCard({ article, ...viewProps }: ArticleContextSelectionCardProps) {
  const { theme } = useExtendedTheme()
  const { isSmallDevice } = useLayout()
  const { selectedArticles, setStatus, lastArticlesRequest } = useArticleSearch()
  const articleObjectUtils = useObjectUtils<Article>(apiDtoIds.articles)
  const unit = article.measureUnit?.description
  const disabled = useMemo(() => selectedArticles.current.some(a => articleObjectUtils.compare(a, article) && !!a.refer2DocId), [])

  const { articleId, articleDescription, supplierArticleId } = useMemo(() => {
    if (lastArticlesRequest?.searchtext) {
      const foundSupplyArticle = article.supplyArticleId?.filter(
        q => q !== article.articleId && q.toLowerCase().includes(lastArticlesRequest?.searchtext?.toLowerCase() ?? '')
      )

      if (foundSupplyArticle?.length) {
        return {
          articleId: article.articleId,
          supplierArticleId: foundSupplyArticle.join(', '),
          articleDescription: article.description,
        }
      }
    }

    return { articleId: article.articleId, articleDescription: article.description }
  }, [article])

  // value is saved as separate state to optimize performance due to reduced re-rendering
  const [value, setValue] = useState(() => selectedArticles.current.find(a => articleObjectUtils.compare(a, article))?.quantity)

  const displayValue = value === 0 ? undefined : value

  useDidUpdate(() => {
    setValue(selectedArticles.current.find(a => articleObjectUtils.compare(a, article))?.quantity)
  }, [article])

  function updateSelectedArticles(newValue?: number, increment?: number) {
    setStatus('waiting')
    const foundArticle = selectedArticles.current.find(p => articleObjectUtils.compare(p, article))

    if (foundArticle) {
      if (newValue !== undefined) {
        foundArticle.quantity = newValue
      } else if (increment) {
        if (!foundArticle.quantity) foundArticle.quantity = increment
        else foundArticle.quantity = Math.max(foundArticle.quantity + increment, 0)
      }
      setValue(foundArticle.quantity)
      return
    }
    const newVal = newValue ?? increment
    setValue(newVal)
    selectedArticles.current.push({
      articleId: article.articleId,
      quantity: newVal,
      measureUnitId: article.measureUnit?.measureUnitId,
      measureUnit: article.measureUnit,
      description: article.description,
      actStock: article.actStock ?? 0,
    })
  }
  const availability = useMemo(() => articleUtils.formatAvailability(article, !isSmallDevice), [article, isSmallDevice])

  const Availability = useCallback(
    () =>
      availability ? (
        <IM.View style={[AppStyle.justifyContentCenter]}>
          <IM.TextWithIcon icon={['fal', 'warehouse-full']} secondary numberOfLines={2}>
            {availability}
          </IM.TextWithIcon>
        </IM.View>
      ) : (
        <></>
      ),
    [availability]
  )

  return (
    <IM.View {...viewProps}>
      <IM.CardBasic disabled={disabled} onPress={Platform.OS !== 'web' ? () => updateSelectedArticles(value ? 0 : 1) : undefined}>
        <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentCenter]}>
          <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, styles.content, { borderColor: theme.inputBox.border.active }]}>
            <IM.View style={[IMLayout.flex.f1, AppStyle.justifyContentCenter]} spacing={'left'}>
              <IM.View>
                <IM.Text primary numberOfLines={1}>
                  {articleId}
                </IM.Text>
                {!!supplierArticleId && (
                  <IM.View style={{ marginLeft: IMLayout.horizontalMargin / 2 }}>
                    <IM.TextWithIcon icon={['fal', 'arrow-turn-down-right']}>{supplierArticleId}</IM.TextWithIcon>
                  </IM.View>
                )}
                <IM.Text secondary numberOfLines={2}>
                  {articleDescription}
                </IM.Text>
                {isSmallDevice && <Availability />}
              </IM.View>
            </IM.View>
            {!isSmallDevice && <Availability />}
            {!!unit && (
              <IM.View style={AppStyle.center} spacing={'horizontal'}>
                <IM.Text>{unit}</IM.Text>
              </IM.View>
            )}
          </IM.View>
          <CardNumberInput
            value={displayValue}
            onChangeValue={v => updateSelectedArticles(v)}
            onIncrement={inc => updateSelectedArticles(undefined, inc)}
            minValue={0}
            disabled={disabled}
          />
        </IM.View>
      </IM.CardBasic>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  content: { borderTopLeftRadius: IMLayout.borderRadius, borderBottomLeftRadius: IMLayout.borderRadius, paddingVertical: 6 },
})

export default memo(ArticleContextSelectionCard)
