import { IM, SpacingProps, ViewProps } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { LoadingType } from '../types'
import Error from './Error'
import ListSpacer from './ListSpacer'
import NoEntry from './NoEntry'
import SkeletonCard from './skeleton/SkeletonCard'

export type ListDataViewProps<T> = ViewProps & {
  data: T[] | undefined | null
  noDataMessage: string
  loading?: LoadingType
  renderItem: (item: T, index: number) => ReactNode
  keyExtractor?: (item: T, index: number) => string
  listSpacer?: boolean
  ListHeaderComponent?: React.ReactElement
  ListFooterComponent?: React.ReactElement
  spacingSkeleton?: SpacingProps
  skeletonViewStyle?: StyleProp<ViewStyle>
  numberOfSkeletons?: number
}

export default function ListDataView<T>({
  data,
  noDataMessage,
  loading = false,
  spacingSkeleton,
  listSpacer = true,
  ListHeaderComponent,
  ListFooterComponent,
  renderItem,
  keyExtractor,
  skeletonViewStyle,
  numberOfSkeletons = 3,
  ...otherProps
}: ListDataViewProps<T>) {
  return (
    <IM.View {...otherProps}>
      {!!listSpacer && <ListSpacer />}
      {ListHeaderComponent}

      {loading !== false && (
        <IM.View style={[styles.container]}>
          <>
            {loading === 'reloading' && (
              <IM.View>
                {Array(numberOfSkeletons)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <IM.View spacing={spacingSkeleton ?? ['bottom']} style={skeletonViewStyle} key={index}>
                        <SkeletonCard />
                      </IM.View>
                    )
                  })}
              </IM.View>
            )}
            {loading === 'catched' && <Error />}
          </>
        </IM.View>
      )}

      {loading === false && (
        <>
          {data?.length === 0 && <NoEntry description={noDataMessage} />}
          {!!data?.length &&
            data.map((item, index) => <React.Fragment key={keyExtractor?.(item, index) ?? index}>{renderItem(item, index)}</React.Fragment>)}
        </>
      )}

      {ListFooterComponent}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {},
})
