import { IM, IMLayout, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { Activity } from '../apis/types/apiResponseTypes'
import useExtendedTheme from '../hooks/useExtendedTheme'
import { activityUtils } from '../utils/ActivityUtils'
import Pressable from './Infominds/Pressable'
import TagContainer from './TagContainer'
import Text from './Text'

type Props = {
  activity: Activity
  spacing?: SpacingProps
  onPress?: (start: string) => void
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
  },
  pressable: {
    padding: 0,
  },
  icon: {
    marginRight: 4,
  },
  view: { alignItems: 'center' },
})

export default function ActivityPlanStatusTag({ activity, spacing, onPress }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()
  const closed = activityUtils.isActivityClosed(activity)
  const { planned, start } = activityUtils.isActivityPlanned(activity)
  const showButton = !closed && planned

  return (
    <TagContainer spacing={spacing} style={styles.container} color={showButton ? theme.primary : undefined}>
      <Pressable
        style={styles.pressable}
        disableEffects={showButton === false}
        onPress={() =>
          showButton && start
            ? onPress?.(start)
            : () => {
                return
              }
        }>
        <IM.View style={[IMLayout.flex.row, styles.view]}>
          {showButton && (
            <>
              <IM.Icon color="white" icon={['fal', 'arrow-left']} style={styles.icon} size={14} />
              <IM.Icon color="white" icon={['fas', 'calendar-range']} style={styles.icon} size={14} />
            </>
          )}
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <Text style={{ color: showButton ? 'white' : undefined }}>
            {closed ? i18n.t('ACTIVITY_ENDED') : planned ? i18n.t('ACTIVITY_PLANNED') : i18n.t('ACTIVITY_TO_BE_PLANNED')}
          </Text>
        </IM.View>
      </Pressable>
    </TagContainer>
  )
}
