import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import * as React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

interface Props {
  type?: 'horizontal' | 'vertical'
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const Separator = React.memo(function Separator({ type = 'horizontal', spacing, style }: Props) {
  const { theme } = useTheme()

  return (
    <IM.View
      spacingType="margin"
      spacing={type === 'horizontal' ? spacing ?? 'vertical' : spacing ?? 'horizontal'}
      style={[
        type === 'horizontal' && styles.separatorHorizontal,
        type === 'vertical' && styles.separatorVertical,
        type === 'horizontal' && { borderBottomWidth: StyleSheet.hairlineWidth, borderBottomColor: theme.input.border },
        type === 'vertical' && { borderLeftWidth: StyleSheet.hairlineWidth, borderLeftColor: theme.input.border },
        style,
      ]}
    />
  )
})

export default Separator

const styles = StyleSheet.create({
  separatorVertical: {
    height: '100%',
  },
  separatorHorizontal: {
    width: '100%',
  },
})
