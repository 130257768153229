import { CardProps, IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import CardLeftTitle from '../components/card/left/CardLeftTitle'
import { ThemeColorExpanded } from '../types'

export type SelectorCardProps = {
  value: string
} & Pick<CardProps, 'style' | 'spacing' | 'onPress' | 'selected'>

export default function SelectorCard({ value, selected, ...otherProps }: SelectorCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.Card {...otherProps} head={<CardLeftTitle color={selected && theme.primary} />}>
      <IM.Text primary>{value}</IM.Text>
    </IM.Card>
  )
}
