import { DefaultTheme, IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { Image } from 'react-native'

import useOnLayout from '../hooks/useOnLayout'
import Pressable, { PressableProps } from './Infominds/Pressable'

type Props = {
  signature: string | null
  width?: number
  height: number | undefined
} & Pick<PressableProps, 'onPress' | 'disabled' | 'spacing' | 'style'>

export default function SignatureViewer({ signature, width, height, ...pressableProps }: Props) {
  const { onLayout, layout } = useOnLayout()

  return (
    <Pressable {...pressableProps}>
      <IM.View onLayout={onLayout}>
        {!!signature && (
          <Image
            resizeMode="contain"
            style={{
              width: width ?? layout?.width,
              height: height ?? 300,
              backgroundColor: DefaultTheme.light.background,
              borderRadius: IMLayout.borderRadius,
            }}
            source={{ uri: signature }}
          />
        )}
      </IM.View>
    </Pressable>
  )
}
