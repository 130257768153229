import ImageResizer from '@bam.tech/react-native-image-resizer'
import { DefaultTheme, IM, IMLayout, useDimensions } from '@infominds/react-native-components'
import lodash from 'lodash'
import React, { createRef, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Image, Platform } from 'react-native'
import ReactNativeBlobUtil from 'react-native-blob-util'
import Signature, { SignatureViewRef } from 'react-native-signature-canvas'

import { SignaturePadRef } from '../types'
import { utils } from '../utils/utils'

interface Props {
  signature: string | null
  width?: number
  height: number | undefined
  disabled?: boolean
  onOk: (base64: string) => lodash.NotVoid
  rotateOnSave?: number
}

const SignaturePad = ({ signature, width, height, disabled, onOk, rotateOnSave }: Props, ref: ForwardedRef<SignaturePadRef>) => {
  useImperativeHandle(ref, () => ({
    clearSignature: () => innerRef.current?.clearSignature(),
    readSignatures: () => innerRef.current?.readSignature(),
  }))
  const { isSmallDevice } = useDimensions()

  const [uuid, setUuid] = useState(utils.generateUuid())

  const innerRef = createRef<SignatureViewRef>()

  useEffect(() => {
    setUuid(utils.generateUuid())
  }, [disabled])

  const handleOK = (newSign: string) => {
    if (isSmallDevice) {
      rotateAndSave(newSign)
    } else {
      onOk(newSign)
    }
  }

  const style = `
  .m-signature-pad--footer {
    display: none;
}

.m-signature-pad {
    position: absolute;
    width: ${width ?? 0}px;
    height: ${height ?? 0}px;
    margin-top: 0px;
    margin-left: 0px;
    border: 0px;
    background-color: ${DefaultTheme.light.card.background};
   
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}  

body {
    background-color: ${DefaultTheme.light.card.background};
    font-family: Helvetica, Sans-Serif;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.m-signature-pad--body {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
   
    border: 1px solid ${DefaultTheme.light.card.background};
}
    

  `

  const rotateAndSave = (srcBase64: string) => {
    rotate(srcBase64)
  }

  const rotate = (src: string) => {
    if (rotateOnSave) {
      ImageResizer.createResizedImage(src, width ?? 0, height ?? 0, 'PNG', 100, rotateOnSave)
        .then(response => {
          ReactNativeBlobUtil.fs
            .readFile(Platform.OS === 'android' ? response.uri : response.path, 'base64')
            .then(val => {
              onOk('data:image/png;base64,' + (val as string))
            })
            .catch(err => console.error('Failed reading file', err))
        })
        .catch(err => console.error('Failed rotating file', err))
    } else {
      onOk(src)
    }
  }

  return (
    <>
      {signature ? (
        <Image
          resizeMode="contain"
          style={{ width: width, height: isSmallDevice ? (Platform.OS === 'ios' ? height : (height ?? 0) * 0.4) : height }}
          source={{ uri: signature }}
        />
      ) : (
        <IM.View style={{ height }}>
          {height && (
            <IM.View pointerEvents={disabled ? 'none' : 'auto'} style={IMLayout.flex.f1}>
              <Signature
                key={uuid}
                ref={innerRef}
                onOK={handleOK}
                webStyle={style}
                penColor={DefaultTheme.light.text}
                loadingOverlayStyle={{ backgroundColor: DefaultTheme.light.background }}
              />
            </IM.View>
          )}
        </IM.View>
      )}
    </>
  )
}

export default forwardRef(SignaturePad)
