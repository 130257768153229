import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import useOnLayout from '../../../hooks/useOnLayout'

type Props = {
  activity: Activity
  forceLayout?: ForceLayoutType
}

export default function ActivityCardTypeInfo({ activity, forceLayout, ...props }: Props & ViewProps) {
  const { isSmallDevice } = useLayout(false, forceLayout)
  const { onLayout, layout } = useOnLayout()
  const smallDeviceSeparateLine = useMemo(() => {
    if (!isSmallDevice || !activity.activityTypeDescription || !activity.taskDescription || !layout?.width) return false
    const totalTextLen = activity.activityTypeDescription.length + activity.taskDescription.length
    // assuming text-width per character of 8
    const maxTextLen = layout?.width / 8
    return totalTextLen > maxTextLen
  }, [activity.activityTypeDescription, activity.taskDescription, layout?.width, isSmallDevice])

  return (
    <IM.View {...props} onLayout={onLayout}>
      <IM.View style={[isSmallDevice && !smallDeviceSeparateLine && [IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]]}>
        {activity.activityTypeDescription && <IM.Text numberOfLines={1}>{activity.activityTypeDescription}</IM.Text>}
        {activity.taskDescription && (
          <IM.View style={[isSmallDevice && IMLayout.flex.f1, IMLayout.flex.row]}>
            <IM.View style={[!!activity.activityTypeDescription && isSmallDevice && !smallDeviceSeparateLine && styles.alignEnd, IMLayout.flex.f1]}>
              <IM.Text numberOfLines={Platform.OS === 'web' ? 2 : 1}>{activity.taskDescription}</IM.Text>
            </IM.View>
          </IM.View>
        )}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  alignEnd: {
    alignItems: 'flex-end',
  },
})
