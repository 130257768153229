import { useEvent } from '@infominds/react-native-components'
import { useEffect, useMemo } from 'react'

import { api } from '../../apis/apiCalls'
import { EmailSettings } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { EVENT_KEYS } from '../../constants/EventKeys'

type Options = {
  onLoaded?: (emailSettings: EmailSettings[] | null) => void
}

export default function useEmailSettings(options?: Options) {
  const {
    item: emailSettingsList,
    loadItem: loadEmailSettings,
    loading: loadingEmailSettings,
  } = useControlledLoader(api.email.settings.get, { onLoadedCallback: options?.onLoaded })

  const emailSettings = emailSettingsList?.at(0)

  useEffect(reloadEmailSettings, [])
  useEvent({ key: EVENT_KEYS.UPDATED_EMAIL_MODELS }, () => reloadEmailSettings())
  const emailSettingsValid = useMemo(() => {
    if (emailSettings?.server && emailSettings?.port && emailSettings?.email && emailSettings?.password) return true
    if (loadingEmailSettings === 'init' || loadingEmailSettings === 'reloading') return 'unknown'
    return false
  }, [emailSettings, loadingEmailSettings])

  function reloadEmailSettings() {
    loadEmailSettings({})
  }

  return {
    emailSettings,
    loadingEmailSettings,
    reloadEmailSettings,
    emailSettingsValid,
  }
}
