import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CalendarViewMode } from '@howljs/calendar-kit'
import { IMStyle, PressableIconProps, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import { useRecoilState } from 'recoil'

import useExtendedTheme from '../../hooks/useExtendedTheme'
import useLayout from '../../hooks/useLayout'
import CalendarUtils from '../../utils/calendarUtils'
import { calendarViewAtom } from '../../utils/stateManager'
import PressableIcon from '../Infominds/PressableIcon'
import TabSelector from '../TabSelector'
import { CALENDAR_ICON, LIST_ICON, MAP_ICON } from './headers/ServiceHeader'
import useScreen from './hooks/useScreen'
import { ScreenViewType } from './types'

const smallDeviceCalendarViews: CalendarViewMode[] = ['day', 'threeDays']
const largeDeviceCalendarViews: CalendarViewMode[] = ['workWeek', 'week']

export type HeaderIcon = { type: ScreenViewType; icon: IconProp; size: number }

type HeaderChangeViewIconProps = {
  types: HeaderIcon[]
}

export default function HeaderChangeViewIcon({ types, ...props }: Omit<PressableIconProps, 'icon'> & HeaderChangeViewIconProps) {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()
  const { sessionKey } = useAuthentication()
  const { isLargeDevice } = useLayout(true)
  const isSmallDevice = !isLargeDevice
  const { view, setView } = useScreen()

  const calendarViews = isSmallDevice ? smallDeviceCalendarViews : largeDeviceCalendarViews

  const [calendarViewMode, setCalendarViewMode] = useRecoilState(calendarViewAtom(sessionKey))
  const backgroundColor = theme.header.detail.background
  const dividerColor = theme.header.detail.skeletonBackground

  useEffect(() => {
    Platform.OS === 'web' && view === ScreenViewType.LOCATION && isSmallDevice && setView(ScreenViewType.CALENDAR)
  }, [view, isSmallDevice])

  const currentViewIndex = Math.max(
    types.findIndex(t => t.type === view),
    0
  )
  const currentView = types.find(t => t.type === view) ?? types[0]
  const nextViewIndex = currentViewIndex >= types.length - 1 ? 0 : currentViewIndex + 1

  const currentCalendarView = !calendarViewMode ? 1 : calendarViews.findIndex(t => t === calendarViewMode)
  const nextCalendarViewIndex = currentCalendarView >= calendarViews.length - 1 ? 0 : currentCalendarView + 1

  if (!currentView) return <></>

  return (
    <>
      {currentView.type === ScreenViewType.CALENDAR && (
        <PressableIcon
          color={IMStyle.palette.white}
          icon={CalendarUtils.getIcon(calendarViews[nextCalendarViewIndex])}
          size={currentView.size}
          onPress={() => setCalendarViewMode(calendarViews[nextCalendarViewIndex])}
          {...props}
        />
      )}
      {isSmallDevice && false ? (
        <PressableIcon
          color={IMStyle.palette.white}
          icon={currentView.icon}
          size={currentView.size}
          onPress={() => setView(types[nextViewIndex].type)}
          {...props}
        />
      ) : (
        <TabSelector height={30} width={isSmallDevice ? 200 : 360}>
          <TabSelector.Item
            icon={LIST_ICON.icon}
            iconSize={LIST_ICON.size}
            text={isSmallDevice ? '' : i18n.t('LIST')}
            selected={view === ScreenViewType.LIST}
            textColor="white"
            selectedTextColor={theme.general.info}
            onPress={() => setView(ScreenViewType.LIST)}
            backgroundColor={backgroundColor}
            dividerColor={dividerColor}
            selectionColor={backgroundColor}
          />
          {!(Platform.OS === 'web' && isSmallDevice) && (
            <TabSelector.Item
              icon={MAP_ICON.icon}
              iconSize={MAP_ICON.size}
              text={isSmallDevice ? '' : i18n.t('MAP')}
              selected={view === ScreenViewType.LOCATION}
              onPress={() => setView(ScreenViewType.LOCATION)}
              textColor="white"
              selectedTextColor={theme.general.info}
              backgroundColor={backgroundColor}
              dividerColor={dividerColor}
              selectionColor={backgroundColor}
            />
          )}
          <TabSelector.Item
            icon={CALENDAR_ICON.icon}
            iconSize={CALENDAR_ICON.size}
            text={isSmallDevice ? '' : i18n.t('CALENDAR')}
            selected={view === ScreenViewType.CALENDAR}
            onPress={() => setView(ScreenViewType.CALENDAR)}
            textColor="white"
            selectedTextColor={theme.general.info}
            disableDivider
            backgroundColor={backgroundColor}
            selectionColor={backgroundColor}
          />
        </TabSelector>
      )}
    </>
  )
}
