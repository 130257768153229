import { IM, IMLayout, SpacingProps, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { Dispatch, SetStateAction } from 'react'

import { Employee } from '../../apis/types/apiResponseTypes'
import EmployeeCard from '../../cards/common/EmployeeCard'
import { AppStyle } from '../../constants/Styles'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import ActivityPlanEmployeeModal, { ActivityPlanEmployeeModalProps } from '../../modals/activity/ActivityPlanEmployeeModal'
import { PlannedActivityEmployee, PlannedActivityEmployeeGroup } from '../../types'
import Button from '../Button'
import PlanningRange from '../PlanningRange'
import { UnavailableGroup } from '../selectors/PlanEmployeeSelector'
import Text from '../Text'

interface Props {
  editable?: boolean
  plannedEmployees: PlannedActivityEmployeeGroup
  spacing?: SpacingProps
  disabled?: boolean
  onAdd: () => void
  onChange: (value: PlannedActivityEmployee) => void
  onDelete?: (value: PlannedActivityEmployee) => void
  onDeleteAll?: () => void
  onChangeUnavailable?: Dispatch<SetStateAction<UnavailableGroup[]>>
  estimatedTime?: string
  timeSlotId?: number
}

export default function PlannedEmployeeInput({
  plannedEmployees,
  spacing,
  disabled,
  onChange,
  onDelete,
  onDeleteAll,
  onAdd,
  onChangeUnavailable,
  editable,
  estimatedTime,
  timeSlotId,
}: Props) {
  const { technician: employee, planedActivityEmployees, disabled: disabledEmployee } = plannedEmployees

  const { theme } = useExtendedTheme()
  const { i18n } = useLanguage()
  const editPlanDateModal = useModalController<ActivityPlanEmployeeModalProps>()

  const opacity = disabledEmployee ? 0.5 : 1

  return (
    <IM.View spacing={spacing}>
      <EmployeeCard
        borderless
        employee={employee ?? ({ id: 0, firstName: '', lastName: '' } as Employee)}
        spacing="bottom"
        onDelete={onDeleteAll ? onDeleteAll : undefined}
        disableDelete={disabled}
        titleStyle={{ opacity }}
      />
      <IM.View style={{ opacity }}>
        {planedActivityEmployees?.map((plannedEmployee, index) => (
          <PlanningRange
            key={index}
            technicianId={employee.id}
            technicianType={employee.technicianType}
            planned={plannedEmployee}
            disabled={disabled}
            disabledEmployee={disabledEmployee}
            editable={editable}
            modalController={editPlanDateModal}
            onUnavailable={(id, unavailable) => {
              onChangeUnavailable?.(prev => {
                const found = prev.find(el => el.id === id)

                if (found) {
                  return [...prev.filter(el => el.id === id), { id, unavailable, technicianId: plannedEmployee.technician?.id }]
                } else {
                  return [...prev, { id, unavailable, technicianId: plannedEmployee.technician?.id }]
                }
              })
            }}
          />
        ))}
      </IM.View>
      {disabledEmployee && <Text style={{ color: theme.general.error }}>{i18n.t('TECHNICIAN_DISABLED_TASK_ID')}</Text>}
      {!disabledEmployee && editable && (
        <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd]} spacing={'top'}>
          <Button title={i18n.t('ADD')} onPress={onAdd} color={theme.primary} disabled={disabled} />
        </IM.View>
      )}

      <ActivityPlanEmployeeModal
        controller={editPlanDateModal}
        onChange={onChange}
        onDelete={onDelete}
        estimatedTime={estimatedTime}
        timeSlotId={timeSlotId}
      />
    </IM.View>
  )
}
