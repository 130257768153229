import { IM, IMLayout, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useMemo } from 'react'
import { DnDSource, EventItem, SchedulerData } from 'react-big-scheduler-stch'
import { StyleSheet } from 'react-native'
import useScrollbarSize from 'react-scrollbar-size'
import { useSetRecoilState } from 'recoil'

import { api } from '../../apis/apiCalls'
import { UnavailableType } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import NoEntry from '../../components/NoEntry'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useFilter from '../../hooks/useFilter'
import FilterModal from '../../modals/common/FilterModal'
import { CommonStackParamList } from '../../navigation/types'
import { filterUtils } from '../../utils/FilterUtils'
import { plannerAbsenceFilterCountAtom } from '../../utils/stateManager'
import { PlannerSideViewRef } from './PlannerSideView'

const styles = StyleSheet.create({
  title: {
    marginLeft: IMLayout.horizontalMargin,
    marginRight: IMLayout.horizontalMargin,
    marginTop: IMLayout.horizontalMargin,
    marginBottom: 4,
  },
  absenceContainer: { flexDirection: 'row', flexWrap: 'wrap' },
  filterContainer: { width: 100, alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'center' },
  divider: { marginTop: 6, marginBottom: 0 },
})

interface Props {
  height: number | undefined
  schedulerData: SchedulerData<EventItem> | undefined
  absencesDndSource: DnDSource
  newEvent: (schedulerData: SchedulerData, slotId: string, slotName: string, start: string, end: string, type: string, item: EventItem) => void
}

const AbsencesToBePlannedView = ({ height, schedulerData, absencesDndSource, newEvent }: Props, ref: ForwardedRef<PlannerSideViewRef>) => {
  useImperativeHandle(ref, () => ({
    openFilterModal: () => controller.show(undefined),
  }))

  const { i18n } = useLanguage()
  const { width } = useScrollbarSize()
  const controller = useModalController<CommonStackParamList['Filter']>()

  const setEnableFilter = useSetRecoilState(plannerAbsenceFilterCountAtom)

  useEvent({ key: EVENT_KEYS.GANTT_CHART_TO_PLAN_REFRESH }, () => reload())

  const { item: absences, loadItem: loadAbsences, loading: loadingAbsences } = useControlledLoader(api.activities.unavailables.types.getList)

  const { filters, filteredItems, initFilters } = useFilter<UnavailableType>(absences)
  const activeFilters = useMemo(() => filterUtils.getActiveFilters(filters), [filters])

  const AbsencesDnDRenderItem = absencesDndSource.getDragSource()

  useEffect(() => {
    reload()
  }, [])

  useEffect(() => {
    if (loadingAbsences === false) {
      initFilters(absences ?? [])
    } else {
      setEnableFilter(undefined)
    }
  }, [loadingAbsences])

  useEffect(() => {
    setEnableFilter(activeFilters.length)
  }, [activeFilters])

  const reload = () => loadAbsences()

  return (
    <>
      <IM.View style={[styles.absenceContainer, { height, marginRight: width }]}>
        {height === undefined &&
          filteredItems?.map((absence, index) => {
            return (
              <IM.View key={index}>
                {typeof absence !== 'string' && (
                  <AbsencesDnDRenderItem
                    key={absence.srvUnavailableTypeId}
                    schedulerData={schedulerData}
                    absence={absence}
                    newEvent={newEvent}
                    spacing="all"
                  />
                )}
              </IM.View>
            )
          })}
        {filteredItems?.length === 0 && <NoEntry description={i18n.t('NO_ACTIVITIES_FOUND')} spacing="all" />}
      </IM.View>
      <FilterModal controller={controller} />
    </>
  )
}

export default memo(forwardRef(AbsencesToBePlannedView))
