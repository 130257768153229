import { IM, IMLayout, IMStyle, useLanguage, useModalController, Utils, ViewProps } from '@infominds/react-native-components'
import dayjs from 'dayjs'
import lodash from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo, StyleSheet } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Technician } from '../../apis/types/apiResponseTypes'
import EmployeeCard from '../../cards/common/EmployeeCard'
import { AppStyle } from '../../constants/Styles'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useObjectUtils from '../../hooks/useObjectUtils'
import ScreenSelectModal from '../../modals/ScreenSelectModal'
import { ListSection, LoadingType, PlannedActivityEmployee, PlannedActivityEmployeeGroup } from '../../types'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import { imDateUtils } from '../../utils/infominds/imDateUtils'
import TimeUtils from '../../utils/TimeUtils'
import Button from '../Button'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import Separator from '../Infominds/Separator'
import PlannedEmployeeInput from '../input/PlannedEmployeeInput'
import LoadingStateView from '../LoadingStateView'
import Text from '../Text'

export type UnavailableGroup = { id: string; technicianId: number | undefined; unavailable: boolean }

type Props = {
  editable?: boolean
  selectedEmployees: PlannedActivityEmployee[] | undefined
  onChange: (values: PlannedActivityEmployee[]) => void
  onTechnicianDisabled?: (technician: Technician, disabled: boolean) => void
  onUnavailable?: (unavailable: boolean) => void
  loadingPlannedEmployees?: LoadingType
  taskId: number | undefined
  estimatedTime?: string
  timeSlotId?: number
} & Pick<ViewProps, 'spacing'>

export default function PlanEmployeeSelector({
  onChange,
  onTechnicianDisabled,
  onUnavailable,
  selectedEmployees,
  editable,
  spacing,
  loadingPlannedEmployees,
  taskId,
  estimatedTime,
  timeSlotId,
}: Props) {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()
  const [search, setSearch] = useState('')
  const objectUtils = useObjectUtils<Technician>(apiDtoIds.employees)
  const { item: data, loadItem, loading } = useControlledLoader(api.technicians.getList)
  const selectModal = useModalController()

  const enabledData = useMemo(() => data?.filter(t => !t.disabled) ?? [], [data])
  const sortedData = useMemo(() => enabledData?.sort(EmployeeUtils.sortTechnicians) ?? [], [enabledData])
  const filteredData = useDataSearchFilter(enabledData, search, ['firstName', 'lastName', 'id'])

  const [unavailable, setUnavailable] = useState<UnavailableGroup[]>([])

  const groupedEmployees = useMemo<PlannedActivityEmployeeGroup[]>(() => {
    if (data && loading === false) {
      const filteredEmployees = selectedEmployees?.filter(se => !se.isDeleted) ?? []

      return Utils.keepUniques(filteredEmployees ?? [], e => `${e.technician?.id ?? ''}#${e.technicianType ?? ''}`)
        .map<PlannedActivityEmployeeGroup>(e => {
          const technicianDisabled =
            data?.find(technician => technician.id === e.technician?.id && technician.technicianType === e.technician.technicianType) === undefined

          onTechnicianDisabled?.(e.technician as Technician, technicianDisabled)
          return {
            disabled: technicianDisabled,
            technician: e.technician as Technician,
            planedActivityEmployees: sortActivityEmployeesByPlanDate(filteredEmployees?.filter(se => se.technician?.id === e.technician?.id) ?? []),
          }
        })
        .sort((a, b) => EmployeeUtils.sortTechnicians(a.technician, b.technician))
    }

    return []
  }, [selectedEmployees, data, loading])

  // on change of estimatedTime recalculate planDateTo of employees
  useEffect(() => {
    if (!selectedEmployees || !estimatedTime || TimeUtils.timeSpanToMinutes(estimatedTime) <= 0) return

    const newValue = [...selectedEmployees]
    newValue.forEach(employee => {
      if (!employee.planDateFrom || employee.isDeleted) return
      const [h, m] = estimatedTime.split(':')
      const newEnd = imDateUtils.toUTC(dayjs(employee.planDateFrom).add(parseInt(h, 10), 'h').add(parseInt(m, 10), 'm'))
      employee.planDateTo = newEnd
    })
    onChange(newValue)
  }, [estimatedTime])

  useEffect(() => {
    refresh()
  }, [taskId])

  useEffect(() => {
    const foundUnavailable = unavailable.find(el => el.unavailable)

    if (foundUnavailable) {
      onUnavailable?.(true)
    } else {
      onUnavailable?.(false)
    }
  }, [unavailable])

  const refresh = () => loadItem({ taskId })

  function handleEmployeeSelected(value: Technician | undefined) {
    if (!value) return
    const foundEmployee = selectedEmployees?.find(se => objectUtils.compare(se.technician, value) && !se.isDeleted)
    if (foundEmployee) {
      if (foundEmployee.srvActivityId) {
        onChange(selectedEmployees?.map(se => (objectUtils.compare(se.technician, value) ? { ...se, isDeleted: true } : se)) ?? [])
      } else {
        onChange(selectedEmployees?.filter(se => !objectUtils.compare(se.technician, value)) ?? [])
      }
      return
    }

    const notDeletedEmployees = selectedEmployees?.filter(el => el.isDeleted === undefined)
    if (notDeletedEmployees?.length !== 0) {
      const grouped = lodash.groupBy(
        notDeletedEmployees?.filter(el => el.isDeleted === undefined),
        el => el.technician?.id
      )
      const dates: Pick<PlannedActivityEmployee, 'planDateFrom' | 'planDateTo' | 'technician' | 'id'>[] = []

      Object.keys(grouped).forEach((key, index) => {
        if (index !== 0) return // We only takes the first employee we found
        grouped[key].forEach(el => dates.push({ planDateFrom: el.planDateFrom, planDateTo: el.planDateTo, technician: value, id: Utils.getUid() }))
      })

      onChange([...(selectedEmployees ?? []), ...dates])
    } else {
      onChange([
        ...(selectedEmployees ?? []),
        {
          technician: value,
          id: Utils.getUid(),
        },
      ])
    }
  }

  function handleEmployeeRemovePlanEntry(value: PlannedActivityEmployee) {
    if (!value) return
    // if edit mode set isDeleted = true
    setUnavailable(prev => prev.filter(el => el.id !== value.id))
    onChange(selectedEmployees?.map<PlannedActivityEmployee>(se => (se.id !== value.id ? se : { ...se, isDeleted: true })) ?? [])
  }

  function handleEmployeeDelete(value: PlannedActivityEmployeeGroup) {
    setUnavailable(prev => prev.filter(el => el.technicianId !== value.technician.id))
    handleEmployeeSelected(value.technician)
  }

  function handleAddEmployeePlanDate(value: PlannedActivityEmployeeGroup) {
    if (!value) return
    onChange([
      ...(selectedEmployees ?? []),
      {
        technician: value.technician,
        id: Utils.getUid(),
      },
    ])
  }

  function handleEmployeePlanDateChanged(value: PlannedActivityEmployee) {
    if (!value) return
    onChange(
      selectedEmployees?.map<PlannedActivityEmployee>(se => {
        if (se.id !== value.id) return se
        return {
          ...se,
          planDateFrom: value.planDateFrom,
          planDateTo: value.planDateTo,
        }
      }) ?? []
    )
  }

  const render = ({ item, index }: SectionListRenderItemInfo<Technician, ListSection<Technician>>, onPress?: () => void) => {
    return (
      <>
        {item.technicianType === 'supplier' && (index === 0 || sortedData[index - 1].technicianType === 'employee') && (
          <IM.View spacing={['horizontal', 'bottom']}>
            <IM.Text primary>{i18n.t('SUPPLIER')}</IM.Text>
          </IM.View>
        )}
        <EmployeeCard
          employee={item}
          selected={!!selectedEmployees?.find(selected => objectUtils.compare(item, selected.technician) && !selected.isDeleted)}
          onPress={onPress}
          spacing={['horizontal', 'bottom']}
        />
      </>
    )
  }

  const disabled = taskId === undefined
  const opacity = disabled ? 0.5 : 1
  return (
    <>
      {disabled && (
        <IM.View spacing={'bottom'}>
          <Text secondary style={styles.disabledText}>
            {i18n.t('SELECT_TASK_ID')}
          </Text>
        </IM.View>
      )}
      <IM.View
        spacing={spacing}
        style={{
          opacity,
          backgroundColor: theme.backgroundSecondary,
          marginHorizontal: -IMLayout.horizontalMargin * 2,
          paddingHorizontal: IMLayout.horizontalMargin * 2,
          paddingTop: IMLayout.verticalMargin * 2,
          paddingBottom: IMLayout.verticalMargin,
        }}>
        <ScreenSelectModal
          data={filteredData}
          isVisible={selectModal.isShown}
          setIsVisible={value => (value ? selectModal.show() : selectModal.close())}
          showNoSelectionItem={false}
          onSearchChange={setSearch}
          loading={loading}
          screenTitle=""
          renderItem={render}
          onChange={handleEmployeeSelected}
          noDataMessage={i18n.t('NO_EMPLOYEE_FOUND')}
          onClose={selectModal.close}
          keepOpenOnChange
        />
        <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
          <IM.View style={[IMLayout.flex.f1, AppStyle.justifyContentCenter]}>
            <IM.Text primary style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>
              {i18n.t('PLANNING')}
            </IM.Text>
          </IM.View>
          {!!editable && (
            <Button
              title={i18n.t(groupedEmployees.length ? 'MANAGE_TECHNICIANS' : 'ADD_TECHNICIANS')}
              onPress={() => {
                selectModal.show()
              }}
              color={theme.primary}
              disabled={disabled || loadingPlannedEmployees === 'reloading'}
            />
          )}
        </IM.View>
        {loadingPlannedEmployees === 'reloading' ? (
          <LoadingStateView loading spacing="top" />
        ) : (
          <IM.View spacing="top">
            {groupedEmployees?.map((employee, index) => (
              <IM.View key={employee.technician.id ?? index.toString()}>
                <PlannedEmployeeInput
                  plannedEmployees={employee}
                  spacing="bottom"
                  onDelete={editable ? handleEmployeeRemovePlanEntry : undefined}
                  onDeleteAll={editable ? () => handleEmployeeDelete(employee) : undefined}
                  onChange={handleEmployeePlanDateChanged}
                  onAdd={() => handleAddEmployeePlanDate(employee)}
                  onChangeUnavailable={setUnavailable}
                  editable={editable}
                  disabled={disabled}
                  estimatedTime={estimatedTime}
                  timeSlotId={timeSlotId}
                />
                {groupedEmployees && index < groupedEmployees.length - 1 && <Separator />}
              </IM.View>
            ))}
          </IM.View>
        )}
      </IM.View>
    </>
  )
}

function sortActivityEmployeesByPlanDate(employees: PlannedActivityEmployee[]) {
  return TimeUtils.sortDate(employees, 'planDateFrom', 'asc').sort((a, b) => (!!a.planDateFrom === !!b.planDateFrom ? 0 : a.planDateFrom ? -1 : 1))
}

const styles = StyleSheet.create({
  disabledText: { fontStyle: 'italic' },
})
