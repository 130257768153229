import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import { ArticlePriceListNode } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import PressableIcon from '../../components/Infominds/PressableIcon'
import CONSTANTS from '../../constants/Constants'
import { AppStyle } from '../../constants/Styles'
import useExtendedTheme from '../../hooks/useExtendedTheme'

export type ArticleCategoryCardProps = {
  node: ArticlePriceListNode
  isOpen: boolean
  isSelected: boolean
  showIcon: boolean
  onCardPressed: () => void
} & Pick<ViewProps, 'spacing' | 'style'>

const CardMinHeight = 50
function ArticleCategoryCard({ node, isOpen, onCardPressed, showIcon, isSelected, ...viewProps }: ArticleCategoryCardProps) {
  const { theme } = useExtendedTheme()

  return (
    <IM.View {...viewProps}>
      <IM.Card
        head={showIcon || !isSelected ? <></> : <CardLeftTitle color={isSelected ? theme.general.info : theme.card.background} />}
        onPress={onCardPressed}
        noContentSpacing>
        <IM.View style={[IMLayout.flex.row, styles.contentView]}>
          {showIcon && <PressableIcon size={16} color={theme.textPlaceholder} icon={isOpen ? ['fal', 'chevron-down'] : ['fal', 'chevron-right']} />}
          <IM.View
            style={[
              AppStyle.justifyContentCenter,
              !showIcon && { marginLeft: IMLayout.horizontalMargin + (!isSelected ? CONSTANTS.cardLeftWidth : 0) },
            ]}>
            <IM.Text>{node.description}</IM.Text>
          </IM.View>
        </IM.View>
      </IM.Card>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  cardSelected: {
    borderWidth: 1,
  },
  contentView: {
    alignItems: 'center',
    minHeight: CardMinHeight,
  },
  content: { borderWidth: 1, borderRightWidth: 0, borderTopLeftRadius: IMLayout.borderRadius, borderBottomLeftRadius: IMLayout.borderRadius },
})

export default memo(ArticleCategoryCard)
