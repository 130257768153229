import { CardButton, IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import type { Customer } from '../../apis/types/apiResponseTypes'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import useClipboard from '../../hooks/useClipboard'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'
import LoaderCard, { LoadedCardProps } from '../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData' | 'buttons'> & { customer: Customer | undefined | null; disableIcon?: boolean }

const CustomerDetailCard = memo(function CustomerDetailCard({ customer, disableIcon, hideButtons, loading = false, ...props }: Props) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const hasData = customer?.companyId
  const { copyToClipboard } = useClipboard()

  const inactive = false
  const phone = customer?.phone
  const phone2 = customer?.phone2
  const email = customer?.email
  const buttons = useMemo<CardButton[]>(() => {
    const result: CardButton[] = []
    if (phone) {
      result.push({
        icon: ['fal', 'phone'],
        ...appUtils.getCardButtonColor(!inactive, theme),
        overrideDisabled: true,
        onPress: inactive ? undefined : () => handlePhonePress(phone),
        onLongPress: inactive ? undefined : () => copyToClipboard(phone),
      })
    }

    if (phone2) {
      result.push({
        icon: phone ? ['fal', 'mobile'] : ['fal', 'phone'],
        ...appUtils.getCardButtonColor(!inactive, theme),
        overrideDisabled: true,
        onPress: inactive ? undefined : () => handlePhonePress(phone2),
        onLongPress: inactive ? undefined : () => copyToClipboard(phone2),
      })
    }

    if (email) {
      result.push({
        icon: ['fal', 'envelope'],
        ...appUtils.getCardButtonColor(!inactive, theme),
        overrideDisabled: true,
        onPress: inactive ? undefined : handleEmailPress,
        onLongPress: inactive ? undefined : () => copyToClipboard(email),
      })
    }

    return result
  }, [phone, phone2, email, inactive, theme])

  function handlePhonePress(number: string) {
    if (number) appUtils.openPhone(number, alert, i18n.t('OPEN_PHONE_ERROR'))
  }

  function handleEmailPress() {
    if (email) appUtils.openEmail(alert, i18n.t('OPEN_EMAIL_ERROR'), email)
  }

  const address = React.useMemo(() => customerUtils.formatAddress(customer?.address, 'location'), [customer?.address])

  return (
    <LoaderCard
      noDataText={i18n.t('NO_CUSTOMER_SELECTED')}
      hasData={!!customer?.companyId}
      loading={loading}
      buttons={{ buttons: hideButtons || !hasData || loading !== false ? [] : buttons }}
      {...props}>
      <IM.View spacing={'bottom'}>
        <TextWithIcon
          secondary={inactive}
          icon={disableIcon ? undefined : ['fal', 'building']}
          iconSize={20}
          style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }]}>
          {customer?.company1}
        </TextWithIcon>
      </IM.View>
      {address && (
        <IM.Text numberOfLines={1} secondary>
          {address}
        </IM.Text>
      )}
      {(phone || phone2 || email) && (
        <IM.View style={[IMLayout.flex.f1, disableIcon === false && styles.margin]}>
          {phone && <IM.Text secondary={inactive}>{phone}</IM.Text>}
          {phone2 && <IM.Text secondary={inactive}>{phone2}</IM.Text>}
          {email && <IM.Text secondary={inactive}>{email}</IM.Text>}
        </IM.View>
      )}
    </LoaderCard>
  )
})

export default CustomerDetailCard

const styles = StyleSheet.create({
  margin: { margin: 4 },
})
