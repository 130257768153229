import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { ActivityArticle } from '../../apis/types/apiResponseTypes'
import ArticleSelectionCard from '../../cards/articles/ArticleSelectionCard'
import { AppStyle } from '../../constants/Styles'
import useErgoTask from '../../hooks/useErgoTask'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useIsRdaEnabled from '../../hooks/useIsRdaEnabled'
import ActivityArticleSelectionModal, { ActivityArticleSelectionModalProps } from '../../modals/activity/ActivityArticleSelectionModal'
import Button from '../Button'
import GroupSpacer from '../GroupSpacer'
import Warning from '../Warning'

interface Props {
  title?: string
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: ActivityArticle[]) => void
  required?: boolean
  selectedArticles?: ActivityArticle[]
  taskId?: number
  customerId?: number
  constructionSiteId?: number
  lottoId?: number
}

export default function MultiActivityArticleSelector({ title, onChange, selectedArticles, taskId, editable }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useExtendedTheme()
  const articleSearchModal = useModalController<ActivityArticleSelectionModalProps>()

  const { ergoTask } = useErgoTask(taskId)

  const rdaEnabled = useIsRdaEnabled(ergoTask)
  const filteredArticles = selectedArticles?.filter(sa => !!sa.quantity)
  const showInfo = useMemo(() => selectedArticles?.some(s => !!s.isRda && !!s.quantity), [selectedArticles])

  const opacity = taskId ? 1 : 0.5

  function handleModalSave(articles: ActivityArticle[]) {
    onChange(articles.map(a => ({ ...a, isRda: a.isRda ?? rdaEnabled })))
  }

  return (
    <IM.View
      style={{
        opacity,
        backgroundColor: theme.backgroundSecondary,
        marginHorizontal: -IMLayout.horizontalMargin * 2,
        paddingHorizontal: IMLayout.horizontalMargin * 2,
        paddingTop: IMLayout.verticalMargin * 2,
        paddingBottom: IMLayout.verticalMargin,
      }}>
      <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
        <IM.View style={[IMLayout.flex.f1, AppStyle.justifyContentCenter]}>
          <IM.Text primary style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>
            {title ?? i18n.t('ARTICLES')}
          </IM.Text>
        </IM.View>
        {!!editable && (
          <Button
            title={i18n.t('ADD')}
            onPress={() => {
              articleSearchModal.show({ ergoTask, selectedArticles })
            }}
            color={theme.primary}
            disabled={!taskId}
          />
        )}
      </IM.View>
      {showInfo && <Warning message={i18n.t('RDA_CREATE_INFO')} spacing={'top'} />}
      <IM.View>
        {filteredArticles?.map(article => (
          <ArticleSelectionCard
            key={article.articleId}
            article={article}
            spacing={'top'}
            ergoTask={ergoTask}
            onValueChanged={changedArticle => {
              onChange(
                selectedArticles
                  ?.map(sa => {
                    if (sa.articleId !== changedArticle?.articleId) return sa
                    return changedArticle
                  })
                  .filter(a => !!a) as ActivityArticle[]
              )
            }}
          />
        ))}
      </IM.View>
      <GroupSpacer />
      <ActivityArticleSelectionModal controller={articleSearchModal} onSave={handleModalSave} />
    </IM.View>
  )
}
