import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import CustomerContactCard from '../../cards/customer/CustomerContactCard'
import { ContactFilterConfig } from '../../constants/Filters'
import useObjectUtils from '../../hooks/useObjectUtils'
import { ListSection } from '../../types'
import customerUtils from '../../utils/customerUtils'
import { filterUtils } from '../../utils/FilterUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Contact | undefined) => void
  disableFastInput?: boolean
  objectId: number
  value?: number
} & Partial<Pick<SelectInputProps<Contact>, 'title' | 'disabledInfo' | 'subTitle' | 'noDataMessage'>>

export default function ConstructionSiteContactSelector({ objectId, onChange, disableFastInput, value, noDataMessage, title, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const objectUtils = useObjectUtils<Contact>(apiDtoIds.contacts)
  const { item: allData, loadItem, loading } = useControlledLoader(api.constructionsSites.contacts.getList)
  const data = useMemo(() => filterUtils.filterItemsBySearch(allData, search, ContactFilterConfig.searchKeys), [allData, search])
  const selected = useMemo(() => allData?.find(c => c.objectId === objectId && c.contactId === value), [allData, value])

  useEffect(() => {
    refresh()
  }, [objectId])

  const refresh = () => loadItem({ objectId })

  const render = ({ item }: SectionListRenderItemInfo<Contact, ListSection<Contact>>, onPress?: () => void) => {
    return (
      <CustomerContactCard
        contact={item}
        selected={objectUtils.compare(item, selected)}
        onPress={onPress}
        hideButtons
        spacing={['horizontal', 'bottom']}
      />
    )
  }

  const handleOnChange = (newValue: Contact | undefined) => {
    onChange(newValue)
  }

  return (
    <SelectInput
      id={apiDtoIds.contacts}
      data={data}
      value={selected ?? undefined}
      loading={objectId ? loading : false}
      refresh={objectId ? refresh : undefined}
      onSearchChange={setSearch}
      title={title ?? i18n.t('CONSTRUCTION_SITE_CONTACT')}
      screenTitle={i18n.t('CONTACTS')}
      noDataMessage={noDataMessage ?? i18n.t('NO_CONTACT_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => customerUtils.getContactInfo(item).contactText ?? ''}
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      {...props}
    />
  )
}
