import { IM, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Technician } from '../../apis/types/apiResponseTypes'
import EmployeeCard from '../../cards/common/EmployeeCard'
import { TechniciansFilterConfig } from '../../constants/Filters'
import useObjectUtils from '../../hooks/useObjectUtils'
import { ListSection } from '../../types'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import { filterUtils } from '../../utils/FilterUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  spacing?: SpacingProps
  value?: number
  filter?: (technician: Technician) => boolean
  noTitle?: boolean
  taskId?: number
} & Pick<
  SelectInputProps<Technician>,
  'disableBorderRadius' | 'required' | 'hideNoSelectionItem' | 'disableFastInput' | 'editable' | 'onChange' | 'placeholder'
>

export default function TechnicianSelector({ onChange, disableFastInput, value, filter, noTitle, taskId, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<Technician | undefined>(undefined)
  const objectUtils = useObjectUtils<Technician>(apiDtoIds.employees)
  const { item: allData, loadItem, loading } = useControlledLoader(api.technicians.getList)
  const data = useMemo(() => (filter ? allData?.filter(filter) : allData), [allData, filter])
  const filteredData = useMemo(() => filterUtils.filterItemsBySearch(data, search, TechniciansFilterConfig.searchKeys), [data, search])
  const sortedData = useMemo(() => filteredData?.sort(EmployeeUtils.sortTechnicians) ?? [], [filteredData])

  useEffect(() => {
    refresh()
  }, [taskId])

  const refresh = () => loadItem({ taskId })

  useEffect(() => {
    if (!value) {
      setSelected(undefined)
      return
    }
    const foundItem = data?.find(d => d.id === value)
    setSelected(foundItem)
  }, [data, value])

  const render = ({ item, index }: SectionListRenderItemInfo<Technician, ListSection<Technician>>, onPress?: () => void) => {
    return (
      <>
        {item.technicianType === 'supplier' && (index === 0 || sortedData[index - 1].technicianType === 'employee') && (
          <IM.View spacing={['horizontal', 'bottom']}>
            <IM.Text primary>{i18n.t('SUPPLIER')}</IM.Text>
          </IM.View>
        )}
        <EmployeeCard employee={item} selected={objectUtils.compare(item, selected)} onPress={onPress} spacing={['horizontal', 'bottom']} />
      </>
    )
  }

  const handleOnChange = (newValue: Technician | undefined) => {
    setSelected(newValue)
    onChange(newValue)
  }

  return (
    <SelectInput
      id={apiDtoIds.employees}
      data={sortedData ?? []}
      value={selected}
      loading={loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={noTitle ? undefined : i18n.t('EMPLOYEE')}
      screenTitle={i18n.t('EMPLOYEES')}
      noDataMessage={i18n.t('NO_EMPLOYEE_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => EmployeeUtils.getName(item)}
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      {...props}
    />
  )
}
