import { Storage, useDidUpdate } from '@infominds/react-native-components'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { STORAGE_KEYS } from '../constants/Keys'
import { ApiFilterConfig, ApiFilterDataSorter, FilterStorageType } from '../types'
import { filterUtils } from '../utils/FilterUtils'
import useUserSettings from './useUserSettings'

export default function useDataApiFilterState<T>(
  storageKeyUniqueId: string,
  config?: ApiFilterConfig<T>
): [ApiFilterDataSorter<T>[], Dispatch<SetStateAction<ApiFilterDataSorter<T>[]>>, boolean] {
  const { user } = useUserSettings()
  const [state, setState] = useState<ApiFilterDataSorter<T>[]>(filterUtils.initDataApiFilter(config, user.role))
  const [initOk, setInitOk] = useState(false)
  const storage = Storage<FilterStorageType[]>(STORAGE_KEYS.FILTER_CONTEXT_GROUP + (config?.type ?? '') + storageKeyUniqueId)

  useEffect(() => {
    storage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const initFilters = filterUtils.initDataApiFilter(config, user?.role)
          const populatedFilter: ApiFilterDataSorter<T>[] = []
          initFilters.forEach(initFilter => {
            const foundLoadedFilter = loaded.find(el => el.filterId === initFilter.id)
            if (foundLoadedFilter) {
              const value = foundLoadedFilter.values.pop()
              try {
                const parsedValue =
                  typeof value?.value === 'number' || typeof value?.value === 'boolean' || !value?.value || !value.value.trim().startsWith('[')
                    ? value?.value
                    : (JSON.parse(value?.value) as string[] | number[])
                populatedFilter.push({
                  ...initFilter,
                  active: !!parsedValue && !!initFilter.visible,
                  value: Array.isArray(parsedValue) ? undefined : parsedValue,
                  values: Array.isArray(parsedValue) ? parsedValue : undefined,
                  text: value?.text ?? '',
                  group: initFilter.group,
                })
              } catch (e) {
                console.error('Failed to parse api-filter', value, e)
              }
            } else {
              populatedFilter.push(initFilter)
            }
          })
          setState(populatedFilter)
        }
      })
      .catch(err => console.error('Failed loading ticket group by', err))
      .finally(() => setInitOk(true))
  }, [user])

  useDidUpdate(() => {
    const storageFilters: FilterStorageType[] = []
    state.forEach(filter => {
      if (
        (filter.value === null || filter.value === undefined || filter.value === '') &&
        (filter.values === null || filter.values === undefined || filter.values.length === 0)
      ) {
        return
      }
      const valueToSave = filter.value ?? JSON.stringify(filter.values)
      storageFilters.push({ filterId: filter.id, values: [{ id: filter.id, value: valueToSave, text: filter.text, isMax: false }] })
    })
    storage.save(storageFilters).catch(err => console.error('Failed saving dataFilterState ' + (config?.type ?? ''), err))
  }, [state])

  return [state, setState, initOk]
}
