import { DateUtils, Language } from '@infominds/react-native-components'
import { addMonths, endOfDay, format, formatDuration, intervalToDuration, startOfDay } from 'date-fns'
import { de, enGB, it } from 'date-fns/locale'

import { TimeFormat, TimeOverflowHandling } from '../types'

const timeElem = ['m', 'h']
const TimeUtils = {
  languageToLocale(language: Language): Locale {
    switch (language) {
      case 'de':
        return de
      case 'it':
        return it
      case 'en':
        return enGB
      default:
        return enGB
    }
  },
  format(data: string | Date, language: Language, formatString?: string) {
    return format(new Date(data), formatString ?? 'P', { locale: TimeUtils.languageToLocale(language) })
  },
  formatTime(time: Date, seconds?: boolean) {
    if (!time) return ''
    return DateUtils.formatDate(time, seconds ? 'HH:mm:ss' : 'HH:mm')
  },
  formatDateTime(input: Date | string, language: Language, showSeconds?: boolean) {
    if (!input) return ''
    const date = DateUtils.dateify(input)
    return `${this.format(date, language)} ${format(date, showSeconds ? 'HH:mm:ss' : 'HH:mm')}`
  },
  parseTimeSpan(timeStamp: string) {
    const [time, milliseconds] = timeStamp.split('.')
    const [hours, minutes, seconds] = time.split(':')
    return {
      hours: Number.parseInt(hours, 10),
      minutes: Number.parseInt(minutes, 10),
      seconds: Number.parseInt(seconds, 10),
      milliseconds: Number.parseInt(milliseconds, 10),
    }
  },
  formatTimeStamp(timeStamp: string | undefined, showSeconds?: boolean) {
    if (!timeStamp) return undefined
    const { hours, minutes, seconds } = this.parseTimeSpan(timeStamp)
    if (hours === undefined || minutes === undefined) return undefined
    const result: string[] = []
    result.push(hours.toString().padStart(2, '0'))
    result.push(minutes.toString().padStart(2, '0'))
    if (showSeconds) result.push(seconds.toString().padStart(2, '0'))
    return result.join(':')
  },
  formateTimeStampFromTo(from: string, to: string) {
    return `${TimeUtils.formatTimeStamp(from) ?? '00:00'} - ${TimeUtils.formatTimeStamp(to) ?? '00:00'}`
  },
  getMinutesOfDay(date: Date) {
    return date.getMinutes() + date.getHours() * 60
  },
  getSecondsOfDay(date: Date) {
    return date.getSeconds() + date.getMinutes() * 60 + date.getHours() * 3600
  },
  getCurrentMinutes() {
    return this.getMinutesOfDay(new Date())
  },
  getCurrentSeconds() {
    return this.getSecondsOfDay(new Date())
  },
  revertMinutesToDate(minutes: number, useDate?: Date) {
    const date = useDate ?? new Date()
    date.setHours(Math.floor(minutes / 60))
    date.setMinutes(minutes % 60)
    date.setSeconds(0)
    return date
  },
  revertSecondsToDate(seconds: number, useDate?: Date) {
    const date = useDate ?? new Date()
    date.setHours(Math.floor(seconds / 3600))
    const minutes = seconds % 3600
    date.setMinutes(Math.floor(minutes / 60))
    date.setSeconds(minutes % 60)
    return date
  },
  formatMinutes(minutes: number, useDate?: Date) {
    return this.formatTime(this.revertMinutesToDate(minutes, useDate))
  },
  formatSeconds(seconds: number, useDate?: Date, hideSeconds?: boolean) {
    return this.formatTime(this.revertSecondsToDate(seconds, useDate), !hideSeconds)
  },
  subtractMinutes(from: number | undefined, until: number | undefined) {
    if (!Number.isInteger(from) || !Number.isInteger(until)) return 0
    //@ts-ignore from and until are checked by Number.isInteger
    if (from > until) return 24 * 60 - (from - until)
    //@ts-ignore from and until are checked by Number.isInteger
    return until - from
  },
  subtractSeconds(from: number | undefined, until: number | undefined) {
    if (!Number.isInteger(from) || !Number.isInteger(until)) return 0
    //@ts-ignore from and until are checked by Number.isInteger
    if (from > until) return 24 * 3600 - (from - until)
    //@ts-ignore from and until are checked by Number.isInteger
    return until - from
  },
  roundSecondsToMinutes(seconds: number) {
    if (!seconds) return 0
    return Math.round(seconds / 60)
  },
  /**
   * @returns input seconds rounded to whole minutes. f.ex 63 will return 60
   */
  roundSecondsToWholeMinute(seconds: number) {
    return this.roundSecondsToMinutes(seconds) * 60
  },
  resetTimeOfISOString(date: string, seconds?: number) {
    return date.split('T')[0] + `T${this.secondsToTime(seconds ?? 0, TimeFormat.TIME, true)}:00.000Z`
  },
  isoStringToSeconds(date: string) {
    const splitted = date.split('T')[1].split(':')

    if (splitted.length > 1) {
      return parseInt(splitted[1], 10) * 60 + parseInt(splitted[0], 10) * 60 * 60
    }

    return 0
  },
  secondsToTime(seconds: number, outputFormat = TimeFormat.COUNTER, disableRadixRounding = false) {
    if (seconds > 24 * 60 * 60) {
      return 'NaN'
    }

    if (outputFormat === TimeFormat.TIME_WITH_DIMENSIONS && seconds < 60) {
      return '< 1m'
    }

    //To Display same time as radix the seconds have to be rounded to whole minutes
    if (outputFormat === TimeFormat.TIME) {
      if (!disableRadixRounding) {
        seconds = this.roundSecondsToWholeMinute(seconds)
      }

      if (seconds === 0) {
        return '00:00'
      }
    }

    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

    const zeroPad = (num: number | undefined) => String(num).padStart(2, '0')

    const formats: string[] = []

    if (duration.hours !== 0) {
      formats.push('hours')
      formats.push('minutes')
    } else {
      if (duration.minutes !== 0) {
        formats.push('minutes')
      }
    }

    const formatted = formatDuration(duration, {
      format: formats,
      zero: true,
      delimiter: ':',
      locale: {
        formatDistance: (_test, count: number, ..._pros) => zeroPad(count),
      },
    })

    if (outputFormat === TimeFormat.TIME_WITH_DIMENSIONS) {
      return formatted
        .split(':')
        .map(elem => {
          return elem.replace(/0(\d+)/, '$1')
        })
        .reverse()
        .reduce((acc, elem, index) => {
          return `${elem}${timeElem[index] ?? ''}${acc === '' ? '' : ' '}` + acc
        }, '')
    }

    if (formatted.indexOf('0') === 0 && outputFormat === TimeFormat.COUNTER) {
      return formatted.replace('0', '')
    }

    if (outputFormat === TimeFormat.TIME) {
      if (format.length === 0) {
        return `00:00`
      } else if (formatted.length === 2) {
        return `00:${formatted}`
      } else if (formatted.length === 3) {
        return `0:${formatted}`
      }
    }

    return formatted
  },
  sortDate: <T>(obj: T[], key: keyof T, type: 'asc' | 'desc') => {
    let toRet: T[] = []

    try {
      const undefDate = obj.filter(el => el[key] === undefined)
      const withDate = obj.filter(el => el[key] !== undefined)

      withDate.sort((a, b) => {
        const aDate = new Date(a[key] as string)
        const bDate = new Date(b[key] as string)

        if (type === 'asc') {
          return aDate.getTime() - bDate.getTime()
        } else {
          return bDate.getTime() - aDate.getTime()
        }
      })

      if (type === 'asc') {
        toRet = [...undefDate, ...withDate]
      } else {
        toRet = [...withDate, ...undefDate]
      }
    } catch (err) {
      console.error('Error on sortDate', err)
    }

    return toRet
  },
  manageDateLimit: (date: Date, dateLimit: Date, type: 'min' | 'max') => {
    if (type === 'max') {
      if (date) {
        return date > dateLimit ? dateLimit : date
      } else {
        return dateLimit
      }
    } else {
      if (date) {
        return date < dateLimit ? dateLimit : date
      } else {
        return dateLimit
      }
    }
  },
  timeSpanToMinutes(timeSpan: string) {
    const time = this.parseTimeSpan(timeSpan)
    return time.hours * 60 + time.minutes
  },
  minutesToTimeSpan(minutes: number) {
    const sign = Math.sign(minutes)
    const absMinutes = Math.abs(minutes)
    const h = Math.floor(absMinutes / 60)
    const m = absMinutes % 60
    return `${sign < 0 ? '-' : ''}${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
  },
  addTimeSpans(timeA: string, timeB: string, overflow?: TimeOverflowHandling) {
    let result = this.timeSpanToMinutes(timeA) + this.timeSpanToMinutes(timeB)
    if (overflow === 'wrap') {
      result = result % (60 * 24)
    } else if (overflow === 'limit') {
      result = Math.min(result, 60 * 24 - 1)
    }
    return this.minutesToTimeSpan(result)
  },
  addTimeSpanToDate(date: Date | string | undefined, timeSpan: string | undefined) {
    if (!date) return undefined
    const resultDate = new Date(date)
    if (timeSpan) {
      const [hh, mm] = timeSpan.split(':').map(Number)
      resultDate.setHours(hh)
      resultDate.setMinutes(mm)
    } else {
      resultDate.setHours(0)
      resultDate.setMinutes(0)
    }
    return resultDate
  },
  subTractTimeSpans(timeA: string, timeB: string, wrap?: TimeOverflowHandling) {
    let result = this.timeSpanToMinutes(timeA) - this.timeSpanToMinutes(timeB)
    if (wrap === 'wrap' && result < 0) {
      result = 60 * 24 - (Math.abs(result) % (60 * 24))
    } else if (wrap === 'limit') {
      result = Math.max(result, 0)
    }
    return this.minutesToTimeSpan(result)
  },
  autoCompleteTimeInput(
    startTime: string | undefined,
    endTime: string | undefined,
    duration: string | undefined,
    find?: 'start' | 'end' | 'duration'
  ) {
    if (endTime && duration && ((!startTime && !find) || find === 'start')) {
      const result = this.subTractTimeSpans(endTime, duration, 'wrap')
      if (result) return { startTime: result }
    } else if (startTime && duration && ((!endTime && !find) || find === 'end')) {
      const result = this.addTimeSpans(startTime, duration, 'wrap')
      if (result) return { endTime: result }
    } else if (startTime && endTime && ((!duration && !find) || find === 'duration')) {
      const result = this.subTractTimeSpans(endTime, startTime, 'wrap')
      if (result) return { duration: result }
    }
    return null
  },
  sortTimeSpans(a: string, b: string) {
    return this.timeSpanToMinutes(a) - this.timeSpanToMinutes(b)
  },
  limitDate(date: Date, minDate: Date | undefined, maxDate: Date | undefined) {
    if (minDate && date < minDate) return new Date(minDate)
    if (maxDate && date > maxDate) return new Date(maxDate)
    return date
  },
  tryAutoCompleteDate(dateInput: string, minDate?: Date, maxDate?: Date) {
    try {
      if (!dateInput || !dateInput.match(/^\d{1,2}[./]?\d{0,2}[./]?$/)) return null
      const [dd, mm, yyyy] = dateInput.replaceAll('.', '/').split('/').map(Number)
      if (!dd) return null
      const currentDate = new Date()
      let result = new Date(yyyy && yyyy > 1900 ? yyyy : currentDate.getFullYear(), mm !== undefined ? mm - 1 : currentDate.getMonth(), dd)
      // if date is < minDate then shift it forward by one month
      if (!!minDate && !mm && result < startOfDay(minDate)) {
        result = addMonths(result, 1)
        if (result < startOfDay(minDate)) return null
      }
      if (!!maxDate && result > endOfDay(maxDate)) return null
      return result
    } catch {
      return null
    }
  },
  compareDateAndTimeStamp(...elements: (Date | string | null | undefined)[]) {
    if (!elements) return false
    const toMinutes = (element: Date | string) => {
      if (element instanceof Date || element.length > 8 /*Max TimeStamp length*/) {
        const date = typeof element === 'string' ? new Date(element) : element
        return TimeUtils.getMinutesOfDay(date)
      }
      return TimeUtils.timeSpanToMinutes(element)
    }

    let mem: null | number = null
    for (const e of elements) {
      if (!e) return false
      const min = toMinutes(e)
      if (mem === null) {
        mem = min
        continue
      }

      if (mem !== min) return false
    }
    return true
  },
}

export default TimeUtils
