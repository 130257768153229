import { IM, IMLayout, IMStyle, useLanguage, useTheme, Utils, ViewProps } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { AppStyle } from '../../constants/Styles'
import { TimeRecord } from '../../types'
import { ValidationUtils } from '../../utils/ValidationUtils'
import Button from '../Button'
import PressableIcon from '../Infominds/PressableIcon'
import Separator from '../Infominds/Separator'
import TextInput from './TextInput'
import TimeInput from './TimeInput'

export type MultiTimeInputProps = {
  times: TimeRecord[] | undefined
  onChange: (times: TimeRecord[] | undefined) => void
  error?: boolean
  editable?: 'full' | 'only-current' | false
  hasNotes?: boolean
} & Pick<ViewProps, 'spacing' | 'style'>

export default function MultiTimeInput(props: MultiTimeInputProps) {
  const { times, onChange, editable = 'full', ...viewProps } = props
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  function handleDataChange(time: Partial<TimeRecord>) {
    onChange(times?.map(t => (t.id === time.id ? { ...t, ...time } : t)))
  }

  function handleAddTime() {
    onChange([...(times ?? []), { id: Utils.getUid() }])
  }

  function handleDelete(id: string) {
    if (times?.length === 1) {
      onChange([...(times?.map(t => ({ id: t.id })) ?? [])])
      return
    }
    onChange([...(times?.filter(t => t.id !== id) ?? [])])
  }

  useEffect(() => {
    if (times?.length || editable !== 'full') return
    handleAddTime()
  }, [times, editable])

  const canAddTime = useMemo(() => editable === 'full' && !times?.find(t => !t.startTime || !t.stopTime), [editable, times])

  return (
    <IM.View {...viewProps}>
      {times?.map((time, index) => (
        <InputRenderItem key={time.id} handleDataChange={handleDataChange} handleDelete={handleDelete} time={time} index={index} {...props} />
      ))}
      {editable === 'full' && (
        <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentEnd]} spacing={'top'}>
          <Button title={i18n.t('ADD')} color={theme.primary} onPress={handleAddTime} disabled={!canAddTime} />
        </IM.View>
      )}
    </IM.View>
  )
}

type TimeInputProps = {
  time: TimeRecord
  index: number
  handleDataChange: (value: Partial<TimeRecord>) => void
  handleDelete: (id: string) => void
} & Pick<MultiTimeInputProps, 'editable' | 'error' | 'hasNotes'>
function InputRenderItem({ time, index, editable, error: externalError, handleDataChange, handleDelete, hasNotes }: TimeInputProps) {
  const { i18n } = useLanguage()
  const error = useMemo(
    () => externalError || (!!time.startTime && !!time.stopTime && !ValidationUtils.timeSpan(time.startTime, time.stopTime)),
    [externalError, time.startTime, time.stopTime]
  )

  function handleChange(value: Partial<Omit<TimeRecord, 'id'>>) {
    handleDataChange({ ...time, ...value })
  }

  return (
    <IM.View key={time.id} spacing={'bottom'}>
      <IM.View style={[IMLayout.flex.row, { gap: 2 * IMLayout.horizontalMargin }]}>
        <TimeInput
          key={`StartTime#${time.id}`}
          required
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('TIME_FROM')}
          value={time?.startTime}
          onChangeTime={value => handleChange({ startTime: value })}
          error={error}
          limitTo24h
          editable={!!editable}
        />
        <TimeInput
          key={`StopTime#${time.id}`}
          required
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('TIME_UNTIL')}
          value={time?.stopTime}
          onChangeTime={value => handleChange({ stopTime: value })}
          error={error}
          defaultPickerValue={time?.startTime}
          limitTo24h
          editable={!!editable}
        />
        {editable === 'full' && index > 0 && (
          <IM.View style={[AppStyle.center, styles.deleteIconView]}>
            <IM.View>
              <IM.Text
                primary // dummy text to move icon to correct position
              >
                {' '}
              </IM.Text>
            </IM.View>
            <PressableIcon icon={['fas', 'circle-minus']} size={20} color={IMStyle.palette.red} onPress={() => handleDelete(time.id)} />
          </IM.View>
        )}
      </IM.View>
      {(!!time.startTime || index > 0) && hasNotes && (
        <>
          <TextInput
            value={time?.notes}
            onChangeText={value => handleChange({ notes: value })}
            editable
            title={i18n.t('NOTES')}
            multiline
            spacing={'bottom'}
          />
          <Separator />
        </>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  deleteIconView: {
    paddingTop: 5,
  },
})
