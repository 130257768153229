import { IMStyle, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { Button, Col, Flex, Popover, Row } from 'antd'
import { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group'
import lodash from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Resource } from 'react-big-scheduler-stch'
import { StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import { Qualification } from '../../apis/types/apiResponseTypes'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { ThemeColorExpanded } from '../../types'
import { ganttChartSelectedTechniciansAtom } from '../../utils/stateManager'
import Text from '../Text'
import ChartFilter from './ChartFilter'
import FilterCheckboxGroup from './FilterCheckboxGroup'

const SHOW_UNUSED_QUALIFICATION = false

export const resourceFilterStyle: React.CSSProperties = {
  overflowY: 'auto',
  flexWrap: 'nowrap',
  //@ts-ignore ok on web
  maxHeight: '50vh',
  maxWidth: 250,
}

const styles = StyleSheet.create({
  divider: { margin: 6 },
  button: { marginTop: 6 },
  buttonTransparent: { backgroundColor: 'transparent' },
  subtitle: { paddingBottom: 4 },
  subtitleText: { fontWeight: IMStyle.typography.fontWeightMedium },
})

interface Props {
  resources: Resource[]
  qualifications: Qualification[]
}

const ResourceFilter = ({ resources, qualifications }: Props) => {
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const resourceInitialValueRef = useRef<CheckboxValueType[]>([])
  const qualificationsInitialValueRef = useRef<CheckboxValueType[]>([])

  const [open, setOpen] = useState(false)
  const employeeOptions = useRef<CheckboxOptionType[]>(
    resources.map(resource => ({
      id: resource.id.toString(),
      label: resource.name,
      value: resource.id,
      style: { color: theme.text },
    }))
  )
  const [qualificationOptions, setQualificationOptions] = useState<CheckboxOptionType[]>(
    qualifications.map(qualification => ({
      id: qualification.id.toString(),
      label: qualification.description,
      value: qualification.id,
      style: { color: theme.text },
    }))
  )

  const prevCheckEmployee = useRef<CheckboxValueType[]>([])
  const [savedCheckedEmployee, setSavedCheckedEmployee] = useRecoilState(ganttChartSelectedTechniciansAtom(sessionKey))
  const [checkedEmployee, setCheckedEmployee] = useState(savedCheckedEmployee)
  const prevCheckQualification = useRef<CheckboxValueType[]>([])
  const [checkedQualification, setCheckedQualification] = useState<CheckboxValueType[] | undefined>(undefined)

  useEvent({ key: EVENT_KEYS.GANTT_CHART_BLUR }, () => setOpen(false))

  useEffect(() => {
    // @ts-ignore qualificationId added manually
    const resourcesQualifications = resources.map(resource => resource.qualificationId as number)
    const qualificationsId = qualificationOptions.map(el => el.value).filter(el => el !== undefined) as number[]

    const difference = qualificationsId.filter(x => !resourcesQualifications.includes(x))

    setQualificationOptions(
      prev =>
        prev
          .map(el => {
            const found = difference.find(diff => diff.toString() === el.id)
            if (found) return SHOW_UNUSED_QUALIFICATION ? { ...el, disabled: true } : undefined
            return { ...el, disabled: false }
          })
          .filter(el => el) as CheckboxOptionType<CheckboxValueType>[]
    )
  }, [])

  useEffect(() => {
    open && onSetInitialValues()
  }, [open])

  useEffect(() => {
    if (checkedEmployee === undefined) {
      const allResources = resources.map(resource => resource.id)
      setCheckedEmployee(allResources)
      resourceInitialValueRef.current = allResources
    } else {
      manageEmployeeChange(prevCheckEmployee.current, checkedEmployee)
      prevCheckEmployee.current = checkedEmployee
    }
  }, [checkedEmployee])

  useEffect(() => {
    if (checkedQualification === undefined) return

    manageQualificationChange(prevCheckQualification.current, checkedQualification)
    prevCheckQualification.current = checkedQualification
  }, [checkedQualification])

  const manageQualificationChange = (prev: CheckboxValueType[], current: CheckboxValueType[]) => {
    const totalAdded: CheckboxValueType[] | undefined = current.filter(x => !prev.includes(x))
    const totalRemoved: CheckboxValueType[] | undefined = prev.filter(x => !current.includes(x))

    if (totalAdded) {
      for (const added of totalAdded) {
        const qualificationIdAdded = qualifications.find(qualification => qualification.id === added)?.id

        if (qualificationIdAdded) {
          setCheckedEmployee(prevState => {
            let toRet = [...(prevState ?? [])]
            // @ts-ignore qualificationId added manually
            const foundNewEmployees = resources.filter(resource => resource.qualificationId === qualificationIdAdded)
            for (const newEmployee of foundNewEmployees) {
              toRet.push(newEmployee.id)
            }

            toRet = [...new Set(toRet)]
            prevCheckEmployee.current = toRet
            return toRet
          })
        }
      }
    }

    if (totalRemoved) {
      for (const removed of totalRemoved) {
        const qualificationIdRemoved = qualifications.find(qualification => qualification.id === removed)?.id
        if (qualificationIdRemoved) {
          setCheckedEmployee(prevState => {
            let toRet = prevState
              ?.map(checkResource => {
                const foundEmployee = resources.find(resource => resource.id === checkResource)
                // @ts-ignore qualificationId added manually
                if (foundEmployee && foundEmployee.qualificationId === qualificationIdRemoved) {
                  return undefined
                }
                return checkResource
              })
              .filter(el => el !== undefined) as CheckboxValueType[]

            toRet = [...new Set(toRet)]
            prevCheckEmployee.current = toRet
            return toRet
          })
        }
      }
    }
  }

  const manageEmployeeChange = (prev: CheckboxValueType[], current: CheckboxValueType[]) => {
    const totalAdded: CheckboxValueType[] | undefined = current.filter(x => !prev.includes(x))
    const totalRemoved: CheckboxValueType[] | undefined = prev.filter(x => !current.includes(x))

    if (totalAdded) {
      for (const added of totalAdded) {
        const resourceAdded = resources.find(resource => resource.id === added)
        if (resourceAdded) {
          // @ts-ignore qualificationId added manually
          const resourceAddedQualificationId = resourceAdded.qualificationId
          // @ts-ignore qualificationId added manually
          const allResourcesWithQualification = resources.filter(resource => resource.qualificationId === resourceAddedQualificationId)

          const intersection = current.filter(x => (allResourcesWithQualification.map(el => el.id) as CheckboxValueType[]).includes(x))

          const result = intersection.length === 0 ? false : intersection.length === allResourcesWithQualification.length ? true : undefined

          if (result === true) {
            setCheckedQualification(prevState => {
              let toRet = [...(prevState ?? [])]
              toRet.push(resourceAddedQualificationId as number)
              toRet = [...new Set(toRet)]
              prevCheckQualification.current = toRet
              return toRet
            })
            setQualificationOptions(prevState => {
              return prevState.map(el => {
                if (el.id === (resourceAddedQualificationId as number).toString()) {
                  return { ...el, indeterminate: false }
                }
                return el
              })
            })
          } else if (result === false) {
            // impossible here
          } else {
            setQualificationOptions(prevState => {
              return prevState.map(el => {
                if (el.id === (resourceAddedQualificationId as number).toString()) {
                  return { ...el, indeterminate: true }
                }
                return el
              })
            })
          }
        }
      }
    }

    if (totalRemoved) {
      for (const removed of totalRemoved) {
        const resourceRemoved = resources.find(resource => resource.id === removed)

        if (resourceRemoved) {
          // @ts-ignore qualificationId added manually
          const resourceRemovedQualificationId = resourceRemoved.qualificationId
          // @ts-ignore qualificationId added manually
          const allResourcesWithQualification = resources.filter(resource => resource.qualificationId === resourceRemovedQualificationId)

          const intersection = current.filter(x => (allResourcesWithQualification.map(el => el.id) as CheckboxValueType[]).includes(x))

          const result = intersection.length === 0 ? false : intersection.length === allResourcesWithQualification.length ? true : undefined

          if (result === true) {
            // impossible here
          } else if (result === false) {
            setCheckedQualification(prevState => {
              const toRet = [...new Set((prevState ?? []).filter(el => el !== (resourceRemovedQualificationId as number)))]
              prevCheckQualification.current = toRet

              return toRet
            })
            setQualificationOptions(prevState => {
              return prevState.map(el => {
                if (el.id === (resourceRemovedQualificationId as number).toString()) {
                  return { ...el, indeterminate: false }
                }
                return el
              })
            })
          } else {
            setQualificationOptions(prevState => {
              return prevState.map(el => {
                if (el.id === (resourceRemovedQualificationId as number).toString()) {
                  return { ...el, indeterminate: true }
                }
                return el
              })
            })
          }
        }
      }
    }
  }

  const onSetInitialValues = () => {
    resourceInitialValueRef.current = checkedEmployee ?? []
    qualificationsInitialValueRef.current = checkedQualification ?? []
  }

  const onRestore = () => {
    setCheckedEmployee(resourceInitialValueRef.current)
    setCheckedQualification(qualificationsInitialValueRef.current)
    setOpen(false)
  }

  const onApply = () => {
    const equal = lodash.isEqual(checkedEmployee, resourceInitialValueRef.current)
    if (equal === false) setSavedCheckedEmployee(checkedEmployee)

    setOpen(false)
  }

  const popoverContent = () => {
    return (
      <Col>
        <Row>
          <Col>
            <Row style={styles.subtitle}>
              <Text style={styles.subtitleText}>{i18n.t('TECHNICIANS')}</Text>
            </Row>
            <FilterCheckboxGroup options={employeeOptions.current} selected={checkedEmployee} setSelected={setCheckedEmployee} />
          </Col>
          <div style={styles.divider} />
          <Col>
            <Row style={styles.subtitle}>
              <Text style={styles.subtitleText}>{i18n.t('QUALIFICATIONS')}</Text>
            </Row>
            <FilterCheckboxGroup options={qualificationOptions} selected={checkedQualification} setSelected={setCheckedQualification} />
          </Col>
        </Row>
        <Row justify="end">
          <Flex gap="small">
            <Button type="default" onClick={onRestore} style={{ ...styles.button, ...styles.buttonTransparent }}>
              {i18n.t('CANCEL')}
            </Button>
            <Button type="primary" onClick={onApply} style={styles.button}>
              {i18n.t('APPLY')}
            </Button>
          </Flex>
        </Row>
      </Col>
    )
  }

  return (
    <Popover
      open={open}
      placement="bottomRight"
      title={i18n.t('TECHNICIANS_FILTER')}
      content={popoverContent}
      trigger="click"
      color={colorScheme === 'dark' ? theme.header.detail.background : 'white'}>
      <ChartFilter
        count={checkedEmployee?.length ?? 0}
        badgeStyle={{ boxShadow: `0 0 0 1px ${theme.gantt.background}` }}
        onClick={() => setOpen(prev => !prev)}
      />
    </Popover>
  )
}

export default memo(ResourceFilter)
