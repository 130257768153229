import { useEvent, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api } from '../../../apis/apiCalls'
import { UnavailableType, UnavailableTypeDefinition } from '../../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../../components/ApiDeleteButton'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../../components/Infominds/hooks/useEditDataHandler'
import ColorInput from '../../../components/input/ColorInput'
import MultiLangTextInput from '../../../components/input/MultiLangTextInput'
import { TextInputProps } from '../../../components/input/TextInput'
import ScrollViewForm from '../../../components/ScrollViewForm'
import TimeAttendanceSelector from '../../../components/selectors/TimeAttendanceSelector'
import UnavailableTypeSelector from '../../../components/selectors/UnavailableTypeSelector'
import { USER_COLORS } from '../../../constants/Colors'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { EditOrCreateViewRef, UploadStatus } from '../../../types'

type Props = {
  unavailableType?: UnavailableType
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

function UnavailableTypeCreateOrEditView({ unavailableType, onDone, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) {
  const { i18n } = useLanguage()

  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))

  const { emit: emitAbsencesRefresh } = useEvent({ key: EVENT_KEYS.GANTT_CHART_TO_PLAN_REFRESH })

  const initialValue = useMemo(
    () =>
      unavailableType ?? {
        planColor: USER_COLORS[Math.round(Math.random() * (USER_COLORS.length - 1))].primary,
        type: UnavailableTypeDefinition.disease,
      },
    [unavailableType]
  )
  const requiredFields = useMemo<EditDataHandlerRequiredFields<UnavailableType>>(() => [['titleDe', 'titleEn', 'titleIt'], 'type', 'planColor'], [])
  const { state, editMode, createOrUpdate, handleDataChange } = useEditDataHandler(
    api.activities.unavailables.types.post,
    api.activities.unavailables.types.put,
    undefined,
    {
      eventKeyCreation: EVENT_KEYS.UPDATED_UNAVAILABLE_TYPES,
      eventKeyModification: EVENT_KEYS.UPDATED_UNAVAILABLE_TYPES,
      onDone: () => {
        emitAbsencesRefresh()
        onDone()
      },
      onUploadStatus: onUploadStatus,
      editMode: !!unavailableType,
      initialValue: initialValue,
      showErrorAlert: true,
      requiredFields,
      modifyDataBeforeRequest: data => ({ ...data, type: data.type ?? UnavailableTypeDefinition.disease }),
    }
  )

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <UnavailableTypeSelector
        required
        value={state?.type}
        onChange={value =>
          handleDataChange({ type: value, timeAttendId: value === UnavailableTypeDefinition.constructionSite ? undefined : state?.timeAttendId })
        }
        {...commonProps}
      />
      {state?.type !== UnavailableTypeDefinition.constructionSite && (
        <TimeAttendanceSelector
          onChange={value => handleDataChange({ timeAttendId: value?.id })}
          editable
          initialValue={unavailableType?.timeAttendId}
          {...commonProps}
        />
      )}
      <MultiLangTextInput
        required
        title={i18n.t('TITLE')}
        placeholder={i18n.t('TITLE')}
        values={{ de: state?.titleDe, en: state?.titleEn, it: state?.titleIt }}
        onChangeText={values => handleDataChange({ titleEn: values.en, titleDe: values.de, titleIt: values.it })}
        multiline
        {...commonProps}
      />

      <MultiLangTextInput
        title={i18n.t('NOTE')}
        placeholder={i18n.t('NOTE')}
        values={{ de: state?.noteDe, en: state?.noteEn, it: state?.noteIt }}
        onChangeText={values => handleDataChange({ noteEn: values.en, noteDe: values.de, noteIt: values.it })}
        multiline
        {...commonProps}
      />

      <ColorInput
        required
        title={i18n.t('PLAN_COLOR')}
        placeholder={i18n.t('COLOR')}
        value={state?.planColor}
        onChangeColor={value => handleDataChange({ planColor: value })}
        {...commonProps}
      />

      {editMode && !!unavailableType && (
        <ApiDeleteButton
          onDeleted={onDone}
          data={unavailableType}
          deleteAlertMessage={i18n.t('DELETE_UNAVAILABLE_TYPE_ALERT')}
          deleteAlertTitle={i18n.t('DELETE')}
          deleteRequest={api.activities.unavailables.types.delete}
          eventKey={EVENT_KEYS.UPDATED_UNAVAILABLE_TYPES}
        />
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(UnavailableTypeCreateOrEditView)
