import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren, ReactNode } from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, ScrollView, ScrollViewProps } from 'react-native'

import CONSTANTS from '../constants/Constants'
import { InfiniteLoadingType } from '../types'
import Error from './Error'
import RefreshControl from './Infominds/RefreshControl'
import ListSpacer from './ListSpacer'
import SkeletonText from './skeleton/SkeletonText'

type Props = {
  loading?: InfiniteLoadingType
  buttonId?: string
  disableHideKeyboardOnScroll?: boolean
  refresh?: () => void
  onScroll?: ((event: NativeSyntheticEvent<NativeScrollEvent>) => void) | undefined
  skeletonComponent?: ReactNode | ((index: number) => ReactNode)
}

export default function ScrollViewData({
  children,
  loading = false,
  disableHideKeyboardOnScroll,
  refresh,
  onScroll,
  skeletonComponent,
  scrollEnabled,
  ...others
}: Props & PropsWithChildren<ScrollViewProps>) {
  const { colorScheme } = useTheme()

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    !disableHideKeyboardOnScroll && Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()

    onScroll?.(e)
  }

  return (
    <ScrollView
      onScroll={handleScroll}
      scrollEventThrottle={200}
      scrollEnabled={loading !== 'reloading' && (!!scrollEnabled || scrollEnabled === undefined)}
      refreshControl={refresh ? <RefreshControl refreshing={false} onRefresh={refresh} /> : undefined}
      style={[IMLayout.flex.f1, others.style]}
      indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
      {...others}
      contentContainerStyle={[{ paddingHorizontal: IMLayout.horizontalMargin * 2 }, others.contentContainerStyle]}>
      <ListSpacer />
      {loading === false && children}
      {loading === 'catched' && <Error />}
      {!!loading && loading !== 'catched' && (
        <>
          {Array(CONSTANTS.skeletonCards)
            .fill(0)
            .map((_, index) => {
              return skeletonComponent ? (
                typeof skeletonComponent === 'function' ? (
                  skeletonComponent(index)
                ) : (
                  skeletonComponent
                )
              ) : (
                <IM.View spacing={index === 0 ? 'bottom' : 'vertical'} key={`ScrollViewData-${index}`}>
                  <SkeletonText width="30%" />
                  <SkeletonText width="60%" spacing="top" />
                  <SkeletonText width="100%" spacing="top" />
                </IM.View>
              )
            })}
        </>
      )}
    </ScrollView>
  )
}
