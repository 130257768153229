import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity } from '../../apis/types/apiResponseTypes'
import SorterTag from '../../components/SorterTag'
import useFilter from '../../hooks/useFilter'
import { DataFilterConfigType } from '../../types'
import { filterUtils } from '../../utils/FilterUtils'

export default function FilterOrderView() {
  const { orders, changeFilterStatus } = useFilter<Activity>()
  const { i18n } = useLanguage()

  const activeOrders = useMemo(() => [...orders].sort(filterUtils.sortOrders).filter(q => q.active), [orders])
  const otherOrders = useMemo(() => orders.filter(o => !o.active && !activeOrders.find(ao => ao.dataKey === o.dataKey)), [orders, activeOrders])

  return (
    <IM.View spacing="top">
      <IM.View style={styles.titleContainer}>
        <IM.Icon icon={['fal', 'arrow-up-arrow-down']} spacing="right" />
        <IM.Text style={styles.title}>{i18n.t('SORT_BY')}</IM.Text>
      </IM.View>
      {activeOrders.map((activeOrder, index) => (
        <React.Fragment key={activeOrder.id}>
          <IM.View style={styles.groupContainer}>
            <SorterTag sorter={activeOrder} style={styles.tag} onPress={id => changeFilterStatus(DataFilterConfigType.Order, id)} />
          </IM.View>
          {(index < activeOrders.length - 1 || !!otherOrders.length) && (
            <IM.View style={styles.titleContainer}>
              <IM.Icon icon={['fal', 'arrow-up-arrow-down']} spacing="right" />
              <IM.Text style={styles.title}>{i18n.t('SORT_THEN_BY')}</IM.Text>
            </IM.View>
          )}
        </React.Fragment>
      ))}
      {!!otherOrders.length && (
        <IM.View style={styles.groupContainer}>
          {otherOrders.map(order => {
            return <SorterTag key={order.id} sorter={order} style={styles.tag} onPress={id => changeFilterStatus(DataFilterConfigType.Order, id)} />
          })}
        </IM.View>
      )}
    </IM.View>
  )
}
const styles = StyleSheet.create({
  title: {
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  groupContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 14,
  },
  tag: { marginVertical: 3 },
  center: {
    alignItems: 'center',
  },
})
