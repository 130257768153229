import { useEvent } from '@infominds/react-native-components'
import { useEffect } from 'react'

import { api } from '../../apis/apiCalls'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { EVENT_KEYS } from '../../constants/EventKeys'

export default function useActivityTypes() {
  const { item: activityTypes, loadItem: loadTypes, loading: loadingTypes } = useControlledLoader(api.activities.types.getList)

  useEvent({ key: EVENT_KEYS.UPDATED_ACTIVITY_TYPES }, refreshTypes)

  useEffect(refreshTypes, [])

  function refreshTypes() {
    loadTypes()
  }

  return { activityTypes, loadingTypes, refreshTypes }
}
