import { IM, useModalController, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'

import { api } from '../../../apis/apiCalls'
import { ActivityArticle, ErgoTask } from '../../../apis/types/apiResponseTypes'
import { AppStyle } from '../../../constants/Styles'
import useOnLayout from '../../../hooks/useOnLayout'
import RdaVehicleSelectionModal from '../../../modals/activity/rda/RdaVehicleSelectionModal'
import PressableIcon from '../../Infominds/PressableIcon'
import LoadingIcon from '../../LoadingIcon'
import Tag from '../../Tag'

export type RdaVehicleSelectorProps = {
  ergoTask: ErgoTask | null | undefined
  activityArticle: ActivityArticle | undefined
  handleChange: (registrationNr: string | undefined) => void
} & Pick<ViewProps, 'style' | 'spacing'>

export default function RdaVehicleSelector({ activityArticle, handleChange, ergoTask, ...viewProps }: RdaVehicleSelectorProps) {
  const { theme } = useTheme()
  const vehicleSelectionModal = useModalController<string | undefined>()
  const { onLayout, layout } = useOnLayout()
  const [loading, setLoading] = useState(false)

  const selectionEnabled =
    !!activityArticle?.isRda && !!ergoTask && !!ergoTask.paramList?.DOCPOSSTATUSID_RDA && ergoTask.paramList?.DOCPOSSTATUSID_RDA !== '0'
  const [selectionActive, setSelectionActive] = useState(!!activityArticle?.registrationNr)

  const showSelection = selectionEnabled && selectionActive
  const showSelectionIcon = selectionEnabled && !selectionActive

  useEffect(() => {
    if (!activityArticle?.registrationNr && selectionActive) setSelectionActive(false)
  }, [activityArticle])

  function handleIconPress() {
    setLoading(true)
    api.vehicles
      .getUserVehicle()
      .then(vehicle => {
        if (vehicle) {
          handleChange(vehicle.registrationNr)
          setSelectionActive(true)
        } else {
          vehicleSelectionModal.show(undefined)
        }
      })
      .catch(error => {
        console.error(error)
        vehicleSelectionModal.show(undefined)
      })
      .finally(() => setLoading(false))
  }

  if (!showSelection && !showSelectionIcon) return null

  return (
    <IM.View {...viewProps}>
      {showSelectionIcon && !loading && (
        <IM.View onLayout={onLayout}>
          <PressableIcon size={20} icon={['fal', 'truck']} onPress={handleIconPress} color={theme.textDetail} />
        </IM.View>
      )}
      {showSelectionIcon && loading && <LoadingIcon style={[{ width: layout?.width, height: layout?.height }, AppStyle.center]} />}
      {showSelection && (
        <IM.View style={[{ minHeight: layout?.height }, AppStyle.justifyContentCenter]}>
          <Tag
            id={activityArticle.registrationNr ?? 'regNr'}
            name={activityArticle.registrationNr ?? ''}
            active
            icon={['fal', 'truck']}
            onPress={() => vehicleSelectionModal.show(activityArticle.registrationNr)}
            rightIcon={['fal', 'chevron-right']}
          />
        </IM.View>
      )}
      <RdaVehicleSelectionModal
        controller={vehicleSelectionModal}
        onChange={vehicle => {
          handleChange(vehicle?.registrationNr)
          setSelectionActive(!!vehicle)
        }}
        selectedVehicleRegistrationNr={activityArticle.registrationNr}
      />
    </IM.View>
  )
}
