import { IM, IMLayout, useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { api } from '../apis/apiCalls'
import { Activity } from '../apis/types/apiResponseTypes'
import { EVENT_KEYS } from '../constants/EventKeys'
import { AppStyle } from '../constants/Styles'
import useExtendedTheme from '../hooks/useExtendedTheme'
import useOnLayout from '../hooks/useOnLayout'
import useUserSettings from '../hooks/useUserSettings'
import { EmployeeUtils } from '../utils/EmployeeUtils'
import EmployeeBadge from './EmployeeBadge'
import useRequest from './Infominds/hooks/useRequest'
import Pressable from './Infominds/Pressable'
import LoadingIcon from './LoadingIcon'

type AssignToMeButtonProps = {
  activity: Activity
  style?: StyleProp<ViewStyle>
  onAssigned?: () => void
}

export default function AssignToMeButton({ activity, style, onAssigned }: AssignToMeButtonProps) {
  const { userSettings } = useUserSettings()
  const { theme } = useExtendedTheme()
  const { i18n } = useLanguage()
  const { layout, onLayout } = useOnLayout()
  const alert = useAlert()
  const { emit } = useEvent<Activity>({ key: EVENT_KEYS.ACTIVITY_ASSIGNED })
  const { request, loading } = useRequest(api.activities.employees.post, {
    onError: handleError,
    onSuccess: () => {
      if (onAssigned) onAssigned()
      emit(activity)
    },
  })

  function handleAssignment() {
    request({
      srvActivityId: activity.srvActivityId,
      srvActivityYear: activity.srvActivityYear,
      srvActivityTypeId: activity.srvActivityTypeId,
      technicianId: userSettings?.employee?.id,
      technicianType: 'employee',
      plannedByEmployeeId: userSettings?.employee?.id,
    })
  }

  function handleError() {
    alert.alert(i18n.t('ERROR'), i18n.t('ERROR_ASSIGN_USER_TO_ACTIVITY'), undefined)
  }

  return (
    <Pressable
      style={[
        IMLayout.flex.row,
        styles.main,
        {
          backgroundColor: theme.card.secondaryBackground,
        },
        style,
        IMLayout.shadow,
      ]}
      onPress={handleAssignment}
      disabled={loading === 'reloading'}>
      <IM.Text numberOfLines={1} style={styles.text}>
        {i18n.t('ASSIGN_TO_ME')}
      </IM.Text>
      {loading === 'reloading' && (
        <IM.View style={[{ width: layout?.width, height: layout?.height }, AppStyle.center]}>
          <LoadingIcon />
        </IM.View>
      )}
      {loading !== 'reloading' && (
        <EmployeeBadge
          id={userSettings?.employee?.id}
          name={EmployeeUtils.getName(userSettings?.employee)}
          style={styles.badge}
          onLayout={onLayout}
        />
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  main: {
    borderRadius: 50,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: IMLayout.horizontalMargin,
    paddingRight: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.horizontalMargin,
    paddingLeft: IMLayout.horizontalMargin * 1.5,
  },
  text: {
    color: '#FFF',
  },
  badge: {},
})
