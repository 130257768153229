import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { i18nKey, ListSection } from '../../types'
import RadioPressable from '../RadioPressable'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  value: string | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: string | undefined) => void
} & Pick<SelectInputProps<CustomerType>, 'required' | 'disableFastInput' | 'title' | 'placeholder'>

type CustomerType = {
  id: string
  labelKey: i18nKey
}

export const SupportedCustomerTypes: CustomerType[] = [
  { id: 'PA', labelKey: 'CUSTOMER_TYPE_PA' },
  { id: 'B2B', labelKey: 'CUSTOMER_TYPE_B2B' },
  { id: 'B2C', labelKey: 'CUSTOMER_TYPE_B2C' },
  { id: 'EXT', labelKey: 'CUSTOMER_TYPE_EXTERNAL' },
]

export default function CustomerTypeSelector({ value, onChange, title, ...props }: Props) {
  const { i18n } = useLanguage()

  const selectedType = useMemo(() => SupportedCustomerTypes.find(g => g.id === value), [value])

  const render = ({ item }: SectionListRenderItemInfo<CustomerType, ListSection<CustomerType>>, onPress?: () => void) => {
    return <RadioPressable key={item.id} onPress={onPress} selected={item.id === value} text={i18n.t(item.labelKey)} />
  }

  const onPressed = (newValue: CustomerType | undefined) => {
    onChange(newValue?.id)
  }

  return (
    <>
      <SelectInput
        id={['id']}
        data={SupportedCustomerTypes ?? []}
        value={selectedType}
        loading={false}
        title={title ?? i18n.t('CUSTOMER_TYPE')}
        screenTitle={title ?? i18n.t('CUSTOMER_TYPE')}
        noDataMessage={''}
        renderItem={render}
        onChange={onPressed}
        renderSelectedString={item => i18n.t(item.labelKey)}
        disableLoadAfterMount
        hideNoSelectionItem
        {...props}
      />
    </>
  )
}
