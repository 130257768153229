import { IM, IMLayout, IMStyle, InfomindsColors } from '@infominds/react-native-components'
import { AssetUtils, LiteAsset, MediaUtils } from '@infominds/react-native-media-lite'
import React, { useMemo } from 'react'
import { Image, Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import useExtendedTheme from '../../hooks/useExtendedTheme'
import Pressable from '../Infominds/Pressable'
import PressableIcon from '../Infominds/PressableIcon'
import LoadingIcon from '../LoadingIcon'
import FilePreview from './FilePreview'
import VideoPreview from './VideoPreview'

export type MediaViewAssetProps = {
  asset: LiteAsset
  onAssetPressed?: (asset: LiteAsset) => void
  size: number
  style?: StyleProp<ViewStyle>
  blurred?: boolean
  overlayText?: string
  onDelete?: (() => void) | false
  isBeingDeleted?: boolean
  showLoadingSpinner?: boolean
  backgroundColor?: string
  isLargeDisplay?: boolean
}

export default function MediaViewAsset(props: MediaViewAssetProps) {
  const { asset, onAssetPressed, size, style, blurred, overlayText, onDelete, isBeingDeleted, showLoadingSpinner, isLargeDisplay } = props
  const { theme } = useExtendedTheme()

  const isFile = asset.type === 'file'
  const isVideo = asset.type === 'video'
  const isAudio = asset.type === 'audio'
  const isImage = asset.type === 'photo'

  const uri = useMemo(() => {
    if (Platform.OS !== 'web' || asset.type === 'file') return asset.path ?? ''
    return asset.base64?.startsWith('data:') ? asset.base64 : MediaUtils.base64ToUri(asset.base64 ?? '', AssetUtils.getAssetMime(asset))
  }, [asset])

  return (
    <>
      <Pressable
        onPress={
          onAssetPressed
            ? () => {
                onAssetPressed(asset)
              }
            : undefined
        }
        disabled={!onAssetPressed || showLoadingSpinner}
        style={[styles.pressable, style]}
        spacing={'none'}>
        {isFile && <FilePreview {...props} icon={['fal', 'file']} />}

        {isImage && (
          <Image
            source={{ uri: uri }}
            style={[{ width: size, height: size, borderRadius: IMLayout.borderRadius }]}
            resizeMode="cover"
            blurRadius={blurred ? 10 : undefined}
          />
        )}
        {isVideo && (
          <VideoPreview
            asset={asset}
            size={size}
            showControls={isLargeDisplay}
            overlayIcon={showLoadingSpinner || isLargeDisplay ? undefined : ['fal', 'play']}
          />
        )}
        {/* {isVideo && Platform.OS === 'web' && <FilePreview asset={asset} size={size} icon={['fal', 'play']} />} */}
        {isAudio && <FilePreview icon={['fal', 'volume']} {...props} />}
        {!!overlayText && (
          <>
            <IM.View style={styles.overlayView}>
              <IM.Text style={styles.overlayText}>{overlayText}</IM.Text>
            </IM.View>
            <IM.View style={styles.darkenedOverlay} />
          </>
        )}

        {!!isBeingDeleted && (
          <IM.View style={[styles.overlayDeleteView]}>
            <LoadingIcon color={InfomindsColors.redDarker} size={25} />
          </IM.View>
        )}
        {!!showLoadingSpinner && (
          <IM.View style={[styles.overlayLoadingSpinnerView]}>
            <LoadingIcon size={25} />
          </IM.View>
        )}
      </Pressable>
      {!!onDelete && !isBeingDeleted && (
        <IM.View style={[styles.deleteIcon, { backgroundColor: theme.error }]}>
          {!isBeingDeleted && onDelete && <PressableIcon color={'#fff'} icon={['fal', 'trash']} size={20} onPress={onDelete} />}
        </IM.View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  pressable: {
    padding: 0,
  },
  darkenedOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    borderRadius: IMLayout.borderRadius,
    opacity: 0.3,
    zIndex: 10,
  },
  overlayView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
  },
  overlayDeleteView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: IMLayout.borderRadius,
    opacity: 0.6,
    zIndex: 10,
  },
  overlayLoadingSpinnerView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: IMLayout.borderRadius,
    opacity: 0.3,
    zIndex: 10,
  },
  overlayText: {
    fontSize: IMStyle.typography.fontSizeMedium,
    color: '#fff',
    opacity: 1,
    zIndex: 11,
  },
  deleteIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 5,
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
    width: 45,
    height: 45,
    justifyContent: 'center',
    alignContent: 'center',
  },
})
