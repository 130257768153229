import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import ActivityDetailArticleCard from '../../cards/activities/detail/ActivityDetailArticleCard'
import ActivityDetailNotesCard from '../../cards/activities/detail/ActivityDetailNotesCard'
import DynamicView from '../../components/Infominds/DynamicView'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useLayout from '../../hooks/useLayout'
import { activityUtils } from '../../utils/ActivityUtils'

export default function ActivityPreparationView() {
  const { isMediumDevice } = useLayout(true)
  const { i18n } = useLanguage()

  const { detail } = useActivityDetail()

  const isHistorical = useMemo(() => activityUtils.isActivityClosed(detail), [detail?.state])
  const allowEdit = !isHistorical

  return (
    <DynamicView flexLimitLargeDevice={isMediumDevice ? 6 : 12} rowStyle={{ gap: IMLayout.horizontalMargin * 2 }} spacing={'top'}>
      <IM.View style={IMLayout.flex.f6}>
        <ActivityDetailArticleCard title={i18n.t('PREPARE_ARTICLES')} spacing={'bottom'} enableChange={allowEdit} canCreateRda />
      </IM.View>
      <IM.View style={IMLayout.flex.f6}>
        <ActivityDetailNotesCard spacing="bottom" style={[styles.notesCard]} enableChange={allowEdit} />
      </IM.View>
    </DynamicView>
  )
}

const styles = StyleSheet.create({
  notesCard: {
    flexShrink: 1,
  },
})
