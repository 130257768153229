import Clipboard from '@react-native-clipboard/clipboard'

export const ClipboardUtils = {
  copyToClipboard: (text: string) => {
    try {
      Clipboard.setString(text)
    } catch (e) {
      console.error('copyToClipboard error: ', e)
    }
  },
}
