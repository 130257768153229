import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { ActivityTravel, ActivityType } from '../../../apis/types/apiResponseTypes'
import { AppStyle } from '../../../constants/Styles'
import useLanguageSelect from '../../../hooks/useLanguageSelect'
import useUserSettings from '../../../hooks/useUserSettings'
import { EmployeeUtils } from '../../../utils/EmployeeUtils'
import TimeUtils from '../../../utils/TimeUtils'
import Pressable from '../../Infominds/Pressable'

export type ActivityTravelRecordProps = {
  travel: ActivityTravel
  activityTypes?: ActivityType[]
  onEdit: () => void
  showType?: boolean
  enableChange?: boolean
}

export default function ActivityTravelRecord({ travel, activityTypes, showType = true, onEdit, enableChange }: ActivityTravelRecordProps) {
  const { i18n } = useLanguage()
  const { user } = useUserSettings()
  const { theme } = useTheme()
  const isUserTravel = useMemo(() => EmployeeUtils.compare(user.technician, travel.technician), [user.technician, travel])
  const editable = (isUserTravel || user.can.modify.timesOfOthers) && !!enableChange

  const type = useMemo(() => activityTypes?.find(q => q.srvActivityTypeId === travel.activityTravelSrvActivityTypeId), [activityTypes, travel])
  const { value: typeDescription } = useLanguageSelect({ de: type?.descriptionDe, en: type?.descriptionEn, it: type?.descriptionIt })

  const distance = useMemo(() => travel.km?.toString() ?? '', [travel.km])
  const duration = useMemo(() => TimeUtils.formatTimeStamp(travel.travelDuration) ?? '', [travel.travelDuration])
  return (
    <Pressable onPress={onEdit} disabled={!editable}>
      <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]} spacing={'none'}>
        <IM.View style={[IMLayout.flex.f1]}>
          <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1, { gap: 3 * IMLayout.horizontalMargin }]}>
            {!!duration && (
              <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
                <IM.Text secondary>{i18n.t('DURATION')}:</IM.Text>
                <IM.Text numberOfLines={1} primary>
                  {duration}
                </IM.Text>
              </IM.View>
            )}
            {!!distance && (
              <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
                <IM.Text secondary>km:</IM.Text>
                <IM.Text numberOfLines={1} primary>
                  {distance}
                </IM.Text>
              </IM.View>
            )}
          </IM.View>
          {!!typeDescription && showType && (
            <IM.View>
              <IM.Text secondary>{typeDescription}</IM.Text>
            </IM.View>
          )}
        </IM.View>
        {!!editable && (
          <IM.View style={[AppStyle.center]}>
            <IM.Icon icon={['fal', 'chevron-right']} color={theme.textPlaceholder} size={20} />
          </IM.View>
        )}
      </IM.View>
    </Pressable>
  )
}
