import React from 'react'

import { useReportActivity } from '../../contexts/report/ReportActivityContext'
import NewReportScreen from './NewReportScreen'
import ReportActivitiesScreen from './ReportActivitiesScreen'

export default function ReportScreen() {
  const { activity } = useReportActivity()

  if (activity) return <NewReportScreen />
  return <ReportActivitiesScreen />
}
