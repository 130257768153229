import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import ActivityTimeCreateOrEditScreen from '../../screens/activity/ActivityTimeCreateOrEditScreen'
import { ModalScreenRef } from '../../types'
import { ActivityTimeCreateOrEditViewProps } from '../../views/activities/ActivityTimeCreateOrEditView'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ActivityTimeCreateOrEditViewProps>
}

export default function ActivityTimeCreateOrEditModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ActivityTimeCreateOrEditScreen ref={ref} controller={controller} />
    </BaseServiceModal>
  )
}
