import { IM, IMLayout, IMStyle, useLanguage, Utils, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { ActivityArticle, ErgoTask, RdaStatus } from '../../../apis/types/apiResponseTypes'
import { AppStyle } from '../../../constants/Styles'
import useIsRdaEnabled from '../../../hooks/useIsRdaEnabled'
import useLayout from '../../../hooks/useLayout'
import useOnLayout from '../../../hooks/useOnLayout'
import { articleUtils } from '../../../utils/ArticleUtils'
import TimeUtils from '../../../utils/TimeUtils'
import CheckBox from '../../Infominds/CheckBox'
import { ActivityArticleEntryProps } from '../articles/ActivityArticleRecord'
import RdaState from './RdaState'
import RdaVehicleSelector from './RdaVehicleSelector'

export type RdaViewProps = Pick<ActivityArticleEntryProps, 'article' | 'onChange' | 'enableChange' | 'canCreateRda'> & {
  isMedia: boolean
  ergoTask: ErgoTask | undefined | null
  viewWidth?: number
} & Pick<ViewProps, 'style' | 'spacing'>

export default function RDAView({ article, onChange, enableChange, canCreateRda, isMedia, ergoTask, style, viewWidth, ...viewProps }: RdaViewProps) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { onLayout, layout } = useOnLayout()
  const rdaDoc = useMemo(() => articleUtils.getRdaDocNumber(article), [article])
  const docDate = useMemo(() => !!article.docDate && TimeUtils.format(article.docDate, language), [article])
  const rdaValue = useMemo(() => {
    if (!article.rdaValue || !article.quantity || isMedia) return null
    return Utils.stringValueReplacer(i18n.t('RDA_MISSING_QUANTITY'), Math.max(article.quantity - article.rdaValue, 0), article.quantity)
  }, [article, isMedia])
  const status = useMemo(() => article.rdaStatus ?? RdaStatus.None, [article])
  const rdaEnabled = useIsRdaEnabled(ergoTask)

  function handleRdaChange(value: boolean) {
    const newArticle: ActivityArticle = { ...article, isRda: value, registrationNr: value ? article.registrationNr : undefined }
    onChange(newArticle)
  }

  if (!rdaEnabled) return <></>

  return (
    <IM.View style={[styles.rdaView, IMLayout.flex.row, style]} {...viewProps}>
      {status === RdaStatus.None && canCreateRda && (
        <IM.View style={[IMLayout.flex.row, styles.CheckBoxView, { minHeight: layout?.height }]} onLayout={onLayout}>
          <IM.View style={[{ width: viewWidth }]}>
            <CheckBox
              value={article.isRda}
              onChange={q => handleRdaChange(q.nativeEvent.value)}
              color={IMStyle.palette.tint}
              disabled={!!article.refer2SerialNr || !enableChange}
            />
          </IM.View>
          <IM.View style={[AppStyle.center]} spacing={viewWidth ? undefined : 'left'}>
            {!rdaDoc && canCreateRda && <IM.Text>{i18n.t('RDA')}</IM.Text>}
          </IM.View>
          <RdaVehicleSelector
            activityArticle={article}
            handleChange={registrationNr => onChange({ ...article, registrationNr })}
            spacing={'left'}
            ergoTask={ergoTask}
          />
        </IM.View>
      )}
      {status !== RdaStatus.None && (
        <IM.View style={IMLayout.flex.row}>
          {!isMedia && (
            <IM.View style={[{ width: viewWidth }, AppStyle.center]}>
              <RdaState rdaStatus={status} />
            </IM.View>
          )}
          <IM.View>
            <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
              {!rdaDoc && canCreateRda && <IM.Text>{i18n.t('RDA')}</IM.Text>}
              {!!rdaDoc && <IM.Text secondary>{rdaDoc}</IM.Text>}
              {!!docDate && <IM.Text secondary>{docDate}</IM.Text>}
              {!!rdaValue && !isSmallDevice && <IM.Text secondary>{rdaValue}</IM.Text>}
            </IM.View>
            {!!rdaValue && isSmallDevice && <IM.Text secondary>{rdaValue}</IM.Text>}
          </IM.View>
        </IM.View>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  CheckBoxView: {
    alignItems: 'center',
  },
  rdaView: {
    alignItems: 'center',
  },
})
