import { CardProps, IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import { ThemeColorExpanded, UnavailableTypeDescription } from '../../types'

export type UnavailableTypeDescriptionCardProps = {
  type: UnavailableTypeDescription
} & Pick<CardProps, 'style' | 'spacing' | 'onPress' | 'selected'>

export default function UnavailableTypeDescriptionCard({ type, selected, ...otherProps }: UnavailableTypeDescriptionCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  return (
    <IM.Card {...otherProps} head={<CardLeftTitle color={selected && theme.primary} />}>
      <IM.Text primary>{i18n.t(type.labelKey)}</IM.Text>
    </IM.Card>
  )
}
