import { IM, SpacingProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

interface Props {
  width?: number | string
  height?: number | string
  spacing?: SpacingProps
  color?: string
  style?: StyleProp<ViewStyle>
  count?: number
}

const inputHeight = 40
const textHeight = 16
const verticalSpacing = 16
export default function FormSkeleton({ spacing, color, style, count = 20 }: Props) {
  return (
    <IM.View spacing={spacing} style={[style]}>
      <IM.SkeletonContainer height={count * (inputHeight + textHeight + verticalSpacing)} width={'100%'} backgroundColor={color}>
        {Array.from({ length: count }).map((_, i) => (
          <InputSkeleton key={'Skeleton' + i.toString()} y={i * (inputHeight + textHeight + verticalSpacing)} />
        ))}
      </IM.SkeletonContainer>
    </IM.View>
  )
}

function InputSkeleton({ y }: { y: number }) {
  return (
    <>
      <IM.Rect x="0" y={y} rx="2" ry="2" width={120} height={textHeight - 5} />
      <IM.Rect x="0" y={y + textHeight} rx="5" ry="5" width={'100%'} height={inputHeight} />
    </>
  )
}
