import { RouteProp, useRoute } from '@react-navigation/native'
import React from 'react'

import { Screen } from '../../components/screen/Screen'
import { RootStackParamList } from '../../navigation/types'
import ReportPdfView from '../../views/reportPDF/ReportPdfView'

export default function ActivityClosingPdfPreviewScreen() {
  const route = useRoute<RouteProp<RootStackParamList, 'ReportPdf'>>()

  return (
    <Screen>
      <Screen.Content>
        <ReportPdfView {...route.params} />
      </Screen.Content>
    </Screen>
  )
}
