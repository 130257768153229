import { Language, useLanguage } from '@infominds/react-native-components'
import { useMemo } from 'react'

import { LanguageSelectType } from '../types'
import { objectUtils } from '../utils/objectUtils'

type Options = {
  dontFallBackToOtherLanguageIfEmpty?: boolean
}

export default function useLanguageSelect<T = string | undefined>(values?: LanguageSelectType<T>, options?: Options) {
  const { language } = useLanguage()

  const value = useMemo(() => languageSelect(values, options), [values, language])

  function getKeys(selectValues: LanguageSelectType<T>) {
    // sort them to make sure every time the same key gets taken
    return Object.keys(selectValues).sort()
  }

  function languageSelect(selectValues: LanguageSelectType<T> | undefined, selectorOptions?: Options) {
    if (!selectValues) return undefined
    const foundKey = getKeys(selectValues).find(key => key === language) as Language | undefined
    if (!foundKey) return undefined
    const selectedLanguageValue = selectValues[foundKey]
    if (selectedLanguageValue || !!selectorOptions?.dontFallBackToOtherLanguageIfEmpty) return selectedLanguageValue
    const foundKeyWithValue = getKeys(selectValues).find(key => objectUtils.validateProperty(selectValues[key as Language])) as Language | undefined
    if (foundKeyWithValue) return selectValues[foundKeyWithValue]
    return undefined
  }

  return { value, languageSelect }
}
