import { useTheme } from '@infominds/react-native-components'
import React, { createContext, memo, PropsWithChildren, useEffect, useState } from 'react'

import { ThemeColorExpanded } from '../../../../types'
import { BaseTextInputProps } from '../BaseTextInput'

interface BaseTextInputContextProps {
  focus: boolean
  loading: boolean
  editable: boolean
  borderColor: string
  backgroundColor: string
  onClickEffect: (focusEff: boolean) => void
}

const BaseTextInputContext = createContext<BaseTextInputContextProps | undefined>(undefined)

export interface BaseTextInputProviderProps {
  error?: boolean
  loading?: boolean
  disableFocus?: boolean
  onBlur?: () => void
}

export const BaseTextInputProvider = memo(function BaseTextInputProvider({
  children,
  editable = true,
  error = false,
  loading = false,
  disableFocus = false,
  onBlur,
}: PropsWithChildren<BaseTextInputProviderProps & Pick<BaseTextInputProps, 'editable'>>) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    setFocus(false)
  }, [])

  useEffect(() => {
    focus === false && onBlur?.()
  }, [focus])

  useEffect(() => {
    editable === false && onClickEffect(false)
  }, [editable])

  function onClickEffect(focusEff: boolean) {
    if (focusEff === false) setFocus(false)

    disableFocus === false && editable && loading === false && setFocus(focusEff)
  }

  const borderColor = error
    ? theme.inputBox.border.error
    : focus
      ? theme.general.info
      : editable
        ? theme.inputBox.border.active
        : theme.inputBox.border.disabled

  const backgroundColor = editable ? theme.inputBox.background.active : theme.inputBox.background.disabled

  return (
    <BaseTextInputContext.Provider
      value={{
        focus,
        loading,
        editable,
        borderColor,
        backgroundColor,
        onClickEffect,
      }}>
      {children}
    </BaseTextInputContext.Provider>
  )
})

export default BaseTextInputContext
