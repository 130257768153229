import { DefaultTheme, IM, IMLayout, IMStyle, ViewProps } from '@infominds/react-native-components'
import { darken } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { NativeSyntheticEvent, Platform, StyleSheet, TextInput, TextInputEndEditingEventData } from 'react-native'

import PressableIcon from '../../components/Infominds/PressableIcon'
import { AppStyle } from '../../constants/Styles'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useLayout from '../../hooks/useLayout'
import { numberUtils } from '../../utils/NumberUtils'

export type CardNumberInputProps = {
  value?: number
  onChangeValue: (changedValue: number) => void
  onIncrement: (Value: 1 | -1) => void
  minValue?: number
  disabled?: boolean
} & Pick<ViewProps, 'spacing' | 'style'>

const IconSize = 14
const disabledColorPlus = darken(IMStyle.palette.tint, 0.3)
const disabledColorMinus = darken(DefaultTheme.light.error, 0.3)
export default function CardNumberInput({ value, onChangeValue, onIncrement, style, minValue, disabled, ...viewProps }: CardNumberInputProps) {
  const { theme } = useExtendedTheme()
  const { isSmallDevice, layoutSelect } = useLayout()

  const plusButtonDisabled = useMemo(() => disabled, [disabled])
  const minusButtonDisabled = useMemo(
    () => disabled || (minValue !== undefined && (value === undefined || value <= minValue)),
    [value, minValue, disabled]
  )

  return (
    <IM.View
      style={[
        IMLayout.flex.row,
        styles.main,
        { borderColor: theme.inputBox.border.active, backgroundColor: theme.inputBox.background.active },
        style,
      ]}
      {...viewProps}>
      <IM.View style={[IMLayout.flex.row, AppStyle.center]}>
        <NumberInput value={value} onEndEditing={onChangeValue} minValue={minValue} disabled={disabled} />
      </IM.View>
      <IM.View style={[!isSmallDevice && IMLayout.flex.row]}>
        <PressableIcon
          hitSlop={undefined}
          icon="plus"
          size={IconSize}
          color="white"
          disabled={plusButtonDisabled}
          iconStyle={styles.icon}
          pressableStyle={styles.pressable}
          style={[
            { backgroundColor: plusButtonDisabled ? disabledColorPlus : theme.general.info },
            isSmallDevice && [IMLayout.flex.f1, styles.plusIconSmallDevice],
          ]}
          onPress={() => {
            onIncrement(1)
          }}
        />
        <PressableIcon
          hitSlop={undefined}
          icon="minus"
          size={IconSize}
          color="white"
          iconStyle={styles.icon}
          pressableStyle={styles.pressable}
          disabled={minusButtonDisabled}
          style={[
            { backgroundColor: minusButtonDisabled ? disabledColorMinus : theme.general.error },
            layoutSelect({ large: [styles.minusIconLargeDevice], small: [IMLayout.flex.f1, styles.minusIconSmallDevice] }),
          ]}
          onPress={() => {
            onIncrement(-1)
          }}
        />
      </IM.View>
    </IM.View>
  )
}

type NumberInputProps = {
  value: number | undefined
  onChangeValue?: (value: number) => void
  onEndEditing?: (value: number) => void
  minValue?: number
  disabled?: boolean
}
export function NumberInput({ value, onChangeValue, onEndEditing, minValue, disabled }: NumberInputProps) {
  const [internalValue, setInternalValue] = useState(value?.toString() ?? '')
  const displayedText = useMemo(() => internalValue.replace('.', ','), [internalValue])
  const { theme } = useExtendedTheme()

  useEffect(() => {
    setInternalValue(value?.toString() ?? '')
  }, [value])

  function parseText(text: string) {
    const parsedValue = numberUtils.parseFloatFromText(text)
    if (parsedValue === undefined || Number.isNaN(parsedValue)) return null
    return parsedValue
  }

  function handleChangeText(text: string) {
    setInternalValue(text)
    const parsedValue = parseText(text)
    if (parsedValue === null) return
    onChangeValue?.(parsedValue)
  }

  function handleEndEditing(e: NativeSyntheticEvent<TextInputEndEditingEventData>) {
    let pValue = parseText(e.nativeEvent.text) ?? 0
    if (minValue !== undefined) pValue = Math.max(pValue, minValue)
    onChangeValue?.(pValue)
    onEndEditing?.(pValue)
  }

  return (
    <TextInput
      value={displayedText}
      onChangeText={handleChangeText}
      onEndEditing={Platform.OS !== 'web' ? handleEndEditing : undefined}
      onBlur={Platform.OS === 'web' ? handleEndEditing : undefined}
      style={[
        {
          color: disabled ? theme.textPlaceholder : theme.text,
          backgroundColor: theme.inputBox.background.active,
          borderLeftColor: theme.inputBox.border.active,
        },
        styles.input,
      ]}
      inputMode="decimal"
      editable={!disabled}
    />
  )
}

const styles = StyleSheet.create({
  main: { minHeight: 50, borderTopRightRadius: IMLayout.borderRadius, borderBottomRightRadius: IMLayout.borderRadius },

  input: {
    minWidth: 50,
    maxWidth: 80,
    textAlign: 'center',
    borderLeftWidth: 1,
    height: '100%',
  },
  pressable: { borderRadius: 0, flex: 1 },
  icon: {
    marginHorizontal: 2,
  },
  plusIconSmallDevice: {
    borderTopRightRadius: IMLayout.borderRadius,
  },
  minusIconSmallDevice: {
    borderBottomRightRadius: IMLayout.borderRadius,
  },
  minusIconLargeDevice: {
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
  },
})
