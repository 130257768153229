import { IM, SpacingProps, useLanguage } from '@infominds/react-native-components'
import { StackActions, useNavigation } from '@react-navigation/native'
import React, { useContext, useEffect } from 'react'
import { Platform, StyleProp, ViewStyle } from 'react-native'

import { api } from '../../../apis/apiCalls'
import { Activity, ActivityState } from '../../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import { MasterDetailContext } from '../../../components/MasterDetail/contexts/MasterDetailContext'
import CardSkeleton from '../../../components/skeleton/CardSkeleton'
import { activityUtils } from '../../../utils/ActivityUtils'
import ActivityListCard from '../../activityList/ActivityListCard'

interface Props {
  activity?: Activity
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

export default function FollowupActivityCard({ activity, ...props }: Props) {
  const navigation = useNavigation()
  const masterDetailContext = useContext(MasterDetailContext)
  const { item: followupActivity, loadItem: loadFollowupActivity, loading: loadingFollowUpActivity } = useControlledLoader(api.activities.getFollowup)
  const { i18n } = useLanguage()
  useEffect(() => {
    if (!activity || activity.state !== ActivityState.ClosedWithInterventionConnected) return
    loadFollowupActivity({
      srvActivityId: activity.srvActivityId,
      srvActivityTypeId: activity.srvActivityTypeId,
      srvActivityYear: activity.srvActivityYear,
    })
  }, [activity])

  function handleCardPressed() {
    if (!followupActivity) return
    if (activityUtils.isActivityClosed(followupActivity)) {
      if (masterDetailContext?.enableMDLayout) {
        masterDetailContext.setDetailParams(followupActivity)
      } else {
        navigation.dispatch(
          StackActions.replace('Detail', {
            srvActivityId: followupActivity.srvActivityId,
            srvActivityTypeId: followupActivity.srvActivityTypeId,
            srvActivityYear: followupActivity.srvActivityYear,
            title: followupActivity.title,
          })
        )
      }
    } else {
      navigation.navigate('ActivityDetailStack', {
        screen: 'ActivityDetail',
        params: {
          srvActivityId: followupActivity.srvActivityId,
          srvActivityTypeId: followupActivity.srvActivityTypeId,
          srvActivityYear: followupActivity.srvActivityYear,
          title: followupActivity.title,
          source: 'HistoryStack',
        },
      })
    }
  }

  if (activity?.state !== ActivityState.ClosedWithInterventionConnected) return <></>

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('FOLLOWUP_ACTIVITY')} />} noContentSpacing {...props}>
      {!!loadingFollowUpActivity && (
        <IM.View spacing={'all'}>
          <CardSkeleton />
        </IM.View>
      )}
      {!!followupActivity && (
        <ActivityListCard
          activity={followupActivity}
          type="normal"
          borderless
          forceLayout={Platform.OS !== 'web' ? 'small' : undefined}
          onPress={handleCardPressed}
        />
      )}
    </IM.Card>
  )
}
