import { ScannerModal } from '@infominds/react-native-barcode-scanner'
import { IM, IMLayout, IMStyle, useLanguage, useOrientation, useTheme } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import Color from 'color'
import React, { useRef, useState } from 'react'
import { Keyboard, Platform, TextInput } from 'react-native'
import { OrientationType } from 'react-native-orientation-locker'

import CONSTANTS from '../../../../constants/Constants'
import useLayout, { ForceLayoutType } from '../../../../hooks/useLayout'
import { ThemeColorExpanded } from '../../../../types'
import { utils } from '../../../../utils/utils'
import useFocus from '../../hooks/useFocus'
import useSearch from '../../hooks/useSearch'

export interface DefaultSearchDetailHeaderProps {
  enableQR?: boolean
  focus?: boolean
  disableAutofocus?: boolean
  deferredTimeout?: number
  forceLayout?: ForceLayoutType
}

export default function DefaultSearchDetailHeader({
  deferredTimeout,
  focus,
  enableQR = false,
  disableAutofocus = false,
  forceLayout,
}: DefaultSearchDetailHeaderProps) {
  const { i18n, language } = useLanguage()
  const { search, setSearch, onSubmit } = useSearch()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const { landscape } = useOrientation()
  const isFocused = useIsFocused()

  const searchBoxRef = useRef<TextInput | null>(null)

  const [key, setKey] = useState('')
  const [showScannerModal, setShowScannerModal] = useState(false)

  useFocus({ ref: searchBoxRef, focus: !!focus && !!isFocused, disable: disableAutofocus })

  function handleBarCodeScanned(barCodes: string[]) {
    Keyboard.dismiss()
    setSearch(barCodes[0], true)
    setKey(utils.generateUuid())
    setShowScannerModal(false)
  }

  return (
    <>
      <IM.SearchBox
        key={key}
        innerRef={searchBoxRef}
        initialValue={search}
        placeholder={i18n.t('SEARCH_PLACEHOLDER')}
        onEnterEnd={setSearch}
        backgroundColor={!isSmallDevice ? theme.header.detail.background : colorScheme === 'light' ? undefined : theme.inputBox.background.active}
        textColor={!isSmallDevice ? theme.header.main.text.primary : undefined}
        placeholderTextColor={colorScheme === 'dark' ? theme.textPlaceholder : Color(IMStyle.palette.white).darken(0.26).toString()}
        containerStyle={
          isSmallDevice
            ? IMLayout.flex.f1
            : {
                width: landscape ? CONSTANTS.landscapeTabletMaxSearchBar : CONSTANTS.portraitTabletMaxSearchBar,
              }
        }
        inputMode="search"
        icon={['fal', 'qrcode']}
        onIconPress={() => {
          setShowScannerModal(true)
        }}
        hideIcon={Platform.OS === 'android' || Platform.OS === 'ios' ? !enableQR : true}
        deferredTimeout={deferredTimeout}
        hideBorder
        autoCapitalize="none"
        keyboardAppearance={colorScheme}
        onSubmitEditing={onSubmit}
      />
      <ScannerModal
        isVisible={showScannerModal}
        languageCode={language}
        onClose={() => setShowScannerModal(false)}
        onScanned={handleBarCodeScanned}
        scannerPostCloseOrientation={isSmallDevice ? OrientationType.PORTRAIT : undefined}
      />
    </>
  )
}
