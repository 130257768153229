import { DateUtils, useEvent, Utils } from '@infominds/react-native-components'
import { isSameDay, startOfDay } from 'date-fns'
import { useContext, useEffect, useMemo } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { ActivityDetailContext } from '../../contexts/activity/ActivityDetailContext'
import { GroupedActivityTravel } from '../../types'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import TimeUtils from '../../utils/TimeUtils'
import useObjectUtils from '../useObjectUtils'

export default function useActivityTravels(activity: Activity | null | undefined, dontLoadOnMount?: boolean) {
  const activityUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const {
    item: travels,
    loadItem: loadTravels,
    loading: loadingTravels,
    setItem: setTravels,
    setLoading,
  } = useControlledLoader(api.activities.travels.getList, { backgroundUpdaterInterval: CONSTANTS.backgroundUpdateInterval })

  useEvent({ key: EVENT_KEYS.ACTIVITY_TRAVELS_UPDATED }, refreshTravels)
  useEffect(() => {
    if (dontLoadOnMount) {
      setLoading(false)
      return
    }
    refreshTravels()
  }, [activity])

  const groupedTravels = useMemo(() => {
    const grouped = Utils.keepUniques(travels ?? [], q => `${q.travelDate ? startOfDay(DateUtils.dateify(q.travelDate)).toISOString() : ''}`)
      .filter(q => !!q.travelDate)
      .map<GroupedActivityTravel>(element => ({ date: element.travelDate ?? '', travels: [] }))
    grouped.forEach(item => {
      item.travels =
        travels
          ?.filter(t => !!t.travelDate && isSameDay(DateUtils.dateify(item.date), DateUtils.dateify(t.travelDate)))
          .sort((a, b) => EmployeeUtils.sorterFun(a.technician, b.technician)) ?? []
    })
    return TimeUtils.sortDate(grouped, 'date', 'desc')
  }, [travels])

  function refreshTravels() {
    if (!activity) {
      setTravels([])
      return
    }
    loadTravels(activityUtils.createRequestObject(activity))
  }

  const detailContext = useContext(ActivityDetailContext) // using useContext instead of custom hook here to allow usage outside of context provider
  useEffect(() => detailContext?.setDataCount(prev => ({ ...prev, travels: travels?.length ?? 0 })), [travels])

  return { travels, groupedTravels, loadingTravels, refreshTravels }
}
