import { CardButton, IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'

import type { ConstructionSite } from '../../apis/types/apiResponseTypes'
import Separator from '../../components/Infominds/Separator'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import constructionSiteUtils from '../../utils/constructionSiteUtils'
import customerUtils from '../../utils/customerUtils'
import { utils } from '../../utils/utils'
import ActivityDetailConstructionSiteLottoLottoIdCard from '../activities/detail/ActivityDetailConstructionSiteLottoIdCard'
import LoaderCard, { LoadedCardProps } from '../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData' | 'buttons'> & {
  constructionSite: ConstructionSite | undefined | null
  showConstructionSiteLotto?: boolean
  disableIcon?: boolean
  lite?: boolean
  includeIdInTitle?: boolean
}

const ConstructionSiteCard = memo(function ConstructionSiteCard({
  constructionSite,
  showConstructionSiteLotto,
  disableIcon,
  enableChange,
  loading = false,
  includeIdInTitle,
  ...props
}: Props) {
  const alert = useAlert()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()
  const hasData = constructionSite?.objectId

  const inactive = false
  const buttons: CardButton[] = []

  const address = useMemo(() => customerUtils.formatAddress(constructionSite?.address, 'street'), [constructionSite])
  const location = useMemo(() => customerUtils.formatAddress(constructionSite?.address, 'location'), [constructionSite])

  const handleRoutePress = () => {
    if (constructionSite?.address?.latitude && constructionSite.address.longitude) {
      utils.openMaps(constructionSite?.address?.latitude, constructionSite.address.longitude, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
    } else {
      utils.openMapsWithQuery(address + ' ' + location, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
    }
  }

  if (address !== '' && location !== '') {
    buttons.push({
      icon: ['fal', 'route'],
      ...appUtils.getCardButtonColor(!inactive, theme),
      overrideDisabled: true,
      onPress: inactive ? undefined : handleRoutePress,
    })
  }

  const LottoSubComponent = useMemo(
    () =>
      !!showConstructionSiteLotto &&
      !!hasData && (
        <IM.View>
          <Separator />
          <ActivityDetailConstructionSiteLottoLottoIdCard changeable={enableChange} />
        </IM.View>
      ),
    [enableChange, showConstructionSiteLotto, hasData]
  )

  const title = useMemo(
    () => (includeIdInTitle ? constructionSiteUtils.getTitle(constructionSite) : constructionSite?.description ?? constructionSite?.objectId),
    [constructionSite]
  )

  return (
    <LoaderCard
      noDataText={i18n.t('NO_CONSTRUCTION_SITE_SELECTED')}
      hasData={!!constructionSite?.objectId}
      loading={loading}
      subComponent={LottoSubComponent}
      enableChange={enableChange}
      {...props}>
      <TextWithIcon
        secondary={inactive}
        icon={disableIcon ? undefined : ['fal', 'hard-hat']}
        iconSize={20}
        style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }]}>
        {title}
      </TextWithIcon>
      {address !== '' && (
        <IM.View style={IMLayout.flex.f1} spacing="top">
          <IM.Text secondary={inactive}>{customerUtils.formatAddress(constructionSite?.address, 'street')}</IM.Text>
          <IM.Text secondary={inactive}>{customerUtils.formatAddress(constructionSite?.address, 'location')}</IM.Text>
        </IM.View>
      )}
    </LoaderCard>
  )
})

export default ConstructionSiteCard
