import { Utils } from '@infominds/react-native-components'

import { DataProviderCore } from '../DataProviderCore'
import { DataStorage } from '../DataStorage'
import { AssignedIdPair, DataProviderOptions, DataProviderStateActions, PendingRequest, RequestType } from '../types'
import DataProviderUtils from './DataProviderUtils'

const pendingRequestsResourceKey = 'DataProviderPendingRequests'
const idParisStorageKey = 'DataProviderSyncIdPairs'

export class DataProviderSyncManager {
  private dataStorage: DataStorage
  private core: DataProviderCore
  private upSyncWaitList: ((value: boolean) => void)[] = []
  public static PendingRequestsResourceKey = pendingRequestsResourceKey

  private autoSyncTried = false
  private upSyncBusy = false

  constructor(dataProvider: DataProviderCore, dataStorage: DataStorage) {
    this.core = dataProvider
    this.dataStorage = dataStorage
  }

  public Init() {
    this.dataStorage.RegisterResource(pendingRequestsResourceKey, { id: ['id'] })
    this.dataStorage.RegisterResource(idParisStorageKey, { id: ['id'] })
  }

  public GetPendingRequests(id?: string) {
    return this.dataStorage.Get<PendingRequest>(pendingRequestsResourceKey, id ? { id } : {}, result => result.sort({ timestamp: 1 }))
  }

  public async PendingRequestsExits() {
    const pendingRequestsCount = await this.dataStorage.Count(pendingRequestsResourceKey)
    this.core.DispatchState(DataProviderStateActions.UpdateDataToSync, { pendingDataToSync: pendingRequestsCount })

    if (pendingRequestsCount > 0) {
      return true
    }

    this.core.DispatchState(DataProviderStateActions.ClearError)
    return false
  }

  /**
   * Checks whether any pending requests are yet to be synced
   */
  public async CheckPendingUpSync() {
    //if upSync is not already active and there are no pending requests then return true
    if (!this.upSyncBusy && !(await this.PendingRequestsExits())) return true
    //if upSync is already active wait for it to be done and return the result
    if (this.upSyncBusy) return await this.WaitForUpSyncDone()

    if (!this.core.options.autoSync || !this.core.state.isOnline || this.autoSyncTried) return false
    try {
      this.autoSyncTried = true
      await this.ProcessPendingRequests(true)
      return !(await this.PendingRequestsExits())
    } catch (exception) {
      exception
    }
    return false
  }

  private WaitForUpSyncDone() {
    return new Promise<boolean>(resolve => {
      if (!this.upSyncBusy) {
        resolve(true)
        return
      }
      this.upSyncWaitList.push(resolve)
    })
  }

  public async AddRequestToPendingRequests<TData extends object, TGetRequest = void>(
    type: RequestType,
    resource: string,
    apiResource: string,
    payload: TData,
    options?: DataProviderOptions<TData, TGetRequest>
  ) {
    try {
      this.core.SetOnline(false)
      await this.dataStorage.Create(pendingRequestsResourceKey, [
        {
          id: Utils.getUid(),
          type,
          timestamp: Date.now(),
          resource,
          apiResource,
          payload,
          payloadId: options?.id_provider?.(payload),
          options: { noLocalStorage: options?.post?.noLocalStorage },
        } as PendingRequest,
      ])
    } catch (exception) {
      console.error('DataProvider: AddRequestToStack Error', exception)
    }
  }

  public async ProcessPendingRequests(auto?: boolean, requestsToProcess?: PendingRequest[]) {
    if (this.upSyncBusy) {
      await this.WaitForUpSyncDone()
    }
    let upSyncResult = false
    try {
      this.upSyncBusy = true
      let requests = await this.GetPendingRequests()
      if (requestsToProcess) requests = requests.filter(r => requestsToProcess.find(rtp => rtp.id === r.id))

      const failedRequests: PendingRequest[] = []
      const changedResources: string[] = []
      for (const request of requests) {
        const result = await this.ProcessPendingRequest(request)
        if (!changedResources.includes(request.resource)) changedResources.push(request.resource)
        if (!result.ok) failedRequests.push({ ...request, error: result.error })
      }
      if (failedRequests.length) {
        if (auto && !requestsToProcess?.length) this.core.DispatchState(DataProviderStateActions.UpSyncFailed, { failedRequests })
      } else if (!(await this.PendingRequestsExits())) {
        await this.CheckDBForRemnants(changedResources)
        await this.dataStorage.DeleteAll(idParisStorageKey)

        this.autoSyncTried = false
        upSyncResult = true
      }
    } catch (exception) {
      console.error('Error at ProcessPendingRequests()', exception)
    } finally {
      this.upSyncBusy = false
      this.ResolveWailList(upSyncResult)
    }
    return upSyncResult
  }

  private async ProcessPendingRequest<T extends object = object>(request: PendingRequest) {
    try {
      const idPairs = await this.dataStorage.Get<AssignedIdPair>(idParisStorageKey)
      const payload = DataProviderUtils.replaceTemporaryIds(
        request.payload,
        idPairs,
        this.core.tempIdIdentifier,
        this.core.tempIdIdentifierNumber
      ) as T
      const resourceIds = this.dataStorage.GetStorageByKey(request.resource)?.dataIds
      if (!resourceIds?.length) throw new Error(`Resource '${request.resource}' has no valid id configuration`)
      const payloadId = DataProviderUtils.createIdFrom(payload, resourceIds as never)

      if (DataProviderCore.debug) {
        console.debug('Processing pending request', request.type, request.resource, request.apiResource)
      }
      const options: DataProviderOptions<T> = { id: resourceIds as (keyof T)[] }
      const apiResult = (await this.core.ApiSend<T>(request.type, request.apiResource, payload, options)) as object | string | number
      //update local db entry
      if (!request.options?.noLocalStorage) {
        // parse ids from api result and
        if (payloadId) {
          const resultId = DataProviderUtils.createIdFrom(apiResult, resourceIds as never)
          if (resultId) {
            await this.dataStorage.UpdateEntry(request.resource, payloadId, { ...payload, ...resultId })
            // create tempId - erpId pair entry if id was temp-id
            for (const payloadIdKey of Object.keys(payloadId)) {
              if (DataProviderUtils.idIsTemporaryId(payloadId[payloadIdKey], this.core.tempIdIdentifier, this.core.tempIdIdentifierNumber)) {
                const payloadKeyValue = request.payload[payloadIdKey as keyof object]
                if (payloadKeyValue) {
                  await this.dataStorage.Create(idParisStorageKey, [{ id: payloadKeyValue, erpId: resultId[payloadIdKey] } as AssignedIdPair])
                }
              }
            }
          }
        }

        // delete pending request from db
        await this.dataStorage.Delete(pendingRequestsResourceKey, [request])
      }

      return { ok: true }
    } catch (exception) {
      console.error('DataProvider: ProcessPendingRequest() Error', request.type, request.resource, exception)

      const error = DataProviderUtils.extractErrorMessageFromException(exception)
      try {
        await this.dataStorage.Update(pendingRequestsResourceKey, [{ id: request.id, error }], 'patch')
      } catch (updateException) {
        console.error('ProcessRequestStack Error updating failed pendingRequest', updateException)
      }
      return { ok: false, error }
    }
  }

  private async CheckDBForRemnants(resourcesToCheck: string[]) {
    for (const resourceKey of resourcesToCheck) {
      try {
        const data = await this.core.dataStorage.Get<object>(resourceKey, { id: new RegExp(this.core.tempIdIdentifier) })
        if (!data.length) continue
        console.warn(
          'DataProviderSyncManager: Deleting',
          data.length,
          'remnants from',
          resourceKey,
          '! This should not happen and probably indicating a bug'
        )
        await this.core.dataStorage.Delete(resourceKey, data)
      } catch (_ignore) {
        continue
      }
    }
  }

  private ResolveWailList(result: boolean) {
    if (!this.upSyncWaitList?.length) return
    this.upSyncWaitList.forEach(callback => callback(result))
  }

  public async DeleteFailedPendingRequest(requestToDelete: PendingRequest) {
    const requests = await this.GetPendingRequests(requestToDelete.id)
    if (!requests?.length) return
    // TODO send deleted request to api (or alternatively store it on device) to ensure no data gets lost
    await this.dataStorage.Delete(pendingRequestsResourceKey, [...requests])
    //called to update states
    await this.PendingRequestsExits()
  }

  /**
   * Init Synchronization, before calling Api
   */
  public async InitSync(reSync?: boolean) {
    if (await this.PendingRequestsExits()) {
      throw new Error('Before Sync can be done any pending requests need to be either processed or deleted')
    }
    if (reSync) {
      await this.dataStorage.DeleteAll()
    }
  }

  /**
   * Returns info for given resource
   * @returns count of records and size in bytes
   */
  public async GetStorageInfo(syncType: string) {
    const resource = this.core.resources.GetAll().find(r => !!r.syncType && Utils.compareStrings(r.syncType, syncType))
    if (!resource) throw new Error(`DataProviderSyncManager GetStorageInfo(): No resource was found with the syncType '${syncType}'`)
    const count = await this.dataStorage.Count(resource.id)
    if (count <= 0) return { count, size: 0 }
    const size = await this.dataStorage.GetStorageSize(resource.id)
    return { count, size }
  }
}
