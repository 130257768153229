import { IM, useLanguage } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'

import { apiDtoIds } from '../../../apis/apiCalls'
import { Activity, ActivityTime, ActivityType } from '../../../apis/types/apiResponseTypes'
import useObjectUtils from '../../../hooks/useObjectUtils'
import { GroupedActivityTime } from '../../../types'
import { EmployeeUtils } from '../../../utils/EmployeeUtils'
import TimeUtils from '../../../utils/TimeUtils'
import Separator from '../../Infominds/Separator'
import ActivityTimeRecord from './ActivityTimeRecord'

export type GroupedActivityTimeProps = {
  activity: Activity
  time: GroupedActivityTime
  separator?: boolean
  activityTypes?: ActivityType[]
  onEdit: (time: ActivityTime) => void
  enableChange?: boolean
}

function GroupedActivityTimeView({ separator, ...props }: GroupedActivityTimeProps) {
  const activityTimeObjectUtils = useObjectUtils<ActivityTime>(apiDtoIds.activityTimes)
  const time = props.time
  const { language } = useLanguage()
  const date = useMemo(() => !!time.date && TimeUtils.format(time.date, language), [language, time.date])

  return (
    <IM.View>
      {separator && <Separator />}
      {!!date && (
        <IM.View spacing={'horizontal'}>
          <IM.Text primary>{date}</IM.Text>
        </IM.View>
      )}
      {time.times.map((timeRecord, index) => (
        <RenderItem key={activityTimeObjectUtils.createId(timeRecord)} timeRecord={timeRecord} index={index} {...props} />
      ))}
    </IM.View>
  )
}

type RenderItemProps = {
  timeRecord: ActivityTime
  index: number
  enableChange?: boolean
} & Pick<GroupedActivityTimeProps, 'time' | 'onEdit' | 'activityTypes' | 'activity'>
function RenderItem({ timeRecord, index, time, onEdit, activityTypes, activity, enableChange }: RenderItemProps) {
  const prevItem = useMemo(() => index > 0 && time.times[index - 1], [index, time])
  const sameTechnicianAsPrev = useMemo(
    () => index > 0 && EmployeeUtils.compare(timeRecord.technician, time.times[index - 1].technician),
    [prevItem, timeRecord]
  )
  const sameTypeAsPrev = useMemo(
    () => prevItem && prevItem.activityTimeSrvActivityTypeId === timeRecord.activityTimeSrvActivityTypeId,
    [prevItem, timeRecord]
  )
  const durationMode = useMemo(() => !timeRecord.startTime || !timeRecord.stopTime, [prevItem, timeRecord])

  return (
    <ActivityTimeRecord
      onEdit={() => onEdit(timeRecord)}
      time={timeRecord}
      activityTypes={activityTypes}
      showType={timeRecord.activityTimeSrvActivityTypeId !== activity.srvActivityTypeId}
      spacing={!sameTechnicianAsPrev ? 'top' : undefined}
      showEmployee={index === 0 || !sameTechnicianAsPrev || !sameTypeAsPrev || durationMode}
      enableChange={enableChange}
    />
  )
}

export default memo(GroupedActivityTimeView)
