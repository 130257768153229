import { IM, IMLayout, useEvent, useLanguage, useModalController, useTheme, Utils } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import { api, apiDtoIds } from '../../../apis/apiCalls'
import { Activity, Contract } from '../../../apis/types/apiResponseTypes'
import ActivityListCard from '../../../cards/activityList/ActivityListCard'
import ApiDeleteButton from '../../../components/ApiDeleteButton'
import Button from '../../../components/Button'
import ListDataView from '../../../components/ListDataView'
import { EVENT_KEYS } from '../../../constants/EventKeys'
import { AppStyle } from '../../../constants/Styles'
import useLayout from '../../../hooks/useLayout'
import useObjectUtils from '../../../hooks/useObjectUtils'
import useUnsavedChangesAlert from '../../../hooks/useUnsavedChangesAlert'
import ActivityCreationModal from '../../../modals/activity/ActivityCreationModal'
import { LoadingType, UploadStatus } from '../../../types'
import { ActivityCreateOrEditViewProps } from '../ActivityCreateOrEditView'

type ContractActivityListViewProps = {
  contract: Contract
  uploadState?: UploadStatus
  activities?: Activity[]
  loadingActivities?: LoadingType
}

export default function ContractActivityListView({ contract, uploadState, activities, loadingActivities }: ContractActivityListViewProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const objectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const navigation = useNavigation()
  const [deleteMode, setDeleteMode] = useState(false)
  const [activitiesToDelete, setActivitiesToDelete] = useState<Activity[]>([])

  const { emit: onActivitiesDeleted } = useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED })

  const { isSmallDevice } = useLayout()
  const unsavedChangesAlert = useUnsavedChangesAlert()
  const activityCreationModal = useModalController<ActivityCreateOrEditViewProps>()

  useEffect(() => {
    if (!deleteMode) return
  }, [deleteMode])

  function handleActivityPressed(activity: Activity) {
    if (isSmallDevice || uploadState === 'done') {
      navigateToActivityDetail(activity)
      return
    }
    unsavedChangesAlert
      .show()
      .then(result => {
        if (result !== 'discard') return
        navigateToActivityDetail(activity)
      })
      .catch(console.error)
  }

  function navigateToActivityDetail(activity: Activity) {
    navigation.navigate('ActivityDetailStack', {
      screen: 'ActivityDetail',
      params: { ...objectUtils.createRequestObject(activity), source: 'AnagraficsStack' },
    })
  }

  function handleAddActivity() {
    if (!contract) return
    activityCreationModal.show({ contract })
  }

  async function handleDeleteActivities() {
    return Promise.all(activitiesToDelete.map(act => api.activities.delete(objectUtils.createRequestObject(act))))
  }

  return (
    <IM.View>
      <IM.View spacing={'bottom'} style={[IMLayout.flex.row]}>
        <IM.View style={[IMLayout.flex.f1, styles.title]}>
          <IM.Text>{i18n.t('ACTIVITIES')}</IM.Text>
        </IM.View>
        {!deleteMode && (
          <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
            <Button title={i18n.t('ADD')} onPress={handleAddActivity} color={theme.primary} />
            {!!activities?.length && <Button title={i18n.t('DELETE')} onPress={() => setDeleteMode(true)} color={theme.error} />}
          </IM.View>
        )}
        {deleteMode && (
          <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
            <Button
              title={i18n.t('CANCEL')}
              onPress={() => {
                setDeleteMode(false)
                setActivitiesToDelete([])
              }}
              color={theme.primary}
            />
            <ApiDeleteButton
              data={activitiesToDelete}
              title={i18n.t('CONFIRM')}
              disabled={!activitiesToDelete.length}
              deleteAlertMessage={Utils.stringValueReplacer(i18n.t('DELETE_N_ACTIVITIES'), activitiesToDelete.length)}
              deleteRequest={handleDeleteActivities}
              onDeleted={() => {
                onActivitiesDeleted()
                setDeleteMode(false)
                setActivitiesToDelete([])
              }}
              deleteAlertTitle={i18n.t('DELETE')}
            />
          </IM.View>
        )}
      </IM.View>
      {deleteMode && (
        <IM.View style={AppStyle.center} spacing={'bottom'}>
          <IM.Text>{i18n.t('SELECT_ACTIVITIES_TO_DELETE')}</IM.Text>
        </IM.View>
      )}
      <ListDataView
        data={activities}
        loading={loadingActivities}
        spacingSkeleton={'bottom'}
        keyExtractor={objectUtils.createId}
        numberOfSkeletons={1}
        listSpacer={false}
        renderItem={activity => (
          <ActivityListCard
            activity={activity}
            forceLayout="small"
            type="normal"
            key={objectUtils.createId(activity)}
            assignable={false}
            spacing="bottom"
            onPress={() => {
              if (deleteMode) {
                setActivitiesToDelete(prev => {
                  const foundActivity = prev.find(p => objectUtils.compare(p, activity))
                  if (foundActivity) return prev.filter(p => p !== foundActivity)
                  return [...prev, activity]
                })
              } else {
                handleActivityPressed(activity)
              }
            }}
            onLongPress={() => {
              setDeleteMode(true)
              setActivitiesToDelete([activity])
            }}
            deleted={!!deleteMode && activitiesToDelete.includes(activity)}
          />
        )}
        noDataMessage={deleteMode ? i18n.t('NO_ACTIVITIES') : i18n.t('NO_ACTIVITIES_FOUND')}
      />
      <ActivityCreationModal controller={activityCreationModal} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  title: {
    justifyContent: 'center',
  },
})
