import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useLayout from '../../hooks/useLayout'
import useOnLayout from '../../hooks/useOnLayout'
import { ThemeColorExpanded } from '../../types'

export type AnagraficMenuCardProps = {
  title: string
  onPress: () => void
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  icon: IconProp
  selected?: boolean
  visible?: boolean
}

export default function AnagraficMenuCard({ title, onPress, spacing, style, icon, selected, visible = true }: AnagraficMenuCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useLayout()
  const { layout, onLayout } = useOnLayout()
  const width = useMemo(() => layout?.width ?? 100, [layout])

  if (!visible) return <></>

  if (Platform.OS === 'web' && !isSmallDevice) {
    return (
      <IM.View spacing={spacing} style={[style]} onLayout={onLayout}>
        <IM.Card onPress={onPress} head={<CardLeftTitle color={selected && theme.general.info} />}>
          <IM.TextWithIcon style={{ fontSize: IMStyle.typography.fontSizeRegular }} icon={icon}>
            {title}
          </IM.TextWithIcon>
        </IM.Card>
      </IM.View>
    )
  }

  return (
    <IM.View spacing={spacing} style={[style]} onLayout={onLayout}>
      <IM.CardBasic onPress={onPress}>
        <IM.View
          style={[
            { height: width, backgroundColor: theme.card.secondaryBackground, borderRadius: IMLayout.borderRadius },
            styles.header,
            IMLayout.shadow,
          ]}>
          <IM.Icon color={'#FFF'} size={Math.max(width * 0.5, 20)} icon={icon} />
        </IM.View>
        <IM.View style={styles.content}>
          <IM.Text style={styles.text}>{title}</IM.Text>
        </IM.View>
      </IM.CardBasic>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 5,
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
  },
})
