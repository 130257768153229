import { IM, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { Keyboard, StyleProp, TextStyle } from 'react-native'

import { TextInputRef } from '../../types'
import SaveableTextInput from './SaveableTextInput'
import { TextInputProps } from './TextInput'

export type NoteInputProps = {
  textStyle?: StyleProp<TextStyle>
  onSaveNote: (note: string) => void
  align?: 'left' | 'right'
} & Pick<ViewProps, 'spacing' | 'spacingType' | 'style' | 'onLayout'> &
  Omit<TextInputProps, 'style' | 'onChangeText' | 'onChange' | 'onLayout'>

export default function NoteInput({
  textStyle,
  spacing,
  spacingType,
  style,
  editable = false,
  onLayout,
  value: externalValue,
  onSaveNote,
  align,
  ...inputProps
}: NoteInputProps) {
  const { theme } = useTheme()

  const [value, setValue] = useState<string | undefined>(undefined)
  const textInputRef = useRef<TextInputRef | null>(null)

  useEffect(() => {
    setValue(externalValue)
  }, [externalValue])

  useEffect(() => {
    if (!editable) {
      textInputRef.current?.blur()
      Keyboard.dismiss()
    }
  }, [editable])

  return (
    <IM.View spacing={spacing} spacingType={spacingType} style={[style]} onLayout={onLayout}>
      <SaveableTextInput
        ref={textInputRef}
        style={[textStyle, !editable && { borderColor: theme.card.background, backgroundColor: theme.card.background }]}
        value={value}
        textAlign={align}
        editable={editable && !inputProps.loading}
        onChangeText={setValue}
        onSavePressed={() => onSaveNote(value ?? '')}
        onEndEditing={() => onSaveNote(value ?? '')}
        textStyle={{ textAlign: align }}
        {...inputProps}
      />
    </IM.View>
  )
}
