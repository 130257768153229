import React, { createContext, PropsWithChildren, useEffect, useRef, useState } from 'react'

import { platforms, pwaUtils } from './pwaUtils'

export const PWAInstallContext = createContext(Promise.reject)

const platform = pwaUtils.getPlatform()

type Props = {
  enableLogging: boolean
}

interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>
}

function PWAInstallProvider({ enableLogging, children }: PropsWithChildren<Props>) {
  //   const awaitingPromiseRef = useRef()
  const deferredprompt = useRef<BeforeInstallPromptEvent | null>(null)
  const [contextValue, setContextValue] = useState({
    supported: supported,
    isInstalled: isInstalled,
    pwaInstall: openDialog,
  })

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent)
    window.addEventListener('appinstalled', handleInstallEvent)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent)
      window.removeEventListener('appinstalled', handleInstallEvent)
    }
  }, [])

  function logger(message: string) {
    if (enableLogging) {
      console.log(message)
    }
  }

  function isInstalled() {
    //@ts-ignore: ok
    if (window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches) {
      logger('isInstalled: true. Already in standalone mode')
      return true
    }
    logger('isInstalled: false')
    return false
  }

  function supported() {
    if (deferredprompt.current != null && platform === platforms.NATIVE) {
      logger('supported: true - native platform')
      return true
    }
    if (platform !== platforms.NATIVE && platform !== platforms.OTHER) {
      logger('supported: true - manual support')
      return true
    }
    logger('supported: false')
    return false
  }

  function handleBeforeInstallPromptEvent(event: Event) {
    deferredprompt.current = event as BeforeInstallPromptEvent
    logger('beforeinstallprompt event fired and captured')
    setContextValue({
      supported: supported,
      isInstalled: isInstalled,
      pwaInstall: openDialog,
    })
  }

  function handleInstallEvent(_event: Event) {
    setContextValue({
      supported: supported,
      isInstalled: () => true,
      pwaInstall: openDialog,
    })
  }

  function openDialog() {
    return new Promise<void>((resolve, reject) => {
      deferredprompt.current
        ?.prompt()
        .then(() => deferredprompt.current?.userChoice)
        .then(choiceResult => {
          if (choiceResult?.outcome === 'accepted') {
            logger('PWA native installation successful')
            resolve()
          } else {
            reject()
            logger('User opted out by cancelling native installation')
          }
        })
        .catch(err => {
          console.error(err)
          reject()
        })
    })
  }

  return (
    //@ts-ignore: ok
    <PWAInstallContext.Provider value={contextValue as { supported: () => boolean; isInstalled: () => boolean; pwaInstall: () => Promise<void> }}>
      {children}
    </PWAInstallContext.Provider>
  )
}

export default PWAInstallProvider
