import { IM, IMLayout, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'

import { api } from '../apis/apiCalls'
import { TechnicianType } from '../apis/types/apiResponseTypes'
import { AppStyle } from '../constants/Styles'
import useExtendedTheme from '../hooks/useExtendedTheme'
import { ActivityPlanEmployeeModalProps } from '../modals/activity/ActivityPlanEmployeeModal'
import { PlannedActivityEmployee } from '../types'
import { imDateUtils } from '../utils/infominds/imDateUtils'
import TimeUtils from '../utils/TimeUtils'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import Pressable from './Infominds/Pressable'
import NavigationIcon from './NavigationIcon'
import TextTable from './TextTable'

const styles = StyleSheet.create({
  pressable: { padding: 0 },
  textWidth: {
    width: 130,
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  textView: {
    width: 50,
  },
})

type Props = {
  technicianId: number
  technicianType: TechnicianType
  planned: PlannedActivityEmployee
  disabled?: boolean
  disabledEmployee?: boolean
  editable?: boolean
  modalController: ModalController<ActivityPlanEmployeeModalProps>
  onUnavailable: (id: string, unavailable: boolean) => void
}

export default function PlanningRange({
  technicianId,
  technicianType,
  planned,
  disabled,
  disabledEmployee,
  editable,
  modalController,
  onUnavailable,
}: Props) {
  const { theme } = useExtendedTheme()
  const { i18n, language } = useLanguage()
  const { item: unavailables, loadItem: loadUnavailables, loading } = useControlledLoader(api.activities.unavailables.getList)
  const { item: activities, loadItem: loadActivities } = useControlledLoader(api.activities.employees.getPlanned)

  useEffect(() => {
    if (!planned.planDateFrom || !planned.planDateTo) return

    const toUTC = imDateUtils.toUTC(planned.planDateTo)
    const fromUTC = imDateUtils.toUTC(planned.planDateFrom)

    if (toUTC && fromUTC) {
      loadUnavailables({
        planDateTo: toUTC,
        planDateFrom: fromUTC,
        technicianId: technicianId,
        technicianType: technicianType,
      })
      loadActivities({
        planDateTo: toUTC,
        planDateFrom: fromUTC,
        technicianId: technicianId,
        technicianType: technicianType,
      })
    }
  }, [planned.planDateFrom, planned.planDateTo])

  useEffect(() => {
    if (loading === false && unavailables !== undefined) {
      onUnavailable(planned.id, unavailables.length !== 0)
    }
  }, [loading, unavailables])

  const activitiesFiltered = activities?.filter(
    el =>
      !(
        el.srvActivityId === planned.srvActivityId &&
        el.srvActivityTypeId === planned.srvActivityTypeId &&
        el.srvActivityYear === planned.srvActivityYear
      )
  )
  const employeeHasUnavailable = unavailables === undefined ? false : unavailables.length !== 0
  const employeeHasActivity = activitiesFiltered === undefined ? false : activitiesFiltered.length !== 0
  const employeeUnavailable = employeeHasUnavailable || employeeHasActivity

  return (
    <Pressable
      key={planned.id}
      style={[IMLayout.flex.row, styles.pressable]}
      spacing="bottom"
      onPress={() => modalController.show({ ...planned })}
      disabled={disabled || disabledEmployee || !editable}>
      <IM.View style={IMLayout.flex.f1}>
        <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
          <IM.View style={styles.textView}>
            <IM.Text secondary>{i18n.t('START')}</IM.Text>
          </IM.View>
          <TextTable
            style={[IMLayout.flex.f1, styles.justifyStart]}
            textViewStyle={styles.textWidth}
            text={planned.planDateFrom ? TimeUtils.formatDateTime(planned.planDateFrom, language) : i18n.t('UNASSIGNED')}
            secondary={!planned.planDateFrom}
            position="top"
            indicatorColor={loading === false && employeeUnavailable ? theme.general.warn : undefined}
          />
        </IM.View>
        <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
          <IM.View style={styles.textView}>
            <IM.Text secondary>{i18n.t('END')}</IM.Text>
          </IM.View>
          <TextTable
            style={[IMLayout.flex.f1, styles.justifyStart]}
            textViewStyle={styles.textWidth}
            text={planned.planDateTo ? TimeUtils.formatDateTime(planned.planDateTo, language) : i18n.t('UNASSIGNED')}
            secondary={!planned.planDateTo}
            position="bottom"
            indicatorColor={loading === false && employeeUnavailable ? theme.general.warn : undefined}
          />
        </IM.View>
      </IM.View>
      {!disabled && !disabledEmployee && editable && (
        <IM.View style={AppStyle.center}>
          <NavigationIcon enableTableStyle />
        </IM.View>
      )}
    </Pressable>
  )
}
