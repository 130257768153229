import { IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { useContractCreation } from '../../contexts/activity/ContractCreationContext'
import ContractActivitySelector from '../../views/activities/contracts/ContractActivitySelector'
import { BaseTextInputProps } from '../input/baseTextInput/BaseTextInput'
import TextInput from '../input/TextInput'

export type ContractDeviceSelectorProps = Pick<BaseTextInputProps, 'required' | 'spacing' | 'editable' | 'style' | 'tabIndex'>
export function ContractDeviceSelector({ spacing, style, ...props }: ContractDeviceSelectorProps) {
  const { i18n } = useLanguage()
  const { state, handleDataChange, editMode, deviceActivity, setDeviceActivity, clearObjectLots } = useContractCreation()

  const shoActivitySelector = !!state?.device && !editMode

  return (
    <IM.View style={style} spacing={spacing}>
      <TextInput
        {...props}
        editable={!editMode}
        title={`${i18n.t('LOTTO_DEVICE')} *`}
        placeholder={i18n.t('LOTTO_DEVICE')}
        value={state?.device}
        onChangeText={value => {
          handleDataChange({ device: value })
          clearObjectLots()
        }}
        showClearTextIcon
        disableBorderRadius={shoActivitySelector ? 'bottom' : undefined}
      />
      {shoActivitySelector && (
        <ContractActivitySelector
          contractActivity={deviceActivity}
          onChange={activity => {
            setDeviceActivity(activity)
          }}
          noTitle
          disableBorderRadius={['top']}
          contract={state}
        />
      )}
    </IM.View>
  )
}
