import { IM, IMLayout, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import { FlatList, FlatListProps, Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, StyleSheet } from 'react-native'

import { InfiniteLoadingType, LoadingType } from '../types'
import Error from './Error'
import RefreshControl from './Infominds/RefreshControl'
import ListSpacer from './ListSpacer'
import NoEntry from './NoEntry'
import SkeletonCard from './skeleton/SkeletonCard'

export type FlatListDataProps<T> = Omit<FlatListProps<T>, 'ref' | 'onRefresh'> &
  Pick<FlatListProps<T>, 'ListHeaderComponent' | 'ListFooterComponent' | 'renderItem'> & {
    noDataMessage: string
    isSearching?: boolean
    loading?: LoadingType | InfiniteLoadingType
    spacingSkeleton?: SpacingProps
    closeEndThreshold?: number
    hideButtonId?: string
    refresh?: () => void
    listSpacer?: boolean | number
    onLoadMore?: () => void
    skeletonComponent?: ReactNode
    skeletonNumberLoading?: number
    skeletonNumberLoadingMore?: number
  }

function List<T>(
  {
    noDataMessage,
    loading = false,
    isSearching = false,
    refresh,
    spacingSkeleton,
    listSpacer = true,
    ListHeaderComponent,
    ListFooterComponent,
    onLoadMore,
    skeletonComponent,
    skeletonNumberLoading = 12,
    skeletonNumberLoadingMore = 3,
    ...others
  }: FlatListDataProps<T>,
  ref: ForwardedRef<FlatList<T>>
) {
  const { colorScheme } = useTheme()

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()
    others.onScroll?.(event)
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlatList
        {...others}
        ref={ref}
        scrollEnabled={loading !== 'reloading'}
        ListHeaderComponent={
          <>
            {!!listSpacer && <ListSpacer height={typeof listSpacer === 'number' ? listSpacer : undefined} />}
            {ListHeaderComponent}
            {loading === 'reloading' && (
              <IM.View>
                {Array(skeletonNumberLoading)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <IM.View spacing={spacingSkeleton ?? ['bottom']} style={{ marginHorizontal: IMLayout.horizontalMargin * 2 }} key={index}>
                        {skeletonComponent ?? <SkeletonCard />}
                      </IM.View>
                    )
                  })}
              </IM.View>
            )}
            <IM.View style={styles.container}>
              <>
                {loading === 'catched' && <Error />}
                {loading === false && others.data?.length === 0 && <>{others.data?.length === 0 && <NoEntry description={noDataMessage} />}</>}
              </>
            </IM.View>
          </>
        }
        ListFooterComponent={
          <>
            {loading === 'loadMore' &&
              Array(skeletonNumberLoadingMore)
                .fill(0)
                .map((_, index) => {
                  return (
                    <IM.View spacing={spacingSkeleton ?? ['bottom']} style={{ marginHorizontal: IMLayout.horizontalMargin * 2 }} key={index}>
                      {skeletonComponent ?? <SkeletonCard />}
                    </IM.View>
                  )
                })}
            {ListFooterComponent ?? <IM.View spacing="vertical" />}
          </>
        }
        refreshControl={!isSearching ? refresh ? <RefreshControl refreshing={false} onRefresh={refresh} /> : undefined : undefined}
        onScroll={handleScroll}
        onEndReachedThreshold={0.5}
        onEndReached={onLoadMore}
        indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
      />
    </IM.View>
  )
}

const FlatListData = forwardRef(List) as <T>(props: FlatListDataProps<T> & { ref?: ForwardedRef<FlatList<T>> }) => ReturnType<typeof List>

export default FlatListData

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
})
