import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { api } from '../../../apis/apiCalls'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import CustomerContactCard from '../../customer/CustomerContactCard'

interface Props {
  error?: ConstrainBoolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  changeable?: boolean
}

export default function ActivityDetailContactIdCard({ spacing, style, changeable }: Props) {
  const { i18n } = useLanguage()

  const { detail } = useActivityDetail()

  const { item: contact, loadItem: loadContact, loading: loadingContact, setItem: setContact } = useControlledLoader(api.customers.contacts.getDetail)

  useEffect(() => {
    load()
  }, [detail?.contactId, detail?.companyId])

  function load() {
    if (!detail?.companyId || !detail.contactId) {
      setContact(undefined)
      return
    }
    loadContact({ customerId: detail.companyId, contactId: detail.contactId })
  }

  return (
    <CustomerContactCard
      contact={contact}
      leftText={i18n.t('CONTACT')}
      disabled={!contact || !changeable}
      enableChange={changeable}
      loading={loadingContact}
      style={style}
      spacing={spacing}
    />
  )
}
