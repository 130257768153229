import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Contract } from '../../apis/types/apiResponseTypes'
import ChangeButton from '../../components/ChangeButton'
import ContractLoader from '../../components/ContractLoader'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import TimeUtils from '../../utils/TimeUtils'
import LoaderCard, { LoadedCardProps } from '../LoaderCard'

type Props = Omit<LoadedCardProps, 'noDataText' | 'hasData'> & { contract: Contract | undefined | null; disableIcon?: boolean; lite?: boolean }

export default function ContractCard({ contract, loading = false, enableChange, onChange, lite, ...props }: Props) {
  const { language, i18n } = useLanguage()

  const { value: title } = useLanguageSelect({ en: contract?.titleEn, it: contract?.titleIt, de: contract?.titleDe })
  const { value: note } = useLanguageSelect({ en: contract?.noteEn, it: contract?.noteIt, de: contract?.noteDe })

  const startDate = useMemo(() => !!contract?.startDate && TimeUtils.format(contract.startDate, language), [contract?.startDate, language])
  const expireDate = useMemo(() => !!contract?.expiredDate && TimeUtils.format(contract.expiredDate, language), [contract?.expiredDate, language])

  const liteMode = lite || !startDate || !expireDate

  return (
    <LoaderCard
      noDataText={i18n.t('NO_CONTRACT_SELECTED')}
      hasData={!!contract?.srvContractId}
      enableChange={enableChange && !contract?.srvContractId}
      loading={loading}
      onChange={onChange}
      {...props}>
      <IM.View style={IMLayout.flex.row}>
        <IM.View style={IMLayout.flex.f1}>
          <IM.View style={IMLayout.flex.row}>
            <IM.Text primary>{title}</IM.Text>
          </IM.View>
          {note && <IM.Text secondary>{note}</IM.Text>}
        </IM.View>
        {enableChange && <ChangeButton onPress={() => onChange?.()} />}
      </IM.View>
      {liteMode && (
        <IM.View spacing={'top'} style={IMLayout.flex.row}>
          <IM.TextWithIcon secondary icon={['fal', 'calendar']}>
            {startDate}
          </IM.TextWithIcon>

          <IM.View spacing={'horizontal'} />
          <IM.TextWithIcon secondary icon={['fal', 'hourglass-clock']}>
            {expireDate || '-'}
          </IM.TextWithIcon>
        </IM.View>
      )}
      {!liteMode && (
        <IM.View style={{ marginTop: 2 * IMLayout.horizontalMargin }}>
          <IM.View spacing="bottom" style={styles.dateContainer}>
            {contract?.startDate && (
              <IM.TextWithIcon secondary icon={['fal', 'calendar']}>
                {startDate}
              </IM.TextWithIcon>
            )}
            {contract?.expiredDate && (
              <IM.TextWithIcon secondary icon={['fal', 'hourglass-clock']}>
                {expireDate || '-'}
              </IM.TextWithIcon>
            )}
          </IM.View>
          <ContractLoader contract={contract} />
        </IM.View>
      )}
    </LoaderCard>
  )
}

const styles = StyleSheet.create({
  content: { flex: 1, justifyContent: 'space-between' },
  margin: { margin: 4 },
  dateContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
