import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'
import Datastore, { Cursor, MongoDocument, StorageStatic } from 'react-native-local-mongodb'

import { DataProviderCore } from './DataProviderCore'
import { DataProviderObjectLink, DataProviderStorageResource } from './types'
import DataProviderUtils from './utils/DataProviderUtils'
import { ResourceManager } from './utils/ResourceManager'

type ResourceStorage = {
  id: string
  resource: string
  store: Datastore
  links?: DataProviderStorageLink[]
  dataIds?: string[]
}

type DataProviderStorageLink = {
  key: string
  resource: ResourceStorage
}
const StorageProvider: StorageStatic = {
  getItem(key: string, callback?: (error?: Error, result?: string) => void) {
    return AsyncStorage.getItem(key, callback ? (error, result) => callback(error ?? undefined, result ?? undefined) : undefined)
  },
  setItem(key: string, value: string, callback?: (error?: Error) => void) {
    if (Platform.OS === 'web') return AsyncStorage.setItem(key, value)
    return AsyncStorage.setItem(key, value, callback ? error => callback(error ?? undefined) : undefined)
  },
  removeItem(key: string, callback?: (error?: Error) => void) {
    return AsyncStorage.removeItem(key, callback ? error => callback(error ?? undefined) : undefined)
  },
}

export class DataStorage {
  private resources = new ResourceManager<ResourceStorage>()

  private GetResourceStorageKey(resource: string) {
    return `DataHandlerResource#${resource}`
  }

  public GetStorageByKey(resourceKey: string) {
    return this.resources.Get(resourceKey)
  }

  public GetAllRegisteredResources() {
    return this.resources.GetAll()
  }

  public RegisterResource<TData>(resourceKey: string, options?: { subObjectLinks?: DataProviderObjectLink<TData>[]; id?: (keyof TData)[] }) {
    const existingStore = this.resources.Get(resourceKey)
    const links = options?.subObjectLinks
    if (existingStore?.store) {
      if (links) {
        this.RegisterResourceLinks(existingStore, links)
      }
      if (options) {
        if (!existingStore.dataIds) existingStore.dataIds = []
        existingStore.dataIds.push(...(options.id?.filter(id => !existingStore.dataIds?.includes(id as string)) as string[]))
      }
      return existingStore
    }
    const store = new Datastore({ filename: this.GetResourceStorageKey(resourceKey), storage: StorageProvider, autoload: true })
    // store.ensureIndex({ fieldName: 'id', unique: true })

    const newResource = this.resources.Register({
      id: resourceKey,
      resource: resourceKey,
      store,
      dataIds: (options?.id as string[]) ?? [],
    })
    if (links) {
      this.RegisterResourceLinks(newResource, links)
    }
    if (DataProviderCore.debug) console.debug('Registering DataProvider-Resource', resourceKey)
    return newResource
  }

  private RegisterResourceLinks<TData>(resource: ResourceStorage, links?: DataProviderObjectLink<TData>[]) {
    if (!links) return
    if (!resource.links) resource.links = []
    resource.links.push(
      ...links
        .filter(link => !resource.links?.find(resourceLink => resourceLink.key === link.key))
        .map(link => ({ key: link.key as string, resource: this.RegisterResource(link.resource) }))
    )
  }

  private GetResource(resourceKey: string) {
    const foundResource = this.resources.Get(resourceKey)
    if (!foundResource) {
      throw new Error(
        `DataProvider DataStorage: Could not find resource '${resourceKey}'. Available resources: [${this.resources
          .GetAll()
          .map(q => q.id)
          .join(', ')}]`
      )
    }
    return foundResource
  }

  public async Get<T>(resourceKey: string, query?: object, modifyResult?: (result: Cursor<T[]>) => Cursor<T[]>) {
    const resource = this.GetResource(resourceKey)

    if (DataProviderCore.debug) console.debug('Running Get', resourceKey, 'with query', JSON.stringify(query ?? {}))
    let dataCursor = resource.store.find(query ?? {}) as Cursor<T[]>

    if (modifyResult) dataCursor = modifyResult(dataCursor)

    if (!resource.links?.length) return dataCursor.exec()
    const data = await dataCursor.exec()

    for (const entry of data) {
      if (!entry || typeof entry !== 'object') continue
      for (const link of resource.links) {
        if (!DataProviderUtils.objectHasKey(entry, link.key)) continue
        const key = link.key as keyof T
        const subObject = await this.Get(link.resource.id, { id: entry[key] })
        if (!subObject.length) continue
        Object.assign(entry, { [key]: subObject[0] })
      }
    }
    return data
  }

  public GetOne<T>(resourceKey: string, query?: object) {
    const resource = this.GetResource(resourceKey)
    if (DataProviderCore.debug) console.debug('Running Get', resourceKey, 'with query', JSON.stringify(query ?? {}))
    return resource.store.findOne(query ?? {}).exec() as Promise<T>
  }

  public async Create<T extends object>(resourceKey: string, dataToCreate: T[]) {
    if (!dataToCreate?.length) return
    const resource = this.GetResource(resourceKey)

    for (const entry of dataToCreate) {
      const modEntry = await this.UpdateSubObjects(resource, entry, 'create')
      await resource.store.insertAsync(modEntry as MongoDocument)
    }
  }

  private idProvider<T extends object>(resource: ResourceStorage, item: T) {
    if (resource.dataIds?.length) return DataProviderUtils.createIdFrom(item, ...(resource.dataIds as (keyof T)[]))
    throw new Error(`DataStorage resource ${resource.id} is missing an id provider`)
  }

  public async Update<T extends MongoDocument>(resourceKey: string, dataToUpdate: T[], mode: 'patch' | 'put' | 'create' = 'create') {
    if (!dataToUpdate?.length) return
    const resource = this.GetResource(resourceKey)

    for (let entry of dataToUpdate) {
      if (resource.links?.length) {
        entry = await this.UpdateSubObjects(resource, entry, mode)
      }
      const id = this.idProvider(resource, entry)
      if (!id) {
        console.error(`Invalid Id configuration for resource ${resource.id}`)
        return
      }

      if (mode === 'create') {
        await resource.store.updateAsync(id, entry, { upsert: true })
      } else if (mode === 'patch') {
        await resource.store.updateAsync(id, { $set: entry })
      } else if (mode === 'put') {
        await resource.store.updateAsync(id, entry)
      }
    }
  }

  private async UpdateSubObjects<T extends object>(resource: ResourceStorage, entry: T, mode?: 'patch' | 'put' | 'create') {
    if (!resource || !resource.links?.length) return entry
    for (const link of resource.links) {
      const subObject = entry[link.key as keyof T]
      if (!subObject || typeof subObject !== 'object') continue

      const id = this.idProvider(link.resource, subObject)
      if (!id) continue
      Object.assign(entry, { [link.key]: id })
      await this.Update(link.resource.id, [subObject], mode)
    }
    return entry
  }

  // update an db-record with given id (used to overwrite records with temporary ids with their radix id + values)
  public async UpdateEntry<T>(resourceKey: string, id: object, entry: T) {
    if (!entry) return
    const resource = this.GetResource(resourceKey)
    const updatedEntry = this.UpdateSubObjects(resource, entry, 'create')
    await resource.store.updateAsync(id, { $set: updatedEntry })
  }

  public async UpdateId(resource: string, idToUpdate: string, newId: string) {
    if (!idToUpdate || !newId) return
    const storage = this.GetResource(resource).store
    await storage.updateAsync({ id: idToUpdate }, { $set: { id: newId } })
  }

  public async UpdateOrCreate<T extends object>(resource: string, dataToUpdate: T[]) {
    await this.Update(resource, dataToUpdate, 'create')
  }

  public async Patch<T extends object>(resource: string, dataToUpdate: T[]) {
    await this.Update(resource, dataToUpdate, 'patch')
  }

  public async Put<T extends object>(resource: string, dataToUpdate: T[]) {
    await this.Update(resource, dataToUpdate, 'put')
  }

  public async Delete<T extends object>(resource: string, dataToDelete: T[]) {
    const storage = this.GetStorageByKey(resource)
    if (!storage) return

    for (const entry of dataToDelete) {
      const id = this.idProvider(storage, entry)
      if (!id) {
        console.error(`Invalid Id configuration for resource ${resource}`)
        continue
      }
      await storage.store.removeAsync(id)
    }
  }

  public async DeleteAll(resource?: string) {
    let resources = []
    if (resource) {
      const store = this.GetStorageByKey(resource)
      if (!store) return
      resources.push(store)
    } else {
      resources = this.resources.GetAll()
    }
    for (const entry of resources) {
      // delete all records
      await entry.store.removeAsync({}, { multi: true })
      // reload dataBase to remove remnants
      await entry.store.loadDatabaseAsync()
    }
  }

  public async Count(resource: string) {
    const storage = this.GetStorageByKey(resource)
    if (!storage) {
      console.error('DataStorage.Count() Error: No resource found with key', resource)
      return 0
    }
    return await storage.store.count({}).exec()
  }

  public async GetStorageSize(resourceKey: string) {
    const storageKey = this.GetResourceStorageKey(resourceKey)
    const data = await AsyncStorage.getItem(storageKey)
    if (!data) return 0
    return DataProviderUtils.getObjectSize(data)
  }

  public async GetResourceCount(includeSize?: boolean) {
    const result: DataProviderStorageResource[] = []
    const resources = this.GetAllRegisteredResources()
    for (const resource of resources) {
      const count = await this.Count(resource.id)
      let size
      if (includeSize) {
        size = await this.GetStorageSize(resource.id)
      }
      result.push({ resource: resource.id, count, size })
    }
    return result
  }
}
