import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import ActivityDetailArticleCard from '../../cards/activities/detail/ActivityDetailArticleCard'
import ActivityDetailNotesCard from '../../cards/activities/detail/ActivityDetailNotesCard'
import ActivityDetailTimesCard from '../../cards/activities/detail/ActivityDetailTimesCard'
import ActivityDetailTravelCard from '../../cards/activities/detail/ActivityDetailTravelCard'
import ActivityButton from '../../components/ActivityButton'
import DynamicView from '../../components/Infominds/DynamicView'
import KeyboardAwareIOS from '../../components/KeyboardAwareIOS'
import ListSpacer from '../../components/ListSpacer'
import ScrollViewData from '../../components/ScrollViewData'
import useActivityBackNavigation from '../../hooks/activity/useActivityBackNavigation'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useLayout from '../../hooks/useLayout'
import { ActivityDetailStackParamList } from '../../navigation/types'

export default function ActivityWorkView() {
  const { detail, isHistorical, refreshDetail, loadingDetail, dataCount } = useActivityDetail()
  const { isLargeDevice, isMediumDevice, layoutSelect } = useLayout(true)

  const navigation = useNavigation<NavigationProp<ActivityDetailStackParamList, 'ActivityDetailWork'>>()

  const { goBackToTop: navigateBack } = useActivityBackNavigation()

  const route = useRoute<RouteProp<ActivityDetailStackParamList, 'ActivityDetailWork'>>()
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const allowEdit = !isHistorical

  return (
    <KeyboardAwareIOS>
      <ScrollViewData refresh={refreshDetail} loading={loadingDetail}>
        {detail && (
          <>
            <DynamicView flexLimitLargeDevice={isMediumDevice ? 6 : 12} rowStyle={{ gap: IMLayout.horizontalMargin * 2 }}>
              <IM.View style={IMLayout.flex.f6}>
                <ActivityDetailArticleCard spacing={'bottom'} enableChange={allowEdit} />
                {isLargeDevice && <ActivityDetailTravelCard spacing={'bottom'} enableChange={allowEdit} />}
                {!isLargeDevice && <ActivityDetailTimesCard spacing={'bottom'} enableChange={allowEdit} />}
              </IM.View>
              <IM.View style={IMLayout.flex.f6}>
                {isLargeDevice && <ActivityDetailTimesCard spacing={'bottom'} enableChange={allowEdit} />}
                {!isLargeDevice && <ActivityDetailTravelCard spacing={'bottom'} enableChange={allowEdit} />}
                <ActivityDetailNotesCard spacing="bottom" style={[styles.notesCard]} enableChange={allowEdit} />
              </IM.View>
            </DynamicView>
            {!isHistorical && (
              <IM.View
                style={[
                  IMLayout.flex.row,
                  layoutSelect<StyleProp<ViewStyle>>({ small: styles.closingButtonsViewSmallDevice, large: styles.closingButtonsViewLargeDevice }),
                ]}
                spacing={'top'}>
                <ActivityButton
                  style={{ backgroundColor: theme.card.secondaryBackground }}
                  title={i18n.t('ACTIVITY_DAILY_CLOSING')}
                  onPress={navigateBack}
                  icon={['fas', 'pause']}
                />
                <ActivityButton
                  title={i18n.t('CONTINUE')}
                  onPress={() => navigation.navigate('ActivityClosing', { ...route.params })}
                  icon={['fas', 'check']}
                  disabled={!dataCount.articles && !dataCount.notes && !dataCount.times && !dataCount.travels}
                />
              </IM.View>
            )}
            <ListSpacer height={100} />
          </>
        )}
      </ScrollViewData>
    </KeyboardAwareIOS>
  )
}

const styles = StyleSheet.create({
  closingButtonsViewLargeDevice: {
    justifyContent: 'flex-end',
    gap: IMLayout.horizontalMargin * 2,
  },
  closingButtonsViewSmallDevice: {
    justifyContent: 'space-between',
  },
  notesCard: {
    flexShrink: 1,
  },
})
