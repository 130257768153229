import { IM, IMLayout, useTheme, ViewProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

type TagContainerProps = ViewProps & {
  hideTag?: boolean
  color?: string
}

export default function TagContainer({ children, style, hideTag, color, ...viewProps }: TagContainerProps) {
  const { theme } = useTheme()

  return (
    <IM.View style={[!hideTag && IMLayout.flex.row, style]} {...viewProps}>
      {hideTag ? children : <IM.View style={[styles.view, { backgroundColor: color ?? theme.chip.background }]}>{children}</IM.View>}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  view: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: 3,
    borderRadius: 10,
    flexShrink: 1,
  },
})
