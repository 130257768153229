import { useMemo, useState } from 'react'

import { ListDisplayMode } from '../types'

type Options = {
  chunkSize?: number
  initialDisplayMode?: ListDisplayMode
  reverse?: boolean
}

export default function usePartialList<T>(items: T[], options?: Options) {
  const [displayMode, setDisplayMode] = useState<ListDisplayMode>(options?.initialDisplayMode ?? 'chunk')

  const displayedItems = useMemo(() => {
    if (!items) return []
    if (displayMode === 'full') {
      if (options?.reverse) return [...items].reverse()
      return items
    }
    const subSet = items.slice(0, options?.chunkSize ?? 3)
    if (options?.reverse) return [...subSet].reverse()
    return subSet
  }, [displayMode, items, options])

  const allItemsDisplayed = displayedItems.length === items.length

  return { displayedItems, displayMode, setDisplayMode, allItemsDisplayed }
}
