import { addYears, startOfToday } from 'date-fns'

import { ActivityState } from '../apis/types/apiResponseTypes'

const CONSTANTS = {
  toastVisibilityTime: 5000,
  appName: 'EMService',
  maxLastUsedCustomers: 3,
  maxLastUsedCustomersTablet: 6,
  skeletonCards: 30,
  customersChunkSize: 14,
  defaultChunkSize: 25,
  activityChunkSize: 10,
  destinationsChunkSize: 25,
  maxNavBarElements: 4,
  maxTabletNavBarElements: 6,
  landscapeTabletMaxSearchBar: 250,
  portraitTabletMaxSearchBar: 130,
  landscapeTabletMaxFilterBar: 500,
  portraitTabletMaxFilterBar: 350,
  maxPhoneNumbers: 13,
  cardLeftWidth: 6,
  secondButtonBottom: 64,
  searchDeferredTimeout: 400,
  signatureTimeout: 700,
  verticalTextMinHeight: 70,
  margin: 6,
  disabledOpacityLight: 0.5,
  disabledOpacityDark: 0.38,
  maxDataLengthForFastInput: 3,
  imageSizeSmallDevice: 180,
  imageSizeLargeDevice: 160,
  imageSpacing: 4,
  activityNoteChunkSize: 3,
  mapCarouselMaxItemWidth: 600,
  today: startOfToday(),
  maxDate: addYears(new Date(), 10),
  numberOfMaxContractActivitiesForAlert: 20,
  articleNodeListIndentWidth: 20,
  noQualificationId: -1,
  modalWidth: 550,
  modalWidthWeb: 650,
  backgroundUpdateInterval: 30000,
} as const

export default CONSTANTS

export const OpenActivityStates = [ActivityState.Open, ActivityState.Suspended]
